<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>

<div Fxflex="100%" class="active-header-status-container">
    <div Fxflex="100%" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column" class="active-text-container">
            <h1 tabindex="0" #pageheading class="active-txt">{{label}}:</h1>
            <div class="active-txtcount" aria-live="polite" aria-atomic="true">
                <span class="active-total-candidate">{{totalSupplierContacts}}</span>
                <span class="active-total-candidate-txt">{{label}}</span>
            </div>
        </div>
        <div fxLayoutAlign="end" fxLayoutAlign.xs="end" fxLayout="column" class="active-addicon">
            <a (click)="openEditSupplierContactDialog(true)">
                <span class="cdk-visually-hidden">{{label}}</span>
                <mat-icon matPrefix id="add_icon">add_circle</mat-icon>
            </a>
        </div>
    </div>
</div>
<section class="search_table" *ngIf="(ELEMENT_DATA.length>0 && dataSource.filteredData.length>0) ||(filterText) ">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <span fxFlex="60%" fxLayoutAlign="start">
            <mat-form-field appearance="outline" class="search-within-table search-box">
            <mat-icon matPrefix>search</mat-icon>
            <input matInput id="searchInput" [(ngModel)]="filterText" 
            (keyup)="applyFilter($event.target.value)"  placeholder="Search within table for..." 
            width="80%" fxFlex.sm="65">
            <button mat-button *ngIf="filterText" matSuffix mat-icon-button aria-label="Clear" (click)="resetList($event.target.value)">
                <mat-icon id="search_icon">close</mat-icon>
                </button>
            </mat-form-field>              
        </span>
        
    </div>
</section> 

<section class="content_table">

    <div>
        <section class="mat-elevation-z8">
            <div *ngIf="ELEMENT_DATA.length>0 && dataSource.filteredData.length>0" style="overflow:auto;">
                <table mat-table [dataSource]="dataSource" aria-describedby="table-candidates" matSort
                    [matSortActive]="sortBy" matSortDirection="asc" (matSortChange)="sortData($event)">

                    <ng-container matColumnDef="select">
                        <th id="checkbox-header" mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event && masterToggle()"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event && selection.toggle(row)" [checked]="selection.isSelected(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <!-- Full Name Column -->
                    <ng-container matColumnDef="fullname" sticky>
                        <th id="" mat-header-cell *matHeaderCellDef mat-sort-header>Full Name</th>
                        <td mat-cell *matCellDef="let element" class="cLinks"
                            [ngClass]="{highlighted: selection.isSelected(element)}"
                            (click)="openEditSupplierContactDialog(false, element)">
                            <span class="text-as-link" [innerHTML]="element.fullName | highlightSearch: filterText"></span>
                        </td>
                    </ng-container>
                    <!-- order Column -->
                    <ng-container matColumnDef="email">
                        <th id='' mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                        <td mat-cell *matCellDef="let element">
                            <span [innerHTML]="element.emailAddress ? (element.emailAddress | highlightSearch: filterText) : '-'"></span><br />
                        </td>
                    </ng-container>

                    <!-- Supplier Column -->
                    <ng-container matColumnDef="supplier">
                        <th id="" mat-header-cell *matHeaderCellDef mat-sort-header>Supplier</th>
                        <td mat-cell *matCellDef="let element">
                            <span
                                [innerHTML]="element.supplierName ? (element.supplierName | highlightSearch: filterText) : '-'"></span><br />
                            <span class="listStatusDate"
                                [innerHTML]="element.suppliersId ? ('Supplier&nbsp;#:&nbsp;' + (element.suppliersId)) : ''"></span>
                        </td>
                    </ng-container>

                    <!-- Status Column -->
                    <ng-container matColumnDef="status">
                        <th id="" mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                        <td mat-cell *matCellDef="let element">
                            <span [innerHTML]="element.status  ? (element.status | highlightSearch: filterText) : '-'"></span><br />
                            <span class="listStatusDate"
                                [innerHTML]="(element.statusDate && element.statusDate !== 'NaN-aN-aN') ? ('on&nbsp;' + (element.statusDate | date: 'yyyy-MM-dd')) : '-'"></span>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                        (click)="(row.status === 'Ready for Review' || row.status === 'Payment Pending') && navigateToBudgetSummary(row.candidateId)"
                        [ngClass]="{highlighted: selection.isSelected(row)}"></tr>
                </table>
            </div>
            <div [hidden]="!(ELEMENT_DATA.length>0 && dataSource.filteredData.length>0)">
                <mat-paginator [length]="pageInfo.totalCount" [pageSize]="pageInfo.pageSize"
                    [pageSizeOptions]="[5, 10, 20]" (page)="onPagination($event)"></mat-paginator>
            </div>
        </section>
    </div>
    <div [hidden]="ELEMENT_DATA.length>0 && dataSource.filteredData.length>0" class="invalidData">
        No records found
    </div>
</section>
<div *ngIf="selection.selected.length > 0" class="candidate-highlight-whitebg">
    <button mat-button class="mat-button contained-button" (click)="inactiveSupplierContacts()" id="withdrawBtn">Set
        <span *ngIf="selection.selected.length>1" class=" cost-model-btn-txt">{{selection.selected.length}}&nbsp;</span>
        <span class=" cost-model-btn-txt">to Inactive</span>
    </button>
</div>