/** MOV360-880 - to be removed at future date */
import { Component, OnInit, Input } from '@angular/core';
import { Supplier } from 'src/app/core/models/add-supplier.model';

@Component({
  selector: 'app-supplier-details-info',
  templateUrl: './supplier-details-info.component.html',
  styleUrls: ['./supplier-details-info.component.scss']
})
export class SupplierDetailsInfoComponent implements OnInit {

  @Input() supplierDetails: any;

  preferredName: string;
  supplierLegalName: string;

  supplierPreferredName: string;

  vendorNumber: string;

  country: string;

  streetAddress: string;

  city: string;

  state: string;

  zipcode: string;

  constructor() { }

  ngOnInit() {
    this.getSupplierDetails();
    this.getAddress();
  }

  getSupplierDetails() {
    this.supplierLegalName = this.supplierDetails.supplierLegalName ?
      this.supplierDetails.supplierLegalName : this.supplierDetails.entityName;
    this.supplierPreferredName = this.supplierDetails.supplierPreferredName ?
      this.supplierDetails.supplierPreferredName : this.supplierDetails.preferredName;
    this.vendorNumber = this.supplierDetails.supplierNumber;
  }

  getAddress() {
    this.country = this.supplierDetails.supplierAddress ?
      this.supplierDetails.supplierAddress.country : this.supplierDetails.contactMechanisms[0].country;
    this.streetAddress = this.supplierDetails.supplierAddress ?
      this.supplierDetails.supplierAddress.streetAddress : this.supplierDetails.contactMechanisms[0].addressLines[0];
    this.state = this.supplierDetails.supplierAddress ?
      this.supplierDetails.supplierAddress.state : this.supplierDetails.contactMechanisms[0].state;
    this.city = this.supplierDetails.supplierAddress ?
      this.supplierDetails.supplierAddress.city : this.supplierDetails.contactMechanisms[0].city;
    this.zipcode = this.supplierDetails.supplierAddress ?
      this.supplierDetails.supplierAddress.zipcode : this.supplierDetails.contactMechanisms[0].postalCode;
  }

}
