<ng-template [appReadonly]="['Manage Candidates/Transferees All']" (valueChange)="isDisabled($event)">
</ng-template>
<div class="cdk-visually-hidden" aria-live="polite" [innerHTML]="sortStatement" aria-atomic="true"></div>
<section class="content_table">
  <div>
    <section class="mat-elevation-z8">
      <div *ngIf="ELEMENT_DATA.length>0 && dataSource.filteredData.length>0" style="overflow:auto;">
        <table mat-table [dataSource]="dataSource" summary="Candidate details" aria-describedby="table-candidates"
          matSort [matSortActive]="sortBy" matSortDirection="asc" (matSortChange)="sortData($event)">
          <!-- Select Checkbox Column -->
          <ng-container matColumnDef="select" sticky>
            <th id="" mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event && masterToggle()"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event && selection.toggle(row)"
                [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- Full Name Column -->
          <ng-container matColumnDef="fullname" sticky>
            <th id="" mat-header-cell *matHeaderCellDef mat-sort-header> Full Name </th>
            <td mat-cell *matCellDef="let element" class="cLinks" [ngClass]="{highlighted: selection.isSelected(element)}"
              (click)="(element.status != 'Ready for Review' && element.status != 'Payment Pending') && open(element)">
              <a href="#" class="client-table-links" (click)="$event.preventDefault()">
                <span class="text-as-link" [innerHTML]="element.fullname | highlightSearch: filterText"></span>
              </a>
            </td>
          </ng-container>

          <!-- Client Column -->
          <ng-container matColumnDef="client">
            <th mat-header-cell id="client" *matHeaderCellDef mat-sort-header> Client </th>
            <td mat-cell *matCellDef="let element"><span
                [innerHTML]="element.clientName? (element.clientName | highlightSearch: filterText):''">{{element.clientName}}</span><br>
              <span class="small no-wrap"
                [innerHTML]="element.clientContactNameFormat? (element.clientContactNameFormat | highlightSearch: filterText):''">{{element.clientContactNameFormat}}</span>
            </td>
          </ng-container>

          <!-- Email Address Column -->
          <ng-container matColumnDef="emailAddress">
            <th id="" mat-header-cell *matHeaderCellDef mat-sort-header> Email Address </th>
            <td mat-cell *matCellDef="let element" [innerHTML]="(element.emailAddress != undefined || null) ?
             (element.emailAddress | highlightSearch: filterText):''">
            </td>
          </ng-container>

          <!-- Level Column -->
          <ng-container matColumnDef="level">
            <th id='' mat-header-cell *matHeaderCellDef mat-sort-header> Level </th>
            <td mat-cell *matCellDef="let element">
              <span [innerHTML]="(element.levelName != undefined || null)?
              (element.levelName | highlightSearch: filterText):''"></span><br />
              <span class="small no-wrap" [innerHTML]="(element.levelDes != undefined || null)?
              (element.levelDes| highlightSearch: filterText):''"></span>
            </td>
          </ng-container>

          <!-- Business Unit Column -->
          <ng-container matColumnDef="businessUnit">
            <th id="" mat-header-cell *matHeaderCellDef mat-sort-header> Business Unit </th>
            <td mat-cell *matCellDef="let element" [innerHTML]="(element.businessUnit != undefined || null) ?
            (element.businessUnit | highlightSearch: filterText):''">
            </td>
          </ng-container>

          <!-- Departure Column -->
          <ng-container matColumnDef="departure.state">
            <th id="" mat-header-cell *matHeaderCellDef mat-sort-header="departure"> Departure </th>
            <td mat-cell *matCellDef="let element" [innerHTML]="((element.departure != undefined) && (element.departure.city != undefined) && (element.departure.state != undefined))?
              (element.departure.state+ ', '+ element.departure.city | highlightSearch: filterText):''">
            </td>
          </ng-container>

          <!-- Destination Column -->
          <ng-container matColumnDef="destination.state">
            <th id="" mat-header-cell *matHeaderCellDef mat-sort-header="destination"> Destination </th>
            <td mat-cell *matCellDef="let element" [innerHTML]="((element.destination != undefined) && (element.destination.city != undefined) && (element.destination.state != undefined))?
              (element.destination.state+ ', '+ element.destination.city | highlightSearch: filterText):''">
            </td>
          </ng-container>

          <!-- Last Status Updated Column -->
          <ng-container matColumnDef="lastUpdatedDate">
            <th id="" mat-header-cell *matHeaderCellDef mat-sort-header> Status Date </th>
            <td mat-cell *matCellDef="let element" [innerHTML]="element.lastUpdatedDate | date:'yyyy-MM-dd'">
            </td>
          </ng-container>

          <!-- Invitation Sent Date Column -->
          <ng-container matColumnDef="invitationSentDate">
            <th id="" mat-header-cell *matHeaderCellDef mat-sort-header> Invitation Sent Date </th>
            <td mat-cell *matCellDef="let element" [innerHTML]="(element.invitationSentDate != null || undefined)?
              (element.invitationSentDate | date:'yyyy-MM-dd'):''">
            </td>
          </ng-container>

          <!-- Created By Column -->
          <ng-container matColumnDef="createdBy">
            <th mat-header-cell id="" *matHeaderCellDef mat-sort-header> Created By </th>
            <td mat-cell *matCellDef="let element" [innerHTML]="element.createdBy | highlightSearch: filterText">
            </td>
          </ng-container>
          <!--Mobile phone number-->
          <ng-container matColumnDef="phoneNumber">
            <th mat-header-cell id="" *matHeaderCellDef mat-sort-header> Mobile Phone Number </th>
            <td mat-cell *matCellDef="let element" [innerHTML]="(element.phoneNumber != null || undefined)?
            (element.phoneNumber | highlightSearch: filterText):''">
            </td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status" stickyEnd>
            <th mat-header-cell id="" *matHeaderCellDef mat-sort-header> Status </th>
            <td mat-cell *matCellDef="let element">
              <div class="notification-important">
                <span *ngIf="element.status === 'Ready for Review'" class="ready-icon">
                  <mat-icon *ngIf="element.status === 'Ready for Review'"> notification_important </mat-icon>
                  <a href="#" (click)="$event.preventDefault()" class="text-as-link"
                    [innerHTML]="element.status | highlightSearch: filterText"></a>
                </span>
                <span *ngIf="element.status !== 'Ready for Review'"
                  [innerHTML]="element.status | highlightSearch: filterText">
                </span>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            (click)="(row.status === 'Ready for Review' || row.status === 'Payment Pending') && navigateToBudgetSummary(row.candidateId)"
            [ngClass]="{highlighted: selection.isSelected(row)}"></tr>
        </table>
      </div>
      <div [hidden]="!(ELEMENT_DATA.length>0 && dataSource.filteredData.length>0)">
        <mat-paginator [length]="pageInfo.totalCount" [pageSize]="pageInfo.pageSize" [pageSizeOptions]="[5, 10, 20]"
          (page)="onPagination($event)"></mat-paginator>
      </div>
    </section>
  </div>
  <div [hidden]="!dataSource || (ELEMENT_DATA.length>0 && dataSource.filteredData.length>0)" class="invalidData"
    role="alert">
    No records found
  </div>
</section>
<div *ngIf="selection.selected.length>0" class="candidate-highlight-whitebg">
  <button mat-button class="mat-button contained-button" (click)="withdrawCandidates()" id="withdrawBtn"
    [disabled]="disable">Withdraw
    <span *ngIf="selection.selected.length===1" class=" cost-model-btn-txt">Candidate</span><span
      *ngIf="selection.selected.length>1" class=" cost-model-btn-txt">{{selection.selected.length}}
      Candidates</span></button>
</div>
