/** MOV360-880 - to be removed at future date */
import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { ExtensionService } from 'src/app/core/services/extension.service';
import { LocationService } from 'src/app/core/services/location.service';
import { OracleSiteId } from 'src/app/core/models/oraclesiteid.model';

export const fixDialogFocus = (dialogRef: MatDialogRef<any>) => {
  /* eslint-disable */
  if (event && event.currentTarget && (<any> event.currentTarget).blur) {
    const initiator: any = event.currentTarget;
    /* eslint-enable */
    dialogRef.afterClosed().subscribe(() => {
      setTimeout(() => {
        try { initiator.blur(); } catch {}
      }, 0);
    });
  }
};

@Component({
  selector: 'app-oracle-site-id-info',
  templateUrl: './oracle-site-id-info.component.html',
  styleUrls: ['./oracle-site-id-info.component.scss']
})
export class OracleSiteIdInfoComponent implements OnInit {

  oracleSiteForm: UntypedFormGroup;
  lockFields = false;
  isEmptyOracleSite = true;
  stateList: any[];
  oracleSiteId: OracleSiteId = {} as OracleSiteId;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<OracleSiteIdInfoComponent>,
  private readonly notificationsService: NotificationsService,
  private readonly formBuilder: UntypedFormBuilder,
  private readonly spinner: NgxSpinnerService,
  private router: Router,
  private extnSvc: ExtensionService,
  private locationService: LocationService) {
    this.stateList = this.locationService.getStates();
  }

  ngOnInit() {
    fixDialogFocus(this.dialogRef);
    this.isEmptyOracleSite = this.data.oracleSiteId ? false : true;
    this.oracleSiteForm = this.formBuilder.group({
      oraclesiteId: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
      country: [''],
      streetaddress: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
      city: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
      state: ['', Validators.required],
      zipcode: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])]
    });
    this.oracleSiteForm.controls['country'].setValue('United States of America');
    if (this.data.oracleSiteId) {
      this.setData();
    }
  }

  setData() {
    this.oracleSiteForm.get('oraclesiteId').setValue(this.data.oracleSiteId.oraclesiteId);
    this.oracleSiteForm.get('country').setValue(this.data.oracleSiteId.country);
    this.oracleSiteForm.get('streetaddress').setValue(this.data.oracleSiteId.streetAddress);
    this.oracleSiteForm.get('city').setValue(this.data.oracleSiteId.city);
    this.oracleSiteForm.get('state').setValue(this.data.oracleSiteId.state);
    this.oracleSiteForm.get('zipcode').setValue(this.data.oracleSiteId.zipcode);
    this.oracleSiteForm.get('isSwitchActive').setValue(this.data.oracleSiteId.isSwitchActive);
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

 /**
   * Closing the dialog box
   */
  close(event): void {
    event.preventDefault();
    event.stopPropagation();
    this.dialogRef.close();
  }

 /**
   * Closing the dialog box - we are setting the form to empty
   */
  onNoClick(event): void {
    event.preventDefault();
    event.stopPropagation();
    this.dialogRef.close();
  }

  canSaveContact() {
    return this.oracleSiteForm.get('oraclesiteId').valid && this.oracleSiteForm.get('streetaddress').valid
      && this.oracleSiteForm.get('city').valid && this.oracleSiteForm.get('state').valid && this.oracleSiteForm.get('zipcode').valid;
  }

  populateOracleData() {
    this.oracleSiteId = {
      'oraclesiteId': this.oracleSiteForm.get('oraclesiteId').value ? this.oracleSiteForm.get('oraclesiteId').value : '',
      'country': this.oracleSiteForm.get('country').value ? this.oracleSiteForm.get('country').value : '',
      'streetAddress': this.oracleSiteForm.get('streetaddress').value ? this.oracleSiteForm.get('streetaddress').value : '',
      'city': this.oracleSiteForm.get('city').value ? this.oracleSiteForm.get('city').value : '',
      'state': this.oracleSiteForm.get('state').value ? this.oracleSiteForm.get('state').value : '',
      'zipcode': this.oracleSiteForm.get('zipcode').value ? this.oracleSiteForm.get('zipcode').value : '',
      'isSwitchActive': true
    };
  }

  onSave(event) {
    event.preventDefault();
    event.stopPropagation();
    this.populateOracleData();
    this.dialogRef.close(this.oracleSiteId);
  }
}
