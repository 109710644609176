import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RolecapsRoutingModule } from './rolecaps-routing.module';
import { MaterialModule } from '../material/material.module';
import { ProjectAlphaModule } from '../public/project-alpha/project-alpha.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RolecapsDashboardComponent } from './components/rolecaps-dashboard/rolecaps-dashboard.component';
import { RolecapsRolesListComponent } from './components/roles/rolecaps-roles-list/rolecaps-roles-list.component';
import { RolecapsAddEditRoleComponent } from './components/roles/rolecaps-add-edit-role/rolecaps-add-edit-role.component';
import { RolecapsAddEditCapComponent } from './components/capabilities/rolecaps-add-edit-cap/rolecaps-add-edit-cap.component';
import { RolecapsCapabilitiesListComponent } from './components/capabilities/rolecaps-capability-list/rolecaps-capability-list.component';
import { RolecapsMappingsComponent } from './components/mappings/rolecaps-mappings/rolecaps-mappings.component';
import { RolecapsRoleMappingComponent } from './components/mappings/rolecaps-role-mapping/rolecaps-role-mapping.component';
import { RolecapsCapSearchComponent } from './components/mappings/rolecaps-cap-search/rolecaps-cap-search.component';
import { RolecapsCapMappingComponent } from './components/mappings/rolecaps-cap-mapping/rolecaps-cap-mapping.component';
import { RolecapsRoleSearchComponent } from './components/mappings/rolecaps-role-search/rolecaps-role-search.component';

@NgModule({
  declarations: [
    RolecapsDashboardComponent,
    RolecapsRolesListComponent,
    RolecapsAddEditRoleComponent,
    RolecapsAddEditCapComponent,
    RolecapsCapabilitiesListComponent,
    RolecapsMappingsComponent,
    RolecapsRoleMappingComponent,
    RolecapsCapSearchComponent,
    RolecapsCapMappingComponent,
    RolecapsRoleSearchComponent
  ],
  imports: [
    CommonModule,
    RolecapsRoutingModule,
    MaterialModule,
    ProjectAlphaModule,
    FormsModule,
    ReactiveFormsModule
  ],
  entryComponents: [
    RolecapsAddEditRoleComponent,
    RolecapsAddEditCapComponent
  ]
})
export class RolecapsModule { }
