/** MOV360-880 - to be removed at future date */
import { Component, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { OracleSiteIdInfoComponent } from '../oracle-site-id-info/oracle-site-id-info.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { SupplierContractService } from 'src/app/core/services/supplier-contract.service';
import { OracleSiteId } from 'src/app/core/models/oraclesiteid.model';

@Component({
  selector: 'app-oracle-site-ids',
  templateUrl: './oracle-site-ids.component.html',
  styleUrls: ['./oracle-site-ids.component.scss']
})
export class OracleSiteIdsComponent {
   /**variable to store the title */
   displayedColumns: string[] = [
    'oraclesiteId',
    'country',
    'streetAddress',
    'city',
    'state',
    'zipcode',
    'active'
  ];

   /**stores the details of oraclesiteid */
  // ELEMENT_DATA: Supplier[] = [];
  ELEMENT_DATA: OracleSiteId[] = [];
  oracleSiteIdData: OracleSiteId[] = [];
  @Input() contractIndex: number;
  dataSource = new MatTableDataSource<any>();
  @Input() isManageSupplier: boolean;

   /** To sort the mat table columns */
   @ViewChild(MatSort, { static: false }) set sortOrder(sort: MatSort) {
    if (sort) {
      this.dataSource.sort = sort;
    }
  }
  /** To paginate in a mat table */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  /** store default column for sorting */
  sortBy = 'oraclesiteId';
  /** sorting direction */
  sortDir = 'asc';
  /**flag for toggle bar */
  isEmptyOracleSite = true;
  oracleSiteData: any;
  isMobile = false;

/**
   * media subscription
   */
  mediasbscription: Subscription;
  constructor(public dialog: MatDialog,
    private readonly mediaobserver: MediaObserver,
    private supplierContractService: SupplierContractService) {
      this.mediasbscription = mediaobserver.asObservable().subscribe((val: MediaChange[]) => {
        if (val[0].mqAlias === 'xs') {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }


      });
    }

  sortData(event) {
    this.sortBy = event.active;
    this.sortDir = event.direction === 'desc' ? 'desc' : 'asc';
  }
  /** Method to open Oracle site Id dialog**/
  openOracleSiteId(oracleSiteId?: OracleSiteId): void {

  const dialogRef = this.dialog.open(OracleSiteIdInfoComponent, {
    disableClose: true,
    panelClass: ['dialogMainContainer'],
    data: {oracleSiteId: oracleSiteId, isSwitchActive: true}
  });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.isEmptyOracleSite = false;
          this.oracleSiteData = result;
          this.oracleSiteIdData.push(result);
          this.dataSource = new MatTableDataSource(this.oracleSiteIdData);
          this.supplierContractService.updateContractIndex(this.contractIndex);
          this.supplierContractService.updateOracleSiteData(this.oracleSiteIdData);
        }
      });
}
onValChange(value, index) {
  this.oracleSiteIdData[index].isSwitchActive = value.target.checked;
  this.supplierContractService.updateContractIndex(this.contractIndex);
  this.supplierContractService.updateOracleSiteData(this.oracleSiteIdData);
}
}
