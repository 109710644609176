/** MOV360-880 - to be removed at future date */
import { Component, OnInit, Input, ViewEncapsulation, ElementRef, Renderer2, HostListener } from '@angular/core';
import { CandidateCoreBenefits } from '../../../../core/models/candidate-core-benifits.models';
import { budgetCurrency, coreServiceDesc } from '../../../../core/models/constants';
import { OverlayConfig, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { PopupPositionService } from '../../../../core/services/popup-position.service';
import { HtmlTooltipComponent } from '../../../../core/components/html-tooltip/html-tooltip.component';
import { CoreSpend } from '../../../../core/models/candidate-budget-details.model';
import {LoggerService} from '../../../../core/services/logger.service';
import { UserContextService } from '../../../../core/services/user-context.service';

/**CoreBenefits component */
@Component({
  selector: 'app-candidate-core-benifits',
  templateUrl: './candidate-core-benifits.component.html',
  styleUrls: ['./candidate-core-benifits.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CandidateCoreBenifitsComponent implements OnInit {

  /** overlayRef to hold overlay config */
  overlayRef: OverlayRef;

  /** tooltip icon active class */
  tooltipIconActive = 'active';

  /**receiving service data from budgetDetails component */
  @Input() coreService: CandidateCoreBenefits;
  /** stores currency */
  currency = budgetCurrency;
  /**to inject dependencies */
  constructor(
    private readonly overlay: Overlay,
    private readonly positionService: PopupPositionService,
    private readonly renderer: Renderer2,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: UserContextService
  ) { }

  /**To initialise the component */
  ngOnInit() {
    this.coreService.coreBenefit.budgetName = coreServiceDesc.name;
    this.coreService.coreBenefit.budgetDesc = coreServiceDesc.desc;
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-CANDIDATE_CORE_BENIFITS');
      });
  }

  @HostListener('document:keyup', ['$event']) onKeyUp(ev: KeyboardEvent) {
    if (ev.code === 'ArrowRight' || ev.code === 'ArrowUp' || ev.code === 'ArrowLeft' || ev.code === 'ArrowDown') {
      this.overlayRef.dispose();
    }
  }
  /** Open HTML tooltip for core service
 * @param coreData flex data to be passed to popup
 * @param evt KeyboardEvent
 */
  openHtmlTooltip(coreData: any, evt: KeyboardEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    evt.stopImmediatePropagation();
    const infoIcon = (evt.currentTarget as Element).getElementsByClassName('info');
    this.renderer.addClass(infoIcon[0], this.tooltipIconActive);
    const rect = (infoIcon[0]).getBoundingClientRect();
    const target = this.getPositionByEvents(rect);
    const element = new ElementRef(target);
    const positionStrategy = this.placeByPositionStrategy(element);
    const thresholdScroll = {
      threshold: 50
    };
    const overlayConfig = new OverlayConfig({
      width: 350,
      panelClass: 'html-tooltip-pane',
      hasBackdrop: true,
      backdropClass: 'mat-backdrop-transparent',
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close(thresholdScroll),
      disposeOnNavigation: true
    });
    this.overlayRef = this.overlay.create(overlayConfig);
    const containerPortal = new ComponentPortal(
      HtmlTooltipComponent,
      null,
      this.positionService.createInjector({
        keyString: 'coreBudget',
        dataObj: coreData,
        clientX: rect.left + (rect.right - rect.left) / 2,
        clientY: rect.top + (rect.bottom - rect.top) / 2,
        targetElem: infoIcon[0],
        overlayRef: this.overlayRef
      })
    );
    this.overlayRef.attach(containerPortal);
    this.overlayRef.backdropClick().subscribe(() => {
      this.overlayRef.dispose();
      this.renderer.removeClass(infoIcon[0], this.tooltipIconActive);
    });
  }

  /** get the position by events of the target */
  getPositionByEvents(rect) {
    return {
      getBoundingClientRect: () => ({
        bottom: rect.top + (rect.bottom - rect.top) / 2,
        height: rect.height,
        left: rect.left + (rect.right - rect.left) / 2,
        right: rect.left + (rect.right - rect.left) / 2,
        top: rect.top + (rect.bottom - rect.top) / 2,
        width: rect.width
      }),
    };
  }

  /** place the pop up by position strategy */
  placeByPositionStrategy(element: ElementRef) {
    return this.overlay
      .position()
      .flexibleConnectedTo(element)
      .withFlexibleDimensions(false)
      .withPositions([
        {
          originX: 'end',
          originY: 'center',
          overlayX: 'start',
          overlayY: 'center',
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'bottom',
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        }
      ]);
  }

}
