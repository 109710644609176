<section class="search_table">
  <form fxLayout="column" class="middle-section" [formGroup]="financeAuditExportForm">
  <div fxLayout="row" fxLayoutAlign="space-between start" class="date-align">
  <div>
    <span fxFlex="27%" fxLayoutAlign="start">
      <mat-form-field appearance="outline" [ngClass]="{ 'header-date-error-field' : financeAuditExportForm.get('startDate').hasError('requiredFromDate')}" class="search-within-table search-box ">
        <input matInput placeholder="Start Date" [matDatepicker]="startDatePicker" (blur)="setValidation('startDate')" formControlName="startDate" width="80%" fxFlex.sm="65" aria-label="Search within table">
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      <mat-error *ngIf="financeAuditExportForm.get('startDate').hasError('requiredFromDate')">From date must be entered</mat-error>
      </mat-form-field>
    </span>
    <span fxFlex="8%" fxLayoutAlign="start"></span>
    <span fxFlex="27%" fxLayoutAlign="start">
      <mat-form-field appearance="outline" [ngClass]="{ 'header-date-error-field' : financeAuditExportForm.get('endDate').hasError('requiredToDate') || financeAuditExportForm.get('endDate').hasError('validateToDate')}" class="search-within-table search-box">
      <input matInput placeholder="End Date" [matDatepicker]="endDatePicker" (blur)="setValidation('endDate')" formControlName="endDate" width="80%" fxFlex.sm="65" aria-label="Search within table">
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
        <mat-error *ngIf="financeAuditExportForm.get('endDate').hasError('requiredToDate')">To date must be entered</mat-error>
      <mat-error *ngIf="financeAuditExportForm.get('endDate').hasError('validateToDate')">To date cannot be prior to from date</mat-error>
      </mat-form-field>
    </span>
    <span fxFlex="20%" fxLayoutAlign="end" fxFlex.xs="10.5em">
        <button class="contained-button"
        [disabled]="!financeAuditExportForm.get('endDate').dirty
        || !financeAuditExportForm.get('startDate').dirty
        || financeAuditExportForm.get('endDate').errors  !== null
        || financeAuditExportForm.get('startDate').errors !== null
        || ((financeAuditExportForm.get('endDate').value  === null || financeAuditExportForm.get('endDate').value === '')
        && (financeAuditExportForm.get('startDate').value === null || financeAuditExportForm.get('startDate').value === ''))"
        (click)="loadFinanceExport()">Apply</button>
    </span>
  </div>
  </div>
</form>
</section>
<section class="export_section">
  <div>
    <section class="mat-elevation-z8">
      <div *ngIf="ELEMENT_DATA && ELEMENT_DATA.length>0" style="overflow:auto;">
        <table id="finaceauditexport" mat-table summary="Authorized transferee table" [dataSource]="dataSource" matSort>

          <!-- Full Name Column -->
           <ng-container matColumnDef="clientNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Client Number</th>
            <td mat-cell *matCellDef="let element">{{element.clientNumber}}</td>
          </ng-container>

          <!-- Client Name Column -->
          <ng-container matColumnDef="clientName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Client Name</th>
            <td mat-cell *matCellDef="let element">{{element.clientName}}</td>
          </ng-container>

          <!-- Client and Client Contact Name Column -->
          <ng-container matColumnDef="clientStatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Client Status</th>
            <td mat-cell *matCellDef="let element"><span
                [innerHTML]="element.clientStatus? (element.clientStatus):''">{{element.clientStatus}}</span>
            </td>
          </ng-container>

          <!-- Move Phase Column -->
          <ng-container matColumnDef="clientStatusDate">
            <th mat-header-cell *matHeaderCellDef>Client Status Date</th>
            <td mat-cell *matCellDef="let element"><span
              [innerHTML]="element.clientStatusDate? (element.clientStatusDate | date:'yyyy-MM-dd'):''">{{element.clientStatusDate| date:'yyyy-MM-dd'}}</span>
          </td>
        </ng-container>
          <!-- Authorized Amount Column -->
          <ng-container matColumnDef="vendorType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Vendor Type</th>
            <td mat-cell *matCellDef="let element"><span
              [innerHTML]="element.vendorType? (element.vendorType):''">{{element.vendorType}}</span>
            </td>
          </ng-container>

          <!-- Order # Column -->
          <ng-container matColumnDef="candidateId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Candidate Id</th>
            <td mat-cell *matCellDef="let element"><span
              [innerHTML]="element.candidateId? (element.candidateId):''">{{element.candidateId}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="candidateName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Candidate Name</th>
            <td mat-cell *matCellDef="let element"><span
              [innerHTML]="element.candidateFirstName && element.candidateLastName? (element.candidateFirstName + ', ' + element.candidateLastName):''">{{element.candidateFirstName + ', ' + element.candidateLastName }}</span>
            </td>
          </ng-container>

          <!-- Email Address Column -->
          <ng-container matColumnDef="candidateAuthDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Candidate Auth Date</th>
            <td mat-cell *matCellDef="let element"
            [innerHTML]="element.candidateAuthDate? (element.candidateAuthDate | date:'yyyy-MM-dd'):''">
              {{element.candidateAuthDate | date:'yyyy-MM-dd'}} </td>
          </ng-container>

          <!-- Business Unit Column -->
          <ng-container matColumnDef="candidateStatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Candidate Status</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="element.candidateStatus? (element.candidateStatus):''">
              {{element.candidateStatus}} </td>
          </ng-container>

          <ng-container matColumnDef="candidateStatusDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Candidate Status Date</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="element.candidateStatusDate? (element.candidateStatusDate | date:'yyyy-MM-dd'):''">
              {{element.candidateStatusDate | date:'yyyy-MM-dd'}} </td>
          </ng-container>

          <ng-container matColumnDef="departureCity">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Departure City</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="element.departureCity? (element.departureCity):''">
              {{element.departureCity}} </td>
          </ng-container>

          <ng-container matColumnDef="departureState">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Departure State</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="element.departureState? (element.departureState):''">
              {{element.departureState}} </td>
          </ng-container>

          <ng-container matColumnDef="destinationCity">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Destination City</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="element.destinationCity? (element.destinationCity):''">
              {{element.destinationCity}} </td>
          </ng-container>

          <ng-container matColumnDef="destinationState">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Destination State</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="element.destinationState? (element.destinationState):''">
              {{element.destinationState}} </td>
          </ng-container>

          <ng-container matColumnDef="clientInvoiceNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Client Invoice Number</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="element.clientInvoiceNumber? (element.clientInvoiceNumber):''">
              {{element.clientInvoiceNumber}} </td>
          </ng-container>

          <ng-container matColumnDef="clientInvoiceDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Client Invoice Date</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="element.clientInvoiceDate? (element.clientInvoiceDate | date:'yyyy-MM-dd'):''">
              {{element.clientInvoiceDate | date:'yyyy-MM-dd'}} </td>
          </ng-container>

          <ng-container matColumnDef="clientInvoiceTotal">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Client Invoice Total</th>
            <td mat-cell *matCellDef="let element">
              <span [innerHTML]="element.clientInvoiceTotal ? (element.clientInvoiceTotal | number : '1.2-2'):''">
                {{element.clientInvoiceTotal | number : '1.2-2'}}</span>
              <span [innerHTML]="element.clientInvoiceCurrency && element.clientInvoiceTotal ? (element.clientInvoiceCurrency):''">
                {{element.clientInvoiceCurrency}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="taxAssistance">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tax Assistance</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="element.taxAssistance? (element.taxAssistance):''">
              {{element.taxAssistance}} </td>
          </ng-container>

          <ng-container matColumnDef="vendorNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Vendor Number</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="element.vendorNumber? (element.vendorNumber):''">
              {{element.vendorNumber}} </td>
          </ng-container>

          <ng-container matColumnDef="vendorSiteId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Vendor Site Id</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="element.vendorSiteId? (element.vendorSiteId):''">
              {{element.vendorSiteId}} </td>
          </ng-container>

          <ng-container matColumnDef="vendorName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Vendor Name</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="element.vendorName? (element.vendorName):''">
              {{element.vendorName}} </td>
          </ng-container>

          <ng-container matColumnDef="invoiceNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Invoice Number</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="element.invoiceNumber? (element.invoiceNumber):''">
              {{element.invoiceNumber}} </td>
          </ng-container>

          <ng-container matColumnDef="invoiceDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Invoice Date</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="element.invoiceDate? (element.invoiceDate | date:'yyyy-MM-dd'):''">
              {{element.invoiceDate | date:'yyyy-MM-dd'}} </td>
          </ng-container>

          <ng-container matColumnDef="invoiceAmount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Invoice Amount</th>
            <td mat-cell *matCellDef="let element">
              <span [innerHTML]="element.invoiceAmount? (element.invoiceAmount):''">
                {{element.invoiceAmount}}</span>
              <span [innerHTML]="element.invoiceCurrency && element.invoiceAmount? (element.invoiceCurrency):''">
                {{element.invoiceCurrency}}</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="datetimeSentToOracle">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Sent To Oracle</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="element.datetimeSentToOracle? (element.datetimeSentToOracle | date:'yyyy-MM-dd'):''">
              {{element.datetimeSentToOracle | date:'yyyy-MM-dd'}} </td>
          </ng-container>

          <ng-container matColumnDef="auditStatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Audit Status</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="element.auditStatus? (element.auditStatus):''">
              {{element.auditStatus}} </td>
          </ng-container>

          <ng-container matColumnDef="cashOutDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Cash Out Date</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="element.cashOutDate? (element.cashOutDate | date:'yyyy-MM-dd'):''">
              {{element.cashOutDate | date:'yyyy-MM-dd'}} </td>
          </ng-container>

          <ng-container matColumnDef="vanlineQuotedAmount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Vanline Quoted Amount</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="element.vanlineQuotedAmount? (element.vanlineQuotedAmount | number : '1.2-2'):''">
              {{element.vanlineQuotedAmount | number : '1.2-2'}} </td>
          </ng-container>

          <ng-container matColumnDef="coreServiceAmount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Core Service Amount</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="element.coreServiceAmount? (element.coreServiceAmount | number : '1.2-2'):''">
              {{element.coreServiceAmount | number : '1.2-2'}} </td>
          </ng-container>

          <ng-container matColumnDef="flexibleSpendAmount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Flexible Spend Amount</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="element.flexibleSpendAmount? (element.flexibleSpendAmount | number : '1.2-2'):''">
              {{element.flexibleSpendAmount | number : '1.2-2'}} </td>
          </ng-container>

          <ng-container matColumnDef="vanLinePayout">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Vanline Payout</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="element.vanLinePayout? (element.vanLinePayout):''">
              {{element.vanLinePayout}} </td>
          </ng-container>

          <ng-container matColumnDef="commission">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Commission</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="element.commission? (element.commission):''">
              {{element.commission}} </td>
          </ng-container>

          <ng-container matColumnDef="insurance">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Insurance</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="element.insurance? (element.insurance | number : '1.2-2'):''">
              {{element.insurance | number : '1.2-2'}} </td>
          </ng-container>

          <ng-container matColumnDef="eePayout">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>EE Payout</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="element.eePayout? (element.eePayout | number : '1.2-2'):''">
              {{element.eePayout | number : '1.2-2'}} </td>
          </ng-container>

          <ng-container matColumnDef="estimatedWeight">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Estimated Weight</th>
            <td mat-cell *matCellDef="let element">
              <span [innerHTML]="element.estimatedWeight? (element.estimatedWeight):''">
              {{element.estimatedWeight}}</span>
              <span [innerHTML]="element.estimatedWeightUnit? (element.estimatedWeightUnit):''">
              {{element.estimatedWeightUnit}}</span>
             </td>
          </ng-container>

          <ng-container matColumnDef="estimatedDistance">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Estimated Distance</th>
            <td mat-cell *matCellDef="let element">
              <span [innerHTML]="element.estimatedDistance? (element.estimatedDistance):''">
              {{element.estimatedDistance}}</span>
              <span [innerHTML]="element.estimatedDistanceUnit? (element.estimatedDistanceUnit):''">
              {{element.estimatedDistanceUnit}}</span>
             </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
            <!-- commented out as part of MOV360-26: function does not exist. -->

            <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;" -->
            <!-- [ngClass]="{highlighted: selection.isSelected(row)}" -->
            <!-- (click)="navigateToTransfereeDetails(row.candidate.candidateId)"></tr> -->

            <!-- Substituted lines below -->
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            [ngClass]="{highlighted: selection.isSelected(row)}"></tr>
        </table>
      </div>
      <div [hidden]="!(ELEMENT_DATA && ELEMENT_DATA.length>0 && dataSource && dataSource.filteredData && dataSource.filteredData.length>0)">
        <mat-toolbar>
          <mat-toolbar-row>
            <mat-paginator [length]="pageInfo.totalCount" [pageSize]="pageInfo.pageSize" [pageSizeOptions]="[5, 10, 20]"></mat-paginator>
          </mat-toolbar-row>
        </mat-toolbar>
      </div>
    </section>
  </div>
  <div [hidden]="!dataSource || (ELEMENT_DATA && ELEMENT_DATA.length>0 && dataSource && dataSource.filteredData && dataSource.filteredData.length>0)" class="invalidData"
    role="alert">
    No records found
  </div>
</section>
