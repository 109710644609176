/** MOV360-880 - to be removed at future date */
import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddCandidateComponent } from './add-candidate/add-edit-budget.component';
import { CandidateDetailsComponent } from './candidate-details/candidate-details.component';
import { CandidateColumnsComponent } from './candidate-columns/candidate-columns.component';
import { Selection } from '../../../core/models/selection.model';
import { NotificationsService } from '../../../../../src/app/core/services/notifications.service';
import { Subscription } from 'rxjs';
import { Candidate } from '../../../core/models/candidate';
import { LoggerService } from '../../../core/services/logger.service';
import { UserContextService } from '../../../core/services/user-context.service';

/**Class for  CandidateProfileComponent*/
@Component({
  selector: 'app-candidate-profile',
  templateUrl: './candidate-profile.component.html',
  styleUrls: ['./candidate-profile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CandidateProfileComponent implements OnInit, OnDestroy {
  /**Injecting CandidateDetailsComponent */
  @ViewChild(CandidateDetailsComponent, { static: true })
  childObj: CandidateDetailsComponent;
  /**variable to store list of selected columns */
  columnList: Selection[] = [];
  /** property to give readonly access */
  disableButton = false;
  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /**
   * Inject dependencies to be used in the component
   * @param dialog -MatDialog object
   * @param notificationsService -Object for NotificationsService
   */
  constructor(
    public dialog: MatDialog,
    private readonly notificationsService: NotificationsService,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: UserContextService

  ) { }
  /**To initialise the component */
  ngOnInit() {
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-CANDIDATE_PROFILE', 'CANDIDATE_PROFILE');
      });

  }
  /**
   * Function to filter results while searching
   * @param filterVal -Filter value passed
   */
  filterResults(filterVal) {
    this.childObj.applyFilter(filterVal);
  }

  /**Function to open modal Dialog to select columns on click of view column icon */
  openModal(evt: MouseEvent): void {
    evt.preventDefault();
    const dialogRef = this.dialog.open(CandidateColumnsComponent, {
      panelClass: 'dialogMainContainer',
      data: {
        selectedColumns: this.childObj.displayedColumns,
        client: this.disableButton
      },
      disableClose: true
    });
    this.subscription.add(
      dialogRef.componentInstance.columnsListUpdated.subscribe(
        (response: Selection[]) => {
          this.columnList = response;
        }
      )
    );
  }
  /** to check for readonly access */
  isDisabled(flag) {
    this.disableButton = flag;
  }
  /**To destroy the subscription */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
