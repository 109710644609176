<div class="container" fxFlex="100%" fxLayout="column" fxLayout.xs="column">

    <div *ngIf="isEmptyTerritoryData" class="emptyInfo" fxLayout="column" fxLayout.xs="column" fxFlex.xs="100%"
        fxLayoutAlign="center center" fxLayoutGap.xs="0px">
        <span class="spanText">You must add Territory Coverage to activate the contract</span>
        <span class=" addInfolink" (click)="AddEditTerritory();"><a>Add Territory Coverage</a></span>
    </div>

    <div *ngIf="!isEmptyTerritoryData" fxLayout.xs="column">
        <div fxLayout="row" fxLayout.xs="row" fxFlex.xs="100%" fxLayoutAlign="space-between" fxLayoutGap.xs="4px"
            fxLayoutGap="32px" *ngFor="let territory of terriToryCoverage; let i = index" class="territoryList">

            <div fxFlex="95%" fxLayoutAlign="start" fxFlex.xs="95%" class="territoryInformation">
                <ol class="territoryDetails">
                    <li fxLayout.xs="column" fxFlex.xs="100%">
                        <div fxLayout="row" fxLayout.xs="row" fxFlex.xs="80%" fxLayoutAlign="space-between"
                            fxLayoutGap.xs="4px" fxLayoutGap="32px" class="territoryLists">

                            <div fxFlex="5%" fxLayoutAlign="start" fxFlex.xs="20%" class="territoryTarrifNumber">
                                <a class="territoryTarrifListNumber">{{i+1}}</a>
                            </div>

                            <div fxFlex="45%" fxLayoutAlign="start" fxFlex.xs="80%" class="territoryTarrif">
                                <div fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%" fxLayout.xs="column"
                                    fxLayoutAlign="space-between" fxLayoutGap.xs="4px" fxLayoutGap="32px">

                                    <div fxFlex="50%" fxLayoutAlign="start" fxFlex.xs="100%">
                                        <mat-form-field fxFlex>
                                            <mat-label class='matLabel'>Territory</mat-label>
                                            <input matInput type="text" readonly [value]="territory.territory">
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="50%" fxLayoutAlign="start" fxFlex.xs="100%">
                                        <mat-form-field fxFlex>
                                            <mat-label class='matLabel'>Territory Tarrif</mat-label>
                                            <input matInput type="text" readonly [value]="territory.territoryTariff">
                                        </mat-form-field>
                                    </div>

                                </div>
                            </div>

                            <div fxFlex="50%" fxLayoutAlign="start" fxFlex.xs="100%" *ngIf="!isMobileView">

                            </div>

                        </div>

                        <div fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%" fxLayoutAlign="space-between"
                            fxLayoutGap.xs="4px" fxLayoutGap="10px" class="territoryTable">

                            <div fxFlex="50%" fxLayoutAlign="start" fxFlex.xs="100%">

                                <table class="supplier-discount-table" mat-table
                                    [dataSource]="territory.supplierDiscounts" *ngIf="territory.supplierDiscounts"
                                    aria-describedby="supplier-discount-table">

                                    <ng-container matColumnDef="discountType">
                                        <th mat-header-cell *matHeaderCellDef>Discount Type</th>
                                        <td mat-cell *matCellDef="let element">
                                            <span
                                                [innerHTML]="element.discountType ? element.discountType : '-'"></span>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="discountPercentage">
                                        <th mat-header-cell *matHeaderCellDef>Discount %</th>
                                        <td mat-cell *matCellDef="let element">
                                            <span
                                                [innerHTML]="element.discountPercentage ? element.discountPercentage : '-'"></span>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="startDate">
                                        <th mat-header-cell *matHeaderCellDef>Start Date</th>
                                        <td mat-cell *matCellDef="let element">
                                            <span [innerHTML]="element.startDate | date:'yyyy-MM-dd'"></span>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="endDate">
                                        <th mat-header-cell *matHeaderCellDef>End Date</th>
                                        <td mat-cell *matCellDef="let element">
                                            <span [innerHTML]="element.endDate | date:'yyyy-MM-dd'"></span>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="discountColumns;"></tr>
                                    <tr mat-row *matRowDef="let row; columns: discountColumns;"></tr>

                                </table>

                            </div>

                            <div fxFlex="50%" fxLayoutAlign="start" fxFlex.xs="100%">

                                <table class="commissions-table" mat-table [dataSource]="territory.commissions"
                                    *ngIf="territory.commissions" aria-describedby="commisions-table">

                                    <ng-container matColumnDef="commisionType">
                                        <th mat-header-cell *matHeaderCellDef>Commission Type</th>
                                        <td mat-cell *matCellDef="let element">
                                            <span
                                                [innerHTML]="element.commissionType ? element.commissionType : '-'"></span>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="commissionPercentage">
                                        <th mat-header-cell *matHeaderCellDef>Commission %</th>
                                        <td mat-cell *matCellDef="let element">
                                            <span
                                                [innerHTML]="element.commissionPercentage ? element.commissionPercentage : '-'"></span>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="startDate">
                                        <th mat-header-cell *matHeaderCellDef>Start Date</th>
                                        <td mat-cell *matCellDef="let element">
                                            <span [innerHTML]="element.startDate | date:'yyyy-MM-dd'"></span>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="endDate">
                                        <th mat-header-cell *matHeaderCellDef>End Date</th>
                                        <td mat-cell *matCellDef="let element">
                                            <span [innerHTML]="element.endDate | date:'yyyy-MM-dd'"></span>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="commissionColumns;"></tr>
                                    <tr mat-row *matRowDef="let row; columns: commissionColumns;"></tr>

                                </table>

                            </div>

                        </div>
                    </li>
                </ol>
                <button mat-button matSuffix mat-icon-button aria-label="EditTerritoryCoverage"
                    class="iconCursor">
                    <mat-icon *ngIf="isManageSupplier" class="iconCursor" (click)="AddEditTerritory(i, true)">edit</mat-icon>
                </button>
            </div>

            <div fxFlex="5%" fxLayoutAlign="start" fxFlex.xs="5%" fxLayoutAlign="center">
                <mat-icon class="iconCursor delete-Mat-icon" (click)="removeTerritoryCoverage(i)">delete_outline
                </mat-icon>
            </div>

        </div>
    </div>

    <div mat-dialog-actions *ngIf="!isEmptyTerritoryData" class="terrotoryButton">
        <button (click)="AddEditTerritory()" mat-button class="mat-button contained-button" [disabled]="isAllStateTerritoryAvailable">Add Territory
            Coverage</button>
    </div>

</div>