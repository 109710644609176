/** MOV360-880 - to be removed at future date */
import { Component, ElementRef, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { ModalData } from 'src/app/core/services/popup-position.service';

@Component({
  selector: 'app-vanline-popup',
  templateUrl: './vanline-popup.component.html',
  styleUrls: ['./vanline-popup.component.scss']
})
export class VanlinePopupComponent {
  vanlinecostdata: any;
  constructor(@Inject(ModalData) public data: any) { }

  onCancel(): void {
    this.data.vanlineOverlayRef.dispose();
  }

}
