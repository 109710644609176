<ngx-spinner type="ball-spin-clockwise-fade" size="medium">
</ngx-spinner>

<div class="dialogMainContainer candidate-formcontainer" flexLayout fxLayout.xs="column">
  <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
    fxLayoutGap="20px" fxLayoutGap.xs="0em">
    <h2>{{ title }}</h2>
    <a class="close-icon" id="close_icon" (click)="onNoClick()">
      <mat-icon>close</mat-icon>
    </a>
  </div>

  <mat-dialog-content class="middle-section">
    <form fxLayout="column" [ngClass]="{'costmodel-scroll-div': inviteAsClientContactFlag}"
      [formGroup]="addClientContactForm">
      <div *ngIf="!deleteDialog">
        <div class="columnGap"></div>
        <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
          fxLayoutGap.xs="0em">
          <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <input matInput placeholder="First Name" formControlName="ClientContactFirstName" id="level">
              <mat-error>
                {{ errors['ClientContactFirstName'] }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <input matInput placeholder="Last Name" formControlName="ClientContactLastName" id="level">
              <mat-error>
                {{ errors['ClientContactLastName'] }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0">
          <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <mat-label>Company</mat-label>
                <!-- commented out as part of MOV360-26: function located in different component. -->

                <!-- <mat-select [disabled]="true" formControlName="CompanyName" id="level" required -->
                <!-- (selectionChange)="getClientFromAPI($event)"> -->

                <!-- Substituted lines below -->
                <mat-select formControlName="CompanyName" id="level" required>
                <mat-option *ngFor="let client of clientList" [value]="client.clientLegalName">
                  {{client.clientLegalName}}
                </mat-option>
              </mat-select>
              <mat-error>
                {{ errors['ClientState'] }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <mat-label>Role</mat-label>
              <mat-select formControlName="ClientContactRole" id="level">
                <mat-option *ngFor="let role of clientRoles" [value]="role.displayName">
                  {{role.displayName}}
                </mat-option>
              </mat-select>
              <mat-error>
                {{ errors['ClientContactRole'] }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayout.xs="column"
          fxLayoutGap.xs="0em">
          <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <input type="text" placeholder="Client Contact Email" matInput formControlName="ClientContactEmailAddress"
                (ngModelChange)="addValidators()" id="departure">
              <mat-error>
                {{ getErrorMessage('EMAIL_ADDRESS') }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="50%" fxLayout.xs="row" fxFlex.xs="100%">
            <div fxFlex="30%" fxFlex.xs="30%" class="extn-leftspacer">
              <mat-form-field>
                <mat-label>Mobile Phone Number</mat-label>
                <mat-select formControlName="Extension">
                  <mat-option *ngFor="let extn of extensions" [value]="extn.countryDialingCode">
                    {{extn.countryDialingCode}}
                  </mat-option>
                </mat-select>
                <mat-error>
                  {{ errors['Extension'] }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="70%" fxFlex.xs="70%" class="international-extn">
              <mat-form-field fxFlex>
                <!-- commented out as part of MOV360-26: variable does not exist -->

                <!-- <input matInput type="text" -->
                <!-- formControlName="ClientContactMobileNumber" (ngModelChange)="addValidators()" -->
                <!-- [readonly]="readOnlyField" maxlength="18"> -->

                <!-- Substituted lines below -->
                <input matInput type="text"
                  formControlName="ClientContactMobileNumber" (ngModelChange)="addValidators()"
                  maxlength="18">
                <mat-hint class="hint">Mobile # required to receive text messages</mat-hint>
                <mat-error
                  *ngIf="addClientContactForm.controls['ClientContactMobileNumber'].hasError('pattern')||
              addClientContactForm.controls['ClientContactMobileNumber'].hasError('minlength') || addClientContactForm.controls['ClientContactMobileNumber'].hasError('alphabets') ">
                  {{ getErrorMessage('PHONE_NUMBER') }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div *ngIf="deleteDialog">
          <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
            <p> Please click on delete to confirm the deletion of the following client(s):<br>
              {{ this.data.clientPreferredName  }} </p>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="statusBtn add-dialogbtn">
      <button mat-button id="cancel_client" (click)="onNoClick()">CANCEL</button>
      <button mat-button class="mat-button outlined-button" id="save_draft" *ngIf="showDraftButton"
        [disabled]="checkSaveDraft() || data.disableButton" (click)="saveDraft()">{{saveDraftButtonLabel}}</button>
      <button mat-button class="mat-button outlined-button" id="save_client" *ngIf="showInactiveCheckBox"
        [disabled]="!checkForClientContactFields() || data.disableButton" (click)="inactivateClient()">Set to Inactive</button>
      <button mat-button *ngIf="!isInvitationSent" class="mat-button contained-button" (click)="sendInvite()"
        id="addEditSave" [disabled]="!(checkForClientContactFields() && isEmailValid && isPhoneNumberValid && checkForClientContactRole()) || data.disableButton">Send
        Invite</button>
      <button *ngIf="isInvitationSent && showResendButton"
        [disabled]="!(checkForClientContactFields() && isEmailValid && isPhoneNumberValid && checkForClientContactRole()) || data.disableButton" mat-button
        class="mat-button outlined-button" (click)="sendInvite()" id="addEditSendInvite">Resend</button>
    </div>
  </mat-dialog-actions>
</div>
