<ng-template [appReadonly]="['Manage User Alignment']" (valueChange)="isDisabled($event)"></ng-template>
<div class="people-search-container" *ngIf="!processing">
  <div class="header-access-container">
    <div class="header-text-container">
      <h1 class="heading">User Alignment Search Results</h1>
    </div>
    <div *ngIf="disable" id="readonly-indicator">
      <span>Read Only</span>
      <mat-icon>edit_off</mat-icon>
    </div>
  </div>
  <div class="people-search-results" *ngIf="displayResults">
    <p>Select the party to use as the Identity Provider Id source.
      The Identity Provider Id of the selected party will be copied to all other eligible parties in the list.
      Ineligible parties will be grayed out. Parties that you do not wish to update can be removed from the list using the remove button.
      Ineligible parties must be removed from the list for the copy button to be available.
    </p>
    <div class="columns-header-wrapper">
      <div class="columns-header">
        <span>Select</span>
        <span>Name</span>
        <span>Party ID</span>
        <span>SSO Value</span>
        <span>Identity Provider ID</span>
        <span>Self Reg Date</span>
        <span>Update Date</span>
        <span>Remove</span>
      </div>
    </div>
    <section *ngFor="let user of users; let i = index">
      <div class="card-wrapper transparent-bg" [ngClass]="{'disabled-bg': isUserDisabled(i), 'blue-bg': selectedUser === i}">
        <mat-card class="user-alignment-card" [ngClass]="{'disabled-bg': isUserDisabled(i)}">
          <span *ngIf="isUserDisabled(i)" class="underlay">not for selection</span>
          <mat-card-content>
            <div class="user-info"
            matTooltip="This party cannot be selected because it either belongs to a different organization or already has the same Identity Provider ID as the selected party"
            [matTooltipDisabled]="!isUserDisabled(i)" matTooltipClass="large-tooltip">
              <span class="checkbox-wrapper">
                <mat-checkbox *ngIf="!isUserDisabled(i) && users[i].identityProviderId" (click)="$event.stopPropagation()"
                  [aria-label]="selectedUser === i ? 'deselect' : selected && selectedUser !== i ? 'disabled' : 'select'"
                  [disabled]="(selected && selectedUser !== i) || disable" (change)="onSelect(i)"></mat-checkbox>
              </span>
              <span class="name" [innerHTML]="user.name"></span>
              <span class="id" [innerHTML]="user._id"></span>
              <span class="sso-value" [innerHTML]="user.ssoValue"></span>
              <span class="identity-provider-id" [innerHTML]="user.identityProviderId"></span>
              <span class="self-reg-date" [innerHTML]="user.selfRegDate"></span>
              <span class="update-date" [innerHTML]="user.updateDate"></span>
              <span class="btn-wrapper remove-wrapper" matTooltip="Remove User" matTooltipPosition="below" matTooltipClass="large-tooltip">
                <button mat-flat-button class="remove transparent-bg" (click)="onRemove(i)">
                  <mat-icon>delete</mat-icon>
                </button>
              </span>
            </div>

            <!-- Contact Mechanism table -->
            <mat-accordion>
              <mat-expansion-panel class='contact-mechanisms-panel' [ngClass]="{'transparent-bg': isUserDisabled(i)}">
                <mat-expansion-panel-header>
                  <mat-panel-title class="panel-title">
                    Contact Mechanisms
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngIf="users[i].contactMechanisms.length; else noCM">
                  <app-user-alignment-table  class="cm-table-container"
                  [markDisabled]="isUserDisabled(i)"
                  [tableData]="users[i].contactMechanisms"
                  [tableColumns]="cmColumns">
                  </app-user-alignment-table>
                </ng-container>
                <ng-template #noCM>
                  <div class="no-table-data">
                    This user has no Contact Mechanisms
                  </div>
                </ng-template>
              </mat-expansion-panel>
            </mat-accordion>

            <div class="accordion-spacer"></div>

            <!-- Role table -->
              <mat-accordion>
                  <mat-expansion-panel class="roles-panel" [ngClass]="{'transparent-bg': isUserDisabled(i)}">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="panel-title">Roles</mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-container *ngIf="users[i].roles.length; else noRoles">
                      <app-user-alignment-table class="roles-table-container"
                        [markDisabled]="isUserDisabled(i)"
                        [tableData]="users[i].roles"
                        [tableColumns]="roleColumns">
                      </app-user-alignment-table>
                    </ng-container>
                    <ng-template #noRoles>
                      <div class="no-table-data" #noRoles>
                        This user has no Roles
                      </div>
                    </ng-template>
                </mat-expansion-panel>
              </mat-accordion>

              <div class="accordion-spacer"></div>

            <!-- Swamp Cross Reference table -->
              <mat-accordion>
                  <mat-expansion-panel class="swamp-cross-references-panel" [ngClass]="{'transparent-bg': isUserDisabled(i)}">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="panel-title">Swamp Cross Reference</mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-container *ngIf="users[i].swampCrossReferences.length; else noSwampCrossReferences">
                      <app-user-alignment-table class="swamp-cross-references-table-container"
                        [markDisabled]="isUserDisabled(i)"
                        [tableData]="users[i].swampCrossReferences"
                        [tableColumns]="swampCrossReferenceColumns">
                      </app-user-alignment-table>
                    </ng-container>
                    <ng-template #noSwampCrossReferences>
                      <div class="no-table-data">
                        This user has no Swamp Cross References
                      </div>
                    </ng-template>
                </mat-expansion-panel>
              </mat-accordion>

            <div class="accordion-spacer"></div>

            <!-- App Users table -->
              <mat-accordion>
                  <mat-expansion-panel class="app-users-panel" [ngClass]="{'transparent-bg': isUserDisabled(i)}">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="panel-title">App Users</mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-container *ngIf="users[i].appUsers.length; else noAppUsers">
                      <app-user-alignment-table class="app-users-table-container"
                        [markDisabled]="isUserDisabled(i)"
                        [tableData]="users[i].appUsers"
                        [tableColumns]="appUsersColumns">
                      </app-user-alignment-table>
                    </ng-container>
                    <ng-template #noAppUsers>
                      <div class="no-table-data">
                        This user has no App Users
                      </div>
                    </ng-template>
                </mat-expansion-panel>
              </mat-accordion>
          </mat-card-content>
        </mat-card>
      </div>

    </section>

    <div class="btn-container">
      <div class="btn-wrapper">
        <button id="cancel" type="button" mat-button class="button-outline-sm" (click)="cancel()" title="Cancel">
          <mat-icon class="material-icons">cancel</mat-icon>
          <span>Cancel</span>
        </button>
      </div>
      <div class="btn-wrapper">
        <button id="copy" type="button" mat-button class="button-outline-sm" [disabled]="!isButtonEnabled() || disable"
          title="Copy IdentityProviderID" (click)="openDialog()">
            <mat-icon class="material-icons">content_copy</mat-icon>
            <span>Copy identityProviderID</span>
        </button>
      </div>
    </div>
  </div>

</div>
