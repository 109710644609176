<table role="list" class="linked-list" [ngClass]="[ listStyleClasses ? listStyleClasses : '' ]" *ngIf="dataSet">
  <ng-container *ngFor="let item of dataSet; let i = index">
    <tr role="listitem" [ngClass]="{'done' : i + 1 < active, 'active' : i + 1 == active, 'notDone' : i + 1 > active}">
      <td class="col-key-dates" aria-hidden="true">
        <div class="key-date">
          {{item.value}}
        </div>
        <div class="vertical" *ngIf="i != dataSet.length - 1">
          <div>&nbsp;</div>
        </div>
      </td>
      <ng-container *ngFor="let column of item.columns; let j = index">
        <td class="col-data"
          [ngClass]="[ columnStyleClasses && columnStyleClasses[j + 1] ? columnStyleClasses[j + 1] : '' ]">
          <span class="cdk-visually-hidden">{{item.value}}</span>
          <span>{{column.label}} </span><span *ngIf="column.subLabel" class="small-text">{{column.subLabel}}</span>
          <span class="cdk-visually-hidden" *ngIf="i + 1 < active">phase completed</span>
          <span class="cdk-visually-hidden" *ngIf="i + 1 == active">phase active</span>
          <span class="cdk-visually-hidden" *ngIf="i + 1 > active">phase not completed</span>
        </td >
      </ng-container>
    </tr>
  </ng-container>
</table>