<div class="container" fxFlex="100%" fxLayout="row" fxLayout.xs="column" fxLayoutGap="2em" fxLayoutGap.xs="4px">
  <div  class="middle-section size">
      <div fxLayout="column" fxLayoutAlign="space-between" fxLayout.xs="column" fxLayoutGap="1em"
        fxLayoutGap.xs="0em">
        <div fxLayout="column" class="candidate-review-field fields" fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
          <mat-form-field>
            <mat-label>Supplier Legal Name</mat-label>
            <input class="text" matInput readonly [value]="supplierLegalName?supplierLegalName:' '">
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="space-between" fxLayout.xs="column" fxLayoutGap="1em"
        fxLayoutGap.xs="0em">
        <div fxLayout="column" class="candidate-review-field fields" fxFlex="50%" fxLayoutAlign="start" fxFlex.md="40%" fxFlex.xs="100%">
          <mat-form-field>
            <mat-label>Supplier Display Name</mat-label>
            <input class="text" matInput readonly [value]="supplierPreferredName?supplierPreferredName:' '">
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="space-between" fxLayout.xs="column" fxLayoutGap="1em"
        fxLayoutGap.xs="0em">
        <div class="candidate-review-field fields" fxLayout="column" fxFlex="50%" fxLayoutAlign="start" fxFlex.md="40%" fxFlex.xs="100%">
          <mat-form-field>
            <mat-label>Supplier Vendor #</mat-label>
            <input class="text" matInput readonly [value]="vendorNumber?vendorNumber:' '">
          </mat-form-field>
        </div>
      </div>
  </div>
  <div fxFlex="calc(50%-1.5em)" fxFlex.xs="100%" fxLayout="column" fxLayout.xs="column" fxLayoutGap="2em">
    <div class="bcgcolor">
      <span class="candidate-dialogtitle title">Primary Location</span>
      <div class="middle-section" >
        <div fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayout.xs="column"
          fxLayoutGap.xs="0em">
          <div fxFlex="50%" fxLayout="column" class="candidate-review-field fields" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <mat-label>Country</mat-label>
              <input class="text" matInput readonly [value]="country? country: ' '">
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayout.xs="column"
          fxLayoutGap.xs="0em">
          <div fxFlex="50%" fxLayout="column" class="candidate-review-field fields" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <mat-label>Street Address</mat-label>
              <input class="text" matInput readonly [value]="streetAddress? streetAddress: ''">
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayout.xs="column"
          fxLayoutGap.xs="0em">
          <div fxLayout="column" class="candidate-review-field fields" fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <mat-label>City</mat-label>
              <input class="text" matInput readonly [value]="city?city:' '">
            </mat-form-field>
          </div>
          <div fxLayout="column" class="candidate-review-field fields" fxFlex="15%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <mat-label>State</mat-label>
              <input class="text" matInput readonly [value]="state?state:' '">
            </mat-form-field>
          </div>
          <div fxLayout="column" class="candidate-review-field fields" fxFlex="35%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <mat-label>Zip Code</mat-label>
              <input class="text" matInput readonly [value]="zipcode?zipcode:' '">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
