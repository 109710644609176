import { routes } from '../app-routing.module';
import { CartusCommonUiConfig } from '@cartus-core/cartus-common-ui';

const urlConfig = require('src/assets/environments/environment-config.' + window.location.hostname.split('.')[0] + '.json');
export const config: CartusCommonUiConfig = {
  CURRENT_APP: 'mobilifyHR',
  IL_ACCESS_MANAGEMENT_API: {
    HOST: urlConfig.accessManagement.host
  },
  IL_LOG_API: {
    HOST: urlConfig.processAPI.host
  },
  IL_INTEGRATION_API: {
    HOST: urlConfig.api.host
  },
  MENU: {
    ROUTES: routes,
    TYPE: 'slide',
    IS_OPEN: false
  }
};
