import {
  Component,
  OnInit,
  ViewChild,
  Input,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { ApprovedMove } from '../../../core/models/approved-move';
import { ApprovedMovesService } from '../../../core/services/approved-moves.service';
import { Selection } from '../../../core/models/selection.model';
import { Subscription } from 'rxjs';
import { OverlayRef } from '@angular/cdk/overlay';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { PartySharedService } from '../../../core/services/party-shared.service';
import * as querystring from 'querystring';
import { apiErrorMessage } from '../../../core/models/constants';
import { UserPreferencesService } from '../../../core/services/user-preference.service';
import { UserPreference } from '../../../core/models/user-preference.model';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { DateValidator } from '../../../core/validators/date.validators';

/** Base component for Authorized-Move Component */
@Component({
  selector: 'app-finance-export',
  templateUrl: './finance-audit-export.component.html',
  styleUrls: ['./finance-audit-export.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FinanceAuditExportComponent implements OnInit, OnDestroy {
  financeAuditExportForm: UntypedFormGroup;
  /** Input prop for receiving data*/
  @Input() selectedCols: Selection[];
  /**variable to store sort order */
  sortStatement = '';
  /**flag for cheching account manager role */
  disableButton = false;
  data: any;
  finanaceExp: Subscription;
  reportData: any;
  /** To sort the mat table columns */
  @ViewChild(MatSort, { static: false }) set sortOrder(sort: MatSort) {
    if (sort) {
      this.dataSource.sort = sort;
    }
  }
  /** To paginate in a mat table */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  /** initially Displaying Columns */
  displayedColumns: string[] = ['clientNumber', 'clientName', 'clientStatus', 'clientStatusDate', 'vendorType', 'candidateId',
    'candidateName', 'candidateAuthDate', 'candidateStatus', 'candidateStatusDate', 'departureCity', 'departureState', 'destinationCity',
    'destinationState', 'clientInvoiceNumber', 'clientInvoiceDate', 'clientInvoiceTotal', 'taxAssistance', 'vendorNumber', 'vendorSiteId',
    'vendorName', 'invoiceNumber', 'invoiceDate', 'invoiceAmount', 'datetimeSentToOracle', 'auditStatus', 'cashOutDate',
    'vanlineQuotedAmount', 'coreServiceAmount', 'flexibleSpendAmount', 'vanLinePayout', 'commission', 'insurance', 'eePayout',
    'estimatedWeight', 'estimatedDistance'
  ];
  /** Used to store Movement Approved Candidate list */
  ELEMENT_DATA: any[] = [];
  /** Used to Store Instance of MatTableDataSource contains Candidate data */
  dataSource: any;
  /** Used to store filter value */
  filterText = '';
  /** Stores the selected Columns */
  columnList: Selection[] = [];
  /** Input prop for receiving data*/
  selection = new SelectionModel<ApprovedMove>(true, []);
  /** To unsubscribe the service */
  subscription: Subscription = new Subscription();
  /** overlayRef to hold overlay config */
  overlayRef: OverlayRef;
  /**property to hold active move phase label */
  activeLabel: string;
  /** Used for sorting */
  sortBy = '';
  /** sorting direction */
  sortDir = '';
  /**variable to store client contact id */
  clientContactId: string;
  /**page information */
  pageInfo = {
    pageSize: 20,
    pageIndex: 0,
    totalCount: 0
  };
  /**
   * Heading to be focused first
   */
  /**
   * Initializes the value
   * @param dialog Instance for MatDialog
   * @param aprovedMovesService Instance for ApprovedMovesService
   * @param spinner Object for Spinner
   * @param datePipe object for DatePipe
   * @param snackBar Object for Snackbar
   * @param partySharedSvc Object for PartySharedService
   */
  constructor(
    public dialog: MatDialog,
    private readonly aprovedMovesService: ApprovedMovesService,
    private readonly spinner: NgxSpinnerService,
    private readonly datePipe: DatePipe,
    public readonly snackBar: MatSnackBar,
    private readonly partySharedSvc: PartySharedService,
    private readonly userPreference: UserPreferencesService,
    private readonly formBuilder: UntypedFormBuilder
  ) {
    this.financeAuditExportForm = this.formBuilder.group({
      startDate: [
        '',
        []
      ],
      endDate: [
        '',
        []
      ]
    }, { validators: [DateValidator.dateComparison] });
  }

  /**
   * It is for Initializing during Page Load
   */
  ngOnInit() {
    this.partySharedSvc.getPartyId().then(id => {
      if (id) {
        this.loadFinanceExport();
      }
    });
    this.userPreference.getPreference('startdate', false).subscribe(response => {
      if (response) {
        this.financeAuditExportForm
          .get('startDate')
          .setValue(new Date(response));
      }
    });
    this.userPreference.getPreference('enddate', false).subscribe(response => {
      if (response) {
        this.financeAuditExportForm
          .get('endDate')
          .setValue(new Date(response));
      }
    });
    this.aprovedMovesService.counter--;
  }

  setValidation(controlName) {
    this.financeAuditExportForm.controls[controlName].markAsDirty();
    this.financeAuditExportForm.controls[controlName].updateValueAndValidity();
  }

  loadFinanceExport() {
    this.spinner.show();
    this.aprovedMovesService.counter++;
    try {
      this.aprovedMovesService.getFinanceExports(this.getQueryString()).subscribe(response => {
        if (response && response.status === 204) {
          this.ELEMENT_DATA = [];
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          this.aprovedMovesService.counter--;
        } else if (response && response.status === 200 && response.body) {
          this.ELEMENT_DATA = response.body.data;
          this._setUserPreference();
          console.log('Expected Approved Moves :', response.body.data);
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          this.dataSource.sort = this.sortOrder;
          setTimeout(() => {
            this.paginator.length = Number(response.body.count);
            this.dataSource.paginator = this.paginator;
          });
          this.aprovedMovesService.counter--;
        } else {
          this.aprovedMovesService.counter--;
          this.snackBar.open(
            apiErrorMessage,
            undefined,
            { duration: 5000 }
          );
        }
      });
    } catch (err) {
      this.aprovedMovesService.counter--;
      this.snackBar.open(
        apiErrorMessage,
        undefined,
        { duration: 5000 }
      );
    }
  }

  private _setUserPreference() {
    if (this.financeAuditExportForm.controls['startDate'].value) {
      const startDate: UserPreference = {
        Key: 'startdate',
        Value: this.financeAuditExportForm.controls['startDate'].value,
        Persist: false
      };
      this.userPreference.setPreference(startDate);
    }
    if (this.financeAuditExportForm.controls['endDate'].value) {
      const endDate: UserPreference = {
        Key: 'enddate',
        Value: this.financeAuditExportForm.controls['endDate'].value,
        Persist: false
      };
      this.userPreference.setPreference(endDate);
    }
  }

  getQueryString(): string {
    const _queryString = {};
    if (this.financeAuditExportForm.controls['startDate'].value) {
      _queryString['fromDate'] = this.datePipe.transform(this.financeAuditExportForm.controls['startDate'].value, 'yyyy-MM-dd');
    }
    if (this.financeAuditExportForm.controls['endDate'].value) {
      _queryString['toDate'] = this.datePipe.transform(this.financeAuditExportForm.controls['endDate'].value, 'yyyy-MM-dd');
    }
    return querystring.stringify(_queryString);
  }

  filterData() {
    if (this.ELEMENT_DATA && this.dataSource && this.dataSource.length > 0
      && this.financeAuditExportForm.controls['startDate'].value
      && this.financeAuditExportForm.controls['endDate'].value
      && this.financeAuditExportForm.controls['startDate'].value <= this.financeAuditExportForm.controls['endDate'].value) {
      const startDate = this.financeAuditExportForm.controls['startDate'].value;
      const endDate = this.financeAuditExportForm.controls['endDate'].value;
      const filteredData = this.dataSource.filter(item => item.clientInvoiceDate >= startDate && item.clientInvoiceDate <= endDate);
      this.dataSource = new MatTableDataSource(filteredData);
    }
  }

  /**unsubscribe the subscriptions */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
