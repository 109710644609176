<div class="html-tooltip {{tooltTipPos}}"
role="tooltip" aria-modal="true" aria-labelledby="tooltip-heading" cdkTrapFocus cdkTrapFocusAutoCapture>
    <div *ngIf="data !== undefined">
        <div class="title">
            <h1 class="cdk-visually-hidden" tabindex="0" id="tooltip-heading">{{data.dataObj.budgetName}}</h1>
            <a href="#" title="close" (click)="close($event)">
                <mat-icon matSuffix class="icon-button">close</mat-icon>
            </a>
        </div>
        <div class="content">
            <p class="description">{{displayObject.budgetDesc}}</p>
            <div *ngIf="displayRange" class="range">
                <span>Range:</span>
                <span class="min">{{displayObject.budgetRangeMin | number }}</span>
                <span> - </span>
                <span class="max">{{displayObject.budgetRangeMax | number }}</span>
                <span>USD</span>
            </div>
        </div>
        <span class="arrow"></span>
    </div>
</div>