import { Component, OnInit, Inject, ElementRef, AfterViewInit,
  ViewEncapsulation, Renderer2, HostBinding, ApplicationRef, OnDestroy } from '@angular/core';
import { ModalData } from '../../../core/services/popup-position.service';
/** html tooltip to be called on click */
@Component({
  selector: 'app-html-tooltip',
  templateUrl: './html-tooltip.component.html',
  styleUrls: ['./html-tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HtmlTooltipComponent implements OnInit, AfterViewInit, OnDestroy {
  /** Decide if range is to display */
  displayRange: boolean;

  /** To set display type of tooltip */
  displayObject: any;

  /** Tooltip position for the field */
  tooltTipPos = '';

  /** tooltip icon active class */
  tooltipIconActive = 'active';

  @HostBinding('class') class = 'app-html-tooltip';


  /** Base constructor method
   * @param data to get the data through ModalData
   * @param myElement to get element reference
   */
  constructor(
    @Inject(ModalData) public data: any,
    private readonly myElement: ElementRef,
    private readonly renderer: Renderer2,
    private readonly appRef: ApplicationRef
  ) { }

  /** function to be called on init of component */
  ngOnInit() {
    this.renderer.setAttribute(this.appRef.components[0].injector.get(ElementRef).nativeElement,
    'aria-hidden', 'true');
    if (this.data.dataObj.budgetName === 'Storage') {
      this.data.keyString = 'flex';
    }
    if (this.data) {
      if (this.data.keyString === 'flexBudget') {
        this.displayRange = true;
      } else {
        this.displayRange = false;
      }
      this.displayObject = this.data.dataObj;
    } else {
      this.displayObject = {};
    }
  }

  /** function to be called after view */
  ngAfterViewInit() {
    setTimeout(() => {
      this.setTooltipPosition();
    }, 200);
  }

  /** To close the dialog */
  close($event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();
    $event.stopImmediatePropagation();
    this.data.overlayRef.dispose();
    this.renderer.removeClass(this.data.targetElem, this.tooltipIconActive);
    this.renderer.removeAttribute(this.appRef.components[0].injector.get(ElementRef).nativeElement,
    'aria-hidden');
  }

  /** To calculate tooltip position */
  setTooltipPosition(): void {
    this.tooltTipPos = '';
    const yPos: number = this.data.clientY - this.myElement.nativeElement.offsetParent.offsetTop;
    const xPos: number = this.data.clientX - this.myElement.nativeElement.offsetParent.offsetLeft;
    if (yPos <= 5 && xPos > 0) {
      this.tooltTipPos = ' bottom';
    }
    if (yPos >= 0 && xPos <= 5) {
      this.tooltTipPos = ' right';
    }
  }

  /** function to be called on destory of component */
  ngOnDestroy(): void {
    this.renderer.removeAttribute(this.appRef.components[0].injector.get(ElementRef).nativeElement,
    'aria-hidden');
  }
}
