import { NgModule, InjectionToken } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot } from '@angular/router';
import { ExternRouteComponent } from './public/project-alpha/extern-route/extern-route.component';
import { LogoutComponent } from './public/project-alpha/logout/logout.component';
import { PrivacyNoticeComponent } from './public/privacy-notice/privacy-notice.component';
import { NonCompatibleComponent } from './public/non-compatible/non-compatible.component';
import { ContactUsComponent } from './public/contact-us/contact-us.component';
import { LoginComponent } from './public/login/login.component';
const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'project-alpha/cartus-employee',
    loadChildren: () =>
      import('./public/project-alpha/project-alpha.module').then(
        m => m.ProjectAlphaModule
      )
  },
  {
    path: 'project-alpha/client-contacts',
    loadChildren: () =>
      import('./public/project-alpha/project-alpha.module').then(
        m => m.ProjectAlphaModule
      )
  },
  {
    path: 'project-alpha/consultants',
    loadChildren: () =>
      import('./public/project-alpha/project-alpha.module').then(
        m => m.ProjectAlphaModule
      )
  },
  {
    path: 'project-alpha/account-manager',
    loadChildren: () =>
      import('./public/project-alpha/project-alpha.module').then(
        m => m.ProjectAlphaModule
      )
  },
  {
    path: 'project-alpha/callsummarizer',
    loadChildren: () =>
      import('./public/project-alpha/project-alpha.module').then(
        m => m.ProjectAlphaModule
      )
  },
  {
    path: 'project-alpha/teams',
    loadChildren: () =>
      import('./teams/teams.module').then(
        m => m.TeamsModule
      )
  },
  {
    path: 'project-alpha/rolecaps',
    loadChildren: () =>
      import('./rolecaps/rolecaps.module').then(
        m => m.RolecapsModule
      )
  },
  {
    path: 'project-alpha/user-alignment',
    loadChildren: () =>
      import('./user-alignment/user-alignment.module').then(
        m => m.UserAlignmentModule
      )
  },
  {
    path: 'externalRedirect',
    resolve: {
      url: externalUrlProvider
    },
    component: ExternRouteComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'privacy-statement',
    component: PrivacyNoticeComponent
  },
  {
    path: 'terms-of-use',
    component: PrivacyNoticeComponent
  },
  {
    path: 'contact-us',
    component: ContactUsComponent
  },
  {
    path: 'not-supported',
    component: NonCompatibleComponent
  }
];

@NgModule({
  providers: [
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalURL = route.paramMap.get('externalUrl');
        window.location.replace(externalURL);
      }
    }
  ],
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
