import { Component, HostBinding, OnInit, ViewEncapsulation, ViewChild, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { RoleCapabilityService } from '../../../services/role-capability.service';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { RolecapsAddEditCapComponent } from '../rolecaps-add-edit-cap/rolecaps-add-edit-cap.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { CapabilityDefinition } from 'src/app/rolecaps/models/definition.model';
import { firstValueFrom, from, lastValueFrom, mergeMap, Subscription, tap } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/core/components/shared/confirm-dialog/confirm-dialog.component';
import { CAPS_APPS_MAP_KEY, CAPS_KEY, CONCURRENT_REQUESTS, GET_CAPABILITY_DEFINITIONS_ERROR, GET_CAP_APP_MAP_ERROR,
         GET_CAP_KEY_ERROR, GET_ROLECAPS_ERROR, REMOVE_CAP_DIALOG_TITLE, REMOVE_CAP_DIALOG_LINES, REMOVE_CAPABILITY_ERROR,
         REMOVE_CAPS_SUCCESS } from 'src/app/rolecaps/models/constants';
import * as querystring from 'querystring';

@Component({
  selector: 'app-rolecaps-capability-list',
  templateUrl: './rolecaps-capability-list.component.html',
  styleUrls: ['./rolecaps-capability-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class RolecapsCapabilitiesListComponent implements OnInit, AfterContentChecked {

  /** Component css class */
  @HostBinding('class') class = 'rolecaps-capabilities-list';

  /** To check for readonly access */
  disable = false;

  /** Processing  */
  processing = false;
  /** Property to indicate if the remove checkbox has selected  */
  disableRemoveCheckBoxes = false;

  /** Property to indicate if the component data has loaded */
  loaded = false;

  /** Table data */
  ELEMENT_DATA: Array<CapabilityDefinition> = [];

  /** Table datasource */
  dataSource: MatTableDataSource<CapabilityDefinition> = new MatTableDataSource(this.ELEMENT_DATA);

  /** Displayed table columns */
  displayedColumns: string[] = [
    'remove',
    'capabilityName',
    'description',
    'application',
    'actions'
  ];

  /** Table Pagination */
  pageInfo = {
    itemCount: 0,
    currentPage: 0,
    perPage: 5,
    perPageOptions: [5, 10, 15]
  };

  /** Initial table sort by column */
  sortBy = 'capabilityName';

  /** Initial table sort direction */
  sortDir = 'asc';

  /** Search filter value */
  filterText = '';

  /** Selected Roles */
  selection = new SelectionModel<CapabilityDefinition>(true, []);

  /** Service subscription  */
  private readonly subscription: Subscription = new Subscription();

  /** Dialog Ref */
  dialogRef: MatDialogRef<any>;

  /** Injecting dependencies */
  constructor(
    private readonly roleCapabilityService: RoleCapabilityService,
    private readonly notificationsService: NotificationsService,
    private readonly loggerService: LoggerService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) { }

  /** To sort the mat table columns */
  @ViewChild(MatSort, { static: false }) set sortOrder(sort: MatSort) {
    if (sort) {
      this.dataSource.sort = sort;
    }
  }

  /** To paginate in a mat table */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  /** To initialize the component */
  ngOnInit() {
    this.loadCaps();
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  /** Load capabilities data */
  loadCaps() {
    this.processing = true;
    this.roleCapabilityService.getCapabilityDefinitions().subscribe({
      next:response => {
      if (response && response.status === 204) {
        this.ELEMENT_DATA = [];
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      } else if (response && response.status === 200 && response.body) {
        this.ELEMENT_DATA = response.body;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      } else {
        this.notificationsService.flashNotification('danger', GET_CAPABILITY_DEFINITIONS_ERROR);
      }
    },
    error: err => {
      this.loggerService.error('Failed to get Capabilities', err);
      this.notificationsService.flashNotification('danger', GET_CAPABILITY_DEFINITIONS_ERROR);
    },
    complete:() => {
      this.dataSource.paginator = this.paginator;
      this.pageInfo.itemCount = this.ELEMENT_DATA.length;
      this.dataSource.filterPredicate = this.customFilterPredicate;
      this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
      this.processing = false;
    }
    });
  }

  /**
   * Table filtration
   * @param filterValue string
   */
  applyFilter(filterValue: string) {
    this.filterText = filterValue.trim();
    this.dataSource.filter = filterValue.trim();
    if (filterValue.trim().length === 0) {
      this.selection.clear();
      this.disableRemoveCheckBoxes = false;
    }
  }

  /**
   * Custom table column filtration
   * @param data CapDefinition
   * @param filter string
   */
  customFilterPredicate(data: CapabilityDefinition, filter: string): boolean {
    const searchTerm = filter.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const dataStr = `|${data.capabilityName}|`;
    return dataStr.search(new RegExp(searchTerm, 'gi')) !== -1;
  }

  /** Sorting accessor */
  sortingDataAccessor = (item: CapabilityDefinition, property: string) => {
    switch (property) {
    case 'capabilityName': return item.capabilityName ? (item.capabilityName.charAt(0).toUpperCase() + item.capabilityName.slice(1)) : '';
    case 'application': return item.application ? (item.application.charAt(0).toUpperCase() + item.application.slice(1)) : '';
    // case 'description': return item.description ? (item.description.charAt(0).toUpperCase() + item.description.slice(1)) : '';
    default: return item[property].toLocaleLowerCase();
      }
  };

  /**
   * Table Pagination
   * @param event PageEvent
   */
  onPagination(event: PageEvent) {
    this.pageInfo.perPage = event.pageSize;
  }

  /**
   * To check for readonly access
   * @param flag boolean
   */
  isDisabled(flag: boolean) {
    this.disable = flag;
  }

  /**
   * To clear the selection model
   */
  cancel() {
    this.selection.clear();
    this.disableRemoveCheckBoxes = false;
  }

    /**
   * Change event logic to select only one checkbox in remove column
   */
    selectRemoveCheckBox($event, dataSource) {
      if (this.selection.selected.length === 0) {
        this.disableRemoveCheckBoxes = true;
      } else {
      this.disableRemoveCheckBoxes = false;
      }
    }

  /**
   * Remove capability confirmation
   */
  openRemoveCapabilityConfirm() {
    const dialogLines = [...REMOVE_CAP_DIALOG_LINES];
    dialogLines.push(this.selection.selected.map(item => item.capabilityName).join('<br />'));
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      panelClass: 'dialogMainContainer',
      data: { dialogTitle: REMOVE_CAP_DIALOG_TITLE, dialogLines },
      autoFocus: false,
      restoreFocus: false
    });
    this.subscription.add(
      this.dialogRef.afterClosed().subscribe(async (result: boolean) => {
        if (result) {
          this.processing = true;
          const requests = [];
          let allResults = [];
          const allErrors = [];
          // get role capabilities call
          const queryString = {};
          queryString['capabilityName'] = this.selection.selected[0].capabilityName;
          await firstValueFrom(this.roleCapabilityService.getRoleCapabilities(querystring.stringify(queryString))).then(async response => {
          if ((response && response.status === 200 && response.body.length > 0) || (response.toString().indexOf('Role Capability not found.') > -1)) {
            requests.push(this.roleCapabilityService.deleteDefinition(this.selection.selected[0]._id));
              if (response.body) {
                const getRoleCaps = response.body;
                getRoleCaps.forEach(roleCap => {
                  let capabilityFromRoleCap = roleCap.capabilities;
                  let roleCapIndex: number = capabilityFromRoleCap.findIndex(value => value.name === this.selection.selected[0].capabilityName);
                  if (roleCapIndex !== -1) capabilityFromRoleCap.splice(roleCapIndex, 1);
                  roleCap.capabilities = capabilityFromRoleCap;
                  roleCap.__v = roleCap.__v ? roleCap.__v : 0;
                  // Updating role capabilities in role capabilities collection
                  requests.push(this.roleCapabilityService.updateRoleCapability(roleCap._id, roleCap));
                });
              }
              const queryStringCapsAppMap = {};
              queryStringCapsAppMap['key'] = CAPS_APPS_MAP_KEY;
              // get value list call using querystring as CapabilitiesApplicationsMap
              await lastValueFrom(this.roleCapabilityService.getValuelist(querystring.stringify(queryStringCapsAppMap))).then(resp => {
                if (!resp || (resp && resp.status !== 200)) {
                  throw new Error(GET_CAP_APP_MAP_ERROR);
                } else {
                  const capsAppsMap = resp.body;
                  const capsAppsMapId = capsAppsMap._id;
                  delete capsAppsMap._id;
                  delete capsAppsMap.dataType;
                  let foundIndex: number = capsAppsMap.values.findIndex(value => value.Capabilities === this.selection.selected[0].capabilityName);
                  if (foundIndex !== -1)  capsAppsMap.values.splice(foundIndex, 1);
                  const values = [...capsAppsMap.values].sort((a, b) => a.Capabilities.toLocaleLowerCase()
                    .localeCompare(b.Capabilities.toLocaleLowerCase()));
                  capsAppsMap.values = values;
                  // removing capability name object for level core and updating value list based on key as CapabilitiesApplicationsMap
                  requests.push(this.roleCapabilityService.updateValuelist(capsAppsMapId, capsAppsMap));
                }
              });
              // get value list call using querystring as capabilities
              const queryStringValueList = {};
              queryStringValueList['key'] = CAPS_KEY;  
              await firstValueFrom(this.roleCapabilityService.getValuelist(querystring.stringify(queryStringValueList))).then(resp => {
                if (!resp || (resp && resp.status !== 200)) {
                  throw new Error(GET_CAP_KEY_ERROR);
                } else {
                  const capsKeyRes = resp.body;
                  const capsKeyResId = capsKeyRes._id;
                  delete capsKeyRes._id;
                  let foundIndex: number = capsKeyRes.values.findIndex(value => value === this.selection.selected[0].capabilityName);
                  if (foundIndex !== -1)  capsKeyRes.values.splice(foundIndex, 1);
                  const values = [...capsKeyRes.values].sort((a, b) => a.toLocaleLowerCase()
                    .localeCompare(b.toLocaleLowerCase()));
                    capsKeyRes.values = values;
                  // updating value list based on key as capabilities
                  requests.push(this.roleCapabilityService.updateValuelist(capsKeyResId, capsKeyRes));
                }
              });                              
            } else {
              throw new Error(GET_ROLECAPS_ERROR);
            }
          });
          from(requests)
          .pipe(mergeMap(observable => observable, CONCURRENT_REQUESTS), tap(() => {}))
          .subscribe({
            next: partialResults => {
              allResults = allResults.concat(partialResults);
            },
            error: error => {
              this.loggerService.error('Failed to delete capability', error);
              this.notificationsService.flashNotification('danger', REMOVE_CAPABILITY_ERROR);
              this.disableRemoveCheckBoxes = false;
            },
            complete: () => {
              allResults.forEach(result => {
                const statusCodeAcceptanceForDelete = [200, 204];
                if (result && !statusCodeAcceptanceForDelete.includes(result.status)) {
                  allErrors.push(result);
                }
              });
              if (allErrors.length === 0) {
                this.filterText = '';
                this.selection.clear();
                this.loadCaps();
                this.disableRemoveCheckBoxes = false;
                this.notificationsService.flashNotification('success', REMOVE_CAPS_SUCCESS);
              } else {
                this.notificationsService.flashNotification('danger', REMOVE_CAPABILITY_ERROR);
                this.disableRemoveCheckBoxes = false;
              }
              this.processing = false;
            }
          });
        }
      })
    );
  }

  /**
   * Add Role Dialog
   */
  openAddCapabilityDialog() {
    this.dialogRef = this.dialog.open(RolecapsAddEditCapComponent, {
      disableClose: true,
      panelClass: 'dialog-main',
      data: {},
      autoFocus: false,
      restoreFocus: false
    });
    this.subscription.add(
      this.dialogRef.afterClosed().subscribe((capability: CapabilityDefinition) => {
        if (capability) {
          this.filterText = '';
          this.selection.clear();
          this.disableRemoveCheckBoxes = false;
          this.loadCaps();
        }
      })
    );
  }

  /**
   * Edit Capability Dialog
   */
  openEditCapsDialog(capability: CapabilityDefinition) {
    this.dialogRef = this.dialog.open(RolecapsAddEditCapComponent, {
      disableClose: true,
      panelClass: 'dialog-main',
      data: { capability },
      autoFocus: false,
      restoreFocus: false
    });
    this.subscription.add(
      this.dialogRef.afterClosed().subscribe((updatedCap: CapabilityDefinition) => {
        if (updatedCap) {
          this.filterText = '';
          this.selection.clear();
          this.disableRemoveCheckBoxes = false;
          this.loadCaps();
        }
      })
    );
  }

}
