<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>

<!--Section To Candidate Profiles-->
<div class="budget-container transferee" *ngIf="candidateBudgetDetails">
  <div class="budget-content-container">
    <div class="candidateprofile-nav">
      <a [routerLink]="['/project-alpha/finance-audit']">Authorized Transferees</a><span
        class="current">{{ candidateName }}</span>
    </div>

    <app-candidate-contact-info *ngIf="candidateContactInfo" [candidateContactInfo]="candidateContactInfo">
    </app-candidate-contact-info>

    <mat-accordion class="candidate-review-panel candidate-info-panel" multi="true">
      <!--Candidate Details starts-->
      <mat-expansion-panel class="candidate-detail" tabindex="0">
        <mat-expansion-panel-header [collapsedHeight]="matExpansionHeaderHeight"
          [expandedHeight]="matExpansionHeaderHeight">
          <span class="panel-heading">Transferee Details</span>
        </mat-expansion-panel-header>
        <mat-panel-description>
          <app-candidate-info *ngIf="candidateInfo" [candidateInfo]="candidateInfo" class="full-stretch">
          </app-candidate-info>
        </mat-panel-description>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion class="candidate-review-panel" multi="true">
      <!--Move Details starts-->
      <mat-expansion-panel tabindex="0" aria-label="Finance Details" role="Financial Data">
        <mat-expansion-panel-header fxLayout fxLayout.xs="row" fxLayoutGap="10px" aria-label="Finance Details">
          <div fxFlex="34%" class="panel-heading">Finance Details</div>
        </mat-expansion-panel-header>
        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0"
          aria-label="Investment details">
          <div fxFlex="40%" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-card class="investment-card" aria-label="Investmet Data">
              <mat-card-header>
                <mat-card-title>Total Investment : {{
                  formatTotalAmount(candidateBudgetDetails.approvedMoves.authorizedAmt)
                }}</mat-card-title>
              </mat-card-header>
              <!-- <mat-card-content > -->
              <app-budget-chart class="candidate-budget-chart" *ngIf="budgetDetailsService"
                [serviceDetails]="budgetDetailsService">
              </app-budget-chart>
              <!-- </mat-card-content> -->
              <mat-card-actions>
                <div fxFlex="50%" fxFlex.xs="100%" class="detail-box">
                  <div class="tile-flex">
                    <div class="box-fields">
                      <div>
                        Core Services
                        <br>
                        {{formatTotalAmount(candidateBudgetDetails.coreBudgetTotal)}}
                      </div>
                    </div>
                  </div>
                </div>
                <div fxFlex="50%" fxFlex.xs="100%" class="detail-box">
                  <div class="tile-flex">
                      <div class="box-fields">
                          <div>
                            Flex Services
                          <br>
                          {{formatTotalAmount(roundofAmount(candidateBudgetDetails.approvedMoves.authorizedAmt) - roundofAmount(candidateBudgetDetails.coreBudgetTotal))}}
                          </div>
                      </div>
                    </div>
                </div>
              </mat-card-actions>
            </mat-card>
          </div>
          <div fxFlex="60%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%" class="finance-row">
            <mat-card class="details-card">
              <mat-card-content>
                <div fxLayout fxLayout.xs="row" fxLayoutGap="10px">
                  <div fxFlex="70%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                    Type of Move
                  </div>
                  <div fxFlex="30%" fxLayoutAlign="end" fxLayout.xs="column" fxFlex.xs="100%">
                    {{candidateBudgetDetails['moveType'] | titlecase}}
                  </div>
                </div>
                <div fxLayout fxLayout.xs="row" fxLayoutGap="10px">
                  <div fxFlex="70%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                    Vendor Gross Quote
                  </div>
                  <div fxFlex="30%" fxLayoutAlign="end" fxLayout.xs="column" fxFlex.xs="100%">
                    {{formatTotalAmount(candidateBudgetDetails.coreBenefits['bidAmount'])}}
                  </div>
                </div>
                <hr>
                <div fxLayout fxLayout.xs="row" fxLayoutGap="10px">
                  <!-- commented out as part of MOV360-26, Substituted with lines below. -->

                  <!-- <div fxFlex="60%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%"> -->
                  <!-- Professional Van Line Move Total <mat-icon class="invoice-info-icon" (mouseover)="hover=true" -->
                  <!-- (mouseleave)="hover=false" (click)="openVanlineModal($event)" -->
                  <!-- [ngStyle]="{'color': hover==true  ? '#002F82' : '#707070', 'margin-left':'3px'}">info</mat-icon> -->
                  <!-- </div> -->

                  <!-- Substituted lines below -->
                  <div fxFlex="60%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                    Professional Van Line Move Total <mat-icon class="invoice-info-icon"
                       (click)="openVanlineModal($event)"
                      [ngStyle]="{'color': '#707070', 'margin-left':'3px'}">info</mat-icon>
                  </div>
                  <div fxFlex="40%" fxLayoutAlign="end" fxLayout.xs="column" fxFlex.xs="100%">
                    {{formatTotalAmount(candidateBudgetDetails['totalVanlineCost'])}}
                  </div>
                </div>
                <hr>
                <div fxLayout fxLayout.xs="row" fxLayoutGap="10px">
                  <div fxFlex="60%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                    Insurance
                  </div>
                  <div fxFlex="40%" fxLayoutAlign="end" fxLayout.xs="column" fxFlex.xs="100%">
                    {{formatTotalAmount(candidateBudgetDetails['insuranceAmount'])}}
                  </div>
                </div>
                <hr>
                <div fxLayout fxLayout.xs="row" fxLayoutGap="10px">
                  <div fxFlex="60%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                    Cartus Commission
                  </div>
                  <div fxFlex="40%" fxLayoutAlign="end" fxLayout.xs="column" fxFlex.xs="100%">
                    {{formatTotalAmount(candidateBudgetDetails['cartusCommission'])}}
                  </div>
                </div>
                <hr>
                <div fxLayout fxLayout.xs="row" fxLayoutGap="10px">
                  <div fxFlex="70%" fxLayoutAlign="end" fxLayout.xs="column" fxFlex.xs="100%">
                    Total:
                  </div>
                  <div fxFlex="30%" fxLayoutAlign="end" fxLayout.xs="column" fxFlex.xs="100%">
                    {{formatTotalAmount(candidateBudgetDetails.coreBudgetTotal)}}
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0"
          class="transportation-section">
          <div fxFlex="13%" fxFlex.xs="100%" class="tile-box">
            <div class="box-fields">
              <mat-form-field class="odd-form-fields">
                <mat-label>Estimated Weight:</mat-label>
                <input class="totalPeople" matInput placeholder="0" readonly
                  [value]="candidateBudgetDetails.estimatedWeight">
              </mat-form-field>
            </div>
          </div>
          <div fxFlex="13%" fxFlex.xs="100%" class="tile-box even-box">
            <div class="box-fields">
              <mat-form-field>
                <mat-label>Actual Weight:</mat-label>
                <input class="totalPeople" matInput placeholder="Total People Moving" readonly
                  [value]="candidateBudgetDetails.actualWeight">
              </mat-form-field>
            </div>
          </div>
          <div fxFlex="13%" fxFlex.xs="100%" class="tile-box">
            <div class="box-fields">
              <mat-form-field>
                <mat-label>Estimated Mileage:</mat-label>
                <input class="totalPeople" matInput placeholder="Total People Moving" readonly
                  [value]="candidateBudgetDetails.estimatedDistance">
              </mat-form-field>
            </div>
          </div>
          <div fxFlex="13%" fxFlex.xs="100%" class="tile-box even-box">
            <div class="box-fields">
              <mat-form-field>
                <mat-label>Actual Mileage:</mat-label>
                <!-- commented out as part of MOV360-26. -->
                <!-- <input class="totalPeople" matInput placeholder="Total People Moving" readonly -->
                <!-- [value]="candidateBudgetDetails.actualMileage"> -->
                <!-- Substituted lines below -->
                <input class="totalPeople" matInput placeholder="Total People Moving" readonly>
              </mat-form-field>
            </div>
          </div>
          <div fxFlex="20%" fxFlex.xs="100%" class="tile-box">
            <div class="box-fields">
              <mat-form-field>
                <mat-label>Estimated Delivery:</mat-label>
                <input class="totalPeople" matInput placeholder="Total People Moving" readonly
                  [value]="deliveryDateString">
              </mat-form-field>
            </div>
          </div>
          <div fxFlex="13%%" fxFlex.xs="100%" class="tile-box">
            <div class="box-fields">
              <mat-form-field>
                <mat-label>Actual Delivery:</mat-label>
                <input class="totalPeople" matInput placeholder="Total People Moving" readonly [value]="0">
              </mat-form-field>
            </div>
          </div>
        </div>
        <mat-panel-description class="remove-margin full-stretch" fxLayout="column" fxLayoutGap="16px">
        </mat-panel-description>
      </mat-expansion-panel>
    </mat-accordion>
    <br>
    <div tabindex="0" role="table" aria-label="Invoice Details">
      Invoice Details:
      <table *ngIf="!showInvoice" class="mat-table">
        <tr class="mat-row">
          <td class="mat-cell-none">None</td>
          <td class="mat-cell-button">
            <button class="contained-button clickable" (click)="open()">Add</button>
          </td>
        </tr>
      </table>
      <!-- <table *ngIf="showInvoice">
      <thead>
        <tr>
        <th>Vendor</th>
        <th>Invoice</th>
        <th>Amount</th>
        <th>Invoice Date</th>
        <th>Actual Weight</th>
        <th>Actual Mileage</th>
        <th>Status</th>
        </tr>
        </thead>
        <tbody>
        <tr>
        <td>Atlas Van Lines</td>
        <td> {{ this.supplierInvoiceData.invoiceId }}</td>
        <td> {{ this.supplierInvoiceData.amount }} </td>
        <td> {{ this.supplierInvoiceData.invoiceDate }} </td>
        <td> {{ this.supplierInvoiceData.actualWeight }} </td>
        <td> {{ this.supplierInvoiceData.actualMileage }}</td>
        <td> {{ this.supplierInvoiceData.status  }} </td>
        </tr>
        </tbody>
    </table> -->
      <table mat-table [dataSource]="dataSource" *ngIf="showInvoice" class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

        <!-- Vendor Column Column -->
        <ng-container matColumnDef="vendorName">
          <th mat-header-cell *matHeaderCellDef> Vendor Name</th>
          <td mat-cell *matCellDef="let element"> Atlas Van Lines </td>
        </ng-container>

        <!-- Invoice Id Column -->
        <ng-container matColumnDef="invoiceId">
          <th mat-header-cell *matHeaderCellDef> Invoice # </th>
          <td mat-cell *matCellDef="let element"> {{element.invoiceId}} </td>
        </ng-container>

        <!-- Amount Column -->
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef> Amount </th>
          <td mat-cell *matCellDef="let element"> {{element.amount}} </td>
        </ng-container>

        <!-- Invoice Date Column -->
        <ng-container matColumnDef="invoiceDate">
          <th mat-header-cell *matHeaderCellDef> Invoice Date </th>
          <td mat-cell *matCellDef="let element"> {{element.invoiceDate}} </td>
        </ng-container>

        <!-- Actual Weight Column -->
        <ng-container matColumnDef="actualWeight">
          <th mat-header-cell *matHeaderCellDef> Actual Weight </th>
          <td mat-cell *matCellDef="let element"> {{element.actualWeight}} </td>
        </ng-container>

        <!-- Actual Mileage Column -->
        <ng-container matColumnDef="actualMileage">
          <th mat-header-cell *matHeaderCellDef> Actual Mileage </th>
          <td mat-cell *matCellDef="let element"> {{element.actualMileage}} </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element !== undefined">
              <span *ngIf="element.auditStatus === 'Draft Saved'" (keyup)="onKeyUp($event)" tabindex="0"
                class="clickable" (click)="open()">
                <a class="text-as-link" [innerHTML]="element.auditStatus"></a>
              </span>
              <span *ngIf="element.auditStatus !== 'Draft Saved'">
                <div [innerHTML]="element.auditStatus"></div>
              </span>
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
