<section class="">
    <h2>Dashboard</h2>
    
    <mat-grid-list cols="6" rowHeight="150px" gutterSize="10">
        <mat-grid-tile
            *ngFor="let tile of cards">

            <mat-card class="example-card">
                <mat-card-header>
                  <mat-card-title><h1>{{tile.number}}</h1></mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <p>
                      {{tile.title}}
                  </p>
                </mat-card-content>
              </mat-card>

        </mat-grid-tile>
      </mat-grid-list>
  
  </section>