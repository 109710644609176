import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { Subscription, interval, Observable } from 'rxjs';
import { Pipe, PipeTransform } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoggerService } from '../../../core/services/logger.service';
import { UserContextService } from '../../../core/services/user-context.service';

@Component({
  selector: 'app-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SessionTimeoutComponent implements OnInit, OnDestroy {
  countDown: Subscription;
  counter = 120;
  tick = 1000;
  timer$: Observable<number>;
  constructor(private ref: ChangeDetectorRef,
    private _snackBar: MatSnackBar,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: UserContextService
  ) { }

  ngOnInit() {
    this.countDown = interval(this.tick).subscribe(() => {
      --this.counter;
      this.ref.detectChanges();
    });
    this.loggedInUserService.getLoggedInUserDetails().subscribe(response => {
      const userId: any = response.userId.replace(/ .*/, '');
      this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-SESSION_TIMEOUT', 'SESSION_TIMEOUT');
    });
  }

  dismiss() {
    this._snackBar.dismiss();
  }

  ngOnDestroy() {
    this.countDown.unsubscribe();
    this.countDown = null;
  }

}

@Pipe({
  name: 'formatTime'
})
export class FormatTimePipe implements PipeTransform {
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return (
      ('00' + minutes).slice(-2) +
      ':' +
      ('00' + Math.floor(value - minutes * 60)).slice(-2)
    );
  }
}
