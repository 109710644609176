/** MOV360-880 - to be removed at future date */
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AddEditDailyOperationsComponent } from '../../../suppliers/add-edit-daily-operations/add-edit-daily-operations.component';
import { MatDialog } from '@angular/material/dialog';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { SupplierContractService } from 'src/app/core/services/supplier-contract.service';

@Component({
  selector: 'app-daily-operation-info',
  templateUrl: './daily-operation-info.component.html',
  styleUrls: ['./daily-operation-info.component.scss']
})
export class DailyOperationInfoComponent {
  @Input() contractIndex: number;
  @Input() dailyOperationsData: any;
  isEmptyDailyOperationInfo = true;
  @Input() isManageSupplier: boolean;

  isMobile = false;
  showDailyOperationNameEdit = false;
  showDailyOperationEmailEdit = false;
  showDailyOperationPhoneEdit = false;
  @Output() hasDailyOperation = new EventEmitter<any>();
/**
   * media subscription
   */
  mediasbscription: Subscription;
  constructor(public dialog: MatDialog,
    private readonly mediaobserver: MediaObserver,
    private supplierContractService: SupplierContractService) {
      this.mediasbscription = mediaobserver.asObservable().subscribe((val: MediaChange[]) => {
        if (val[0].mqAlias === 'xs') {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }


      });
    }

  foramtPhoneNumber() {
    this.dailyOperationsData.formattedPhone =
    `${this.dailyOperationsData.countryDialingCode} (${this.dailyOperationsData.phoneNumber
      .substring(0, 3)}) ${this.dailyOperationsData.phoneNumber
        .substring(3, 6)}-${this.dailyOperationsData.phoneNumber.substring(6, 10)}`;
  }
formatName() {
  this.dailyOperationsData.fullName = `${this.dailyOperationsData.firstName.charAt(0)
    .toUpperCase()}${this.dailyOperationsData.firstName.slice(1)} ${this.dailyOperationsData.lastName.charAt(0)
      .toUpperCase()}${this.dailyOperationsData.lastName.slice(1)}`;
}

/** Method to open Daily Operations dialog**/
openDailyOperationsModal(): void {

  const dialogRef = this.dialog.open(AddEditDailyOperationsComponent, {
    disableClose: true,
    panelClass: ['dialogMainContainer'],
    data: {isEmptySupplierInfo: this.isEmptyDailyOperationInfo, dailyOperations: this.dailyOperationsData}
  });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.isEmptyDailyOperationInfo = false;
          this.hasDailyOperation.emit(true);
          this.dailyOperationsData = result;
          this.formatName();
          this.foramtPhoneNumber();
          this.supplierContractService.updateContractIndex(this.contractIndex);
          this.supplierContractService.updateDailyOperations(this.dailyOperationsData);
        }
      });

}

}
