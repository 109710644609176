import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  /** Dialog Title */
  dialogTitle: string;

  /** Dialog message lines */
  dialogLines: string[];

  /** Injecting dependencies */
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  /** Init */
  ngOnInit() {
    if (this.data && this.data.dialogTitle) {
      this.dialogTitle = this.data.dialogTitle;
    }
    if (this.data && this.data.dialogLines) {
      this.dialogLines = this.data.dialogLines;
    }
  }

  /** Closes dialog and returns Selected Clients */
  continue() {
    this.dialogRef.close(true);
  }

  /** Closes dialog and clears the form */
  cancel(): void {
    this.dialogRef.close(false);
  }

}
