<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<div class="authorize-dialog dialogContent">
  <div mat-dialog-title disableTypography fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="20px"
    fxLayoutGap.xs="0em">
    <div class="candidate-dialogtitle">
      <span *ngIf="!data.territoryCoverage">Add Territory Coverage</span>
      <span *ngIf="data.territoryCoverage">Edit Territory Coverage</span>
    </div>
      <a class="close-icon" id="close_icon" (click)="close($event)">
        <mat-icon>close</mat-icon>
      </a>
  </div>
  <div mat-dialog-content>
    <form fxLayout="column" class="form-group middle-section" [formGroup]="territoryForm"  novalidate fxLayoutGap="24px">
      <div fxLayout="column" fxLayoutGap="0.5em" fxLayoutGap.xs="1.5em">
        <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayout.xs="column"
          fxLayoutGap.xs="0em">
          <div fxFlex="100%" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <mat-label>Territory</mat-label>
              <mat-select class="form-control controlBackground" [(value)]="selectedTerritory" formControlName="territory">
                <mat-option [value]="state.shortName" *ngFor="let state of territoryStates">{{ state.shortName }}</mat-option>
              </mat-select>
              <mat-error>
                {{validationErrorMessages.territoryRequired}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayout.xs="column"
          fxLayoutGap.xs="0em">
          <div fxFlex="100%" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <mat-label>Territory Tariff</mat-label>
              <mat-select class="form-control controlBackground" [(value)]="selectedTariff" formControlName="territoryTariff">
                <mat-option [value]="tariff" *ngFor="let tariff of territoryTariff">{{ tariff }}</mat-option>
              </mat-select>
              <mat-error>
                {{validationErrorMessages.territoryTariffRequired}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxflex="100%"  fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayout.xs="column"
          fxLayoutGap.xs="0em" >
          <div fxflex="100%" formArrayName="specialDiscounts"
            *ngFor="let item of territoryForm.get('specialDiscounts')['controls']; let i = index;">
            <span>Supplier Discounts (optional)</span>
            <div fxflex="100%" fxLayout="row" [formGroupName]="i">
              <div fxflex="88%" fxflex.xs="80%" fxLayout="column" class="formarrayContent" fxLayoutGap="24px">
                <div fxflex="100%" fxLayout="row" fxLayoutGap="5px">
                  <div fxflex="45%" class="typerowdiv" fxLayout="row">
                    <mat-form-field class="type-form">
                      <mat-label>Discount Type</mat-label>
                      <mat-select class="form-control controlBackground" (selectionChange)="onDiscountTypeChange($event,i)"  formControlName="discountType">
                        <mat-option [value]="discount" *ngFor="let discount of discountTypes">{{ discount }}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="territoryForm.controls.specialDiscounts['controls'][i].controls.discountType.hasError('required') ">
                        {{validationErrorMessages.discountTypeRequired}}
                      </mat-error>
                      <mat-error *ngIf="territoryForm.controls.specialDiscounts['controls'][i].controls.discountType.hasError('overlapDiscounts') ">
                        {{validationErrorMessages.overlapDiscounts}}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div fxFlex="7%" class="emptySpaceDiv"></div>
                  <div fxflex="48%" fxLayout="row" class="typerowdiv">
                    <mat-form-field class="percentage-form">
                      <input matInput type="text" class="form-control controlBackground" formControlName="discountPercentage" appTwoDigitDecimaNumber
                        placeholder="Discount %" />
                      <mat-error>{{validationErrorMessages.discountPercentageRequired}}</mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div fxflex="100%" fxLayout="row" fxLayoutGap="5px" formGroupName="packDateGroup">
                  <div fxFlex="45%" fxLayout="row" fxflex.xs="48%">
                    <mat-form-field class="datecontrol">
                      <input matInput [matDatepicker]="picker" placeholder="Start Date" formControlName="start" (dateChange)="startdateChange(i)"  class="controlBackground">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                      <mat-error *ngIf="territoryForm.controls.specialDiscounts['controls'][i].controls.packDateGroup.controls.start.hasError('required') ">{{validationErrorMessages.endNoStart}}</mat-error>
                      <mat-error *ngIf="territoryForm.controls.specialDiscounts['controls'][i].controls.packDateGroup.controls.start.hasError('endNoStart') ">{{validationErrorMessages.endNoStart}}</mat-error>
                    </mat-form-field>
                  </div>
                  <div fxFlex="10%" fxLayoutAlign="start center" fxLayoutAlign.xs="center center" fxLayout.xs="column" fxFlex.xs="%">
                    <span>-</span>
                  </div>
                  <div fxFlex="48%" fxLayout.xs="column"  fxflex.xs="48%">
                    <mat-form-field class="enddatecontrol">
                      <input matInput [matDatepicker]="picker1" placeholder="End Date" formControlName="end" class="controlBackground" (dateChange)="enddateChange(i)">
                      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                      <mat-datepicker #picker1></mat-datepicker>
                      <mat-error *ngIf="territoryForm.controls.specialDiscounts['controls'][i].controls.packDateGroup.controls.end.hasError('required')">{{validationErrorMessages.startNoEnd}}</mat-error>
                      <mat-error *ngIf="territoryForm.controls.specialDiscounts['controls'][i].controls.packDateGroup.controls.end.hasError('startNoEnd')">{{validationErrorMessages.startNoEnd}}</mat-error>
                      <mat-error *ngIf="territoryForm.controls.specialDiscounts['controls'][i].controls.packDateGroup.controls.end.hasError('dateValidator')">{{validationErrorMessages.endDateInvalid}}</mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div fxflex="5%" fxLayout="row" fxLayoutAlign="center">
                <mat-icon (click)="removeDiscountItem(i)" class="iconCursor delete-Mat-icon">delete_outline</mat-icon>
              </div>
            </div>
          </div>
          <div (click)="addDiscount()" class="iconCursor">
            <mat-icon class="add-Mat-icon">add_circle</mat-icon> <span class="addarraylink">Add Supplier Discount</span>
          </div>
        </div>
        <div fxflex="100%"  fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayout.xs="column"
          fxLayoutGap.xs="0em" >
          <div fxflex="100%" formArrayName="commissions"
            *ngFor="let item of territoryForm.get('commissions')['controls']; let i = index;">
            <span>Commissions (optional)</span>
            <div fxflex="100%" fxLayout="row" [formGroupName]="i">
              <div fxflex="88%" fxflex.xs="80%" fxLayout="column" class="formarrayContent" fxLayoutGap="30px">
                <div fxflex="100%" fxLayout="row" fxLayoutGap="0px">
                  <div fxflex="45%" class="typerowdiv" fxLayout="row">
                    <mat-form-field class="type-form">
                      <mat-label>Commission Type</mat-label>
                      <mat-select class="form-control controlBackground" (selectionChange)="onCommissionTypeChange($event,i)"  formControlName="commissionType">
                        <mat-option [value]="commissionType" *ngFor="let commissionType of commissionTypes">{{ commissionType }}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="territoryForm.controls.commissions['controls'][i].controls.commissionType.hasError('required') ">
                        {{validationErrorMessages.commissionTypeRequired}}
                      </mat-error>
                      <mat-error *ngIf="territoryForm.controls.commissions['controls'][i].controls.commissionType.hasError('overlapCommissions') ">
                        {{validationErrorMessages.overlapCommissions}}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div fxFlex="7%" class="emptySpaceDiv"></div>
                  <div fxflex="48%" fxLayout="row" class="typerowdiv">
                    <mat-form-field class="percentage-form">
                      <input matInput type="text" class="form-control controlBackground" formControlName="commissionPercentage" appTwoDigitDecimaNumber
                        placeholder="Commission %" />
                      <mat-error>{{validationErrorMessages.commissionPercentageRequired}}</mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div fxflex="100%" fxLayout="row" fxLayoutGap="5px" formGroupName="packDateGroup">
                  <div fxFlex="45%" fxLayout="row" fxflex.xs="48%">
                    <mat-form-field class="datecontrol">
                      <input matInput [matDatepicker]="picker" placeholder="Start Date" formControlName="start" (dateChange)="commissionStartdateChange(i)"  class="controlBackground">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                      <mat-error *ngIf="territoryForm.controls.commissions['controls'][i].controls.packDateGroup.controls.start.hasError('required') ">{{validationErrorMessages.endNoStart}}</mat-error>
                      <mat-error *ngIf="territoryForm.controls.commissions['controls'][i].controls.packDateGroup.controls.start.hasError('endNoStart') ">{{validationErrorMessages.endNoStart}}</mat-error>
                    </mat-form-field>
                  </div>
                  <div fxFlex="4%" fxLayoutAlign="start center" fxLayoutAlign.xs="center center" fxLayout.xs="column" fxFlex.xs="%">
                    <span>-</span>
                  </div>
                  <div fxFlex="48%" fxLayout.xs="column"  fxflex.xs="48%">
                    <mat-form-field class="enddatecontrol">
                      <input matInput [matDatepicker]="picker1" placeholder="End Date" formControlName="end" class="controlBackground" (dateChange)="commissionEnddateChange(i)">
                      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                      <mat-datepicker #picker1></mat-datepicker>
                      <mat-error *ngIf="territoryForm.controls.commissions['controls'][i].controls.packDateGroup.controls.end.hasError('required')">{{validationErrorMessages.startNoEnd}}</mat-error>
                      <mat-error *ngIf="territoryForm.controls.commissions['controls'][i].controls.packDateGroup.controls.end.hasError('startNoEnd')">{{validationErrorMessages.startNoEnd}}</mat-error>
                      <mat-error *ngIf="territoryForm.controls.commissions['controls'][i].controls.packDateGroup.controls.end.hasError('dateValidator')">{{validationErrorMessages.endDateInvalid}}</mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div fxflex="5%" fxLayout="row" fxLayoutAlign="center">
                <mat-icon (click)="removeCommissionItem(i)" class="iconCursor delete-Mat-icon">delete_outline</mat-icon>
              </div>
            </div>
          </div>
          <div (click)="addCommision()" class="iconCursor">
            <mat-icon class="add-Mat-icon">add_circle</mat-icon> <span class="addarraylink">Add Commission</span>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div mat-dialog-actions class="actionbtn">
    <div class="statusBtn">
      <button class="uppercase" mat-button (click)="close($event)">Cancel</button>
      <button type="submit" (click)="SaveTerritory($event)"  mat-button class="mat-button contained-button" [disabled]="territoryForm.invalid">Save</button>
    </div>
  </div>
</div>
