/** MOV360-880 - to be removed at future date */
import { Component, OnDestroy, ɵConsole, OnInit } from '@angular/core';
import {
  FormControl, UntypedFormBuilder, UntypedFormGroup, Validators, ValidationErrors,
  ValidatorFn, AbstractControl,
} from '@angular/forms';
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocationService } from '../../../../core/services/location.service';
import { ClientRoleService } from '../../../../core/services/client-role.service';
import { PartySharedService } from 'src/app/core/services/party-shared.service';
import { ClientRole, roleType } from '../../../../core/models/client-role';
import { Client, ClientStatus } from '../../../../core/models/client';
import { Location } from '../../../../core/models/location';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NotificationsService } from '../../../../core/services/notifications.service';

import {
  addClientTitleForCreateClient,
  addClientModelMode,
  addClientTitleForEditClient,
  addClientTitleForDeleteClient,
  addClientTitleforinviteClientContact,
  addClientSubmitButtonLabel,
  addClientActivateClientButtonLabel,
  addClientSaveClientButtonLabel,
  phoneErrorMessage,
  addClientTitleForReviewClient,
  createCandidateResponse,
  updateCandidateResponse,
  duplicateResponse,
  activateResponse
} from 'src/app/core/models/constants';
import { ClientServiceService } from 'src/app/core/services/client-service.service';
import { ClientContactService } from '../../../../core/services/client-contact.service';
import { ClientContact } from '../../../../core/models/client-contact';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoggerService } from '../../../../core/services/logger.service';
import { UserContextService } from '../../../../core/services/user-context.service';

/**
 * Exporting the errormessages
 */
export const errorMessages: { [key: string]: string } = {
  Email: 'You must enter Email address',
  ClientStreetAddress: 'You must enter street address',
  ClientCity: 'You must enter City',
  ClientState: 'You must select State',
  ClientZipCode: 'You must enter Zip code',
  ClientCountry: 'You must enter Country',
  ClientTaxID: 'You must enter Tax ID',
  BillingContactFirstName: 'You must enter First Name',
  BillingContactLastName: 'You must enter Last Name',
  BillingContactEmail: '\'You must enter email address',
  ClientRole: 'You must select a valid role',
  BillingContactMobileNumber: 'You must enter a valid mobile phone number',
  ClientLegalName: 'You must enter Client Legal Name',
  ClientPreferredName: 'You must enter Client Name'
};

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss']
})
export class AddClientComponent implements OnInit, OnDestroy {
  /**Form group name */
  addClientForm: UntypedFormGroup;
  /* Title to display the dialog window page title */
  title = addClientTitleForCreateClient;
  /**Flag mode for Create */
  mode = addClientModelMode;
  /**Assign formready Variable as False */
  formReady = false;
  /**Variables for error */
  errors = errorMessages;
  /**Binding the dropdown values to roles filed */
  clientRoles: ClientRole[];
  /** auto complete values for destination */
  options: Location[];
  /* variable declared for showLoader*/
  showLoader = false;
  /* variable declared for deleteDialog*/
  deleteDialog = false;
  /* variable declared for title for invite as client contact*/
  inviteAsClientContactLabel = addClientTitleforinviteClientContact;
  /* variable declared for invite as client contact flag*/
  inviteAsClientContactFlag = false;
  /* variable declared for title for submit button*/
  submitButtonLabel = addClientSubmitButtonLabel;
  /** Subscription prop for unsubscribing services */
  private subscription: Subscription = new Subscription();
  /**addCandidate  model of type Candidate */
  addClient: Client = {} as Client;
  /** flag to check phone number valid */
  isPhoneNumberValid = false;
  isEmailValid = false;
  /** flag to show inactive checkbox */
  showInactiveCheckBox = false;
  /** flag to maintain inactive client status */
  inactivateClientFlag = false;
  stateList: any[];
  clientID = '15898';
  checkFirstTimeEntry = true;
  capabilities = [];
  lockFields = false;
  activateOrInactivate = 'Activate/Inactivate Clients';

  /**
   * Initializes form elements
   * @param formBuilder - property for form elements
   * @param dialogRef - property for mat dialog reference
   * @param data - contains popup data
   * @param candidateProfilesService - service parameter
   * @param changeDetectorRef - property for change detections
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AddClientComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Client,
    private locationService: LocationService,
    private notificationsService: NotificationsService,
    private clientRolesService: ClientRoleService,
    private clientService: ClientServiceService,
    private clientContactService: ClientContactService,
    private partySharedSvc: PartySharedService,
    private readonly spinner: NgxSpinnerService,
    private Logger: LoggerService,
    private loggedInUserService: UserContextService
  ) {
    /* Get all the locations for destination and departure */
    //  this.locationService
    //    .getLocations()
    //    .subscribe(location => (this.options = location));
    // this.subscription.add(this.locationService
    //   .getLocations()
    //   .subscribe(location => (this.options = location)));

    this.stateList = this.locationService.getStates();
    this.clientRoles = this.clientRolesService.getRoles();
    this.isPhoneNumberValid = true;
    this.isEmailValid = true;
    /* Create the Add/Edit dialog window */
    this.initializeClientForm();
    /* Set the deafult country value as US */
    this.addClientForm.get('ClientCountry').setValue('United States of America');
    /* If the data is present - it will open and pre-populate dialog window */
    this.partySharedSvc.getPartyId().then(id => {
      this.partySharedSvc.getRoleCapabilities(id).then(response => {
        if (!!response && !!response.roleCapabilities) {
          for (const rolecapability of response.roleCapabilities) {
            if (!!rolecapability && !!rolecapability.capabilities && rolecapability.capabilities.length > 0) {
              for (const capability of rolecapability.capabilities) {
                this.capabilities.push(capability.name);
                // console.log('Yay!! Im here' + this.capabilities)
              }
            }
          }
          if (this.data) {
            this.checkFirstTimeEntry = false;
            /* set page title to edit client */
            // console.log('Capabilities : ' + this.capabilities)
            if (this.data.status === 'Ready for Review') {
              if ((this.capabilities.indexOf(this.activateOrInactivate) >= 0)) {
                // console.log('For the CCC QA')
                this.lockFields = true;
                this.submitButtonLabel = addClientActivateClientButtonLabel;
                this.title = addClientTitleForReviewClient;
              } else if (this.capabilities.indexOf('Manage Clients') >= 0) {
                this.submitButtonLabel = addClientSaveClientButtonLabel;
                this.title = addClientTitleForEditClient;
              }
            } else if (this.data.status === 'Active') {
              this.submitButtonLabel = addClientSaveClientButtonLabel;
              this.title = addClientTitleForEditClient;
              this.lockFields = false;
              if ((this.capabilities.indexOf(this.activateOrInactivate) >= 0)) {
                this.showInactiveCheckBox = true;
              }
            }

            // console.log('Add client varialble in Init Method : ' + JSON.stringify(this.addClient, null, 4))
            // console.log('data varialble in Init Method : ' + JSON.stringify(this.data, null, 4))
            //  this.addClient = this.data
            this.clientID = this.data.clientID;
            this.addClient.partyID = this.data.partyID;
            const billingContact = this.data.billingContact;

            /* Setting the default values for form elements in edit candidate dialog */
            this.addClientForm.get('ClientPreferredName').setValue(this.data.clientPreferredName);
            this.addClientForm.get('ClientLegalName').setValue(this.data.clientLegalName);
            this.addClientForm.get('ClientAddress').setValue(this.data.clientAddress.streetAddress);
            this.addClientForm.get('ClientCity').setValue(this.data.clientAddress.city);
            this.addClientForm.get('ClientState').setValue(this.data.clientAddress.state);
            this.addClientForm.get('ClientCountry').setValue(this.data.clientAddress.country);
            this.addClientForm.get('ClientPostalCode').setValue(this.data.clientAddress.zipcode);
            this.addClientForm.get('InviteClientContactCheckBox').setValue(true);
            this.addClientForm.get('TaxID').setValue(this.data.taxID);
            if (billingContact !== undefined) {
              this.addClientForm.get('ClientContactFirstName').setValue(billingContact.firstName ? billingContact.firstName : '');
              this.addClientForm.get('ClientContactLastName').setValue(billingContact.lastName ? billingContact.lastName : '');
              this.addClientForm.get('ClientContactEmailAddress').setValue(billingContact.emailAddress ? billingContact.emailAddress : '');
              this.addClientForm.get('InviteClientContactCheckBox').setValue(billingContact.invitedAsClientContact ?
                billingContact.invitedAsClientContact : false);
              this.inviteAsClientContactFlag = billingContact.invitedAsClientContact;
              if (this.inviteAsClientContactFlag) {
                const ccRole: ClientRole = this.data.billingContact.role.find(item => item.name === 'client-contact-initiator' ||
                  item.name === 'client-contact-administrator');
                const role = this.clientRolesService.getRoleDetails(ccRole.name);
                this.addClientForm.get('ClientContactRole').setValue(role.roleName);
                this.addClientForm.get('PhoneNumber').setValue(billingContact.phoneNumber);
              }
            }

            if (this.data.isDeleted === true) {
              this.title = addClientTitleForDeleteClient;
              this.deleteDialog = true;
            }
          }
        }
      });
    });
  }

  /**
   * Used for initializing Client Form
   */
  initializeClientForm(): void {
    this.addClientForm = this.formBuilder.group({
      // TODO: need to add pattern for ModelName to avoid special character.
      ClientPreferredName: ['',
        Validators.compose([Validators.required, this.noWhitespaceValidator])],
      ClientLegalName: ['',
        Validators.compose([Validators.required, this.noWhitespaceValidator])],
      ClientAddress: ['',
        Validators.compose([Validators.required, this.noWhitespaceValidator])],
      ClientCity: ['',
        Validators.compose([Validators.required, this.noWhitespaceValidator])],
      ClientState: ['',
        Validators.compose([Validators.required, this.noWhitespaceValidator])],
      ClientPostalCode: ['',
        Validators.compose([Validators.required, this.noWhitespaceValidator])],
      ClientCountry: ['',
        Validators.compose([Validators.required, this.noWhitespaceValidator])],
      TaxID: ['',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.maxLength(9),
          Validators.minLength(9)
        ])],
      InviteClientContactCheckBox: [''],
      InactivateClientCheckBox: [''],
      PhoneNumber: [''],
      ClientContactFirstName: ['',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-z A-Z0-9]*$')
        ])],
      ClientContactLastName: ['',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-z A-Z0-9]*$')
        ])],
      ClientContactEmailAddress: ['',
        Validators.compose([
          Validators.required,
          Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,3}$'),
          this.noWhitespaceValidator
        ])
      ],
      ClientContactRole: ['']
    });
  }

  /**
   * Click on Submit button inside the addCstModelForm dialog window
   */
  saveClient(): void {
    if (this.addClientForm.valid) {
      this.spinner.show();
      this.populateClientObject();
      if (this.data) {
        if (this.capabilities.indexOf(this.activateOrInactivate) >= 0 && this.data.status === 'Ready for Review') {
          this.clientService.activateClient(this.addClient).subscribe({
            next: response => {
              this.spinner.hide();
              if (response.statusCode === 200) {
                this.addClient.executedFunctionType = 'Activate';
                this.flashAndCloseDialog(response.message);
              }
            },
            error: () => {
              this.spinner.hide();
            }
          });
        } else {
          // console.log('We are in the right block : ' + JSON.stringify(this.addClient, null, 4))
          this.addClient.partyID = this.data.partyID;
          this.clientService
            .updateClient(this.addClient)
            .subscribe({
              next: response => {
                this.spinner.hide();
                if (response.statusCode === 200) {
                  // console.log('Expected Response : ' + JSON.stringify(response, null, 4))
                  this.addClient.executedFunctionType = 'Update';
                  this.flashAndCloseDialog(response.message);
                } else {
                  // console.log("Error : ", JSON.stringify(response, null, 4))
                }
              },
              error: () => {
                this.spinner.hide();
              }
            });
          }
      } else {
          this.clientService.createClient(this.addClient).subscribe({
            next: response => {
              this.spinner.hide();
              if (response.statusCode === 200) {
                // console.log('Response : ' + JSON.stringify(response, null, 4))
                this.addClient.executedFunctionType = addClientModelMode;
                // console.log('Before Client ID Update : ' + JSON.stringify(this.addClient, null, 4))
                this.clientService.updateExistingClientId(this.addClient.clientID, response.candidateID);
                this.addClient.partyID = response.clientID;
                this.addClient.clientID = response.clientNumber;
                // console.log('Added Client : ' + JSON.stringify(this.addClient, null, 4))
                this.flashAndCloseDialog(response.message);
              } else {
                // console.log("Error : ", JSON.stringify(response, null, 4))
              }
            },
            error: () => {
              this.spinner.hide();
            }
        });
      }
    }
  }

  //  /* Enable the event listener for departure drop down form element */
  //  /* Enable the event listener for departure drop down form element */
  //  this.departures = this.addClientForm.get('Departure').valueChanges.pipe(
  //    startWith(''),
  //    map(name =>
  //      name ? this._filter(name) : this.options && this.options.slice()
  //    )
  //  );
  //  /* Enable the event listener for the destination drop down form element */
  //  this.destinations = this.addClientForm
  //    .get('Destination')
  //    .valueChanges.pipe(
  //      startWith(''),
  //      map(name =>
  //        name ? this._filter(name) : this.options && this.options.slice()
  //      )
  //    );

  /**
   *  Modified incoming value to lowerCase and assigned to const variable filterValue
   * @param value - start character for filter values
   */
  private _filter(value: string): Location[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  /**
    * function to flash message and close dialog
    * @param response string
    */
  flashAndCloseDialog(message: string) {
    switch (message) {
      case createCandidateResponse: {
        this.dialogRef.close(this.addClient);
        this.notificationsService.flashNotification(
          'success',
          'Client added successfully');
        break;
      }
      case updateCandidateResponse: {
        this.dialogRef.close(this.addClient);
        this.notificationsService.flashNotification(
          'success',
          'Client updated successfully'
        );
        break;
      }
      case activateResponse: {
        this.dialogRef.close(this.addClient);
        // this.notificationsService.flashNotification(
        //   'success',
        //   'Client Activated Successfully'
        // );
        break;
      }
      case duplicateResponse: {
        this.notificationsService.flashNotification(
          'error',
          'Duplicate Client Found, Please inactivate client to continue',
          true,
          'dismiss'
        );
        this.addClientForm.disable();
        break;
      }
    }
  }

  inactivateClient() {
    // this.clientService.deleteClient(this.addClient.clientID)
    // console.log('Data to be sent back' + JSON.stringify(this.addClient, null, 4))
    this.addClient.executedFunctionType = 'Inactivate';
    this.dialogRef.close(this.addClient);
  }

  checkForClientContactFields() {
    if (this.inviteAsClientContactFlag) {
      if (this.addClientForm.controls['PhoneNumber'].value && this.addClientForm.controls['ClientContactRole'].value) {
        return !this.addClientForm.valid;
      } else {
        return true;
      }
    }
    return !this.addClientForm.valid;
  }

  populateClientObject() {
    const dateString = this.formatDate(new Date());
    const currentDate = new Date();
    const numberOfDaysToAdd = 30;
    const invitedAsCC = this.addClientForm.value.InviteClientContactCheckBox ? this.addClientForm.value.InviteClientContactCheckBox : false;
    let roleDetails;
    if (invitedAsCC) {
      roleDetails = this.clientRolesService.getRoleID(this.addClientForm.value.ClientContactRole);
      // console.log(roleDetails);
    }
    currentDate.setDate(currentDate.getDate() + numberOfDaysToAdd);
    const ccRole: ClientRole = {
      roleID: invitedAsCC ? roleDetails.roleID : undefined,
      roleDescrpition: invitedAsCC ? roleDetails.roleDescrpition : undefined,
      roleName: invitedAsCC ? roleDetails.roleName : undefined
    };
    // console.log(ccRole)
    this.addClient = {
      'clientPreferredName': this.addClientForm.value.ClientPreferredName,
      'clientLegalName': this.addClientForm.value.ClientLegalName,
      'clientAddress': {
        // eslint-disable-next-line max-len
        'fullAddress': `${this.addClientForm.value.ClientAddress}, ${this.addClientForm.value.ClientCity}, ${this.addClientForm.value.ClientState}, ${this.addClientForm.value.ClientPostalCode}, ${this.addClientForm.value.ClientCountry}`,
        'streetAddress': this.addClientForm.value.ClientAddress,
        'city': this.addClientForm.value.ClientCity,
        'state': this.addClientForm.value.ClientState,
        'zipcode': this.addClientForm.value.ClientPostalCode,
        'country': this.addClientForm.value.ClientCountry
      },
      billingContact: {
        'firstName': this.addClientForm.value.ClientContactFirstName ? this.addClientForm.value.ClientContactFirstName : '',
        'lastName': this.addClientForm.value.ClientContactLastName ? this.addClientForm.value.ClientContactLastName : '',
        'emailAddress': this.addClientForm.value.ClientContactEmailAddress ? this.addClientForm.value.ClientContactEmailAddress : '',
        // 'phoneNumber': invitedAsCC ? this.addClientForm.value.PhoneNumber: '',
        'role': [{
          roleID: invitedAsCC ? roleDetails.roleID : undefined,
          roleDescrpition: invitedAsCC ? roleDetails.roleDescrpition : undefined,
          roleName: invitedAsCC ? roleDetails.roleName : undefined
        }],
        'isBillingContact': true,
        'invitedAsClientContact': this.addClientForm.value.InviteClientContactCheckBox ?
          this.addClientForm.value.InviteClientContactCheckBox : false,
        'status': 'Invitation Not Sent',
        'statusDate': dateString,
        'isDeleted': false
      },
      'taxID': this.addClientForm.value.TaxID,
      'status': 'Ready for Review',
      'createdDate': dateString,
      'createdBy': '5d702e7f5a7876dcaba3d443',
      'statusDate': dateString,
      'isDeleted': false
    };
    if (invitedAsCC) {
      this.addClient.billingContact.phoneNumber = this.addClientForm.value.PhoneNumber !== undefined ?
        this.addClientForm.value.PhoneNumber : '';
    }

    // console.log('data : ' + JSON.stringify(this.data, null, 4));

    if (this.data !== undefined && this.data !== null) {
      this.addClient.clientID = this.data.clientID;
      this.addClient.billingContact.clientContactID = this.data.billingContact.clientContactID;
      this.addClient.partyID = this.data.partyID;
      this.addClient.billingContact.clientID = this.data.partyID;
      this.addClient.taxID = this.addClientForm.get('TaxID').value;
      this.addClient.clientRole = this.data.clientRole;
      this.addClient.status = this.data.status;
      // console.log('add client: ' + JSON.stringify(this.addClient, null, 4));
    }
  }

  formatDate(date) {
    return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
  }

  deleteClient(): void {
    this.clientService.deleteClient(this.data.clientID);
    this.dialogRef.close();
  }

  /**
   * Closing the dialog box - we are setting the form to empty
   */
  onNoClick(): void {
    this.dialogRef.close();
    this.initializeClientForm();
  }

  /**
   * Custom error messages for Firstname, lastname and Email to verify special character or empty errors
   * @param field_name - field parameter to check for errors
   */
  getErrorMessage(fieldName): string {
    const charNotAllowed = 'Special characters are not allowed';
    if (fieldName === 'FIRST_NAME') {
      return this.addClientForm.get('ClientContactFirstName').hasError('required')
        ? 'You must enter first name'
        : this.addClientForm.get('ClientContactFirstName').hasError('pattern')
          ? charNotAllowed
          : '';
    }
    if (fieldName === 'LAST_NAME') {
      return this.addClientForm.get('ClientContactLastName').hasError('required')
        ? 'You must enter last name'
        : this.addClientForm.get('ClientContactLastName').hasError('pattern')
          ? charNotAllowed
          : '';
    }
    if (fieldName === 'PHONE_NUMBER') {
      return this.addClientForm.get('PhoneNumber').hasError('required')
        ? phoneErrorMessage
        : this.addClientForm.get('PhoneNumber').hasError('specialCharacter')
          ? charNotAllowed
          : this.addClientForm.get('PhoneNumber').hasError('pattern')
            ? phoneErrorMessage
            : this.addClientForm.get('PhoneNumber').hasError('alphabets')
              ? phoneErrorMessage
              : this.addClientForm.get('PhoneNumber').hasError('minlength')
                ? phoneErrorMessage
                : '';
    }
    if (fieldName === 'TAX_ID') {
      return this.addClientForm.get('TaxID').hasError('required')
        ? 'You must enter Tax ID'
        : this.addClientForm.get('TaxID').hasError('pattern')
          ? 'You must enter valid Tax ID'
          : this.addClientForm.get('TaxID').hasError('minlength')
            ? 'You must enter valid Tax ID'
            : '';
    }
    if (fieldName === 'EMAIL_ADDRESS') {
      return this.addClientForm.get('ClientContactEmailAddress').hasError('required')
        ? 'You must enter email address'
        : this.addClientForm.get('ClientContactEmailAddress').hasError('pattern')
          ? 'You must enter valid email address'
          : '';
    }

    switch (fieldName === 'clientName') {
      case this.addClientForm.get('ClientPreferredName').hasError('required'):
        return 'You must enter Client Name';
      case this.addClientForm.get('ClientPreferredName').hasError('pattern'):
        return charNotAllowed;
      case this.addClientForm.get('ClientLegalName').hasError('required'):
        return 'YYou must enter Client Legal Name';
      case this.addClientForm.get('ClientLegalName').hasError('pattern'):
        return charNotAllowed;
    }
  }

  updateClientContactFlag(): boolean {
    this.inviteAsClientContactFlag = !this.inviteAsClientContactFlag;
    return this.inviteAsClientContactFlag;
  }

  updateClientStatus(): boolean {
    this.inactivateClientFlag = !this.inactivateClientFlag;
    return this.inactivateClientFlag;
  }

  onSearchChange(searchValue: string): void {
    if (this.checkFirstTimeEntry || this.addClientForm.get('ClientPreferredName').value === '') {
      this.addClientForm.get('ClientPreferredName').setValue(this.addClientForm.value['ClientLegalName']);
    }
  }

  toggleDuplicateFlag() {
    this.checkFirstTimeEntry = false;
  }

  /**
   * destroys the object
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  addValidators() {
    if (this.addClientForm.controls['PhoneNumber'].value) {
      this.addClientForm.controls['PhoneNumber'].setValidators(
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(10),
          this.regexValidator(new RegExp(/[A-Za-z]/g), { 'alphabets': true }),
          this.regexValidator(new RegExp(/[!@#$%^&*()/\\?,.?":{}\-\+=_|<>;'`~\]\[ ]/g), { 'specialCharacter': true })
        ]));
      this.addClientForm.controls['PhoneNumber'].updateValueAndValidity();
      this.isPhoneNumberValid = true;
    } else {
      this.addClientForm.controls['PhoneNumber'].clearValidators();
      this.addClientForm.controls['PhoneNumber'].updateValueAndValidity();
      this.isPhoneNumberValid = false;
    }
  }

  /**validate the pattern for phone number */
  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = control.value.match(regex);
      return valid ? error : null;
    };
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  ngOnInit() {
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-ADD_CLIENT', 'ADD_CLIENT');
      });
  }
}
