<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<div class="authorize-dialog">
  <div mat-dialog-title disableTypography fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="20px"
    fxLayoutGap.xs="0em">
    <div class="candidate-dialogtitle">
      <span>{{addEditSupplier}}</span>
    </div>
    <a href="#" tabindex="0" class="close-icon" matTooltip="Close" aria-label="close" role="link" id="close_icon" (click)="close($event)" title="close">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <div mat-dialog-content>
    <form fxLayout="column" class="middle-section" [formGroup]="addSupplierForm" (ngSubmit)="onSave($event)" novalidate>
      <div class="form-group middle-section space" >
        <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
          fxLayoutGap.xs="0em">
          <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <input matInput type="text" class="form-control" (blur)="toggleDuplicateFlag()" formControlName="supplierlegalname"
                (input)="onSearchChange($event.target.value)" placeholder="Supplier Legal Name"/>
              <mat-error>You must enter Legal Name</mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <input matInput type="text" class="form-control" formControlName="supplierpreferredname" placeholder="Supplier Display Name"/>
              <mat-error>You must enter Supplier Name</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="form-group middle-section space" >
        <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
          fxLayoutGap.xs="0em">
          <div fxFlex="48%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <input matInput type="text" class="form-control"  formControlName="vendornumber" placeholder="Supplier Vendor #"/>
              <mat-error>You must enter Supplier Vendor Number</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="bcgcolor">
        <span class="candidate-dialogtitle title">Primary Location</span>
        <div class="form-group middle-section space" >
          <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
            fxLayoutGap.xs="0em">
            <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
              <mat-form-field>
                <input [readonly]=true class="form-control bgcolor" [disabled]='lockFields'
                  type="text" placeholder="Country" matInput formControlName="country" id="departure">
              </mat-form-field>
            </div>
            <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                  <input matInput type="text" class="form-control bgcolor" formControlName="streetaddress" placeholder="Street Address"/>
                  <mat-error>You must enter street address</mat-error>
                </mat-form-field>
            </div>
          </div>
        </div>
        <div class="form-group middle-section space" >
          <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
            fxLayoutGap.xs="0em">
            <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
              <mat-form-field>
                <input matInput type="text" class="form-control bgcolor"  formControlName="city" placeholder="City"/>
                <mat-error>You must enter city</mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="15%" fxLayout.xs="column" fxFlex.xs="100%">
              <mat-form-field>
                <mat-label>State</mat-label>
                <mat-select class="form-control bgcolor" formControlName="state">
                  <mat-option [value]="state.shortName" *ngFor="let state of stateList">{{state.shortName}}</mat-option>
                </mat-select>
                <mat-error>You must select state</mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="35%" fxLayout.xs="column" fxFlex.xs="100%">
              <mat-form-field>
                <input matInput type="text" class="form-control bgcolor"  formControlName="zipcode" placeholder="Zip Code"/>
                <mat-error>You must enter zip code</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div mat-dialog-actions>
        <div class="statusBtn">
          <button class="uppercase" mat-button (click)="onNoClick($event)">Cancel</button>
          <button type="submit" mat-button [disabled]="addSupplierForm.invalid" class="mat-button contained-button">Save</button>
        </div>
      </div>
    </form>
  </div>
</div>
