import { MultiStepComponent } from './../../core/components/multi-step/multi-step.component';
import { LinkedListComponent } from './../../core/components/linked-list/linked-list.component';
import { AuthorizedMovePhaseComponent } from './../../core/components/authorized-move-phase/authorized-move-phase.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { BreadcrumbComponent } from './../../core/components/breadcrumb/breadcrumb.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { ProjectAlphaRoutingModule } from './project-alpha-routing.module';
import { CandidateProfileComponent } from './candidate-profile/candidate-profile.component';
import { CostModelComponent } from './cost-model/cost-model.component';
import { CompareCostModelsComponent } from './cost-model/compare-cost-models/compare-cost-models.component';
import { AuthorizedMoveComponent } from './authorized-move/authorized-move.component';
import { ProjectAlphaComponent } from './project-alpha.component';
import { CandidateDetailsComponent } from './candidate-profile/candidate-details/candidate-details.component';
import { AddCandidateComponent } from './candidate-profile/add-candidate/add-edit-budget.component';
import { CandidateColumnsComponent } from './candidate-profile/candidate-columns/candidate-columns.component';
import { MoveColumnsComponent } from './authorized-move/move-columns/move-columns.component';
import { SettingsComponent } from './settings/settings.component';
import { ResendInviteComponent } from './candidate-profile/resend-invite/resend-invite.component';
import { HighlightSearchPipe } from './highlight-search.pipe';
import { AddCostModelComponent } from './cost-model/add-cost-model/add-cost-model.component';
import { TransfereeDetailsComponent } from './authorized-move/transferee-details/transferee-details.component';
import { ExploreDestinationsComponent } from './explore-destinations/explore-destinations.component';
import { ServicesComponent } from './services/services.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { WithdrawCandidateComponent } from './candidate-profile/withdraw-candidate/withdraw-candidate.component';
import { DuplicateCandidateComponent } from './candidate-profile/duplicate-candidate/duplicate-candidate.component';
import { HeaderStatusComponent } from '../../core/components/header-status/header-status.component';
import { InvitationSentComponent } from './candidate-profile/invitation-sent/invitation-sent.component';
import { BudgetDetailsComponent } from './budget-details/budget-details.component';
import { CandidateContactInfoComponent } from '../../core/components/candidate-contact-info/candidate-contact-info.component';
import { CandidateInfoComponent } from '../../core/components/candidate-info/candidate-info.component';
import { CandidateMoveInfoComponent } from '../../core/components/candidate-move-info/candidate-move-info.component';
import { CandidateCoreBenifitsComponent } from './budget-details/candidate-core-benifits/candidate-core-benifits.component';
import { CandidateFlexibleBenifitsComponent } from './budget-details/candidate-flexible-benifits/candidate-flexible-benifits.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DeleteCostModelComponent } from './cost-model/delete-cost-model/delete-cost-model.component';
import { CandidateDepartureHomeDetailComponent } from '../../core/components/candidate-departure-home-detail/candidate-departure-home-detail.component';
import { BudgetChartComponent } from '../../core/components/budget-chart/budget-chart.component';
import { CandidateNameInitialsComponent } from '../../core/components/candidate-name-initials/candidate-name-initials.component';
import { OverlayTooltipComponent } from '../../core/components/overlay-tooltip/overlay-tooltip.component';
import { AmountFormatDirective } from '../../core/directives/amount-format.directive';
import { InputPhoneFormatDirective } from '../../core/directives/input-phone-format.directive';
import { AuthorizeTransferComponent } from './budget-details/authorize-transfer/authorize-transfer.component';
import { ClientColumnsComponent } from './client/client-columns/client-columns.component';
import { AddClientComponent } from './client/add-client/add-client.component';
import { ClientComponent } from './client/client.component';
import { HtmlTooltipComponent } from '../../core/components/html-tooltip/html-tooltip.component';
import { AuthorizeTransfereeDetailsComponent } from './authorize-transferee-details/authorize-transferee-details.component';
import { InvoicePdfComponent } from '../../core/components/invoice-pdf/invoice-pdf.component';
import { InactivateClientComponent } from './client/inactivate-client/inactivate-client.component';
import { TransfereeTimelineComponent } from './authorize-transferee-details/transferee-timeline/transferee-timeline.component';
import { ReloOfferDocumentComponent } from './budget-details/relo-offer-document/relo-offer-document.component';
import { CanAccessDirective } from './../../core/directives/can-access.directive';
import { ClientContactComponent } from './client-contact/client-contact.component';
import { FinanceAuditComponent } from './finance-audit/finance-audit.component';
import { AddClientContactComponent } from './client-contact/add-client-contact/add-client-contact.component';
import { ClientContactColumnsComponent } from './client-contact/client-contact-columns/client-contact-columns.component';
import { InactivateClientContactsComponent } from './client-contact/inactivate-client-contacts/inactivate-client-contacts.component';
import { CartusEmployeeComponent } from './cartus-employee/cartus-employee/cartus-employee.component';
import { AddRolesComponent } from './cartus-employee/cartus-employee/add-roles/add-roles.component';
import { EmployeeDetailsComponent } from './cartus-employee/cartus-employee/employee-details/employee-details.component';
import { FooterComponent } from '../../core/components/footer/footer.component';
import { FinanceColumnsComponent } from './finance-audit/finance-columns/finance-columns.component';
import { AccountManagerComponent } from './account-manager/account-manager.component';
import { AccountManagerDetailsComponent } from './account-manager/account-manager-details/account-manager-details.component';
import { AddAccountManagerComponent } from './account-manager/add-account-manager/add-account-manager.component';
import { FinanceAuditDetailsComponent } from './finance-audit-details/finance-audit-details.component';
import { AddSupplierInvoiceComponent } from './finance-audit/add-supplier-invoice/add-supplier-invoice.component';
import { ReadonlyDirective } from '../../core/directives/readonly.directive';
import { TestComponent } from '../../core/directives/TestComponent';
import { FinanceAuditExportComponent } from './finance-audit-export/finance-audit-export.component';
import { UserContactInfoComponent } from './finance-audit/user-contact-info/user-contact-info.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { TransfereeDocumentLibraryComponent } from './authorize-transferee-details/transferee-document-library/transferee-document-library.component';
import { SupplierContactsListComponent } from './supplier-contacts-list/supplier-contacts-list.component';
import { InactiveSupplierContactsComponent } from './supplier-contacts-list/inactive-supplier-contacts/inactive-supplier-contacts.component';
import { AddEditSupplierContactComponent } from './supplier-contacts-list/add-edit-supplier-contact/add-edit-supplier-contact.component';
import { VanlinePopupComponent } from './finance-audit-details/vanline-popup/vanline-popup.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { AddSupplierComponent } from './suppliers/add-supplier/add-supplier.component';
import { SupplierDetailsComponent } from './suppliers/supplier-details/supplier-details.component';
import { SupplierInfoComponent } from './supplier-info/supplier-info.component';
import { SupplierDetailsInfoComponent } from './supplier-details-info/supplier-details-info.component';
import { AddContractComponent } from './supplier-details-info/add-contract/add-contract.component';
import { AddEditDailyOperationsComponent } from './suppliers/add-edit-daily-operations/add-edit-daily-operations.component';
import { SupplierContractComponent } from './supplier-details-info/supplier-contract/supplier-contract.component';
import { DailyOperationInfoComponent } from './supplier-details-info/supplier-contract/daily-operation-info/daily-operation-info.component';
import { TerritoryCoverageInfoComponent } from './supplier-details-info/supplier-contract/territory-coverage-info/territory-coverage-info.component';
import { OracleSiteIdsComponent } from './supplier-details-info/supplier-contract/oracle-site-ids/oracle-site-ids.component';
import { SensitiveInfoMaskComponent } from 'src/app/core/components/sensitive-info-mask/sensitive-info-mask.component';
import { OracleSiteIdInfoComponent } from './supplier-details-info/supplier-contract/oracle-site-id-info/oracle-site-id-info.component';
import { AddEditTerritoryComponent } from './supplier-details-info/add-edit-territory/add-edit-territory.component';
import { TwoDigitDecimaNumberDirective } from 'src/app/core/directives/two-digit-number.directive';
import { ConsultantsComponent } from './consultants/consultants.component';
import { ConsultantDetailsComponent } from './consultants/consultant-details/consultant-details.component';
import { AddConsultantModalComponent } from './consultants/add-consultant-modal/add-consultant-modal.component';
import { CustomSnackbarComponent } from '../custom-snackbar/custom-snackbar.component';
import { ConfirmDialogComponent } from 'src/app/core/components/shared/confirm-dialog/confirm-dialog.component';
import { ProgressSpinnerComponent } from 'src/app/core/components/progress-spinner/progress-spinner.component';
import { OverlayService } from 'src/app/core/services/overlay.service';
import { CallSummarizerComponent } from './call-summarizer/call-summarizer.component';

@NgModule({
  declarations: [
    CanAccessDirective,
    ReadonlyDirective,
    TestComponent,
    CandidateProfileComponent,
    CostModelComponent,
    AuthorizedMoveComponent,
    CandidateDetailsComponent,
    ProjectAlphaComponent,
    AddCandidateComponent,
    CandidateColumnsComponent,
    SettingsComponent,
    ResendInviteComponent,
    HighlightSearchPipe,
    MoveColumnsComponent,
    AddCostModelComponent,
    TransfereeDetailsComponent,
    ExploreDestinationsComponent,
    ServicesComponent,
    DashboardComponent,
    WithdrawCandidateComponent,
    DuplicateCandidateComponent,
    InvitationSentComponent,
    BudgetDetailsComponent,
    CandidateContactInfoComponent,
    CandidateInfoComponent,
    CandidateMoveInfoComponent,
    CandidateCoreBenifitsComponent,
    CandidateFlexibleBenifitsComponent,
    CandidateDepartureHomeDetailComponent,
    BreadcrumbComponent,
    BudgetChartComponent,
    CandidateNameInitialsComponent,
    HeaderStatusComponent,
    InvitationSentComponent,
    CompareCostModelsComponent,
    DeleteCostModelComponent,
    OverlayTooltipComponent,
    AmountFormatDirective,
    InputPhoneFormatDirective,
    AuthorizeTransferComponent,
    ClientComponent,
    ClientColumnsComponent,
    AddClientComponent,
    HtmlTooltipComponent,
    AuthorizeTransfereeDetailsComponent,
    AuthorizedMovePhaseComponent,
    LinkedListComponent,
    MultiStepComponent,
    InactivateClientComponent,
    ClientContactComponent,
    FinanceAuditComponent,
    FinanceAuditExportComponent,
    AddClientContactComponent,
    ClientContactColumnsComponent,
    InactivateClientContactsComponent,
    InvoicePdfComponent,
    InactivateClientComponent,
    TransfereeTimelineComponent,
    ReloOfferDocumentComponent,
    CartusEmployeeComponent,
    AddRolesComponent,
    EmployeeDetailsComponent,
    FooterComponent,
    FinanceColumnsComponent,
    AccountManagerComponent,
    AccountManagerDetailsComponent,
    AddAccountManagerComponent,
    FinanceAuditDetailsComponent,
    AddSupplierInvoiceComponent,
    UserContactInfoComponent,
    AccountSettingsComponent,
    TransfereeDocumentLibraryComponent,
    SupplierContactsListComponent,
    InactiveSupplierContactsComponent,
    AddEditSupplierContactComponent,
    VanlinePopupComponent,
    SuppliersComponent,
    AddSupplierComponent,
    SupplierDetailsComponent,
    SupplierInfoComponent,
    SupplierDetailsInfoComponent,
    AddContractComponent,
    AddEditDailyOperationsComponent,
    SupplierContractComponent,
    DailyOperationInfoComponent,
    TerritoryCoverageInfoComponent,
    OracleSiteIdsComponent,
    SensitiveInfoMaskComponent,
    OracleSiteIdInfoComponent,
    AddEditTerritoryComponent,
    TwoDigitDecimaNumberDirective,
    ConsultantsComponent,
    ConsultantDetailsComponent,
    AddConsultantModalComponent,
    CustomSnackbarComponent,
    ConfirmDialogComponent,
    ProgressSpinnerComponent,
    CallSummarizerComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ProjectAlphaRoutingModule,
    NgxSpinnerModule,
    GoogleChartsModule
  ],
  providers: [
    OverlayService
  ],
  bootstrap: [
    AddCandidateComponent,
    CandidateColumnsComponent,
    MoveColumnsComponent,
    SettingsComponent,
    ResendInviteComponent,
    AddCostModelComponent,
    TransfereeDetailsComponent,
    WithdrawCandidateComponent,
    DuplicateCandidateComponent,
    InvitationSentComponent,
    CompareCostModelsComponent,
    DeleteCostModelComponent,
    ClientColumnsComponent,
    AddClientComponent,
    InactivateClientComponent,
    ClientContactComponent,
    FinanceAuditComponent,
    FinanceAuditExportComponent,
    InactivateClientContactsComponent,
    AddClientContactComponent,
    ClientContactColumnsComponent,
    FinanceColumnsComponent,
    AddSupplierInvoiceComponent,
    AccountSettingsComponent,
    CustomSnackbarComponent,
    CallSummarizerComponent
  ],
  entryComponents: [
    OverlayTooltipComponent, AuthorizeTransferComponent, HtmlTooltipComponent, AuthorizedMovePhaseComponent, AddSupplierComponent,
    AddEditDailyOperationsComponent, AddRolesComponent, OracleSiteIdInfoComponent, AddAccountManagerComponent, UserContactInfoComponent,
    AddEditSupplierContactComponent, InactiveSupplierContactsComponent, VanlinePopupComponent, AddContractComponent,
    AddEditTerritoryComponent, AddConsultantModalComponent, ConfirmDialogComponent, CallSummarizerComponent
  ],
  exports: [
    ReadonlyDirective,
    HighlightSearchPipe,
    ConfirmDialogComponent,
    ProgressSpinnerComponent
  ]
})
export class ProjectAlphaModule { }
