<div class="cdk-visually-hidden" aria-live="polite" [innerHTML]="sortStatement" aria-atomic="true"></div>
<ng-template [appReadonly]="['Manage Candidates/Transferees All']" (valueChange)="isDisabled($event)"></ng-template>
<section class="placeholder-section" *ngIf="false">
  <div>
    <mat-card>
    </mat-card>
    <mat-card>
    </mat-card>
  </div>
</section>
<section class="search_table">
  <h1 tabindex="0" #pageheading class="cdk-visually-hidden">Authorized transferee details</h1>
  <span class="cdk-visually-hidden" aria-live="assertive" aria-atomic="true" role="alert"
    *ngIf="dataSource">{{dataSource.filteredData.length}} Authorized transferee</span>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <span fxFlex="60%" fxLayoutAlign="start">
      <mat-form-field appearance="outline" class="search-within-table search-box">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput id="searchInput" (keyup)="applyFilter($event.target.value)"
          placeholder="Search within table for..." width="80%" fxFlex.sm="65" aria-label="Search within table">
      </mat-form-field>
      <span fxLayoutAlign="start center">
        <a href="#" id="selectColumns" (click)="openModal($event)">
          <span class="cdk-visually-hidden">Select columns</span>
          <mat-icon matTooltip="Select columns" matTooltipPosition="right" class="viewsetting-icon" id="view_column">
            view_column
          </mat-icon>
        </a>
      </span>
    </span>
    <span fxFlex="12.5em" fxLayoutAlign="end" fxFlex.xs="10.5em">
    </span>
  </div>
</section>

<p></p>
<section class="content_table">
  <div>
    <section class="mat-elevation-z8">
      <div *ngIf="ELEMENT_DATA.length>0 && dataSource.filteredData.length>0" style="overflow:auto;">
        <table mat-table summary="Authorized transferee table" [dataSource]="dataSource" matSort
          (matSortChange)="sortData($event)" matSortDisableClear>

          <!-- Full Name Column -->
          <ng-container matColumnDef="candidate.fullname" sticky>
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Full Name</th>
            <td mat-cell *matCellDef="let element" class="cLinks" [ngClass]="{highlighted: selection.isSelected(element)}">
              <a href="#" class="client-table-links" (click)="$event.preventDefault()">
                <span class="text-as-link"
                  [innerHTML]="element.candidate.fullname? (element.candidate.fullname | highlightSearch: filterText):''">{{element.candidate.fullname}}
                </span>
              </a>
            </td>
          </ng-container>
          <!-- Client and Client Contact Name Column -->
          <ng-container matColumnDef="client">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Client</th>
            <td mat-cell *matCellDef="let element"><span
                [innerHTML]="element.candidate.clientName? (element.candidate.clientName | highlightSearch: filterText):''">{{element.candidate.clientName}}</span><br>
              <span class="small"
                [innerHTML]="element.contactName? (element.contactName | highlightSearch: filterText):''">{{element.contactName}}</span>
            </td>
          </ng-container>

          <!-- Move Phase Column -->
          <ng-container matColumnDef="movePhase">
            <th mat-header-cell *matHeaderCellDef>Move Phase</th>
            <td mat-cell *matCellDef="let element" [ngClass]="{'clickable' : element.movePhase }"
              (click)="openMovePhaseModal(element, $event)">
              <a *ngIf="element.movePhase" href="#" (click)="$event.preventDefault()" class="multi-step-link">
                <app-multi-step [steps]="5" [selected]="element.movePhase"></app-multi-step>
              </a>
              <span *ngIf="!element.movePhase"> N/A </span>
            </td>
          </ng-container>
          <!-- Authorized Amount Column -->
          <ng-container matColumnDef="authorizedAmtFormat">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount Authorized</th>
            <td mat-cell *matCellDef="let element"><span
                [innerHTML]="element.authorizedAmtFormat? (element.authorizedAmtFormat | highlightSearch: filterText):''">{{element.authorizedAmtFormat}}</span><br>
              <span class="small"
                [innerHTML]="element.remainingFormat? (element.remainingFormat | highlightSearch: filterText):''">{{element.remainingFormat}}</span>
            </td>
          </ng-container>

          <!-- Departure Column -->
          <ng-container matColumnDef="candidate.departure.state">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="departure">Departure</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="((element.candidate.departure != undefined) && (element.candidate.departure.city != undefined) && (element.candidate.departure.state != undefined))?
              (element.candidate.departure.state+ ', '+ element.candidate.departure.city | highlightSearch: filterText):''">
              {{element.candidate.departure}}</td>
          </ng-container>

          <!-- Destination Column -->
          <ng-container matColumnDef="candidate.destination.state">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="destination">Destination</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="((element.candidate.destination != undefined) && (element.candidate.destination.city != undefined) && (element.candidate.destination.state != undefined))?
              (element.candidate.destination.state+ ', '+ element.candidate.destination.city | highlightSearch: filterText):''">
              {{element.candidate.destination}} </td>
          </ng-container>

          <!-- Email Address Column -->
          <ng-container matColumnDef="candidate.emailAddress">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="element.candidate.emailAddress? (element.candidate.emailAddress | highlightSearch: filterText):''">
              {{element.candidate.emailAddress}} </td>
          </ng-container>

          <!-- Business Unit Column -->
          <ng-container matColumnDef="candidate.businessUnit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="Businees unit">Business Unit</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="element.candidate.businessUnit? (element.candidate.businessUnit | highlightSearch: filterText):''">
              {{element.candidate.businessUnit}} </td>
          </ng-container>

          <!-- Level Column -->
          <ng-container matColumnDef="candidate.level">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="level">Level</th>
            <td mat-cell *matCellDef="let element">
              <span [innerHTML]="(element.levelName != undefined || null)?
            (element.levelName | highlightSearch: filterText):''"></span><br />
              <span class="level-description" [innerHTML]="(element.levelDes != undefined || null)?
            (element.levelDes| highlightSearch: filterText):''"></span>
            </td>
          </ng-container>



          <!-- Created By Column -->
          <ng-container matColumnDef="createdBy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="element.candidate.createdBy? (element.candidate.createdBy | highlightSearch: filterText):''">
              {{element.candidate.createdBy}} </td>
          </ng-container>

          <!-- Authorized By Column -->
          <ng-container matColumnDef="authorizedBy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Authorized By</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="element.authorizedBy? (element.authorizedBy | highlightSearch: filterText):''">
              {{element.authorizedBy}} </td>
          </ng-container>

          <!-- Job Start Date Column -->
          <ng-container matColumnDef="jobStartDate">
            <th mat-header-cell *matHeaderCellDef>Job Start Date</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="element.jobStartDate? (element.jobStartDate | highlightSearch: filterText):''">
              {{element.jobStartDate}} </td>
          </ng-container>

          <!-- Move Order Id Column -->
          <ng-container matColumnDef="orderReference">
            <th mat-header-cell *matHeaderCellDef>Order #</th>
            <td mat-cell *matCellDef="let element"
              [innerHTML]="element.orderReference? (element.orderReference | highlightSearch: filterText):''">
              {{element.orderReference}} </td>
          </ng-container>

          <!--Latest Milestone-->
          <ng-container matColumnDef="latestMilestone" stickyEnd>
            <th mat-header-cell *matHeaderCellDef>Latest Milestone</th>
            <td mat-cell *matCellDef="let element">
              <span [innerHTML]="element.mileStone | highlightSearch: filterText"></span><br />
              <span class="small"
                [innerHTML]="element.mileStoneDate? (element.mileStoneDate | highlightSearch: filterText):''"></span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>

          <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            [ngClass]="{highlighted: selection.isSelected(row)}" (click)="navigateToTransfereeDetails(row)"></tr>
        </table>
      </div>
      <div [hidden]="!(ELEMENT_DATA.length>0 && dataSource.filteredData.length>0)">
        <mat-paginator [length]="pageInfo.totalCount" [pageSize]="pageInfo.pageSize" [pageSizeOptions]="[5, 10, 20]"
          (page)="onPagination($event)"></mat-paginator>
      </div>
    </section>
  </div>
  <div [hidden]="!dataSource || (ELEMENT_DATA.length>0 && dataSource.filteredData.length>0)" class="invalidData"
    role="alert">
    No records found
  </div>
</section>