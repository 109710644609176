import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { MovePhase } from '../../models/move-phase';

@Component({
  selector: 'app-multi-step',
  templateUrl: './multi-step.component.html',
  styleUrls: ['./multi-step.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MultiStepComponent implements OnInit {
  /** Input for number of steps **/
  @Input() steps: MovePhase[];
  /** Input for selected step */
  @Input() selected: MovePhase[];
  /**property to hold the move phase */
  active: number;
  constructor() { }

  ngOnInit() {
    for (const element of this.selected) {
      if (element.status === false) {
        this.active = element.order - 1;
        break;
      }
    }
    if (this.selected[this.selected.length - 1].status === true) {
      this.active = this.selected[this.selected.length - 1].order;
    }
  }
}
