/** MOV360-880 - to be removed at future date */
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocationService } from '../../../../core/services/location.service';
import { LevelService } from '../../../../core/services/level.service';
import { Level } from '../../../../core/models/level';
import { Location } from '../../../../core/models/location';
import { Observable, empty, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ApprovedMove } from '../../../../core/models/approved-move';
import { transfereeDetailsTitle } from '../../../../core/models/constants';
import { LoggerService } from '../../../../core/services/logger.service';
import { UserContextService } from '../../../../core/services/user-context.service';

/**
 * Exporting the errormessages
 */
export const errorMessages: { [key: string]: string } = {
  Email: 'You must enter Email address',
  Destination: 'You must select destination',
  Level: 'You must select level'
};

/** Base component for Authorized-Move Component */
@Component({
  selector: 'app-transferee-details',
  templateUrl: './transferee-details.component.html',
  styleUrls: ['./transferee-details.component.scss']
})
export class TransfereeDetailsComponent implements OnInit, OnDestroy {
  /**Form group name */
  transfereeDetailsForm: UntypedFormGroup;
  /**Assign formready Variable as False */
  formReady = false;
  /**Variables for error */
  errors = errorMessages;
  /**Binding the dropdown values to level filed */
  levels: Level[];

  /** auto complete values for destination */
  options: Location[];
  /** variable declared for depature */
  departures: Observable<Location[]>;
  /** variable declared for destinations */
  destinations: Observable<Location[]>;
  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();

  transfereeDetailsTitle = transfereeDetailsTitle;

  /**
   * Initializes form elements
   * @param formBuilder - property for form elements
   * @param dialogRef - property for mat dialog reference
   * @param data - contains popup data
   * @param locationService - Instance of LocationService
   * @param levelService - Instance os LevelService
   */
  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<TransfereeDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ApprovedMove,
    private readonly locationService: LocationService,
    private readonly levelService: LevelService,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: UserContextService
  ) { }

  /**
   * It is for Initializing during Page Load
   */
  ngOnInit() {
    /* Create the Add/Edit dialog window */
    this.transfereeDetailsForm = this.formBuilder.group({
      FirstName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9]*$')
        ])
      ],
      Name: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(30),
          Validators.pattern('^[a-z A-Z0-9]*$')
        ])
      ],
      Email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[a-zA-Z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$')
        ])
      ],
      Departure: [''],
      Destination: ['', Validators.required],
      AmountAuthorized: [''],
      AmountRemaining: ['']
    });

    /* If the data is present - it will open and pre-populate dialog window */
    if (this.data) {
      /* Setting the default values for form elements in edit candidate dialog */
      this.transfereeDetailsForm
        .get('FirstName')
        .setValue(
          this.data.candidate.fullname.slice(
            this.data.candidate.fullname.indexOf(',') + 2,
            this.data.candidate.fullname.length
          )
        );
      this.transfereeDetailsForm.get('Name').setValue(
        `${this.data.candidate.fullname.slice(
          0,
          this.data.candidate.fullname.indexOf(',')
        )}
            " "
            ${this.data.candidate.fullname.slice(
          this.data.candidate.fullname.indexOf(',') + 2,
          this.data.candidate.fullname.length
        )}`
      );
      this.transfereeDetailsForm
        .get('Destination')
        .setValue(this.data.candidate.destination);
      this.transfereeDetailsForm
        .get('Departure')
        .setValue(this.data.candidate.departure);
      this.transfereeDetailsForm
        .get('Email')
        .setValue(this.data.candidate.emailAddress);
      this.transfereeDetailsForm
        .get('AmountAuthorized')
        .setValue(this.data.authorizedAmt);
      this.transfereeDetailsForm
        .get('AmountRemaining')
        .setValue(this.data.authorizedAmt - this.data.remainingAmt);
    }

    /* Get all the locations for destination and departure */
    this.subscription.add(
      this.locationService
        .getLocations()
        .subscribe(location => (this.options = location))
    );

    /* Get all the locations for destination and departure */
    this.levels = this.levelService.getLevels();

    /* Enable the event listener for departure drop down form element */
    this.departures = this.transfereeDetailsForm
      .get('Departure')
      .valueChanges.pipe(
        startWith(''),
        map(name =>
          name
            ? this._filter(name)
            : this.options.length > 0
              ? this.options.slice()
              : this.options
        )
      );
    /* Enable the event listener for the destination drop down form element */
    this.destinations = this.transfereeDetailsForm
      .get('Destination')
      .valueChanges.pipe(
        startWith(''),
        map(name =>
          name
            ? this._filter(name)
            : this.options.length > 0
              ? this.options.slice()
              : this.options
        )
      );
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-TRANSFEREE_DETAILS');
      });

  }
  /**
   *  Modified incoming value to lowerCase and assigned to const variable filterValue
   * @param value - start character for filter values
   */
  private _filter(value: string): Location[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  /**
   * Closing the dialog box - we are setting the form to empty
   */
  closeWindow(evt): void {
    evt.preventDefault();
    this.dialogRef.close();

    this.transfereeDetailsForm = this.formBuilder.group({
      FirstName: ['', Validators.required],
      Name: [
        '',
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(30)
      ],
      Email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[a-zA-Z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$')
        ])
      ],
      Departure: [''],
      Destination: ['', Validators.required],
      AmountAuthorized: ['', Validators.required],
      AmountRemaining: ['', Validators.required]
    });
  }

  /**
   * Custom error messages for Firstname, lastname and Email to verify special character or empty errors
   * @param fieldName - field parameter to check for errors
   */
  getErrorMessage(fieldName) {
    if (fieldName === 'FIRST_NAME') {
      return this.transfereeDetailsForm.get('FirstName').hasError('required')
        ? 'You must enter first name'
        : this.transfereeDetailsForm.get('FirstName').hasError('pattern')
          ? 'Special characters are not allowed'
          : '';
    }
    if (fieldName === 'NAME') {
      return this.transfereeDetailsForm.get('Name').hasError('required')
        ? 'You must enter a name'
        : this.transfereeDetailsForm.get('Name').hasError('pattern')
          ? 'Special characters are not allowed'
          : '';
    }
    if (fieldName === 'EMAIL') {
      return this.transfereeDetailsForm.get('Email').hasError('required')
        ? 'You must enter email address'
        : this.transfereeDetailsForm.get('Email').hasError('pattern')
          ? 'You must enter a valid email address'
          : '';
    }
  }

  /**
   * destroys the object
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
