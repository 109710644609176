<div class="transfereeDetailsFormContainer" flexLayout fxLayout.xs="column">
  <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
    fxLayoutGap="20px" fxLayoutGap.xs="0em">
    <h2>{{transfereeDetailsTitle}}</h2>
    <a href="#" class="close-icon" (click)="closeWindow($event)" title="close">
      <mat-icon>close</mat-icon>
    </a>
  </div>

  <mat-dialog-content>
    <form fxLayout="column" [formGroup]="transfereeDetailsForm">
      <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
        fxLayoutGap.xs="0em">
        <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
          <mat-form-field>
            <input matInput placeholder="Name" formControlName="Name">
            <mat-error>
              {{ getErrorMessage('NAME') }}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
          <mat-form-field fxFlex>
            <input matInput type="text" placeholder="Email" formControlName="Email">
            <mat-error>
              {{ getErrorMessage('EMAIL') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0"
        style="margin-top: 0.9em;">
        <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
          <mat-form-field>
            <input type="text" placeholder="Departure (Optional)" matInput formControlName="Departure"
              [matAutocomplete]="departureList">
            <mat-autocomplete #departureList="matAutocomplete">
              <mat-option *ngFor="let departure of departures | async" [value]="departure.name">
                {{departure.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div fxFlex fxLayout.xs="column" class="hide-arrow-xs">
          <em class="material-icons modal-rightnavarrow">
            arrow_forward_ios
          </em>
        </div>
        <div fxFlex="45%" fxLayout.xs="column" fxFlex.xs="100%">
          <mat-form-field>
            <input type="text" placeholder="Destination" matInput formControlName="Destination"
              [matAutocomplete]="destinationList">
            <mat-autocomplete #destinationList="matAutocomplete">
              <mat-option *ngFor="let destination of destinations | async" [value]="destination.name">
                {{destination.name}}
              </mat-option>
            </mat-autocomplete>
            <mat-error>
              {{ errors.Destination }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
        fxLayoutGap.xs="0em" style="margin-top: 0.9em;">
        <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
          <mat-form-field>
            <input matInput placeholder="Amount Authorized" formControlName="AmountAuthorized">
            <mat-error>
              {{ getErrorMessage('AMOUNT_AUTHORIZED') }}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
          <mat-form-field>
            <input matInput placeholder="Amount Remaining" formControlName="AmountRemaining">
            <mat-error>
              {{ getErrorMessage('AMOUNT_REMAINING') }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
        fxLayoutGap.xs="0em" style="margin-top: 0.9em;">
        <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
          <div style="border: 1px solid #707070;border-radius: 4px;text-align: center;">
            <p>Housing Details</p>
            <p>Housing Type: Rent</p>
            <p>#Bedrooms: 4</p>
          </div>
        </div>
        <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%" class="people-container">
          <div style="border: 1px solid #707070;border-radius: 4px;text-align: center;">
            <p>Amount of People</p>
            <p>Total: 4</p>
            <p>#Children: 2</p>
          </div>
        </div>
      </div>
      <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
        fxLayoutGap.xs="0em" style="margin-top: 0.9em;">
        <div fxFlex="100%" fxLayout.xs="column" fxFlex.xs="100%">
          <mat-accordion>
            <mat-expansion-panel style="border: 1px solid #707070;box-shadow: none;">
              <mat-expansion-panel-header class="disable_ripple">
                <mat-panel-title>
                  Activity History
                </mat-panel-title>
                <mat-panel-description>

                </mat-panel-description>
              </mat-expansion-panel-header>
              <p>Activity History Section</p>
              <p>Coming Soon....</p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="closeWindow($event)">Ok</button>
  </mat-dialog-actions>
</div>