/** MOV360-880 - to be removed at future date */
import { Component, OnInit, ViewEncapsulation, Inject, EventEmitter, Output, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService } from '../../../../core/services/notifications.service';
import { BudgetDetailsService } from '../../../../core/services/budget-details.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { DateAdapterService } from '../../../../core/services/date-adapter.service';
import { APP_DATE_FORMATS, apiErrorMessage } from '../../../../core/models/constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { PartySharedService } from '../../../../core/services/party-shared.service';
import { LoggerService } from '../../../../core/services/logger.service';
import { UserContextService } from '../../../../core/services/user-context.service';

/** base component for authorize transfer modal */
@Component({
  selector: 'app-authorize-transfer',
  templateUrl: './authorize-transfer.component.html',
  styleUrls: ['./authorize-transfer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: DateAdapter, useClass: DateAdapterService },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS
    }
  ]
})
export class AuthorizeTransferComponent implements OnInit, OnDestroy {
  /**flag to check the transferee authorization */
  isTransfereeAuthorized: boolean;
  /**property to store Job Start Date */
  jobStartOn: Date;
  /**property to store invoice details */
  invoiceDetails: any;
  /**property for min Date */
  minStartDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate());
  /** Stores the client contact ID */
  clientContactId: string;
  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /**
   * base constructor
   * @param data data received from calling component
   * @param dialogRef - property for mat dialog reference
   * @param budgetDetailsService - budget service
   * @param notificationsService - snackbar service
   * @param snackBar to display snack bar
   * @param spinner - ngx spinner service
   */

  /**variable to store electronicPaymentInstructions */
  electronicPaymentInstructions: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AuthorizeTransferComponent>,
    private readonly budgetDetailsService: BudgetDetailsService,
    private readonly notificationsService: NotificationsService,
    private readonly snackBar: MatSnackBar,
    private readonly spinner: NgxSpinnerService,
    private readonly partySharedSvc: PartySharedService,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: UserContextService
  ) { }

  /** function to be executed on init */
  ngOnInit() {
    this.invoiceDetails = this.data.invoiceDetails;
    this.partySharedSvc.getPartyId().then(id => { this.clientContactId = id; });
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-AUTHORIZE_TRANSFER');
      });
  }
  /**
   * Closing the dialog box
   */
  close(evt): void {
    evt.preventDefault();
    this.dialogRef.close();
  }
  /** function to be called if Authorize Tranfer button is clicked */
  authorizeTransferee() {
    this.spinner.show();
    const transfereeDetails = {
      candidateId: this.data.candidateID,
      clientContactId: this.clientContactId,
      jobStartDate: this.jobStartOn,
      status: 'Payment Pending',
      invoiceId: this.invoiceDetails.invoiceId,
      authorizedAmt: Math.ceil(this.data.amountDue).toString(),
      taxAssistanceNeeded: this.data.isTaxEnabled
    };
    this.subscription.add(
      this.budgetDetailsService.authorizeTransferee(transfereeDetails).subscribe(result => {
        if (result) {
          this.spinner.hide();
          if (result.status === 200) {
            this.isTransfereeAuthorized = true;
            this.notificationsService.flashNotification(
              'success',
              'You will receive a notification  on receipt of payment by Cartus'
            );
            this.dialogRef.close(this.isTransfereeAuthorized);
          }
        } else {
          this.spinner.hide();
          this.snackBar.open(
            apiErrorMessage,
            undefined,
            { duration: 5000 }
          );
        }
      })
    );
  }

  /**
   * destroys the object
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
