/** MOV360-880 - to be removed at future date */
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidationErrors, ValidatorFn, AbstractControl, FormControl } from '@angular/forms';
import { Extension } from 'src/app/core/models/extension.model';
import { Subscription } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoggerService } from 'src/app/core/services/logger.service';
import { UserContextService } from 'src/app/core/services/user-context.service';
import { Router } from '@angular/router';
import { ExtensionService } from 'src/app/core/services/extension.service';
import { phoneErrorMessage, errorMessage, validPhoneErrorMessage } from 'src/app/core/models/constants';
import { SupplierContact } from 'src/app/core/models/supplier-contact.model';
import { SupplierRole } from 'src/app/core/models/supplier-role.model';
import { SupplierRoleService } from 'src/app/core/services/supplier-role.service';
import * as moment from 'moment';
import { tryParse } from 'selenium-webdriver/http';
import { SupplierContactService } from 'src/app/core/services/supplier-contact.service';
import { SupplierContactResponse } from 'src/app/core/models/supplier-contact-response.model';

export const errorMessages: { [key: string]: string } = {
  Extension: 'You must select Extension',
  Supplier: 'You must select Supplier'
};

@Component({
  selector: 'app-add-edit-supplier-contact',
  templateUrl: './add-edit-supplier-contact.component.html',
  styleUrls: ['./add-edit-supplier-contact.component.scss']
})
export class AddEditSupplierContactComponent implements OnInit, OnDestroy {

  isEmptySupplierInfo = true;
  days = 30;
  errorMessage = 'We are unable to process your request at this time. Please try again later.';
  Supplier: Array<any> = [];
  selected: any;
  editsupplierContact: UntypedFormGroup;
  /**property to store Job Start Date */
  jobStartOn: Date;
  /**property to store invoice details */
  invoiceDetails: any;
  /** Stores the client contact ID */
  clientContactId: string;
  matExpansionHeaderHeight: string;
  isPhoneNumberValid = false;
  isExtensionValid = false;
  isEmailValid = false;
  extensions: Extension[];
  defaultExtension: any;
  errors = errorMessages;
  supplierContact: SupplierContact = {} as SupplierContact;
  supplierRoles: SupplierRole[];
  supplierDetails: any;
  selectedExt: any;
  supplierContactStatus: string;
  supplierContactExtension: string;
  sortArray: Array<any>;
  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /**
   * base constructor
   * @param data data received from calling component
   * @param dialogRef - property for mat dialog reference
   * @param notificationsService - snackbar service
   * @param snackBar to display snack bar
   * @param spinner - ngx spinner service
   */

  /**variable to store electronicPaymentInstructions */
  electronicPaymentInstructions: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddEditSupplierContactComponent>,
    private readonly notificationsService: NotificationsService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly snackBar: MatSnackBar,
    private readonly spinner: NgxSpinnerService,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: UserContextService,
    private readonly supplierRoleService: SupplierRoleService,
    private router: Router,
    private extnSvc: ExtensionService,
    private readonly supplierContactService: SupplierContactService
  ) {
    this.supplierRoles = this.supplierRoleService.getRoles();
  }

  /** function to be executed on init */
  ngOnInit() {
    this.isEmptySupplierInfo = this.data.isEmptySupplierInfo;
    this.sortArray = this.data.activeSupplier;
    for (const arr of this.sortArray) {
      this.Supplier.push({
        name: arr.preferredName ? arr.preferredName : arr.entityName,
        id: arr._id
      });
    }
    this.Supplier = this.Supplier.sort((x, y) => {
      return ((x.name === y.name) ? 0 : ((x.name > y.name) ? 1 : -1));
    });
    this.editsupplierContact = this.formBuilder.group({
      firstname: ['',
        Validators.compose([Validators.required, this.noWhitespaceValidator])],
      lastname: ['',
        Validators.compose([Validators.required, this.noWhitespaceValidator])],
      emailAddress: ['',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+(?:\\.[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])$'),
          this.noWhitespaceValidator
        ])],
      phoneNumber: ['',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(10),
          this.regexValidator(new RegExp(/[A-Za-z]/g), { 'alphabets': true }),
          this.regexValidator(new RegExp(/[!@#$%^&*()/\\?,.?":{}\-\+=_|<>;'`~\]\[ ]/g), { 'specialCharacter': true }),
          this.noWhitespaceValidator
        ])],
      supplier: ['', Validators.required],
      Extension: ['', Validators.required]
    });

    this.extnSvc.extensionList$.subscribe(extension => {
      if (extension) {
        this.extensions = extension;
        this.defaultExtension = this.extensions.find(x => (x.name === 'United States' || x.name === 'Canada'));
        if (this.data.supplierContactDetails && this.data.supplierContactDetails.countryDialingCode) {
          this.editsupplierContact.get('Extension')
            .setValue(`+${this.data.supplierContactDetails.countryDialingCode}`);
        } else {
          if (this.defaultExtension) {
            this.editsupplierContact.controls.Extension
              .setValue(this.defaultExtension.countryDialingCode);
          }
        }
      }
      this.extensions = extension;
    });
    if (!this.isEmptySupplierInfo) {
      this.setData();
    }
  }

  /* bind the values for edit-supplier modal */
  setData() {
    this.editsupplierContact.get('firstname').setValue(this.data.supplierContactDetails.firstName);
    this.editsupplierContact.get('lastname').setValue(this.data.supplierContactDetails.lastName);
    this.editsupplierContact.get('emailAddress').setValue(this.data.supplierContactDetails.emailAddress);
    this.editsupplierContact.get('phoneNumber').setValue(this.data.supplierContactDetails.phoneNumber);
    this.editsupplierContact.get('supplier').setValue(this.data.supplierContactDetails.supplierName);
    this.supplierContactStatus = this.data.supplierContactDetails.status;
    this.supplierContactExtension = this.data.supplierContactDetails.countryDialingCode;
  }
  /**
   * Closing the dialog box
   */
  close(event): void {
    event.preventDefault();
    event.stopPropagation();
    this.dialogRef.close();
  }

  /** function to be called if Send Invite button is clicked */
  sendInvite(event) {
    event.preventDefault();
    event.stopPropagation();
    this.spinner.show();
    this.supplierContact.status = 'Invitation Sent';
    this.populateSupplierContact();
    this.sendInviteToSupplierContact();
  }

  sendInviteToSupplierContact() {
    this.supplierContactService.sendInviteToSupplierContact(this.supplierContact).subscribe((response: SupplierContactResponse) => {
      this.spinner.hide();
      if (response !== null && response.statusCode === 200) {
        this.supplierContact.supplierContactId = response.supplierContactId;
        this.supplierContact.inviteSent = true;
        this.flashAndCloseDialog(response.message);
      } else {
        this.dialogRef.close(true);
        this.notificationsService.flashNotification(
          'success',
          errorMessage,
          true,
          'dismiss'
        );
      }
    });
  }

  saveDraft(event) {
    event.preventDefault();
    event.stopPropagation();
    this.spinner.show();
    this.supplierContact.status = 'Invitation Not Sent';
    this.populateSupplierContact();
    this.supplierContactService.createSupplierContact(this.supplierContact).subscribe((response: SupplierContactResponse) => {
      this.spinner.hide();
      if (response !== null && response.statusCode === 200) {
        this.supplierContact.supplierContactId = response.supplierContactId;
        this.supplierContact.inviteSent = true;
        this.flashAndCloseDialog(response.message);
      } else {
        this.dialogRef.close(true);
        this.notificationsService.flashNotification(
          'success',
          errorMessage,
          true,
          'dismiss'
        );
      }
    });
  }

  setToInactive(event) {
    event.preventDefault();
    event.stopPropagation();
    this.dialogRef.close('InActivate');
  }

  saveUpdates(event) {
    event.preventDefault();
    event.stopPropagation();
    this.spinner.show();
    this.supplierContact.status = this.supplierContact.status;
    this.populateSupplierContact();
    this.supplierContactService.updateSupplierContact(this.supplierContact).subscribe((response: SupplierContactResponse) => {
      this.spinner.hide();
      if (response !== null && response.statusCode === 200) {
        this.supplierContact.supplierContactId = response.supplierContactId;
        this.supplierContact.inviteSent = true;
        this.flashAndCloseDialog(response.message);
      } else {
        this.dialogRef.close(true);
        this.notificationsService.flashNotification(
          'success',
          errorMessage,
          true,
          'dismiss'
        );
      }
    });
  }

  populateSupplierContact() {
    const dateString = moment(new Date()).format('YYYY-MM-DD');
    const roleData = this.supplierRoleService.getRoles().find(item => item.displayName === 'Supplier Contact');
    const role = roleData.roleName;
    const idVal = this.findID(this.editsupplierContact.get('supplier').value, this.Supplier);
    this.supplierContact = {
      'firstName': this.editsupplierContact.get('firstname').value ? this.editsupplierContact.get('firstname').value : '',
      'lastName': this.editsupplierContact.get('lastname').value ? this.editsupplierContact.get('lastname').value : '',
      'emailAddress': this.editsupplierContact.get('emailAddress').value ? this.editsupplierContact.get('emailAddress').value : '',
      'phoneNumber': this.editsupplierContact.get('phoneNumber').value ? this.editsupplierContact.get('phoneNumber').value : '',
      'countryDialingCode': (this.editsupplierContact.get('Extension').value && this.editsupplierContact.get('phoneNumber').value) ?
        this.editsupplierContact.get('Extension').value : this.defaultExtension.countryDialingCode ?
          this.defaultExtension.countryDialingCode : '',
      'status': this.supplierContact.status,
      'supplierId': idVal,
      'role': role,
      'isDailyOperationsContact': false,
      'invitedAsSupplierContact': true,
      'statusDate': dateString,
      'createdAt': dateString,
      'isDeleted': false
    };
    if (this.supplierContact.supplierContactId) {
      this.supplierContact = { ...this.supplierContact, ...{ 'supplierContactId': this.supplierContact.supplierContactId } };
    }
    if (this.data.supplierContactDetails && this.data.supplierContactDetails.supplierContactId) {
      this.supplierContact = { ...this.supplierContact, ...{ 'supplierContactId': this.data.supplierContactDetails.supplierContactId } };
    }
  }

  findID(suppValue, supplierList) {
    let id = '';
    if (suppValue) {
      const supp = supplierList.find(item => item.name === suppValue);
      id = supp.id ? supp.id : '';
    }
    return id;
  }

  /**
   * function to flash message and close dialog
   * @param response string
   */
  flashAndCloseDialog(message: string) {
    this.dialogRef.close(true);
    this.notificationsService.flashNotification(
      'success',
      message,
      true,
      'dismiss'
    );
  }

  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = control.value.match(regex);
      if (valid) {
        return error;
      } else {
        return null;
      }
    };
  }

  getErrorMessage(fieldName): string {
    const charNotAllowed = 'Special characters are not allowed';
    if (fieldName === 'FIRST_NAME') {
      return this.editsupplierContact.get('firstname').hasError('required')
        ? 'You must enter first name'
        : this.editsupplierContact.get('firstname').hasError('pattern')
          ? charNotAllowed
          : '';
    }
    if (fieldName === 'LAST_NAME') {
      return this.editsupplierContact.get('lastname').hasError('required')
        ? 'You must enter last name'
        : this.editsupplierContact.get('lastname').hasError('pattern')
          ? charNotAllowed
          : '';
    }
    if (fieldName === 'PHONE_NUMBER') {
      return this.editsupplierContact.get('phoneNumber').hasError('required')
        ? phoneErrorMessage
        : this.editsupplierContact.get('phoneNumber').hasError('specialCharacter')
          ? charNotAllowed
          : this.editsupplierContact.get('phoneNumber').hasError('pattern')
            ? validPhoneErrorMessage
            : this.editsupplierContact.get('phoneNumber').hasError('alphabets')
              ? validPhoneErrorMessage
              : this.editsupplierContact.get('phoneNumber').hasError('minlength')
                ? validPhoneErrorMessage
                : '';
    }
    if (fieldName === 'EMAIL_ADDRESS') {
      return this.editsupplierContact.get('emailAddress').hasError('required')
        ? 'You must enter email address'
        : this.editsupplierContact.get('emailAddress').hasError('pattern')
          ? 'You must enter valid email address'
          : '';
    }
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  /**
   * destroys the object
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
