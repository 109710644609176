<div class="dialogMainContainer-withdraw" flexLayout fxLayout.xs="column">
    <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
        fxLayoutGap="20px" fxLayoutGap.xs="0em">
        <div class="candidate-dialogtitle">{{ title }}</div>
        <a href="#" class="close-icon" (click)="close($event)" id="btnCloseIcon" title="close">
            <mat-icon>close</mat-icon>
        </a>
    </div>
    <mat-dialog-content class="withdraw-popup-content middle-section">
        {{ displayText1 }}
        <div *ngFor="let contact of data.selected">
            <div class="candidate-name-highlightbg">{{contact.fullName}}</div>
        </div>
        {{ displayText2 }}
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="statusBtn">
            <button mat-button color="primary" class="mat-button text-button uppercase"
                (click)="close($event)" id="cancelbtn">Cancel</button>
            <button mat-button class="mat-button contained-button" (click)="inactivateSupplierContacts()" id="inActivateSupplierContactBtn">Inactivate
                <span *ngIf="data.selected.length===1" class=" cost-model-btn-txt">Contact</span><span
                    *ngIf="data.selected.length>1" class=" cost-model-btn-txt">{{data.selected.length}}&nbsp;Contacts</span></button>
        </div>
    </mat-dialog-actions>
</div>