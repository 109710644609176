
import { Component, HostBinding, Inject, OnInit, ViewEncapsulation, ViewChild, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { deepCopy } from '@angular-devkit/core/src/utils/object';
import { RoleCapabilityService } from '../../../services/role-capability.service';
import { RolecapsCapSearchComponent } from '../rolecaps-cap-search/rolecaps-cap-search.component';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSelectChange } from '@angular/material/select';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { Capability } from 'src/app/rolecaps/models/rolecapability.model';
import { ConfirmDialogComponent } from 'src/app/core/components/shared/confirm-dialog/confirm-dialog.component';
import { ROLE_CAP_MAPPING_TITLE, CANCEL_DIALOG_TITLE, CANCEL_DIALOG_LINES, EDIT_ROLE_CAP_ERROR, EDIT_ROLE_CAP_SUCCESS,
         ROLE_CAP_VERSION_ERROR, EDIT_ROLE_CAP_DIALOG_TITLE, EDIT_ROLE_CAP_DIALOG_LINES, REMOVE_CAP_DIALOG_TITLE,
         REMOVE_CAP_DIALOG_LINES, 
         OPERATION_OPTIONS} from 'src/app/rolecaps/models/constants';

@Component({
  selector: 'app-rolecaps-role-mapping',
  templateUrl: './rolecaps-role-mapping.component.html',
  styleUrls: ['./rolecaps-role-mapping.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class RolecapsRoleMappingComponent implements OnInit, AfterContentChecked {

  /** Component css class */
  @HostBinding('class') class = 'rolecaps-role-mapping';

  /** Page Title */
  title = ROLE_CAP_MAPPING_TITLE;

  /** To check for readonly access */
  disable = false;

  /** The capability being edited */
  editRoleCap;

  /** Definition only flag */
  definitionOnly: boolean = false;

  /** Capability has changes */
  hasChanges: boolean = false;

  /** Store current value of opened select */
  currentSelectValue = '';

  /** capabilityTitle */
  capabilityTitle = '';

  /** Table data */
  ELEMENT_DATA: Array<Capability> = [];

  /** Table datasource */
  dataSource: MatTableDataSource<Capability>;

  /** Displayed table columns */
  displayedColumns: string[] = [
    'name',
    'description',
    'operation',
    'permission',
    'actions'
  ];

  /** Table Pagination */
  pageInfo = {
    itemCount: 0,
    currentPage: 0,
    perPage: 5,
    perPageOptions: [5, 10, 15]
  };

  /** Initial table sort by column */
  sortBy = 'name';

  /** Initial table sort direction */
  sortDir = 'asc';

  /** Search filter value */
  filterText = '';

  /** Service subscription  */
  private readonly subscription: Subscription = new Subscription();

  /** Capability Permission options */
  permissionOptions = ['allow', 'deny'];

  /** Capability Operation options */
  operationOptions = OPERATION_OPTIONS;

  /** Confirm Dialog Ref */
  confirmDialogRef: MatDialogRef<any>;

  /** Processing */
  processing = false;

  /** To sort the mat table columns */
  @ViewChild(MatSort, { static: false }) set sortOrder(sort: MatSort) {
    if (sort) {
      this.dataSource.sort = sort;
    }
  }

  /** To paginate in a mat table */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  /** Injecting dependencies */
  constructor(
    private readonly roleCapabilityService: RoleCapabilityService,
    private readonly notificationsService: NotificationsService,
    private readonly loggerService: LoggerService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<RolecapsRoleMappingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef
  ) {
    if (this.data.roleCap) {
      this.editRoleCap = deepCopy(this.data.roleCap);
      this.capabilityTitle = `${this.editRoleCap.roleName} (${this.editRoleCap.friendlyName})`;
      if (this.data.hasOwnProperty('definitionOnly')) {
        this.definitionOnly = this.data.definitionOnly;
      }
      if (this.definitionOnly) {
        delete this.editRoleCap._id;
        delete this.editRoleCap.definitionType;
        this.editRoleCap.capabilities = [];
      }
      if (!this.editRoleCap.capabilities) {
        this.editRoleCap.capabilities = [];
      }
      this.ELEMENT_DATA = this.editRoleCap.capabilities;
    }
  }

  /** To initialize the component */
  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    this.pageInfo.itemCount = this.ELEMENT_DATA.length;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
  }

  /** To detect changes after initialization */
  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  /**
   * Sorting accessor
   * @param item Capability
   * @param property string
   */
  sortingDataAccessor(item: Capability, property: string) {
    return item[property].toLocaleLowerCase();
  }

  /**
   * Table Pagination
   * @param event PageEvent
   */
  onPagination(event: PageEvent) {
    this.pageInfo.perPage = event.pageSize;
  }

  /**
   * To check for readonly access
   * @param flag boolean
   */
  isDisabled(flag: boolean) {
    this.disable = flag;
  }

  /**
   * Store current value of opened select
   * @param value string
   */
  onMatSelectOpen(value: string): void {
    this.currentSelectValue = value;
  }

  /**
   * Compare changed value of opened select
   * @param event MatSelectChange 
   */
  onMatSelectValueChanges(event: MatSelectChange): void {
    if (this.currentSelectValue !== event.value) {
      this.hasChanges = true;
    }
  }

  /** Add Capability */
  openAddCapDialog() {
    this.confirmDialogRef = this.dialog.open(RolecapsCapSearchComponent, {
      disableClose: true,
      panelClass: 'dialog-main',
      data: { editRoleCap: this.editRoleCap },
      autoFocus: false,
      restoreFocus: false
    });
    this.subscription.add(
      this.confirmDialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.refreshData();
          this.hasChanges = true;
        }
      })
    );
  }

  /**
   * Remove Capability confirmation
   * @param capability Capability
   */
  openRemoveCapConfirm(capability: Capability) {
    const dialogLines = [...REMOVE_CAP_DIALOG_LINES];
    dialogLines.push(capability.name);
    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      panelClass: 'dialogMainContainer',
      data: { dialogTitle: REMOVE_CAP_DIALOG_TITLE, dialogLines },
      autoFocus: false,
      restoreFocus: false
    });
    this.subscription.add(
      this.confirmDialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.removeCap(capability);
        }
      })
    );
  }

  /**
   * Remove Capability from Role Capability
   * @param capability Capability
   */
  removeCap(capability: Capability) {
    const capIndex = this.editRoleCap.capabilities.findIndex(item => item === capability);
    this.editRoleCap.capabilities.splice(capIndex, 1);
    this.refreshData();
    this.hasChanges = true;
  }

  /** Cancel confirmation */
  openCancelConfirm() {
    if (this.hasChanges) {
      const dialogLines = [...CANCEL_DIALOG_LINES];
      this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        panelClass: 'dialogMainContainer',
        data: { dialogTitle: CANCEL_DIALOG_TITLE, dialogLines },
        autoFocus: false,
        restoreFocus: false
      });
      this.subscription.add(
        this.confirmDialogRef.afterClosed().subscribe((result: boolean) => {
          if (result) {
            this.cancel();
          }
        })
      );
    } else {
      this.cancel();
    }
  }

  /** To close the modal without saving changes */
  cancel() {
    this.dialogRef.close();
  }

  /** Refresh the table datasource */
  refreshData() {
    this.dataSource.data = this.dataSource.data;
  }

  /** Update Role Capability confirmation */
  openUpdateRoleCapConfirm() {
    const dialogLines = [...EDIT_ROLE_CAP_DIALOG_LINES];
    dialogLines.push(this.editRoleCap.roleName);
    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      panelClass: 'dialogMainContainer',
      data: { dialogTitle: EDIT_ROLE_CAP_DIALOG_TITLE, dialogLines },
      autoFocus: false,
      restoreFocus: false
    });
    this.subscription.add(
      this.confirmDialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.updateRoleCap();
        }
      })
    );
  }

  /** Update the Role Capability*/
  async updateRoleCap() {
    this.processing = true;
    let observable: Observable<any>;
    if (this.definitionOnly) {
      observable = this.roleCapabilityService.createRoleCapability(this.editRoleCap);
    } else {
      observable = this.roleCapabilityService.updateRoleCapability(this.editRoleCap._id, this.editRoleCap);
    }
    observable.subscribe({
      next: response => {
        if (response && response.status === 200) {
          this.notificationsService.flashNotification('success', EDIT_ROLE_CAP_SUCCESS);
          const updatedCap = response.body;
          this.dialogRef.close(updatedCap);
        } else {
          this.notificationsService.flashNotification('danger', this.processError(response));
        }
      },
      error: error => {
        this.loggerService.error(EDIT_ROLE_CAP_ERROR, error);
        this.notificationsService.flashNotification('danger', this.processError(error));
      },
      complete: () => {
        this.processing = false;
      }
    });
  }

  /**
   * Translate API errors into user friendly messages
   * @param err any
   */
  processError(err: any): string {
    let errString = '';
    if (err instanceof Error) {
      errString = err.toString();
    } else {
      errString = JSON.stringify(err);
    }
    if (errString.toLocaleLowerCase().indexOf('no matching document found for id') > -1) {
      return ROLE_CAP_VERSION_ERROR;
    }
    return EDIT_ROLE_CAP_ERROR;
  }

}
