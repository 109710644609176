<div class="vanline-popup" aria-modal="true">
    <div class="title" mat-dialog-title>
        <h1 class="vanline-popup-header" tabindex="0">Professional Van Line Move Total</h1>
        <a  title="close" (click)="onCancel()" class="closeIcon">
            <mat-icon matSuffix class="" style="font-size: 17px;">close</mat-icon>
        </a>
    </div>
    <div class="content">
        <div class='total-info'>Total Costs: <b>{{data.data.totalVanlineCost}} USD</b></div>
        <hr/>

        <div class='breakdown-info'>
            Van Line Move Cost<br/>
            <div class="breakdown-info-data">{{data.data.lockedInPrice}} USD</div>
        </div>

        <div class='breakdown-info'>
            Specialty Services<br/>
            <div class="breakdown-info-data">{{data.data.nonDiscountableThirdPartyServices}} USD</div>
        </div>

        <div class='breakdown-info'>
            Other Services<br/>
            <div class="breakdown-info-data">{{data.data.nonDiscountableOthers}} USD</div>
        </div>
        <hr/>
    </div>
    <mat-dialog-actions class="btnAction">
        <button class="transferee-contained-button cancelBtn" (click)="onCancel()">Close</button>        
    </mat-dialog-actions>
</div>