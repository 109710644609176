/** MOV360-880 - to be removed at future date */
import { ComponentPortal } from '@angular/cdk/portal';
import { AuthorizedMovePhaseComponent } from './../../../core/components/authorized-move-phase/authorized-move-phase.component';
import { PopupPositionService } from './../../../core/services/popup-position.service';
import {
  Component,
  OnInit,
  ViewChild,
  Input,
  SimpleChanges,
  OnDestroy,
  OnChanges,
  ElementRef
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { ApprovedMove } from '../../../core/models/approved-move';
import { ApprovedMovesService } from '../../../core/services/approved-moves.service';
import { FinanceColumnsComponent } from '../finance-audit/finance-columns/finance-columns.component';
import { Selection } from '../../../core/models/selection.model';
import { Subscription, of } from 'rxjs';
import { Router } from '@angular/router';
import { OverlayConfig, OverlayRef, Overlay } from '@angular/cdk/overlay';
import { clientContactIDMock } from '../../../../UnitTest-Support/Mocks/data.mock';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatNumber, DatePipe } from '@angular/common';
import { PartySharedService } from '../../../core/services/party-shared.service';
import { LoggerService } from '../../../core/services/logger.service';
import { UserContextService } from '../../../core/services/user-context.service';
import * as querystring from 'querystring';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { apiErrorMessage, skipRecordCount } from '../../../core/models/constants';
import { UserContactInfo } from 'src/app/core/models/user-contact-info.model';
import { UserContactInfoComponent } from './user-contact-info/user-contact-info.component';
import * as moment from 'moment';

/** Base component for Authorized-Move Component */
@Component({
  selector: 'app-finance-audit',
  templateUrl: './finance-audit.component.html',
  styleUrls: ['./finance-audit.component.scss'],
})
export class FinanceAuditComponent implements OnInit, OnDestroy {
  /** Input prop for receiving data*/
  @Input() selectedCols: Selection[];
  /**variable to store sort order */
  sortStatement = '';
  /**flag for cheching account manager role */
  disableButton = false;
  /** To sort the mat table columns */
  @ViewChild(MatSort, { static: false }) set sortOrder(sort: MatSort) {
    if (sort) {
      this.dataSource.sort = sort;
    }
  }
  /** To paginate in a mat table */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  /** initially Displaying Columns */
  displayedColumns: string[] = [
    'candidate.fullname',
    'clientName',
    'orderReference',
    'authorizedAmtFormat',
    'auditStatus'
  ];
  /** Used to store Movement Approved Candidate list */
  ELEMENT_DATA: ApprovedMove[] = [];
  /** Used to Store Instance of MatTableDataSource contains Candidate data */
  dataSource: any;
  /** Used to store filter value */
  filterText = '';
  /** Stores the selected Columns */
  columnList: Selection[] = [];
  /** Input prop for receiving data*/
  selection = new SelectionModel<ApprovedMove>(true, []);
  /** To unsubscribe the service */
  subscription: Subscription = new Subscription();
  /** overlayRef to hold overlay config */
  overlayRef: OverlayRef;
  /**property to hold active move phase label */
  activeLabel: string;
  /** Used for sorting */
  sortBy = '';
  /** sorting direction */
  sortDir = '';
  /**variable to store client contact id */
  clientContactId: string;
  userContactInfoData: any;
  /**page information */
  pageInfo = {
    pageSize: 20,
    pageIndex: 0,
    totalCount: 0
  };
  /**
   * Heading to be focused first
   */
  @ViewChild('pageheading', { static: false }) pageheading: ElementRef;
  /**
   * Initializes the value
   * @param dialog Instance for MatDialog
   * @param aprovedMovesService Instance for ApprovedMovesService
   * @param _router object for Router
   * @param spinner Object for Spinner
   * @param overlay object for Overlay
   * @param positionService object for PositionService
   * @param datePipe object for DatePipe
   * @param loggedInUserService Object for LoggedInService
   * @param Logger Object for LoggerService
   * @param snackBar Object for Snackbar
   * @param partySharedSvc Object for PartySharedService
   */
  constructor(
    public dialog: MatDialog,
    private readonly aprovedMovesService: ApprovedMovesService,
    private readonly _router: Router,
    private readonly overlay: Overlay,
    private readonly positionService: PopupPositionService,
    private readonly spinner: NgxSpinnerService,
    private readonly datePipe: DatePipe,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: UserContextService,
    public readonly snackBar: MatSnackBar,
    private readonly partySharedSvc: PartySharedService
  ) { }

  /**
   * It is for Initializing during Page Load
   */
  ngOnInit() {
    this.aprovedMovesService.counter = 0;
    this.partySharedSvc.getPartyId().then(id => {
      if (id) {
        this.clientContactId = id;
        this.loggedInUserService.getLoggedInUserDetails()
          .subscribe(response => {
            const userId: any = response.userId.replace(/ .*/, '');
            this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-AUTHORIZED_MOVE');
          });
        setTimeout(() => {
          this.pageheading.nativeElement.focus();
        }, 2000);
        this.loadCandidates(this.pageInfo.pageSize, this.pageInfo.pageIndex);
      }
    });
    this.aprovedMovesService.counter--;
    if (this.aprovedMovesService.counter === 0) {
      this.spinner.hide();
    }
  }

  /**
   * method for server side sort
   * @param event sort event
   */
  sortData(event) {
    switch (event.active) {
      case 'candidate.fullname':
        event.active = 'fullname';
        break;
      case 'authorizedAmtFormat':
        event.active = 'authorizedAmount';
        break;
      case 'candidate.departure.state':
        event.active = 'departure';
        break;
      case 'candidate.destination.state':
        event.active = 'destination';
        break;
      case 'candidate.businessUnit':
        event.active = 'businessUnit';
        break;
      case 'candidate.level':
        event.active = 'level';
        break;
    }
    switch (event.direction) {
      case 'asc':
        this.sortStatement = `sorting ${event.active} in ascending order`;
        break;
      case 'desc':
        this.sortStatement = `sorting ${event.active} in descending order`;
        break;
      case '':
        this.sortStatement = `sorting ${event.active} in random order`;
        break;
    }
    this.sortBy = event.active;
    this.sortDir = event.direction === 'desc' ? 'DESC' : 'ASC';
    this.loadCandidates(this.pageInfo.pageSize, this.paginator.pageIndex * this.paginator.pageSize);
  }

  /**
   * querystring
   * @param _clientContactId clientContactId
   * @param _searchText search text
   * @param _sortField field to be sorted
   * @param _sortDir sort direction
   * @param _skip skip field
   * @param _limit limit
   */
  getQueryString(_clientContactId, _searchText?, _sortField?, _sortDir?, _skip?, _limit?): string {
    const _queryString = { clientContactId: _clientContactId };
    if (_searchText) { _queryString['searchText'] = _searchText; }
    if (_sortField) { _queryString['sortField'] = _sortField; }
    if (_sortDir) { _queryString['sortDir'] = _sortDir; }
    if (_skip) { _queryString['skip'] = _skip; }
    if (_limit) { _queryString['limit'] = _limit; }
    return querystring.stringify(_queryString);
  }

  /**
   * method for get all authorized transferees
   * @param pageSize pagesize
   * @param skipRecords skip record count
   */
  loadCandidates(pageSize: number, skipRecords: number) {
    this.spinner.show();
    this.aprovedMovesService.counter++;
    try {
      this.subscription.add(this.aprovedMovesService.getApprovedMovesForFinance(
        this.getQueryString(this.clientContactId, this.filterText, this.sortBy, this.sortDir, skipRecords, pageSize)).subscribe({
            next: response => {
              if (response && response.status === 204) {
                this.ELEMENT_DATA = [];
                this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
                this.aprovedMovesService.counter--;
                if (this.aprovedMovesService.counter <= 0) {
                  this.spinner.hide();
                }
              } else if (response && response.status === 200 && response.body) {
                this.formatModels(response.body.approvedMoves);
                this.userContactInfoData = {};
                this.ELEMENT_DATA = response.body.approvedMoves;
                this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
                this.dataSource.sortData = (contactData: any[], sort: MatSort) => {
                  return contactData.sort((first, second) => {
                    if (sort.active === 'candidate.fullname') {
                      if (this.sortDir === 'DESC') {
                        const a = first && first[sort.active.split('.')[0]][sort.active.split('.')[1]].toLowerCase();
                        const b = second && second[sort.active.split('.')[0]][sort.active.split('.')[1]].toLowerCase();
                        if (a !== undefined && b !== undefined) {
                          if (a.toLowerCase() < b.toLowerCase()) { return -1; }
                          if (a.toLowerCase() > b.toLowerCase()) { return +1; }
                          if (a.toLowerCase() === b.toLowerCase()) {
                            if (a < b) { return -1; }
                            if (a > b) { return +1; }
                          }
                        }
                      } else if (this.sortDir === 'ASC') {
                        const a = first && first[sort.active.split('.')[0]][sort.active.split('.')[1]].toLowerCase();
                        const b = second && second[sort.active.split('.')[0]][sort.active.split('.')[1]].toLowerCase();
                        if (a !== undefined && b !== undefined) {
                          if (a.toLowerCase() < b.toLowerCase()) { return 1; }
                          if (a.toLowerCase() > b.toLowerCase()) { return -1; }
                          if (a.toLowerCase() === b.toLowerCase()) {
                            if (a < b) { return 1; }
                            if (a > b) { return -1; }
                          }
                        }
                      }
                    } else {
                      if (this.sortDir === 'DESC') {
                        if (first[sort.active] && second[sort.active]) {
                          const a = first[sort.active] ? first && first[sort.active].toLowerCase() : first;
                          const b = second[sort.active] ? second && second[sort.active].toLowerCase() : second;
                          if (a !== undefined && b !== undefined) {
                            if (a.toLowerCase() < b.toLowerCase()) { return -1; }
                            if (a.toLowerCase() > b.toLowerCase()) { return +1; }
                            if (a.toLowerCase() === b.toLowerCase()) {
                              if (a < b) { return -1; }
                              if (a > b) { return +1; }
                            }
                          }
                        } else {
                          return 0;
                        }
                      } else if (this.sortDir === 'ASC') {
                        if (first[sort.active] && second[sort.active]) {
                          const a = first[sort.active] ? first && first[sort.active].toLowerCase() : first;
                          const b = second[sort.active] ? second && second[sort.active].toLowerCase() : second;
                          if (a !== undefined && b !== undefined) {
                            if (a.toLowerCase() < b.toLowerCase()) { return 1; }
                            if (a.toLowerCase() > b.toLowerCase()) { return -1; }
                            if (a.toLowerCase() === b.toLowerCase()) {
                              if (a < b) { return 1; }
                              if (a > b) { return -1; }
                            }
                          }
                        } else {
                          return 0;
                        }
                      }
                    }
                  }).reverse();
                };
                setTimeout(() => {
                  this.paginator.length = Number(response.body.totalApprovedMove);
                });
                this.dataSource.sortingDataAccessor = this.getPropertyByPath;
                this.dataSource.filterPredicate = this.customFilterPredicate;
                this.aprovedMovesService.counter--;
                if (this.aprovedMovesService.counter <= 0) {
                  this.spinner.hide();
                }
              } else {
                this.aprovedMovesService.counter--;
                if (this.aprovedMovesService.counter <= 0) {
                  this.spinner.hide();
                }
                this.snackBar.open(
                  apiErrorMessage,
                  undefined,
                  { duration: 5000 }
                );
              }
            },
            error: () => {
              this.aprovedMovesService.counter--;
              if (this.aprovedMovesService.counter <= 0) {
                this.spinner.hide();
              }
              this.snackBar.open(
                apiErrorMessage,
                undefined,
                { duration: 5000 }
              );
            }
          }
        )
      )
    } catch (err) {
      this.aprovedMovesService.counter--;
      if (this.aprovedMovesService.counter <= 0) {
        this.spinner.hide();
      }
      this.snackBar.open(
        err,
        undefined,
        { duration: 5000 }
      );
    }
  }

  /**method to filter column values */
  customFilterPredicate(data, filter) {
    const searchTerm = filter.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const dataStr =
      `${data.candidate.destination.state}, ${data.candidate.destination.city}${data.candidate.fullname}
      ${data.candidate.departure.state}, ${data.candidate.departure.city}${data.authorizedAmtFormat}
      ${data.candidate.businessUnit}${data.candidate.level}${data.mileStone}
      ${data.mileStoneDate}${data.candidate.createdBy}${data.authorizedBy}${data.remainingFormat}`;
    return dataStr.search(new RegExp(searchTerm, 'gi')) !== -1;
  }

  /**format model for candidate level*/
  formatModels(approvedMoves) {
    for (const moves of approvedMoves) {
      if (moves.candidate) {
        moves.levelName = moves.candidate.level ? moves.candidate.level.split('(')[0] : null;
        moves.levelDes = moves.candidate.level ? moves.candidate.level.replace('(', '#(').split('#')[1] : null;
        moves.remainingFormat = `remaining: ${formatNumber(Math.ceil(moves.remainingAmt), 'en-US').toString()} USD`;
        moves.authorizedAmtFormat = formatNumber(Math.ceil(moves.authorizedAmt), 'en-US').toString() + ' USD';
        moves.mileStoneDate = 'on ' + this.datePipe.transform(moves.latestMilestone, 'yyyy-MM-dd');
        moves.contactName = 'creator: ' + moves.candidate.clientContactName;
        for (const element of moves.movePhase) {
          if (element.status === true) {
            this.activeLabel = element.dateLabel;
          } else {
            break;
          }
        }
        moves.mileStone = this.activeLabel;
      }
    }
  }

  /**
   * Applying filter value to the data defined
   * @param filterValue holds the filter value
  */
  applyFilter(filterValue: string) {
    this.filterText = filterValue.trim();
    if (this.filterText && this.filterText.length >= 3) {
      this.paginator.pageIndex = skipRecordCount;
      return this.loadCandidates(this.pageInfo.pageSize, skipRecordCount);
    } else if (this.filterText === '') {
      return this.loadCandidates(this.pageInfo.pageSize, this.paginator.pageIndex * this.paginator.pageSize);
    }
    return false;
  }

  /**
   * function to get property by path
   * @param obj Object value
   * @param pathString path value
   */
  getPropertyByPath(obj: Object, pathString: string) {
    if (pathString === 'departure.state' && !obj.hasOwnProperty('departure')) { return null; }
    return pathString.split('.').reduce((o, i) => o[i], obj);
  }

  /**
   * Filters the result
   * @param filterVal filter value
   */
  filterResults(filterVal) {
    this.applyFilter(filterVal);
  }

  /** Displays the Column name with checkbox to show/hide column and calls the updateTable function */
  openModal(evt: MouseEvent): void {
    evt.preventDefault();
    const dialogRef = this.dialog.open(FinanceColumnsComponent, {
      panelClass: 'dialogMainContainer',
      data: this.displayedColumns,
      disableClose: true
    });
    this.subscription.add(dialogRef.componentInstance.columnsListUpdated.subscribe(
      (response: Selection[]) => {
        this.columnList = response;
        this.selectedCols = this.columnList;
        this.updateTable();
      }
    ));
  }

  /** Updates the Table */
  updateTable() {
    this.selectedCols.forEach((item, index) => {
      if (this.displayedColumns.indexOf(item.value) < 0) {
        this.displayedColumns.splice(2, 0, item.value);
      } else {
        this.displayedColumns = this.displayedColumns.filter(val => {
          return item.value === val;
        });
      }
    });
  }

  /**navigate to transferee details */
  navigateToTransfereeDetails(candidateId: string) {
    this._router.navigate(['project-alpha/finance-audit-details', candidateId]);
  }

  /** Open the move phase dialog */
  openMovePhaseModal(approvedMove: ApprovedMove, evt: MouseEvent) {
    evt.stopPropagation();
    if (approvedMove.movePhase) {
      const rect = (evt.currentTarget as Element).getBoundingClientRect();
      const target = this.getPositionByEvents(rect);
      const element = new ElementRef(target);
      const positionStrategy = this.placeByPositionStrategy(element);
      const overlayConfig = new OverlayConfig({
        width: '',
        height: '',
        panelClass: 'movePhaseModal',
        hasBackdrop: true,
        backdropClass: 'mat-backdrop-transparent',
        positionStrategy,
      });
      this.overlayRef = this.overlay.create(overlayConfig);
      const containerPortal = new ComponentPortal(AuthorizedMovePhaseComponent, null, this.positionService.createInjector({
        movePhase: approvedMove.movePhase,
        overlayRef: this.overlayRef
      }));
      this.overlayRef.attach(containerPortal);
      this.overlayRef.backdropClick().subscribe(() => {
        this.overlayRef.dispose();
      });
    }
  }

  /** get the position by events of the target */
  getPositionByEvents(rect) {
    return {
      getBoundingClientRect: () => ({
        bottom: rect.top + (rect.bottom - rect.top) / 2,
        height: rect.height,
        left: rect.left + (rect.right - rect.left) / 2,
        right: rect.left + (rect.right - rect.left) / 2,
        top: rect.top + (rect.bottom - rect.top) / 2,
        width: rect.width
      }),
    };
  }

  /** place the pop up by position strategy */
  placeByPositionStrategy(element: ElementRef) {
    return this.overlay
      .position()
      .flexibleConnectedTo(element)
      .withFlexibleDimensions(false)
      .withPositions([
        {
          originX: 'end',
          originY: 'center',
          overlayX: 'start',
          overlayY: 'center',
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'bottom',
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        }
      ]);
  }

  /** place the pop up by position strategy */
  placeByPositionStrategyForUserInfo(element: ElementRef) {
    return this.overlay
      .position()
      .flexibleConnectedTo(element)
      .withFlexibleDimensions(false)
      .withPositions([
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        }
      ]);
  }

  /**
   * pagination method
   * @param event pagination event
   */
  onPagination(event: any) {
    this.pageInfo.pageSize = this.paginator.pageSize;
    this.loadCandidates(this.paginator.pageSize, this.paginator.pageIndex * this.paginator.pageSize);
  }

  /**method to update the column*/
  isDisabled(flag) {
    this.disableButton = flag;
    if (this.disableButton === true) {
      this.displayedColumns = [
        'candidate.fullname',
        'clientName',
        'orderReference',
        'authorizedAmtFormat',
        'auditStatus'
      ];
    }
  }

  userContactInfo(userInfo: ApprovedMove, evt: MouseEvent) {
    evt.stopPropagation();
    evt.preventDefault();
    const emptyInfo: UserContactInfo = {} as UserContactInfo;
    if (userInfo !== undefined) {
      emptyInfo.firstName = userInfo.lastUpdatedBy.firstName ? userInfo.lastUpdatedBy.firstName : '';
      emptyInfo.lastName = userInfo.lastUpdatedBy.lastName ? userInfo.lastUpdatedBy.lastName : '';
      emptyInfo.emailAddress = userInfo.lastUpdatedBy.emailAddress ? userInfo.lastUpdatedBy.emailAddress : '';
      emptyInfo.phoneNumber = userInfo.lastUpdatedBy.phoneNumber ? userInfo.lastUpdatedBy.phoneNumber : '';
    }
    const rect = (evt.currentTarget as Element).getBoundingClientRect();
    const target = this.getPositionByEvents(rect);
    const element = new ElementRef(target);
    const positionStrategy = this.placeByPositionStrategyForUserInfo(element);
    const overlayConfig = new OverlayConfig({
      width: '',
      height: '',
      panelClass: 'dialogMainContainer',
      hasBackdrop: true,
      backdropClass: 'mat-backdrop-transparent',
      positionStrategy,
      disposeOnNavigation: true
    });
    this.overlayRef = this.overlay.create(overlayConfig);
    const containerPortal = new ComponentPortal(UserContactInfoComponent, null, this.positionService.createInjector({
      data: emptyInfo,
      overlayRef: this.overlayRef
    }));
    this.overlayRef.attach(containerPortal);
    this.overlayRef.backdropClick().subscribe(() => {
      this.overlayRef.dispose();
    });
  }

  getDate(dtt: string) {
    return (moment(dtt, 'YYYY-MM-DD').format('YYYY-MM-DD'));
  }

  userInitials(user: UserContactInfo) {
    const userFullName = user.firstName + ' ' + user.lastName;
    const parts = userFullName.split(' ');
    let initials = '';
    for (let i = 0; i < parts.length; i++) {
      if (parts[i].length > 0 && parts[i] !== '') {
        initials += parts[i][0];
      }
    }
    return initials;
  }

  /**unsubscribe the subscriptions */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
