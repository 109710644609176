<mat-card [ngClass]="serviceDetails.class">
   
    <mat-card-header *ngIf="serviceDetails.title !=='' && serviceDetails.title !== undefined">
        <mat-card-title> <h2 class="budget-details-title">  Budget Details </h2> </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="chart-container">
            <google-chart #chart  
                [type]="type"
                [data]="data"  
                [options]="options">
            </google-chart>
        </div>
    </mat-card-content>
</mat-card>
