<div id="skiptocontent" *ngIf="!(router.url==='/privacy-statement' || router.url==='/terms-of-use' || router.url==='/not-supported')">
  <a tabindex="0" href="#maincontent">Skip to main content</a>
</div>
<app-privacy-notice *ngIf="(router.url==='/privacy-statement' || router.url==='/terms-of-use')"></app-privacy-notice>
<app-non-compatible *ngIf="(router.url==='/not-supported')"></app-non-compatible>
<app-contact-us *ngIf="(router.url==='/contact-us')"></app-contact-us>
<app-login *ngIf="(router.url==='/login')"></app-login>
<div id="menu-container">
  <ng-template #menuContainer></ng-template>
</div>
<div fxLayout="row" fxFill *ngIf="!(router.url==='/privacy-statement' || router.url==='/terms-of-use' || router.url==='/not-supported' || router.url==='/contact-us' || router.url==='/login')">
  <div fxFlex="100">
    <div class="content-col-desktop">
      <mat-toolbar class="header-desktop header-desktop-r" fxHide.lt-md fxFill fxLayout="row" fxLayoutAlign="space-between center">
        <div class="client-desktop-logo">
          <img src="../assets/images/logos/logo-mobilify-H-desktop.svg" alt="Mobilify Logo" />
        </div>
        <div class="userInfo">
          <div class="shortName" aria-hidden="true">{{loggedInUserInitials}}</div>
          <div class="userName">{{loggedInUserName}}</div>
          <div>
            <button class="userOptionsBtn" aria-label="User options" mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon class="material-icons drop-down-ico">arrow_drop_down</mat-icon>
            </button>
          </div>
        </div>
      </mat-toolbar>
      <div fxHide.lt-md>
        <mat-toolbar id="menu-toolbar">
          <button mat-icon-button (click)="toggleMenu()">
            <mat-icon>menu</mat-icon>
          </button>
        </mat-toolbar>
      </div>
      <mat-menu class="user-opt-drop-down header-menu" #menu="matMenu">
        <div class="authorName" fxHide fxShow.xs fxShow.sm>{{loggedInUserName}}</div>
        <ng-container *ngFor="let item of menuItems">
          <button class="userOptions" mat-menu-item [routerLink]="[item.link]">
            <mat-icon class="menu-item-icon" svgIcon={{item.icon}}></mat-icon>
            <span class="menu-item-txt">{{item.title}}</span>
          </button>
        </ng-container>
      </mat-menu>
      <div fxHide.gt-sm>
        <div class="header-mobile">
          <div fxFill fxLayout="row" fxLayoutAlign="space-between center">
            <button mat-icon-button (click)="toggleMenu()">
              <mat-icon>menu</mat-icon>
            </button>
            <span class="client-logo-mob">
              <img src="../assets/images/logos/logo-mobilify-stacked-mobile.svg" alt="Mobilify Logo" />
            </span>
            <div class="userInfo">
              <div class="shortName">{{loggedInUserInitials}}</div>
              <div>
                <button class="userOptionsBtn" aria-label="User options" mat-icon-button [matMenuTriggerFor]="menu">
                  <mat-icon class="material-icons drop-down-ico">arrow_drop_down</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mat-drawer-container fxFill ngClass.lt-md="content-mobile" ngClass.gt-sm="content-desktop">
      <mat-drawer-content>
        <div fxFill class="main-content">
          <router-outlet></router-outlet>
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
</div>
