import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { EmployeeDetails } from '../../../../../core/models/employee-details.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddRolesComponent } from '../add-roles/add-roles.component';
import { Subscription } from 'rxjs';
import { EmployeeDetailsService } from '../../../../../core/services/employee-details.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationsService } from '../../../../../core/services/notifications.service';
import { APIResponse } from '../../../../../core/models/response.model';
import { PartySharedService } from '../../../../../core/services/party-shared.service';
import { LoggerService } from '../../../../../core/services/logger.service';
import { UserContextService } from '../../../../../core/services/user-context.service';
import { EmployeeRoles } from '../../../../../core/models/employee-roles.model';
import * as querystring from 'querystring';
import { apiErrorMessage, skipRecordCount } from '../../../../../core/models/constants';

@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.scss']
})

export class EmployeeDetailsComponent implements OnInit, OnDestroy {
  @Input() filterText;
  /** Used to Sort Mat-table Column */
  @ViewChild(MatSort, { static: false }) set sortOrder(sort: MatSort) {
    if (sort) {
      this.dataSource.sort = sort;
    }
  }
  /** Used to paginate in a Mat-table */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  /** Variable to store the columns */
  displayedEmpColumns: string[] = [
    'name',
    'emailAddress',
    'role'
  ];
  /** Datasource for Table */
  dataSource: any;
  /** Stores the details of Employees */
  ELEMENT_DATA: EmployeeDetails[] = [];
  /** Store default column for sorting */
  sortBy = 'name';
  /** sorting direction */
  sortDir = 'ASC';
  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /** Holds Client Contact Id */
  clientContactId: string;
  /** total employees count */
  totalEmployeesCount: number;
  pageInfo = {
    pageSize: 20,
    pageIndex: 0,
    totalCount: 0
  };
  /** stores employee roles */
  roles: EmployeeRoles[] = [];
  /**
   * Base Constructor for Injecting dependencies to the Component
   * @param dialog nstance of MatDialog
   * @param spinner Instance of NgxSpinnerService
   * @param notificationsService Instance of NotificationsService
   * @param employeeSvc Instance of EmployeeDetailsService
   */
  constructor(
    public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    private readonly notificationsService: NotificationsService,
    private readonly employeeSvc: EmployeeDetailsService,
    private readonly partySharedSvc: PartySharedService,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: UserContextService
  ) { }

  /**
   * Intial Component initialization and API call to Load Page
   */
  ngOnInit() {
    this.spinner.show();
    this.partySharedSvc.getPartyId().then(id => {
      if (id) {
        this.clientContactId = id;
        this.loadCandidates(this.pageInfo.pageSize, this.pageInfo.pageIndex);
      } else {
        this.spinner.hide();
      }
    }, err => {
      this.spinner.hide();
    });
  }

  sortData(event) {
    this.sortBy = event.active;
    this.sortDir = event.direction === 'desc' ? 'DESC' : 'ASC';
    this.loadCandidates(this.pageInfo.pageSize, this.paginator.pageIndex * this.paginator.pageSize);
  }

  getQueryString(_searchText?, _sortField?, _sortDir?, _skip?, _limit?): string {
    const _queryString = {};
    if (_searchText) { _queryString['searchText'] = _searchText; }
    if (_sortField) { _queryString['sortField'] = _sortField; }
    if (_sortDir) { _queryString['sortDir'] = _sortDir; }
    if (_skip) { _queryString['skip'] = _skip; }
    if (_limit) { _queryString['limit'] = _limit; }
    return querystring.stringify(_queryString);
  }

  loadCandidates(pageSize: number, skipRecords: number) {
    this.spinner.show();
    this.subscription.add(
      this.employeeSvc.getEmployees(
        this.getQueryString(this.filterText, this.sortBy, this.sortDir, skipRecords, pageSize))
        .subscribe({
          next: response => {
            if (response && response.status === 204) {
              this.totalEmployeesCount = 0;
              this.ELEMENT_DATA = [];
              this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
              this.spinner.hide();
            } else if (response && response.status === 200 && response.body) {
              this.formatData(response.body.employees);
              this.roles = response.body.employees[0].roles;
              for (const emp of response.body.employees) {
                emp.roles = emp.roles.filter(x => x.status === true);
              }
              this.totalEmployeesCount = response.body.totalEmployees;
              this.pageInfo.pageSize = this.totalEmployeesCount > 10 ? 20 : this.totalEmployeesCount > 5 ? 10 : 5;
              this.ELEMENT_DATA = response.body.employees;
              this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
              this.dataSource.sort = this.sortOrder;
              setTimeout(() => {
                this.paginator.length = Number(response.body.totalEmployees);
              });
              this.dataSource.sortingDataAccessor = this.getPropertyByPath;
              this.dataSource.filterPredicate = this.customFilterPredicate;
              this.loggedInUserService.getLoggedInUserDetails()
              .subscribe(result => {
                const userId: any = result.userId.replace(/ .*/, '');
                this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-EMPLOYEE_DETAILS', 'EMPLOYEE_DETAILS');
              });
              this.spinner.hide();
            } else {
              this.notificationsService.flashNotification(
                'failed',
                apiErrorMessage,
                false,
                'dismiss'
              );
              this.spinner.hide();
            }
          },
          error: () => {
            this.spinner.hide();
          }
        }
      )
    )
  }

  /**method to format the data */
  formatData(data) {
    for (const emp of data) {
      emp.name = `${emp.name.split(',')[1]}, ${emp.name.split(',')[0]}`;
    }
  }

  /** Mat data table filtering matching wild characters */
  applyFilter(filterValue: string) {
    this.pageInfo.pageSize = 20;
    this.filterText = filterValue.trim();
    if (this.filterText && this.filterText.length >= 3) {
      this.paginator.pageIndex = skipRecordCount;
      return this.loadCandidates(this.pageInfo.pageSize, skipRecordCount);
    } else if (this.filterText === '') {
      return this.loadCandidates(this.pageInfo.pageSize, this.paginator.pageIndex * this.paginator.pageSize);
    }
    return false;
  }

  /**
   * function to get property by path
   * @param obj -object passed
   * @param pathString -path passed
   */
  getPropertyByPath(obj: Object, pathString: string) {
    return pathString.split('.').reduce((o, i) => o[i], obj);
  }

  /**method to filter column values */
  customFilterPredicate(data, filter): boolean {
    const searchTerm = filter.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const dataStr =
      data.name +
      data.emailAddress;
    return dataStr.search(new RegExp(searchTerm, 'gi')) !== -1;
  }

  /**
   * Function to open Dialog to Add/Remove roles for Employee
   * @param data Holds the selected Row Data
   */
  openModal(data): void {
    this.roles.forEach(role => {
      role.status = false;
    });
    const dialogRef = this.dialog.open(AddRolesComponent, {
      panelClass: 'empdialogMainContainer',
      data: {
        employeeData: data,
        roles: this.roles
      },
      disableClose: true
    });
    this.subscription.add(
      dialogRef.afterClosed().subscribe(employee => {
        if (employee) {
          this.spinner.show();
          const index = this.ELEMENT_DATA.findIndex(x => x.employeeId === employee.employeeId);
          this.employeeSvc.updateEmployeeroles(employee).subscribe({
            next: (resp: APIResponse) => {
              if (resp.statusCode === 200) {
                this.ELEMENT_DATA[index].roles = employee.roles.filter(x => x.status === true);
                this.notificationsService.flashNotification(
                  'success',
                  'Updated Employee Roles Successfully'
                );
              } else {
                this.notificationsService.flashNotification(
                  'failed',
                  apiErrorMessage,
                  false,
                  'dismiss'
                );
              }
              this.spinner.hide();
            },
            error: () => {
                this.spinner.hide();
            }
          });
        }
      })
    );
  }

  onPagination(event: any) {
    this.loadCandidates(this.paginator.pageSize, this.paginator.pageIndex * this.paginator.pageSize);
  }

  /**
   * Destroys the object
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
