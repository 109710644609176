/** MOV360-880 - to be removed at future date */
import { APIResponse, APIResponseDuplicate } from './../../../../core/models/response.model';
import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectorRef,
  OnDestroy, Inject
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  FormControl,
  AbstractControl,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { CandidateProfilesService } from '../../../../core/services/candidate-profiles.service';
import { LocationService } from '../../../../core/services/location.service';
import { LevelService } from '../../../../core/services/level.service';
import { Candidate, statusType, CandidateDuplicateParam } from '../../../../core/models/candidate';
import { Location } from '../../../../core/models/location';
import {
  map,
  startWith,
  debounceTime,
  distinctUntilChanged,
  switchMap
} from 'rxjs/operators';
import { Observable, Subscription, of } from 'rxjs';
import { Level } from '../../../../core/models/level';
import {
  addCandidateMode,
  addCandidateTitleForAddCandidate,
  addCandidateTitleForCandidateAssesment,
  addCandidateTitleForRecommendedBudget,
  addCandidateTitleForEditCandidate,
  createCandidateResponse,
  updateCandidateResponse,
  duplicateResponse,
  sendInviteResponse,
  reSendInviteResponse,
  invitationSentStatus,
  invitationNotSentStatus,
  vanlineQuoteStatus,
  phoneErrorMessage,
  errorMessage,
  specialCharactersMessage
} from '../../../../core/models/constants';
import { NotificationsService } from '../../../../core/services/notifications.service';
import { Address } from '../../../../core/models/address.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PartySharedService } from '../../../../core/services/party-shared.service';
import { LoggerService } from '../../../../core/services/logger.service';
import { UserContextService } from '../../../../core/services/user-context.service';
import { ExtensionService } from 'src/app/core/services/extension.service';
import { Extension } from 'src/app/core/models/extension.model';
/**
 * Exporting the errormessages
 */
export const errorMessages: { [key: string]: string } = {
  Email: 'You must enter Email address',
  Level: 'You must select level',
  DeptDestSame: 'Destination cannot be same as Departure',
  NoResults: 'Results not found',
  Extension: 'You must select extension',
};

/**
 * Add component for candidate
 */
@Component({
  selector: 'app-add-candidate',
  templateUrl: './add-edit-budget.component.html',
  styleUrls: ['./add-edit-budget.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddCandidateComponent implements OnInit, OnDestroy {
  /**Form group name */
  addCandidateForm: UntypedFormGroup;
  /** Title to display the dialog window page title */
  title = addCandidateTitleForAddCandidate;
  /**Flag mode for Create */
  mode = addCandidateMode;
  /**Assign formready Variable as False */
  formReady = false;
  /**Binding the dropdown values to level filed */
  levels: Level[];
  /** auto complete values for destination */
  options: Location[];
  /**variable declared for  tax value checkbox change */
  taxValChkBox: boolean;
  /**variable declared for totalcost */
  totalCost: number;
  /**variable declared for modal popup component reuse */
  transferreFlag: boolean;
  /**variable declared to load buttons based on status */
  btnChangeFlag: boolean;
  /**variable declared for tax value */
  taxValue: number;
  /**boolean variable to display services */
  showServices = false;
  /**boolean variable to make fields readOnly */
  readOnlyField = false;
  /** Enable/Disable Update Button */
  isDirty = true;
  /**boolean variable for FirstNameField */
  isFirstNameField = true;
  /**boolean variable for LastNameField */
  isLastNameField = true;
  /**boolean variable for isbusinessUnitField */
  isbusinessUnitField = true;
  /**Variables for error */
  errors = errorMessages;
  /** variable declared for depature */
  departures: Address[];
  /** variable declared for destinations */
  destinations: Address[];
  /**variable declared for enum values */
  statusOftype = statusType;
  /**boolean variable to show assessment details like address,housing details, no of people */
  showAssessment = false;
  /**variable to store the response from api on saving the candidate */
  saveResponseMessage: string;
  /**variable to store the response from api on saving and sending invite for the candidate */
  inviteResponseMessage: string;
  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /**check whether details are saved successfully */
  saveSuccess: string;
  /**to disable close button when snackbar is opened */
  formDisable = false;
  /**addCandidate  model of type Candidate */
  addCandidate: Candidate = {} as Candidate;
  /**invitation sent flag */
  isInvitationSent = false;
  /**save changes flag */
  isSaveChanges = false;
  /**pending vanline quote flag */
  isPendingVanlineQuote: boolean;
  /**flag to check email field */
  isEmailValid = false;
  /** flag to check level field*/
  isLevelValid = false;
  /** flag to check departure valid*/
  isDestinationValid = false;
  /** flag to check phone number valid */
  isPhoneNumberValid = false;
  /**boolean variable for destination Selection */
  destinationSelected = false;
  /**boolean variable for departure Selection */
  departureSelected = false;
  /**boolean variable for departure spinner */
  showDepartureSpinner = false;
  /**boolean variable for destination spinner */
  showDestinationSpinner = false;
  /** flag for dept selected */
  isDeptSelected: boolean;
  /** flag for dest selected */
  isDestSelected: boolean;
  /** Stores the client contact ID */
  clientContactId: string;
  /** readonly access flag */
  disabled: boolean;
  /**stores extension list */
  extensions: Extension[];
  /** flag to check level field*/
  isExtensionValid = false;
  /**default extension */
  defaultExtension: any;
  /**
   * Initializes form elements
   * @param formBuilder - property for form elements
   * @param dialogRef - property for mat dialog reference
   * @param data - contains popup data
   * @param candidateProfilesService - service parameter
   * @param locationService - loads location details
   * @param levelService - fetches level details
   * @param changeDetectorRef - property for change detections
   * @param notificationsService - property for notification service
   * @param snackBar - property for snackbar
   * @param spinner - property for spinner
   */
  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AddCandidateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly candidateProfilesService: CandidateProfilesService,
    private readonly locationService: LocationService,
    private readonly levelService: LevelService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly notificationsService: NotificationsService,
    private readonly partySharedSvc: PartySharedService,
    private readonly snackBar: MatSnackBar,
    private readonly spinner: NgxSpinnerService,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: UserContextService,
    private extensionService: ExtensionService
  ) {
    this.disabled = this.data.disableButton;
    this.levelService.levelList$.subscribe(level => {
      this.levels = level;
    });
    /* Create the Add/Edit dialog window */
    this.addCandidateForm = this.formBuilder.group({
      FirstName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.pattern('^[a-z A-Z0-9-`]*$')
        ])
      ],
      LastName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(30),
          Validators.pattern('^[a-z A-Z0-9-`]*$')
        ])
      ],
      Email: [''],
      PhoneNumber: [''],
      Level: [''],
      Departure: [''],
      BusinessUnit: [''],
      Destination: [''],
      Extension: ['']
    });
    this.extensionService.extensionList$.subscribe(extension => {
      if (extension) {
        this.extensions = extension;
        this.defaultExtension = this.extensions.find(x => (x.name === 'United States' || x.name === 'Canada'));
        if (this.data.candidateDetails && this.data.candidateDetails.countryDialingCode) {
          this.addCandidateForm.get('Extension')
            .setValue(this.data.candidateDetails.countryDialingCode);
        } else {
          if (this.defaultExtension) {
            this.addCandidateForm.controls.Extension.setValue(this.defaultExtension.countryDialingCode);
          }
        }
      }
      this.extensions = extension;
    });
    /* Setting default title of the dialof window */
    this.title = addCandidateTitleForAddCandidate;
    this.readOnlyField = false;

    /* If the data is present - it will open and pre-populate dialog window */
    if (this.data.candidateDetails) {
      this.title = addCandidateTitleForEditCandidate;
      if (this.data.candidateDetails.status === invitationSentStatus) {
        this.isInvitationSent = true;
      }
      if (this.data.candidateDetails.status === vanlineQuoteStatus) {
        this.addCandidateForm.disable();
        this.isPendingVanlineQuote = true;
      } else {
        this.addCandidateForm.enable();
      }

      /**
       * Assigning the recommended budget and cost
       */

      /* Setting the default values for form elements in edit candidate dialog */
      this.addCandidateForm
        .get('FirstName')
        .setValue(
          this.data.candidateDetails.fullname.substr(
            data.candidateDetails.fullname.indexOf(',') + 2,
            data.candidateDetails.fullname.length
          )
        );
      this.addCandidateForm
        .get('LastName')
        .setValue(this.data.candidateDetails.fullname.substr(0, data.candidateDetails.fullname.indexOf(',')));
      if (this.data.candidateDetails.destination) {
        this.addCandidateForm
          .get('Destination')
          .setValue(this.data.candidateDetails.destination.fullAddress);
      }
      if (this.data.candidateDetails.departure) {
        this.addCandidateForm
          .get('Departure')
          .setValue(this.data.candidateDetails.departure.fullAddress);
      }
      this.addCandidateForm.get('Email').setValue(this.data.candidateDetails.emailAddress);
      this.addCandidateForm.get('PhoneNumber').setValue(this.data.candidateDetails.phoneNumber);
      this.addCandidateForm
        .get('BusinessUnit')
        .setValue(this.data.candidateDetails.businessUnit);
      this.addCandidateForm.get('Level').setValue(this.data.candidateDetails.level);
    }
  }

  /**
   * On Page load, sets the flag to show appropriate buttons based on status
   */
  ngOnInit() {
    this.partySharedSvc.getPartyId().then(id => {
      this.clientContactId = id;
    });

    this.taxValChkBox = true;
    this.totalCost = this.taxValue + this.totalCost;

    if (this.addCandidateForm.controls['Email'].value && this.addCandidateForm.controls['Level'].value
      && this.addCandidateForm.controls['Destination'].value && this.addCandidateForm.controls['PhoneNumber'].value
      && this.addCandidateForm.controls['Extension'].value) {
      this.isLevelValid = true;
      this.isEmailValid = true;
      this.isDestinationValid = true;
      this.isPhoneNumberValid = true;
      this.isExtensionValid = true;
    }
    if (this.data.candidateDetails) {
      this.isSaveChanges = true;
      if (this.data.candidateDetails.status === this.statusOftype.pendingVanline ||
        this.data.candidateDetails.status === this.statusOftype.readyForReview) {
        this.transferreFlag = true;
      } else {
        this.transferreFlag = false;
      }
      if (this.statusOftype.readyForReview === this.data.candidateDetails.status) {
        this.btnChangeFlag = false;
      } else {
        this.btnChangeFlag = true;
      }
    }
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-ADD_CANDIDATE', 'ADD_CANDIDATE');
      });
  }

  /**method to be called on departure input change */
  onDeptInput(search) {
    of(search)
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(searchTerm => {
          searchTerm = searchTerm.trim();
          if (searchTerm) {
            this.showDepartureSpinner = true;
            this.isDeptSelected = false;
            return this.locationService.getAddresses(searchTerm);
          }
        })
      )
      .subscribe(locations => {
        this.showDepartureSpinner = false;
        this.departures = locations;
        if (this.departures && this.departures.length === 0) {
          this.addCandidateForm.controls['Departure'].setErrors({
            invalidAddress: true
          });
        }
      });
  }

  /**method to be called on destination input change */
  onDestInput(search) {
    this.isDestinationValid = false;
    of(search)
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(searchTerm => {
          searchTerm = searchTerm.trim();
          if (searchTerm) {
            this.showDestinationSpinner = true;
            this.isDestSelected = false;
            return this.locationService.getAddresses(searchTerm);
          }
        })
      )
      .subscribe(locations => {
        this.showDestinationSpinner = false;
        this.destinations = locations;
        if (this.destinations && this.destinations.length === 0) {
          this.addCandidateForm.controls['Destination'].setErrors({
            invalidAddress: true
          });
        }
      });
  }

  /** on dept dropdown close */
  onDepartureSelectionClosed() {
    if (!this.isDeptSelected && this.addCandidateForm.controls['Departure'].value !== '') {
      this.addCandidateForm.controls['Departure'].setErrors({
        invalidAddress: true
      });
    }
    this.checkAddress();
    this.departures = [];
  }

  /** on dept selection */
  onDepartureSelected() {
    this.isDeptSelected = true;
    this.departures = [];
    this.checkAddress();
  }

  /** on dest dropdown close */
  onDestinationSelectionClosed() {
    if (!this.isDestSelected && this.addCandidateForm.controls['Destination'].value !== '') {
      this.addCandidateForm.controls['Destination'].setErrors({
        invalidAddress: true
      });
      this.isDestinationValid = false;
    }
    if (!this.addCandidateForm.controls['Destination'].value) {
      this.isDestinationValid = false;
    } else {
      this.isDestinationValid = true;
    }
    this.destinations = [];
  }

  /** on dest selection */
  onDestinationSelected() {
    this.isDestSelected = true;
    this.destinations = [];
    this.checkAddress();
  }

  /**method to be called on keydown event for departure */
  onDeptTabOut() {
    if (this.isDeptSelected !== undefined && !this.isDeptSelected && this.addCandidateForm.controls.Departure.value) {
      this.addCandidateForm.controls['Departure'].setErrors({
        invalidAddress: true
      });
    }
  }

  /**method to be called on keydown event for destination */
  onDestTabOut() {
    if (this.isDestSelected !== undefined && !this.isDestSelected && this.addCandidateForm.controls.Destination.value) {
      this.addCandidateForm.controls['Destination'].setErrors({
        invalidAddress: true
      });
    }
  }

  /**
   * used to check if departure address is same as destination address
   * @param input form with required fields
   */
  // addressValidator(input: AbstractControl): ValidationErrors | null {
  //   return new Promise((resolve, reject) => {
  //     if (input.value !== null && input.root.value.Departure !== null) {
  //       if (input.value === input.root.value.Departure) {
  //         resolve({ sameAddress: true });
  //       }
  //     }
  //     resolve(null);
  //   });
  // }

  /**
   * cross field validation for departure and destination fields
   */
  checkAddress() {
    if (this.addCandidateForm.controls['Destination'].value) {
      if (
        this.addCandidateForm.controls['Departure'].value ===
        this.addCandidateForm.controls['Destination'].value
      ) {
        this.addCandidateForm.controls['Destination'].markAsTouched();
        this.addCandidateForm.controls['Destination'].setErrors({
          sameAddress: true
        });
      } else if (this.destinations) {
        if (this.addCandidateForm.controls['Destination'].value !== '' && !this.isDestSelected) {
          this.addCandidateForm.controls['Destination'].setErrors({
            invalidAddress: true
          });
        } else {
          this.addCandidateForm.controls['Destination'].setErrors(null);
        }
      } else {
        this.addCandidateForm.controls['Destination'].setErrors(null);
      }
    } else {
      this.isDestinationValid = false;
      this.addCandidateForm.controls['Destination'].clearValidators();
      this.addCandidateForm.controls['Destination'].updateValueAndValidity();
    }
  }

  /**
   * Click on Submit button inside the addCandidateForm dialog window
   */
  sendInvite() {
    this.spinner.show();
    this.populateCandidateObject();
    /** candidate object to send as request param for duplicate check api */
    const candidate: CandidateDuplicateParam = this.fetchCandidateData();
    const toCallDuplicate = this.shouldcallDuplicate(candidate, this.data.candidateDetails);
    this.addCandidate.status = invitationSentStatus;
    delete this.addCandidate.executedFunctionType;
    if (toCallDuplicate) { // to call duplicate api
      this.candidateProfilesService.duplicateCandidate(candidate).subscribe({
        next: (response: APIResponseDuplicate) => {
          if (response !== null && response.CODE !== 'DUP_FOUND') { // if not duplicate, call sendInvite to candidate
            this.sendInviteToCandidate();
          } else {
            this.spinner.hide();
            if (response === null) {
              this.sendInviteToCandidate();
            } else {
              // pop duplicate message
              this.flashAndCloseDialog(duplicateResponse);
            }
          }
        },
        error: () => {
          this.spinner.hide();
          this.dialogRef.close(this.addCandidate);
        }
      });
    } else {
      this.sendInviteToCandidate();
    }
  }

  /**send invite to candidate */
  sendInviteToCandidate() {
    this.candidateProfilesService
      .sendInviteToCandidate(this.addCandidate)
      .subscribe(response => {
        this.inviteResponse(response);
      });
  }

  /**
   * Used to resend email to candidate
   */
  resendInvite() {
    this.spinner.show();
    this.populateCandidateObject();
    this.addCandidate.status = invitationSentStatus;
    delete this.addCandidate.executedFunctionType;
    const candidate: CandidateDuplicateParam = this.fetchCandidateData();
    const toCallDuplicate = this.shouldcallDuplicate(candidate, this.data.candidateDetails);
    if (toCallDuplicate) { // to call duplicate api
      this.candidateProfilesService.duplicateCandidate(candidate).subscribe({
        next: (response: APIResponseDuplicate) => {
          if (response !== null && response.CODE !== 'DUP_FOUND') { // if not duplicate, call sendInvite to candidate
            this.resendInviteToCandidate();
          } else if (response === null) {
            this.resendInviteToCandidate();
          } else {
            this.spinner.hide();
            // pop duplicate message
            this.flashAndCloseDialog(duplicateResponse);
          }
        },
        error: () => {
          this.spinner.hide();
          this.dialogRef.close(this.addCandidate);
        }
      });
    } else {
      this.resendInviteToCandidate();
    }
  }

  /**method to be called on click of resend button */
  resendInviteToCandidate() {
    this.candidateProfilesService
      .reSendInviteToCandidate(this.addCandidate)
      .subscribe(response => {
        this.inviteResponse(response);
      });
  }

  inviteResponse(response) {
    this.spinner.hide();
    if (response !== null && response.statusCode === 200) {
      this.addCandidate.candidateId = response.candidateID;
      this.flashAndCloseDialog(response.message);
    } else {
      this.notificationsService.flashNotification(
        'success',
        errorMessage,
        true,
        'dismiss'
      );
    }
  }

  /**
   * function to fetch candidate form fields value after change
   */
  fetchCandidateData(): CandidateDuplicateParam {
    return {
      emailId: this.addCandidate.emailAddress,
      clientContactId: this.clientContactId,
      departureAddress: {
        city: this.addCandidate.departure !== undefined ? this.addCandidate.departure.city : undefined,
        state: this.addCandidate.departure !== undefined ? this.addCandidate.departure.state : undefined
      },
      destinationAddress: {
        city: this.addCandidate.destination !== undefined ? this.addCandidate.destination.city : undefined,
        state: this.addCandidate.destination !== undefined ? this.addCandidate.destination.state : undefined
      }
    };
  }

  /**
   * check whether to call duplicate api or not
   * @param candidate1 candidate form fields value after change in dialog
   * @param candidate2 this will be null incase of create and candidate data from dialog on load incase of edit
   */
  shouldcallDuplicate(candidate1: CandidateDuplicateParam, candidate2: Candidate): boolean {
    return false;
  }

  /**
   * Save candidate create/update
   * @param type notifies whether to add/edit
   */
  saveCandidate(type: string) {
    const candidateServiceSave =
      (type === 'Create') ? this.candidateProfilesService.createCandidate(this.addCandidate)
        : this.candidateProfilesService.updateCandidate(this.addCandidate);
    candidateServiceSave.subscribe({
      next: (response: APIResponse) => {
        this.spinner.hide();
        if (response.statusCode === 200) {
          this.addCandidate.executedFunctionType = type;
          this.addCandidate.candidateId = response.candidateID;
          this.flashAndCloseDialog(response.message);
        }
      },
      error: () => {
        this.spinner.hide();
      }
    });
  }

  /**
   * Click on Save Draft button inside the addCandidateForm dialog window
   */
  saveDraft() {
    if (this.addCandidateForm.valid) {
      this.spinner.show();
      this.populateCandidateObject();
      /** candidate object to send as request param for duplicate check api */
      const candidate: CandidateDuplicateParam = this.fetchCandidateData();
      if (this.data.candidateDetails) { // is edit candidate
        delete this.addCandidate.executedFunctionType;
        const toCallDuplicate = this.shouldcallDuplicate(candidate, this.data.candidateDetails);
        if (toCallDuplicate) { // to call duplicate api
          this.candidateProfilesService.duplicateCandidate(candidate).subscribe({
            next: (response: APIResponseDuplicate) => {
              if (response !== null && response.CODE !== 'DUP_FOUND') { // if not duplicate, call udpate candidate
                this.saveCandidate('Update');
              } else if (response === null) {
                this.saveCandidate('Update');
              } else {
                this.spinner.hide();
                // pop duplicate message
                this.flashAndCloseDialog(duplicateResponse);
              }
            },
            error: () => {
              this.spinner.hide();
              this.dialogRef.close(this.addCandidate);
            }
          });
        } else {
          this.saveCandidate('Update');
        }
      } else {
        delete this.addCandidate.executedFunctionType;
        const toCallDuplicate = this.shouldcallDuplicate(candidate, this.data.candidateDetails);
        if (toCallDuplicate) { // to call duplicate api
          this.candidateProfilesService.duplicateCandidate(candidate).subscribe({
            next: (resp: APIResponseDuplicate) => {
              if (resp !== null && resp.CODE !== 'DUP_FOUND') { // if not duplicate, call create candidate
                this.saveCandidate('Create');
              } else if (resp === null) {
                this.saveCandidate('Create');
              } else {
                this.spinner.hide();
                // pop duplicate message
                this.flashAndCloseDialog(duplicateResponse);
              }
            },
            error: () => {
              this.spinner.hide();
              this.dialogRef.close(this.addCandidate);
            }
          });
        } else {
          this.saveCandidate('Create');
        }
      }
    }
  }

  /**
   * function to populate the candidate details in candidate
   */
  populateCandidateObject() {
    this.addCandidate.fullname =
      `${this.addCandidateForm.controls['LastName'].value}, ${this.addCandidateForm.controls['FirstName'].value}`;
    this.addCandidate.emailAddress = this.addCandidateForm.controls[
      'Email'
    ].value;
    this.addCandidate.phoneNumber = this.addCandidateForm.controls[
      'PhoneNumber'
    ].value;
    this.addCandidate.businessUnit = this.addCandidateForm.controls[
      'BusinessUnit'
    ].value;
    if (this.addCandidateForm.controls['Destination'].value) {
      this.addCandidate.destination = {} as Address;
      this.addCandidate.destination.fullAddress = this.addCandidateForm.controls[
        'Destination'
      ].value;
      this.addCandidate.destination.city = this.addCandidateForm.controls[
        'Destination'
      ].value.split(',')[0];
      this.addCandidate.destination.state = this.addCandidateForm.controls[
        'Destination'
      ].value.split(',')[1].trim();
    } else {
      this.addCandidate.destination = undefined;
    }
    if (this.addCandidateForm.controls['Departure'].value) {
      this.addCandidate.departure = {} as Address;
      this.addCandidate.departure.fullAddress = this.addCandidateForm.controls[
        'Departure'
      ].value;
      this.addCandidate.departure.city = this.addCandidateForm.controls[
        'Departure'
      ].value.split(',')[0];
      this.addCandidate.departure.state = this.addCandidateForm.controls[
        'Departure'
      ].value.split(',')[1].trim();
    } else {
      this.addCandidate.departure = undefined;
    }
    this.addCandidate.level = this.addCandidateForm.controls['Level'].value ? this.addCandidateForm.controls['Level'].value : '';
    this.addCandidate.countryDialingCode =
      (this.addCandidateForm.controls['PhoneNumber'].value && this.addCandidateForm.controls['Extension'].value) ?
        this.addCandidateForm.controls['Extension'].value : this.defaultExtension.countryDialingCode;
    if (this.data.candidateDetails) {
      this.addCandidate.candidateId = this.data.candidateDetails.candidateId;
      this.addCandidate.status = this.data.candidateDetails.status;
      this.addCandidate.invitationSentDate = this.data.candidateDetails.invitationSentDate;
      this.addCandidate.createdDate = this.data.candidateDetails.createdDate;
      this.addCandidate.createdBy = this.clientContactId;
      this.addCandidate.lastUpdatedDate = this.data.candidateDetails.lastUpdatedDate;
      this.addCandidate.noOfPeople = this.data.candidateDetails.noOfPeople;
    } else {
      this.addCandidate.createdBy = this.clientContactId;
      this.addCandidate.createdDate = new Date().toString();
      this.addCandidate.lastUpdatedDate = new Date().toString();
      this.addCandidate.invitationSentDate = '';
      this.addCandidate.status = invitationNotSentStatus;
      this.addCandidate.noOfPeople = '';
    }
  }

  /**
   * function to flash message and close dialog
   * @param response string
   */
  flashAndCloseDialog(message: string) {
    switch (message) {
      case createCandidateResponse: {
        this.dialogRef.close(this.addCandidate);
        break;
      }
      case updateCandidateResponse: {
        this.dialogRef.close(this.addCandidate);
        break;
      }
      case duplicateResponse: {
        this.notificationsService.flashNotification(
          'error',
          'Duplicate Candidate Found, Please withdraw candidate to continue',
          true,
          'dismiss'
        );
        this.addCandidateForm.disable();
        break;
      }
      case sendInviteResponse: {
        this.setInviteStatus();
        break;
      }
      case reSendInviteResponse: {
        this.setInviteStatus();
        break;
      }
    }
  }

  setInviteStatus() {
    this.addCandidate.status = invitationSentStatus;
    this.addCandidate.invitationSentDate = new Date().toLocaleString();
    this.addCandidate.lastUpdatedDate = new Date().toLocaleString();
    this.dialogRef.close(this.addCandidate);
    this.notificationsService.confirmNotification(
      'Email'
    );
  }

  /**
   * Closing the dialog box - we are setting the form to empty
   */
  onNoClick(evt): void {
    evt.preventDefault();
    this.dialogRef.close();
    this.addCandidateForm = this.formBuilder.group({
      FirstName: ['', Validators.required],
      LastName: [
        '',
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(30)
      ],
      Email: [''],
      PhoneNumber: [''],
      Departure: [''],
      BusinessUnit: [''],
      Destination: ['']
    });
  }

  /**
   * Custom error messages for Firstname, lastname and Email to verify special character or empty errors
   * @param fieldName - field parameter to check for errors
   */
  getErrorMessage(fieldName) {
    if (fieldName === 'FIRST_NAME') {
      return this.addCandidateForm.get('FirstName').hasError('required')
        ? 'You must enter first name'
        : this.addCandidateForm.get('FirstName').hasError('pattern')
          ? specialCharactersMessage
          : this.addCandidateForm.get('FirstName').hasError('minlength')
            ? 'First name should be more than one character'
            : '';
    }
    if (fieldName === 'LAST_NAME') {
      return this.addCandidateForm.get('LastName').hasError('required')
        ? 'You must enter last name'
        : this.addCandidateForm.get('LastName').hasError('pattern')
          ? specialCharactersMessage
          : this.addCandidateForm.get('LastName').hasError('minlength')
            ? 'Last name should be more than one character'
            : this.addCandidateForm.get('LastName').hasError('maxlength')
              ? 'Last name should be less than thirty character'
              : '';
    }
    if (fieldName === 'EMAIL') {
      return this.addCandidateForm.get('Email').hasError('pattern')
        ? 'You must enter a valid email address'
        : '';
    }
    if (fieldName === 'PHONE_NUMBER') {
      return this.addCandidateForm.get('PhoneNumber').hasError('required')
        ? phoneErrorMessage
        : this.addCandidateForm.get('PhoneNumber').hasError('specialCharacter')
          ? specialCharactersMessage
          : this.addCandidateForm.get('PhoneNumber').hasError('pattern')
            ? phoneErrorMessage
            : this.addCandidateForm.get('PhoneNumber').hasError('alphabets')
              ? phoneErrorMessage
              : this.addCandidateForm.get('PhoneNumber').hasError('minlength')
                ? phoneErrorMessage
                : '';
    }
  }

  /**method to add required validators */
  addValidators() {
    if (this.addCandidateForm.controls['Email'].value) {
      this.addCandidateForm.controls['Email'].setValidators(
        Validators.compose([
          Validators.required,
          Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+[a-zA-Z0-9-]*\\.[a-zA-Z]{2,3}$')
        ]));
      this.addCandidateForm.controls['Email'].updateValueAndValidity();
      this.isEmailValid = true;
    } else {
      this.addCandidateForm.controls['Email'].clearValidators();
      this.addCandidateForm.controls['Email'].updateValueAndValidity();
      this.isEmailValid = false;
    }
    if (this.addCandidateForm.controls['PhoneNumber'].value) {
      this.addCandidateForm.controls['PhoneNumber'].setValidators(
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(10),
          this.regexValidator(new RegExp(/[A-Za-z]/g), { 'alphabets': true }),
          this.regexValidator(new RegExp(/[!@#$%^&*()/\\?,.?":{}\-\+=_|<>;'`~\]\[ ]/g), { 'specialCharacter': true })
        ]));
      this.addCandidateForm.controls['PhoneNumber'].updateValueAndValidity();
      this.isPhoneNumberValid = true;
    } else {
      this.addCandidateForm.controls['PhoneNumber'].clearValidators();
      this.addCandidateForm.controls['PhoneNumber'].updateValueAndValidity();
      this.isPhoneNumberValid = false;
    }
    if (this.addCandidateForm.controls['Level'].value) {
      this.addCandidateForm.controls['Level'].setValidators(Validators.required);
      this.addCandidateForm.controls['Level'].updateValueAndValidity();
      this.isLevelValid = true;
    } else {
      this.addCandidateForm.controls['Level'].clearValidators();
      this.addCandidateForm.controls['Level'].updateValueAndValidity();
      this.isLevelValid = false;
    }
    if (this.addCandidateForm.controls['Destination'].value) {
      this.isDestinationValid = true;
    } else {
      this.isDestinationValid = false;
    }
    if (this.addCandidateForm.controls['Extension'].value) {
      this.addCandidateForm.controls['Extension'].setValidators(Validators.required);
      this.addCandidateForm.controls['Extension'].updateValueAndValidity();
      this.isExtensionValid = true;
    } else {
      this.addCandidateForm.controls['Extension'].clearValidators();
      this.addCandidateForm.controls['Extension'].updateValueAndValidity();
      this.isExtensionValid = false;
    }
  }

  /**validate the pattern for phone number */
  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = control.value.match(regex);
      if (valid) {
        return error;
      } else {
        return null;
      }
    };
  }

  /**
   * destroys the object
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
