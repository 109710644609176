
import { Component, HostBinding, Inject, OnInit, ViewEncapsulation, ViewChild, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { deepCopy } from '@angular-devkit/core/src/utils/object';
import { RoleCapabilityService } from '../../../services/role-capability.service';
import { RolecapsRoleSearchComponent } from '../rolecaps-role-search/rolecaps-role-search.component';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSelectChange } from '@angular/material/select';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription, lastValueFrom } from 'rxjs';
import { Capability, RoleCapability, RoleCapabilityChanges } from 'src/app/rolecaps/models/rolecapability.model';
import { ConfirmDialogComponent } from 'src/app/core/components/shared/confirm-dialog/confirm-dialog.component';
import { CAP_ROLE_MAPPING_TITLE, CANCEL_DIALOG_TITLE, CANCEL_DIALOG_LINES, EDIT_CAP_ROLE_SUCCESS, ROLE_CAP_VERSION_ERROR,
         REMOVE_ROLE_DIALOG_TITLE, REMOVE_ROLE_DIALOG_LINES, EDIT_CAP_ROLE_DIALOG_TITLE, EDIT_CAP_ROLE_DIALOG_LINES,
         ROLE_CAP_ALREADY_EXISTS_ERROR, ROLE_DEF_NOT_FOUND_ERROR, GENERIC_API_ERROR, OPERATION_OPTIONS } from 'src/app/rolecaps/models/constants';
import { OperationType } from 'src/app/core/models/OperationType';
import * as querystring from 'querystring';
import { RoleDefinition } from 'src/app/rolecaps/models/definition.model';

@Component({
  selector: 'app-rolecaps-cap-mapping',
  templateUrl: './rolecaps-cap-mapping.component.html',
  styleUrls: ['./rolecaps-cap-mapping.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class RolecapsCapMappingComponent implements OnInit, AfterContentChecked {

  /** Component css class */
  @HostBinding('class') class = 'rolecaps-cap-mapping';

  /** Page Title */
  title = CAP_ROLE_MAPPING_TITLE;

  /** To check for readonly access */
  disable = false;

  /** The roleCaps being edited */
  editRoleCaps: Array<RoleCapability>;

  /** Transformed role caps for table datasource */
  transformedRoleCaps: Array<RoleCapability> = [];

  /** To store changes to role capabilities */
  changedRoleCaps: Array<RoleCapabilityChanges> = [];

  /** Definition only flag */
  definitionOnly: boolean = false;

  /** Store current value of opened select */
  currentSelectValue = '';

  /** The capability being added to or removed from role capabilities */
  capability = '';

  /** The capability description */
  capabilityDescription = '';

  /** Table data */
  ELEMENT_DATA: Array<RoleCapability> = [];

  /** Table datasource */
  dataSource: MatTableDataSource<RoleCapability>;

  /** Displayed table columns */
  displayedColumns: string[] = [
    'roleName',
    'friendlyName',
    'operation',
    'permission',
    'actions'
  ];

  /** Table Pagination */
  pageInfo = {
    itemCount: 0,
    currentPage: 0,
    perPage: 5,
    perPageOptions: [5, 10, 15]
  };

  /** Initial table sort by column */
  sortBy = 'roleName';

  /** Initial table sort direction */
  sortDir = 'asc';

  /** Service subscription  */
  private readonly subscription: Subscription = new Subscription();

  /** Capability Permission options */
  permissionOptions = ['allow', 'deny'];

  /** Capability Operation options */
  operationOptions = OPERATION_OPTIONS;

  /** Confirm Dialog Ref */
  confirmDialogRef: MatDialogRef<any>;

  /** Processing */
  processing = false;

  /** Progress spinner */
  progressMode = 'indeterminate';
  progressValue = 0;

  /** To sort the mat table columns */
  @ViewChild(MatSort, { static: false }) set sortOrder(sort: MatSort) {
    if (sort) {
      this.dataSource.sort = sort;
    }
  }

  /** To paginate in a mat table */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  /** Injecting dependencies */
  constructor(
    private readonly roleCapabilityService: RoleCapabilityService,
    private readonly notificationsService: NotificationsService,
    private readonly loggerService: LoggerService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<RolecapsCapMappingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef
  ) {
    if (this.data.roleCaps) {
      this.editRoleCaps = deepCopy(this.data.roleCaps);
      this.capability = this.data.capability;
      this.capabilityDescription = this.data.capabilityDescription;
      if (this.data.hasOwnProperty('definitionOnly')) {
        this.definitionOnly = this.data.definitionOnly;
      }
      if (!this.definitionOnly) {
        this.transformedRoleCaps = deepCopy(this.editRoleCaps);
        this.transformedRoleCaps.forEach(role => {
          role.capabilities = role.capabilities.filter((cap: Capability) => cap.name === this.capability);
        });
      }
      this.ELEMENT_DATA = this.transformedRoleCaps;
    }
  }

  /** To initialize the component */
  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    this.pageInfo.itemCount = this.ELEMENT_DATA.length;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
  }

  /** To detect changes after initialization */
  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  /**
   * Sorting accessor
   * @param item Capability
   * @param property string
   */
  sortingDataAccessor(item: RoleCapability, property: string) {
    switch (property) {
      case 'operation':
      case 'permission':
        return item.capabilities[0][property].toLocaleLowerCase();
      default:
        return item[property].toLocaleLowerCase();
    }
  }

  /**
   * Table Pagination
   * @param event PageEvent
   */
  onPagination(event: PageEvent) {
    this.pageInfo.perPage = event.pageSize;
  }

  /**
   * To check for readonly access
   * @param flag boolean
   */
  isDisabled(flag: boolean) {
    this.disable = flag;
  }

  /**
   * Store current value of opened select
   * @param value string
   */
  onMatSelectOpen(value: string): void {
    this.currentSelectValue = value;
  }

  /**
   * Compare changed value of opened select and push update
   * @param event MatSelectChange
   * @param roleCap RoleCapability 
   */
  onMatSelectValueChanges(event: MatSelectChange, roleCap: RoleCapability): void {
    if (this.currentSelectValue !== event.value) {
      const updateObj = {
        _id: roleCap._id,
        roleName: roleCap.roleName,
        friendlyName: roleCap.friendlyName,
        capability: roleCap.capabilities[0],
        operationType: OperationType.update
      };
      const index = this.changedRoleCaps.findIndex(item => item._id === roleCap._id);
      if (index === -1) {
        this.changedRoleCaps.push(updateObj);
      } else {
        if (this.changedRoleCaps[index].operationType === OperationType.create) {
          this.changedRoleCaps[index] = { ...updateObj, operationType: OperationType.create };
        } else {
          this.changedRoleCaps[index] = updateObj;
        }
      }
    }
  }

  /** Add Capability */
  openAddRoleDialog() {
    this.confirmDialogRef = this.dialog.open(RolecapsRoleSearchComponent, {
      disableClose: true,
      panelClass: 'dialog-main',
      data: { roleCaps: this.transformedRoleCaps, capability: this.capability },
      autoFocus: false,
      restoreFocus: false
    });
    this.subscription.add(
      this.confirmDialogRef.afterClosed().subscribe((additions: Array<RoleCapabilityChanges> | null) => {
        if (additions) {
          this.changedRoleCaps.push(...additions);
          additions.forEach(addition => {
            this.transformedRoleCaps.push({
              _id: addition._id,
              roleName: addition.roleName,
              friendlyName: addition.friendlyName,
              capabilities: [addition.capability]
            });
          });
          this.refreshData();
        }
      })
    );
  }

  /**
   * Remove Capability from Role Capability confirmation
   * @param roleCap Capability
   */
  openRemoveCapConfirm(roleCap: RoleCapability) {
    const dialogLines = [...REMOVE_ROLE_DIALOG_LINES];
    dialogLines[0] = dialogLines[0].replace('$role', roleCap.roleName);
    dialogLines.push(roleCap.capabilities[0].name);
    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      panelClass: 'dialogMainContainer',
      data: { dialogTitle: REMOVE_ROLE_DIALOG_TITLE, dialogLines },
      autoFocus: false,
      restoreFocus: false
    });
    this.subscription.add(
      this.confirmDialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.removeCap(roleCap);
        }
      })
    );
  }

  /**
   * Remove Capability from Role Capability
   * @param roleCap Capability
   */
  removeCap(roleCap: RoleCapability) {
    const deleteObj = {
      _id: roleCap._id,
      roleName: roleCap.roleName,
      friendlyName: roleCap.friendlyName,
      capability: roleCap.capabilities[0],
      operationType: OperationType.delete
    };
    const index = this.changedRoleCaps.findIndex(item => item._id === roleCap._id);
    if (index === -1) {
      this.changedRoleCaps.push(deleteObj);
    } else {
      if (this.changedRoleCaps[index].operationType === OperationType.create) {
        this.changedRoleCaps.splice(index, 1);
      } else {
        this.changedRoleCaps[index] = deleteObj;
      }
    }
    this.transformedRoleCaps.splice(this.transformedRoleCaps.findIndex(item => item._id === roleCap._id), 1);
    this.refreshData();
  }

  /** Cancel confirmation */
  openCancelConfirm() {
    if (this.changedRoleCaps.length > 0) {
      const dialogLines = [...CANCEL_DIALOG_LINES];
      this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        panelClass: 'dialogMainContainer',
        data: { dialogTitle: CANCEL_DIALOG_TITLE, dialogLines },
        autoFocus: false,
        restoreFocus: false
      });
      this.subscription.add(
        this.confirmDialogRef.afterClosed().subscribe((result: boolean) => {
          if (result) {
            this.cancel();
          }
        })
      );
    } else {
      this.cancel();
    }
  }

  /** To close the modal without saving changes */
  cancel() {
    this.dialogRef.close();
  }

  /** Refresh the table datasource */
  refreshData() {
    this.dataSource.data = this.dataSource.data;
  }

  /** Update Role Capability confirmation */
  openUpdateRoleCapsConfirm() {
    const dialogLines = [...EDIT_CAP_ROLE_DIALOG_LINES, this.createChangesSummary()];
    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      panelClass: 'dialogMainContainer',
      data: { dialogTitle: EDIT_CAP_ROLE_DIALOG_TITLE, dialogLines },
      autoFocus: false,
      restoreFocus: false
    });
    this.subscription.add(
      this.confirmDialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.updateRoleCaps();
        }
      })
    );
  }
  
  /** Create a summary of the changes to display in the confirmation dialog */
  createChangesSummary() {
    const lines = [];
    this.changedRoleCaps.forEach(item => {
      if (item.operationType === OperationType.create) {
        lines.push(`Add capability '${item.capability.name}' to '${item.roleName}' role capability`);
      }
      if (item.operationType === OperationType.delete) {
        lines.push(`Remove capability '${item.capability.name}' from '${item.roleName}' role capability`);
      }
      if (item.operationType === OperationType.update) {
        lines.push(`Update capability '${item.capability.name}' in '${item.roleName}' role capability`);
      }
    });
    return lines.sort().join('<br />');
  }

  /** Update the Role Capabilities */
  async updateRoleCaps() {
    this.progressMode = 'determinate';
    this.progressValue = 25;
    this.processing = true;
    const requestCount = this.changedRoleCaps.length;
    let completed = 0;
    let allErrors = [];
    for (const item of this.changedRoleCaps) {
      let request: Observable<any>;
      // check if an existing role capabilities document exists
      const queryString = {};
      queryString['roleName'] = item.roleName;
      await lastValueFrom(this.roleCapabilityService.getRoleCapByRoleName(querystring.stringify(queryString))).then(async resp => {
        if (!resp || resp && resp.status !== 200 && !(resp.toString().indexOf('Role Capability not found.') > -1)) {
          allErrors.push({ item, resp });
        } else if (resp && resp.body && resp.status === 200) {
          const roleCapability: RoleCapability = resp.body;
          const roleCapabilityId = roleCapability._id;
          delete roleCapability._id;
          if (!roleCapability.capabilities) {
            roleCapability['capabilities'] = [];
          }
          const index = roleCapability.capabilities.findIndex(cap => cap.name === item.capability.name);
          if (item.operationType === OperationType.create) {
            if (index > -1) {
              roleCapability.capabilities[index] = item.capability;
            } else {
              roleCapability.capabilities.push(item.capability);
            }
          }
          if (item.operationType === OperationType.update && index > -1) {
            roleCapability.capabilities[index] = { ...roleCapability.capabilities[index], ...item.capability };
          }
          if (item.operationType === OperationType.delete && index > -1) {
            roleCapability.capabilities.splice(index, 1);
          }
          request = this.roleCapabilityService.updateRoleCapability(roleCapabilityId, roleCapability);
          // there is no existing role capabilities document so get role defintion instead
        } else if (resp && resp.toString().indexOf('Role Capability not found.') > -1) {
          await lastValueFrom(this.roleCapabilityService.getRoleDefinitions()).then(async resp => {
            if (!resp || resp && resp.status !== 200 && resp.status !== 404) {
              allErrors.push({ item, resp });
            } else if (resp && resp.body && resp.status === 200) {
              const roleDefinitions: Array<RoleDefinition> = resp.body;
              const roleDefinition = roleDefinitions.find(definition => definition.roleName === item.roleName);
              if (roleDefinition) {
                const {
                  _id, definitionType, __v, createdAt, updatedAt, createdBy, updatedBy, ...roleCapability
                } = roleDefinition;
                roleCapability['capabilities'] = [item.capability];
                request = this.roleCapabilityService.createRoleCapability(roleCapability);
              } else {
                allErrors.push({ item, resp: ROLE_DEF_NOT_FOUND_ERROR });
              }
            } else if (resp && resp.body && resp.status === 404) {
              allErrors.push({ item, resp: ROLE_DEF_NOT_FOUND_ERROR });
            }
          });
        }
        if (request) {
          await lastValueFrom(request).then(async resp => {
            if (!resp || resp && resp.status !== 200) {
              allErrors.push({ item, resp });
            }
          });
        }
        completed++;
        this.progressValue = Math.round((completed / requestCount) * 75) + 25;
      });
    }
    this.progressMode = 'indeterminate';
    this.processing = false;
    if (allErrors.length > 0) {
      this.notificationsService.flashNotification('danger', this.processErrors(allErrors));
    } else {
      this.notificationsService.flashNotification('success', EDIT_CAP_ROLE_SUCCESS);
    }
    this.dialogRef.close(this.capability);
  }

  /**
   * Translate API errors into user friendly messages
   * @param errors
   */
  processErrors(errors: Array<any>) {
    const header = `The following operation${errors.length > 1 ? 's' : ''} failed:`;
    const data = {
      html: true,
      header: header,
      lines: [],
      footer: ''
    };
    errors.forEach(error => {
      const errString = JSON.stringify(error.resp).toLocaleLowerCase();
      const operationType = error.item.operationType;
      let operationDesc = '';
      switch (operationType) {
        case 0:
          operationDesc = `Add '${error.item.capability.name}' capability to '${error.item.roleName}' role capability`;
          break;
        case 2:
          operationDesc = `Update '${error.item.capability.name}' capability in '${error.item.roleName}' role capability`;
          break;
        case 3:
          operationDesc = `Delete '${error.item.capability.name}' capability from '${error.item.roleName}' role capability`;
          break;
      }
      if (errString.indexOf('already exists') > -1) {
        data.lines.push(`${operationDesc} - ${ROLE_CAP_ALREADY_EXISTS_ERROR}.`);
      } else if (errString.indexOf('no matching document found for id') > -1) {
        data.lines.push(`${operationDesc} - ${ROLE_CAP_VERSION_ERROR}.`);
      } else if (errString.indexOf('internal server error') > -1) {
        data.lines.push(`${operationDesc} - ${GENERIC_API_ERROR}.`);
      } else {
        data.lines.push(`${operationDesc} - ${errString}.`);
      }
    });
    return data;
  }

}
