/** MOV360-880 - to be removed at future date */
import { Component, OnInit, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApprovedMove } from '../../../core/models/approved-move';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApprovedMovesService } from '../../../core/services/approved-moves.service';
import { BudgetDetailsService } from '../../../core/services/budget-details.service';
import { PopupPositionService } from './../../../core/services/popup-position.service';
import { CandidateBudgetDetails } from '../../../core/models/candidate-budget-details.model';
import { formatNumber } from '@angular/common';
import { CandidateContactInfo } from '../../../core/models/candidate-contact-info.model';
import { CandidateMoveInfo } from '../../../core/models/candidate-move-info.model';
import { DepatureHomeDetails } from '../../../core/models/depature-home-details.models';
import { OverlayConfig, OverlayRef, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MatDialog } from '@angular/material/dialog';
import { AuthorizedMovePhaseComponent } from '../../../core/components/authorized-move-phase/authorized-move-phase.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Client } from '../../../core/models/client';
import { LoggerService } from '../../../core/services/logger.service';
import { UserContextService } from '../../../core/services/user-context.service';
import { Timeline } from '../../../core/models/timeline-service.model';
import { PartySharedService } from 'src/app/core/services/party-shared.service';
import { AddSupplierInvoiceComponent } from '../finance-audit/add-supplier-invoice/add-supplier-invoice.component';
import {
  FormControl, UntypedFormBuilder, UntypedFormGroup, Validators, ValidationErrors,
  ValidatorFn, AbstractControl,
} from '@angular/forms';
import { SupplierInvoiceService } from 'src/app/core/services/supplier-invoice.service';
import { SupplierInvoice } from 'src/app/core/models/supplier-invoice';
import { VanlinePopupComponent } from './vanline-popup/vanline-popup.component';

@Component({
  selector: 'app-finance-audit-details',
  templateUrl: './finance-audit-details.component.html',
  styleUrls: ['./finance-audit-details.component.scss']
})

export class FinanceAuditDetailsComponent implements OnInit {

  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /**used to store approvedMoves details */
  approvedMovesDetails: ApprovedMove;
  /**used to store core and flex budget details */
  budgetDetailsService: any;
  /**store candidate budget details */
  candidateBudgetDetails: CandidateBudgetDetails;
  /**candidateInfo of type CandidateInfo */
  candidateInfo: any;
  /**candidateContactInfo of type CandidateContactInfo */
  candidateContactInfo: CandidateContactInfo;
  /**candidateMoveInfo of type CandidateMoveInfo */
  candidateMoveInfo: CandidateMoveInfo;
  /** stores candidate departure home details information */
  candidateDepartureInfo: DepatureHomeDetails;
  /**stores timeline information */
  timelineService: Timeline[];
  /**Client contact ID */
  clientContactId: string;
  /**store candidate name */
  candidateName: string;
  /**mat accordion panel header height */
  matExpansionHeaderHeight = '40px';
  /** overlayRef to hold overlay config */
  overlayRef: OverlayRef;
  /** vanlineoverlayRef to hold overlay config */
  vanlineOverlayRef: OverlayRef;
  /** Cartus Commission */
  cartusCommission: number;
  /** Show Invoice Flag */
  showInvoice = false;
  /** Data Source for the table */
  ELEMENT_DATA: any;
  /** Form Group */
  AuditForm: UntypedFormGroup;
  /** Supplier Invoice Data */
  supplierInvoiceData: any;
  dataSource: any;
  deliveryDateString: string;
  /** columns to be displayed */
  displayedColumns: string[] = [
    'vendorName',
    'invoiceId',
    'amount',
    'invoiceDate',
    'actualWeight',
    'actualMileage',
    'status'
  ];
  /**
   * injecting dependencies
   * @param route instance of ActivatedRoute
   * @param spinner spinner injection
   * @param approvedService ApprovedMovesService injection
   */
  constructor(private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private readonly route: ActivatedRoute,
    private readonly spinner: NgxSpinnerService,
    private readonly approvedService: ApprovedMovesService,
    private readonly overlay: Overlay,
    private readonly positionService: PopupPositionService,
    private readonly snackBar: MatSnackBar,
    private Logger: LoggerService,
    private loggedInUserService: UserContextService,
    private partySharedSvc: PartySharedService
  ) { }


  /**Initialise the component */
  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const candidateId = params.get('candidateId');
      if (candidateId) {
        this.partySharedSvc.getPartyId().then(id => {
          if (id) {
            this.spinner.show();
            this.clientContactId = id;
            this.subscription.add(
              this.approvedService.getAppovedMovesById(candidateId).subscribe(budgetDetails => {
                this.spinner.hide();
                if (budgetDetails) {
                  this.candidateBudgetDetails = budgetDetails;
                  if (budgetDetails['auditStatus'] === 'Audit Complete' || budgetDetails['auditStatus'] === 'Audit Completed') {
                    this.showInvoice = true;
                    this.supplierInvoiceData = {};
                    this.supplierInvoiceData['invoiceId'] = budgetDetails['invoiceId'] !== undefined ?
                      budgetDetails['invoiceId'] : '00000';
                    this.supplierInvoiceData['actualWeight'] = budgetDetails['actualWeight'] !== undefined ?
                      budgetDetails['actualWeight'] : '0';
                    this.supplierInvoiceData['actualMileage'] = budgetDetails['actualMileage'] !== undefined ?
                      budgetDetails['actualMileage'] : '0';
                    this.supplierInvoiceData['invoiceDate'] = budgetDetails['invoiceDate'] !== undefined ?
                      budgetDetails['invoiceDate'] : '0';
                    this.supplierInvoiceData['amount'] = budgetDetails['amount'] !== undefined ? budgetDetails['amount'] : '0';
                    this.supplierInvoiceData['auditStatus'] = 'Audit Complete';
                  } else if (budgetDetails['auditStatus'] === 'Draft Saved' || budgetDetails['auditStatus'] === 'Draft Saved') {
                    this.showInvoice = true;
                    this.supplierInvoiceData = {};
                    this.supplierInvoiceData['invoiceId'] = budgetDetails['invoiceId'] !== undefined ?
                      budgetDetails['invoiceId'] : '00000';
                    this.supplierInvoiceData['actualWeight'] = budgetDetails['actualWeight'] !== undefined ?
                      budgetDetails['actualWeight'] : '0';
                    this.supplierInvoiceData['actualMileage'] = budgetDetails['actualMileage'] !== undefined ?
                      budgetDetails['actualMileage'] : '0';
                    this.supplierInvoiceData['invoiceDate'] = budgetDetails['invoiceDate'] !== undefined ?
                      budgetDetails['invoiceDate'] : '0';
                    this.supplierInvoiceData['amount'] = budgetDetails['amount'] !== undefined ? budgetDetails['amount'] : '0';
                    this.supplierInvoiceData['auditStatus'] = 'Draft Saved';
                  }
                  this.deliveryDateString = `${this.candidateBudgetDetails.needsAssessment.estimatedMoveStartDate} - ${this.candidateBudgetDetails.needsAssessment.estimatedMoveEndDate}`;
                  this.ELEMENT_DATA = this.supplierInvoiceData;
                  this.dataSource = [this.ELEMENT_DATA];
                  this.getCandidateBudgetDetails(this.candidateBudgetDetails);
                  this.setCandidateName();
                  this.initializeClientForm();
                  this.loggedInUserService.getLoggedInUserDetails()
                    .subscribe(response => {
                      const userId: any = response.userId.replace(/ .*/, '');
                      this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-AUTHORIZE_TRANSFEREE_DETAILS');
                    });
                } else {
                  this.snackBar.open(
                    'We are unable to process your request at this time. Please try again later.',
                    undefined,
                    { duration: 5000 }
                  );
                }
              })
            );
          }
        });
      }

    });
  }

  initializeClientForm(): void {
    this.AuditForm = this.formBuilder.group({
      VendorName: [''],
      InvoiceNumber: [''],
      Amount: [''],
      InvoiceDate: [''],
      EstimatedWeight: [''],
      ActualWeight: [''],
      EstimatedMileage: [''],
      ActualMileage: [''],
      EstimatedDeliveryStartDate: [''],
      EstimatedDeliveryEndDate: [''],
      ActualDeliveryDate: ['']
    });
  }

  public reloadTransfereeData() {
    this.spinner.show();
    this.route.paramMap.subscribe((params: ParamMap) => {
      const candidateId = params.get('candidateId');
      // console.log('Finance audit details' +JSON.stringify(candidateId, null, 4))
      if (candidateId) {
        this.partySharedSvc.getPartyId().then(id => {
          if (id) {
            this.clientContactId = id;
            this.subscription.add(
              this.approvedService.getAppovedMovesById(candidateId).subscribe(budgetDetails => {
                if (budgetDetails) {
                  this.spinner.hide();
                  this.candidateBudgetDetails = budgetDetails;
                  if (budgetDetails['auditStatus'] === 'Audit Complete' || budgetDetails['auditStatus'] === 'Audit Completed') {
                    this.showInvoice = true;
                    this.supplierInvoiceData = {};
                    this.supplierInvoiceData['invoiceId'] = budgetDetails['invoiceId'] !== undefined ?
                      budgetDetails['invoiceId'] : '00000';
                    this.supplierInvoiceData['actualWeight'] = budgetDetails['actualWeight'] !== undefined ?
                      budgetDetails['actualWeight'] : '0';
                    this.supplierInvoiceData['actualMileage'] = budgetDetails['actualMileage'] !== undefined ?
                      budgetDetails['actualMileage'] : '0';
                    this.supplierInvoiceData['invoiceDate'] = budgetDetails['invoiceDate'] !== undefined ?
                      budgetDetails['invoiceDate'] : '0';
                    this.supplierInvoiceData['amount'] = budgetDetails['amount'] !== undefined ?
                      budgetDetails['amount'] : '0';
                    this.supplierInvoiceData['auditStatus'] = 'Audit Complete';
                  } else if (budgetDetails['auditStatus'] === 'Draft Saved' || budgetDetails['auditStatus'] === 'Draft Saved') {
                    this.showInvoice = true;
                    this.supplierInvoiceData = {};
                    this.supplierInvoiceData['invoiceId'] = budgetDetails['invoiceId'] !== undefined ?
                      budgetDetails['invoiceId'] : '00000';
                    this.supplierInvoiceData['actualWeight'] = budgetDetails['actualWeight'] !== undefined ?
                      budgetDetails['actualWeight'] : '0';
                    this.supplierInvoiceData['actualMileage'] = budgetDetails['actualMileage'] !== undefined ?
                      budgetDetails['actualMileage'] : '0';
                    this.supplierInvoiceData['invoiceDate'] = budgetDetails['invoiceDate'] !== undefined ?
                      budgetDetails['invoiceDate'] : '0';
                    this.supplierInvoiceData['amount'] = budgetDetails['amount'] !== undefined ? budgetDetails['amount'] : '0';
                    this.supplierInvoiceData['auditStatus'] = 'Draft Saved';
                  }
                  this.ELEMENT_DATA = this.supplierInvoiceData;
                  this.dataSource = [this.ELEMENT_DATA];
                  this.getCandidateBudgetDetails(this.candidateBudgetDetails);
                  this.setCandidateName();
                  this.initializeClientForm();
                  this.loggedInUserService.getLoggedInUserDetails()
                    .subscribe(response => {
                      const userId: any = response.userId.replace(/ .*/, '');
                      this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-AUTHORIZE_TRANSFEREE_DETAILS');
                    });
                } else {
                  this.snackBar.open(
                    'We are unable to process your request at this time. Please try again later.',
                    undefined,
                    { duration: 5000 }
                  );
                }
              })
            );
          }
        });
      }
    });
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  onKeyUp(event) {
    if (event.key === 13) {
      this.open();
    }
  }

  open() {
    let emptyInvoice: SupplierInvoice = {} as SupplierInvoice;
    if (this.supplierInvoiceData !== undefined) {
      emptyInvoice = this.supplierInvoiceData;
      emptyInvoice.orderId = this.candidateBudgetDetails['orderId'];
      emptyInvoice.amount = this.supplierInvoiceData['amount'] ?
        this.supplierInvoiceData['amount'] : this.candidateBudgetDetails['totalVanlineCost'];
    } else {
      emptyInvoice.orderId = this.candidateBudgetDetails['orderId'];
      emptyInvoice.amount = this.candidateBudgetDetails['totalVanlineCost'];
    }
    const dialogRef = this.dialog.open(AddSupplierInvoiceComponent, {
      panelClass: 'dialogMainContainer',
      data: emptyInvoice,
      disableClose: false
    });
    this.subscription.add(dialogRef.afterClosed().subscribe((supplierInvoice: any) => {
      if (supplierInvoice) {
        this.showInvoice = true;
        this.supplierInvoiceData = supplierInvoice;
        this.reloadTransfereeData();
      }
    }));
  }

  /**store the candidate budget details */
  getCandidateBudgetDetails(candidateDetails: CandidateBudgetDetails) {
    this.candidateContactInfo = {
      candidateName: candidateDetails.candidate.fullname,
      emailId: candidateDetails.candidate.emailAddress,
      phoneNumber: candidateDetails.candidate.phoneNumber,
      countryDialingCode: candidateDetails.candidate.countryDialingCode

    };
    this.candidateInfo = {
      level: candidateDetails.candidate.level,
      businessUnit: candidateDetails.candidate.businessUnit,
      clientName: candidateDetails.candidate.clientName
    };

    this.candidateMoveInfo = {
      departure: {
        fullAddress: candidateDetails.candidate.departure.fullAddress,
        streetAddress: candidateDetails.candidate.departure.streetAddress,
        city: candidateDetails.candidate.departure.city,
        state: candidateDetails.candidate.departure.state,
        zipcode: candidateDetails.candidate.departure.zipcode,
        country: candidateDetails.candidate.departure.country
      },
      destination: {
        fullAddress: candidateDetails.candidate.destination.fullAddress,
        streetAddress: candidateDetails.candidate.destination.streetAddress,
        city: candidateDetails.candidate.destination.city,
        state: candidateDetails.candidate.destination.state,
        zipcode: candidateDetails.candidate.destination.zipcode,
        country: candidateDetails.candidate.destination.country
      },
      totalNumberOfPeople: candidateDetails.needsAssessment.familyDetails.noOfRelocatePeople,
      estimatedMoveStartDate: candidateDetails.needsAssessment.estimatedMoveStartDate,
      estimatedMoveEndDate: candidateDetails.needsAssessment.estimatedMoveEndDate
    };
    this.candidateDepartureInfo = {
      departure: {
        fullAddress: candidateDetails.candidate.departure.fullAddress,
        streetAddress: candidateDetails.candidate.departure.streetAddress,
        city: candidateDetails.candidate.departure.city,
        state: candidateDetails.candidate.departure.state,
        zipcode: candidateDetails.candidate.departure.zipcode,
        country: candidateDetails.candidate.departure.country
      },
      ownerStatus: candidateDetails.needsAssessment.residenceDetails.ownerStatus,
      housingType: candidateDetails.needsAssessment.residenceDetails.homeType,
      noOfRooms: candidateDetails.needsAssessment.residenceDetails.noOfRooms.toString()
    };
    this.budgetDetailsService = {
      coreBenefitsTotal: candidateDetails.coreBudgetTotal,
      flexSpendTotal: candidateDetails.flexSpendTotal,
      estimatedWeight: candidateDetails.estimatedWeight,
      title: '',
      class: 'authorized-moves'
    };

    this.approvedMovesDetails = candidateDetails.approvedMoves;
    this.timelineService = candidateDetails.timelineDetails;
  }
  /** sets Candidate Name */
  setCandidateName() {
    this.candidateName =
      `${this.candidateContactInfo.candidateName.split(',')[1]} ${this.candidateContactInfo.candidateName.split(',')[0]}`;
  }
  /**Format the amount to USD format */
  formatTotalAmount(Amount) {
    return formatNumber(Amount, 'en-US').toString() + ' USD';
  }

  roundofAmount(amt) {
    return Math.round(amt);
  }
  /** Open the move phase dialog */
  openMovePhaseModal(approvedMove: ApprovedMove, evt: MouseEvent) {
    evt.stopPropagation();
    if (approvedMove.movePhase) {
      const rect = (evt.currentTarget as Element).getBoundingClientRect();
      const target = this.getPositionByEvents(rect);
      const element = new ElementRef(target);
      const positionStrategy = this.placeByPositionStrategy(element);
      const overlayConfig = new OverlayConfig({
        width: '',
        height: '',
        panelClass: 'movePhaseModal',
        hasBackdrop: true,
        backdropClass: 'mat-backdrop-transparent',
        positionStrategy,
      });
      this.overlayRef = this.overlay.create(overlayConfig);
      const containerPortal = new ComponentPortal(AuthorizedMovePhaseComponent, null, this.positionService.createInjector({
        movePhase: approvedMove.movePhase,
        overlayRef: this.overlayRef
      }));
      this.overlayRef.attach(containerPortal);
      this.overlayRef.backdropClick().subscribe(() => {
        this.overlayRef.dispose();
      });
    }
  }

  openVanlineModal(evt: MouseEvent) {
    const rect = (evt.currentTarget as Element).getBoundingClientRect();
    const target = this.getPositionByEvents(rect);
    const element = new ElementRef(target);
    const positionStrategy = this.placeByPositionStrategy(element);
    const overlayConfig = new OverlayConfig({
      width: '',
      height: '',
      panelClass: 'dialogMainContainerVanline',
      hasBackdrop: true,
      backdropClass: 'mat-backdrop-transparent',
      positionStrategy,
    });
    this.vanlineOverlayRef = this.overlay.create(overlayConfig);
    const containerPortal = new ComponentPortal(VanlinePopupComponent, null, this.positionService.createInjector({
      data: this.candidateBudgetDetails,
      vanlineOverlayRef: this.vanlineOverlayRef
    }));
    this.vanlineOverlayRef.attach(containerPortal);
    this.vanlineOverlayRef.backdropClick().subscribe(() => {
      this.vanlineOverlayRef.dispose();
    });
  }

  /** get the position by events of the target */
  getPositionByEvents(rect) {
    return {
      getBoundingClientRect: () => ({
        bottom: rect.top + (rect.bottom - rect.top) / 2,
        height: rect.height,
        left: rect.left + (rect.right - rect.left) / 2,
        right: rect.left + (rect.right - rect.left) / 2,
        top: rect.top + (rect.bottom - rect.top) / 2,
        width: rect.width
      }),
    };
  }

  /** place the pop up by position strategy */
  placeByPositionStrategy(element: ElementRef) {
    return this.overlay
      .position()
      .flexibleConnectedTo(element)
      .withFlexibleDimensions(false)
      .withPositions([
        {
          originX: 'end',
          originY: 'center',
          overlayX: 'start',
          overlayY: 'center',
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'bottom',
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        }
      ]);
  }

}
