/** MOV360-880 - to be removed at future date */
import { BaseClientService } from './base-client.service';
import { Injectable } from '@angular/core';
import { CostModel, CostModelDetails } from '../models/cost-model';
import { formatDate } from '@angular/common';
import { RemoteLoggingService } from './remote-logging.service';
import { Observable, of } from 'rxjs';
import { map, catchError, delay } from 'rxjs/operators';
import { COST_MODEL_LIST } from '../../../UnitTest-Support/Mocks/data.mock';
import { APIResponse } from '../models/response.model';
import { LoggerService } from './logger.service';
import { urlType } from '../models/urlType';
import { CandidateProfilesService } from './candidate-profiles.service';

@Injectable({
  providedIn: 'root'
})

export class CostModelsService {
  costModelList: CostModel[] = COST_MODEL_LIST.body.costmodels;

  costModelGet: CostModelDetails = {
    totalCostModel: 20,
    costmodels: this.costModelList
  };

  /**
   * Base constructor
   * @param baseClientService Instance of BaseClient Service
   * @param logSvc Instance of log Service
   */
  constructor(private readonly baseClientService: BaseClientService, private readonly logSvc: RemoteLoggingService,
    private readonly customLogger: LoggerService, private readonly candidateProfileSvc: CandidateProfilesService) { }

  /**
   * Return the cost models as json list and loop to display in the table
   * @param queryString queryString
   */
  getCostModels(queryString: string):
    Observable<any> {
    return this.baseClientService.get<any>(`/costmodel?${queryString}`,
      '',
      urlType.costmodel).pipe(
        map(r => r),
        catchError(err => {
          this.customLogger.error('Failed to get cost models', err);
          const emptyArray: CostModelDetails = null;
          return of(emptyArray);
        })
      );
  }

  /**
   * Used to add a CostModel
   * @param request cost model details which is to be added
   */
  createCostModel(request: CostModel): Observable<CostModel> {
    return this.baseClientService.post<any>('/costmodel', request, '', urlType.costmodel).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: APIResponse = null;
        this.customLogger.error('Failed to add cost models', err);
        return of(empty);
      })
    );
  }

  /**
   * Used to save CostModel
   * @param request cost model details which is to be saved
   */
  saveCostModel(costModelId, request: CostModel): Observable<CostModel> {
    return this.baseClientService.put<any>(`/costmodel/${costModelId}`, request, '', urlType.costmodel).pipe(
      map(r => r.body),
      catchError((err, source) => {
        return this.candidateProfileSvc.catchBlock('Failed to update cost model', err, 'string');

      })
    );
  }

  /**
   * Used to update Cost Model
   * @param request Cost Model whose details are to be updated
   */
  // addCostModel(formData, costModelId?) {
  //   const dateString = this.formatDate(new Date());
  //   const currentDate = new Date();
  //   const numberOfDaysToAdd = 30;
  //   currentDate.setDate(currentDate.getDate() + numberOfDaysToAdd);
  //   if (this.costModelList.find(v => v.costModelId === costModelId) === undefined) {
  //     const newCostModelObj = {
  //       'costModelId': (Math.floor((Math.random() * 100000) + 1)).toString(),
  //       'costModelName': formData.ModelName,
  //       'clientId': '1234',
  //       'clientContactId': '5d8b16401c9d440000f9bdec',
  //       'costEstimates': [
  //         {
  //           'familySize': 2,
  //           'estimatedTotalMaximumCost': 30400,
  //           'estimatedTotalMinimumCost': 20400,
  //           'coreServices': {
  //             'estimatedSubTotalMaximumCost': 16400,
  //             'estimatedSubTotalMinimumCost': 14600,
  //           },
  //           'flexServices': {
  //             'estimatedSubTotalMaximumCost': 14000,
  //             'estimatedSubTotalMinimumCost': 5800,
  //           }
  //         },
  //         {
  //           'familySize': 4,
  //           'estimatedTotalMaximumCost': 45000,
  //           'estimatedTotalMinimumCost': 25900,
  //           'coreServices': {
  //             'estimatedSubTotalMaximumCost': 20400,
  //             'estimatedSubTotalMinimumCost': 14800,
  //           },
  //           'flexServices': {
  //             'estimatedSubTotalMaximumCost': 24600,
  //             'estimatedSubTotalMinimumCost': 11100,
  //           }
  //         },
  //         {
  //           'familySize': 1,
  //           'estimatedTotalMaximumCost': 52300,
  //           'estimatedTotalMinimumCost': 31800,
  //           'coreServices': {
  //             'estimatedSubTotalMaximumCost': 25800,
  //             'estimatedSubTotalMinimumCost': 19600,
  //           },
  //           'flexServices': {
  //             'estimatedSubTotalMaximumCost': 26500,
  //             'estimatedSubTotalMinimumCost': 12200,
  //           }
  //         }
  //       ],
  //       'taxGrossRate': '15500',
  //       'destCity': formData.Destination.split(',')[0],
  //       'destState': formData.Destination.split(',').map(x => x.trim())[1],
  //       'deptCity': formData.Departure.split(',')[0],
  //       'deptState': formData.Departure.split(',').map(x => x.trim())[1],
  //       'level': formData.level,
  //       'createdDate': dateString,
  //       'updatedDate': dateString,
  //       'expiryDate': this.formatDate(currentDate)
  //     };
  //     this.costModelList.push(newCostModelObj);
  //   } else {
  //     const updateDate = this.formatDate(currentDate);
  //     this.costModelList
  //       .filter(function (item) {
  //         return item.costModelId === costModelId;
  //       })
  //       .forEach(item => {
  //         item.costModelName = formData.ModelName;
  //         item.level = formData.level;
  //         item.deptCity = formData.Departure.split(',')[0];
  //         item.deptState = formData.Departure.split(',').map(x => x.trim())[1];

  //         item.destCity = formData.Destination.split(',')[0];
  //         item.destState = formData.Destination.split(',').map(x =>
  //           x.trim()
  //         )[1];
  //         item.updatedDate = dateString;
  //         item.expiryDate = updateDate;
  //         return of(item);
  //       });
  //   }
  // }

  deleteCostModel(deleteCostModelDetails): Observable<any> {
    return this.baseClientService
      .put<APIResponse>('/costmodel/delete', deleteCostModelDetails, '', urlType.costmodel)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty: any = null;
          this.customLogger.error('Failed to delete cost model', err);
          return of(empty);
        })
      );
  }

  // toggleDeleteFlag(costModelId) {
  //   this.costModelList.filter(function (item) {
  //     return item.costModelId === costModelId;
  //   }).map(function (item) {
  //     item.isDeleted = !item.isDeleted;
  //     return item;
  //   });
  // }

  // formatDate(date) {
  //   return `${date.getFullYear()} -${('0' + (date.getMonth() + 1)).slice(-2)} -${('0' + date.getDate()).slice(-2)}`;
  // }
}
