/** MOV360-880 - to be removed at future date */
import { Component, OnInit, ViewEncapsulation, ElementRef, HostBinding, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  addCostModelCurrency,
  compareCostModelBreadcrumbTittle,
  costModelBreadcrumbTittle,
  warningIcon,
  arrowForwardIos,
  personOne,
  twoPerson,
  fourPerson,
  coreServices,
  flexibleServices,
  taxAssistanceServices,
  vanLineMove,
  multipleProbableServices,
  insurancePercent,
  minInsuranceAmt
} from '../../../../core/models/constants';
import { OverlayConfig, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { OverlayTooltipComponent } from '../../../../core/components/overlay-tooltip/overlay-tooltip.component';
import { PopupPositionService } from '../../../../core/services/popup-position.service';
import { LoggerService } from '../../../../core/services/logger.service';
import { UserContextService } from '../../../../core/services/user-context.service';

@Component({
  selector: 'app-compare-cost-models',
  templateUrl: './compare-cost-models.component.html',
  styleUrls: ['./compare-cost-models.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CompareCostModelsComponent implements OnInit {
  /** costmodel List */
  costmodelList: any;
  /** Currency */
  currency = addCostModelCurrency;
  /** overlayRef to hold overlay config */
  overlayRef: OverlayRef;
  /** Title to display compare cost model in breadcrumb*/
  compareCostModelBreadcrumbTittle = compareCostModelBreadcrumbTittle;
  /** Title to display cost model in breadcrumb*/
  costModelBreadcrumbTittle = costModelBreadcrumbTittle;
  /** Title to display warning icon*/
  warningIcon = warningIcon;
  /** Title to display arrow forward icon*/
  arrowForwardIos = arrowForwardIos;
  /** Title to display personOne*/
  personOne = personOne;
  /** Title to display two person*/
  twoPerson = twoPerson;
  /** Title to display four person*/
  fourPerson = fourPerson;
  /** Title to display core services*/
  coreServices = coreServices;
  /** Title to display flexible services*/
  flexibleServices = flexibleServices;
  /** Title to display tax assistance services*/
  taxAssistanceServices = taxAssistanceServices;
  /** Title to display van Line Move services*/
  vanLineMove = vanLineMove;
  /** Title to display van Line Move services*/
  multipleProbableServices = multipleProbableServices;

  @HostBinding('class') class = 'app-compare-cost-models';

  /**
   * Base constructor
   * @param overlay Overlay injection
   * @param positionService PopupPositionService injection
   * @param route ActivatedRoute injection
   */
  constructor(private readonly route: ActivatedRoute, private readonly router: Router,
    private readonly overlay: Overlay,
    private readonly positionService: PopupPositionService,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: UserContextService) {
    /** get selected costmodel List */
    this.route.queryParams.subscribe(params => {
      const data = this.router.getCurrentNavigation().extras.state;
      if (data && data.selectedCostModels) {
        for (const costModel of data.selectedCostModels) {
          costModel.deptFullAdd = `${costModel.deptCity}, ${costModel.deptState}`;
          costModel.destFullAdd = `${costModel.destCity}, ${costModel.destState}`;
        }
        this.costmodelList = data.selectedCostModels;
      }
    });
  }

  @HostListener('document:keyup', ['$event']) onKeyUp(ev: KeyboardEvent) {
    if (ev.code === 'ArrowRight' || ev.code === 'ArrowUp' || ev.code === 'ArrowLeft' || ev.code === 'ArrowDown') {
      this.overlayRef.dispose();
    }
  }
  /** Open the tooltip dialog */
  openTooltipDialog(key: string, evt: MouseEvent) {
    const rect = (evt.currentTarget as Element).getBoundingClientRect();
    const target = this.getPositionByEvents(rect);
    const element = new ElementRef(target);
    const positionStrategy = this.placeByPositionStrategy(element);
    const thresholdScroll = {
      threshold: 50
    };
    const overlayConfig = new OverlayConfig({
      width: 250,
      panelClass: 'overlay-tooltip-pane',
      hasBackdrop: true,
      backdropClass: 'mat-backdrop-transparent',
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close(thresholdScroll),
      disposeOnNavigation: true

    });
    this.overlayRef = this.overlay.create(overlayConfig);
    const containerPortal = new ComponentPortal(OverlayTooltipComponent, null, this.positionService.createInjector({
      keyString: 'static',
      tooltipContent: 'Expired on ' + key,
      clientX: rect.left,
      clientY: rect.top,
      overlayRef: this.overlayRef

    }));
    this.overlayRef.attach(containerPortal);
    this.overlayRef.backdropClick().subscribe(() => {
      this.overlayRef.dispose();
    });
  }

  close() {
    this.overlayRef.dispose();
  }

  /** get the position by events of the target */
  getPositionByEvents(rect) {
    return {
      getBoundingClientRect: () => ({
        bottom: rect.top,
        height: rect.height,
        left: rect.left + (rect.right - rect.left) / 2,
        right: rect.right,
        top: rect.top,
        width: rect.width
      }),
    };
  }

  /** place the pop up by position strategy */
  placeByPositionStrategy(element: ElementRef) {
    return this.overlay
      .position()
      .flexibleConnectedTo(element)
      .withFlexibleDimensions(false)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'bottom',
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);
  }

  ngOnInit() {
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-COMPARE_COST_MODEL', 'COMPARE_COST_MODEL');
      });
    for (const costModel of this.costmodelList) {
      this.costModelTaxCal(costModel);
    }
  }

  costModelTaxCal(costModel) {
    const taxGrossRate = Number(costModel.taxGrossRate);
    for (const costmodel of costModel.costEstimates) {
      const minInsurance = (costmodel.coreServices.estimatedSubTotalMinimumCost * insurancePercent);
      const maxInsurance = (costmodel.coreServices.estimatedSubTotalMaximumCost * insurancePercent);
      /**calculate core services amount including insurance */

      (minInsurance <= minInsuranceAmt && minInsurance !== 0) ? costmodel.coreServices.minCostInsurance =
        costmodel.coreServices.estimatedSubTotalMinimumCost +
        minInsuranceAmt : costmodel.coreServices.minCostInsurance = costmodel.coreServices.estimatedSubTotalMinimumCost + minInsurance;

      (maxInsurance <= minInsuranceAmt && maxInsurance !== 0) ? costmodel.coreServices.maxCostInsurance =
        costmodel.coreServices.estimatedSubTotalMaximumCost +
        minInsuranceAmt : costmodel.coreServices.maxCostInsurance = costmodel.coreServices.estimatedSubTotalMaximumCost + maxInsurance;
      /**calculate tax amount without insurance */
      costmodel.minTaxAmount = (costmodel.coreServices.estimatedSubTotalMinimumCost * taxGrossRate) +
        (costmodel.flexServices.estimatedSubTotalMinimumCost * taxGrossRate);
      costmodel.maxTaxAmount = (costmodel.coreServices.estimatedSubTotalMaximumCost * taxGrossRate) +
        (costmodel.flexServices.estimatedSubTotalMaximumCost * taxGrossRate);


      costmodel.estimatedTotalMinimumCost = costmodel.coreServices.minCostInsurance +
        costmodel.flexServices.estimatedSubTotalMinimumCost +
        costmodel.minTaxAmount;

      costmodel.estimatedTotalMaximumCost = costmodel.coreServices.maxCostInsurance +
        costmodel.flexServices.estimatedSubTotalMaximumCost +
        costmodel.maxTaxAmount;
    }
  }
}
