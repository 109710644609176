<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>

<div class="dialogMainContainer candidate-formcontainer" flexLayout fxLayout.xs="column">
  <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
    fxLayoutGap="20px" fxLayoutGap.xs="0em">
    <h2>{{ title }}</h2>
    <a class="close-icon" id="close_icon" (click)="onNoClick()">
      <mat-icon>close</mat-icon>
    </a>
  </div>

  <mat-dialog-content class="middle-section">
    <form fxLayout="column" [ngClass]="{'costmodel-scroll-div': inviteAsClientContactFlag}"
      [formGroup]="addClientContactForm">
      <div *ngIf="!deleteDialog">
        <div class="columnGap"></div>
        <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
          fxLayoutGap.xs="0em">
          <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <mat-label>*Atlas Van Lines</mat-label>
              <mat-select [disabled]="true" formControlName="VendorName" id="level">
                <mat-option>
                  Atlas Van Lines
                </mat-option>
              </mat-select>
              <mat-error>
                {{ errors['ClientState'] }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <input matInput placeholder="Invoice Number" formControlName="InvoiceNumber" id="level" aria-label="Invoice Number">
              <mat-error>
                {{ errors['ClientContactLastName'] }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0">
          <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">

            <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayoutGap.xs=".3em" fxFlex="100%">

              <div fxFlex="90%" fxLayoutAlign="start" fxFlex.xs="90%">
                <mat-form-field>
                  <input matInput placeholder="Amount" formControlName="Amount" id="level" readonly  aria-label="Amount">
                  <mat-hint class="hint">Whole Numbers only</mat-hint>
                </mat-form-field>
              </div>

              <div fxFlex="10%" fxLayoutAlign="center" fxFlex.xs="10%">
                <span style="padding-top: 16px; text-align: center;">USD</span>
              </div>

            </div>

          </div>
          <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
            <!-- <mat-form-field appearance="standard" floatLabel="auto" hideRequiredMarker>
              <input matInput [matDatepicker]="startDate" auto-complete="off" placeholder="Enter Date" readonly
                  required [(ngModel)]='jobStartOn' #jobStartDate (click)="startDate.open()">
              <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
              <mat-datepicker #startDate></mat-datepicker>
          </mat-form-field> -->
            <mat-form-field>
              <input matInput [matDatepicker]="picker3" placeholder="Invoice Date" [value]="date.value" aria-label="Invoice Date"
                (dateChange)="addEvent('change', $event)">
              <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
              <mat-datepicker #picker3></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
          fxLayoutGap.xs="0em">
          <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <input type="text" placeholder="Actual Lb (optional)" matInput formControlName="ActualWieght" aria-label="Actual Lb"
                id="departure">
              <mat-error>
                {{ getErrorMessage('INVOICE_DATE') }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field fxFlex>
              <!-- commented out as part of MOV360-26: variable does not exist. -->

              <!-- <input matInput appInputPhoneFormat type="text" placeholder="Actual Mileage (optional)" aria-label="Actual Milage" -->
              <!-- formControlName="ActualMileage" [readonly]="readOnlyField"> -->

              <!-- Substituted lines below -->
              <input matInput appInputPhoneFormat type="text" placeholder="Actual Mileage (optional)" aria-label="Actual Milage"
                formControlName="ActualMileage">
              <mat-error
                *ngIf="addClientContactForm.controls['Amount'].hasError('pattern')||
              addClientContactForm.controls['Amount'].hasError('minlength') || addClientContactForm.controls['Amount'].hasError('alphabets') ">
                {{ getErrorMessage('PHONE_NUMBER') }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="deleteDialog">
          <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
            <p> Please click on delete to confirm the deletion of the following client(s):<br>
              {{ this.data.clientPreferredName  }} </p>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="statusBtn add-dialogbtn" *ngIf="canSaveSubmitInvoice">
      <button mat-button id="cancel_client" (click)="onNoClick()">CANCEL</button>
      <button mat-button class="mat-button outlined-button" id="save_draft" *ngIf="showDraftButton"
        [disabled]="checkSaveDraft()" (click)="saveDraft()">{{saveDraftButtonLabel}}</button>
      <button mat-button class="mat-button outlined-button" id="save_client" *ngIf="showInactiveCheckBox"
        [disabled]="!checkForClientContactFields()" (click)="inactivateClient()">Set to Inactive</button>
      <button mat-button class="mat-button contained-button" (click)="sendInvite()" id="addEditSave"
        [disabled]="!checkForClientContactFields()">
        Complete Audit</button>
      <button *ngIf="isInvitationSent && showResendButton"
        [disabled]="!(checkForClientContactFields() && isActualWeightValid)" mat-button
        class="mat-button outlined-button" (click)="sendInvite()" id="addEditSendInvite">Resend</button>
    </div>
    <div class="statusBtn add-dialogbtn" *ngIf="!canSaveSubmitInvoice">
      <button mat-button id="cancel_client" (click)="onNoClick()">CANCEL</button>
      <button mat-button class="mat-button outlined-button" id="save_draft"
        [disabled]="!canSaveSubmitInvoice">{{saveDraftButtonLabel}}</button>
      <button mat-button class="mat-button contained-button" id="addEditSave" [disabled]="!canSaveSubmitInvoice">
        Complete Audit</button>
    </div>
  </mat-dialog-actions>
</div>
