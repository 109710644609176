export interface SupplierRole {
  roleID?: string;
  roleName?: roleType;
  displayName?: string;
  name?: string;
  fromDate?: Date;
  roleDescrpition?: string;
}

export enum roleType {
  alpha = 'alpha',
  supplier = 'supplier-contact',
  initiator = 'supplier-contact-initiator',
  admin = 'supplier-contact-administrator',
  noRole = '',
}
