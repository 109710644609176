/** MOV360-880 - to be removed at future date */
import { ModalData } from '../../../core/services/popup-position.service';
import { LinkedListData } from './../../models/linked-list-data.model';
import { Component, OnInit, Inject, ViewEncapsulation, HostBinding, ApplicationRef, ElementRef, Renderer2, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-authorized-move-phase',
  templateUrl: './authorized-move-phase.component.html',
  styleUrls: ['./authorized-move-phase.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthorizedMovePhaseComponent implements OnInit, OnDestroy {
  /** empMovePhase array variable of type LinkedListData[] */
  public empMovePhases: LinkedListData[] = [];

  @HostBinding('class') class = 'authorize-move-component';

  constructor(@Inject(ModalData) public data: any,
  private readonly appRef: ApplicationRef,
  private readonly renderer: Renderer2) { }

  /** function to be called on init of component */
  ngOnInit() {
    this.renderer.setAttribute(this.appRef.components[0].injector.get(ElementRef).nativeElement,
    'aria-hidden', 'true');
    Object.assign(this.empMovePhases, this.data.movePhase.map(movePhase => {
      return {
        value: movePhase.order,
        columns: [
          {
            label: movePhase.phase
          }
        ],
        status: movePhase.status
      } as LinkedListData;
    }));
  }

  /** To close the dialog */
  close() {
    this.renderer.removeAttribute(this.appRef.components[0].injector.get(ElementRef).nativeElement,
    'aria-hidden');
    this.data.overlayRef.dispose();
  }

  /** function to be called on destory of component */
  ngOnDestroy(): void {
    this.renderer.removeAttribute(this.appRef.components[0].injector.get(ElementRef).nativeElement,
    'aria-hidden');
  }
}
