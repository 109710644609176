/** MOV360-880 - to be removed at future date */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoggerService } from '../../../../core/services/logger.service';
import { UserContextService } from '../../../../core/services/user-context.service';

/**class for ResendInviteComponent */
@Component({
  selector: 'app-resend-invite',
  templateUrl: './resend-invite.component.html',
  styleUrls: ['./resend-invite.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResendInviteComponent implements OnInit {
  /**constructor to inject dependencies */
  constructor(
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: UserContextService) { }
  /**To initialise the component */
  ngOnInit() {
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-RESEND_INVITE', 'RESEND_INVITE');
      });

  }
}
