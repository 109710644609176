<div  fxFlex="100%" fxLayout="column" fxLayout.xs="column" >
    
    <div class="emptyInfo" fxLayout="column" fxLayout.xs="column" fxFlex.xs="100%" *ngIf="isEmptyDailyOperationInfo" fxLayoutAlign="center center" fxLayoutGap.xs="0px">
        <span class="spanText">You must add Daily Operation Contact info to activate the contract</span>
        <span class=" addInfolink"><a (click)="openDailyOperationsModal()">Add Daily Operation Contact</a></span>
    </div> 
    <div  fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%" fxLayout.xs="column" fxLayoutAlign="space-between"
    fxLayoutGap.xs="4px" fxLayoutGap="32px" *ngIf="!isEmptyDailyOperationInfo">
        <div fxFlex="33%" fxLayoutAlign="start" class="contract-section-div">
            <mat-form-field fxFlex class="daily-operation-mat-form"> 
                <mat-label class='matLabel'>Daily Operation Contact Name</mat-label>
                <input matInput type="text"  readonly  [value]="dailyOperationsData.fullName" class="dailyInput"/> 
                <button *ngIf="isManageSupplier" mat-button  matSuffix mat-icon-button aria-label="Edit" (click)="openDailyOperationsModal()" class="pointer">
                    <mat-icon class="pointer">edit</mat-icon>
                 </button>
            </mat-form-field>
        </div>
        <div fxFlex="33%" class="contract-section-div">
            <mat-form-field fxFlex class="daily-operation-mat-form">
                <mat-label class='matLabel'>Email Address</mat-label>
                <input matInput type="text"  readonly  [value]="dailyOperationsData.emailAddress" class="dailyInput"/>
                <button *ngIf="isManageSupplier" mat-button  matSuffix mat-icon-button aria-label="Edit" (click)="openDailyOperationsModal()" class="pointer">
                    <mat-icon class="pointer">edit</mat-icon>
                 </button>
            </mat-form-field>
        </div> 
        <div fxFlex="33%" class="contract-section-div">
            <mat-form-field fxFlex class="daily-operation-mat-form">
                <mat-label class='matLabel'>Mobile Phone Number</mat-label>
                <input matInput readonly type="text" [value]="dailyOperationsData.formattedPhone" class="dailyInput"/>
                <button *ngIf="isManageSupplier" mat-button matSuffix mat-icon-button aria-label="Edit" (click)="openDailyOperationsModal()" class="pointer">
                    <mat-icon class="pointer">edit</mat-icon>
                 </button>
            </mat-form-field>
        </div>
    </div>

    
</div>