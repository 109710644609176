<ngx-spinner type="ball-spin-clockwise-fade" size="medium">
</ngx-spinner>

<div class="dialogMainContainer candidate-formcontainer" flexLayout fxLayout.xs="column">
  <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
    fxLayoutGap="20px" fxLayoutGap.xs="0em">
    <h2>{{ title }}</h2>
    <a class="close-icon" id="close_icon" (click)="onNoClick()">
      <mat-icon>close</mat-icon>
    </a>
  </div>

  <mat-dialog-content class="middle-section">
    <form fxLayout="column" [ngClass]="{'costmodel-scroll-div': inviteAsClientContactFlag}" [formGroup]="addClientForm">
      <div *ngIf="!deleteDialog">
        <div class="input-row title" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
          fxLayoutGap.xs="0em">
          <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
            Client Company Details
          </div>
        </div>
        <div class="columnGap"></div>
        <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
          fxLayoutGap.xs="0em">
          <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <input matInput [disabled]='lockFields' [readonly]='lockFields' placeholder="Client Legal Name" formControlName="ClientLegalName"
                id="level" (blur)="toggleDuplicateFlag()" (input)="onSearchChange($event.target.value)">
              <mat-error>
                {{ errors['ClientLegalName'] }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <input matInput [disabled]='lockFields' [readonly]='lockFields' placeholder="Client Display Name"
                formControlName="ClientPreferredName">
              <mat-error>
                {{ errors['ClientPreferredName'] }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0">
          <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <input type="text" [disabled]='lockFields' [readonly]='lockFields' placeholder="Tax ID" matInput formControlName="TaxID">
              <mat-error>
                {{ getErrorMessage('TAX_ID') }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <input [readonly]=true [disabled]='lockFields' type="text" placeholder="Country" matInput
                formControlName="ClientCountry" id="departure">
              <mat-error>
                {{ errors['ClientCountry'] }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
          fxLayoutGap.xs="0em">
          <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <input matInput [disabled]='lockFields' [readonly]='lockFields' placeholder="Street Address" formControlName="ClientAddress">
              <mat-error>
                {{ errors['ClientStreetAddress'] }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
          fxLayoutGap.xs="0em">
          <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <input matInput [disabled]='lockFields' [readonly]='lockFields' placeholder="City" formControlName="ClientCity">
              <mat-error>
                {{ errors['ClientCity'] }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="20%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <mat-label>State</mat-label>
              <mat-select [disabled]='lockFields' formControlName="ClientState" id="level">
                <mat-option *ngFor="let state of stateList" [value]="state.shortName">
                  {{state.shortName}}
                </mat-option>
              </mat-select>
              <mat-error>
                {{ errors['ClientState'] }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="30%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <input matInput [disabled]='lockFields' [readonly]='lockFields' placeholder="Zip Code" formControlName="ClientPostalCode">
              <mat-error>
                {{ errors['ClientZipCode'] }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="columnGap"></div>
        <div class="input-row title" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
          fxLayoutGap.xs="0em">
          <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
            Billing Contact Details
          </div>
        </div>
        <div class="columnGap"></div>
        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0">
          <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <input type="text" [disabled]='lockFields' [readonly]='lockFields' placeholder="First Name" matInput
                formControlName="ClientContactFirstName" id="departure">
              <mat-error>
                {{ getErrorMessage('FIRST_NAME') }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <input type="text" [disabled]='lockFields' [readonly]='lockFields' placeholder="Last Name" matInput
                formControlName="ClientContactLastName" id="destination">
              <mat-error>
                {{ getErrorMessage('LAST_NAME') }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0">
          <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <input type="text" [disabled]='lockFields' [readonly]='lockFields' placeholder="Billing Contact Email" matInput
                formControlName="ClientContactEmailAddress" id="departure">
              <mat-error>
                {{ getErrorMessage('EMAIL_ADDRESS') }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="inviteAsClientContactFlag" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em"
          fxLayout.xs="column" fxLayoutGap.xs="0">
          <div *ngIf="inviteAsClientContactFlag" fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <mat-label>Role</mat-label>
              <mat-select [disabled]='lockFields' formControlName="ClientContactRole" id="level">
                <mat-option *ngFor="let role of clientRoles" [value]="role.displayName">
                  {{role.displayName}}
                </mat-option>
              </mat-select>
              <mat-error>
                {{ errors['ClientRole'] }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field fxFlex>
              <input matInput [disabled]='lockFields' [readonly]='lockFields' appInputPhoneFormat type="text" placeholder="Mobile Phone Number"
                formControlName="PhoneNumber" (ngModelChange)="addValidators()" [readonly]="readOnlyField"
                maxlength="10">
              <mat-hint class="hint">Mobile # required to invite Client Contact</mat-hint>
              <mat-error
                *ngIf="addClientForm.controls['PhoneNumber'].hasError('pattern')||
                  addClientForm.controls['PhoneNumber'].hasError('minlength') || addClientForm.controls['PhoneNumber'].hasError('alphabets') ">
                {{ errors['BillingContactMobileNumber'] }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div *ngIf="deleteDialog">
        <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
          <p> Please click on delete to confirm the deletion of the following client(s):<br>
            {{ this.data.clientPreferredName  }} </p>
        </div>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="statusBtn add-dialogbtn">
      <button mat-button id="cancel_client" (click)="onNoClick()">CANCEL</button>
      <button mat-button class="mat-button outlined-button" id="save_client" *ngIf="showInactiveCheckBox"
        [disabled]="checkForClientContactFields() && isEmailValid && isPhoneNumberValid" (click)="inactivateClient()">Set to Inactive</button>
      <button mat-button class="mat-button contained-button" (click)="saveClient()" id="addEditSave"
        [disabled]="checkForClientContactFields() && isEmailValid && isPhoneNumberValid">{{ submitButtonLabel }}</button>
    </div>
  </mat-dialog-actions>
</div>
