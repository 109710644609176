/** MOV360-880 - to be removed at future date */
import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Timeline } from '../../../../core/models/timeline-service.model';
// import { ValueConverter } from '@angular/compiler/src/render3/view/compiler';

/** TransfereeTimelineComponent */
@Component({
  selector: 'app-transferee-timeline',
  templateUrl: './transferee-timeline.component.html',
  styleUrls: ['./transferee-timeline.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TransfereeTimelineComponent implements OnInit {
  /**flag for toggle bar */
  ifSelectedSwitch = false;
  /**stores timeline service details*/
  @Input() timelineService: Timeline[];
  /**to store timelineService details */
  serviceDetails: any;
  /**
   * injecting dependencies
   */
  constructor() { }
  /**Initializing the component */
  ngOnInit() {
    this.timelineService = this.timelineService.filter(value => (value.serviceType === 'estimated' && !value.isActualAvailable)
    || (value.serviceType === 'actual' || value.serviceType === 'others'));
    this.selectData();
  }
   /**
   * include missing milestone when user clicks on the toggle
   * @param event event passed on click of checkbox
   */
  isToggleChoosen(event) {
    this.ifSelectedSwitch = event.checked;
    this.selectData();
  }
  /**To initialize the serviceDetails based on flag switched */
  selectData() {
    if (!this.ifSelectedSwitch) {
      this.serviceDetails = this.timelineService.slice();
      this.serviceDetails.sort((firstData, secondData) => (firstData.sortOrder < secondData.sortOrder) ? 1 : -1);
    } else {
      this.serviceDetails = this.timelineService.slice();
    }
  }
}
