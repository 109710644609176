import { LinkedListData } from './../../models/linked-list-data.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-linked-list',
  templateUrl: './linked-list.component.html',
  styleUrls: ['./linked-list.component.scss']
})
export class LinkedListComponent implements OnInit {

  /** the data to be displayed */
  @Input() dataSet: LinkedListData[];

  /** list style classes */
  @Input() listStyleClasses: string;

  /** column style classes */
  @Input() columnStyleClasses: string[];
  /**property to store move status */
  active: number;

  /** empty constructor */
  constructor() { }

  /** empty init */
  ngOnInit() {
    for (const element of this.dataSet) {
      if (element.status === false) {
        this.active = parseInt(element.value, 10) - 1;
        break;
      }
      if (this.dataSet[this.dataSet.length - 1].status === true) {
        this.active = parseInt(this.dataSet[this.dataSet.length - 1].value, 10);
      }
    }
  }

}
