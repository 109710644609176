<div class="dialogMainContainer-cost" flexLayout fxLayout.xs="column">
    <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
        fxLayoutGap="20px" fxLayoutGap.xs="0em">
        <div class="candidate-dialogtitle">{{ title }}</div>
        <a href="#" class="close-icon" (click)="onNoClick($event)" title="close">
            <mat-icon>close</mat-icon>
        </a>
    </div>
    <mat-dialog-content class="cost-popup-content middle-section">
        <p> {{ displayText1 }}</p>
        <div *ngFor="let costmodel of data.selectedCostModels">
            <div class="candidate-name-highlightbg">{{costmodel.costModelName}}</div>
        </div>
        <p> {{ displayText2 }}</p>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="statusBtn delete-dialog-btn">
            <button mat-button color="primary" class="mat-button text-button" (click)="onNoClick($event)">CANCEL</button>
            <button mat-button class="mat-button contained-button" (click)="removeCostModel()" id="withdrawBtn">Delete
                {{data.noOfSelected}} <span *ngIf="data.noOfSelected===1" class="cost-model-btn-txt">Cost
                    Model</span><span *ngIf="data.noOfSelected>1" class="cost-model-btn-txt">Cost
                    Models</span></button>
        </div>
    </mat-dialog-actions>
</div>