<button *ngIf="!authorizedTransferee" [disabled]="invalidBudget" mat-button [matMenuTriggerFor]="sharelist"
    class="mat-button outlined-button" id="share">
    <mat-icon class="left">share</mat-icon>
    Share
    <mat-icon class="right" matSuffix>arrow_drop_down</mat-icon>
</button>
<mat-menu class="budget-share-menu" #sharelist="matMenu">
    <p class="clear-cache-warning">
        Downloading files with sensitive information is a security risk, please clear your cache afterwards
    </p>
    <button mat-menu-item (click)="exportToPDF(false)" id="authPdf">
        <mat-icon class="left">save_alt</mat-icon>Authorization PDF
    </button>
    <button mat-menu-item (click)="exportToPDF(true)" id="candidatePdf">
        <mat-icon class="left">save_alt</mat-icon>Candidate Review PDF
    </button>
</mat-menu>