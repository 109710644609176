<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<div Fxflex="100%" class="active-header-status-container">
    <div Fxflex="100%" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column" class="active-text-container">
            <h1 #pageheading class="active-txt">{{label}}:</h1>
            <div tabindex="0" class="active-txtcount" aria-live="polite" aria-atomic="true">
                <span class="active-total-candidate" [attr.aria-label]="'Total Suppliers' + totalSuppliers">{{totalSuppliers}}</span>
                <span class="active-total-candidate-txt" [attr.aria-label]="label">{{label}}</span>
            </div>
        </div>
        <div fxLayoutAlign="end" fxLayoutAlign.xs="end" fxLayout="column" class="active-addicon">
            <a href="#" *ngIf="isManageSupplierCapability" role="Link" aria-label="Add Supplier" (click)="openAddModal($event)">
                <span class="cdk-visually-hidden">{{label}}</span>
                <mat-icon matPrefix id="add_icon">add_circle</mat-icon>
            </a>
        </div>
    </div>
</div>

<section class="content_table">

    <div>
        <section class="mat-elevation-z8">
            <div *ngIf="ELEMENT_DATA.length>0 && dataSource.filteredData.length>0" style="overflow:auto;">
                <table mat-table [dataSource]="dataSource" aria-describedby="table-candidates" matSort
                    [matSortActive]="sortBy" matSortDirection="asc" (matSortChange)="sortData($event)">

                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event && masterToggle()"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event && selection.toggle(row)" [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <!-- SupplierName Column -->
                    <ng-container matColumnDef="preferredName" sticky>
                        <th id="" mat-header-cell *matHeaderCellDef mat-sort-header>Supplier Name</th>
                        <td mat-cell *matCellDef="let element" class="cLinks"
                            [ngClass]="{highlighted: selection.isSelected(element)}"
                            (click)="navigateToSupplierSummary(element)">
                        <!-- <td mat-cell *matCellDef="let element" class="cLinks" -->
                            <!-- [ngClass]="{highlighted: selection.isSelected(row)}" -->
                            <!-- (click)="navigateToSupplierSummary(element)"> -->
                            <a href="#" class="client-table-links" (click)="$event.preventDefault()">
                                <span class="text-as-link"
                                    [innerHTML]="element.preferredName ? element.preferredName : '-'">
                                </span>
                            </a>
                        </td>
                    </ng-container>
                    <!-- Location Column -->
                    <ng-container matColumnDef="address">
                        <th id="" mat-header-cell *matHeaderCellDef mat-sort-header>Location</th>
                        <td mat-cell *matCellDef="let element" class="addressText">
                            <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="1em" fxLayoutGap.xs="0.1em">
                                <div fxFlex="100%" fxLayoutAlign="start" fxFlex.xs="100%">
                                    <span [innerHTML]="element.address ? element.address : '-'"></span>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <!-- Daily Operations Column -->
                    <ng-container matColumnDef="contractStatus">
                        <th id='' mat-header-cell *matHeaderCellDef mat-sort-header>Contract Status</th>
                        <td mat-cell *matCellDef="let element">
                            <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="1em" fxLayoutGap.xs="0.1em"
                                class="conImg">

                                <div fxFlex="33%" fxLayoutAlign="start" fxFlex.xs="100%" class="imageAlign"
                                    *ngIf="!isReview">
                                    <img class="reviewImg"
                                        src="../../../../assets/images/candidate-assessment/noreview.svg"
                                        aria-hidden="true" />
                                    <span class="alignText noReviewText">Review: 0</span>
                                </div>
                                <div fxFlex="33%" fxLayoutAlign="start" fxFlex.xs="100%" class="imageAlign"
                                    *ngIf="isReview">
                                    <img class="reviewImg"
                                        src="../../../../assets/images/candidate-assessment/review.svg"
                                        aria-hidden="true" />
                                    <span class="alignText reviewText">Review: 1</span>
                                </div>

                                <div fxFlex="33%" fxLayoutAlign="start" fxFlex.xs="100%" class="imageAlign"
                                    *ngIf="!isActive">
                                    <img class="activeImg"
                                        src="../../../../assets/images/candidate-assessment/noactive.svg"
                                        aria-hidden="true" />
                                    <span class="alignText noActiveText">Active: 0</span>
                                </div>
                                <div fxFlex="33%" fxLayoutAlign="start" fxFlex.xs="100%" class="imageAlign"
                                    *ngIf="isActive">
                                    <img class="activeImg"
                                        src="../../../../assets/images/candidate-assessment/active.svg"
                                        aria-hidden="true" />
                                    <span class="alignText activeText">Active: 1</span>
                                </div>

                                <div fxFlex="33%" fxLayoutAlign="start" fxFlex.xs="100%" class="imageAlign"
                                    *ngIf="!isInactive">
                                    <img class="inactiveImg"
                                        src="../../../../assets/images/candidate-assessment/noinactive.svg"
                                        aria-hidden="true" />
                                    <span class="alignText noInactiveText">Inactive: 0</span>
                                </div>
                                <div fxFlex="33%" fxLayoutAlign="start" fxFlex.xs="100%" class="imageAlign"
                                    *ngIf="isInactive">
                                    <img class="inactiveImg"
                                        src="../../../../assets/images/candidate-assessment/inactive.svg"
                                        aria-hidden="true" />
                                    <span class="alignText inactiveText">Inactive: 1</span>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Status Column -->
                    <ng-container matColumnDef="lastUpdateAt">
                        <th id="" mat-header-cell *matHeaderCellDef mat-sort-header id="chk1-label">Supplier Active</th>
                        <td mat-cell *matCellDef="let element">
                            <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="1em" fxLayoutGap.xs="0.1em"
                                class="switchText">
                                <div fxFlex="24%" fxLayoutAlign="start" fxFlex.xs="24%" class="switchSection">
                                    <span [ngClass]="{offText: !element.isSwitchActive}">Off</span>
                                </div>

                                <div fxFlex="37%" fxLayoutAlign="start" fxFlex.xs="37%" class="switchSection">
                                    <label class="switch">
                                        <input type="checkbox" aria-labelledby="chk1-label" role="checkbox" aria-checked="false" [(ngModel)]="element.isSwitchActive">
                                        <span class="slider round"></span>
                                    </label>
                                </div>

                                <div fxFlex="39%" fxLayoutAlign="start" fxFlex.xs="39%" class="switchSection">
                                    <span [ngClass]="{onText: element.isSwitchActive}">On</span>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
                    <!-- commented out as part of MOV360-290 : function does not exist. Substituted with lines below. -->

                    <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;" -->
                    <!-- (click)="row.status === 'Ready for Review' || row.status === 'Payment Pending' ? navigateToBudgetSummary(row.candidateId):return;" -->
                    <!-- [ngClass]="{highlighted: selection.isSelected(row)}"></tr> -->

                    <!-- Substituted lines below -->
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                        [ngClass]="{highlighted: selection.isSelected(row)}"></tr>
                </table>
            </div>
            <div [hidden]="!(ELEMENT_DATA.length>0 && dataSource.filteredData.length>0)">
                <mat-paginator [length]="pageInfo.totalCount" [pageSize]="pageInfo.pageSize"
                    [pageSizeOptions]="[5, 10, 20]" (page)="onPagination($event)"></mat-paginator>
            </div>
        </section>
    </div>
    <div [hidden]="ELEMENT_DATA.length>0 && dataSource.filteredData.length>0" class="invalidData">
        No records found
    </div>
</section>
<div *ngIf="selection.selected.length > 0" class="candidate-highlight-whitebg">
    <button mat-button class="mat-button contained-button" id="withdrawBtn">Set
        <span *ngIf="selection.selected.length>1" class=" cost-model-btn-txt">{{selection.selected.length}}&nbsp;</span>
        <span class="cost-model-btn-txt">to Inactive</span>
    </button>
</div>
