/** MOV360-880 - to be removed at future date */
import { Injectable } from '@angular/core';
import { SupplierRole } from '../models/supplier-role.model';
import { roleType } from '../models/supplier-role.model';

@Injectable({
    providedIn: 'root'
})
export class SupplierRoleService {

    levelList: SupplierRole[] = [
        { roleID: 'R1', roleName: roleType.supplier, roleDescrpition: 'supplier-contact-initiator', displayName: 'Supplier Contact' }
    ];

    emptyLevel: SupplierRole[] = [
        { roleID: '', roleName: roleType.noRole, roleDescrpition: '' }
    ];

    constructor() { }

    getRoles(): SupplierRole[] {
        return this.levelList;
    }

}
