/** MOV360-880 - to be removed at future date */
import { Component, OnInit, Input } from '@angular/core';
import { AddEditTerritoryComponent } from '../../add-edit-territory/add-edit-territory.component';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SupplierContractService } from 'src/app/core/services/supplier-contract.service';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-territory-coverage-info',
  templateUrl: './territory-coverage-info.component.html',
  styleUrls: ['./territory-coverage-info.component.scss']
})
export class TerritoryCoverageInfoComponent implements OnInit {

  discountColumns: string[] = [
    'discountType',
    'discountPercentage',
    'startDate',
    'endDate'
  ];
  isAllStateTerritoryAvailable = false;
  @Input() terriToryCoverages: Array<any>;
  @Input() contractIndex: number;
  commissionColumns: string[] = [
    'commisionType',
    'commissionPercentage',
    'startDate',
    'endDate'
  ];

  @Input() isManageSupplier: boolean;
  mediasbscription: Subscription;
  isMobileView = false;
  terriToryCoverage: Array<any> = [];
  isEmptyTerritoryData = true;
  constructor(private router: Router,
    public dialog: MatDialog,
    private supplierContractService: SupplierContractService,
    private mediaobserver: MediaObserver) {
    this.mediasbscription = mediaobserver.asObservable().subscribe((val: MediaChange[]) => {
      if (val[0].mqAlias === 'xs') {
        this.isMobileView = true;
      } else {
        this.isMobileView = false;
      }
    });
  }

  ngOnInit() {
    if (this.terriToryCoverages && this.terriToryCoverage.length > 0) {
      this.terriToryCoverage = this.terriToryCoverages;
      this.isEmptyTerritoryData = false;
    }
  }


  AddEditTerritory(index?, isEdit?) {
    let editTerritory;
    if (this.terriToryCoverage.length >= index && isEdit) {
      editTerritory = this.terriToryCoverage[index];
    }
    const dialogRef = this.dialog.open(AddEditTerritoryComponent, {
      disableClose: true,
      panelClass: ['dialogMainContainer', 'authorize-dialog-container'],
      data: { territoryCoverage: editTerritory }
    });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.isEmptyTerritoryData = false;
          if (isEdit) {
            this.terriToryCoverage[index] = result;
          } else {
            this.terriToryCoverage.push(result);
          }
          this.checkForAllStateTerritoryCoverage();
          this.supplierContractService.updateContractIndex(this.contractIndex);
          this.supplierContractService.updateTerriToryData(this.terriToryCoverage);
        }
      });

  }

  checkForAllStateTerritoryCoverage() {
    this.isAllStateTerritoryAvailable = false;
    this.terriToryCoverage.forEach(element => {
      if (element.territory === 'All States') {
        this.isAllStateTerritoryAvailable = true;
      }

    });
  }

  removeTerritoryCoverage(i) {

    this.terriToryCoverage.splice(i, 1);
    if (this.terriToryCoverage.length === 0) {
      this.isEmptyTerritoryData = true;
    }
    this.supplierContractService.updateContractIndex(this.contractIndex);
    this.supplierContractService.updateTerriToryData(this.terriToryCoverage);
    this.checkForAllStateTerritoryCoverage();
  }

}
