import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthorizedMoveComponent } from './authorized-move/authorized-move.component';
import { CostModelComponent } from './cost-model/cost-model.component';
import { CandidateProfileComponent } from './candidate-profile/candidate-profile.component';
import { ProjectAlphaComponent } from './project-alpha.component';
import { ServicesComponent } from './services/services.component';
import { ExploreDestinationsComponent } from './explore-destinations/explore-destinations.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BudgetDetailsComponent } from './budget-details/budget-details.component';
import { CompareCostModelsComponent } from './cost-model/compare-cost-models/compare-cost-models.component';
import { ClientComponent } from './client/client.component';
import { AuthorizeTransfereeDetailsComponent } from './authorize-transferee-details/authorize-transferee-details.component';
import { ClientContactComponent } from './client-contact/client-contact.component';
import { FinanceAuditComponent } from './finance-audit/finance-audit.component';
import { CartusEmployeeComponent } from './cartus-employee/cartus-employee/cartus-employee.component';
import { AuthGuard } from './authGuard';
import { roleType } from '../../core/models/client-role';
import { AccountManagerComponent } from './account-manager/account-manager.component';
import { FinanceAuditDetailsComponent } from './finance-audit-details/finance-audit-details.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { SupplierContactsListComponent } from './supplier-contacts-list/supplier-contacts-list.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { SupplierDetailsComponent } from './suppliers/supplier-details/supplier-details.component';
import { ConsultantsComponent } from './consultants/consultants.component';
import { CallSummarizerComponent } from './call-summarizer/call-summarizer.component';
const manageCandidatesCapability = 'Manage Candidates/Transferees';
const manageCandidatesCapabilityAll = 'Manage Candidates/Transferees All';
export const routes: Routes = [
  {
    path: 'project-alpha',
    component: ProjectAlphaComponent,
    children: [
      {
        path: 'account-manager',
        component: AccountManagerComponent,
        data: { capabilities: ['Employee to Client Association'] },
        canActivate: [AuthGuard]
      },
      {
        path: 'consultants',
        component: ConsultantsComponent,
        data: { capabilities: ['Employee to Client Association'] },
        canActivate: [AuthGuard]
      },
      {
        path: 'client-contacts',
        component: ClientContactComponent,
        data: { capabilities: ['Manage Client Contacts'] },
        canActivate: [AuthGuard]
      },
      {
        path: 'cartus-employee',
        component: CartusEmployeeComponent,
        data: { capabilities: ['Assign Employee Roles'] },
        canActivate: [AuthGuard]
      },
      {
        path: 'callsummarizer',
        component: CallSummarizerComponent,
        data: { capabilities: ['MOB - Call Summarizer'] },
        canActivate: [AuthGuard]
      },
      {
        path: 'account-settings',
        component: AccountSettingsComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectAlphaRoutingModule { }
