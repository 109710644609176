/** MOV360-880 - to be removed at future date */
import { Injectable } from '@angular/core';
import { BaseClientService } from './base-client.service';
import { Client, ClientStatus } from '../models/client';
import { roleType } from '../models/client-role';
import { ClientContactService } from '../services/client-contact.service';
import { RemoteLoggingService } from './remote-logging.service';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { APIResponse } from '../models/response.model';
import { LoggerService } from './logger.service';
import { stringToKeyValue } from '@angular/flex-layout/extended/style/style-transforms';
// import { trimTrailingNulls } from '@angular/compiler/src/render3/view/util';

@Injectable({
  providedIn: 'root'
})
export class ClientServiceService {

  constructor(private readonly baseClientService: BaseClientService,
    private readonly logSvc: RemoteLoggingService,
    private readonly clientContactService: ClientContactService,
    private readonly customLogger: LoggerService) { }
  clientToBeAdded: Client;

  client$ = new BehaviorSubject<Client[]>(null);
  clientList$: Observable<any> = this.client$.asObservable();
  message1 = 'This user can only view candidates created by themselves.';
  message2 = 'Ready for Review';

  clientList: Client[] = [{
    'clientID': '10000',
    'partyID': '5d4ebddb9cc67bfc1921434e',
    'clientPreferredName': 'Element Fleet Corporation',
    'clientLegalName': 'Element Fleet Corporation',
    'clientAddress': {
      'fullAddress': '2800 West Higgins Rd, Hoffman Estates, IL, 60169',
      'streetAddress': '2800 West Higgins Rd',
      'city': 'Hoffman Estates',
      'state': 'IL',
      'zipcode': '60169',
      'country': 'US',
    },
    'clientRole': [{
      'roleID': '345435',
      'roleName': roleType.alpha,
      'roleDescrpition': this.message1
    }, {
      'roleID': '67886',
      'roleName': roleType.client,
      'roleDescrpition': this.message1
    }],
    'taxID': '749907815',
    'status': 'Active',
    'statusDate': '2019-10-10',
    'createdDate': '2019-10-01',
    'createdBy': '5d8b16401c9d440000f9df787',
    'isDeleted': false
  },
  {
    'clientID': '10001',
    'partyID': '5d4ebddb9cc67bad6d21434d',
    'clientPreferredName': 'Machaon Diagnostics, Inc.',
    'clientLegalName': 'Machaon Diagnostics, Inc.',
    'clientAddress': {
      'fullAddress': '3023 Summit Street',
      'streetAddress': '3023 Summit Street, Oakland, CA, 94609',
      'city': 'Oakland',
      'state': 'CA',
      'zipcode': '94043',
      'country': 'US',
    },
    'clientRole': [{
      'roleID': '345435',
      'roleName': roleType.alpha,
      'roleDescrpition': this.message1
    }, {
      'roleID': '67886',
      'roleName': roleType.client,
      'roleDescrpition': this.message1
    }],
    'taxID': '530787815',
    'status': this.message2,
    'statusDate': '2019-10-21',
    'createdDate': '2019-10-01',
    'createdBy': '5d8b16401c9d46789df348',
    'isDeleted': false
  }
  ];

  emptyClient = [{
    'clientID': '',
    'clientPreferredName': '',
    'clientLegalName': '',
    'taxID': '',
    'emailAddress': '',
    'statusDate': '',
    'role': '',
    'createdBy': '',
    'isDeleted': false
  }];

  // selectedValue$ = new Subject<string>();
  // totalClients = new Subject<any>();
  // totalClients$: Observable<any> = this.totalClients.asObservable();

  /* Return the client json list and loop to display in the table */
  getClients(): Observable<Array<Client>> {
    return this.baseClientService.get<Client>('/client/getAllClients?expandBillingContact=true').pipe(
      map(r => {
        // console.log('Got Data from Server : ' + JSON.stringify(r.body, null, 4));
        return r.body;
      }),
      catchError(err => {
        this.logSvc.logger('', 'Failed to clients', err);
        const emptyArray: Client[] = [];
        return of(emptyArray);
      })
    );
  }

  getClientsWithPagination(pageSize: number, skipRecords: number): Observable<any> {
    // return of(candidateListMock);
    return this.baseClientService
      .get<any>(`/client/getAllClients?expandBillingContact=true&limit=${pageSize}&skip=${skipRecords}`)
      .pipe(
        map(r => r.body),
        catchError(err => {
          // this.logSvc.logger('', 'Failed to get candidate profiles', err);
          this.customLogger.error('Failed to get candidate profiles', err);
          const emptyArray: Client = null;
          return of(emptyArray);
        })
      );
  }

  updateExistingClientId(oldClientId, newClientId) {
    // this.clientContactService.updateClientIdForBillingContacts(oldClientId,newClientId)
    this.clientList.forEach(client => {
      if (client.clientID === oldClientId) {
        client.partyID = newClientId;
      }
    });
    const existingClient = this.clientList.find(client => client.partyID === newClientId);
  }

  getClientsFromLocal(): Client[] {
    return this.clientList;
  }

  getActiveClients(): Observable<Array<Client>> {
    return this.baseClientService.get<Client>('/client/getAllActiveClients').pipe(
      map(r => {
        // console.log('Get Clients : ' + JSON.stringify(r.body, null, 4));
        return r.body;
      }),
      catchError(err => {
        this.logSvc.logger('', 'Failed to clients', err);
        const emptyArray: Client[] = [];
        return of(emptyArray);
      })
    );
  }


  getClient(clientID: string): Client {
    let client: Client;
    if (clientID !== undefined) {
      client = this.clientList.find(item => item.clientID === clientID);
    }
    // console.log('Client found : ' + JSON.stringify(client, null, 4));
    return client;
  }

  getClientDetails(clientName) {
    for (let i = 0; i < this.clientList.length; i++) {
      // look for the entry with a matching `code` value
      // console.log('Client List Name' + this.clientList[i].clientPreferredName);
      // console.log('Search Name' + clientName);
      if (clientName.trim() !== null) {
        if (this.clientList[i].clientPreferredName.localeCompare(clientName)) {
          return this.clientList[i];
        }
      } else {
        return this.emptyClient[0];
      }
    }
    return this.emptyClient[0];
  }

  getClientsWithBillingContacts(): Observable<Array<any>> {
    const activeClients = this.clientList.filter(item => item.isDeleted === false);
    const updatedResponse = activeClients.map(client => ({
      ...client,
      billingContact: this.clientContactService.getBillingContactFor(client.clientID)
    }));
    return of(updatedResponse);
  }

  createClient(request: Client): Observable<APIResponse> {
    return this.baseClientService.post<APIResponse>('/client', request).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: APIResponse = null;
        // this.logSvc.logger('', 'Failed to add candidate details', err);
        this.customLogger.error('Failed to create candidate details', err);
        return of(err);
      })
    );
  }

  activateClient(request: Client): Observable<APIResponse> {
    // console.log('Put request to be sent:' + JSON.stringify(request, null, 4));
    return this.baseClientService.put<APIResponse>(`/client/activateClient/${request.partyID}`, request).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: APIResponse = null;
        // this.logSvc.logger('', 'Failed to update client details', err);
        this.customLogger.error('Failed to activate candidate details', err);
        return of(err);
      })
    );
  }

  inActivateClient(request: Client[]): Observable<APIResponse> {
    // console.log('Inactivated Clients');
    return this.baseClientService.put<APIResponse>(`/client/inActivateClient/${request[0].partyID}`, request).pipe(
      map(r => {
        // console.log('Inactivated response : ' + JSON.stringify(r.body, null, 4));
        return r.body;
      }),
      catchError((err, source) => {
        const empty: APIResponse = null;
        // this.logSvc.logger('', 'Failed to update client details', err);
        this.customLogger.error('Failed to inactivate candidate details', err);
        return of(err);
      })
    );
  }

  updateClient(request: Client): Observable<APIResponse> {
    return this.baseClientService.put<APIResponse>(`/client/updateClient/${request.partyID}`, request).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: APIResponse = null;
        this.logSvc.logger('', 'Failed to update client details', err);
        return of(err);
      }));
  }

  addClient(formData, clientID, inviteAsClientContactFlag, roleDetails): string {
    const dateString = this.formatDate(new Date());
    const currentDate = new Date();
    const numberOfDaysToAdd = 30;
    currentDate.setDate(currentDate.getDate() + numberOfDaysToAdd);
    const newClientID = (Math.floor((Math.random() * 100000) + 1)).toString();
    if (this.clientList.find(v => v.clientID === clientID) === undefined) {
      const newClientObj = {
        'clientID': newClientID,
        'clientPreferredName': formData.ClientPreferredName,
        'clientLegalName': formData.ClientLegalName,
        'clientAddress': {
          'fullAddress': `${formData.ClientAddress}, ${formData.ClientCity}, ${formData.ClientState}, ${formData.ClientPostalCode}, ${formData.ClientCountry}`,
          'streetAddress': formData.ClientAddress,
          'city': formData.ClientCity,
          'state': formData.ClientState,
          'zipcode': formData.ClientPostalCode,
          'country': formData.ClientCountry
        },
        'clientRole': [{
          'roleID': '345435',
          'roleName': roleType.alpha,
          'roleDescrpition': this.message1
        }, {
          'roleID': '67886',
          'roleName': roleType.client,
          'roleDescrpition': this.message1
        }],
        'taxID': formData.TaxID,
        'status':  this.message2,
        'createdDate': dateString,
        'createdBy': '5d8b16401c9d440000f9df787',
        'statusDate': dateString,
        'isDeleted': false
      };
      this.clientList.push(newClientObj);
      if (inviteAsClientContactFlag) {
        this.clientContactService.addClientContact(formData, newClientID, undefined, 'create', roleDetails, true);
      } else {
        this.clientContactService.addClientContact(formData, newClientID, undefined, 'create', roleDetails, true);
      }
    } else {
      const updateDate = this.formatDate(currentDate);
      const billingContact = this.clientContactService.getBillingContactFor(clientID);
      this.clientList.filter(function (item) {
        return item.clientID === clientID;
      }).map(function (item) {
        item.clientPreferredName = formData.ClientPreferredName;
        item.clientLegalName = formData.ClientLegalName;
        item.clientAddress.streetAddress = formData.ClientAddress;
        item.clientAddress.city = formData.ClientCity;
        item.clientAddress.state = formData.ClientState;
        item.clientAddress.zipcode = formData.ClientPostalCode;
        item.clientAddress.country = formData.ClientCountry;
        item.taxID = formData.TaxID;
        item.status = 'Active';
        item.statusDate = dateString;
        if (inviteAsClientContactFlag) {
          if (billingContact.clientContactID) {
            this.clientContactService.addClientContact(formData, newClientID, billingContact.clientContactID, roleDetails, true);
            this.clientContactService.addClientContact(formData, newClientID, billingContact.clientContactID, roleDetails, true);
          } else {
            this.clientContactService.addClientContact(formData, newClientID, undefined, roleDetails, true);
          }
        }
        return item;
      });
    }
    return clientID ? clientID : newClientID;
    // return this.baseClientService
    //   .post<any>('end point', 'end point')
    //   .pipe(
    //     map(r => r.body),
    //     catchError((err, source) => {
    //       const empty: any = null;
    //       this.logService.logger('', 'Failed to add cost model', err);
    //       return of(empty);
    //     })
    //   );
  }

  updateStatus(formData, clientID): Client {
    const dateString = this.formatDate(new Date());
    const currentDate = new Date();
    const updateDate = this.formatDate(currentDate);
    const updatedClient = this.clientList.filter(function (item) {
      return item.clientID === clientID;
    }).map(function (item) {
      item.clientPreferredName = formData.ClientPreferredName;
      item.clientLegalName = formData.ClientLegalName;
      item.clientAddress.streetAddress = formData.ClientAddress;
      item.clientAddress.city = formData.ClientCity;
      item.clientAddress.state = formData.ClientState;
      item.clientAddress.zipcode = formData.ClientPostalCode;
      item.clientAddress.country = formData.ClientCountry;
      item.taxID = formData.TaxID;
      item.status = item.status ===  this.message2 ? 'Active' : 'Inactive';
      item.statusDate = dateString;
      return item;
    });
    return updatedClient[0];
  }

  deleteClient(clientID) {
    const index = this.clientList.indexOf(this.clientList.find(v => v.clientID === clientID), 0);
    this.clientList.filter(function (item) {
      return item.clientID === clientID;
    }).map(function (item) {
      item.isDeleted = true;
      return item;
    });
    this.clientList.splice(index, 1);

    // return this.baseClientService
    //   .delete<any>('end point')
    //   .pipe(
    //     map(r => r.body),
    //     catchError((err, source) => {
    //       const empty: any = null;
    //       this.logService.logger('', 'Failed to delete cost model', err);
    //       return of(empty);
    //     })
    //   );
  }

  deleteClients(clients) {
    clients.forEach(client => {
      const index = this.clientList.indexOf(this.clientList.find(v => v.clientID === client.clientID), 0);
      this.clientList.filter(function (item) {
        return item.clientID === client.clientID;
      }).map(function (item) {
        item.isDeleted = true;
        return item;
      });
      this.clientList.splice(index, 1);
    });
  }

  toggleDeleteFlag(clientID) {
    this.clientList.filter(function (item) {
      return item.clientID === clientID;
    }).map(function (item) {
      item.isDeleted = !item.isDeleted;
      return item;
    });
  }

  formatDate(date) {
    return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
  }

}
