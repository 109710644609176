/** MOV360-880 - to be removed at future date */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DailyOperations } from '../models/dailyoperations.model';

@Injectable({
  providedIn: 'root'
})
export class SupplierContractService {

  private supplierContractState = new BehaviorSubject<string>('');
  currentContractState = this.supplierContractState.asObservable();

  private dailyOperations = new BehaviorSubject<any>({});
  currentDailyOperationData = this.dailyOperations.asObservable();

  private oracleSite = new BehaviorSubject<any>('');
  currentOracleSiteData = this.oracleSite.asObservable();

  private contractIndex = new BehaviorSubject<number>(0);
  currentContractIndex = this.contractIndex.asObservable();

  private contractdata = new BehaviorSubject<any>({});
  currentContractData = this.contractdata.asObservable();

  private terriToryCoverage = new BehaviorSubject<any>([]);
  currentTerritoryData = this.terriToryCoverage.asObservable();

  updateContractState(state: any) {
    this.supplierContractState.next(state);
  }

  updateDailyOperations(data: any) {
    this.dailyOperations.next(data);
  }

  updateOracleSiteData(data: any) {
    this.oracleSite.next(data);
  }

  updateContractIndex(data: number) {
    this.contractIndex.next(data);
  }

  updateContractData(data: any) {
    this.contractdata.next(data);
  }

  updateTerriToryData(data: any) {
    this.terriToryCoverage.next(data);
  }

  constructor() { }
}
