/** MOV360-880 - to be removed at future date */
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoggerService } from 'src/app/core/services/logger.service';
import { Router } from '@angular/router';
import { SupplierContractService } from 'src/app/core/services/supplier-contract.service';
import { dateRangeValidator } from 'src/app/core/validators/date.validators';
import { LocationService } from 'src/app/core/services/location.service';
import * as moment from 'moment';
import { TerritoryCoverage, SupplierDiscount, SupplierCommission } from 'src/app/core/models/territory-coverage.model';

@Component({
  selector: 'app-add-edit-territory',
  templateUrl: './add-edit-territory.component.html',
  styleUrls: ['./add-edit-territory.component.scss']
})

export class AddEditTerritoryComponent implements OnInit {
  territoryForm: UntypedFormGroup;
  validationErrorMessages = {
    'territoryRequired': 'You must select territory',
    'territoryTariffRequired': 'You must select territory tariff',
    'discountTypeRequired': 'You must select discount type',
    'discountPercentageRequired': 'You must enter discount percentage',
    'startNoEnd': 'End date must be entered',
    'endNoStart': 'Start date must be entered',
    'endDateInvalid': 'End date must be greater than start date',
    'discountInvalid': 'You cannot enter multiple discount type for specified time period',
    'overlapDiscounts': 'You cannot enter multiple discount type for specified time period',
    'overlapStartDate': 'You cannot enter multiple discount type for specified time period',
    'overlapEndDate': 'You cannot enter multiple discount type for specified time period',
    'commissionTypeRequired': 'You must select commission type',
    'commissionPercentageRequired': 'You must enter commission percentage',
    'overlapCommissions': 'You cannot enter multiple commission type for specified time period'
  };
  commissionTypes = ['Micro-Move', 'Regular', 'Storage'];
  territoryStates: any[];
  territoryTariff = ['Cal Max 4', 'Cartus Rate Schedule', 'Sales Agency Agreement'];
  discountTypes = ['Micro-Move', 'Regular', 'Storage'];
  selectedDiscountType = '';
  selectedTerritory = '';
  selectedTariff = '';
  territoryData: any = {};

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<AddEditTerritoryComponent>,
  private readonly notificationsService: NotificationsService,
  private readonly formBuilder: UntypedFormBuilder,
  private readonly snackBar: MatSnackBar,
  private readonly spinner: NgxSpinnerService,
  private readonly Logger: LoggerService,
  private router: Router,
  private supplierContractService: SupplierContractService,
  private locationService: LocationService) {}

  ngOnInit() {
    this.includeAllState();
    this.territoryForm = this.formBuilder.group({
      territory: ['', Validators.required],
      territoryTariff: ['', Validators.required],
      specialDiscounts: this.formBuilder.array([]),
      commissions: this.formBuilder.array([])
    });

    if (this.data.territoryCoverage) {
      this.territoryData = this.data.territoryCoverage;
      this.setTerritoryValuesForEdit();
    }
  }

  includeAllState() {
    this.territoryStates = [{ 'name': 'All States', 'shortName': 'All States' }];
    const statelist = this.locationService.getStates();
    statelist.forEach(element => {
      this.territoryStates.push(element);
    });
  }

  close(event): void {
    event.preventDefault();
    event.stopPropagation();
    this.dialogRef.close();
  }

  onDiscountTypeChange(data, index) {
   const discounts = this.territoryForm.get('specialDiscounts').value;
    if (discounts.length > 1) {
      if ((discounts[index].packDateGroup.start) && (discounts[index].packDateGroup.end)) {
        const isInvalid = this.validateDiscount(index, discounts[index], discounts, index);
        let arrayIndex = 0;
        discounts.forEach(element => {
          if (arrayIndex !== index) {
            this.validateDiscount(arrayIndex, discounts[arrayIndex], discounts, index);
          }
          arrayIndex++;
        });
      }
    }
  }

  validateDiscount(index, dataToValidate, discountArray, ctrlIndex) {
    let arrayIndex = 0;
    let isDiscountInvalid = false;
    discountArray.forEach(element => {
      if (arrayIndex !== index && !isDiscountInvalid) {
        if (element.discountType === dataToValidate.discountType) {
          if ((dataToValidate.packDateGroup.start >= element.packDateGroup.start)
            && (dataToValidate.packDateGroup.start <= element.packDateGroup.end)) {
            isDiscountInvalid = true;
          }
          if ((dataToValidate.packDateGroup.end >= element.packDateGroup.start)
            && (dataToValidate.packDateGroup.end <= element.packDateGroup.end)) {
            isDiscountInvalid = true;
          }
        }
      }
      arrayIndex++;
    });
    const changedItem: UntypedFormGroup = this.specialDiscounts.controls[index] as UntypedFormGroup;
    if (isDiscountInvalid && index >= ctrlIndex) {
      changedItem.controls['discountType'].setErrors({'overlapDiscounts': true});
    } else {
      changedItem.controls['discountType'].setErrors(null);
    }
    return isDiscountInvalid;
  }

  startdateChange(index) {
    const discounts = this.territoryForm.get('specialDiscounts').value;
    this.validateDiscountDateRange(discounts[index], index);
    if (discounts.length > 1) {
      if (discounts[index].discountType) {
        const isInvalid = this.validateDiscount(index, discounts[index], discounts, index);
        let arrayIndex = 0;
        discounts.forEach(element => {
          if (arrayIndex !== index) {
            this.validateDiscount(arrayIndex, discounts[arrayIndex], discounts, index);
          }
          arrayIndex++;
        });
      }
    }
  }

  validateDiscountDateRange(discountdata, index) {
    const changedItem: UntypedFormGroup = this.specialDiscounts.controls[index] as UntypedFormGroup;
    const enddate = (changedItem.controls['packDateGroup']as UntypedFormGroup).controls['end'];
    const startDate = (changedItem.controls['packDateGroup']as UntypedFormGroup).controls['start'];
    if (discountdata.packDateGroup.start && !discountdata.packDateGroup.end) {
      enddate.setErrors({'startNoEnd': true});
      enddate.markAsTouched();
    } else if (!discountdata.packDateGroup.start && discountdata.packDateGroup.end) {
      startDate.setErrors({'endNoStart': true});
      startDate.markAsTouched();
    } else if (moment(enddate.value).diff(moment(startDate.value)) < 0 ) {
      enddate.setErrors({'dateValidator': true});
      enddate.markAsTouched();
    }
  }

  enddateChange(index) {
    const discounts = this.territoryForm.get('specialDiscounts').value;
    this.validateDiscountDateRange(discounts[index], index);
    if (discounts.length > 1) {
      if (discounts[index].discountType) {
        const isInvalid = this.validateDiscount(index, discounts[index], discounts, index);
        let arrayIndex = 0;
        discounts.forEach(element => {
          if (arrayIndex !== index) {
            this.validateDiscount(arrayIndex, discounts[arrayIndex], discounts, index);
          }
          arrayIndex++;
        });
      }
    }
  }

  get specialDiscounts(): FormArray {
    return this.territoryForm.get('specialDiscounts') as FormArray;
  }

  addDiscount(data?: any) {
    if (this.specialDiscounts.status === 'VALID') {
      if (data) {
      this.specialDiscounts.push(this.createDiscountItem(data));
      } else {
      this.specialDiscounts.push(this.createDiscountItem());
      }
    }
  }

  createDiscountItem(data?: any): UntypedFormGroup {
    if (data) {
      return this.formBuilder.group({
        discountType: [data.discountType, Validators.required],
        discountPercentage: [data.discountPercentage, Validators.required],
        packDateGroup: this.formBuilder.group({
          start: [data.startDate, Validators.required],
          end: [data.endDate, Validators.required],
        }),
      });
    } else {
      return this.formBuilder.group({
        discountType: ['', Validators.required],
        discountPercentage: ['', Validators.required],
        packDateGroup: this.formBuilder.group({
          start: ['', Validators.required],
          end: ['', Validators.required],
        }),
      });
    }
  }

  removeDiscountItem(index) {
    this.specialDiscounts.removeAt(index);
  }

  populateTerritoryData() {
    this.territoryData = {};
    this.territoryData.territory = this.territoryForm.value.territory;
    this.territoryData.territoryTariff = this.territoryForm.value.territoryTariff;
    if (this.territoryForm.value.specialDiscounts && this.territoryForm.value.specialDiscounts.length > 0) {
      this.territoryData.supplierDiscounts = [];
      this.territoryForm.value.specialDiscounts.forEach(element => {
        const discountData: SupplierDiscount = {
          discountType: element.discountType,
          discountPercentage: element.discountPercentage,
          startDate: element.packDateGroup.start,
          endDate: element.packDateGroup.end
        };
        this.territoryData.supplierDiscounts.push(discountData);
      });
    }

    if (this.territoryForm.value.commissions && this.territoryForm.value.commissions.length > 0) {
      this.territoryData.commissions = [];
      this.territoryForm.value.commissions.forEach(element => {
        const commissionData: SupplierCommission = {
          commissionType: element.commissionType,
          commissionPercentage: element.commissionPercentage,
          startDate: element.packDateGroup.start,
          endDate: element.packDateGroup.end
        };
        this.territoryData.commissions.push(commissionData);
      });
    }
  }

  SaveTerritory(event) {
    event.preventDefault();
    event.stopPropagation();
    this.populateTerritoryData();
    this.dialogRef.close(this.territoryData);
  }

    /** for commisions */
  get commissions(): FormArray {
    return this.territoryForm.get('commissions') as FormArray;
  }

  addCommision(data?: any) {
    if (this.commissions.status === 'VALID') {
      if (data) {
        this.commissions.push(this.createCommissionItem(data));
      } else {
        this.commissions.push(this.createCommissionItem());
      }
    }
  }

  createCommissionItem(data?: any): UntypedFormGroup {
    if (data) {
      return this.formBuilder.group({
        commissionType: [data.commissionType, Validators.required],
        commissionPercentage: [data.commissionPercentage, Validators.required],
        packDateGroup: this.formBuilder.group({
          start: [data.startDate, Validators.required],
          end: [data.endDate, Validators.required],
        }),
      });
    } else {
      return this.formBuilder.group({
        commissionType: ['', Validators.required],
        commissionPercentage: ['', Validators.required],
        packDateGroup: this.formBuilder.group({
          start: ['', Validators.required],
          end: ['', Validators.required],
        }),
      });
    }
  }

  removeCommissionItem(index) {
    this.commissions.removeAt(index);
  }

  onCommissionTypeChange(data, index) {
    const commissionArray = this.territoryForm.get('commissions').value;
    if (commissionArray.length > 1) {
      if ((commissionArray[index].packDateGroup.start) && (commissionArray[index].packDateGroup.end)) {
        const isInvalid = this.validateCommission(index, commissionArray[index], commissionArray, index);
        let arrayIndex = 0;
        commissionArray.forEach(element => {
          if (arrayIndex !== index) {
            this.validateCommission(arrayIndex, commissionArray[arrayIndex], commissionArray, index);
          }
          arrayIndex++;
        });
      }
    }
  }

  validateCommission(index, dataToValidate, discountArray, ctrlIndex) {
    let arrayIndex = 0;
    let isInvalid = false;
    discountArray.forEach(element => {
      if (arrayIndex !== index && !isInvalid) {
        if (element.commissionType === dataToValidate.commissionType) {
          if ((dataToValidate.packDateGroup.start >= element.packDateGroup.start)
            && (dataToValidate.packDateGroup.start <= element.packDateGroup.end)) {
            isInvalid = true;
          }
          if ((dataToValidate.packDateGroup.end >= element.packDateGroup.start)
            && (dataToValidate.packDateGroup.end <= element.packDateGroup.end)) {
            isInvalid = true;
          }
        }
      }
      arrayIndex++;
    });
    const changedItem: UntypedFormGroup = this.commissions.controls[index] as UntypedFormGroup;
    if (isInvalid && index >= ctrlIndex) {
      changedItem.controls['commissionType'].setErrors({'overlapCommissions': true});
    } else {
      changedItem.controls['commissionType'].setErrors(null);
    }
    return isInvalid;
  }

  commissionStartdateChange(index) {
    const commissionArray = this.territoryForm.get('commissions').value;
    this.validateCommissionDateRange(commissionArray[index], index);
    if (commissionArray.length > 1) {
      if (commissionArray[index].commissionType) {
        const isInvalid = this.validateCommission(index, commissionArray[index], commissionArray, index);
        let arrayIndex = 0;
        commissionArray.forEach(element => {
          if (arrayIndex !== index) {
            this.validateCommission(arrayIndex, commissionArray[arrayIndex], commissionArray, index);
          }
          arrayIndex++;
        });
      }
    }
  }

  validateCommissionDateRange(dataToValidate, index) {
    const changedItem: UntypedFormGroup = this.commissions.controls[index] as UntypedFormGroup;
    const enddate = (changedItem.controls['packDateGroup']as UntypedFormGroup).controls['end'];
    const startDate = (changedItem.controls['packDateGroup']as UntypedFormGroup).controls['start'];
    if (dataToValidate.packDateGroup.start && !dataToValidate.packDateGroup.end) {
      enddate.setErrors({'startNoEnd': true});
      enddate.markAsTouched();
    } else if (!dataToValidate.packDateGroup.start && dataToValidate.packDateGroup.end) {
      startDate.setErrors({'endNoStart': true});
      startDate.markAsTouched();
    } else if (moment(enddate.value).diff(moment(startDate.value)) < 0 ) {
      enddate.setErrors({'dateValidator': true});
      enddate.markAsTouched();
    }
  }

  commissionEnddateChange(index) {
    const commissionArray = this.territoryForm.get('commissions').value;
    this.validateCommissionDateRange(commissionArray[index], index);
    if (commissionArray.length > 1) {
      if (commissionArray[index].commissionType) {
        const isInvalid = this.validateCommission(index, commissionArray[index], commissionArray, index);
        let arrayIndex = 0;
        commissionArray.forEach(element => {
          if (arrayIndex !== index) {
            this.validateCommission(arrayIndex, commissionArray[arrayIndex], commissionArray, index);
          }
          arrayIndex++;
        });
      }
    }
  }

  /**end of Commisions */

  /**Initialize for edit territory*/
  setTerritoryValuesForEdit() {
    this.territoryForm.get('territory').setValue(this.territoryData.territory);
    this.territoryForm.get('territoryTariff').setValue(this.territoryData.territoryTariff);
    if (this.territoryData.supplierDiscounts && this.territoryData.supplierDiscounts.length > 0) {
      this.territoryData.supplierDiscounts.forEach(element => {
        this.addDiscount(element);
      });
    }

    if (this.territoryData.commissions && this.territoryData.commissions.length > 0) {
      this.territoryData.commissions.forEach(element => {
        this.addCommision(element);
      });
    }
  }
  /**End for edit territory*/
}
