/** MOV360-880 - to be removed at future date */
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalData } from 'src/app/core/services/popup-position.service';
import { UserContactInfo } from 'src/app/core/models/user-contact-info.model';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-user-contact-info',
  templateUrl: './user-contact-info.component.html',
  styleUrls: ['./user-contact-info.component.scss']
})
export class UserContactInfoComponent implements OnInit {

  constructor(@Inject(ModalData) public data: any) { }

  ngOnInit() {
    this.data.overlayRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        this.close(event);
      }
    });
  }

  userInitials(firstName: string, lastName: string) {
    const userFullName = firstName + ' ' + lastName;

    const parts = userFullName.split(' ');
    let initials = '';
    for (let i = 0; i < parts.length; i++) {
      if (parts[i].length > 0 && parts[i] !== '') {
        initials += parts[i][0];
      }
    }
    return initials;
  }

  close(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.data.overlayRef.dispose();
  }

}
