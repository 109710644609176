import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { Subscription } from 'rxjs';
import { addTeamTitle, specialCharactersMessage, apiErrorMessage, apiSuccessMessage, apiTeamAlreadyExists } from '../../models/constants';
import { TeamsService } from '../../services/teams.service';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { TeamEmployeeSearchComponent } from '../team-employee-search/team-employee-search.component';
import { Team, SelectedEmployee } from '../../models/teams.model';

@Component({
  selector: 'app-add-team',
  templateUrl: './add-team.component.html',
  styleUrls: ['./add-team.component.scss']
})

export class AddTeamComponent implements OnInit, OnDestroy {

  /** Form group name */
  addTeamForm: UntypedFormGroup;

  /** Title to display the dialog window page title */
  title = addTeamTitle;

  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();

  /** Team status options */
  teamStatuses: string[] = [];

  /** Processing */
  processing = false;

  /** Dialog Ref */
  employeeSearchDialogRef: MatDialogRef<TeamEmployeeSearchComponent>;

  /** Injecting dependencies */
  constructor(
    private readonly teamsService: TeamsService,
    private readonly notificationsService: NotificationsService,
    private readonly loggerService: LoggerService,
    private readonly formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddTeamComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackBar: MatSnackBar,
  ) {
    this.addTeamForm = this.formBuilder.group({
      name: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(30),
          Validators.pattern('^[a-z A-Z0-9-`]*$')
        ])
      ],
      owner: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      ownerId: [''],
      status: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
    });
  }

  /** Init */
  ngOnInit() {
    this.teamsService.getStatuses().subscribe({
      next: statuses => {
        if (statuses) {
          this.teamStatuses = statuses;
        } else {
          this.notificationsService.flashNotification('danger', apiErrorMessage);
        }
      },
      error: err => {
        this.loggerService.error('Failed to get team statuses', err);
        this.notificationsService.flashNotification('danger', apiErrorMessage);
      }
    });
    this.addTeamForm.controls.status.setValidators(this.validateSelect(this.teamStatuses));
  }

  /**
   * Custom error messages
   * @param fieldName - field parameter to check for errors
   */
  getErrorMessage(fieldName) {
    if (fieldName === 'NAME') {
      return this.addTeamForm.get('name').hasError('required')
      ? 'You must enter a Team Name'
      : this.addTeamForm.get('name').hasError('pattern')
        ? specialCharactersMessage
        : this.addTeamForm.get('name').hasError('minlength')
          ? 'Team Name should be more than one character'
          : this.addTeamForm.get('name').hasError('maxlength')
            ? 'Team name should be thirty characters or less'
            : '';
    }
    if (fieldName === 'OWNER') {
      return this.addTeamForm.get('owner').hasError('required')
        ? 'You must select a Team Owner'
        : '';
    }
    if (fieldName === 'STATUS') {
      return this.addTeamForm.get('status').hasError('required') || this.addTeamForm.get('status').hasError('match')
        ? 'You must select a Team Status'
        : '';
    }
    return '';
  }

  /** Employee Search */
  openEmployeeSearchDialog(): void {
    this.employeeSearchDialogRef = this.dialog.open(TeamEmployeeSearchComponent, {
      disableClose: true,
      panelClass: 'dialogMainContainer',
      data: { multiSelect: false },
      autoFocus: false
    });
    this.subscription.add(
      this.employeeSearchDialogRef.afterClosed().subscribe((employee: SelectedEmployee) => {
        if (employee) {
          this.addTeamForm.controls.owner.setValue(employee.name);
          this.addTeamForm.controls.ownerId.setValue(employee.id);
        }
      })
    );
  }

  /** Validate Team Status was chosen from select */
  validateSelect(arr: any[]): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      const value = c.value;
      const selected = arr.filter(item => item === value);
      return selected.length > 0 ? null : { match: true };
    };
  }

  /** Creates the Team */
  createTeam() {
    const team: Team = {
      name: this.addTeamForm.controls.name.value,
      owner: this.addTeamForm.controls.ownerId.value,
      status: this.addTeamForm.controls.status.value
    };
    this.processing = true;
    this.teamsService.createTeam(team).subscribe({
      next: response => {
      if (response && response.status === 200) {
        this.notificationsService.flashNotification('success', apiSuccessMessage);
        const newTeam = response.body;
        this.dialogRef.close(newTeam);
      } else {
          this.notificationsService.flashNotification('danger', this.processError(response));
      }
    },
    error: err => {
      this.loggerService.error('Failed to create team', err);
      this.notificationsService.flashNotification('danger', apiErrorMessage);
    },
    complete: () => {
      this.processing = false;
    }});
  }

  /** Translate errors into user friendly messages */
  processError(err: any): string {
    if (err.toString().indexOf('already existing') > -1) {
      return apiTeamAlreadyExists.replace('$TeamName', this.addTeamForm.controls.name.value);
    }
    return apiErrorMessage;
  }

  /** Closes the dialog and clears the form */
  cancel() {
    this.addTeamForm = this.formBuilder.group({
      name: [''],
      ownerId: [''],
      owner: [''],
      status: ['']
    });
    this.dialogRef.close();
  }

  /** Destroy */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
