/** MOV360-880 - to be removed at future date */
import { Component } from '@angular/core';

@Component({
  selector: 'app-candidate-name-initials',
  templateUrl: './candidate-name-initials.component.html',
  styleUrls: ['./candidate-name-initials.component.scss']
})
export class CandidateNameInitialsComponent  {

  constructor() { }

}
