<ng-template [appReadonly]="['Manage Candidates/Transferees All']" (valueChange)="isDisabled($event)">
</ng-template>
<app-header-status aria-live="assertive" aria-atomic="true" (event)="details.updateDataSource($event)"
  [disable]="disableButton"></app-header-status>
<section class="search_table">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <span fxFlex="60%" fxLayoutAlign="start">
      <mat-form-field appearance="outline" class="search-within-table search-box">
        <mat-icon matPrefix id="search_icon">search</mat-icon>
        <input matInput (input)="filterResults($event.target.value)" placeholder="Search within table for..."
          width="80%" fxFlex.sm="85" id="search_text" aria-label="Search within table">
      </mat-form-field>
      <span fxLayoutAlign="start center">
        <a href="#" (click)="openModal($event)">
          <span class="cdk-visually-hidden">Select columns</span>
          <mat-icon matTooltip="Select columns" matTooltipPosition="right" class="viewsetting-icon" id="view_column">
            view_column</mat-icon>
        </a>
      </span>
    </span>
  </div>
</section>
<p></p>
<app-candidate-details [selectedCols]="columnList" #details></app-candidate-details>