/** MOV360-880 - to be removed at future date */
import { Injectable } from '@angular/core';
import { BaseClientService } from './base-client.service';
import { Observable, of } from 'rxjs';
import { Candidate } from '../models/candidate';
import { map, catchError } from 'rxjs/operators';
import { RemoteLoggingService } from './remote-logging.service';
import { APIResponse } from '../models/response.model';
import { CandidateBudgetDetails } from '../models/candidate-budget-details.model';
import { LoggerService } from './logger.service';
import { CandidateProfilesService } from './candidate-profiles.service';

@Injectable({
  providedIn: 'root'
})
export class BudgetDetailsService {
  constructor(
    private readonly baseClientService: BaseClientService,
    private readonly logSvc: RemoteLoggingService,
    private readonly customLogger: LoggerService,
    private readonly candidateProfileSvc: CandidateProfilesService
  ) { }

  /**
   * updates the candidate move budget
   * @param budgetDetails budget details to update
   */
  updateCandidateMoveBudget(budgetDetails: any): Observable<APIResponse> {
    return this.baseClientService
      .put<APIResponse>(`/candidate/budget`, budgetDetails)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.customLogger.error('Failed to update candidate move budget', err);
          const empty: APIResponse = null;
          return of(empty);
        })
      );
  }
  authorizeTransferee(transfereeDetails): Observable<any> {
    return this.baseClientService
      .post<any>(`/candidate/authorizeTransferee`, transfereeDetails)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.customLogger.error('Failed to authorize transferee', err);
          const empty: any = null;
          return of(empty);
        })
      );
  }
  /**
   * service callto get consolidated details containing candidate, needsassessment and budget details
   * @param candidateId candidate ID
   */
  getCandidateBudget(candidateId: string): Observable<CandidateBudgetDetails> {
    return this.baseClientService.
      getById<CandidateBudgetDetails>(`/candidate/budget?candidateId=${candidateId}`)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.customLogger.error('Failed to authorize transferee', err);
          const empty: CandidateBudgetDetails = null;
          return of(empty);
        })
      );
  }

  /** service call to get Electronic Payment Instructions details*/
  getInvoicePdfDetails(clientId: string, candidateId: string, isAuthorizedTransferee?: boolean): Observable<any> {
    let queryString = '';
    if (isAuthorizedTransferee) {
      queryString = 'isAuthorizedTranfreree=1';
    }
    return this.baseClientService.
      getById<any>(`/candidate/getInvoiceData/${clientId}/${candidateId}?${queryString}`)
      .pipe(
        map(r => r),
        catchError(err => {
          return this.candidateProfileSvc.catchBlock('Failed to get Electronic Payment Instructions details', err, 'string');

        })
      );
  }
}
