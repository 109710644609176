<ngx-spinner type="ball-spin-clockwise-fade" size="medium">
</ngx-spinner>

<div class="authorize-dialog">
    <div mat-dialog-title disableTypography fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="20px"
       fxLayoutGap.xs="0em">
       <div class="candidate-dialogtitle">
          <span>{{isEmptyOracleSite ? 'Add Site ID' : 'Edit Site ID' }}</span>
       </div>
       <a class="close-icon" id="close_icon" (click)="close($event)">
          <mat-icon>close</mat-icon>
       </a>
    </div>
    <form fxLayout="column" class="middle-section form-group" [formGroup]="oracleSiteForm" (ngSubmit)="onSave($event)" novalidate>
        <div class="form-group" >
            <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
                fxLayoutGap.xs="0em">
                <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                    <mat-form-field>
                    <input matInput type="text" class="form-control" formControlName="oraclesiteId" maxlength="20"
                     placeholder="Oracle Site ID" onkeypress="return event.charCode >= 48 && event.charCode<=57"/>
                    <mat-error>You must enter Oracle Site ID</mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                    <mat-form-field>
                        <input [readonly]=true class="form-control" [disabled]='lockFields'
                        type="text" placeholder="Country" matInput
                         formControlName="country" id="departure">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayout.xs="column"
        fxLayoutGap.xs="0em">
            <div fxFlex="100%" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-form-field>
                    <input matInput type="text" class="form-control" formControlName="streetaddress"
                        placeholder="Street Address" />
                    <mat-error>
                        You must enter street address
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div  class="form-group middle-section space" >
            <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
                fxLayoutGap.xs="0em">
                <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                    <mat-form-field>
                    <input matInput type="text" class="form-control"  formControlName="city" placeholder="City"/>
                    <mat-error>You must enter city</mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="15%" fxLayout.xs="column" fxFlex.xs="100%">
                    <mat-form-field>
                        <mat-label>State</mat-label>
                        <mat-select class="form-control" formControlName="state">
                            <mat-option [value]="state.shortName" *ngFor="let state of stateList">{{state.shortName}}</mat-option>
                        </mat-select>
                    <mat-error>You must select state</mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="35%" fxLayout.xs="column" fxFlex.xs="100%">
                    <mat-form-field>
                    <input matInput type="text" class="form-control" formControlName="zipcode" placeholder="Zip Code"/>
                    <mat-error>You must enter zip code</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div mat-dialog-actions>
            <div class="statusBtn">
                <button class="uppercase" mat-button (click)="onNoClick($event)">Cancel</button>
                <button type="submit" mat-button *ngIf="isEmptyOracleSite" [disabled]="!canSaveContact()" [disabled]="oracleSiteForm.invalid" class="mat-button contained-button">Save</button>
                <button type="submit" mat-button  *ngIf="!isEmptyOracleSite" [disabled]="!canSaveContact()" [disabled]="oracleSiteForm.invalid" class="mat-button contained-button"
                (click)="close(event)">Save</button>
            </div>
        </div>
    </form>
</div>
