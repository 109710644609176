import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { EmployeeDetails } from '../../../../../core/models/employee-details.model';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { EmployeeRoles } from '../../../../../core/models/employee-roles.model';
import { EmployeeDetailsService } from '../../../../../core/services/employee-details.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { APIResponse } from '../../../../../core/models/response.model';
import { NotificationsService } from '../../../../../core/services/notifications.service';
import { LoggerService } from '../../../../../core/services/logger.service';
import { UserContextService } from '../../../../../core/services/user-context.service';
import { Employee } from '../../../../../core/models/employee.model';

@Component({
  selector: 'app-add-roles',
  templateUrl: './add-roles.component.html',
  styleUrls: ['./add-roles.component.scss']
})
export class AddRolesComponent implements OnInit {
  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /** Used to hold Employee Form */
  employeeForm: UntypedFormGroup;
  /** It stores the value for media observer */
  gridColumn: number;
  /** Store selected roles count */
  rolesCount = 0;
  /** Used to store Employee Details for Save API call */
  updatedEmployee: EmployeeDetails;
  /**
   * Base Constructor for Injecting dependencies to the Component
   * @param data Stores the data of selected employee
   * @param dialogRef Instance of MatDialogRef
   * @param formBuilder Instance of FormBuilder
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Employee,
    public dialogRef: MatDialogRef<AddRolesComponent>,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: UserContextService,
  ) { }

  /**
   * Intial Component initialization and API call to Load Page
   */
  ngOnInit() {
    this.initializeEmployeeForm();
    this.data.roles.forEach((role, ind) => {
      this.data.employeeData.roles.forEach((data, index) => {
        if (role.roleName === data.roleName) {
          role.status = true;
          this.rolesCount++;
        }
      });
    });
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-ADD_ROLES', 'ADD_ROLES');
      });
  }

  /**
   * Employee form Initialization
   */
  initializeEmployeeForm(): void {
    this.employeeForm = this.formBuilder.group({
      EmployeeName: this.data.employeeData.name,
      Email: { value: this.data.employeeData.emailAddress, disabled: true }
    });
  }

  /**
   * Function used to Updated the Roles for Employee
   * @param selected Holds the Employee Roles
   */
  updateChkbx(selected: EmployeeRoles): void {
    const index = this.data.roles.findIndex(x => x.roleName === selected.roleName);
    this.data.roles[index].status = !selected.status;
    this.data.roles[index].status ? this.rolesCount = this.rolesCount + 1 : this.rolesCount = this.rolesCount - 1;
  }

  /** Function for Closing the Mat-dialog */
  onNoClick(evt): void {
    evt.preventDefault();
    this.dialogRef.close();
  }

  /**
   * Function Used for Updating Employee Roles
   */
  save(): void {
    this.updatedEmployee = {} as EmployeeDetails;
    this.updatedEmployee.employeeId = this.data.employeeData.employeeId;
    this.updatedEmployee.roles = this.data.roles;
    this.dialogRef.close(this.updatedEmployee);
  }
}
