<div class="action" *ngIf="!authorizedTransferee" class="unAuthDownloadBtn">
    <button matTooltip="Downloading files with sensitive information is a security risk, please clear your cache afterwards" matTooltipPosition="above" matTooltipClass='clear-invoice-cache-warning'
        mat-button class="mat-button outlined-button" (click)="exportToPDF()">
        <mat-icon class="material-icons left">save_alt</mat-icon>Download Invoice PDF
    </button>
</div>

<button *ngIf="authorizedTransferee" [disabled]="invalidBudget" (click)="exportToPDF()" matTooltip="Downloading files with sensitive information is a security risk, please clear your cache afterwards" matTooltipPosition="above" matTooltipClass='clear-invoice-cache-warning' [disabled]="invalidBudget" mat-button
    class="mat-button outlined-button">
    <mat-icon class="material-icons left">save_alt</mat-icon>Download Invoice
</button>