<ngx-spinner type="ball-spin-clockwise-fade" size="medium">
</ngx-spinner>
<ng-template [appReadonly]="['Manage Candidates/Transferees All']" (valueChange)="isDisabled($event)">
</ng-template>
<!--Section To Candidate Profiles-->
<div class="budget-container">
    <div class="budget-content-container">
        <div class="candidateprofile-nav">
            <a [routerLink]="['/project-alpha/candidate-profiles']" #breadcrumb>Candidates</a><span
                class="current">{{candidateName}}</span>
        </div>
        <app-candidate-contact-info *ngIf="candidateContactInfo" [candidateContactInfo]="candidateContactInfo" [candidateId]="candidateId">
        </app-candidate-contact-info>

        <mat-accordion class="candidate-review-panel candidate-info-panel" multi="true">
            <!--Candidate Details starts-->
            <mat-expansion-panel class="candidate-detail">
                <mat-expansion-panel-header [collapsedHeight]="matExpansionHeaderHeight"
                    [expandedHeight]="matExpansionHeaderHeight">
                    <h2 class="panel-heading">Candidate Details</h2>
                </mat-expansion-panel-header>
                <mat-panel-description>
                    <app-candidate-info *ngIf="candidateInfo" [candidateInfo]="candidateInfo" class="full-stretch">
                    </app-candidate-info>
                </mat-panel-description>
            </mat-expansion-panel>
        </mat-accordion>

        <app-budget-chart class="candidate-budget-chart" *ngIf="candidateBudgetDetails"
            [serviceDetails]="budgetChartDetails">
        </app-budget-chart>

        <mat-accordion class="candidate-review-panel budget-panel" multi="true">
            <!--Core Benefits starts-->
            <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="matExpansionHeaderHeight"
                    [expandedHeight]="matExpansionHeaderHeight" fxLayout fxLayout.xs="row" fxLayoutGap="10px">
                    <h2 fxFlex="50%" class="panel-heading">Core Benefits</h2>
                    <div *ngIf="candidateBudgetDetails" fxFlex="50%" class="header-right-node hide-on-expand">
                        {{coreBudget| number}}
                        {{currency}}</div>
                </mat-expansion-panel-header>
                <mat-panel-description class="remove-margin">
                    <app-candidate-core-benifits class="full-stretch" *ngIf="coreBenefits" [coreService]="coreBenefits">
                    </app-candidate-core-benifits>
                </mat-panel-description>
            </mat-expansion-panel>

            <!--Flexible Spend starts-->
            <mat-expansion-panel class="flex-panel">
                <mat-expansion-panel-header [collapsedHeight]="matExpansionHeaderHeight"
                    [expandedHeight]="matExpansionHeaderHeight" fxLayout fxLayout.xs="row" fxLayoutGap="10px">
                    <h2 fxFlex="50%" class="panel-heading">Flexible Spend</h2>
                    <div *ngIf="candidateBudgetDetails" fxFlex="50%" class="header-right-node">
                        {{flexSpend | number}}
                        {{currency}}</div>
                </mat-expansion-panel-header>
                <app-candidate-flexible-benifits *ngIf="flexSpendDetails" [flexibleSpendDetails]="flexSpendDetails">
                </app-candidate-flexible-benifits>
            </mat-expansion-panel>
            <section class="budget-total">
                <div class="move-budget">
                    Recommended Move Budget: <span *ngIf="candidateBudgetDetails"
                        class="total">{{recommendedMoveBudget| number}}
                        {{currency}}</span>
                </div>
            </section>
        </mat-accordion>

        <mat-accordion class="candidate-review-panel" multi="true">
            <!--Include Tax Assistance starts-->
            <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="matExpansionHeaderHeight"
                    [expandedHeight]="matExpansionHeaderHeight" fxLayout fxLayout.xs="row" fxLayoutGap="10px">
                    <h2 fxFlex="50%" class="panel-heading">
                        <mat-checkbox [checked]="ifSelectedSwitch"
                            (change)="isTaxChoosen($event);getRelocationDetails();" (click)=" $event.stopPropagation();"
                            [disabled]="taxDisable">
                            Include Tax Assistance
                        </mat-checkbox>
                    </h2>
                    <div *ngIf="candidateBudgetDetails" fxFlex="50%" class="header-right-node"
                        [ngClass]="{ 'tax-exclude': !ifSelectedSwitch }">
                        {{taxAssistanceDefault | number}}
                        {{currency}}
                    </div>
                </mat-expansion-panel-header>
                <mat-panel-description class="remove-margin tax-assistance-term">
                    <div *ngIf="ifSelectedSwitch" [innerHTML]="taxIncludedMessgae">
                    </div>
                    <div *ngIf="!ifSelectedSwitch" [innerHTML]="taxNotIncludedMessage">
                    </div>
                </mat-panel-description>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion class="candidate-review-panel" multi="true">
            <!--Move Details starts-->
            <mat-expansion-panel class="move-detail-panel">
                <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="matExpansionHeaderHeight"
                    fxLayout fxLayout.xs="row" fxLayoutGap="10px">
                    <h2 fxFlex="34%" class="panel-heading">Move Details</h2>
                    <div *ngIf="candidateMoveInfo" fxFlex="66%" class="header-right-node move hide-on-expand">
                        <span>{{candidateMoveInfo.departure.fullAddress}}</span>
                        <mat-icon class="material-icons arrow">chevron_right</mat-icon>
                        <span>{{candidateMoveInfo.destination.fullAddress}}</span>
                    </div>
                </mat-expansion-panel-header>
                <mat-panel-description class="remove-margin">
                    <app-candidate-move-info *ngIf="candidateMoveInfo" [candidateMoveInfo]="candidateMoveInfo"
                        class="full-stretch"></app-candidate-move-info>
                </mat-panel-description>
            </mat-expansion-panel>
            <!--Departure & Home Details starts-->
            <mat-expansion-panel>
                <mat-expansion-panel-header [collapsedHeight]="matExpansionHeaderHeight"
                    [expandedHeight]="matExpansionHeaderHeight" fxLayout fxLayout.xs="row" fxLayoutGap="10px">
                    <h2 fxFlex="80%" class="panel-heading">Departure Home Details</h2>
                </mat-expansion-panel-header>
                <mat-panel-description class="remove-margin">
                    <app-candidate-departure-home-detail *ngIf="candidateDepartureInfo"
                        [candidateDepartureInfo]="candidateDepartureInfo" class="full-stretch">
                    </app-candidate-departure-home-detail>
                </mat-panel-description>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div class="budget-footer">
        <div fxFlex="100%" fxLayout fxLayout.md="row" fxLayout.sm="column" fxLayout.xs="column"
            fxLayoutAlign="start space-between" fxLayoutAlign.sm="start center" fxLayoutAlign.xs="start center">
            <div class="inner-pane" fxFlex="60%" fxFlex.sm="100%" fxFlex.xs="100%" fxLayout>
                <div class="item budget-move">
                    <form class="update-budget-form" [formGroup]="budgetForm">
                        <mat-form-field id=moveBudgetField appearance="standard">
                            <mat-label>Move Budget:</mat-label>
                            <input matInput [appAmountFormat]="budgetForm.controls['MoveBudget']" id='moveBudget'
                                formControlName="MoveBudget" (blur)="checkBudget(); getRelocationDetails();"
                                autocomplete="off">
                            <mat-icon *ngIf="mode==='edit'" aria-hidden="true" matSuffix>mode_edit</mat-icon>
                            <a href="javascript:void(0)" aria-label="restore move budget" *ngIf="mode==='undo'"
                                (click)="undoBudget(); $event.stopPropagation(); getRelocationDetails();" matSuffix>
                                <mat-icon aria-hidden="true">restore</mat-icon>
                            </a>
                        </mat-form-field>
                    </form>
                </div>
                <div class="item tax">
                    <label class="label">Tax Assistance:</label>
                    <mat-icon matSuffix>info</mat-icon>
                    <div class="toggle">
                        <mat-slide-toggle [checked]="ifSelectedSwitch"
                            (change)="isTaxChoosen($event); getRelocationDetails();" [disabled]="taxDisable"
                            id='taxToggle'>
                            <span *ngIf="ifSelectedSwitch">ON</span>
                            <span *ngIf="!ifSelectedSwitch">OFF</span>
                        </mat-slide-toggle>
                    </div>
                </div>
                <div class="item budget-total">
                    <mat-form-field appearance="standard">
                        <mat-label>Total Expense:</mat-label>
                        <input matInput readonly placeholder="Total expense" [value]="totalBudget">
                    </mat-form-field>
                </div>
            </div>
            <div class="inner-pane" fxFlex="40%" fxFlex.sm="100%" fxFlex.xs="100%" fxLayout
                fxLayoutAlign="flex-end start" fxLayoutAlign.sm="space-between start"
                fxLayoutAlign.xs="space-between start">
                <div class="item">

                    <app-relo-offer-document *ngIf="candidateBudgetDetails"
                        [authorizedTransferee]="authorizedTransferee" [relocationDetails]="relocationDetails"
                        [invalidBudget]="invalidBudget" class="inherit-height">
                    </app-relo-offer-document>
                    <span *ngIf="authorizedTransferee" class="message">Authorized Candidate Transfer on
                        {{ authorizedDate | date:'yyyy-MM-dd'}}</span>
                </div>
                <div class="item">
                    <button *ngIf="!authorizedTransferee" [disabled]="invalidBudget || disableButton" mat-button
                        class="mat-button contained-button" (click)="openAuthorizeDialog()" id="Authorize">
                        Authorize Transfer
                    </button>
                    <app-invoice-pdf *ngIf="authorizedTransferee && invoiceDetails" class="inherit-height"
                        [authorizedTransferee]="authorizedTransferee" [amountDue]="moveBudget"
                        [transfereeName]="candidateName" [invoiceDetails]="invoiceDetails"
                        [invalidBudget]="invalidBudget">
                    </app-invoice-pdf>
                </div>
            </div>
        </div>
    </div>
</div>