/** MOV360-880 - to be removed at future date */
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { SupplierContact } from '../models/supplier-contact.model';
import { APIResponse } from '../models/response.model';
import { LoggerService } from './logger.service';
import { urlType } from '../models/urlType';

@Injectable({
    providedIn: 'root'
})
export class SupplierContactService {
    constructor(private readonly baseClientService: BaseClientService,
        private readonly logSvc: RemoteLoggingService,
        private readonly customLogger: LoggerService) { }

    private isManageSupplier = new BehaviorSubject<boolean>(false);
    currentMangeSupplierCapability = this.isManageSupplier.asObservable();
    updateManageSupplierCapability(data: any) {
      this.isManageSupplier.next(data);
    }

    getActiveSupplier(): Observable<any> {
        return this.baseClientService.get('/supplier/getActiveSupplier').pipe(
            map(r => r.body),
            catchError(err => {
                this.logSvc.logger('', 'Failed to get Active Supplier', err);
                const emptyArray = {};
                return of(emptyArray);
            })
        );
    }

    createSupplierContact(request: SupplierContact): Observable<APIResponse> {
        return this.baseClientService
            .post<APIResponse>(`/supplierContact`, request)
            .pipe(
                map(r => r.body),
                catchError((err, source) => {
                    const empty: APIResponse = null;
                    this.customLogger.error('Failed to add supplier details', err);
                    return of(empty);
                })
            );
    }

    sendInviteToSupplierContact(request: SupplierContact): Observable<APIResponse> {
        return this.baseClientService
            .post<APIResponse>(`/supplierContact/sendInvite`, request)
            .pipe(
                map(r => r.body),
                catchError((err, source) => {
                    const empty: APIResponse = null;
                    this.customLogger.error('sending Invite to supplier contact Failed', err);
                    return of(empty);
                })
            );
    }

    getAllSupplierContacts(limit, skip, searchText, sortField?: string, sortDir: string = 'ASC'): Observable<any> {
        let queryString = `roleName=supplier-contact&limit=${limit}&skip=${skip}`;
        if (sortField) {
          queryString = `${queryString}&sortField=${sortField}&sortDir=${sortDir}`;
        }
        if (searchText) {
            queryString = `${queryString}&searchText=${searchText}`;
        }

        return this.baseClientService.get<any>(`/supplierContact/getSupplierContacts?${queryString}`, '', urlType.api)
        .pipe(map(response => response.body));
    }

    getActiveSuppliers(): Observable<any> {
        return this.baseClientService.get<any>(`/supplier/getActiveSupplier`, '', urlType.api)
        .pipe(map(response => response.body));
    }

    updateSupplierContact(body): Observable<any> {
        const {supplierContactId} = body;
        return this.baseClientService.put<any>(`/supplierContact/updateSupplierContact/${supplierContactId}`, body).pipe(
          map(r => r.body),
          catchError(error => {
            this.customLogger.error('Failed to update supplier', error);
            const emptyArray: any = null;
            return of(emptyArray);
          })
        );
    }

    inActivateSupplierContact(body): Observable<any> {
        return this.baseClientService.put<any>(`/supplierContact/inActiveSupplierContact`, body).pipe(
          map(r => r.body),
          catchError(error => {
            this.customLogger.error('Failed to inActiate supplier', error);
            const emptyArray: any = null;
            return of(emptyArray);
          })
        );
    }

}
