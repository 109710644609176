/** MOV360-880 - to be removed at future date */
import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import {
  deleteCostModelTitle,
  deleteCostModelDisplayText1,
  deleteCostModelDisplayText2
} from '../../../../core/models/constants';
import { LoggerService } from '../../../../core/services/logger.service';
import { UserContextService } from '../../../../core/services/user-context.service';


@Component({
  selector: 'app-delete-cost-model',
  templateUrl: './delete-cost-model.component.html',
  styleUrls: ['./delete-cost-model.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeleteCostModelComponent implements OnInit {

  /** Title for cost model pop up */
  title = deleteCostModelTitle;
  /** Text to be displayed - first line */
  displayText1 = deleteCostModelDisplayText1;
  /** Text to be displayed - last line */
  displayText2 = deleteCostModelDisplayText2;
  /**
   * base constructor
   * @param data data received from calling component
   * @param dialogRef MatDialogRef component
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DeleteCostModelComponent>,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: UserContextService
  ) { }

  /** Function to be called on init  */
  ngOnInit() {
    this.data.selectedCostModels.sort((firstData, secondData) => (firstData.costModelName > secondData.costModelName) ? 1 : -1);
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-DELETE_COST_MODEL', 'DELETE_COST_MODEL');
      });

  }

  /** Function to be called on cancel/close; returns 'close' to calling component */
  onNoClick(evt) {
    evt.preventDefault();
    this.dialogRef.close('cancel');
  }
  /** Function to be called on confirmation; returns 'withdraw' to calling component */
  removeCostModel() {
    this.dialogRef.close('deleteCostModel');
  }
}
