/** MOV360-880 - to be removed at future date */
import { Component, OnInit, ViewChild, ChangeDetectorRef, SimpleChanges, Input, Inject, OnChanges, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as querystring from 'querystring';
import { AddSupplierComponent } from './add-supplier/add-supplier.component';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { ActiveSuppliers } from 'src/app/core/models/active-suppliers.model';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { Selection } from '../../../core/models/selection.model';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExtensionService } from 'src/app/core/services/extension.service';
import { Extension } from 'src/app/core/models/extension.model';
import { SupplierContactService } from 'src/app/core/services/supplier-contact.service';
import { Candidate } from 'src/app/core/models/candidate';
import { AddEditDailyOperationsComponent } from './add-edit-daily-operations/add-edit-daily-operations.component';
import { Supplier } from 'src/app/core/models/add-supplier.model';
import { PartySharedService } from 'src/app/core/services/party-shared.service';

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.scss']
})
export class SuppliersComponent implements OnInit, OnChanges, OnDestroy {
  /**variable to store the title */
  displayedColumns: string[] = [
    'select',
    'preferredName',
    'address',
    'contractStatus',
    'lastUpdateAt'
  ];

  dailyOperationsData = {
    firstName: 'Mathew',
    lastName: 'Maturity',
    emailAddress: 'mathew.maturity@gmail.com',
    countryDialingCode: '+21',
    phoneNumber: '9875643278'
  };


  supplierPreferredName;
  supplierData;


  /**stores the details of Supplier */
  // ELEMENT_DATA: Supplier[] = [];
  ELEMENT_DATA: ActiveSuppliers[] = [];


  supplierContactSubscription: Subscription;
  dataSource = new MatTableDataSource<any>();

  /**to store the rows selected */
  selection = new SelectionModel<any>(true, []);

  errorMessage = 'We are unable to fetch Quote Request List at this time. Please try again later.';

  /** To sort the mat table columns */
  @ViewChild(MatSort, { static: false }) set sortOrder(sort: MatSort) {
    if (sort) {
      this.dataSource.sort = sort;
    }
  }
  /** To paginate in a mat table */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  /** store default column for sorting */
  sortBy = 'preferredName';
  /** sorting direction */
  sortDir = 'asc';
  defaultExtension: any;

  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /**stores count of active Supplier */
  initialCount = 0;
  /** Stores the client contact ID */
  clientContactId: string;
  pageInfo = {
    pageSize: 20,
    pageIndex: 0,
    totalCount: 7
  };
  departureState = 'departure.state';
  totalSuppliers;
  label = 'Suppliers';
  /** Input prop for receiving data*/
  @Input() selectedCols: Selection[];

  isReview = true;
  isActive = true;
  isInactive = true;
  capabilities = [];
  isManageSupplierCapability = false;
   /**
   * injecting dependencies
   * @param dialog object for matdialog
   * @param  candidateProfilesService object for CandidateProfileService
   * @param changeDetectorRefs object for changeDetectorRefs object
   * @param notificationsService object for NotificationsService
   * @param spinner object for spinner
   */

  /**
   * Inject dependencies to be used in the component
   * @param dialog -MatDialog object
   * @param notificationsService -Object for NotificationsService
   */
  constructor(
    public dialog: MatDialog,
    private readonly notificationsService: NotificationsService,
    private readonly Logger: LoggerService,
    private readonly changeDetectorRefs: ChangeDetectorRef,
    private readonly _router: Router,
    public spinner: NgxSpinnerService,
    private extnSvc: ExtensionService,
    private supplierContactService: SupplierContactService,
    private partySharedSvc: PartySharedService
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.spinner.show();
    }, 150);
    this.loadActiveSuppliers();
    this.subscription.add(
      this.extnSvc.getExtensionDetails().subscribe((extensions: any[]) => {
        this.defaultExtension = extensions.find(x => x.name === 'United States');
        const extensionDetails: any[] = [];
        for (let i = 0; i < extensions.length; i++) {
          const extn = extensions[i];
          const extensionData: Extension = {
            countryDialingCode: `+${extn.dialingCode}`,
            name: extn.name
          };
          extensionDetails.push(extensionData);
        }
        this.extnSvc.extension$.next(extensionDetails);
      })
    );
    this.showRoleCapabilities();
  }

  loadActiveSuppliers() {
    this.spinner.show();
    this.supplierContactService.getActiveSuppliers().subscribe({
      next: data => {
        this.ELEMENT_DATA = data;
        this.dataSource.data = this.ELEMENT_DATA;

        this.setSuppliers();
        this.setLocation();
        this.setActiveInactive();

        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sortOrder;
        this.totalSuppliers = data.length.toString();
        this.spinner.hide();
      },
      error: err => {
        this.spinner.hide();
        console.log('HTTP Error', err);
        this.notificationsService.flashNotification(
          'error',
          err.message,
          true,
          'dismiss'
        );
      }
    });
  }

  /*Method for entry specialist role n capabilities*/
  showRoleCapabilities() {
    this.partySharedSvc.getPartyId().then(id => {
    this.partySharedSvc.getRoleCapabilities(id).then(response => {
      if (!!response && !!response.roleCapabilities) {
        for (const rolecapability of response.roleCapabilities) {
          if (!!rolecapability && !!rolecapability.capabilities && rolecapability.capabilities.length > 0) {
            for (const capability of rolecapability.capabilities) {
              if (capability.name === 'Manage Supplier') {
                this.isManageSupplierCapability = true;
                this.supplierContactService.updateManageSupplierCapability(this.isManageSupplierCapability);
              }
              this.capabilities.push(capability.name);
            }
          }
        }
      }
    });
    });
  }

  setActiveInactive() {
    this.ELEMENT_DATA.forEach(element => {
      element.isSwitchActive = true;
    });
  }

  /*Method to set the suppliers*/
  setSuppliers() {
    this.ELEMENT_DATA.forEach(element => {
      if (element.preferredName) {
        element.preferredName = `${element.preferredName.charAt(0).toUpperCase()}${element.preferredName.slice(1)}`;
      }
    });
  }

  /* Method to set the location*/
  setLocation() {
    this.ELEMENT_DATA.forEach(element => {
      if (element.contactMechanisms.length > 0) {
        element.contactMechanisms.forEach(contact => {
          if (contact.contactType === 'address') {
            const city = `${contact.city.charAt(0).toUpperCase()}${contact.city.slice(1)}` || '';
            const state = `${contact.state.charAt(0).toUpperCase()}${contact.state.slice(1)}` || '';
            element.address = `${state}, ${city}`;
          }
        }
        );
      }
    });
  }

  sortData(event) {
    this.sortBy = event.active;
    this.sortDir = event.direction === 'desc' ? 'desc' : 'asc';
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  getQueryString(_clientContactId, _searchText?, _sortField?, _sortDir?, _skip?, _limit?): string {
    const _queryString = { clientContactId: _clientContactId };
    if (_searchText) { _queryString['searchText'] = _searchText; }
    if (_sortField) { _queryString['sortField'] = _sortField; }
    if (_sortDir) { _queryString['sortDir'] = _sortDir; }
    if (_skip) { _queryString['skip'] = _skip; }
    if (_limit) { _queryString['limit'] = _limit; }
    return querystring.stringify(_queryString);
  }

  /**method to filter column values */
  customFilterPredicate(data, filter): boolean {
    const departure = data.departure ? `${data.departure.state}, ${data.departure.city}` : '';
    const searchTerm = filter.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const dataStr =
      `${data.level}
      ${data.destination.state}, ${data.destination.city}${data.fullname}${departure}${data.emailAddress}
      ${data.businessUnit}${data.status}${data.createdBy}${data.phoneNumber}`;
    return dataStr.search(new RegExp(searchTerm, 'gi')) !== -1;
  }

  /**
   * function to get property by path
   * @param obj -object passed
   * @param pathString -path passed
   */
  getPropertyByPath(obj: Object, pathString: string) {
    if (pathString === this.departureState && !obj.hasOwnProperty('departure')) { return null; }
    return pathString.split('.').reduce((o, i) => o[i], obj);
  }

  /**Refresh and detect the changes */
  refresh() {
    this.changeDetectorRefs.detectChanges();
  }

  /**format model for supplier level*/
  formatModels(candidates) {
    for (const candidate of candidates) {
      if (candidate.level) {
        candidate.levelName = candidate.level.split('(')[0];
        candidate.levelDes = candidate.level.replace('(', '#(').split('#')[1];
      }
    }
  }

  /**function for selecting the rows in the table */
  checkboxLabel(row?: ActiveSuppliers): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${
      this.selection.isSelected(row) ? 'deselect' : 'select'
      } row ${row.preferredName + 1}`;
  }

  /**Function to detect the changes */
  public ngOnChanges(changes: SimpleChanges) {
    if ('selectedCols' in changes) {
      const tempStr: string[] = [];
      this.selectedCols.forEach((item, index) => {
        if (this.displayedColumns.indexOf(item.value) < 0) {
          this.displayedColumns.splice(
            this.displayedColumns.length - 1,
            0,
            item.value
          );
        }
      });
      this.displayedColumns.forEach(element => {
        const ind = this.selectedCols.findIndex(col => col.value === element);
        if (ind !== -1) {
          tempStr.push(element);
        }
      });
      this.displayedColumns =
        tempStr.length > 0 ? tempStr : this.displayedColumns;
      if (this.displayedColumns.findIndex(val => val === 'select') < 0) {
        this.displayedColumns.unshift('select');
      }
    }
  }

  /** Method to open Add supplier dialog**/
  openAddModal(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    const dialogRef = this.dialog.open(AddSupplierComponent, {
      disableClose: true,
      panelClass: ['dialogMainContainer'],
      data: []
    });
  }

/** Method to open Daily Operations dialog**/
openDailyOperationsModal(isEmptySupplierInfo: boolean, supplierDetails?: any): void {
  const dialogRef = this.dialog.open(AddEditDailyOperationsComponent, {
    disableClose: true,
    panelClass: ['dialogMainContainer'],
    data: {isEmptySupplierInfo: isEmptySupplierInfo, supplierDetails : supplierDetails, dailyOperations: this.dailyOperationsData}
  });
  this.subscription.add(
    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.dailyOperationsData = result;
          this.loadActiveSuppliers();
        }
      })
  );
}

  /** navigate to Supplier Summary **/
  navigateToSupplierSummary(supplier: any) {
    this._router.navigate(['/project-alpha/supplier/details'], {
      state: supplier
    });
  }

  parseSupplierData(supplier: any) {
const data: Supplier = {
    supplierId: supplier._id,
    supplierLegalName: supplier.entityName,
    supplierPreferredName: supplier.preferredName,
    supplierNumber: '',
    supplierAddress: {
      country: '',
      streetAddress: '',
      city: '',
      state: '' ,
      zipcode: ''
    },
    status:  '',
    statusDate: '',
    supplierRole: 'supplier',
    createdDate: supplier.createdAt,
    createdBy: '',
    isDeleted: false,
};
const contactMechanisms = supplier.contactMechanisms;
if (contactMechanisms && contactMechanisms.length > 0) {
  data.supplierAddress = {
    country: contactMechanisms[0].country,
    streetAddress: contactMechanisms[0].addressLines[0],
    city: contactMechanisms[0].city,
    state: contactMechanisms[0].state,
    zipcode: contactMechanisms[0].postalCode
  };
}
 return data;
  }
  /**To destroy the subscription */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  onPagination(event: any) {
    this.dataSource.paginator = this.paginator;
    this.pageInfo.pageSize = this.paginator.pageSize;
  }
}
