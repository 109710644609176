/** MOV360-880 - to be removed at future date */
import { Component, OnInit, Output, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { Selection } from '../../../../core/models/selection.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import {LoggerService} from '../../../../core/services/logger.service';
import { UserContextService } from '../../../../core/services/user-context.service';

@Component({
  selector: 'app-client-columns',
  templateUrl: './client-columns.component.html',
  styleUrls: ['./client-columns.component.scss']
})
export class ClientColumnsComponent implements OnInit, OnDestroy {

  /**
   * Prop to store the column names and flag details
   */
  billingContactLastName = 'billingContact.lastName';
  billingContactEmailAddress = 'billingContact.emailAddress';
  clientAddressState = 'clientAddress.state';
  columnsList: Selection[] = [
    {
      displayName: 'Client Name',
      value: 'clientPreferredName',
      flag: true,
      disabled: true
    },
    {
      displayName: 'Billing Contact',
      value: this.billingContactLastName,
      flag: true,
      disabled: false
    },
    {
      displayName: 'Client Status',
      value: 'status',
      flag: true,
      disabled: true
    },
    {
      displayName: 'Client Legal Name',
      value: 'clientLegalName',
      flag: false,
      disabled: false
    },
    {
      displayName: 'Billing Contact Role',
      value: 'billingContact.role.roleName',
      flag: false,
      disabled: false
    },
    {
      displayName: 'TaxID',
      value: 'taxID',
      flag: false,
      disabled: false
    },
    {
      displayName: 'Client Location',
      value: this.clientAddressState,
      flag: true,
      disabled: false
    }
  ];
  /**
   * Prop to store the updated column list
   */
  selectedColumnsList: Selection[] = [];
  /**
   * It stores the value for media observer
   */
  gridColumn: number;
  /**
   * It Emits the data to the parent component
   */
  @Output() columnsListUpdated = new EventEmitter<Selection[]>();
  /**
   * media subscription
   */
  mediasbscription: Subscription;
  /**
   * Initializes the value
   * @param dialogRef Instance for DialogRef
   * @param mediaobserver Instance for media observer
   * @param data Instance for mat dialog data
   */
  constructor(public dialogRef: MatDialogRef<ClientColumnsComponent>,
    private mediaobserver: MediaObserver, @Inject(MAT_DIALOG_DATA) public data: string[], private Logger: LoggerService,
    private loggedInUserService: UserContextService) {
    this.mediasbscription = mediaobserver.asObservable().subscribe((val: MediaChange[]) => {
      if (val[0].mqAlias === 'xs') {
        this.gridColumn = 1;
      } else {
        this.gridColumn = 2;
      }
    });
  }
  /**
   * It resets the value for the view props
   */
  ngOnInit() {
    this.data.forEach(item => {
      this.columnsList.forEach(col => {
        if (item === col.value) {
          col.flag = true;
          this.selectedColumnsList.push(col);
        } else {
          if (this.columnsList.findIndex(val => val.value !== item) > 0) {
            col.flag = false;
          }
        }
      });
    });
    this.loggedInUserService.getLoggedInUserDetails()
    .subscribe(response => {
      const userId: any = response.userId.replace(/ .*/, '');
      this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-CLIENT_COLUMNS', 'CLIENT_COLUMNS');
    });
  }
  /**
   * Method to initialize the selected array list
   */
  populateArray(): void {
    this.selectedColumnsList = [
      {
        displayName: 'Client Name',
        value: 'clientPreferredName',
        flag: true,
        disabled: true
      },
      {
        displayName: 'Client Location',
        value: this.clientAddressState,
        flag: true,
        disabled: false
      },
      {
        displayName: 'Billing Contact Name',
        value: this.billingContactLastName,
        flag: true,
        disabled: false
      },
      {
        displayName: 'Status',
        value: 'status',
        flag: true,
        disabled: true
      }
    ];
  }
  /**
   * updates the new column list
   * @param selected - sends the selected array value
   * @param isChecked - prop for check box selected value
   */
  updateChkbxArray(selected: Selection, isChecked: boolean) {
    if (isChecked) {
      if (this.columnsList.findIndex(x => x.value === selected.value)) {
        this.selectedColumnsList.splice(
          this.selectedColumnsList.length - 1, 0,
          { displayName: selected.displayName, value: selected.value, flag: isChecked, disabled: selected.disabled }
        );
        this.columnsList.forEach((col, index) => {
          if (selected.value === col.value) {
            col.flag = true;
          }
        });
      }
    } else {
      const ind: number = this.selectedColumnsList.findIndex(y => y.value === selected.value);
      if (ind !== -1) {
        this.selectedColumnsList.splice(ind, 1);
        this.columnsList.forEach(col => {
          if (selected.value === col.value) {
            col.flag = false;
          }
        });
      }
    }
  }
  /**
    * Closing the dialog box - we are setting the form to empty
    */
  onNoClick(): void {
    this.dialogRef.close();
  }
  /**
   * Resets the value to default version
   */
  resetValues(): void {
    this.populateArray();
    const chkBoxLabels = [
      'clientPreferredName', 'status', this.clientAddressState, this.billingContactLastName, this.billingContactEmailAddress
    ];
    this.columnsList.forEach(col =>
      chkBoxLabels.find( v => v === col.value) === undefined && (col.flag = false)
    );
  }
  /**
   * Emits the updated array to parent component
   */
  save(): void {
    this.dialogRef.close();
    this.columnsListUpdated.emit(this.selectedColumnsList);
  }
  /**
   * destroys the object
   */
  ngOnDestroy() {
    this.mediasbscription.unsubscribe();
  }
}
