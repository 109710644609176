<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<div class="authorize-dialog dialogContent">
    <div mat-dialog-title disableTypography fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="20px"
        fxLayoutGap.xs="0em">
        <div class="candidate-dialogtitle">
            <span *ngIf="data.contractInfo">Edit Supplier Contract</span>
            <span *ngIf="!data.contractInfo">Add Supplier Contract</span>
        </div>
        <a class="close-icon" id="close_icon" (click)="close($event)">
            <mat-icon>close</mat-icon>
        </a>
    </div>
    <div mat-dialog-content>
        <form fxLayout="column" class="form-group middle-section" [formGroup]="addContractForm" novalidate fxLayoutGap="24px" >
            <div class="contractContent" fxLayout="column" fxLayoutGap="0.5em" fxLayoutGap.xs="1.5em">
                <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayout.xs="column"
                    fxLayoutGap.xs="0em">
                    <div fxFlex="100%" fxLayout.xs="column" fxFlex.xs="100%">
                        <mat-form-field>
                            <mat-label>Contract Service Type</mat-label>
                            <mat-select class="form-control controlBackground" [(value)]="selectedServiceType" formControlName="contractServiceType">
                                <mat-option [value]="type" *ngFor="let type of contractServiceTypes">{{ type }}</mat-option>
                            </mat-select>
                            <mat-error>
                                {{validationErrorMessages.serviceTypeInvalid}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em"
                    fxLayout.xs="row" fxLayoutGap.xs="0.5em" formGroupName="packDateGroup" *ngIf="selectedServiceType">
                    <div fxFlex="48%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="48%">
                        <mat-form-field class="datewidth">
                            <input matInput [matDatepicker]="picker" placeholder="Start Date"  formControlName="start" class="controlBackground change">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error >{{validationErrorMessages.startDateRequired}}</mat-error>                        
                        </mat-form-field>
                    </div>
                    <div fxFlex="4%" fxLayoutAlign="center center" fxLayout.xs="column" fxFlex.xs="4%">
                    <span>-</span>
                    </div>
                    <div fxFlex="48%" fxLayout.xs="column" fxFlex.xs="48%">
                        <mat-form-field class="datewidth">
                            <input matInput [matDatepicker]="picker1" placeholder="End Date (optional)" formControlName="end" class="controlBackground change">
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                            <mat-error *ngIf="addContractForm.get('packDateGroup').hasError('dateValidator')">{{validationErrorMessages.endDateInvalid}}</mat-error>                        
                        </mat-form-field>
                    </div>
                </div>
                <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayout.xs="column"
                    fxLayoutGap.xs="0em" *ngIf="selectedServiceType">
                    <div fxFlex="100%" fxLayout.xs="column" fxFlex.xs="100%">
                        <mat-form-field>
                            <mat-label>Affiliation Relationship</mat-label>
                            <mat-select class="form-control controlBackground change" [(value)]="selectedAffiliationRelationship" formControlName="affiliationRelation">
                                <mat-option [value]="relation" *ngFor="let relation of affiliationRelations">{{ relation }}</mat-option>
                            </mat-select>
                            <mat-error>
                                {{validationErrorMessages.affilationRelationInvalid}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayout.xs="column"
                    fxLayoutGap.xs="0em" *ngIf="selectedAffiliationRelationship ==='Affiliate'">
                    <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                        <mat-form-field >
                            <mat-label>Affiliate of</mat-label>
                            <mat-select class="form-control controlBackground change" [(value)]="selectedAffiliatedOf" formControlName="affiliateOf">
                                <mat-option [value]="affiliate" *ngFor="let affiliate of affiliateOf">{{ affiliate }}</mat-option>
                            </mat-select>
                            <mat-error>
                                {{validationErrorMessages.affiliateOfInvalid}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                        <mat-form-field>
                            <input matInput type="text" class="form-control controlBackground change" formControlName="affiliateId"
                                placeholder="Affiliate ID" />
                            <mat-error>{{validationErrorMessages.affiliateIdInvalid}}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>  
            <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayout.xs="column"
                fxLayoutGap.xs="0em">
                <mat-form-field >
                    <input matInput type="text" class="form-control controlBackground change" formControlName="notificationEmail"
                                placeholder="Notification Email (optional)" />
                            <mat-error>{{validationErrorMessages.emailInvalid}}</mat-error>
                </mat-form-field>
                </div>          
        </form>
    </div>
    <div mat-dialog-actions class="actionbtn">
        <div class="statusBtn">
            <button class="uppercase" mat-button (click)="close($event)">Cancel</button>                    
            <button type="submit"   mat-button class="mat-button contained-button"
                [disabled]="addContractForm.invalid" *ngIf="!data.contractInfo" (click)="saveContract(event)">Create Contract</button>
                <button type="submit"   mat-button class="mat-button contained-button"
                [disabled]="addContractForm.invalid" *ngIf="data.contractInfo" (click)="saveContract(event)">Save Changes</button>
        </div>
    </div>
</div>
