<div class="call-summarizer-card-header">
    <h1 tabindex="1">Call Summarizer</h1>
    <h4 tabindex="1">Summarize your call transcript here for an email draft</h4>
</div>
<div *ngFor="let summarization of previousSummarizations; let i = index">
    <mat-card id="{{(i === (previousSummarizations.length -1)) || previousSummarizations.length === 1 ? 'latestCard' : 'oldCards'}}" class="call-summarizer-card">
        <p tabindex="1" class="notification-msg" *ngIf="summarization.transcriptTimeStamp || state.startTimeStamp"><strong>Call Transcript </strong>  <strong>•</strong>  {{summarization.transcriptTimeStamp ? summarization.transcriptTimeStamp : state.startTimeStamp}}</p>
        <textarea tabindex="1" class="generated-text-style input-fields-text" [disabled]="true" [(ngModel)]='summarization.transcript.textInput' matInput></textarea>
        <mat-card-content class="call-summarizer-card-content">
            <p tabindex="1" class="notification-msg draft" *ngIf="summarization.emailTimeStampMsg === 'Email Draft is ready!'"><strong>{{summarization.emailTimeStampMsg}}</strong></p>
            <p tabindex="1" class="notification-msg draft" *ngIf="summarization.emailTimeStampMsg !== 'Email Draft is ready!'"><strong>Email Draft </strong>  <strong>•</strong>{{summarization.emailTimeStampMsg}}</p>
            <div [ngClass]="{'focus-preview': focusCard && (i === previousSummarizations.length -1)}" class="preview-content">
                <p [innerHtml]="summarization.htmlSummary"></p>
            </div>
            <p tabindex="1" class="notification-msg"> Pro Tip: The email draft is computer-generated and may not capture the full context or nuances of the transcript. Please edit after copying.</p>
            <div *ngIf="!copyButtonStates[i]?.draftCopied" fxLayout="row" fxLayoutAlign="end center">
                <button tabindex="1" (click)="copyToClipBoard(i)" class="copy-btn">
                        <img class="copy-icon" src="../../../../assets/imgs/copy-icon.svg" aria-hidden="true" alt="Copy Icon" /> Copy
                </button>
            </div>
            <div *ngIf="copyButtonStates[i]?.draftCopied"  fxLayout="row" fxLayoutAlign="end center">
                <button tabindex="1" (click)="copyToClipBoard(i)" class="copied-btn">
                        <img class="copy-icon" src="../../../../assets/imgs/copied-icon.svg" aria-hidden="true" alt="Copied Icon" /> Draft Copied
                </button>
            </div>
        </mat-card-content>
    </mat-card>
</div>

<mat-card class="call-summarizer-card">
    <mat-card-content class="call-summarizer-card-content">
        <p tabindex="1" class="notification-msg" *ngIf="!state.inflight && !(this.callSummarizerForm?.get('textInput')?.value?.length > 0)"><strong>Call Transcript </strong>  <strong>•</strong>  Paste call transcription below to summarize in email draft.</p>
        <p tabindex="1" class="notification-msg" *ngIf="!state.inflight && (this.callSummarizerForm?.get('textInput')?.value?.length > 0)"><strong>Call Transcript </strong></p>
        <p tabindex="1" class="notification-msg" *ngIf="state.inflight"><strong>Call Transcript </strong>  <strong>•</strong>  {{state.startTimeStamp}}</p>
    <div class="form-container">
        <div *ngIf="(!(this.callSummarizerForm?.get('textInput')?.value?.length > 0)) && this.pasteButtonShow" class="paste-btn">
            <button tabindex="1" (click)="pasteContent()" class="paste-button">
                <span>Paste here</span>
            </button>
            <p tabindex="1">Then, click on the ‘Generate’ button to automatically create the email draft.</p>
        </div>
        <form fxLayout="row" [formGroup]="callSummarizerForm">
            <mat-form-field appearance="none">
                <textarea (focus)="focusFunction()" (focusout)="focusOutFunction()" [ngClass]="{'generated-text-style': state?.inflight === true}" [disabled]="!state?.inflight" tabindex="1" formControlName="textInput" matInput class="generate-text-style  input-fields-text"></textarea>
                <mat-error class="transcript-text-error">{{ getErrorMessage('textInput') }}</mat-error>
            </mat-form-field>
        </form>
        <button tabindex="1" *ngIf="state?.inflight !== true" [disabled]="!(this.callSummarizerForm.get('textInput')?.valid)" (click)="generate()" mat-button
            class="button-sm call-summarizer-button">
            <span>Generate</span>
        </button>
    </div>

    <p tabindex="1" *ngIf="state?.inflight !== true && (this.callSummarizerForm?.get('textInput')?.value?.length > 0)" class="notification-msg">Computer-generated email draft may not be accurate. Please check the the response.</p>
        <div *ngIf="state?.inflight === true">
            <p class="notification-msg draft"><strong>Email Draft </strong>  <strong>•</strong> The email for your transcript is being generated...please stay with us!</p>
            <div class="inflight-email-box">
            </div>
        </div>
        <div *ngIf="state?.inflight === true" fxLayout="row" fxLayoutAlign="end center">
            <button tabindex="1" disabled = "true" class="copy-btn dis-copy">
                    <img class="copy-icon" src="../../../../assets/imgs/disabled-copy-icon.svg" aria-hidden="true" alt="Copy Icon" /> Copy
            </button>
        </div>
    </mat-card-content>
</mat-card>
