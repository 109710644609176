/** MOV360-880 - to be removed at future date */
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApprovedMoveDetails } from '../models/approved-move';
import { CandidateBudgetDetails } from '../models/candidate-budget-details.model';
import { urlType } from '../models/urlType';
import { BaseClientService } from './base-client.service';
import { CandidateProfilesService } from './candidate-profiles.service';
import { LoggerService } from './logger.service';
import { RemoteLoggingService } from './remote-logging.service';

@Injectable({
  providedIn: 'root'
})
export class ApprovedMovesService {
  public counter = 0;
  constructor(
    private readonly baseClient: BaseClientService,
    private readonly logSvc: RemoteLoggingService,
    private readonly customLogger: LoggerService,
    private readonly candidateProfileSvc: CandidateProfilesService
  ) { }

  /** Return the candidate json list and loop to display in the table
   * @param clientContactId client contact id
   * @param queryString query string params
   */
  getApprovedMoves(queryString: string): Observable<any> {
    return this.baseClient.get<any>(`/candidate/authorizeTransferee?${queryString}`).pipe(
      map(r => r),
      catchError(err => {
        this.customLogger.error('getApprovedMoves:Failed to fetch data');
        const emptyArray: ApprovedMoveDetails = null;
        return of(emptyArray);
      })
    );
  }

  getApprovedMovesForFinance(queryString: string): Observable<any> {
    return this.baseClient.get<any>(`/candidate/financeAudit/authorizeTransferee?${queryString}`).pipe(
      map(r => r),
      catchError(err => {
        this.customLogger.error('getApprovedMoves:Failed to fetch data');
        const emptyArray: ApprovedMoveDetails = null;
        return of(emptyArray);
      })
    );
    // return this.baseClient.get<any>(
    //  `/candidate/financeAudit/authorizeTransferee/${clientContactId}?skip=${skipRecords}&limit=${pageSize}`)
    //   .pipe(
    //     map(r => r),
    //     catchError((err) => {
    //       return this.candidateProfileSvc.catchBlock('getApprovedMoves:Failed to fetch data', err, 'string');
    //     })
    //   );
  }

  getFinanceExports(queryString: string): Observable<any> {
    return this.baseClient.get<any>(`/v1/financialExport?${queryString}`, '', urlType.financemgmt).pipe(
      map(r => r),
      catchError(err => {
        this.customLogger.error('getFinanceExports:Failed to fetch data');
        const emptyArray = null;
        return of(emptyArray);
      })
    );
  }

  getAppovedMovesById(candidateId: string): Observable<CandidateBudgetDetails> {
    return this.baseClient.getById<CandidateBudgetDetails>(`/candidate/authorizeTransferee/${candidateId}`)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.customLogger.error('getAppovedMovesById: Failed to fetch transferee details');
          const empty: CandidateBudgetDetails = null;
          return of(empty);
        })
      );
  }

  getAddedHighValueItems(candidateId: string): Observable<Object> {
    return this.baseClient.get<Object>(`/hvg/getAddedItemList/${candidateId}`).pipe(
      map(r => r.body),
      catchError(err => {
        this.customLogger.error('Error Occured', err);
        const emptyArray: Object = {};
        return of(emptyArray);
      })
    );
  }

}
