<ngx-spinner type="ball-spin-clockwise-fade" size="medium">
</ngx-spinner>
<div class="authorize-dialog">
    <div mat-dialog-title disableTypography fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="20px"
        fxLayoutGap.xs="0em">
        <div class="candidate-dialogtitle">
            <span>Authorize Transfer:&nbsp;</span>
            <span class="candidate-name">{{ data.candidateName }}</span>
        </div>
        <a href="#" class="close-icon" id="close_icon" (click)="close($event)" title="close">
            <mat-icon>close</mat-icon>
        </a>
    </div>
    <div mat-dialog-content>
        <div class="start-date">
            <label class="label">Please enter a job start date:</label>
            <mat-form-field appearance="standard" floatLabel="auto" hideRequiredMarker>
                <input matInput [matDatepicker]="startDate" auto-complete="off" placeholder="Enter Date" readonly
                    required [(ngModel)]='jobStartOn' #jobStartDate (click)="startDate.open()">
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
            </mat-form-field>
        </div>
        <p class="note" fxHide.xs>Please send payment electronically,
            your transferee will not be authorized until we receive full payment and
            have received a job start date.</p>
        <div class="authorized-detail" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between"
            fxLayoutGap="20px" fxLayoutGap.xs="5px">
            <div fxFlex="50%" fxFlex.xs="100%" class="tabular">
                <div class="head">Invoice #: <span class="strong">{{invoiceDetails?.invoiceId}}</span></div>
                <dl class="invoice-breakup">
                    <div>
                        <dt>Core Benefits:</dt>
                        <dd>{{ data.coreBenefits | number }} USD</dd>
                    </div>
                    <div>
                        <dt>Flexible Spend:</dt>
                        <dd>{{ (data.amountDue - data.coreBenefits) | number }} USD</dd>
                    </div>
                    <div class="right">
                        <dt>Amount Due:</dt>
                        <dd class="strong">{{ data.amountDue | number }} USD</dd>
                    </div>
                    <div class="auto-size">
                        <dt>Tax Assistance:</dt>
                        <dd class="italic">{{ data.isTaxEnabled? 'Client Responsibility' : 'Not Included' }}</dd>
                    </div>
                </dl>
                <app-invoice-pdf class="action" *ngIf="invoiceDetails" [amountDue]="data.amountDue"
                    [authorizedTransferee]=false [invoiceDetails]="invoiceDetails"
                    [transfereeName]="data.candidateName"></app-invoice-pdf>
            </div>
            <div fxFlex="50%" fxFlex="100%" class="tabular">
                <div class="payment-instruction">
                    <div fxHide.xs class="head">Electronic Payment Instructions:</div>
                    <p class="note" fxHide fxShow.xs>Please send payment electronically,
                        your transferee will not be authorized until we receive full payment and
                        have received a job start date.</p>
                    <div class="box">
                        <address>
                            {{invoiceDetails?.epInfo[0].bankName}}<br>
                            {{invoiceDetails?.epInfo[0].addrLine1}}<br>
                            {{invoiceDetails?.epInfo[0].addrLine2}}
                        </address>
                        <div class="bank-detail">
                            <p>ABA / Routing Number: {{invoiceDetails?.epInfo[0].aba_RoutingNumber}}</p>
                            <p>{{invoiceDetails?.epInfo[0].accountName}}</p>
                            <p>Account Number: {{invoiceDetails?.epInfo[0].accountNumber}}</p>
                            <p>Swift/BIC: {{invoiceDetails?.epInfo[0].swift_BICCode}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p class="note strong center">You must inform us of your intent to authorize by using this form, <br>
            We will not accept payment without this authorization.</p>
    </div>
    <div mat-dialog-actions>
        <div class="statusBtn">
            <button class="uppercase" mat-button (click)="close($event)">Cancel</button>
            <button mat-button [disabled]="!jobStartOn" class="mat-button contained-button"
                (click)="authorizeTransferee()">Authorize</button>
        </div>
    </div>
</div>