/** MOV360-880 - to be removed at future date */
import { Component, OnInit, Input, OnChanges, ElementRef } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { SupplierContractService } from '../../../../core/services/supplier-contract.service';
import { SupplierContract } from 'src/app/core/models/contract-suppliers.model';
import { DatePipe } from '@angular/common';
import { AddContractComponent } from '../add-contract/add-contract.component';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { UserContactInfo } from 'src/app/core/models/user-contact-info.model';
import { OverlayRef, OverlayConfig, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { UserContactInfoComponent } from '../../finance-audit/user-contact-info/user-contact-info.component';
import { PopupPositionService } from 'src/app/core/services/popup-position.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-supplier-contract',
  templateUrl: './supplier-contract.component.html',
  styleUrls: ['./supplier-contract.component.scss']
})
export class SupplierContractComponent implements OnInit, OnChanges {
  @Input() contractData: any;
  @Input() contractIndex: Number;
  @Input() isManageSupplier: boolean;
  matExpansionHeaderHeight = '40px';
  mediasbscription: Subscription;
  isMobileView = false;
  contractState = 'Draft';
  isActivateContract = false;
  ContractButtonText = 'Submit for Review';
  showEdit = false;
  showStartDateEdit = false;
  showEndDateEdit = false;
  showEmailEdit = false;
  showAffiliationRelationEdit = false;
  showAffilateEdit = false;
  showAffilateIdEdit = false;
  dailyOperationData: any;
  oracleData: any;
  territoryData: any;
  contractStartDate: string;
  contractEndDate: string;
  hasDailyOperation = false;
  isActive = false;
  isDeactive = false;
  date = moment(new Date()).format('YYYY-MM-DD');
  overlayRef: OverlayRef;
  firstName: any;
  toolTipText = 'You must complete all sections to perform this action';
  hasTerritoryData = false;
  hasOracleData = false;

  constructor(private readonly mediaobserver: MediaObserver,
    private supplierContractService: SupplierContractService,
    private datePipe: DatePipe,
    private readonly positionService: PopupPositionService,
    private readonly overlay: Overlay,
    public dialog: MatDialog,
    private readonly router: Router,
  ) {
    this.mediasbscription = mediaobserver.asObservable().subscribe((val: MediaChange[]) => {
      if (val[0].mqAlias === 'xs') {
        this.isMobileView = true;
      } else {
        this.isMobileView = false;
      }


    });

  }
  formatDate(data) {
    this.contractStartDate = this.datePipe.transform(data.contractStartDate, 'yyyy-MM-dd');
    this.contractEndDate = this.datePipe.transform(data.contractEndDate, 'yyyy-MM-dd');
  }
  ngOnInit() {
    this.formatDate(this.contractData.contract);
    this.contractState = this.contractData.contractStatus ? this.contractData.contractStatus : 'Draft';

    if (this.contractState === 'Ready For Review') {
      this.isActivateContract = true;
      this.ContractButtonText = 'Activate Contract';
    }

  }

  canSubmit() {
    return !this.contractData.isBtnEnabled;
  }

  checkDailyOperationData() {
    this.supplierContractService.currentDailyOperationData.subscribe(data => {
      if (data.firstName) {
        this.dailyOperationData = data;
      }
    });
  }

  checkTerritoryData() {
    this.supplierContractService.currentTerritoryData.subscribe(data => {
      if (data.length > 0) {
        const arr = [];
        for (const d of data) {
          for (const s of d.supplierDiscounts) {
            arr.push(s);
          }
        }
        const checkDiscountType = arr.find(x => x.discountType === 'Regular');
        this.hasTerritoryData = checkDiscountType ? true : false;
      }
    });
  }

  checkOracleIds() {
    this.supplierContractService.currentOracleSiteData.subscribe(data => {
      if (data.length > 0) {
        const checkOracleActive = data.find(x => x.isSwitchActive === true);
        this.hasOracleData = checkOracleActive ? true : false;
      }
    });
  }

  ngOnChanges(): void {
    this.formatDate(this.contractData.contract);
  }

  openEditContract(event) {
    event.preventDefault();
    event.stopPropagation();
    const dialogRef = this.dialog.open(AddContractComponent, {
      disableClose: true,
      panelClass: ['dialogMainContainer', 'authorize-dialog-container'],
      data: { contractInfo: this.contractData.contract, index: this.contractIndex }
    });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.formatDate(result);

        }
      });

  }

  userInitials() {
    const userFullName = 'Jeenesh Suryavanshi';
    const parts = userFullName.split(' ');
    this.firstName = parts[0] ? parts[0] : '';
    let initials = '';
    for (let i = 0; i < 2; i++) {
      if (parts[i].length > 0 && parts[i] !== '') {
        initials += parts[i][0];
      }
    }
    return initials;
  }

  checkDailyOperation(check) {
    this.hasDailyOperation = check;
  }

  sendStatus(button) {
    if (button === 'Submit for Review') {
      this.isActive = true;
      this.contractState = 'Ready for Review';
      this.contractData.contractStatus = 'Ready for Review';
      this.ContractButtonText = 'Activate Contract';
    }
    if (button === 'Activate Contract') {
      this.isActive = true;
      this.contractState = 'Active';
      this.contractData.contractStatus = 'Active';
      this.ContractButtonText = 'Deactivate Contract';
    }
    if (button === 'Deactivate Contract') {
      this.isActive = true;
      this.isDeactive = true;
      this.contractState = 'Inactive';
      this.contractData.contractStatus = 'Inactive';
    }
  }

  userContactInfo(evt: MouseEvent) {
    evt.stopPropagation();
    const emptyInfo: UserContactInfo = {} as UserContactInfo;
    const userInfo = '';
    if (userInfo !== undefined) {
      emptyInfo.firstName = 'Desmond';
      emptyInfo.lastName = 'Miles';
      emptyInfo.emailAddress = 'ezioauditore@origin.com';
      emptyInfo.phoneNumber = '9898989898';
    }
    const rect = (evt.currentTarget as Element).getBoundingClientRect();
    const target = this.getPositionByEvents(rect);
    const element = new ElementRef(target);
    const positionStrategy = this.placeByPositionStrategyForUserInfo(element);
    const overlayConfig = new OverlayConfig({
      width: '',
      height: '',
      panelClass: 'dialogMainContainer',
      hasBackdrop: true,
      backdropClass: 'mat-backdrop-transparent',
      positionStrategy,
    });
    this.overlayRef = this.overlay.create(overlayConfig);
    const containerPortal = new ComponentPortal(UserContactInfoComponent, null, this.positionService.createInjector({
      data: emptyInfo,
      overlayRef: this.overlayRef
    }));
    this.overlayRef.attach(containerPortal);
    this.overlayRef.backdropClick().subscribe(() => {
      this.overlayRef.dispose();
    });
  }

  getPositionByEvents(rect) {
    return {
      getBoundingClientRect: () => ({
        bottom: rect.top + (rect.bottom - rect.top) / 2,
        height: rect.height,
        left: rect.left + (rect.right - rect.left) / 2,
        right: rect.left + (rect.right - rect.left) / 2,
        top: rect.top + (rect.bottom - rect.top) / 2,
        width: rect.width
      }),
    };
  }

  /** place the pop up by position strategy */
  placeByPositionStrategy(element: ElementRef) {
    return this.overlay
      .position()
      .flexibleConnectedTo(element)
      .withFlexibleDimensions(false)
      .withPositions([
        {
          originX: 'end',
          originY: 'center',
          overlayX: 'start',
          overlayY: 'center',
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'bottom',
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        }
      ]);
  }

  /** place the pop up by position strategy */
  placeByPositionStrategyForUserInfo(element: ElementRef) {
    return this.overlay
      .position()
      .flexibleConnectedTo(element)
      .withFlexibleDimensions(false)
      .withPositions([
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        }
      ]);
  }

}
