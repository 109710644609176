import { Address } from './address.model';
import { ClientRole } from './client-role';
import { ClientContact } from './client-contact';

/**interface for Client model */
export interface Client {
    /** Client Id */
    clientID?: string;
    /** Client Id */
    partyID?: string;
    /** Client preferred name used to display/address */
    clientPreferredName: string;
    /** Client name used for legal purposes */
    clientLegalName: string;
    /** Client address */
    clientAddress: Address;
    /** The client contact role of the client's billing contact */
    clientRole?: ClientRole[];
    /** Billing Contact Optional Field */
    billingContact?: ClientContact;
    /** Client TIN */
    taxID: string;
    /** The client status - Ready for Review, Active, Inactive */
    status: string;
    /** The client status last updated date */
    statusDate: string;
    /**member variable for createdDate */
    createdDate: string;
    /**member variable for createdBy */
    createdBy: string;
    /** The client deleted flag */
    isDeleted: boolean;
    /** variable to check executed function type */
    executedFunctionType?: string;
}

export enum ClientStatus {
    readyForReview = 'Ready for Review',
    active = 'Active',
    Inactive = 'Inactive'
  }
