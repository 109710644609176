<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<div class="authorize-dialog">
  <div mat-dialog-title disableTypography fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="20px"
    fxLayoutGap.xs="0em">
    <div class="candidate-dialogtitle">
      <span>Daily Operation Contact</span>
    </div>
    <a class="close-icon" id="close_icon" (click)="close($event)">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <div mat-dialog-content>
    <form fxLayout="column" class="middle-section form-group" [formGroup]="dailyOperationForm" novalidate>
      <div class="form-group middle-section space">
        <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em"
          fxLayout.xs="column" fxLayoutGap.xs="0em">
          <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <input matInput type="text" class="form-control" formControlName="firstname"
                placeholder="First Name" />
              <mat-error>{{ getErrorMessage('FIRST_NAME') }}</mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <input matInput type="text" class="form-control" formControlName="lastname"
                placeholder="Last Name" />
              <mat-error>{{ getErrorMessage('LAST_NAME') }}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayout.xs="column"
        fxLayoutGap.xs="0em">
          <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <input matInput type="email" class="form-control" formControlName="emailAddress"
                placeholder="Email Address" />
              <mat-error>
                {{ getErrorMessage('EMAIL_ADDRESS') }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="50%" fxLayout.xs="row" fxLayout="row" fxLayoutALign="space-between" fxFlexFill
            fxLayoutGap="1em" fxFlex.xs="100%">
            <div fxFlex="25%" fxFlex.xs="25%" class="extn-leftspacer">
              <mat-form-field>
                <mat-label>Extension</mat-label>
                <mat-select formControlName="Extension" [(value)]="selectedExt">
                  <mat-option *ngFor="let extn of extensions" [value]="extn.countryDialingCode">
                    {{extn.countryDialingCode}}
                  </mat-option>
                </mat-select>
                <mat-error>
                  {{ errors['Extension'] }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="75%" fxFlex.xs="75%" class="international-extn">
              <mat-form-field>
                <input matInput type="text" formControlName="phoneNumber" placeholder="Mobile Phone Number"
                  appInputPhoneFormat maxlength="10" onkeypress="return event.charCode >= 48 && event.charCode<=57"/>
                <mat-hint align="end" class="hint">Mobile # required to recieve text messeges</mat-hint>
                <mat-error>
                  {{ getErrorMessage('PHONE_NUMBER') }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
      </div>
      <div mat-dialog-actions>
          <div class="statusBtn">
            <button class="uppercase" mat-button (click)="close($event)">Cancel</button>
            <button type="submit" [disabled]="!canSaveContact()" mat-button class="mat-button contained-button"
              [disabled]="dailyOperationForm.invalid" (click)="save($event)">Save</button>
            <!-- <button type="submit" *ngIf="!isEmptySupplierInfo" mat-button class="mat-button contained-button"
              [disabled]="dailyOperationForm.invalid">
              Send Invitation</button> -->
          </div>
      </div>
    </form>
  </div>
</div>
