<ngx-spinner type="ball-spin-clockwise-fade" role="alert" aria-atomic="true" size="medium">
  <span class="cdk-visually-hidden">Loading, please wait...</span>
</ngx-spinner>

<div class="dialogMainContainer candidate-formcontainer" flexLayout fxLayout.xs="column">
  <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
    fxLayoutGap="20px" fxLayoutGap.xs="0em">
    <div class="candidate-dialogtitle">{{ title }}</div>
    <a href="#" class="close-icon" (click)="onNoClick($event)" [ngClass]="{'disable':formDisable==true}" title="close">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <form fxLayout="column" class="middle-section" [formGroup]="addCandidateForm">
    <div class="middle-section">
      <mat-dialog-content>
        <div>

          <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
            fxLayoutGap.xs="0em">
            <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
              <mat-form-field>
                <input matInput placeholder="First Name" formControlName="FirstName">
                <mat-error>
                  {{ getErrorMessage('FIRST_NAME') }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
              <mat-form-field>
                <input matInput placeholder="Last Name" formControlName="LastName">
                <mat-error>
                  {{ getErrorMessage('LAST_NAME') }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="input-row" class="addcandidate-toppadding" fxLayout="row" fxLayoutAlign="space-between"
            fxLayoutGap="1em" fxLayout.xs="column" fxLayoutGap.xs="0em">
            <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
              <mat-form-field fxFlex>
                <input matInput type="text" placeholder="Email Address" formControlName="Email"
                  (ngModelChange)="addValidators()" [readonly]="readOnlyField">
                <mat-error>
                  {{ getErrorMessage('EMAIL') }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="50%" fxLayout.xs="row" fxFlex.xs="100%" fxLayout="row" fxFlexFill
              fxLayoutAlign="space-between">
              <div fxFlex="30%" fxFlex.xs="30%" class="extn-leftspacer">
                <mat-form-field>
                  <mat-label>Extension</mat-label>
                  <mat-select formControlName="Extension" (selectionChange)="addValidators()">
                    <mat-option *ngFor="let extn of extensions" [value]="extn.countryDialingCode">
                      {{extn.countryDialingCode}}
                    </mat-option>
                  </mat-select>
                  <mat-error>
                    {{ errors['Extension'] }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div fxFlex="70%" fxFlex.xs="70%" class="international-extn">
                <mat-form-field fxFlex>
                  <input matInput appInputPhoneFormat type="text" placeholder="Mobile Phone Number"
                    formControlName="PhoneNumber" (ngModelChange)="addValidators()" [readonly]="readOnlyField"
                    maxlength="10">
                  <mat-hint class="hint">Mobile # required to receive text messages</mat-hint>
                  <mat-error
                    *ngIf="addCandidateForm.controls['PhoneNumber'].hasError('pattern')||
                    addCandidateForm.controls['PhoneNumber'].hasError('minlength') || addCandidateForm.controls['PhoneNumber'].hasError('alphabets') ">
                    {{ getErrorMessage('PHONE_NUMBER') }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0px"
            class="addcandidate-toppadding">
            <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
              <mat-form-field>
                <mat-label>Level</mat-label>
                <mat-select formControlName="Level" (selectionChange)="addValidators()">
                  <mat-option *ngFor="let level of levels" [value]="level.levelName">
                    {{level.levelName}}
                  </mat-option>
                </mat-select>
                <mat-error>
                  {{ errors['Level'] }}
                </mat-error>

              </mat-form-field>
            </div>
            <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
              <mat-form-field>
                <input matInput placeholder="Business Unit (optional)" formControlName="BusinessUnit">
              </mat-form-field>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayoutGap.xs=".3em"
            class="addcandidate-toppadding">
            <div fxFlex="50%" fxLayoutAlign="start" fxFlex.xs="40%">
              <mat-form-field>
                <input type="text" (keydown.tab)="onDeptTabOut()" placeholder="Departure (optional)" matInput
                  formControlName="Departure" [matAutocomplete]="departureList" [readonly]="readOnlyField"
                  (input)="onDeptInput(addCandidateForm.controls['Departure'].value)">
                <mat-spinner *ngIf="showDepartureSpinner" matSuffix strokeWidth="1" [diameter]="20"></mat-spinner>
                <mat-autocomplete id="departure" #departureList="matAutocomplete"
                  (closed)='onDepartureSelectionClosed()' (optionSelected)='onDepartureSelected()'>
                  <mat-option *ngFor="let departure of departures" [value]="departure.city +', '+ departure.state">
                    {{departure.city +', '+ departure.state}}
                  </mat-option>
                </mat-autocomplete>

                <mat-error *ngIf="addCandidateForm.controls['Departure'].hasError('invalidAddress')">
                  {{ errors['NoResults'] }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="5%" fxFlex.xs="15%">
              <mat-icon class="material-icons modal-rightnavarrow">chevron_right</mat-icon>
            </div>
            <div fxFlex="45%" fxFlex.xs="40%">
              <mat-form-field>
                <input type="text" (keydown.tab)="onDestTabOut()" placeholder="Destination" matInput
                  formControlName="Destination" [matAutocomplete]="destinationList" [readonly]="readOnlyField"
                  (input)="onDestInput(addCandidateForm.controls['Destination'].value)">
                <mat-spinner *ngIf="showDestinationSpinner" matSuffix strokeWidth="1" [diameter]="20"></mat-spinner>
                <mat-autocomplete id="destination" #destinationList="matAutocomplete"
                  (closed)='onDestinationSelectionClosed()' (optionSelected)='onDestinationSelected()'>
                  <mat-option *ngFor="let destination of destinations"
                    [value]="destination.city +', '+ destination.state">
                    {{destination.city +', '+ destination.state}}
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="addCandidateForm.controls['Destination'].hasError('required')">
                  {{ errors['Destination'] }}
                </mat-error>
                <mat-error *ngIf="addCandidateForm.controls['Destination'].hasError('sameAddress')">
                  {{ errors['DeptDestSame'] }}
                </mat-error>
                <mat-error *ngIf="addCandidateForm.controls['Destination'].hasError('invalidAddress')">
                  {{ errors['NoResults'] }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>


        </div>
      </mat-dialog-content>
    </div>
    <mat-dialog-actions>
      <div class="statusBtn add-dialogbtn">
        <button [disabled]="formDisable" type="button" class="mat-button text-button" (click)="onNoClick($event)"
          mat-button color="primary" id="cancel">CANCEL</button>
        <button *ngIf="(!transferreFlag || isPendingVanlineQuote) && !isSaveChanges" mat-button
          class="mat-button outlined-button" (click)="saveDraft()" id="addEditSave"
          [disabled]="!addCandidateForm.valid || showDestinationSpinner || showDepartureSpinner || disabled">Save
          Draft</button>
        <button *ngIf="(!transferreFlag || isPendingVanlineQuote) && isSaveChanges" mat-button
          class="mat-button outlined-button" (click)="saveDraft()" id="addEditSave"
          [disabled]="!addCandidateForm.valid || showDestinationSpinner || showDepartureSpinner
          ||(data.candidateDetails.status==='Invitation Sent'&&(!isEmailValid||!isDestinationValid||!isPhoneNumberValid))|| disabled">Save
          Changes</button>
        <button *ngIf="(!transferreFlag && !isInvitationSent)||isPendingVanlineQuote"
          [disabled]="!(addCandidateForm.valid && isEmailValid && isLevelValid && isDestinationValid && isPhoneNumberValid  && isExtensionValid) || disabled "
          mat-button class="mat-button contained-button" (click)="sendInvite()" id="addEditSendInvite">Send
          Invite</button>
        <button *ngIf="!transferreFlag && isInvitationSent"
          [disabled]="!(addCandidateForm.valid && isEmailValid && isLevelValid && isDestinationValid && isPhoneNumberValid  && isExtensionValid) || disabled"
          mat-button class="mat-button outlined-button" (click)="resendInvite()" id="addEditSendInvite">Resend</button>
      </div>
    </mat-dialog-actions>
  </form>
</div>