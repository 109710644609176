/** MOV360-880 - to be removed at future date */
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import {
  cartusAddress,
  invoice,
  invoiceNumber,
  invoiceDate,
  paymentTerms,
  totalAmountDue,
  invoiceCurrency,
  budgetCurrency,
  invoiceTo,
  transferee,
  approvedMoveBudget,
  moveOrder,
  paymentRemit,
  electronicPayment,
  thankyouMsg,
  invoiceMsg,
  paymentType,
  payTerms, routingNumber, accountNumber, swiftNumber
} from '../../../core/models/constants';
import { PdfFontsService } from '../../services/pdf-fonts.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { BudgetDetailsService } from '../../services/budget-details.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { formatNumber, DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-invoice-pdf',
  templateUrl: './invoice-pdf.component.html',
  styleUrls: ['./invoice-pdf.component.scss'],
  providers: [DecimalPipe]
})
export class InvoicePdfComponent implements OnInit, OnDestroy {

  /**property for amountDue */
  @Input() amountDue: string;
  /**property for Transferee Name */
  @Input() transfereeName: string;
  /**property to check  authorizedTransferee*/
  @Input() authorizedTransferee: boolean;
  /**property to check  invalid budget*/
  @Input() invalidBudget: boolean;
  /**property for candidateId*/
  @Input() candidateId: string;
  /**property for invoice details */
  @Input() invoiceDetails: any;
  /**property for invoice Date */
  invoiceDate: string;
  /**property for  Client contact name  */
  clientContactName: string;
  /**property for payment Instructions */
  paymentInstructions: any;
  /**property for invoiceNumber */
  invoiceNumber: string;
  /**property for ClientAddress */
  clientAddress: any;
  /**property for Move Order Number */
  moveOrder: string;
  /**property to get date*/
  date: Date;
  /**property to store clientName */
  clientName: string;
  dollars: any;
  cents: any;
  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();

  constructor(private readonly pdfMakeFonts: PdfFontsService,
    private readonly spinner: NgxSpinnerService,
    private readonly snackBar: MatSnackBar,
    private readonly budgetDetailsService: BudgetDetailsService,
    private decimalPipe: DecimalPipe) {
    pdfMakeFonts.defineFonts();
    pdfMakeFonts.assignFonts();
  }

  ngOnInit() {
    this.amountDue = this.decimalPipe.transform(parseInt(this.amountDue, 10).toFixed(2), '1.2-2');
    this.getPdfContents();
  }
  /**
   * format current date
   */
  dateFormat() {
    const monthNames = ['Jan', 'Feb', 'Mar',
      'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep',
      'Oct', 'Nov', 'Dec'];
    const day = this.date.getDate();
    const monthIndex = this.date.getMonth();
    const year = this.date.getFullYear();
    return `${day} ${monthNames[monthIndex]} ${year}`;
  }

  /**
   * get pdf contents for invoice pdf.
   */
  getPdfContents() {
    if ( this.invoiceDetails ) {
      this.paymentInstructions = this.invoiceDetails.epInfo[0];
      this.invoiceNumber = this.invoiceDetails.invoiceId;
      this.clientAddress = this.invoiceDetails.clientAddress;
      this.clientContactName = this.invoiceDetails.clientContactName.replace(',', '');
      this.moveOrder = this.invoiceDetails.moveOrderId;
      this.clientName = this.invoiceDetails.clientName;
      this.date = new Date();
      if (this.authorizedTransferee) {
        this.date = new Date(this.invoiceDetails.invoiceDate);
        this.clientContactName = this.invoiceDetails.authorizedBy;
      }
      this.invoiceDate = this.dateFormat();
    }
  }

  /**
   * Creates template for the invoice pdf.
   */
  createDocumentDefinition() {
    const baseDocDefinition = {
      content: [
        {
          image: this.pdfMakeFonts.cartusLogo(),
          width: 212,
          height: 93
        },
        {
          columns: [
            {
              fontSize: 10,
              width: '40%',
              lineHeight: 0.5,
              margin: [0, 20, 0, 20],
              text: [
                { text: cartusAddress.name + '\n\n' },
                { text: cartusAddress.addLine1 + '\n\n' },
                { text: cartusAddress.addLine2 + '\n\n' },
                { text: cartusAddress.addLine3 + '\n\n' },
                { text: cartusAddress.website + '\n\n' },
                { text: cartusAddress.fax },
              ]
            },
            {
              table: {
                headerRows: 1,
                widths: ['50%', '50%'],
                body: [
                  [
                    { text: invoice, alignment: 'center', bold: true, colSpan: 2, fillColor: '#D0D0D0' },
                    {}
                  ],
                  [
                    { text: invoiceNumber, style: 'tableLeftContent', border: [true, false, false, false], lineHeight: 0.75, },
                    { text: this.invoiceNumber, style: 'tableRightContent', border: [false, false, true, false], lineHeight: 0.75, },
                  ],
                  [
                    { text: invoiceDate, style: 'tableLeftContent', border: [true, false, false, false], lineHeight: 0.75, },
                    { text: this.invoiceDate, style: 'tableRightContent', border: [false, false, true, false], lineHeight: 0.5, },
                  ],
                  [
                    { text: paymentTerms, style: 'tableLeftContent', border: [true, false, false, false], lineHeight: 0.75, },
                    { text: paymentType, style: 'tableRightContent', border: [false, false, true, false], lineHeight: 0.75, },
                  ],
                  [
                    { text: totalAmountDue, style: 'tableLeftContent', border: [true, false, false, false], lineHeight: 0.75, },
                    { text: this.amountDue, style: 'tableRightContent', border: [false, false, true, false], lineHeight: 0.75, },
                  ],
                  [
                    { text: invoiceCurrency, style: 'tableLeftContent', border: [true, false, false, true], lineHeight: 2.5, },
                    { text: budgetCurrency, style: 'tableRightContent', border: [false, false, true, true], lineHeight: 2.5, },
                  ]
                ]
              },
            }
          ],
        },
        {
          margin: [0, 20, 0, 0],
          table: {
            headerRows: 1,
            widths: ['100%'],
            body: [
              [{ text: invoiceTo, alignment: 'left', italics: true, bold: true, fillColor: '#D0D0D0' }],
              [{ text: this.clientName, style: 'tableLeftContent', border: [true, false, true, false], lineHeight: 0.5 }],
              [{
                text: this.clientAddress.streetAddress + ', ', style: 'tableLeftContent',
                border: [true, false, true, false], lineHeight: 0.5
              }],
              [{
                text: `${this.clientAddress.city}, ${this.clientAddress.state}`,
                style: 'tableLeftContent', border: [true, false, true, false], lineHeight: 0.60
              }],
              [{
                text: `${this.clientAddress.zipcode}, ${this.clientAddress.country}`,
                style: 'tableLeftContent', border: [true, false, true, false], lineHeight: 0.75
              }],
              [{ text: 'Attn: ' + this.clientContactName, style: 'tableLeftContent', border: [true, false, true, false], lineHeight: 1.5 }],
            ]
          }
        },
        {
          table: {
            headerRows: 1,
            widths: ['20%', '80%'],
            body: [
              [{ text: '', fillColor: '#D0D0D0', colSpan: 2, margin: [0, 10, 0, 0] }, {}],
              [
                { text: transferee, style: 'tableLeftContent', border: [true, false, false, false], lineHeight: 0.75, },
                { text: this.transfereeName.trim(), style: 'tableLeftContent', border: [false, false, true, false], lineHeight: 0.75, },
              ],
              [
                { text: moveOrder, style: 'tableLeftContent', border: [true, false, false, false], lineHeight: 2, },
                { text: this.moveOrder, style: 'tableLeftContent', border: [false, false, true, false], lineHeight: 2, },
              ],
            ]
          }
        },
        {
          table: {
            headerRows: 1,
            widths: ['50%', '50%'],
            body: [
              [{ text: approvedMoveBudget, style: 'tableRightContent', border: [true, false, false, false], lineHeight: 1.75, },
              {
                text: `${this.amountDue} ${budgetCurrency}`, style: 'tableLeftContent', border: [false, false, true, false],

                lineHeight: 1.75, margin: [50, 0, 0, 0]
              }],
              [{
                text: totalAmountDue, style: 'tableRightContent', border: [true, false, false, true], lineHeight: 1.5,
                fontSize: 17, bold: true
              },
              {
                text: `${this.amountDue}       ${budgetCurrency}`, style: 'tableLeftContent', border: [false, false, true, true],

                lineHeight: 1.5, margin: [50, 0, 0, 0], fontSize: 17, bold: true
              }],
            ]
          }
        },
        {
          margin: [0, 20, 0, 5],
          table: {
            headerRows: 1,
            widths: ['100%'],
            body: [
              [{ text: paymentRemit, alignment: 'left', italics: true, bold: true, fillColor: '#D0D0D0' }],
              [{
                border: [true, true, true, false],
                table: {
                  headerRows: 1,
                  widths: ['100%'],
                  body: [
                    [{
                      text: electronicPayment, border: [false, false, false, false], decoration: 'underline',
                      lineHeight: 1, style: 'tableLeftContent', margin: [10, 0, 0, 0]
                    }],
                    [{
                      text: this.paymentInstructions.bankName, border: [false, false, false, false],
                      lineHeight: 0.5, style: 'tableLeftContent', margin: [10, 0, 0, 0]
                    }],
                    [{
                      text: this.paymentInstructions.addrLine1, border: [false, false, false, false],
                      lineHeight: 0.5, style: 'tableLeftContent', margin: [10, 0, 0, 0]
                    }],
                    [{
                      text: this.paymentInstructions.addrLine2, border: [false, false, false, false],
                      lineHeight: 0.5, style: 'tableLeftContent', margin: [10, 0, 0, 0]
                    }],
                    [{
                      text: `${routingNumber} ${this.paymentInstructions.aba_RoutingNumber}`,
                      border: [false, false, false, false], lineHeight: 0.5, style: 'tableLeftContent', margin: [10, 0, 0, 0]
                    }],
                    [{
                      text: this.paymentInstructions.accountName, border: [false, false, false, false],
                      lineHeight: 0.5, style: 'tableLeftContent', margin: [10, 0, 0, 0]
                    }],
                    [{
                      text: accountNumber + this.paymentInstructions.accountNumber, border: [false, false, false, false],
                      lineHeight: 0.5, style: 'tableLeftContent', margin: [10, 0, 0, 0]
                    }],
                    [{
                      text: swiftNumber + this.paymentInstructions.swift_BICCode, border: [false, false, false, false],
                      lineHeight: 1, style: 'tableLeftContent', margin: [10, 0, 0, 0]
                    }],

                  ]
                }
              }],
              [{
                border: [true, false, true, true],
                table: {
                  headerRows: 1,
                  widths: ['18%', '82%'],
                  body: [
                    [{
                      text: paymentTerms, style: 'tableLeftContent', decoration: 'underline',
                      border: [false, false, false, false], margin: [10, 0, 0, 0]
                    },
                    {
                      text: payTerms,
                      style: 'tableLeftContent', border: [false, false, false, false], margin: [0, 0, 0, 20],
                      lineHeight: 0.5, font: 'AvenirBlack', normal: true
                    }],
                  ]
                }
              }]
            ]
          }
        },
        {
          text: `${cartusAddress.addLine1} ${cartusAddress.addLine2} ${cartusAddress.addLine3}`,
          fontSize: 8, alignment: 'center', lineHeight: 2,
        },
        { text: thankyouMsg, fontSize: 22, alignment: 'center' },
        { text: invoiceMsg, fontSize: 12, alignment: 'center' },
      ],
      styles: {
        tableLeftContent: {
          alignment: 'left',
          fontSize: 10,
        },

        tableRightContent: {
          alignment: 'right',
          fontSize: 10
        },
      },
      defaultStyle: {
        font: 'AvenirMedium',
        normal: true
      },
      pageOrientation: 'portrait',
      pageSize: 'A4',

    };
    return JSON.parse(JSON.stringify(baseDocDefinition));
  }

  /**
   *method to call generate pdf
   */
  exportToPDF() {
    const docDefinition = this.createDocumentDefinition();
    pdfMake.createPdf(docDefinition).download(this.invoiceNumber + '.pdf');
  }

  /**
   * destroys the object
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
