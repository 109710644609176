/** MOV360-880 - to be removed at future date */
import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  HostListener,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { BudgetDetailsService } from '../../../core/services/budget-details.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CandidateContactInfo } from '../../../core/models/candidate-contact-info.model';
import { CandidateInfo } from '../../../core/models/candidate-info.models';
import { CandidateMoveInfo } from '../../../core/models/candidate-move-info.model';
import { CandidateCoreBenefits } from '../../../core/models/candidate-core-benifits.models';
import { TaxAssistance } from '../../../core/models/tax-assistance.modal';
import { DepatureHomeDetails } from '../../../core/models/depature-home-details.models';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { OverlayConfig, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { OverlayTooltipComponent } from '../../../core/components/overlay-tooltip/overlay-tooltip.component';
import { PopupPositionService } from '../../../core/services/popup-position.service';
import { formatNumber } from '@angular/common';
import { AuthorizeTransferComponent } from './authorize-transfer/authorize-transfer.component';
import { APIResponse } from '../../../core/models/response.model';
import {
  notice, taxInfoIncluded, warning, taxInfoNotIncluded, apiErrorMessage, budgetCurrency, taxIncludedMessgae, taxNotIncludedMessage
} from '../../../core/models/constants';
import { CandidateBudgetDetails } from '../../../core/models/candidate-budget-details.model';
import { CandidateFlexSpend } from '../../../core/models/candidate-flex-spend.model';
import { RelocationOfferInfo } from '../../../core/models/relo-offer.model';
import { PartySharedService } from '../../../core/services/party-shared.service';
import { LoggerService } from '../../../core/services/logger.service';
import { UserContextService } from '../../../core/services/user-context.service';

/** base component for budget details */
@Component({
  selector: 'app-budget-details',
  templateUrl: './budget-details.component.html',
  styleUrls: ['./budget-details.component.scss'],
  viewProviders: [MatExpansionPanel]
})
export class BudgetDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
  /** used to store candidate info */
  candidateBudgetDetails: CandidateBudgetDetails;
  /**candidateInfo of type CandidateInfo */
  candidateInfo: any;
  /**candidateContactInfo of type CandidateContactInfo */
  candidateContactInfo: CandidateContactInfo;
  /**candidateMoveInfo of type CandidateMoveInfo */
  candidateMoveInfo: CandidateMoveInfo;
  /** stores candidate departure home details information */
  candidateDepartureInfo: DepatureHomeDetails;
  /** stores core benefits details */
  coreBenefits: CandidateCoreBenefits;
  /** stores flex spend details */
  flexSpendDetails: CandidateFlexSpend;
  /** stores flex and core service total */
  budgetChartDetails;
  /** default departure address */
  departureDefaultAddress = 'Danbury, CT';
  /** Holds the candidate name*/
  candidateName: string;
  /** stores currency */
  currency = budgetCurrency;
  /** form for move budget details */
  budgetForm: UntypedFormGroup;
  /** sets flag if move budget lesser than recommended */
  invalidBudget = false;
  /** least recommended move budget */
  minRelocationInvestment;
  /** acceptable move budget value */
  acceptableRelocationInvestment;
  /** sets mode to edit/undo */
  mode = 'edit';
  /** overlayRef to hold overlay config */
  overlayRef: OverlayRef;
  /** screenWidth to hold screen width */
  screenWidth: any;
  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /**flag for check box */
  ifChecked = true;
  /**flag for toggle bar */
  ifSelectedSwitch = true;
  /**property for total expense */
  recommendedMoveBudget;
  /**for storing tax rate */
  taxAssistanceDefault;
  /**property to store total budget amount*/
  totalAmount;
  /**property to have the amount with USD */
  totalBudget;
  /** variable to store the move budget */
  moveBudget;
  /**disable tax for invalid value */
  taxDisable = false;
  /**property to store message for tax included */
  taxIncludedMessgae = taxIncludedMessgae;
  /**property to store message for tax excluded */
  taxNotIncludedMessage = taxNotIncludedMessage;

  /**flag to check whether transferee is authorized or not */
  authorizedTransferee = false;
  /**mat accordion panel header height */
  matExpansionHeaderHeight = '40px';
  /** date of transferee auhtorization */
  authorizedDate;
  /**property to store invoice details */
  invoiceDetails: any;
  /**property to store candidateId */
  candidateId: string;
  /** Stores the client contact ID */
  clientContactId: string;
  /**property to store RelocationDetails */
  relocationDetails: RelocationOfferInfo = {} as RelocationOfferInfo;
  /** to store core budget */
  coreBudget;
  /** property to give readonly access */
  disableButton = false;
  /** used to store flex spend total */
  flexSpend;
  /**
   * Breadcrumb to be focused first
   */
  @ViewChild('breadcrumb', { static: false }) breadcrumb: ElementRef;
  /**
   * Injecting the dependencies
   * @param route Instance of ActivatedRoute
   * @param budgetSrvc Instance of BudgetService
   * @param spinner Instance of NgxSpinnerService
   * @param formBuilder FormBuilder variable
   * @param overlay Overlay injection
   * @param positionService PopupPositionService injection
   * @param snackBar to display snack bar
   * @param dialog Matdialog object
   * @param myElement ElementRef instance
   */
  constructor(
    private readonly route: ActivatedRoute,
    private readonly budgetSrvc: BudgetDetailsService,
    private readonly spinner: NgxSpinnerService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly overlay: Overlay,
    private readonly positionService: PopupPositionService,
    private readonly partySharedSvc: PartySharedService,
    public dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private readonly myElement: ElementRef,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: UserContextService
  ) {
    this.budgetForm = this.formBuilder.group({
      MoveBudget: ['', Validators.compose([
        Validators.pattern('^[0-9,]*[0-9]*$'),
        Validators.maxLength(20)
      ])
      ]
    });
    this.getScreenSize();
  }
  /** To initialise the component */
  ngOnInit() {
    this.spinner.show();
    this.partySharedSvc.getPartyId().then(id => {
      if (id) {
        this.clientContactId = id;
        this.route.paramMap.subscribe((params: ParamMap) => {
          this.candidateId = params.get('candidateId');
          if (this.candidateId) {
            this.subscription.add(
              this.budgetSrvc
                .getCandidateBudget(this.candidateId)
                .subscribe((candidateBudget: CandidateBudgetDetails) => {
                  if (candidateBudget) {
                    this.candidateBudgetDetails = candidateBudget;
                    this.getCandidateBudgetDetails(this.candidateBudgetDetails);
                    this.setCandidateName(this.candidateBudgetDetails);
                    this.recommendedMoveBudget =
                      Math.ceil(this.candidateBudgetDetails.coreBudgetTotal + this.candidateBudgetDetails.flexSpendTotal);
                    this.coreBudget = Math.ceil(this.candidateBudgetDetails.coreBudgetTotal);
                    this.flexSpend = Math.ceil(this.candidateBudgetDetails.flexSpendTotal);
                    this.moveBudget = this.candidateBudgetDetails.UserBudget ?
                      Number(this.candidateBudgetDetails.UserBudget) : this.recommendedMoveBudget;
                    this.budgetForm.controls['MoveBudget'].setValue(
                      formatNumber(Math.ceil(this.moveBudget), 'en-US')
                    );
                    this.ifSelectedSwitch =
                      this.candidateBudgetDetails.isTaxEnabled !== undefined ? this.candidateBudgetDetails.isTaxEnabled : true;
                    this.minRelocationInvestment = this.candidateBudgetDetails.coreBudgetTotal;
                    this.acceptableRelocationInvestment = Math.ceil(
                      this.recommendedMoveBudget * 1.25
                    );
                    this.taxAssistanceDefault = this.calculateTaxAssistance(
                      this.moveBudget
                    );
                    this.totalAmount = this.ifSelectedSwitch ?
                      this.moveBudget + this.taxAssistanceDefault : this.moveBudget;
                    this.formatTotalAmount(this.totalAmount);
                    this.getInvoiceDetails(this.candidateBudgetDetails.candidate.createdBy, this.candidateId);
                    this.loggedInUserService.getLoggedInUserDetails()
                      .subscribe(response => {
                        const userId: any = response.userId.replace(/ .*/, '');
                        this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-BUDGET_DETIALS');
                      });
                  } else {
                    this.spinner.hide();
                    this.snackBar.open(
                      apiErrorMessage,
                      undefined,
                      { duration: 5000 }
                    );
                  }
                })
            );
          } else {
            this.spinner.hide();
          }
        });
      } else {
        this.spinner.hide();
      }
    }, err => {
      this.spinner.hide();
    });
  }

  /** method to get invoiceDetails for pdf generation*/
  getInvoiceDetails(clientContactId: string, candidateId: string) {
    this.subscription.add(
      this.budgetSrvc.getInvoicePdfDetails(clientContactId, candidateId).subscribe(res => {
        this.spinner.hide();
        if (res && res.status === 204) {
          this.invoiceDetails = {};
        } else if (res && res.status === 200 && res.body) {
          this.invoiceDetails = res.body;
          this.getRelocationDetails();
        } else {
          this.snackBar.open(
            apiErrorMessage,
            undefined,
            { duration: 5000 }
          );
        }
      })
    );
  }
  /** Get screen width */
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenWidth = window.innerWidth;
  }
  /**
   * populated candidate info variables
   * @param candidateBudget candidateBudget of typeCandidateBudgetDetails
   */
  getCandidateBudgetDetails(candidateBudget: CandidateBudgetDetails) {
    candidateBudget.flexSpendTotal = candidateBudget.flexSpendTotal === null ? 0 : candidateBudget.flexSpendTotal;
    this.candidateContactInfo = {
      candidateName: candidateBudget.candidate.fullname,
      emailId: candidateBudget.candidate.emailAddress,
      phoneNumber: candidateBudget.candidate.phoneNumber,
      countryDialingCode: candidateBudget.candidate.countryDialingCode
    };
    this.candidateInfo = {
      level: candidateBudget.candidate.level,
      businessUnit: candidateBudget.candidate.businessUnit
    };
    this.candidateMoveInfo = {
      departure: {
        fullAddress: candidateBudget.candidate.departure.fullAddress,
        streetAddress: candidateBudget.candidate.departure.streetAddress,
        city: candidateBudget.candidate.departure.city,
        state: candidateBudget.candidate.departure.state,
        zipcode: candidateBudget.candidate.departure.zipcode,
        country: candidateBudget.candidate.departure.country
      },
      destination: {
        fullAddress: candidateBudget.candidate.destination.fullAddress,
        streetAddress: candidateBudget.candidate.destination.streetAddress,
        city: candidateBudget.candidate.destination.city,
        state: candidateBudget.candidate.destination.state,
        zipcode: candidateBudget.candidate.destination.zipcode,
        country: candidateBudget.candidate.destination.country
      },
      totalNumberOfPeople: candidateBudget.needsAssessment.familyDetails.noOfRelocatePeople,
      estimatedMoveStartDate: candidateBudget.needsAssessment.estimatedMoveStartDate,
      estimatedMoveEndDate: candidateBudget.needsAssessment.estimatedMoveEndDate
    };
    this.candidateDepartureInfo = {
      departure: {
        fullAddress: candidateBudget.candidate.departure.fullAddress,
        streetAddress: candidateBudget.candidate.departure.streetAddress,
        city: candidateBudget.candidate.departure.city,
        state: candidateBudget.candidate.departure.state,
        zipcode: candidateBudget.candidate.departure.zipcode,
        country: candidateBudget.candidate.departure.country
      },
      ownerStatus: candidateBudget.needsAssessment.residenceDetails.ownerStatus,
      housingType: candidateBudget.needsAssessment.residenceDetails.homeType,
      noOfRooms: candidateBudget.needsAssessment.residenceDetails.noOfRooms.toString(),
      hasHighValueGoods: candidateBudget.hasHighValueGoods
    };
    this.coreBenefits = {
      coreBenefit: candidateBudget.coreBenefits,
      coreBenefitsTotal: Math.ceil(candidateBudget.coreBudgetTotal)
    };
    this.flexSpendDetails = {
      flexSpend: candidateBudget.flexSpend,
      flexSpendTotal: Math.ceil(candidateBudget.flexSpendTotal)
    };
    this.budgetChartDetails = {
      coreBenefitsTotal: Math.ceil(candidateBudget.coreBudgetTotal),
      flexSpendTotal: Math.ceil(candidateBudget.flexSpendTotal),
      title: 'Budget Details',
      class: 'budget-Details'
    };
    if (candidateBudget.candidate.status === 'Payment Pending') {
      this.authorizedTransferee = true;
      this.authorizedDate = candidateBudget.authorizedDate;
      this.taxDisable = true;
      this.budgetForm.disable();
      this.mode = '';
    }
  }

  /** sets Candidate Name */
  setCandidateName(candidateBudget: CandidateBudgetDetails) {
    this.candidateName =
      `${this.candidateContactInfo.candidateName.split(',')[1]} ${this.candidateContactInfo.candidateName.split(',')[0]}`;
  }

  /** checks if move budget entered is valid or not */
  checkBudget() {
    if (this.budgetForm.controls['MoveBudget'].valid) {
      if (Number(this.budgetForm.controls['MoveBudget'].value)) {
        this.moveBudget = Math.ceil(Number(this.budgetForm.controls['MoveBudget'].value));
      } else {
        this.moveBudget = Math.ceil(Number(
          this.budgetForm.controls['MoveBudget'].value.replace(/[,]/g, '')
        ));
      }
      if (this.moveBudget === this.recommendedMoveBudget) {
        this.invalidBudget = false;
        this.taxDisable = false;
        this.updateTotalExpense(this.moveBudget);
        // service call
        this.updateMoveBudget(this.moveBudget);
        this.mode = 'edit';
      } else if (this.moveBudget < this.minRelocationInvestment) {
        this.invalidBudget = true;
        this.mode = 'undo';
        this.budgetForm.controls['MoveBudget'].setErrors({ valueError: true });
        this.taxDisable = true;
        // call overlay tooltip with message:
        // `Your relocation investment needs to be equal to or exceed the cost associated to the Core Benefits`
        this.openTooltipDialog('budgetInvalid');
        this.ifSelectedSwitch = false;
      } else if (this.moveBudget > this.acceptableRelocationInvestment) {
        this.invalidBudget = false;
        this.mode = 'undo';
        this.budgetForm.controls['MoveBudget'].setErrors({ valueError: true });
        this.taxDisable = false;
        this.updateTotalExpense(this.moveBudget);
        // call overlay tooltip with message:
        // `You have exceeded the Recommended Move Budget by 25% or more.`
        this.openTooltipDialog('budgetExceeded');
        // service call
        this.updateMoveBudget(this.moveBudget);
        this.updateTotalExpense(this.moveBudget);
      } else {
        this.invalidBudget = false;
        this.mode = 'undo';
        this.taxDisable = false;
        this.updateTotalExpense(this.moveBudget);
        // service call
        this.updateMoveBudget(this.moveBudget);
      }
    } else {
      this.invalidBudget = true;
      this.mode = 'undo';
      this.budgetForm.controls['MoveBudget'].setErrors({ valueError: true });
      this.taxDisable = true;
      // call overlay tooltip with message:
      if (this.budgetForm.controls['MoveBudget'].value.match(/^[0]+$/) !== null) {
        this.openTooltipDialog('valueInvalid');
      } else {
        this.openTooltipDialog('numericInvalid');
      }
      this.ifSelectedSwitch = false;
    }
  }

  /** resets budget value to recommended budget */
  undoBudget() {
    document.getElementById('moveBudget').blur();
    this.budgetForm.controls['MoveBudget'].setValue(
      formatNumber(this.recommendedMoveBudget, 'en-US')
    );
    this.invalidBudget = false;
    this.taxDisable = false;
    this.moveBudget = this.recommendedMoveBudget;
    this.updateTotalExpense(this.moveBudget);
    this.updateMoveBudget(this.moveBudget);
    this.mode = 'edit';
  }
  /**
   * Get the keyboard event
   * @param ev instance of KeyboardEvent
   */
  @HostListener('document:keyup', ['$event']) onKeyUp(ev: KeyboardEvent) {
    if (ev.code === 'ArrowUp' || ev.code === 'ArrowDown') {
      this.overlayRef.dispose();
    }
  }

  /** Open the tooltip dialog */
  openTooltipDialog(key: string) {
    const elementDom = this.myElement.nativeElement.querySelector(
      'mat-form-field#moveBudgetField'
    );
    const target = this.getPositionByEvents(elementDom);
    const element = new ElementRef(target);
    const positionStrategy = this.placeByPositionStrategy(element);
    const thresholdScroll = {
      threshold: 50
    };
    const overlayConfig = new OverlayConfig({
      width: 250,
      panelClass: 'overlay-tooltip-pane',
      hasBackdrop: true,
      backdropClass: 'mat-backdrop-transparent',
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close(thresholdScroll),
      disposeOnNavigation: true
    });
    this.overlayRef = this.overlay.create(overlayConfig);
    const containerPortal = new ComponentPortal(
      OverlayTooltipComponent,
      null,
      this.positionService.createInjector({
        keyString: key,
        clientX: target.getBoundingClientRect().left,
        clientY: target.getBoundingClientRect().top,
        screenWidth: this.screenWidth,
        overlayRef: this.overlayRef
      })
    );
    this.overlayRef.attach(containerPortal);
    this.overlayRef.backdropClick().subscribe(() => {
      this.overlayRef.dispose();
    });
  }

  /** get the position of the target */
  getPositionByEvents(elem) {
    const rect = elem.getBoundingClientRect();
    return {
      getBoundingClientRect: () => ({
        bottom: rect.top,
        height: rect.height,
        left: rect.left + (rect.right - rect.left) / 2,
        right: rect.right,
        top: rect.top,
        width: rect.width
      })
    };
  }

  /** place the pop up by position strategy */
  placeByPositionStrategy(element: ElementRef) {
    return this.overlay
      .position()
      .flexibleConnectedTo(element)
      .withFlexibleDimensions(false)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'bottom'
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top'
        }
      ]);
  }

  /** Open Authorize transfer dialog */
  openAuthorizeDialog(): void {

    const data = {
      candidateName: this.candidateName,
      coreBenefits: Math.ceil(this.candidateBudgetDetails.coreBudgetTotal),
      flexSpend: Math.ceil(this.candidateBudgetDetails.flexSpendTotal),
      amountDue: Math.ceil(this.moveBudget),
      isTaxEnabled: this.ifSelectedSwitch,
      taxAmount: Math.ceil(this.taxAssistanceDefault),
      clientContactId: this.candidateBudgetDetails.candidate.createdBy,
      candidateID: this.candidateBudgetDetails.candidate.candidateId,
      invoiceDetails: this.invoiceDetails
    };
    const dialogRef = this.dialog.open(AuthorizeTransferComponent, {
      disableClose: false,
      panelClass: ['dialogMainContainer', 'authorize-dialog-container'],
      data
    });
    this.subscription.add(
      dialogRef.afterClosed().subscribe(
        result => {
          if (result) {
            this.authorizedTransferee = result;
            this.authorizedDate = new Date();
            this.taxDisable = true;
            this.budgetForm.disable();
            this.mode = '';
          }
        })
    );
  }

  /**
   * include tax when user clicks on the textbox
   * @param event event passed on click of checkbox
   */
  isTaxChoosen(event) {
    if (event.checked === false) {
      this.ifSelectedSwitch = false;
      this.totalAmount -= this.taxAssistanceDefault;
    } else {
      this.ifSelectedSwitch = true;
      this.totalAmount += this.taxAssistanceDefault;
    }
    this.updateMoveBudget(this.moveBudget);
    this.formatTotalAmount(this.totalAmount);
  }
  /**
   * method to update the total expense with updated move budget
   * @param moveBudget moveBudget amount
   */
  updateTotalExpense(moveBudget) {
    this.taxAssistanceDefault = this.calculateTaxAssistance(moveBudget);
    if (this.ifSelectedSwitch) {
      this.totalAmount = moveBudget + this.taxAssistanceDefault;
      this.formatTotalAmount(this.totalAmount);
    } else {
      this.totalAmount = moveBudget;
      this.formatTotalAmount(this.totalAmount);
    }
  }
  /**
   * method to convert the amount to USD format
   * @param totalAmount amount to format
   */
  formatTotalAmount(totalAmount) {
    this.totalBudget = formatNumber(Math.ceil(totalAmount), 'en-US').toString() + ' USD';
  }
  /**
   * method to calculate the tax assistance amount
   * @param budget budget passed
   */
  calculateTaxAssistance(budget) {
    return Math.ceil(budget * this.candidateBudgetDetails.grossUpRate);
  }
  /**
   * service call to update move budget
   * @param moveBudget moveBudget amount
   */
  updateMoveBudget(moveBudget) {
    const budgetDetails = {
      authorizedAmt: Math.ceil(moveBudget).toString(),
      taxAssistanceNeeded: this.ifSelectedSwitch,
      clientContactId: this.clientContactId,
      candidateId: this.candidateBudgetDetails.candidate.candidateId
    };
    this.budgetSrvc.updateCandidateMoveBudget(budgetDetails).subscribe(
      (response: APIResponse) => {
        if (!response) {
          this.snackBar.open(
            apiErrorMessage,
            undefined,
            { duration: 5000 }
          );
        }
      });
  }
  /**
   * method to get relocation details
  */
  getRelocationDetails() {
    this.relocationDetails.candidateFullName = this.candidateName;
    this.relocationDetails.clientName = this.invoiceDetails.clientName;
    this.relocationDetails.bussinessUnit = this.candidateBudgetDetails.candidate.businessUnit ?
      this.candidateBudgetDetails.candidate.businessUnit : '';
    this.relocationDetails.depature = this.candidateBudgetDetails.candidate.departure.fullAddress;
    this.relocationDetails.destination = this.candidateBudgetDetails.candidate.destination.fullAddress;
    this.relocationDetails.coreBudget = formatNumber(Math.ceil(this.candidateBudgetDetails.coreBudgetTotal), 'en-US').toString() + ' USD';
    this.relocationDetails.flexBudget = formatNumber(Math.ceil(this.moveBudget) -
      Math.ceil(this.candidateBudgetDetails.coreBudgetTotal), 'en-US') + ' USD';
    this.relocationDetails.moveBudget = formatNumber(Math.ceil(this.moveBudget), 'en-US').toString() + ' USD';
    this.relocationDetails.totalMoveBuget = this.totalBudget;
    this.relocationDetails.recommendedBudget = formatNumber(Math.ceil(this.recommendedMoveBudget), 'en-US').toString() + ' USD';
    this.ifSelectedSwitch ?
      (this.relocationDetails.taxinfo = notice + taxInfoIncluded) : (this.relocationDetails.taxinfo = warning + taxInfoNotIncluded);
    this.relocationDetails.flexSpend = this.candidateBudgetDetails.flexSpend ? this.candidateBudgetDetails.flexSpend : null;
    this.relocationDetails.taxAmount = this.ifSelectedSwitch ?
      formatNumber(Math.ceil(this.taxAssistanceDefault), 'en-US').toString() + ' USD' : '0 USD';
  }
  /** to check for readonly access */
  isDisabled(flag) {
    this.disableButton = flag;
    if (flag === true) {
      this.taxDisable = true;
      this.budgetForm.disable();
      this.mode = '';
    }
  }
  /**
   * destroys the object
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  /**
   * After view is initialized
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.breadcrumb.nativeElement.focus();
    }, 2000);
  }
}
