<div class="container" fxLayout fxLayout.xs="column" fxLayoutGap="32.5px">
    <div fxFlex="32%" fxFlex.md="40%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
        <mat-form-field>
            <mat-label aria-label="Company Name">Company:</mat-label>
            <input class="businessUnit" matInput readonly [value]="clientName?clientName:' '">
        </mat-form-field>
    </div>
    <div fxFlex="32%" fxFlex.md="40%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
        <mat-form-field>
            <mat-label>Business Unit:</mat-label>
            <input class="businessUnit" matInput readonly [value]="businessUnit?businessUnit:' '">
        </mat-form-field>
    </div>
    <div fxFlex="32%" fxFlex.md="40%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
        <mat-form-field>
            <mat-label>Level:</mat-label>
            <input class="level" matInput readonly [value]="level">
        </mat-form-field>
    </div>
</div>
