/** MOV360-880 - to be removed at future date */
import * as querystring from 'querystring';
import { QuoteRequest } from '../../../core/models/quote-request.model';
import { UntypedFormGroup } from '@angular/forms';
import { Selection } from '../../../core/models/selection.model';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  Input,
  Component,
  ChangeDetectorRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  Output,
  EventEmitter,
  SimpleChanges,
  OnDestroy,
  OnChanges
} from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription, Subject } from 'rxjs';
import { Candidate } from '../../../core/models/candidate';
import { InactiveSupplierContactsComponent } from './inactive-supplier-contacts/inactive-supplier-contacts.component';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { AddEditSupplierContactComponent } from './add-edit-supplier-contact/add-edit-supplier-contact.component';
import { ExtensionService } from 'src/app/core/services/extension.service';
import { Extension } from 'src/app/core/models/extension.model';
import { SupplierContactService } from 'src/app/core/services/supplier-contact.service';
import { SupplierContact } from 'src/app/core/models/supplier-contact.model';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { skipRecordCount } from 'src/app/core/models/constants';

@Component({
  selector: 'app-supplier-contacts-list',
  templateUrl: './supplier-contacts-list.component.html',
  styleUrls: ['./supplier-contacts-list.component.scss']
})
export class SupplierContactsListComponent implements OnInit, OnChanges, OnDestroy {
  /**variable to store the title */
  displayedColumns: string[] = [
    'select',
    'fullname',
    'email',
    'supplier',
    'status'
  ];

  supplierData;


  /**Form to capture the candidate details */
  addCandidateForm: UntypedFormGroup;
  /**Data source for table */

  /**stores the details of candidates */
  // ELEMENT_DATA: Candidate[] = [];
  ELEMENT_DATA: SupplierContact[] = [];

  supplierContactSubscription: Subscription;
  dataSource = new MatTableDataSource<any>();
  /**store the filter value */
  filterText = '';
  /**to store the rows selected */
  selection = new SelectionModel<any>(true, []);

  errorMessage = 'We are unable to fetch Quote Request List at this time. Please try again later.';

  /** To sort the mat table columns */
  @ViewChild(MatSort, { static: false }) set sortOrder(sort: MatSort) {
    if (sort) {
      this.dataSource.sort = sort;
    }
  }
  /** To paginate in a mat table */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  /** Input prop for receiving data*/
  @Input() selectedCols: Selection[];
  /** store default column for sorting */
  sortBy = '';
  /** sorting direction */
  sortDir = 'asc';
  /**check if table data is loaded */
  isLoaded = false;
  /**store levelDetails */
  // levelDetails: Level[] = [];
  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /**stores count of active candidates */
  initialCount = 0;
  /** Stores the client contact ID */
  clientContactId: string;
  pageInfo = {
    pageSize: 20,
    pageIndex: 0,
    totalCount: 0
  };
  departureState = 'departure.state';
  totalSupplierContacts;
  private searchterms = new Subject<string>();
  defaultExtension: any;
  label = 'Supplier Contacts';
  /**
   * injecting dependencies
   * @param dialog object for matdialog
   * @param  candidateProfilesService object for CandidateProfileService
   * @param changeDetectorRefs object for changeDetectorRefs object
   * @param snackBar object for snachbar
   * @param datePipe object for datePipe object
   * @param notificationsService object for NotificationsService
   * @param spinner object for spinner
   */

  /**
   * Inject dependencies to be used in the component
   * @param dialog -MatDialog object
   * @param notificationsService -Object for NotificationsService
   */
  constructor(
    public dialog: MatDialog,
    private readonly notificationsService: NotificationsService,
    private readonly Logger: LoggerService,
    private readonly changeDetectorRefs: ChangeDetectorRef,
    public snackBar: MatSnackBar,
    public datePipe: DatePipe,
    private readonly _router: Router,
    public spinner: NgxSpinnerService,
    private extnSvc: ExtensionService,
    private supplierContactService: SupplierContactService
  ) { }

  loadSuppliercontacts(limit= 20, skip= 0, searchText= this.filterText) {
    this.spinner.show();
    this.supplierContactSubscription = this.supplierContactService
      .getAllSupplierContacts(limit, skip, searchText, this.sortBy, this.sortDir).subscribe({
        next: data => {
          this.ELEMENT_DATA = data['supplierContactList'];
          this.dataSource.data = this.ELEMENT_DATA;
          this.setNames();
          this.paginator.length = data['total_supplierContacts'];
          this.dataSource.sort = this.sortOrder;
          this.totalSupplierContacts = data['total_supplierContacts'].toString();
          this.selection.clear();
          this.spinner.hide();
        },
        error: err => {
          this.spinner.hide();
          console.log('HTTP Error', err);
          this.notificationsService.flashNotification(
            'error',
            err.message,
            true,
            'dismiss'
          );
        }
      });
  }

  loadActiveSuppliers() {
    this.spinner.show();
    this.supplierContactService.getActiveSuppliers().subscribe(
      data => {
        this.supplierData = data;
        this.loadSuppliercontacts();
      }
    );
  }

  /**To initialise the component */
  ngOnInit() {
    setTimeout(() => {
      this.spinner.show();
    }, 0);
    this.subscription.add(
      this.extnSvc.getExtensionDetails().subscribe((extensions: any[]) => {
        this.defaultExtension = extensions.find(x => x.name === 'United States');
        const extensionDetails: any[] = [];
        for (let i = 0; i < extensions.length; i++) {
          const extn = extensions[i];
          const extensionData: Extension = {
            countryDialingCode: `+${extn.dialingCode}`,
            name: extn.name
          };
          extensionDetails.push(extensionData);
        }
        this.extnSvc.extension$.next(extensionDetails);
         this.loadActiveSuppliers();
      })
    );
    this.subscribeTosearchTerm();
  }

  sortData(event) {
  this.sortBy = event.active;
  this.sortDir = event.direction === 'desc' ? 'DESC' : 'ASC';
  this.loadSuppliercontacts(this.pageInfo.pageSize, this.paginator.pageIndex * this.paginator.pageSize);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  setNames() {
    this.ELEMENT_DATA.forEach(element => {
      if (element.lastName || element.firstName) {
        element.lastName = `${element.lastName.charAt(0).toUpperCase()}${element.lastName.slice(1)}`;
        element.firstName = `${element.firstName.charAt(0).toUpperCase()}${element.firstName.slice(1)}`;
        element.fullName = `${element.lastName}, ${element.firstName}`;
      }
    });
  }

  /**
   * function to get property by path
   * @param obj -object passed
   * @param pathString -path passed
   */
  getPropertyByPath(obj: Object, pathString: string) {
    if (pathString === this.departureState && !obj.hasOwnProperty('departure')) { return null; }
    return pathString.split('.').reduce((o, i) => o[i], obj);
  }

  /**Refresh and detect the changes */
  refresh() {
    this.changeDetectorRefs.detectChanges();
  }

  /**format model for candidate level*/
  formatModels(candidates) {
    for (const candidate of candidates) {
      if (candidate.level) {
        candidate.levelName = candidate.level.split('(')[0];
        candidate.levelDes = candidate.level.replace('(', '#(').split('#')[1];
      }
    }
  }

  /**function for selecting the rows in the table */
  checkboxLabel(row?: Candidate): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${
      this.selection.isSelected(row) ? 'deselect' : 'select'
      } row ${row.fullname + 1}`;
  }

  /**Function to detect the changes */
  public ngOnChanges(changes: SimpleChanges) {
    if ('selectedCols' in changes) {
      const tempStr: string[] = [];
      this.selectedCols.forEach((item, index) => {
        if (this.displayedColumns.indexOf(item.value) < 0) {
          this.displayedColumns.splice(
            this.displayedColumns.length - 1,
            0,
            item.value
          );
        }
      });
      this.displayedColumns.forEach(element => {
        const ind = this.selectedCols.findIndex(col => col.value === element);
        if (ind !== -1) {
          tempStr.push(element);
        }
      });
      this.displayedColumns =
        tempStr.length > 0 ? tempStr : this.displayedColumns;
      if (this.displayedColumns.findIndex(val => val === 'select') < 0) {
        this.displayedColumns.unshift('select');
      }
    }
  }

  /** Open quote request response dialog */
  openEditSupplierContactDialog(isEmptySupplierInfo: boolean, supplierContactDetails?: any): void {
    const dialogRef = this.dialog.open(AddEditSupplierContactComponent, {
      disableClose: true,
      panelClass: ['dialogMainContainer', 'authorize-dialog-container'],
      data: { isEmptySupplierInfo: isEmptySupplierInfo, supplierContactDetails: supplierContactDetails, activeSupplier: this.supplierData }
    });
    this.subscription.add(
      dialogRef.afterClosed().subscribe(
        result => {
          if (result === 'InActivate') {
            this.inactiveSupplierContacts(supplierContactDetails);
          } else {
            if (result) {
              this.loadActiveSuppliers();
            }
          }
        })
    );
  }

  /** Open quote request response dialog */
  inactiveSupplierContacts(supplierContact?): void {
    let selectedSupplierContact = [];
    if (supplierContact) {
      selectedSupplierContact.push(supplierContact);
    } else {
      selectedSupplierContact = this.selection.selected;
    }
    const dialogRef = this.dialog.open(InactiveSupplierContactsComponent, {
      disableClose: true,
      panelClass: ['dialogMainContainer', 'inactive-supplier-contact-dialog-container'],
      data: { selected: selectedSupplierContact }
    });
    this.subscription.add(
      dialogRef.afterClosed().subscribe(
        result => {
          if (result === 'inActivateSupplierContacts') {
            const inactiveSupplierContacts = [];
            selectedSupplierContact.forEach(item => {
            const inactiveContact = {
              'firstName': item.firstName,
              'lastName': item.lastName,
              'emailAddress': item.emailAddress,
              'phoneNumber': item.phoneNumber,
              'countryDialingCode': item.countryDialingCode,
              'status': item.status,
              'supplierId': item.supplierId,
              'role': item.role[0].name,
              'isDailyOperationsContact': item.isDailyOperationsContact,
              'invitedAsSupplierContact': item.invitedAsSupplierContact,
              'createdAt': item.createdAt,
              'statusDate': item.statusDate,
              'supplierContactId': item.supplierContactId,
              'isDeleted': true
            };
            inactiveSupplierContacts.push(inactiveContact);
          });

          this.spinner.show();
          this.supplierContactService.inActivateSupplierContact(inactiveSupplierContacts).subscribe({
            next: data => {
              this.showNotificationForInactivateSupplierContact(inactiveSupplierContacts);
              this.selection.clear();
              this.filterText = '';
              this.loadSuppliercontacts();
            },
            error: error => {
              this.spinner.hide();
              console.log('HTTP Error', error);
              this.notificationsService.flashNotification(
                'error',
                error.message,
                true,
                'dismiss'
              );
            }});
          } else {
            this.selection.clear();
          }
        })
    );
  }

  showNotificationForInactivateSupplierContact(selectedSupplierContact) {
    if (selectedSupplierContact.length > 1) {
      this.notificationsService.flashNotification('danger',
        `${selectedSupplierContact.length} Supplier Contacts set to Inactive`, true, 'dismiss');
      } else if (selectedSupplierContact.length === 1) {
        this.notificationsService.flashNotification('danger',
          `${selectedSupplierContact[0].firstName} ${selectedSupplierContact[0].lastName} set to Inactive`, true, 'dismiss');
      }
  }

  /** navigate to Budget Summary of requested candidate*/
  navigateToBudgetSummary(quote: QuoteRequest) {
    this._router.navigate(['quote-request/detail'], {
      state: quote
    });
  }

  navigateToQuoteSummary(quoteId: string) {
    this._router.navigate(['quote-summary', quoteId]);
  }

  /**To destroy the subscription */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onPagination(event: any) {
    this.pageInfo.pageSize = this.paginator.pageSize;
    this.pageInfo.totalCount = this.paginator.length;
    this.loadSuppliercontacts(this.paginator.pageSize, this.paginator.pageIndex * this.paginator.pageSize);
  }

  subscribeTosearchTerm() {
    this.searchterms.pipe(
      debounceTime(1000),
      distinctUntilChanged())
      .subscribe(text => {
        if (text.length >= 2) {
          this.paginator.pageIndex = skipRecordCount;
          this.loadSuppliercontacts(this.paginator.pageSize);
        } else {
          this.loadSuppliercontacts(this.paginator.pageSize, this.paginator.pageIndex * this.paginator.pageSize);
        }
      });
  }

  applyFilter(searchTerm) {
    this.filterText = searchTerm;
    if (searchTerm.length >= 2) {
      this.searchterms.next(searchTerm.toLowerCase());
    } else {
      this.searchterms.next('');
    }
  }

  resetList(searchTerm) {
    this.filterText = '';
    this.searchterms.next(this.filterText);
  }

}
