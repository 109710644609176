<ngx-spinner type="ball-spin-clockwise-fade" size="medium">
</ngx-spinner>

<section fxLayout="column">
    <h1 class="explore-title-text">Account Settings</h1>
</section>

<app-candidate-contact-info *ngIf="candidateContactInfo" [candidateContactInfo]="candidateContactInfo" [candidateCompany]="candidateCompany">
</app-candidate-contact-info>

<mat-card *ngIf="isNotCartusEmp" class="mat-card-candidate-contact clickable" (click)="gotoChangePasswordPage()" fxLayout fxLayoutAlign="start center" fxLayoutAlign.xs="start start">
    <span><a>Change Password</a></span>
    <!-- The below Last Changed Date div should be implemented Later -->
    <!-- <div class="password-save-date" fxLayout fxLayout.xs="column">
        <div fxLayout>
            <span>Last Changed: NA&nbsp;&nbsp;</span>
            <img src="../../../../assets/images/candidate-assessment/chevron_right.svg" />
        </div>
    </div> -->
    <!-- Last changed date should be in YYYY-MM-DD format -->
</mat-card>