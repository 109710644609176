import { SupplierContract } from './contract-suppliers.model';

export interface TerritoryCoverage {
  territory: string;
  territoryTariff: string;
  supplierDiscounts?: SupplierDiscount[];
  commissions?: SupplierCommission[];
}

export interface SupplierDiscount {
  discountType: string;
  discountPercentage: string;
  startDate: string;
  endDate: string;
}
export interface SupplierCommission {
  commissionType: string;
  commissionPercentage: string;
  startDate: string;
  endDate: string;
}
