/** MOV360-880 - to be removed at future date */
import { Component, OnDestroy, ɵConsole, OnInit } from '@angular/core';
import {
  FormControl, UntypedFormBuilder, UntypedFormGroup, Validators, ValidationErrors,
  ValidatorFn, AbstractControl,
} from '@angular/forms';
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocationService } from '../../../../core/services/location.service';
import { ClientRoleService } from '../../../../core/services/client-role.service';
import { ClientRole, roleType } from '../../../../core/models/client-role';
import { Client, ClientStatus } from '../../../../core/models/client';
import { ClientContact } from '../../../../core/models/client-contact';
import { Location } from '../../../../core/models/location';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NotificationsService } from '../../../../core/services/notifications.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

import {
  addVendorInvoiceTitleForVendorInvoice,
  addClientContactModelMode,
  addClientContactTitleForReviewClientContact,
  addClientContactTitleForEditClientContact,
  addClientContactTitleForDeleteClientContact,
  addClientContactTitleforinviteClientContact,
  addClientContactSubmitButtonLabel,
  addClientContactActivateClientContactButtonLabel,
  phoneErrorMessage,
  sendInviteResponse,
  reSendInviteResponse,
  invitationSentStatus,
  invitationNotSentStatus,
  addClientContactSaveClientContactButtonLabel,
  createCandidateResponse,
  updateCandidateResponse,
  duplicateResponse,
  errorMessage,
  createSupplierInvoiceResponse,
  saveSupplierInvoiceResponse
} from 'src/app/core/models/constants';
import { ClientServiceService } from 'src/app/core/services/client-service.service';
import { ClientContactService } from '../../../../core/services/client-contact.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoggerService } from '../../../../core/services/logger.service';
import { UserContextService } from '../../../../core/services/user-context.service';
import { SupplierInvoice } from 'src/app/core/models/supplier-invoice';
import { SupplierInvoiceService } from 'src/app/core/services/supplier-invoice.service';
import { PartySharedService } from 'src/app/core/services/party-shared.service';

/**
 * Exporting the errormessages
 */
export const errorMessages: { [key: string]: string } = {
  ClientContactFirstName: 'You must enter Invoice Amount',
  ClientContactLastName: 'You must enter Invoice Number',
  ClientContactEmailAddress: '\'You must enter email address',
  ClientContactRole: 'You must select a valid role',
  ClientContactMobileNumber: 'You must enter a valid mobile phone number',
  CompanyName: 'You must select a Company'
};

@Component({
  selector: 'app-add-supplier-invoice',
  templateUrl: './add-supplier-invoice.component.html',
  styleUrls: ['./add-supplier-invoice.component.scss']
})
export class AddSupplierInvoiceComponent implements OnInit, OnDestroy {
  /**Form group name */
  addClientContactForm: UntypedFormGroup;
  /* Title to display the dialog window page title */
  title = addVendorInvoiceTitleForVendorInvoice;
  /**Flag mode for Create */
  mode = addClientContactModelMode;
  /**Assign formready Variable as False */
  formReady = false;
  /**Variables for error */
  errors = errorMessages;
  /**Binding the dropdown values to roles filed */
  clientRoles: ClientRole[];
  /** auto complete values for destination */
  options: Location[];
  /* variable declared for showLoader*/
  showLoader = false;
  /* variable declared for deleteDialog*/
  deleteDialog = false;
  /* variable declared for title for invite as client contact*/
  inviteAsClientContactLabel = addClientContactTitleforinviteClientContact;
  /* variable declared for invite as client contact flag*/
  inviteAsClientContactFlag = false;
  /* variable declared for title for submit button*/
  submitButtonLabel = addClientContactSubmitButtonLabel;
  /** Subscription prop for unsubscribing services */
  private subscription: Subscription = new Subscription();
  /**addCandidate  model of type Candidate */
  addClient: SupplierInvoice = {} as SupplierInvoice;
  /** flag to check phone number valid */
  isActualWeightValid = false;
  isEmailValid = false;
  /** flag to show inactive checkbox */
  showInactiveCheckBox = false;
  /** flag to maintain inactive client status */
  inactivateClientFlag = false;
  /** flag to show save draft checkbox */
  showDraftButton = false;
  stateList: any[];
  clientList: Client[];
  clientID = '15898';
  checkFirstTimeEntry = true;
  inactiveButtonLabel = 'Set to Inactive';
  saveDraftButtonLabel = 'Save Draft';
  isInvitationSent = false;
  showResendButton = true;
  /**property to store Invoice Date */
  jobStartOn: Date;
  roleDetails: ClientRole;
  roleDetailList: ClientRole[] = [];
  orderId: string;
  date = new FormControl(new Date());
  amount: string;
  canSaveSubmitInvoice = true;

  /**
   * Initializes form elements
   * @param formBuilder - property for form elements
   * @param dialogRef - property for mat dialog reference
   * @param data - contains popup data
   * @param candidateProfilesService - service parameter
   * @param changeDetectorRef - property for change detections
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    private readonly partySharedSvc: PartySharedService,
    public dialogRef: MatDialogRef<AddSupplierInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SupplierInvoice,
    private locationService: LocationService,
    private notificationsService: NotificationsService,
    private clientRolesService: ClientRoleService,
    private clientService: ClientServiceService,
    private clientContactService: ClientContactService,
    private supplierInvoiceService: SupplierInvoiceService,
    private spinner: NgxSpinnerService,
    private Logger: LoggerService,
    private loggedInUserService: UserContextService
  ) {
    this.stateList = this.locationService.getStates();
    this.clientService.clientList$.subscribe(result => {
      this.clientList = result;
    });
    // console.log('Client List' + JSON.stringify(this.clientList, null, 4))
    // console.log(data);

    this.clientRoles = this.clientRolesService.getRoles();
    this.showDraftButton = true;
    this.checkCanSaveSubmitInvoice();

    /* Create the Add/Edit dialog window */
    this.initializeClientForm();
    /* If the data is present - it will open and pre-populate dialog window */
    if (this.data) {
      this.addClient = this.data;
      this.checkFirstTimeEntry = false;
      this.addClientContactForm.get('Amount').setValue(this.data.amount ? this.formatTotalAmount(this.data.amount) : '');
      // this.amount = this.data.amount != undefined ? this.data.amount : '';
      if (this.data.status === invitationSentStatus) {
        this.isInvitationSent = true;
        this.showResendButton = true;
        this.saveDraftButtonLabel = 'Save Changes';
        this.title = addVendorInvoiceTitleForVendorInvoice;
      }
      if (this.data['clientLegalName'] !== undefined) {
        this.title = addVendorInvoiceTitleForVendorInvoice;
      } else {
        this.title = addVendorInvoiceTitleForVendorInvoice;
        if (this.data.status === 'Active') {
          this.submitButtonLabel = addClientContactSaveClientContactButtonLabel;
          this.showResendButton = false;
          // this.showInactiveCheckBox = true
          this.showDraftButton = true;
          this.saveDraftButtonLabel = 'Save Changes';
          this.isInvitationSent = true;
        } else if (this.data.status === 'Invitation Not Sent') {
          this.submitButtonLabel = addClientContactSubmitButtonLabel;
          this.showDraftButton = true;
          this.showResendButton = false;
        }
      }
      // console.log('Add client varialble in Init Method : ' + JSON.stringify(this.addClient, null, 4))
      // console.log('data varialble in Init Method : ' + JSON.stringify(this.data, null, 4))
      //  this.addClient = this.data

      /* Setting the default values for form elements in edit candidate dialog */
      if (this.data['invoiceId'] !== null && this.data['invoiceId'] !== undefined) {
        const someDate = new Date(this.data.invoiceDate !== undefined ? new Date(this.data.invoiceDate) : '');
        const numberOfDaysToAdd = 1;
        someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
        this.addClientContactForm.get('InvoiceNumber').setValue(this.data.invoiceId ? this.data.invoiceId : '');
        this.addClientContactForm.get('Amount').setValue(this.data.amount ? this.data.amount : '');
        this.date.setValue(this.data.invoiceDate !== undefined ? new Date(someDate) : null);
        this.addClientContactForm.get('ActualWieght').setValue(this.data.actualWeight ? this.data.actualWeight : '');
        this.addClientContactForm.get('ActualMileage').setValue(this.data.actualMileage ? this.data.actualMileage : '');
      }
    }
  }

  /**
   * Used for initializing Client Form
   */
  initializeClientForm(): void {
    this.addClientContactForm = this.formBuilder.group({
      // TODO: need to add pattern for ModelName to avoid special character.
      VendorName: [''],
      InvoiceNumber: ['',
        Validators.compose([Validators.required, this.noWhitespaceValidator])],
      Amount: ['',
        Validators.compose([
          Validators.required,
          this.noWhitespaceValidator
        ])
      ],
      ActualWieght: [''],
      ActualMileage: ['']
    });
  }

  checkSaveDraft() {
    return !this.addClientContactForm.controls['VendorName'].valid || !this.addClientContactForm.controls['InvoiceNumber'].valid;
  }

  /**
   *  Modified incoming value to lowerCase and assigned to const variable filterValue
   * @param value - start character for filter values
   */
  private _filter(value: string): Location[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  /**
   * Click on Submit button inside the addCstModelForm dialog window
   */
  saveInvoice(): void {
    if (this.addClientContactForm.valid) {
      this.spinner.show();
      this.populateSupplierInvoiceObject();
      const roleDetails = this.clientRolesService.getRoleID(this.addClientContactForm.value.ClientContactRole);
      const clientDetails = this.clientService.getClientDetails(this.addClientContactForm.value.CompanyName);
      // console.log('clientDetails : ' + JSON.stringify(clientDetails, null, 4))
      if (this.data) {
        this.supplierInvoiceService
          .createSupplierInvoice(this.addClient)
          .subscribe({
            next: response => {
              if (response.statusCode === 200) {
                this.spinner.hide();
                this.addClient.status = 'Audit Complete';
                this.flashAndCloseDialog(response.message);
              } else {
                // console.log("Error : ", JSON.stringify(response, null, 4))
              }
            },
            error: () => {
              this.spinner.hide();
            }
      });
      } else {
        this.supplierInvoiceService
          .createSupplierInvoice(this.addClient)
          .subscribe({
            next: response => {
              if (response.statusCode === 200) {
                this.spinner.hide();
                this.addClient.status = 'Audit Complete';
                this.flashAndCloseDialog(response.message);
              } else {
                // console.log("Error : ", JSON.stringify(response, null, 4))
              }
            },
            error: () => {
              this.spinner.hide();
            }
      });
      }
    }
  }

  /**
   * Click on Submit button inside the addClientContacteForm dialog window
   */
  sendInvite() {
    this.spinner.show();
    this.populateSupplierInvoiceObject();
    this.addClient.status = 'Audit Complete';
    if (this.addClient) {
      this.sendInviteToClientContact();
    } else {
      this.sendInviteToClientContact();
    }
  }

  saveDraft() {
    this.spinner.show();
    this.populateSupplierInvoiceObject();
    this.addClient.status = 'Draft Saved';
    if (this.addClient) {
      this.saveInvoiceDraft();
    } else {
      this.saveInvoiceDraft();
    }
  }

  /**send invite to candidate */
  sendInviteToClientContact() {
    // this.addCandidate.executedFunctionType = 'Invite';
    this.orderId = this.addClient['orderId'];
    this.addClient.status = 'Audit Complete';
    this.supplierInvoiceService
      .createSupplierInvoice(this.addClient)
      .subscribe(response => {
        this.spinner.hide();
        // console.log("Response" + JSON.stringify(response, null, 4))
        if (response !== null && response.statusCode === 200) {
          this.addClient.status = 'Audit Complete';
          this.flashAndCloseDialog(response.message);
        } else {
          this.notificationsService.flashNotification(
            'success',
            errorMessage,
            true,
            'dismiss'
          );
        }
      });
  }

  /**send invite to candidate */
  saveInvoiceDraft() {
    // this.addCandidate.executedFunctionType = 'Invite';
    this.orderId = this.addClient['orderId'];
    this.addClient.status = 'Draft Saved';
    this.supplierInvoiceService
      .saveSupplierInvoiceDraft(this.addClient)
      .subscribe(response => {
        this.spinner.hide();
        // console.log("Response" + JSON.stringify(response, null, 4))
        if (response !== null && response.statusCode === 200) {
          this.addClient.status = 'Draft Saved';
          this.flashAndCloseDialog(response.message);
        } else {
          this.notificationsService.flashNotification(
            'success',
            errorMessage,
            true,
            'dismiss'
          );
        }
      });
  }


  flashAndCloseDialog(message: string) {
    // console.log("Success Message :" + message)
    switch (message) {
      case createSupplierInvoiceResponse: {
        this.dialogRef.close(this.addClient);
        this.notificationsService.flashNotification(
          'success',
          'Invoice Submitted, Audit Complete');
        break;
      }
      case saveSupplierInvoiceResponse: {
        this.dialogRef.close(this.addClient);
        this.notificationsService.flashNotification(
          'success',
          'Invoice draft saved');
        break;
      }
      case duplicateResponse: {
        this.notificationsService.flashNotification(
          'error',
          'Duplicate Client Contact Found, Please inactivate Client Contact to continue',
          true,
          'dismiss'
        );
        this.addClientContactForm.disable();
        break;
      }
    }
  }

  inactivateClient() {
    // this.clientService.deleteClient(this.addClient.clientID)
    // console.log('Data to be sent back' + JSON.stringify(this.addClient, null, 4))
    this.addClient.executedFunctionType = 'Inactivate';
    this.dialogRef.close(this.addClient);
  }

  checkForClientContactFields() {
    return this.addClientContactForm.valid;
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date.setValue(event.value);
  }

  populateSupplierInvoiceObject() {
    // console.log(this.clientRolesService.getRoleID(this.addClientContactForm.value.ClientContactRole));
    const dateString = this.formatDate(new Date());
    const currentDate = new Date();
    const numberOfDaysToAdd = 30;
    currentDate.setDate(currentDate.getDate() + numberOfDaysToAdd);
    // console.log("Invoice Date : " + JSON.stringify(this.date, null, 4))
    this.addClient = {
      'orderId': this.data.orderId,
      'invoiceId': this.addClientContactForm.value.InvoiceNumber !== undefined ? this.addClientContactForm.value.InvoiceNumber : '',
      'amount': this.addClientContactForm.value.Amount !== undefined ? this.addClientContactForm.value.Amount : '',
      'invoiceDate': this.date.value !== undefined ? this.formatDate(this.date.value) : '',
      'actualWeight': this.addClientContactForm.value.ActualWieght !== undefined ? this.addClientContactForm.value.ActualWieght : '',
      'actualMileage': this.addClientContactForm.value.ActualMileage !== undefined ? this.addClientContactForm.value.ActualMileage : '',
      'statusDate': dateString,
    };

    // console.log('Populate data : ' + JSON.stringify(this.addClient, null, 4))
    if (this.data) {
      if (this.data.invoiceId !== undefined && this.data.invoiceId !== null) {
        this.addClient.invoiceId = this.addClientContactForm.value.InvoiceNumber !== undefined ?
          this.addClientContactForm.value.InvoiceNumber : this.data.invoiceId;
        this.addClient.status = this.data.status;
      }
    }
  }

  formatDate(date) {
    return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
  }

  /**
   * Closing the dialog box - we are setting the form to empty
   */
  onNoClick(): void {
    this.addClient.executedFunctionType = 'Cancel';
    this.dialogRef.close();
    this.initializeClientForm();
  }

  /**
   * Custom error messages for Firstname, lastname and Email to verify special character or empty errors
   * @param field_name - field parameter to check for errors
   */
  getErrorMessage(fieldName): string {
    if (fieldName === 'INVOICE_DATE') {
      return this.addClientContactForm.get('VendorName').hasError('required')
        ? 'You must select Invoice Date'
        : this.addClientContactForm.get('VendorName').hasError('pattern')
          ? 'Special characters are not allowed'
          : '';
    }
    if (fieldName === 'LAST_NAME') {
      return this.addClientContactForm.get('InvoiceNumber').hasError('required')
        ? 'You must enter last name'
        : this.addClientContactForm.get('InvoiceNumber').hasError('pattern')
          ? 'Special characters are not allowed'
          : '';
    }
    if (fieldName === 'INVOICE_AMOUNT') {
      return this.addClientContactForm.get('Amount').hasError('required')
        ? 'You must enter Invoice Amount'
        : this.addClientContactForm.get('Amount').hasError('specialCharacter')
          ? 'You must enter a numeric value'
          : this.addClientContactForm.get('Amount').hasError('pattern')
            ? 'You must enter Invoice Amount'
            : this.addClientContactForm.get('Amount').hasError('alphabets')
              ? 'You must enter Invoice Amount'
              : this.addClientContactForm.get('Amount').hasError('minlength')
                ? 'You must enter Invoice Amount'
                : '';
    }
    switch (fieldName === 'ActualWieght') {
      case this.addClientContactForm.get('ActualWieght').hasError('required'):
        return 'You must enter Actual Weight';
      case this.addClientContactForm.get('ActualWieght').hasError('pattern'):
        return 'Special characters are not allowed';
      case this.addClientContactForm.get('ActualWieght').hasError('required'):
        return 'YYou must enter Actual Weight';
      case this.addClientContactForm.get('ActualWieght').hasError('pattern'):
        return 'Special characters are not allowed';
    }

    switch (fieldName === 'clientName') {
      case this.addClientContactForm.get('ActualMileage').hasError('required'):
        return 'You must enter Client Name';
      case this.addClientContactForm.get('ActualMileage').hasError('pattern'):
        return 'Special characters are not allowed';
      case this.addClientContactForm.get('ActualMileage').hasError('required'):
        return 'YYou must enter Client Legal Name';
      case this.addClientContactForm.get('ActualMileage').hasError('pattern'):
        return 'Special characters are not allowed';
    }
  }

  updateClientContactFlag(): boolean {
    this.inviteAsClientContactFlag = !this.inviteAsClientContactFlag;
    return this.inviteAsClientContactFlag;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else if (charCode === 190 || charCode === 110) {
      return true;
    }
    return true;
  }

  updateClientStatus(): boolean {
    this.inactivateClientFlag = !this.inactivateClientFlag;
    return this.inactivateClientFlag;
  }

  //   onSearchChange(searchValue: string): void {
  //    if(this.checkFirstTimeEntry || this.addClientContactForm.get('ClientPreferredName').value === ''){
  //      this.addClientContactForm.get('ClientPreferredName').setValue(this.addClientContactForm.value['ClientLegalName'])
  //    }
  //  }

  toggleDuplicateFlag() {
    this.checkFirstTimeEntry = false;
  }

  /**
   * destroys the object
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  addValidators() {
    if (this.addClientContactForm.controls['ActualWieght'].value) {
      this.addClientContactForm.controls['ActualWieght'].setValidators(
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          this.regexValidator(new RegExp(/[A-Za-z]/g), { 'alphabets': true }),
          this.regexValidator(new RegExp(/[!@#$%^&*()/\\?,.?":{}\-\+=_|<>;'`~\]\[ ]/g), { 'specialCharacter': true })
        ]));
      this.addClientContactForm.controls['ActualWieght'].updateValueAndValidity();
      this.isActualWeightValid = true;
    } else {
      this.addClientContactForm.controls['ActualWieght'].clearValidators();
      this.addClientContactForm.controls['ActualWieght'].updateValueAndValidity();
      this.isActualWeightValid = false;
    }
    if (this.addClientContactForm.controls['ClientContactEmailAddress'].value) {
      this.addClientContactForm.controls['ClientContactEmailAddress'].setValidators(
        Validators.compose([
          Validators.required,
          Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+[a-zA-Z0-9-]*\\.[a-zA-Z]{2,3}$')
        ]));
      this.addClientContactForm.controls['ClientContactEmailAddress'].updateValueAndValidity();
      this.isEmailValid = true;
    } else {
      this.addClientContactForm.controls['ClientContactEmailAddress'].clearValidators();
      this.addClientContactForm.controls['ClientContactEmailAddress'].updateValueAndValidity();
      this.isEmailValid = false;
    }
  }

  /**validate the pattern for phone number */
  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = control.value.match(regex);
      return valid ? error : null;
    };
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  formatTotalAmount(Amount) {
    const amt = Math.round(Amount);
    return amt.toString();
  }

  ngOnInit() {
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-ADD_CLIENT_CONTACT', 'ADD_CLIENT_CONTACT');
      });
  }

  async checkCanSaveSubmitInvoice() {
    const partyId = await this.partySharedSvc.getPartyId();
    const capabilities = await this.partySharedSvc.getRoleCapabilities(partyId);
    for (let i = 0; i < capabilities.roleCapabilities.length; i++) {
      if (capabilities.roleCapabilities[i].roleName === 'application-owner' ||
        capabilities.roleCapabilities[i].roleName === 'cartus-account-analyst' ||
        capabilities.roleCapabilities[i].roleName === 'application-reliability-analyst') {
        this.canSaveSubmitInvoice = false;
      }
      if (capabilities.roleCapabilities[i].roleName === 'cartus-account-auditor') {
        this.canSaveSubmitInvoice = true;
      }
    }
  }

}
