import { Component, OnInit, OnDestroy, Inject, HostBinding } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { teamSearchTitle } from '../../models/constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { TeamsService } from '../../services/teams.service';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { SelectedClient, SearchTerms, SelectedEmployee } from '../../models/teams.model';
import { TeamClientSearchComponent } from '../team-client-search/team-client-search.component';
import { TeamEmployeeSearchComponent } from '../team-employee-search/team-employee-search.component';

@Component({
  selector: 'app-team-search',
  templateUrl: './team-search.component.html',
  styleUrls: ['./team-search.component.scss']
})
export class TeamSearchComponent implements OnInit, OnDestroy {

  /** Component css class */
  @HostBinding('class') class = 'team-search';

  /** Form group name */
  teamSearchForm: UntypedFormGroup;

  /** Title to display the dialog window page title */
  title = teamSearchTitle;

  /** Search terms */
  searchTerms: SearchTerms = {};

  /** Search active */
  searchActive = false;

  /** Dialog refs */
  clientSearchDialogRef: MatDialogRef<TeamClientSearchComponent>;
  employeeSearchDialogRef: MatDialogRef<TeamEmployeeSearchComponent>;

  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();

  /** Injecting dependencies */
  constructor(
    private readonly teamsService: TeamsService,
    private readonly notificationsService: NotificationsService,
    private readonly loggerService: LoggerService,
    private readonly formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TeamSearchComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly spinner: NgxSpinnerService,
  ) {

    this.teamSearchForm = this.formBuilder.group({
      teamName: [
        '',
        Validators.compose([Validators.minLength(3)])
      ],
      owner: [
        '',
        Validators.compose([Validators.minLength(2)])
      ],
      teamOwnerId: [''],
      includeInactive: [false],
      client: [
        '',
        Validators.compose([Validators.minLength(2)])
      ],
      teamClientId: [''],
      employee: [
        '',
        Validators.compose([Validators.minLength(2)])
      ],
      teamEmployeeId: [''],
  }, { validators: this.atLeastOne(['teamName', 'owner', 'client', 'employee']) });

  }

  /** Init */
  ngOnInit() {
    if (this.data && this.data.searchTerms && Object.entries(this.data.searchTerms).filter(([k, v], i) => !!v).length > 0) {
      this.teamSearchForm.controls.teamName.setValue(this.data.searchTerms.teamName);
      this.teamSearchForm.controls.owner.setValue(this.data.searchTerms.owner);
      this.teamSearchForm.controls.teamOwnerId.setValue(this.data.searchTerms.teamOwnerId);
      this.teamSearchForm.controls.includeInactive.setValue(this.data.searchTerms.includeInactive);
      this.teamSearchForm.controls.client.setValue(this.data.searchTerms.client);
      this.teamSearchForm.controls.teamClientId.setValue(this.data.searchTerms.teamClientId);
      this.teamSearchForm.controls.employee.setValue(this.data.searchTerms.employee);
      this.teamSearchForm.controls.teamEmployeeId.setValue(this.data.searchTerms.teamEmployeeId);
      this.searchActive = true;
    } else {
      this.searchActive = false;
    }
  }

  /** Custom error messages */
  getErrorMessage(fieldName) {
    if (fieldName === 'NAME') {
      return this.teamSearchForm.get('teamName').hasError('minlength')
        ? 'Team Name should be more than two characters'
        : '';
    }
    if (fieldName === 'OWNER') {
      return this.teamSearchForm.get('owner').hasError('minlength')
      ? 'Team Owner should be more than one character'
      : '';
    }
    if (fieldName === 'CLIENT') {
      return this.teamSearchForm.get('client').hasError('minlength')
      ? 'Client should be more than one character'
      : '';
    }
    if (fieldName === 'EMPLOYEE') {
      return this.teamSearchForm.get('employee').hasError('minlength')
      ? 'Employee should be more than one character'
      : '';
    }
    if (fieldName === 'FORMGROUP') {
      return this.teamSearchForm.dirty && this.teamSearchForm.hasError('atLeastOne')
      ? 'Enter or choose at least one search term'
      : '';
    }
    return '';
  }

  /** Custom validator */
  atLeastOne = (fields: Array<string>) => {
    return (group: UntypedFormGroup) => {
      for (const fieldName of fields) {
        if (group.get(fieldName).value) {
          return null;
        }
      }
      return { atLeastOne: true };
    };
  }

  /** Client Search */
  openClientSearchDialog(): void {
    this.clientSearchDialogRef = this.dialog.open(TeamClientSearchComponent, {
      disableClose: true,
      panelClass: 'dialogMainContainer',
      data: { multiSelect: false },
      autoFocus: false
    });
    this.subscription.add(
      this.clientSearchDialogRef.afterClosed().subscribe((client: SelectedClient) => {
        if (client) {
          this.teamSearchForm.controls.client.setValue(`${client.name} (${client.number})`);
          this.teamSearchForm.controls.teamClientId.setValue(client.id);
        }
      })
    );
  }

  /** Employee Search */
  openEmployeeSearchDialog(type: string): void {
    this.employeeSearchDialogRef = this.dialog.open(TeamEmployeeSearchComponent, {
      disableClose: true,
      panelClass: 'dialogMainContainer',
      data: { multiSelect: false },
      autoFocus: false
    });
    this.subscription.add(
      this.employeeSearchDialogRef.afterClosed().subscribe((employee: SelectedEmployee) => {
        if (employee) {
          if (type === 'owner') {
            this.teamSearchForm.controls.owner.setValue(employee.name);
            this.teamSearchForm.controls.teamOwnerId.setValue(employee.id);
          }
          if (type === 'employee') {
            this.teamSearchForm.controls.employee.setValue(employee.name);
            this.teamSearchForm.controls.teamEmployeeId.setValue(employee.id);
          }
        }
      })
    );
  }

  /** Populate or clear search terms object */
  toggleSearchTerms(reset: boolean) {
    this.searchTerms['teamName'] = reset ? '' : this.teamSearchForm.controls.teamName.value;
    this.searchTerms['owner'] = reset ? '' : this.teamSearchForm.controls.owner.value;
    this.searchTerms['teamOwnerId'] = reset ? '' : this.teamSearchForm.controls.teamOwnerId.value;
    this.searchTerms['employee'] = reset ? '' : this.teamSearchForm.controls.employee.value;
    this.searchTerms['teamEmployeeId'] = reset ? '' : this.teamSearchForm.controls.teamEmployeeId.value;
    this.searchTerms['client'] = reset ? '' : this.teamSearchForm.controls.client.value;
    this.searchTerms['teamClientId'] = reset ? '' : this.teamSearchForm.controls.teamClientId.value;
    this.searchTerms['includeInactive'] = reset ? false : this.teamSearchForm.controls.includeInactive.value;
  }

  /** Closes dialog and returns empty search terms */
  reset() {
    this.toggleSearchTerms(true);
    this.dialogRef.close(this.searchTerms);
  }

  /** Closes dialog and returns the search terms */
  search() {
    this.toggleSearchTerms(false);
    this.dialogRef.close(this.searchTerms);
  }

  /** Closes dialog and clears the form */
  cancel(): void {
    this.teamSearchForm = this.formBuilder.group({
      teamName: [''],
      owner: [''],
      teamOwnerId: [''],
      includeInactive: [false],
      client: [''],
      teamClientId: [''],
      employee: [''],
      teamEmployeeId: ['']
    });
    this.dialogRef.close();
  }

  /** Destroy */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
