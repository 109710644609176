import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UserPreference } from '../models/user-preference.model';
import { BrowserStorageService } from './browser-storage.service';

/** Service to get and set user preferences */
@Injectable({
  providedIn: 'root'
})

export class UserPreferencesService {
  /** standard constructor for services */
  constructor(private readonly browserStorageService: BrowserStorageService) { }

  /**
  * Get user preference
  */
  getPreference(key: string, persistent: boolean): Observable<any> {
    if (persistent) {
      return of(this.browserStorageService.getLocalStorageValue(key));
    } else {
      return of(this.browserStorageService.getSessionStorageValue(key));
    }
  }

  /**
  * Set user preference
  */
  setPreference(userPreference: UserPreference): void {
    if (userPreference.Persist) {
      this.browserStorageService.setLocalStorageValue(userPreference.Key, userPreference.Value);
    } else {
      this.browserStorageService.setSessionStorageValue(userPreference.Key, userPreference.Value);
    }
  }
}
