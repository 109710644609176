<div class="milestone-toggle">
  <label class="label" id="milestone-label">Show Incomplete Milestones:</label>
  <div class="toggle">
    <mat-slide-toggle [checked]="ifSelectedSwitch" (change)="isToggleChoosen($event)" id='milestoneToggle'
      aria-labelledby="milestone-label">
      <span>
        <span *ngIf="ifSelectedSwitch">ON</span>
        <span *ngIf="!ifSelectedSwitch">OFF</span>
      </span>
    </mat-slide-toggle>
  </div>
</div>
<table class="timeline-list" *ngIf="serviceDetails">
  <ng-container *ngFor="let item of serviceDetails; let i = index">
    <tr [ngClass]="{'missing' : item.sortOrder == 0 || (item.startDate === null && item.endDate === null)}" *ngIf="(item.sortOrder == 0 && ifSelectedSwitch) || 
    ( item.sortOrder > 0 && 
      ( ifSelectedSwitch || 
        (!ifSelectedSwitch && (item.startDate !== null || item.endDate !== null))
      )
    )">
      <td class="col-key-dates">
        <div class="key-date">
          <span *ngIf="item.startDate !== null">{{item.startDate | date:'yyyy-MM-dd'}}</span>
          <span *ngIf="item.startDate === null">{{item.endDate | date:'yyyy-MM-dd'}}</span>
        </div>
        <div class="vertical" *ngIf="i != serviceDetails.length - 1">
          <div>&nbsp;</div>
        </div>
      </td>
      <ng-container>
        <td class="col-data">
          <span>{{item.serviceName}}</span>
        </td>
      </ng-container>
    </tr>
  </ng-container>
</table>