import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { deepCopy } from '@angular-devkit/core/src/utils/object'
import { PeopleDataItem } from '../../models/peopledata.model'
import { TableColumn } from 'src/app/user-alignment/models/table.model'
import { ConfirmDialogComponent } from 'src/app/core/components/shared/confirm-dialog/confirm-dialog.component'
import { Subscription } from 'rxjs'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { NgxSpinnerService } from 'ngx-spinner'
import { FULFILLED_ON_UPDATE_IDENTITY_PROVIDER_ID, GET_PEOPLE_DATA_ERROR, GET_PEOPLE_DATA_ERROR_HEADER, GET_PEOPLE_DATA_ONE_PARTY_ERROR, GET_PEOPLE_DATA_SUCCESS,
         GET_PEOPLE_DATA_ZERO_PARTIES_ERROR, REJECTED_ERROR_ON_UPDATE_IDENTITY_PROVIDER_ID, UPDATE_IDENTITY_PROVIDER_ID_DIALOG_LINES, UPDATE_IDENTITY_PROVIDER_ID_DIALOG_TITLE, 
         UPDATE_IDENTITY_PROVIDER_ID_ERROR, UPDATE_IDENTITY_PROVIDER_ID_SUCCESS} from 'src/app/user-alignment/models/constants'
import { UserAlignmentService } from '../../services/user-alignment.service'
import { LoggerService } from 'src/app/core/services/logger.service'
import { Navigation, Router } from '@angular/router'
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { GENERIC_API_ERROR } from 'src/app/rolecaps/models/constants'

@Component({
  selector: 'app-user-alignment-parties',
  templateUrl: './user-alignment-parties.component.html',
  styleUrls: ['./user-alignment-parties.component.scss']
})
export class UserAlignmentPartiesComponent implements OnInit, AfterViewInit {
  /** Displayed PartyIds */
  partyIds = []

  /** columnsToDisplay variable to store the displayed columns array for the parties */
  columnsToDisplay: string[] =
  [
    'select',
    'name',
    'id',
    'identityProviderId',
    'ssoValue',
    'selfRegDate',
    'updateDate',
    'remove'
  ]

  /** cmColumns variable to store the displayed columns for the contact-mechanisms table */
  cmColumns: TableColumn[] = [
    {
      name:   'Type',
      dataKey: 'type'
    },
    {
      name:   'Usage Type',
      dataKey: 'usageType'
    },
    {
      name:   'Details',
      dataKey: 'details'
    }
  ]

  /** roleColumns variable to store the displayed columns for the roles table */
  roleColumns: TableColumn[] = [
    {
      name:   'Name',
      dataKey: 'name'
    },
    {
      name:   'Associated Party',
      dataKey: 'associatedParty'
    },
    {
      name: 'Entity Name',
      dataKey: 'entityName'
    },
    {
      name:   'Client Number',
      dataKey: 'clientNumber'
    },
    {
      name:   'From Date',
      dataKey: 'fromDate'
    },
    {
      name:   'To Date',
      dataKey: 'toDate'
    }
  ]

  /** swampCrossReference variable to store the displayed columns for the swampCrossReference table */
  swampCrossReferenceColumns: TableColumn[] = [
    {
      name:   'SubjectArea Collection',
      dataKey: 'subjectAreaCollection'
    },
    {
      name:   'SubjectArea Id',
      dataKey: 'subjectAreaId'
    },
    {
      name: 'Swamp Collection',
      dataKey: 'swampCollection'
    },
    {
      name:   'Swamp ID',
      dataKey: 'swampId'
    },
    {
      name:   'Legacy Name',
      dataKey: 'legacyName'
    },
    {
      name:   'Legacy Id',
      dataKey: 'legacyID'
    }
  ]

  /** appUsersColumns variable to store the displayed columns for the appUsersColumns table */
  appUsersColumns: TableColumn[] = [
    {
      name:   'First Name',
      dataKey: 'FirstName'
    },
    {
      name:   'Last Name',
      dataKey: 'LastName'
    },
    {
      name: 'BusnPartEmpId',
      dataKey: 'BusnPartEmpId'
    },
    {
      name:   'PartyId',
      dataKey: 'partyId'
    },
    {
      name:   'EmpNTLogin',
      dataKey: 'EmpNTLogin'
    },
  ]

  /** users variable to store the parties  */
  users: Array<any> = []

  /** selectedUser variable to store the currently selected user */
  selectedUser = -1

  /** selected variable to store whether a user has been selected */
  selected = false

  /** eligibleSources variable to store the index of users eligible as copy sources */
  eligibleSources: Array<number> = []

  /** Subscription property for subscribing services */
  private readonly subscription: Subscription = new Subscription()

  /** Dialog Ref */
  dialogRef: MatDialogRef<any>

  /** Property to indicate if the component data is processing */
  processing = false

  /** To check for readonly access */
  disable = false

  /** Property to indicate if the people results should be displayed */
  displayResults = false

  /** disabledUsers variables to store the index values of disabled users */
  disabledUsers = new Set()

  /** Injecting dependencies */
  constructor(public dialog: MatDialog,
    public spinner: NgxSpinnerService,
    private readonly userAlignmentService: UserAlignmentService,
    private readonly notificationsService: NotificationsService,
    private readonly loggerService: LoggerService,
    private router: Router, private cdr: ChangeDetectorRef) {
      const nav: Navigation = this.router.getCurrentNavigation()
      if (nav?.extras?.state?.partyIds) {
        this.partyIds = nav.extras.state.partyIds
      } else {
        this.router.navigateByUrl('/project-alpha/user-alignment', { skipLocationChange: true });
      }
    }

  /** Component initialization */
  ngOnInit(): void {
    this.loadUsers()
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges()
  }

  /** Load Parties Data */
  loadUsers(isLoadUserOnPatialFailure: boolean = true) {
    this.displayResults = false
    this.spinner.show()
    this.processing = true
    this.userAlignmentService.getPeopleData(this.partyIds).subscribe({
      next: response => {
        if (response && response.status === 200 && response.body) {
          if (response.body.length > 1) {
            this.transformUsers(response.body)
            this.displayResults = true
          } else {
            let lines = []
            if (response.body.length === 1) {
              lines = [...GET_PEOPLE_DATA_ONE_PARTY_ERROR]
              lines[0] = lines[0].replace('$partyId', response.body[0]._id.toString())
            } else if (response.body.length === 0) {
              lines = [...GET_PEOPLE_DATA_ZERO_PARTIES_ERROR]
            }
            this.notificationsService.flashNotification('danger', { html: true, header: GET_PEOPLE_DATA_ERROR_HEADER, lines })
            this.cancel()
          }
        } else {
          if (response instanceof Error && response.toString().indexOf('People Data not found') > -1) {
            this.notificationsService.flashNotification('danger', { html: true, header: GET_PEOPLE_DATA_ERROR_HEADER, lines: GET_PEOPLE_DATA_ZERO_PARTIES_ERROR });
          } else {
            this.notificationsService.flashNotification('danger', GET_PEOPLE_DATA_ERROR);
          }
          this.cancel()
        }
      },
      error: err => {
        this.loggerService.error('Failed to get People data', err)
        this.displayResults = false
        this.notificationsService.flashNotification('danger', GET_PEOPLE_DATA_ERROR);
        this.cancel()
      },
      complete: () => {
        this.processing = false
        this.spinner.hide()
        if (isLoadUserOnPatialFailure) {
          if (this.users.length > 1) {
            const message = String(GET_PEOPLE_DATA_SUCCESS).replace('$x', this.users.length.toString()).replace('$y', this.partyIds.length.toString());
            this.notificationsService.flashNotification('success', message);
          }
        }
      }
    })
  }

  /**
   * Transforms the user users
   * @param users loadUsers response body
   */
  transformUsers(users) {
    users.forEach((u: PeopleDataItem) => {
      let contactMechanisms: Array<any> = (u.contactMechanisms_address || []).map((cm => {
        let addressLines
        if (cm.addressLines) {
          addressLines = cm.addressLines.join(' , ')
        }
        const cityStateCountry = cm.state ? `${cm.city}, ${cm.state}, ${cm.country}`
        : `${cm.city},  ${cm.country}`
        const addressDetails = addressLines ? addressLines.concat(cityStateCountry) : cityStateCountry
        
        return  {
          type: cm.contactType,
          usageType: cm.usageType,
          details: addressDetails
        }
      }))

      contactMechanisms = contactMechanisms.concat((u.contactMechanisms_email || []).map(cm => {
        return {
            type: cm.contactType,
            usageType: cm.usageType,
            details: cm.address
          }
      }))

      contactMechanisms = contactMechanisms.concat((u.contactMechanisms_phone || []).map(cm => {
          return {
            type: cm.contactType,
            usageType: cm.usageType,
            details: cm.number
          }
      }))

      let roles: Array<any> = []
      if (u.roles?.length) {
        let roleAssocationsCopy = deepCopy(u?.roles_associations)
        u.roles.forEach(r => {
          if (roleAssocationsCopy) {
            const foundIndex = roleAssocationsCopy.findIndex(ra => r.roleId == ra._id)
            if (foundIndex > -1) {
              const found = roleAssocationsCopy.find(ra => r.roleId == ra._id)
              roleAssocationsCopy.splice(foundIndex, 1)
              roles = [
                ...roles,
                {
                  name: found.name,
                  associatedParty: found.associatedParty,
                  clientNumber: found.roles_associatedParty.clientNumber,
                  entityName: found.roles_associatedParty.entityName,
                  fromDate: r.fromDate,
                  toDate: r.toDate,
                  ...(found.roles_associatedParty.parent_organization?._id && {
                    parentOrg: found.roles_associatedParty.parent_organization?._id})
                }
              ]
            }
          }
        })
      }

      const swampCrossReferences: Array<any> = (u.swampCrossReferences || []).map((swampCrossReference: any) => {
        swampCrossReference.subjectAreaCollection = swampCrossReference.subjectArea.collectionName
        swampCrossReference.subjectAreaId = swampCrossReference.subjectArea.id
        swampCrossReference.swampCollection = swampCrossReference.swamp.collectionName
        swampCrossReference.swampId = swampCrossReference.swamp.id
        swampCrossReference.legacyName = swampCrossReference.legacyId.name
        swampCrossReference.legacyID = swampCrossReference.legacyId.value

        return swampCrossReference
      })

      this.users = [
        ...this.users,
        {
          _id: u._id,
          name: u.currentName.names.join(' '),
          selfRegDate: u.selfRegDate ? new Date(u.selfRegDate).toLocaleDateString() : '',
          updateDate: u.updatedAt ? new Date(u.updatedAt).toLocaleDateString(): '',
          emailAddress: u.contactMechanisms_email?.length > 0 ? u.contactMechanisms_email[0]?.address : null,
          ...(u?.ssoValue && {
            ssoValue: u.ssoValue}),
          ...(u?.identityProviderId && {
            identityProviderId: u?.identityProviderId}),
          contactMechanisms: [
            ...contactMechanisms
          ],
          swampCrossReferences,
          roles: [
            ...roles
          ],
          appUsers: u.appUsers || [],
          ...(u?.identityProviderId && {
            identityProviderId: u?.identityProviderId})
        }
      ]
    })
    const ineligibleSources = new Set()

    for (let x = 0; x < this.users.length; x++ ) {
      // filter out parties without identityProviderId
      if (this.users[x].identityProviderId) {
        const iteratedRoles = new Set()
        const iteratedAssocParties = new Set()
        const iteratedParentOrgs = new Set()

        for (let y = 0; y < this.users[x].roles.length; y++ ) {
          iteratedRoles.add(this.users[x].roles[y].name)
          iteratedAssocParties.add(this.users[x].roles[y].associatedParty)
          if (this.users[x].roles[y].parentOrg) iteratedParentOrgs.add(this.users[x].roles[y].parentOrg)
        }
        // Only consider source roles if they have are of a given role and have one associatedParty
        if ((iteratedRoles.has('employee-of') || iteratedRoles.has('legacy-transferee') || iteratedRoles.has('transferee') )
            && iteratedAssocParties.size === 1 && !ineligibleSources.has(x)) {
          this.users[x].eligibleDestinations = []

          // cycle through each of the users to compare it to the iterated one
          for (let z = 0; z < this.users.length; z++) {
            if (z !== x) {
              if (!ineligibleSources.has(z) || this.users[x].identityProviderId !== this.users[z].identityProviderId) {
                const comparedRoles = new Set()
                const comparedAssocParties = new Set()
                const comparedParentOrgs = new Set()

                for (let y = 0; y < this.users[z].roles.length; y++) {
                  comparedRoles.add(this.users[z].roles[y].name)
                  comparedAssocParties.add(this.users[z].roles[y].associatedParty)
                  if (this.users[z].roles[y].parentOrg) comparedParentOrgs.add(this.users[z].roles[y].parentOrg)
                }
                if ((comparedRoles.has('employee-of') || comparedRoles.has('legacy-transferee') || comparedRoles.has('transferee'))
                    && comparedAssocParties.size === 1) {

                  // set of matching associated parties
                  const assocIntersection = new Set([...comparedAssocParties]
                    .filter(x => iteratedAssocParties.has(x)))
                  // set of matching parent orgs
                  const parentIntersection = new Set([...comparedParentOrgs]
                    .filter(x => iteratedParentOrgs.has(x)))
                  // set of differing associated parties
                  const assocDifferences = new Set([...comparedAssocParties]
                    .filter(x => !iteratedAssocParties.has(x)))
                  // set of differing parent orgs
                  const parentDifferences = new Set([...comparedParentOrgs]
                    .filter(x => !iteratedParentOrgs.has(x)))

                  // only consider users that have either matching associated parties without contradiction -or-
                  // matching parent orgs without contradiction (regardless of matching associated parties)
                  if ((assocIntersection.size && !assocDifferences.size)
                      && (!iteratedParentOrgs.size && !comparedParentOrgs.size)
                      || (iteratedParentOrgs.size && comparedParentOrgs.size)
                      && (parentIntersection.size && !parentDifferences.size)) {
                    this.users[x].eligibleDestinations.push(z)
                    if (!this.eligibleSources.includes(x)) {
                      this.eligibleSources.push(x)
                    }
                    if (!this.eligibleSources.includes(z)) {
                      this.eligibleSources.push(z)
                    }
                  }
                } else {
                  ineligibleSources.add(z)
                }
              }
            }
          }
        } else {
          ineligibleSources.add(x)
        }
      }
    }
  }

  /**
   * Checkbox event handler
   * @param index index of the user
   */
  onSelect(index: number) {
    if (!this.selected) {
      this.selectedUser = index
      this.selected = true
    } else {
      this.selectedUser = -1
      this.selected = false
    }
  }

  /**
   * Remove button event handler
   * @param index index of the user
   */
  onRemove(index: number) {
    this.users.splice(index, 1)
    if (this.selectedUser === index) {
      this.selectedUser = -1
      this.selected = false
    }
    if (this.selectedUser > -1 && index < this.selectedUser) {
      this.selectedUser--
    }
    let filteredArray: Array<number> = []
    this.users.forEach(element => {
      if (element.hasOwnProperty('eligibleDestinations') && element.eligibleDestinations.length) {
        filteredArray = element.eligibleDestinations.filter((b: number) => b !== index).map((a: number) => a > index ? a - 1 : a)
        element.eligibleDestinations = filteredArray
      }
    })
    filteredArray = this.eligibleSources.filter((b: number) => b !== index).map((a: number) => a > index ? a - 1: a)
    this.eligibleSources = filteredArray
    const newSet = new Set()
    this.disabledUsers.forEach((val: number) => {
      if (val > index) {
        newSet.add(val - 1)
      } else if (val !== index) {
        newSet.add(val)
      }
    })
    this.disabledUsers = newSet
  }

  /**
   * Determines if the user can be selected
   * @param index index of the user
   */
  isUserDisabled(index: number) {
    if (!this.eligibleSources.includes(index)) {
      this.disabledUsers.add(index)
      return true
    }
    if (this.selected && this.selectedUser > -1 && this.selectedUser !== index
        && this.eligibleSources.includes(index)) {
      if (!this.users[this.selectedUser]?.eligibleDestinations?.includes(index)
          || this.users[this.selectedUser].identityProviderId === this.users[index].identityProviderId) {
        this.disabledUsers.add(index)
        return true
      }
    }
    this.disabledUsers.delete(index)
    return false
  }

  /** Determines the button state */
  isButtonEnabled() {
    if (this.selected && this.users[this.selectedUser]?.eligibleDestinations.length
        && !this.disable && this.users.length && !this.disabledUsers.size) {
      return true
    }
    return false
  }

  resetLoadProperties() {
    this.users = []
    this.selectedUser = -1
    this.selected = false
    this.eligibleSources = []
    this.displayResults = false
    this.disabledUsers = new Set()
  }

  //** Opens the dialog */
  openDialog() {
    let isPartialFailure: boolean = false 
    this.processing = true;
    this.spinner.show()
    const destinationParties = this.users.filter(p => p._id !== this.users[this.selectedUser]._id).map(a => a._id )
    const dialogLines = deepCopy(UPDATE_IDENTITY_PROVIDER_ID_DIALOG_LINES)
    dialogLines.splice(1, 0, destinationParties.join(', '))
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      panelClass: 'dialogMainContainer',
      data: { dialogTitle: UPDATE_IDENTITY_PROVIDER_ID_DIALOG_TITLE, dialogLines },
      autoFocus: false,
      restoreFocus: false
    })
    this.subscription.add(
      this.dialogRef.afterClosed().subscribe(async (result: boolean) => {
        if (result) {
          this.processing = true;
          this.userAlignmentService.updateIdentityProviderId(this.users[this.selectedUser]._id, this.users[this.selectedUser].identityProviderId, destinationParties).subscribe({
            next:response => {
              if (response && response.status === 202 && response.body.name === 'PARTIAL_FAILURE_ERROR') {
                let lines = []
                let responseDetails = JSON.parse(response.body.details)
                responseDetails.forEach((detail: any) => {
                  if (detail.status === 'rejected') {
                    lines.push(String(REJECTED_ERROR_ON_UPDATE_IDENTITY_PROVIDER_ID).replace('$partyId', detail.partyId.toString()).replace('$message', detail.reason.message.toString()))
                  } else {
                    lines.push(String(FULFILLED_ON_UPDATE_IDENTITY_PROVIDER_ID).replace('$partyId', detail.partyId.toString()))
                  }
                })
                isPartialFailure = true
                this.notificationsService.flashNotification('warning', { html: true, header: GET_PEOPLE_DATA_ERROR_HEADER, lines })
              } else if (response && response.status === 200 && response.body) {
                this.notificationsService.flashNotification('success', UPDATE_IDENTITY_PROVIDER_ID_SUCCESS);
              } else if (response && response.status === 500) {
                this.notificationsService.flashNotification('danger', GENERIC_API_ERROR);
                this.cancel()
              } else {
                this.notificationsService.flashNotification('danger', UPDATE_IDENTITY_PROVIDER_ID_ERROR);
                this.cancel()
              }
            },
            error: err => {
              this.loggerService.error('Failed to update IdentityProviderId', err);
              this.notificationsService.flashNotification('danger', UPDATE_IDENTITY_PROVIDER_ID_ERROR);
            },
            complete:() => {
              this.processing = false;
              this.spinner.hide()
              if (!isPartialFailure) {
                this.router.navigateByUrl('/project-alpha/user-alignment', { skipLocationChange: true });
              } else {
                this.resetLoadProperties()
                this.loadUsers(false)
              }
            }
          });
        } else {
          this.processing = false
          this.spinner.hide()
          this.disable = false
          this.selected = false
        }
      })
    );    
  }

  /* Cancel button event handler. Redirects back to the party record search */
  cancel() {
    this.router.navigateByUrl('/project-alpha/user-alignment', { skipLocationChange: true, state: { partyIds: this.partyIds } });
  }

  /**
   * To check for readonly access
   * @param flag boolean
   */
  isDisabled(flag) {
    this.disable = flag
  }

}
