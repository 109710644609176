/** MOV360-880 - to be removed at future date */
import { Component, OnInit, Output, OnDestroy, Input, EventEmitter, Inject, ViewEncapsulation } from '@angular/core';
import { Selection } from '../../../../core/models/selection.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { LoggerService } from '../../../../core/services/logger.service';
import { UserContextService } from '../../../../core/services/user-context.service';


/** Base component for Move-Columns Component */
@Component({
  selector: 'app-move-columns',
  templateUrl: './move-columns.component.html',
  styleUrls: ['./move-columns.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MoveColumnsComponent implements OnInit, OnDestroy {
  /**
   * It Emits the data to the parent component
   */
  @Output() columnsListUpdated = new EventEmitter<Selection[]>();
  /**
   * Prop to store the updated column list
   */
  selectedColumnsList: Selection[] = [];
  /**
   * It stores the value for media observer
   */
  gridColumn: number;
  /**
   * media subscription
   */
  mediasbscription: Subscription;
  /**candidate full name */
  candidateFullname = 'candidate.fullname';
  /**candidate departure state */
  candidateDepartureState = 'candidate.departure.state';
  /**candidate destination state */
  candidateDestinationState = 'candidate.destination.state';
  /**
   * Prop to store the column names and flag details
   */
  columnsList: Selection[] = [
    {
      displayName: 'Full Name',
      value: this.candidateFullname,
      flag: true,
      disabled: true
    },
    {
      displayName: 'Move Phase',
      value: 'movePhase',
      flag: true,
      disabled: false
    },
    {
      displayName: 'Latest Milestone',
      value: 'latestMilestone',
      flag: true,
      disabled: true
    },
    {
      displayName: 'Authorized Amount',
      value: 'authorizedAmtFormat',
      flag: true,
      disabled: false
    },
    {
      displayName: 'Business Unit',
      value: 'candidate.businessUnit',
      flag: false,
      disabled: false
    },
    {
      displayName: 'Departure',
      value: this.candidateDepartureState,
      flag: true,
      disabled: false
    },
    {
      displayName: 'Level',
      value: 'candidate.level',
      flag: false,
      disabled: false
    },
    {
      displayName: 'Destination',
      value: this.candidateDestinationState,
      flag: true,
      disabled: false
    },
    {
      displayName: 'Created By',
      value: 'createdBy',
      flag: false,
      disabled: false
    },
    {
      displayName: 'Authorized By',
      value: 'authorizedBy',
      flag: false,
      disabled: false
    },
    {
      displayName: 'Job Start Date',
      value: 'jobStartDate',
      flag: false,
      disabled: false
    },
    {
      displayName: 'Order #',
      value: 'orderReference',
      flag: false,
      disabled: false
    }
  ];
  /**
   * Initializes the value
   * @param dialogRef Instance for DialogRef
   * @param mediaobserver Instance for media observer
   * @param data Instance for mat dialog data
   * @param Logger Object for LoggerService
   * @param loggedInUserService Object for UserContextService
   *
   */
  constructor(public dialogRef: MatDialogRef<MoveColumnsComponent>,
    private readonly mediaobserver: MediaObserver, @Inject(MAT_DIALOG_DATA) public data: string[], private readonly Logger: LoggerService,
    private readonly loggedInUserService: UserContextService) {
    this.mediasbscription = mediaobserver.asObservable().subscribe((val: MediaChange[]) => {
      if (val[0].mqAlias === 'xs') {
        this.gridColumn = 1;
      } else {
        this.gridColumn = 2;
      }
    });
  }
  /**
   * It resets the value for the view props
   */
  ngOnInit(): void {
    if (this.data.includes('client')) {
      const clientCol = {
        displayName: 'Client',
        value: 'client',
        flag: true,
        disabled: true
      };
      this.columnsList.splice(2, 0, clientCol);
    }
    this.data.forEach(item => {
      this.columnsList.forEach(col => {
        if (item === col.value) {
          col.flag = true;
          this.selectedColumnsList.splice(2, 0, col);
        } else {
          if (this.columnsList.findIndex(val => val.value !== item) > 0) {
            col.flag = false;
          }
        }
      });
    });
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-MOVE_COLUMNS');
      });

  }
  /**
   * Method to initialize the selected array list
   */
  populateArray(): void {
    this.selectedColumnsList = [
      {
        displayName: 'Full Name',
        value: this.candidateFullname,
        flag: true,
        disabled: true
      },
      {
        displayName: 'Move Phase',
        value: 'movePhase',
        flag: false,
        disabled: false
      },
      {
        displayName: 'Authorized Amount',
        value: 'authorizedAmtFormat',
        flag: true,
        disabled: false
      },
      {
        displayName: 'Departure',
        value: this.candidateDepartureState,
        flag: true,
        disabled: false
      },
      {
        displayName: 'Destination',
        value: this.candidateDestinationState,
        flag: true,
        disabled: false
      },
      {
        displayName: 'Latest Milestone',
        value: 'latestMilestone',
        flag: true,
        disabled: true
      },
    ];
  }
  /**
   * updates the new column list
   * @param selected - sends the selected array value
   */
  updateChkbxArray(selected: any) {
    const index = this.columnsList.findIndex(x => x.value === selected.value);
    this.columnsList[index].flag = !selected.flag;
    if (this.columnsList[index].flag === true) {
      this.selectedColumnsList.splice(this.selectedColumnsList.length - 1,
        0, { displayName: selected.displayName, value: selected.value, flag: !selected.flag, disabled: selected.disabled });
    } else {
      const ind: number = this.selectedColumnsList.findIndex(y => y.value === selected.value);
      if (ind !== -1) {
        this.selectedColumnsList.splice(ind, 1);
      }
    }
  }
  /**
   * Closing the dialog box - we are setting the form to empty
   */
  onNoClick(evt): void {
    evt.preventDefault();
    this.dialogRef.close();
  }
  /**
   * Resets the value to default version
   */
  resetValues(): void {
    this.populateArray();
    const colListToMatch = [
      this.candidateFullname, 'movePhase', 'authorizedAmtFormat', 'client',
      this.candidateDepartureState, this.candidateDestinationState, 'latestMilestone'
    ];
    this.columnsList.forEach(col => {
      colListToMatch.find(val => val === col.value) === undefined ?
        col.flag = false : col.flag = true;
      if (col.value === 'client') {
        this.selectedColumnsList.splice(1, 0, col);
      }
    });
  }
  /**
   * Emits the updated array to parent component
   */
  save(): void {
    this.dialogRef.close();
    this.columnsListUpdated.emit(this.selectedColumnsList);
  }
  /**
   * destroys the object
   */
  ngOnDestroy(): void {
    this.mediasbscription.unsubscribe();
  }
}
