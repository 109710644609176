/** MOV360-880 - to be removed at future date */
import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  Input,
  OnDestroy
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent} from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { UntypedFormGroup } from '@angular/forms';
import { Selection } from '../../../core/models/selection.model';
import { Client } from '../../../core/models/client';
import { ClientServiceService } from '../../../core/services/client-service.service';
import { CandidateProfilesService } from '../../../core/services/candidate-profiles.service';
import { ClientColumnsComponent } from '../client/client-columns/client-columns.component';
import { ClientContact } from '../../../core/models/client-contact';
import { roleType } from '../../../core/models/client-role';
import { NgxSpinnerService } from 'ngx-spinner';
import { PartySharedService } from 'src/app/core/services/party-shared.service';
import { ClientContactService } from '../../../core/services/client-contact.service';
import { AddClientComponent } from '../client/add-client/add-client.component';
import { NotificationsService } from '../../../../../src/app/core/services/notifications.service';
import { Subscription } from 'rxjs';
import { InactivateClientComponent } from '../client/inactivate-client/inactivate-client.component';
import { LoggerService } from '../../../core/services/logger.service';
import { UserContextService } from '../../../core/services/user-context.service';
import { APIResponse } from 'src/app/core/models/response.model';
import { apiErrorMessage } from 'src/app/core/models/constants';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})

export class ClientComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'select',
    'clientPreferredName',
    'clientAddress.state',
    'billingContact.lastName',
    'status'
  ];

  addCandidateForm: UntypedFormGroup;
  dataSource: any;
  ELEMENT_DATA: Client[] = [];
  billingContacts: any;
  filterText = '';
  deleteFlag = false;
  clientRoleName = '';

  currentClientCount: number;
  inactivateButtonText: string;

  selection = new SelectionModel<any>(true, []);
  columnList: Selection[] = [];

  /**stores count of active candidates */
  initialCount = 0;

  /** Subscription prop for unsubscribing services */
  private subscription: Subscription = new Subscription();

  constructor(
    public dialog: MatDialog,
    private clientService: ClientServiceService,
    private clientContactService: ClientContactService,
    public spinner: NgxSpinnerService,
    private partySharedSvc: PartySharedService,
    public snackBar: MatSnackBar,
    private notificationsService: NotificationsService,
    private candidateProfilesService: CandidateProfilesService,
    private Logger: LoggerService,
    private loggedInUserService: UserContextService
  ) { }

  /** To sort the mat table columns */
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  /** To paginate in a mat table */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @Input() selectedCols: Selection[];

  ngOnInit() {
    this.spinner.show();
    this.subscription.add(this.clientService
      .getClients()
      .subscribe(models => {
        models.forEach(model => {
          if (model.billingContact === undefined) {
            model.billingContact = this.getEmptyClientContact(model.partyID);
          } else {
            const clientContactRole = model.billingContact.role.find(r => r.name === 'client-contact-initiator' ||
              r.name === 'client-contact-administrator');
            model.billingContact.clientContactType = clientContactRole !== undefined ? clientContactRole.name : 'Billing Contact';
          }
        });
        this.ELEMENT_DATA = models.filter(model => model.isDeleted === false);
        // console.log(this.ELEMENT_DATA);
        this.currentClientCount = this.ELEMENT_DATA.length;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        this.dataSource.data = models;
        this.dataSource.sortingDataAccessor = this.getPropertyByPath;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        setTimeout(() => {
          this.paginator.length = this.ELEMENT_DATA.length;
        });
        this.dataSource.filterPredicate = this.customFilterPredicate;
        this.calculateActiveClients(this.dataSource);
        this.spinner.hide();
        this.loggedInUserService.getLoggedInUserDetails()
          .subscribe(response => {
            const userId: any = response.userId.replace(/ .*/, '');
            this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-CLIENT', 'CLIENT');
          });
      }));
    this.inactivateButtonText = `Set to Inactive`;
  }

  loadClients(pageSize: number, skipRecords: number) {
    this.spinner.show();
    this.subscription.add(
      this.clientService
        .getClients()
        .subscribe({
          next: clients => {
            if (clients) {
              this.initialCount = Number(clients.length);
              this.ELEMENT_DATA = clients;
              this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
              this.dataSource.sortingDataAccessor = this.getPropertyByPath;
              this.dataSource.sort = this.sort;
              setTimeout(() => {
                this.paginator.length = Number(clients.length);
              });
              this.dataSource.filterPredicate = this.customFilterPredicate;
              this.calculateActiveClients(this.initialCount);
              this.spinner.hide();
            } else {
              this.calculateActiveClients(this.initialCount);
              this.spinner.hide();
              this.snackBar.open(
                apiErrorMessage,
                undefined,
                { duration: 5000 }
              );
            }
          },
          error: () => {
            this.spinner.hide();
          }
        })
    );
  }

  getEmptyClientContact(partyID): ClientContact {
    return {
      'clientContactID': '',
      'clientID': partyID,
      'firstName': '',
      'lastName': '',
      'emailAddress': '',
      'phoneNumber': '',
      'role': [{
        roleID: '',
        roleDescrpition: '',
        roleName: roleType.noRole
      }],
      'isBillingContact': true,
      'invitedAsClientContact': false,
      'status': 'Active',
      'statusDate': '',
      'isDeleted': false,
      countryDialingCode: '+1'
    };
  }

  formatClients(models): Client[] {
    // models.forEach(client => {
    //   client.
    // });
    return models;
  }

  customFilterPredicate(data, filter): boolean {
    const dataStr =
      data.clientID +
      data.clientPreferredName +
      data.billingContact.firstName +
      data.billingContact.lastName +
      data.clientAddress.city +
      data.clientAddress.state +
      data.status;
    return dataStr.search(new RegExp(filter, 'i')) !== -1;
  }

  applyFilter(filterValue: string) {
    this.filterText = filterValue.trim();
    this.dataSource.filter = filterValue;
  }

  getPropertyByPath(obj: Object, pathString: string) {
    return pathString.split('.').reduce((o, i) => o[i], obj);
  }

  /**Function to update the client in the table */
  updateDataSource(data: Client) {
    this.spinner.show();
    if (data) {
      this.spinner.hide();
      this.getUpdatedData();
    }
  }

  calculateActiveClients(dataSource) {
    if (dataSource === undefined) {
      setTimeout(() => this.candidateProfilesService.testSubject.next({
        type: 'Clients',
        length: 0,
        label: 'Clients'
      }), 0);
    } else {
      setTimeout(() => this.candidateProfilesService.testSubject.next({
        type: 'Clients',
        length: dataSource.data.length,
        label: 'Clients'
      }), 0);
    }
  }

  /* Method to check if all the rows in the mat table were selected*/
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    let numRows = 0;
    if (this.dataSource !== undefined) {
      numRows = this.dataSource.data.length ? this.dataSource.data.length : 0;
    }
    return numSelected === numRows;
  }

  /* Method to toggle select all or clear all for rows inside in the mat table */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
    if (this.selection.selected.length === 1) {
      this.inactivateButtonText = `Set to Inactive`;
    } else {
      this.inactivateButtonText = `Inactivate ${this.selection.selected.length} Clients`;
    }
  }

  checkboxLabel(row?: Client): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    if (this.selection.selected.length === 1) {
      this.inactivateButtonText = `Set to Inactive`;
    } else {
      this.inactivateButtonText = `Inactivate ${this.selection.selected.length} Clients`;
    }
    return `${
      this.selection.isSelected(row) ? 'deselect' : 'select'
      } row ${row.clientPreferredName + 1}`;
  }

  /* Open the dialog box AddClientContactComponent in EDIT mode when any row is clicked of the mat table*/
  public open(event, data): void {
    const dialogRef = this.dialog.open(AddClientComponent, {
      panelClass: 'dialogMainContainer',
      data: data
    });

    this.subscription.add(dialogRef.afterOpened().subscribe(result => {
      // TO DO:
    }));

    this.subscription.add(dialogRef.afterClosed().subscribe((client: Client) => {
      if (client) {
        if (client.executedFunctionType === 'Update') {
          this.notificationsService.flashNotification(
            'success',
            'Updated client successfully.',
            true,
            'dismiss'
          );
          this.getUpdatedData();
        } else if (client.executedFunctionType === 'Inactivate') {
          this.selection.selected.push(client);
          this.inactivateClients(true);
          this.getUpdatedData();
        } else if (client.executedFunctionType === 'Activate') {
          this.notificationsService.flashNotification(
            'success',
            'Activated client successfully.',
            true,
            'dismiss'
          );
          this.getUpdatedData();
        } else {
          this.getUpdatedData();
        }
      }
    }));
  }

  public deleteRow(event, data, deleteFlag): void {
    data.isDeleted = deleteFlag;
    const dialogRef = this.dialog.open(AddClientComponent, {
      panelClass: 'dialogMainContainer',
      data: data
    });

    this.subscription.add(dialogRef.afterOpened().subscribe(result => {
      // TO DO:
    }));

    this.subscription.add(dialogRef.afterClosed().subscribe(result => {
      this.clientService.getClients().subscribe(resp => {
        if (resp) {
          const updatedCount = resp.length;
          if (updatedCount < this.currentClientCount) {
            this.notificationsService.flashNotification(
              'danger',
              'Client Inactivated Successfully.',
              true,
              'dismiss'
            );
          }
          this.getUpdatedData();
        }
      });
    }));
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(AddClientComponent, {
      panelClass: 'dialogMainContainer'
    });
    this.subscription.add(dialogRef.afterClosed().subscribe((client: Client) => {
      this.clientService.getClients().subscribe(resp => {
        this.spinner.show();
        if (resp) {
          const updatedCount = resp.length;
          if (updatedCount > this.currentClientCount) {
            this.notificationsService.flashNotification(
              'success',
              'Added client successfully.',
              true,
              'dismiss'
            );
          }
          this.spinner.hide();
          this.getUpdatedData();
        }
      });
    }));
  }

  openModal(): void {
    const dialogRef = this.dialog.open(ClientColumnsComponent, {
      panelClass: 'DisplayedColumnsModal',
      data: this.displayedColumns
    });
    this.subscription.add(dialogRef.componentInstance.columnsListUpdated.subscribe(
      (response: Selection[]) => {
        this.columnList = response;
        this.selectedCols = this.columnList;
        this.updateTable();
      }
    ));
  }

  updateTable(): void {
    this.selectedCols.forEach((item, index) => {
      if (this.displayedColumns.indexOf(item.value) < 0) {
        this.displayedColumns.push(item.value);
      } else {
        this.displayedColumns = this.displayedColumns.filter(val => {
          return item.value === val;
        });
      }
    });
    if (this.displayedColumns.findIndex(val => val === 'select') < 0) {
      this.displayedColumns.unshift('select');
    }
  }

  inactivateClients(deleteFlag) {
    const payload = {
      noOfSelected: this.selection.selected.length,
      selectedClients: this.selection.selected,
      deleteDialog: deleteFlag
    };
    const dialogRef = this.dialog.open(InactivateClientComponent, {
      panelClass: 'dialogMainContainer',
      data: payload
    });

    dialogRef.afterOpened().subscribe(result => {
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'inActivateClients') {
        this.spinner.show();
        this.subscription.add(this.clientService.inActivateClient(this.selection.selected).subscribe((response: APIResponse) => {
          if (response.statusCode === 200) {
            this.spinner.hide();
            this.selection.selected.forEach(client => this.clientService.deleteClient(client.clientID));
            this.selection.clear();
            this.notificationsService.flashNotification('danger', 'Client(s) inactivated successfully.', true, 'dismiss');
            this.getUpdatedData();
          }
        }));
      } else {
        this.selection.clear();
      }
    });
  }

  getUpdatedData() {
    this.spinner.show();
    this.subscription.add(this.clientService
      .getClients()
      .subscribe(models => {
        models.forEach(model => {
          if (model.billingContact === undefined) {
            model.billingContact = this.getEmptyClientContact(model.partyID);
          } else {
            const clientContactRole = model.billingContact.role.find( r => r.name === 'client-contact-initiator' ||
              r.name === 'client-contact-administrator' );
            model.billingContact.clientContactType = clientContactRole !== undefined ? clientContactRole.name : 'Billing Contact';
          }
        });
        this.ELEMENT_DATA = models.filter(model => model.isDeleted === false);
        // console.log(this.ELEMENT_DATA);
        this.currentClientCount = this.ELEMENT_DATA.length;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        this.dataSource.data = models;
        this.dataSource.sortingDataAccessor = this.getPropertyByPath;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        setTimeout(() => {
          this.paginator.length = this.ELEMENT_DATA.length;
        });
        this.dataSource.filterPredicate = this.customFilterPredicate;
        this.calculateActiveClients(this.dataSource);
        this.spinner.hide();
        this.loggedInUserService.getLoggedInUserDetails()
          .subscribe(response => {
            const userId: any = response.userId.replace(/ .*/, '');
            this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-CLIENT', 'CLIENT');
            this.getDataFromAPI();
          });
      }));
  }

  getDataFromAPI() {
    this.spinner.show();
    this.subscription.add(this.clientService
      .getClients()
      .subscribe(models => {
        models.forEach(model => {
          if (model.billingContact === undefined) {
            model.billingContact = this.getEmptyClientContact(model.partyID);
          } else {
            const clientContactRole = model.billingContact.role.find( r => r.name === 'client-contact-initiator' ||
              r.name === 'client-contact-administrator' );
            model.billingContact.clientContactType = clientContactRole !== undefined ? clientContactRole.name : 'Billing Contact';
          }
        });
        this.ELEMENT_DATA = models.filter(model => model.isDeleted === false);
        // console.log(this.ELEMENT_DATA);
        this.currentClientCount = this.ELEMENT_DATA.length;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        this.dataSource.data = models;
        this.dataSource.sortingDataAccessor = this.getPropertyByPath;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        setTimeout(() => {
          this.paginator.length = this.ELEMENT_DATA.length;
        });
        this.dataSource.filterPredicate = this.customFilterPredicate;
        this.calculateActiveClients(this.dataSource);
        this.spinner.hide();
        this.loggedInUserService.getLoggedInUserDetails()
          .subscribe(response => {
            const userId: any = response.userId.replace(/ .*/, '');
            this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-CLIENT', 'CLIENT');
          });
      }));
  }

  /**
      * destroys the object
     */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
