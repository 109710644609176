/** MOV360-880 - to be removed at future date */
import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { LocationService } from 'src/app/core/services/location.service';
import { Supplier } from 'src/app/core/models/add-supplier.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import * as moment from 'moment';
import { SupplierAddress } from 'src/app/core/models/supplieraddress.model';
import { SupplierService } from 'src/app/core/services/supplier.service';
import { SupplierResponse } from 'src/app/core/models/supplierRespose.model';
import { errorMessage } from 'src/app/core/models/constants';
import { UserContextService } from 'src/app/core/services/user-context.service';
import { BrowserStorageService } from 'src/app/core/services/browser-storage.service';

export const fixDialogFocus = (dialogRef: MatDialogRef<any>) => {
  /* eslint-disable */
  if (event && event.currentTarget && (<any>event.currentTarget).blur) {
    const initiator: any = event.currentTarget;
    /* eslint-enabled */
    dialogRef.afterClosed().subscribe(() => {
      setTimeout(() => {
        try { initiator.blur(); } catch { }
      }, 0);
    });
  }
};

@Component({
  selector: 'app-add-supplier',
  templateUrl: './add-supplier.component.html',
  styleUrls: ['./add-supplier.component.scss']
})
export class AddSupplierComponent implements OnInit {

  addSupplierForm: UntypedFormGroup;
  stateList: any[];
  lockFields = false;
  checkFirstTimeEntry = true;
  active_or_inactive = 'Activate/Inactivate Suppliers';
  /**addSupplier  model of type Supplier */
  addSupplier: Supplier = {} as Supplier;
  addEditSupplier = 'Add Supplier';
  supplierInfo: any;

  constructor(public dialogRef: MatDialogRef<AddSupplierComponent>,
    private readonly formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly _router: Router,
    private locationService: LocationService,
    private readonly spinner: NgxSpinnerService,
    private notificationsService: NotificationsService,
    private supplierService: SupplierService,
    private readonly userContextService: UserContextService,
    private readonly browserStorageService: BrowserStorageService
  ) {
    this.stateList = this.locationService.getStates();
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  ngOnInit() {
    fixDialogFocus(this.dialogRef);
    this.supplierInfo = this.data;
    this.addSupplierForm = this.formBuilder.group({
      supplierlegalname: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
      supplierpreferredname: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
      vendornumber: this.supplierInfo.edit ? ['', Validators.compose([Validators.required, this.noWhitespaceValidator])] : [''],
      country: [''],
      streetaddress: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
      city: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
      state: ['', Validators.required],
      zipcode: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])]
    });
    this.addSupplierForm.controls['country'].setValue('United States of America');

    if (!!this.supplierInfo.edit) {
      this.addEditSupplier = 'Edit Supplier';
      this.addSupplierForm.get('supplierlegalname').setValue(this.supplierInfo.supplierDetails.entityName ?
        this.supplierInfo.supplierDetails.entityName : this.supplierInfo.supplierDetails.supplierLegalName);
      this.addSupplierForm.get('supplierpreferredname').setValue(this.supplierInfo.supplierDetails.preferredName ?
        this.supplierInfo.supplierDetails.preferredName : this.supplierInfo.supplierDetails.supplierPreferredName);
      this.addSupplierForm.get('vendornumber').setValue('');
      this.addSupplierForm.get('streetaddress').setValue(this.supplierInfo.supplierDetails.contactMechanisms ?
        this.supplierInfo.supplierDetails.contactMechanisms[0].addressLines[0] :
          this.supplierInfo.supplierDetails.supplierAddress.streetAddress ?
          this.supplierInfo.supplierDetails.supplierAddress.streetAddress : '');
      this.addSupplierForm.get('city').setValue(this.supplierInfo.supplierDetails.contactMechanisms ?
        this.supplierInfo.supplierDetails.contactMechanisms[0].city :
          this.supplierInfo.supplierDetails.supplierAddress.city ?
          this.supplierInfo.supplierDetails.supplierAddress.city : '');
      this.addSupplierForm.get('state').setValue(this.supplierInfo.supplierDetails.contactMechanisms ?
        this.supplierInfo.supplierDetails.contactMechanisms[0].state :
          this.supplierInfo.supplierDetails.supplierAddress.state ?
          this.supplierInfo.supplierDetails.supplierAddress.state : '');
      this.addSupplierForm.get('zipcode').setValue(this.supplierInfo.supplierDetails.contactMechanisms ?
        this.supplierInfo.supplierDetails.contactMechanisms[0].postalCode :
          this.supplierInfo.supplierDetails.supplierAddress.zipcode ?
          this.supplierInfo.supplierDetails.supplierAddress.zipcode : '');
    }
  }

  initializeSupplierForm() {
    const dateString = moment(new Date()).format('YYYY-MM-DD');

    const supplierAddress: SupplierAddress = {
      'country': (this.addSupplierForm.get('country') && this.addSupplierForm.get('country').value) ?
        this.addSupplierForm.get('country').value : '',
      'streetAddress': (this.addSupplierForm.get('streetaddress') && this.addSupplierForm.get('streetaddress').value) ?
        this.addSupplierForm.get('streetaddress').value : '',
      'city': (this.addSupplierForm.get('city') && this.addSupplierForm.get('city').value) ?
        this.addSupplierForm.get('city').value : '',
      'state': (this.addSupplierForm.get('state') && this.addSupplierForm.get('state').value) ?
        this.addSupplierForm.get('state').value : '',
      'zipcode': (this.addSupplierForm.get('zipcode') && this.addSupplierForm.get('zipcode').value) ?
        this.addSupplierForm.get('zipcode').value : ''
    };
    this.addSupplier = {
      'supplierLegalName': (this.addSupplierForm.get('supplierlegalname') && this.addSupplierForm.get('supplierlegalname').value) ?
        this.addSupplierForm.get('supplierlegalname').value : '',
      'supplierPreferredName': (this.addSupplierForm.get('supplierpreferredname') &&
        this.addSupplierForm.get('supplierpreferredname').value) ? this.addSupplierForm.get('supplierpreferredname').value : '',
      'supplierNumber': '',
      'supplierAddress': supplierAddress,
      'status': 'Draft',
      'statusDate': dateString,
      'supplierRole': 'supplier',
      'createdDate': dateString,
      'createdBy': this.browserStorageService.getSessionStorageValue('car-ses-con'),
      'isDeleted': false
    };
  }

  /*navigate to supplier details*/
  onSave(event) {
    event.preventDefault();
    event.stopPropagation();
    this.spinner.show();
    this.initializeSupplierForm();
    if (!!this.supplierInfo.edit) {
      this.spinner.hide();
      this.dialogRef.close(true);
    } else {
      this.supplierService.createSupplier(this.addSupplier).subscribe((response: SupplierResponse) => {
        this.spinner.hide();
        if (response !== null && response.statusCode === 200) {
          this.flashAndCloseDialog(response.message);
          this._router.navigate(['/project-alpha/supplier/details'], {
            state: this.addSupplier
          });
        } else {
          this.dialogRef.close(true);
          this.notificationsService.flashNotification(
            'success',
            errorMessage,
            true,
            'dismiss'
          );
        }
      });
    }
  }

  toggleDuplicateFlag() {
    this.checkFirstTimeEntry = false;
  }

  onSearchChange(searchValue: string): void {
    if (this.checkFirstTimeEntry || this.addSupplierForm.get('supplierpreferredname').value === '') {
      this.addSupplierForm.get('supplierpreferredname').setValue(this.addSupplierForm.value['supplierlegalname']);
    }
  }


  formatDate(date) {
    return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
  }

  /**
   * Closing the dialog box - we are setting the form to empty
   */
  onNoClick(event): void {
    event.preventDefault();
    event.stopPropagation();
    this.dialogRef.close();
    this.initializeSupplierForm();
  }
  /**
  * function to flash message and close dialog
  * @param response string
  */
  flashAndCloseDialog(message: string) {
    this.dialogRef.close(true);
    this.notificationsService.flashNotification(
      'success',
      'Supplier Added',
      true,
      'dismiss'
    );
  }
  /**
  * Closing the dialog box
  */
  close(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.dialogRef.close(true);
  }

}
