/** MOV360-880 - to be removed at future date */
import { Component, OnInit } from '@angular/core';
import { LoggerService } from '../../../core/services/logger.service';
import { UserContextService } from '../../../core/services/user-context.service';

/**Class for ExploreDestinationsComponent */
@Component({
  selector: 'app-explore-destinations',
  templateUrl: './explore-destinations.component.html',
  styleUrls: ['./explore-destinations.component.scss']
})
export class ExploreDestinationsComponent implements OnInit {

  /**variable to store breakpoint */
  breakpoint: number;
  /**array to store images */
  masonryImages = [
    {
      image: '../../../../assets/images/explore-destinations/img_neighboods.png',
      label: 'Neighborhoods',
    },
    {
      image: '../../../../assets/images/explore-destinations/img_schools.png',
      label: 'Schools',
    },
    {
      image: '../../../../assets/images/explore-destinations/img_thingsToDo.png',
      label: 'Things To Do',
    },
  ];

  /**constructor to inject dependencies */
  constructor(private readonly Logger: LoggerService,
    private readonly loggedInUserService: UserContextService
  ) { }
  /**To initialise the component */
  ngOnInit() {
    this.breakpoint = (window.innerWidth <= 400) ? 1 : 6;
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-EXPLORE_DESTINATIONS');
      });
  }
}
