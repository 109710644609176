/** MOV360-880 - to be removed at future date */
import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import {
  withdrawCandidateTitle,
  withdrawCandidateDisplayText1,
  withdrawCandidateDisplayText2
} from '../../../../core/models/constants';
import { LoggerService } from '../../../../core/services/logger.service';
import { UserContextService } from '../../../../core/services/user-context.service';


/** Base component for Withdraw Candidate Modal */
@Component({
  selector: 'app-withdraw-candidate',
  templateUrl: './withdraw-candidate.component.html',
  styleUrls: ['./withdraw-candidate.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WithdrawCandidateComponent implements OnInit {
  /** Title for withdraw modal */
  title = withdrawCandidateTitle;
  /** Text to be displayed - first line */
  displayText1 = withdrawCandidateDisplayText1;
  /** Text to be displayed - last line */
  displayText2 = withdrawCandidateDisplayText2;
  /**
   * base constructor
   * @param data data received from calling component
   * @param dialogRef MatDialogRef component
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<WithdrawCandidateComponent>,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: UserContextService
  ) { }

  /** Function to be called on init  */
  ngOnInit() {
    this.data.selectedCandidates.sort((firstData, secondData) => (firstData.fullname > secondData.fullname) ? 1 : -1);
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-WITHDRAW_CANDIDATE', 'WITHDRAW_CANDIDATE');
      });

  }

  /** Function to be called on cancel/close; returns 'close' to calling component */
  onNoClick(evt) {
    evt.preventDefault();
    this.dialogRef.close('cancel');
  }
  /** Function to be called on confirmation; returns 'withdraw' to calling component */
  withdraw() {
    this.dialogRef.close('withdraw');
  }
}
