/** MOV360-880 - to be removed at future date */
import { Component, OnInit, Input } from '@angular/core';
/**Pie chart comonent */
@Component({
  selector: 'app-budget-chart',
  templateUrl: './budget-chart.component.html',
  styleUrls: ['./budget-chart.component.scss']
})
export class BudgetChartComponent implements OnInit {
  /**Input property received from budgetDetails component */
  @Input() serviceDetails: any;
  /**variable to store type */
  public type;
  /**variable to store data */
  public data;
  /**variable to store options */
  public options;

  /**injecting dependencies */
  constructor() { }

  /**Initialise the component */
  ngOnInit() {
    this.type = 'PieChart';
    this.data = [
      [
        'Core',
        {
          v: this.serviceDetails.coreBenefitsTotal,
          f: this.serviceDetails.coreBenefitsTotal.toLocaleString()
        }
      ],
      [
        'Flex',
        {
          v: this.serviceDetails.flexSpendTotal,
          f: this.serviceDetails.flexSpendTotal.toLocaleString()
        }
      ]
    ];

    this.options = {
      backgroundColor: 'transparent',
      colors: ['#44A4B9', '#8BBD3F'],
      pieSliceText: 'none',
      is2D: true,
      sliceVisibilityThreshold: 0,
      legend: {
        position: 'labeled',
        labeledValueText: 'value'
      },
      pieStartAngle: 180,
      height: 65,
      chartArea: { width: '40%', height: '80%' },
      enableInteractivity: false,
      slices: {
        1: { offset: 0.1 }
      }
    };
  }
}
