/** MOV360-880 - to be removed at future date */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActiveSuppliers } from 'src/app/core/models/active-suppliers.model';
import { Supplier } from 'src/app/core/models/add-supplier.model';
import { AddContractComponent } from '../../supplier-details-info/add-contract/add-contract.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ExtensionService } from 'src/app/core/services/extension.service';
import { Extension } from 'src/app/core/models/extension.model';
import { SupplierContract, SupplierContractData } from 'src/app/core/models/contract-suppliers.model';
import { SupplierContractService } from 'src/app/core/services/supplier-contract.service';
import { SupplierContactService } from 'src/app/core/services/supplier-contact.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-supplier-details',
  templateUrl: './supplier-details.component.html',
  styleUrls: ['./supplier-details.component.scss']
})
export class SupplierDetailsComponent implements OnInit, OnDestroy {

   /**mat accordion panel header height */
   matExpansionHeaderHeight = '40px';

  supplierInfo: ActiveSuppliers;

  supplierDetails: Supplier;

  supplierLegalName: string;

  preferredName: string;

  clickedSupplier: any;

  isContractAdded = false;
  defaultExtension: any;
  isManageSupplierCapability = false;
  isSubscriptionAvailable = false;
  supplierContractData: SupplierContractData;
  supplierContract: any = {};
  Contracts = [];
  currentIndex = 0;
  subscriptions: Subscription[] = [];
  constructor(private router: Router,
    public dialog: MatDialog,
    private extnSvc: ExtensionService,
    private supplierContractService: SupplierContractService,
    private supplierContactService: SupplierContactService) {
      if (this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation().extras.state) {
      this.clickedSupplier = this.router.getCurrentNavigation().extras.state;
      } else {
      this.router.navigate(['/project-alpha/suppliers']);
      }
  }

  ngOnInit() {
    this.subscribeForContractIndex();
    this.subscribeForContractData();
    this.subscribeForManageSupplier();
    this.setSupplierName();
      this.extnSvc.getExtensionDetails().subscribe((extensions: any[]) => {
        this.defaultExtension = extensions.find(x => x.name === 'United States');
        const extensionDetails: any[] = [];
        for (let i = 0; i < extensions.length; i++) {
          const extn = extensions[i];
          const extensionData: Extension = {
            countryDialingCode: `+${extn.dialingCode}`,
            name: extn.name
          };
          extensionDetails.push(extensionData);
        }
        this.extnSvc.extension$.next(extensionDetails);
      });
  }
  /**This Method is to subscribe to get data of daily operation when it add or update */
subscribeForDailyOperation() {
  this.subscriptions.push(this.supplierContractService.currentDailyOperationData.subscribe(data => {
    if (data.firstName) {
      this.setSupplierContractData();
      this.supplierContract.dailyOperationContract = data;
      this.setContracts();
    }
  }));
}
subscribeForContractIndex() {
  this.subscriptions.push(this.supplierContractService.currentContractIndex.subscribe(data => {
    this.currentIndex = data;
    if (this.Contracts[this.currentIndex]) {
      this.supplierContract = this.Contracts[this.currentIndex];
    }
  }));
}

subscribeForContractData() {
  this.subscriptions.push(this.supplierContractService.currentContractData.subscribe(data => {
    if (data.contractServiceType) {
      this.setSupplierContractData();
      this.supplierContract.contract = data;
      this.setContracts();
    }
  }));
}

subscribeForOracleSiteId() {
  this.subscriptions.push(this.supplierContractService.currentOracleSiteData.subscribe(data => {
    if (data.length > 0) {
      this.setSupplierContractData();
      this.supplierContract.oracleSiteIds = data;
      this.setContracts();
    } else {
      this.supplierContract.oracleSiteIds = data;
      this.setContracts();
    }
  }));
}

subscribeForTerritoryData() {
  this.subscriptions.push(this.supplierContractService.currentTerritoryData.subscribe(data => {
    if (data.length > 0) {
      this.setSupplierContractData();
      this.supplierContract.territoryCoverages = data;
      this.setContracts();
    } else {
      this.supplierContract.territoryCoverages = data;
      this.setContracts();
    }
  }));
}

/**This Method is to set Contract status by default it set to Draft */
setContractStatus() {
  this.supplierContract.contractStatus = 'Draft';
  this.setContracts();
}
/**This method is to set the contracts */
setContracts() {
  if (this.isContractAdded) {
    if (this.Contracts.length > this.currentIndex) {
      this.Contracts[this.currentIndex] = this.supplierContract;
    } else {
      this.Contracts.push(this.supplierContract);
    }
    this.setContractButtonEnableState();
  }

}
  setSupplierName() {
    this.preferredName = this.clickedSupplier.supplierPreferredName ?
      this.clickedSupplier.supplierLegalName : this.clickedSupplier.entityName;
  }
  /** This method is to open add contract popup */
  addContract() {
    this.supplierContract = {};
    const dialogRef = this.dialog.open(AddContractComponent, {
      disableClose: true,
      panelClass: ['dialogMainContainer', 'authorize-dialog-container'],
      data: {index: this.Contracts.length }
    });

      dialogRef.afterClosed().subscribe(
        result => {
            if (result) {
              this.supplierContractData = result;
                this.isContractAdded = true;
                if (!this.isSubscriptionAvailable) {
                this.subscribeForDailyOperation();
                this.subscribeForTerritoryData();
                this.subscribeForOracleSiteId();
                this.isSubscriptionAvailable = true;
                }
                this.setContractStatus();

            }
        });

  }

  subscribeForManageSupplier() {
    this.subscriptions.push(this.supplierContactService.currentMangeSupplierCapability.subscribe(res => {
      this.isManageSupplierCapability = res;
    }));
  }

  setSupplierContractData() {
    if (this.Contracts.length > this.currentIndex) {
      this.supplierContract = this.Contracts[this.currentIndex];
    } else {
      this.supplierContract = {};
    }
  }
  setContractButtonEnableState() {
    this.Contracts.forEach(element => {
      const isEnabled = this.checkConditionForSubmit(element);
      if (isEnabled) {
        element.isBtnEnabled = true;
      } else {
        element.isBtnEnabled = false;
      }
    });
  }
  checkConditionForSubmit(data) {
    const dailyOperationdata = data.dailyOperationContract;
    const territorydata = data.territoryCoverages;
    const oracleSiteIds = data.oracleSiteIds;
    let isTerritoryValid = false;
    let hasOracleData = false;
    if (territorydata && territorydata.length > 0) {
      const arr = [];
      for (const d of territorydata) {
        for (const s of d.supplierDiscounts) {
          arr.push(s);
        }
      }
      const checkDiscountType = arr.find(x => x.discountType === 'Regular');
      isTerritoryValid = checkDiscountType ? true : false;
    }

    if (oracleSiteIds && oracleSiteIds.length > 0) {
      const checkOracleActive = oracleSiteIds.find(x => x.isSwitchActive === true);
      hasOracleData = checkOracleActive ? true : false;
    }

    if (dailyOperationdata && isTerritoryValid && hasOracleData) {
      return true;
    } else {
      return false;
    }

  }
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.supplierContractService.updateContractData({});
    this.supplierContractService.updateDailyOperations({});
    this.supplierContractService.updateOracleSiteData([]);
    this.supplierContractService.updateTerriToryData([]);
  }
}
