/** MOV360-880 - to be removed at future date */
import { Component, OnInit, Input } from '@angular/core';
import { CandidateBudgetDetails } from '../../../../core/models/candidate-budget-details.model';
import { PdfFontsService } from '../../../../core/services/pdf-fonts.service';
import pdfMake from 'pdfmake/build/pdfmake';
import {
  alphaLogo,
  guaranteedServicesTitle,
  vanlineTitle,
  vanlineContentForCandidate,
  taxInfoTitle,
  notice,
  taxInfoIncluded,
  totalMoveBudget,
  recommendedServices,
  moveBudget,
  destination,
  departure,
  businessUnit,
  clientName,
  candidateFullName,
  authorizationOfferTitle,
  candidateOfferTitle,
  coreBenefits,
  taxAssistance,
  flexServicesDesc,
  candidateFlexServicesDesc,
  flexibleSpend,
  budgetAdjustment,
  enteredMoveBudget,
  cartusRecommendedBudget,
  hrCoreSvcDesc

} from '../../../../core/models/constants';
import { RelocationOfferInfo } from '../../../../core/models/relo-offer.model';
import { LoggerService } from '../../../../core/services/logger.service';
import { UserContextService } from '../../../../core/services/user-context.service';

import { formatNumber } from '@angular/common';

@Component({
  selector: 'app-relo-offer-document',
  templateUrl: './relo-offer-document.component.html',
  styleUrls: ['./relo-offer-document.component.scss']
})
export class ReloOfferDocumentComponent implements OnInit {
  /**flag to check whether transferee is authorized or not */
  @Input() authorizedTransferee: boolean;
  /** sets flag if move budget lesser than recommended */
  @Input() invalidBudget: boolean;

  /** used to get  relocation Detailst */
  @Input() relocationDetails: RelocationOfferInfo;
  /** used to store current date */
  date = new Date().toLocaleString('sv-SE').split(' ')[0];
  /**flag to check whether candidate offer is  clicked or authourize offer is clicked */
  isDownloadcandidateoffer: boolean;
  /** used to store title ofpdf */
  title;

  constructor(private readonly pdfMakeFonts: PdfFontsService, private readonly Logger: LoggerService,
    private readonly loggedInUserService: UserContextService) {
    pdfMakeFonts.defineFonts();
    pdfMakeFonts.assignFonts();
  }

  createRelocationOffer = (...contentParts) => {
    const baseDocDefinition = {
      pageOrientation: 'portrait',
      pageSize: 'LETTER',
      pageMargins: [40, 110, 40, 110],
      header: currentPage => {
        if (currentPage !== 1) {
          return {
            table: {
              headerRows: 1,
              widths: ['40%', '60%'],
              body: [
                [
                  {
                    image: this.pdfMakeFonts.alphaLogo(), width: 108, height: 32,
                    fillColor: '#25397C', alignment: 'center',
                    border: [false, false, false, false], margin: [35, 10, 0, 10]
                  },
                  {
                    text: '', alignment: 'center', lineHeight: 0.5,
                    color: '#FFFFFF', fillColor: '#3D72B9', border: [false, false, false, false], margin: [10, 10, 0, 10]
                  }
                ]
              ]
            },
          };
        } else {
          return {
            table: {
              headerRows: 1,
              widths: ['40%', '60%'],
              margin: [0, 10, 0, 80],
              body: [
                [{
                  image: this.pdfMakeFonts.alphaLogo(), width: 150, height: 50, alignment: 'center', color: '#FFFFFF',
                  fillColor: '#25397C', border: [false, false, false, false], margin: [0, 30, 0, -12],
                },
                {
                  text: this.title, alignment: 'left', color: '#FFFFFF', lineHeight: 0,
                  fillColor: '#3D72B9', border: [false, false, false, false],
                  fontSize: 18, margin: [30, 45, 0, -12],
                }],
                [{
                  text: '', alignment: 'center', color: '#FFFFFF',
                  fillColor: '#25397C', border: [false, false, false, false],
                  fontSize: 24, margin: [0, 0, 0, 50],
                },
                {
                  text: `Date ${this.date}`, alignment: 'left', color: '#FFFFFF', lineHeight: 0,
                  fillColor: '#3D72B9', border: [false, false, false, false],
                  fontSize: 10, margin: [30, 0, 0, 55],
                }]
              ]
            },
          };
        }
      },
      footer: (currentPage, pageCount) => {
        return {
          table: {
            headerRows: 1,
            widths: ['55%', '45%'],
            body: [
              [{
                text: `${currentPage.toString()} / ${pageCount.toString()}`,
                alignment: 'right', border: [false, false, false, false], margin: [0, 70, 5, 0]
              },
              {
                image: this.pdfMakeFonts.cartusLogo(), width: 75, height: 35,
                alignment: 'right', border: [false, false, false, false], margin: [0, 50, 15, 0]
              }]
            ]
          },
        };
      },
      styles: {
        heading1: {
          font: 'AvenirBlack',
          normal: true,
          fontSize: 12,

        },
        heading2: {
          font: 'AvenirMedium',
          normal: true,
          fontSize: 12,

        },
        heading3: {
          font: 'AvenirMedium',
          normal: true,
          fontSize: 9,

        },
        candidateDetails: {
          font: 'AvenirBlack',
          normal: true,
          fontSize: 10
        },
        candidateInfo: {
          font: 'AvenirMedium',
          normal: true,
          fontSize: 10
        }
      },
      defaultStyle: {
        font: 'AvenirMedium',
        normal: true,
      },

      content: [

      ],
    };
    const docDefinition = JSON.parse(JSON.stringify(baseDocDefinition));
    docDefinition.footer = baseDocDefinition.footer;
    docDefinition.header = baseDocDefinition.header;
    docDefinition.content.push(...contentParts);
    return docDefinition;
  }


  exportToPDF(iscandidateClicked: boolean) {
    this.isDownloadcandidateoffer = iscandidateClicked;
    const moveBudgetAmt = parseInt(this.relocationDetails.moveBudget.split(' ')[0].replace(',', ''), 10);
    const recommendedBudgetAmt = parseInt(this.relocationDetails.recommendedBudget.split(' ')[0].replace(',', ''), 10);
    const taxAmt = parseInt(this.relocationDetails.taxAmount.split(' ')[0].replace(',', ''), 10);
    const budgetAdjustmentAmt = formatNumber((moveBudgetAmt - recommendedBudgetAmt), 'en-US').toString() + ' USD';
    const totalExpense = formatNumber((moveBudgetAmt + taxAmt), 'en-US').toString() + ' USD';

    const candidateSummary: any = {
      columns: [
        {
          margin: [-5, 35, 0, 10],
          table: {
            widths: ['22.5%', '77.5%'],
            body: [
              [{
                text: candidateFullName, color: '#25397C', border: [false, false, false, false],
                style: 'candidateDetails', margin: [0, 0, 0, 0]
              },
              {
                text: this.relocationDetails.candidateFullName, color: '#000000', margin: [-2, 0, 0, 0],
                border: [false, false, false, false], style: 'candidateInfo', alignment: 'left'
              }
              ],
              [{
                text: clientName, color: '#25397C', border: [false, false, false, false],
                style: 'candidateDetails', margin: [0, 0, 0, 0]
              },
              {
                text: this.relocationDetails.clientName, color: '#000000', border: [false, false, false, false],
                style: 'candidateInfo', alignment: 'left', margin: [-2, 0, 0, 0]
              }
              ],
              [{
                text: businessUnit, color: '#25397C', border: [false, false, false, false],
                style: 'candidateDetails', margin: [0, 0, 0, 0]
              },
              {
                text: this.relocationDetails.bussinessUnit, color: '#000000', border: [false, false, false, false],
                style: 'candidateInfo', alignment: 'left', margin: [-2, 0, 0, 0]
              }
              ],
              [{
                text: departure, color: '#25397C', border: [false, false, false, false],
                style: 'candidateDetails', margin: [0, 0, 0, 0]
              },
              {
                text: this.relocationDetails.depature, color: '#000000', border: [false, false, false, false],
                style: 'candidateInfo', alignment: 'left', margin: [-2, 0, 0, 0]
              },
              ],
              [{
                text: destination, color: '#25397C', border: [false, false, false, false],
                style: 'candidateDetails', margin: [0, 0, 0, 0]
              },
              {
                text: this.relocationDetails.destination, color: '#000000', border: [false, false, false, false],
                style: 'candidateInfo', alignment: 'left', margin: [-2, 0, 0, 0]
              },
              ]
            ],
          }
        }
      ]
    };

    const candidatemoveBudgetAmt = {
      width: '50%',
      lineHeight: 0.5,
      margin: [60, 70, -20, 10],
      text: [
        { text: moveBudget + '\n\n', fontSize: 12, color: '#25397C', alignment: 'center' },
        {
          text: this.relocationDetails.moveBudget, fontSize: 24, color: '#000000',
          font: 'AvenirHeavy', normal: true, alignment: 'center'
        },
      ]
    };

    const candidateCoreService: any = {
      table: {
        widths: ['77%', '23%'],
        body: [
          [{
            text: guaranteedServicesTitle, style: 'heading1', color: '#25397C',
            borderColor: ['#7D7D7D', '#7D7D7D', '#7D7D7D', '#7D7D7D'], border: [true, true, false, false], margin: [5, 6, 0, 6]
          },
          {
            text: '', borderColor: ['#7D7D7D', '#7D7D7D', '#7D7D7D', '#7D7D7D'],
            border: [false, true, true, false], margin: [0, 6, 4, 6], fontSize: 14, alignment: 'right'
          }],
          [{
            text: vanlineTitle, style: 'heading2', color: '#000000', borderColor: ['#7D7D7D', '#7D7D7D', '#7D7D7D', '#7D7D7D'],
            border: [true, false, false, false], fillColor: '#EDEDED', margin: [5, 4, 0, 0]
          }, {
            text: '', fillColor: '#EDEDED', borderColor: ['#7D7D7D', '#7D7D7D', '#7D7D7D', '#7D7D7D'],
            border: [false, false, true, false]
          }],
          [{
            text: vanlineContentForCandidate, style: 'heading3', color: '#686868', lineHeight: 1,
            borderColor: ['#7D7D7D', '#7D7D7D', '#7D7D7D', '#7D7D7D'],
            border: [true, false, false, true], fillColor: '#EDEDED', margin: [5, 0, 0, 8]
          }, {
            text: '', fillColor: '#EDEDED', border: [false, false, true, true], margin: [0, 0, 4, 8],
            borderColor: ['#7D7D7D', '#7D7D7D', '#7D7D7D', '#7D7D7D'], fontSize: 14, alignment: 'right'
          }]
        ]
      }
    };

    const candidateflexService = {
      table: {
        widths: ['77%', '23%'],
        body: [
          [{
            text: recommendedServices, style: 'heading1', color: '#25397C', borderColor: ['#7D7D7D', '#7D7D7D', '#7D7D7D', '#7D7D7D'],
            border: [true, false, false, false], margin: [5, 6, 0, 6]
          },
          {
            text: this.relocationDetails.flexBudget, borderColor: ['#7D7D7D', '#7D7D7D', '#7D7D7D', '#7D7D7D'],
            border: [false, false, true, false], margin: [0, 6, 4, 6], fontSize: 14, alignment: 'right'
          }]
        ]
      }
    };

    const candidateTotalAmt: any = {
      table: {
        widths: ['50%', '50%'],
        body: [
          [{
            text: totalMoveBudget, style: 'heading2', alignment: 'left', color: '#25397C', border: [false, false, false, true],
            borderColor: ['#25397C', '#25397C', '#25397C', '#25397C'], margin: [0, 10, 0, 0]
          },
          {
            text: this.relocationDetails.moveBudget, color: '#000000', font: 'AvenirHeavy', normal: true, alignment: 'right',
            border: [false, false, false, true], borderColor: ['#25397C', '#25397C', '#25397C', '#25397C'],
            margin: [0, 10, 0, 0], fontSize: 18
          }]
        ]
      }
    };
    const candidateTaxAmt: any = {
      table: {
        body: [
          [{ text: taxInfoTitle, style: 'heading2', color: '#25397C', border: [false, false, false, false], margin: [0, 25, 0, 0] }],
          [{
            text: this.relocationDetails.taxinfo, style: 'heading3', color: '#686868', lineHeight: 1,
            border: [false, false, false, false]
          }],
        ]
      }
    };

    const authBudgetAdjustment: any = {
      table: {
        widths: ['80%', '20%'],
        body: [
          [{
            text: cartusRecommendedBudget, style: 'heading1', color: '#000000',
            borderColor: ['#7D7D7D', '#7D7D7D', '#7D7D7D', '#7D7D7D'], border: [false, true, false, false], margin: [5, 15, 0, 5]
          },
          {
            text: this.relocationDetails.recommendedBudget, borderColor: ['#7D7D7D', '#7D7D7D', '#7D7D7D', '#7D7D7D'],
            border: [false, true, false, false], margin: [0, 15, 5, 0], fontSize: 14, alignment: 'right', style: 'heading1'
          }],
          [{
            text: enteredMoveBudget, style: 'heading1', color: '#FFFFFF',
            border: [false, false, false, false], fillColor: '#6392CC', margin: [5, 5, 0, 5]
          }, {
            text: this.relocationDetails.moveBudget, fillColor: '#6392CC', border: [false, false, false, false],
            color: '#FFFFFF', margin: [0, 5, 5, 5], fontSize: 14, alignment: 'right', style: 'heading1'
          }],
          [{
            text: budgetAdjustment, style: 'heading1', color: '#25397C',
            borderColor: ['#7D7D7D', '#7D7D7D', '#7D7D7D', '#7D7D7D'],
            border: [false, false, false, true], margin: [5, 5, 0, 5]
          }, {
            text: budgetAdjustmentAmt, border: [false, false, false, true], margin: [0, 5, 5, 5], style: 'heading1',
            borderColor: ['#7D7D7D', '#7D7D7D', '#7D7D7D', '#7D7D7D'], fontSize: 14, alignment: 'right'
          }],
          [{
            text: '', border: [false, false, false, false], margin: [5, 20, 0, 5]
          }, {
            text: '', border: [false, false, false, false], margin: [5, 20, 0, 5]
          }],
          [{
            text: moveBudget, style: 'heading2', color: '#000000', fillColor: '#EDEDED',
            borderColor: ['#7D7D7D', '#7D7D7D', '#7D7D7D', '#7D7D7D'],
            border: [false, false, false, false], margin: [5, 5, 0, 5]
          }, {
            text: this.relocationDetails.moveBudget, border: [false, false, false, false],
            fillColor: '#EDEDED', fontSize: 14, alignment: 'right', margin: [0, 5, 5, 5]
          }],
          [{
            text: taxAssistance, style: 'heading2', color: '#000000', fillColor: '#EDEDED',
            borderColor: ['#7D7D7D', '#7D7D7D', '#7D7D7D', '#7D7D7D'],
            border: [false, false, false, true], margin: [5, 0, 0, 5]
          }, {
            text: '+ ' + this.relocationDetails.taxAmount, border: [false, false, false, true], fillColor: '#EDEDED', margin: [0, 0, 5, 5],
            borderColor: ['#7D7D7D', '#7D7D7D', '#7D7D7D', '#7D7D7D'], fontSize: 14, alignment: 'right'
          }], [{
            text: totalExpense, style: 'heading1', color: '#25397C',
            borderColor: ['#7D7D7D', '#7D7D7D', '#7D7D7D', '#7D7D7D'],
            border: [false, false, false, true], margin: [5, 5, 0, 5]
          }, {
            text: totalExpense, border: [false, false, false, true],
            borderColor: ['#7D7D7D', '#7D7D7D', '#7D7D7D', '#7D7D7D'], fontSize: 14, alignment: 'right', margin: [0, 5, 5, 5]
          }],
          [{
            text: '', border: [false, false, false, false], margin: [5, 20, 0, 15]
          }, {
            text: '', border: [false, false, false, false], margin: [5, 20, 0, 15]
          }],
        ]
      }
    };

    if (this.isDownloadcandidateoffer) {
      this.title = candidateOfferTitle;
      candidateCoreService.table.body[0][1].text = this.relocationDetails.coreBudget;
      const flexServices = this.getFlexServices(candidateFlexServicesDesc);
      candidateSummary.columns[0].table.widths = ['41%', '59%'];
      candidateSummary.columns.push(candidatemoveBudgetAmt);
      const docDefinition = this.createRelocationOffer(candidateSummary, candidateCoreService,
        candidateflexService, flexServices, candidateTotalAmt, candidateTaxAmt);
      pdfMake.createPdf(docDefinition).download('Relocation Offer' + '.pdf');
    } else {
      this.title = authorizationOfferTitle;
      candidateCoreService.table.body[0][0].text = coreBenefits;
      candidateCoreService.table.body[2][0].text = hrCoreSvcDesc;
      candidateCoreService.table.body[2][1].text = this.relocationDetails.coreBudget;
      candidateCoreService.table.body[2][0].border = [true, false, false, true];
      candidateCoreService.table.body[2][1].border = [false, false, true, true];
      candidateCoreService.table.body[0][0].margin = [5, 7, 0, 7];
      candidateCoreService.table.body[0][1].margin = [5, 7, 0, 7];
      candidateflexService.table.body[0][0].text = flexibleSpend;
      candidateflexService.table.body[0][0].border = [true, true, false, false];
      candidateflexService.table.body[0][1].border = [false, true, true, false];
      const flexServices = this.getFlexServices(flexServicesDesc);
      for (const x of flexServices) {
        for (const y of this.relocationDetails.flexSpend) {
          if (x.table.body[0][0].text === y.budgetName) {
            x.table.body[1][1].text = formatNumber(y.budgetAmount, 'en-US').toString() + ' USD';
          }

        }
      }
      const coreScv = [{
        text: '', border: [false, false, false, false], margin: [5, 160, 0, 0]
      }, {
        text: '', border: [false, false, false, false], margin: [5, 160, 0, 0]
      }];
      candidateCoreService.table.body.push(coreScv);

      const docDefinition = this.createRelocationOffer(candidateSummary, authBudgetAdjustment, candidateCoreService,
        candidateflexService, flexServices);
      pdfMake.createPdf(docDefinition).download('Authorization Offer' + '.pdf');
    }
  }

  getFlexServices(flexServicesDetails) {
    const flexServices: any = [];
    let flexIndex = 0;
    for (const y of this.relocationDetails.flexSpend) {
      for (const x of flexServicesDetails) {
        if (y.budgetName === x.name) {
          const fillColorValue = (flexIndex % 2 === 0) ? '#EDEDED' : null;
          const flexService = {
            table: {
              widths: ['77%', '23%'],
              body: [
                [{
                  text: x.name, style: 'heading2', color: '#000000', fillColor: fillColorValue,
                  border: [true, false, false, false], margin: [5, 5, 0, 0], borderColor: ['#7D7D7D', '#7D7D7D', '#7D7D7D', '#7D7D7D']
                }, {
                  text: '', border: [false, false, true, false], borderColor: ['#7D7D7D', '#7D7D7D', '#7D7D7D', '#7D7D7D'],
                  fillColor: fillColorValue
                }],
                [{
                  text: x.desc, style: 'heading3', color: '#686868',
                  borderColor: ['#7D7D7D', '#7D7D7D', '#7D7D7D', '#7D7D7D'], fillColor: fillColorValue,
                  lineHeight: 1, border: [true, false, false, false], margin: [5, 0, 0, 10]
                }, {
                  text: '', border: [false, false, true, false], borderColor: ['#7D7D7D', '#7D7D7D', '#7D7D7D', '#7D7D7D'],
                  fillColor: fillColorValue, margin: [0, 0, 5, 10], fontSize: 14, alignment: 'right'
                }]
              ]
            },
          };
          flexServices.push(flexService);
          flexIndex++;
        }
      }
    }
    flexServices[flexServices.length - 1].table.body[1][0].border = [true, false, false, true];
    flexServices[flexServices.length - 1].table.body[1][1].border = [false, false, true, true];
    return flexServices;
  }

  /** To initialise the component */
  ngOnInit() {
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-RELO_OFFER_DOCUMENT');
      });

  }

}
