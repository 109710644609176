<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<div class="cdk-visually-hidden" aria-live="polite" aria-atomic="true" [innerHTML]="announceStmt"></div>
<div class="empsettingsContainer settingsContainer" flexLayout fxLayout.xs="column">
  <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
    fxLayoutGap="20px">
    <h2 class="candidate-dialogtitle account-mgr-detail-subheader">Consultant Details</h2>
    <a href="#" class="close-icon" (click)="onNoClick($event)" title="close">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <mat-dialog-content class="account-mgr-minht">
    <form [formGroup]="ConsultantForm">
      <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
        fxLayoutGap.xs="0em">
        <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
          <mat-form-field>
            <input matInput placeholder="Full Name" readonly formControlName="consultantName">
          </mat-form-field>
        </div>
      </div>
      <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
        fxLayoutGap.xs="0em">
        <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
          <mat-form-field fxFlex>
            <input matInput type="text" placeholder="Email" readonly formControlName="Email">
          </mat-form-field>
        </div>
      </div>
      <!-- Assigned clients starts -->
      <h3 class="role-txt">Assigned Clients:</h3>
      <!-- Client display container -->
      <div class="assignedclient-container">
        <!-- None button -->
        <div class="assignedclient-none" *ngIf="clientRows.length==0">None</div>
        <!-- None button -->
        <div *ngIf="clientRows.length>0" role="list">
          <div [ngClass]="{'settings-tablecol': clientRows.length>4}">
            <div [ngClass]="{'settings-scrolldiv': clientRows.length>4}">
              <div *ngFor="let client of clientRows" class="assigned-client-addr" role="listitem">
                <span class="assigned-addrtxt"  >{{client.entityName}}</span>
                <span class="assigned-pin">{{client.clientNumber}}</span>
                <a href="javascript:void(0)" (click)="removeClient(client.clientId)"
                  [attr.aria-label]="'Delete' + client.entityName">
                  <mat-icon class="assigned-del-icon" role="button">delete</mat-icon>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Add button cont -->
      <div class="account-mgr-btn">
        <button *ngIf="!displayRow" class="contained-button mat-button" (click)="add()">Add</button>
      </div>
      <!-- Add button cont ends-->
      <!-- Client display container ends-->
      <!-- search container starts -->
      <div *ngIf="displayRow" class="assigned-mgr-search-cont">
        <input #search type="text" formControlName="Search" autocomplete="off" matInput id="searchMgrInput"
          (input)="onClientInput(ConsultantForm.controls['Search'].value)" width="80%" fxFlex.sm="100"
          aria-label="Search for Assigned Clients">
        <a href="#" (click)="closeSearchBar($event)" aria-label="Close Client Search">
          <mat-icon matSuffix id="search_icon">close</mat-icon>
        </a>
        <mat-spinner class="mgr-spinner" *ngIf="showClientSpinner" matSuffix strokeWidth="1" [diameter]="20">
        </mat-spinner>
      </div>

      <!-- search container starts -->
      <!-- tablecontainer starts -->

      <div *ngIf="!!canLoad && dataSource?.filteredData?.length>0" class="assigned-mgr-tbl-cont mat-elevation-z8">
        <div class="settings-tablecol">
          <div class="settings-scrolldiv">
            <table mat-table [dataSource]="dataSource">
              <!-- Client -->
              <ng-container matColumnDef="entityName">
                <th mat-header-cell *matHeaderCellDef> Client </th>
                <td mat-cell *matCellDef="let element">
                  <span class="text-as-link" [innerHTML]="element.entityName | highlightSearch: filterText"></span>
                </td>
              </ng-container>
              <!--  Client ID -->
              <ng-container matColumnDef="clientNumber">
                <th mat-header-cell *matHeaderCellDef> Client ID </th>
                <td mat-cell *matCellDef="let element">
                  <span class="text-as-link" [innerHTML]="(element.clientNumber != undefined || null)?
                  (element.clientNumber | highlightSearch: filterText):''"></span>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row tabindex="0" *matRowDef="let row; columns: displayedColumns;" (click)="onClientSelected(row)"
                (keydown.enter)="onClientSelected(row)"></tr>
            </table>
          </div>
        </div>
      </div>
      <div *ngIf="showClientSpinner && dataSource?.filteredData?.length===0">
        Loading..
      </div>
      <div *ngIf="!!canLoad && dataSource?.filteredData?.length===0">
        No records found
      </div>
      <!-- table container ends -->
      <!-- Assigned Clients Ends -->
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="statusBtn">
      <button class="mat-button text-button" type="button" color="primary" (click)="onNoClick($event)">CANCEL</button>
      <button class="contained-button mat-button" id="save" (click)="saveAssociations()">OK</button>
    </div>
  </mat-dialog-actions>
</div>