import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';

/**
 * Gets the global API services configuration
 */
@Injectable({
  providedIn: 'root'
})
export class ApiConfigService {

  /** Root URL for Candidate Process API operations */
  rootUrl: string;
  /** Root URL for Cost Model API operations */
  costModelRootUrl: string;
  /** Root URL for Cost Model API operations */
  accessManagementRootUrl: string;
  /** Root URL for Finance Model API operations */
  financeManagementRootUrl: string;
  /** Root URL for Operational Data API Operations */
  operationalDataRootUrl: string;


  /**
   * Initialize the rootURL
   */
  constructor(private readonly config: AppConfigService) {
    const apiConfig: { [key: string]: string | number } = this.config.getConfig('api');
    this.rootUrl = `${apiConfig.protocol}://${apiConfig.host}`;

    const costConfig: { [key: string]: string | number } = this.config.getConfig('costModel');
    this.costModelRootUrl = `${costConfig.protocol}://${costConfig.host}`;

    const accessConfig: { [key: string]: string | number } = this.config.getConfig('accessManagement');
    this.accessManagementRootUrl = `${accessConfig.protocol}://${accessConfig.host}`;

    const financeConfig: { [key: string]: string | number } = this.config.getConfig('financeManagement');
    this.financeManagementRootUrl = `${financeConfig.protocol}://${financeConfig.host}`;

    const operationalConfig: { [key: string]: string | number } = this.config.getConfig('operationalData');
    this.operationalDataRootUrl = `${operationalConfig.protocol}://${operationalConfig.host}${operationalConfig.base_url}`;
  }
}
