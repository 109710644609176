/** MOV360-880 - to be removed at future date */
import { Component, OnInit, Input, ViewEncapsulation, ElementRef, Renderer2, HostListener } from '@angular/core';
import { CandidateFlexSpend } from '../../../../core/models/candidate-flex-spend.model';
import { budgetCurrency, flexServicesDesc } from '../../../../core/models/constants';
import { OverlayConfig, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { HtmlTooltipComponent } from '../../../../core/components/html-tooltip/html-tooltip.component';
import { PopupPositionService } from '../../../../core/services/popup-position.service';
import {LoggerService} from '../../../../core/services/logger.service';
import { UserContextService } from '../../../../core/services/user-context.service';

/**flexibleBenefits component */
@Component({
  selector: 'app-candidate-flexible-benifits',
  templateUrl: './candidate-flexible-benifits.component.html',
  styleUrls: ['./candidate-flexible-benifits.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CandidateFlexibleBenifitsComponent implements OnInit {

  /** overlayRef to hold overlay config */
  overlayRef: OverlayRef;

  /** tooltip icon active class */
  tooltipIconActive = 'active';

  /**Receiving service data from budgetDetails component */
  @Input() flexibleSpendDetails: CandidateFlexSpend;
  /** stores currency */
  currency = budgetCurrency;
  /**To inject dependencies
   * @param overlay overlay for popup
   * @param positionService popup position service
   */
  constructor(
    private readonly overlay: Overlay,
    private readonly positionService: PopupPositionService,
    private readonly renderer: Renderer2,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: UserContextService
  ) { }
  /**To initialise the component */
  ngOnInit() {
    this.flexibleSpendDetails.flexSpend.forEach(element => {
      const service = flexServicesDesc.filter(data => data.id === element.budgetName);
      if (service[0].name === 'Storage' && element.daysInStorage !== 0) {
        element.budgetDesc = `This quote has been estimated for ${element.daysInStorage} days.`;
      } else {
        element.budgetDesc = service[0].desc;
      }
      element.budgetName = service[0].name;
    });
    this.loggedInUserService.getLoggedInUserDetails()
    .subscribe(response => {
      const userId: any = response.userId.replace(/ .*/, '');
      this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-CANDIDATE_FLEXIBLE_BENIFITS');
    });

  }

  @HostListener('document:keyup', ['$event']) onKeyUp(ev: KeyboardEvent) {
    if (ev.code === 'ArrowUp' || ev.code === 'ArrowDown') {
      this.overlayRef.dispose();
    }
  }


  /** Open HTML tooltip for flex service
 * @param flexData flex data to be passed to popup
 * @param evt KeyboardEvent
 */
  openHtmlTooltip(flexData: any, evt: KeyboardEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    evt.stopImmediatePropagation();
    const infoIcon = (evt.currentTarget as Element).getElementsByClassName('info');
    this.renderer.addClass(infoIcon[0], this.tooltipIconActive);
    const rect = (infoIcon[0]).getBoundingClientRect();
    const target = this.getPositionByEvents(rect);
    const element = new ElementRef(target);
    const positionStrategy = this.placeByPositionStrategy(element);
    const thresholdScroll = {
      threshold: 50
    };
    const overlayConfig = new OverlayConfig({
      width: 350,
      panelClass: 'html-tooltip-pane',
      hasBackdrop: true,
      backdropClass: 'mat-backdrop-transparent',
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close(thresholdScroll),
      disposeOnNavigation: true
    });
    this.overlayRef = this.overlay.create(overlayConfig);
    const containerPortal = new ComponentPortal(
      HtmlTooltipComponent,
      null,
      this.positionService.createInjector({
        keyString: 'flexBudget',
        dataObj: flexData,
        clientX: rect.left + (rect.right - rect.left) / 2,
        clientY: rect.top + (rect.bottom - rect.top) / 2,
        targetElem: infoIcon[0],
        overlayRef: this.overlayRef
      })
    );
    if (flexData.daysInStorage !== 0) {
      this.overlayRef.attach(containerPortal);
    } else {
      this.renderer.removeClass(infoIcon[0], this.tooltipIconActive);
    }
    this.overlayRef.backdropClick().subscribe(() => {
      this.overlayRef.dispose();
      this.renderer.removeClass(infoIcon[0], this.tooltipIconActive);
    });
  }

  /** get the position by events of the target */
  getPositionByEvents(rect) {
    return {
      getBoundingClientRect: () => ({
        bottom: rect.top + (rect.bottom - rect.top) / 2,
        height: rect.height,
        left: rect.left + (rect.right - rect.left) / 2,
        right: rect.left + (rect.right - rect.left) / 2,
        top: rect.top + (rect.bottom - rect.top) / 2,
        width: rect.width
      }),
    };
  }

  /** place the pop up by position strategy
   * @param element element reference
   */
  placeByPositionStrategy(element: ElementRef) {
    return this.overlay
      .position()
      .flexibleConnectedTo(element)
      .withFlexibleDimensions(false)
      .withPositions([
        {
          originX: 'end',
          originY: 'center',
          overlayX: 'start',
          overlayY: 'center',
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'bottom',
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        }
      ]);
  }

}
