<div class="settingsContainer" flexLayout fxLayout.xs="column">
  
  <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="20px">
    <div class="candidate-dialogtitle">Table Columns</div>
    <a href="#" id="close" class="close-icon" (click)="onNoClick($event)" title="close">
      <mat-icon>close</mat-icon>
    </a>
  </div>  
  <mat-dialog-content> 
    <div class="settings-tablecol">
      <div class="settings-scrolldiv">
        <mat-grid-list [cols]="gridColumn" rowHeight="35px">
          <mat-grid-tile *ngFor="let selection of columnsList">
            <mat-checkbox [checked]="selection.flag" [disabled]="selection.disabled" (change)="updateChkbxArray(selection)">{{selection.displayName}}</mat-checkbox>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </div>
    <div class="settings-selectedcount">
      <span>{{selectedColumnsList.length}}</span> Selected
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="text-button uppercase" (click)="resetValues()" id="reset">RESET</button>
    <button class="contained-button" id="save" (click)="save()">OK</button>
  </mat-dialog-actions>
</div>