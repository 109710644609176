import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { ProjectAlphaModule } from '../public/project-alpha/project-alpha.module';
import { UserAlignmentRoutingModule } from './user-alignment-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { OverlayService } from '../core/services/overlay.service';
import { UserAlignmentDashboardComponent } from './components/user-alignment-dashboard/user-alignment-dashboard.component';
import {MatTableModule} from '@angular/material/table'; 
import { UserAlignmentService } from './services/user-alignment.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { HttpClientModule } from '@angular/common/http';
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {MatButtonModule} from '@angular/material/button';
import { UserAlignmentPartiesComponent } from './components/user-alignment-parties/user-alignment-parties.component';
import { UserAlignmentTableComponent } from './components/user-alignment-table/user-alignment-table.component';
import { ColumnDataKeyPipe } from './pipes/column-data-key-pipe';

@NgModule({
  declarations: [
    UserAlignmentDashboardComponent,
    UserAlignmentPartiesComponent,
    UserAlignmentTableComponent,
    ColumnDataKeyPipe
    ],
  imports: [
    CommonModule,
    MaterialModule,
    ProjectAlphaModule,
    UserAlignmentRoutingModule,
    ReactiveFormsModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatSortModule,
    MatSnackBarModule,
    MatButtonModule,
    MatTableModule
  ],
  providers: [
    OverlayService,
    UserAlignmentService
  ],
  entryComponents: [
  ]
})
export class UserAlignmentModule { }
