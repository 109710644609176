<div class="container" fxFlex="100%" fxLayout="column" fxLayout.xs="column" >
    
    <div class="emptyInfo" fxLayout="column" fxLayout.xs="column" fxFlex.xs="100%" *ngIf="isEmptyOracleSite" fxLayoutAlign="center center" fxLayoutGap.xs="0px">
        <span class="spanText">You must add the related Site IDs for Oracle to activate the contract</span>
        <span class=" addInfolink"><a (click)="openOracleSiteId()">Add Site ID</a></span>
    </div> 
    <section *ngIf="!isEmptyOracleSite" class="content_table">
        <section class="mat-elevation-z8">
          <div style="overflow:auto;">
              <table class="oracle-site-table" mat-table [dataSource]="dataSource" aria-describedby="table-candidates" matSort
              [matSortActive]="sortBy" matSortDirection="asc" (matSortChange)="sortData($event)">
      
              <!-- Oracle Site ID Column -->
              <ng-container matColumnDef="oraclesiteId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Site ID</th>
                    <td class="contract-section-div" mat-cell *matCellDef="let element">
                        <span class="daily-operation-mat-form" [innerHTML]="element.oraclesiteId ? element.oraclesiteId : '-'"></span>
                        <button *ngIf="isManageSupplier" mat-button matSuffix mat-icon-button aria-label="Edit" (click)="openOracleSiteId(element)" class="pointer">
                            <mat-icon class="size pointer">edit</mat-icon>
                         </button>
                    </td>  
              </ng-container>
      
              <!-- Country Column -->
              <ng-container matColumnDef="country">
                <th mat-header-cell *matHeaderCellDef>Country</th>
                <td class="contract-section-div" mat-cell *matCellDef="let element">
                    <span class="daily-operation-mat-form" (click)="openOracleSiteId(element)" [innerHTML]="element.country ? element.country : '-'"></span>
                </td> 
              </ng-container>
      
              <!-- Street Address Column -->
              <ng-container matColumnDef="streetAddress">
                <th mat-header-cell *matHeaderCellDef>Street Address</th>
                <td mat-cell *matCellDef="let element">
                    <span (click)="openOracleSiteId(element)" [innerHTML]="element.streetAddress ? element.streetAddress : '-'"></span>
                </td> 
              </ng-container>
              
               <!-- City Column -->
              <ng-container matColumnDef="city">
                <th mat-header-cell *matHeaderCellDef>City</th>
                <td mat-cell *matCellDef="let element">
                    <span (click)="openOracleSiteId(element)" [innerHTML]="element.city ? element.city : '-'"></span>
                </td> 
              </ng-container>

                <!-- State Column -->
                <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef>State</th>
                    <td mat-cell *matCellDef="let element">
                        <span (click)="openOracleSiteId(element)" [innerHTML]="element.state ? element.state : '-'"></span>
                    </td> 
                </ng-container>

                 <!-- Postal Code Column -->
              <ng-container matColumnDef="zipcode">
                <th mat-header-cell *matHeaderCellDef>Postal Code</th>
                <td mat-cell *matCellDef="let element">
                    <span (click)="openOracleSiteId(element)" [innerHTML]="element.zipcode ? element.zipcode : '-'"></span>
                </td> 
              </ng-container>
              
                <!-- slide toggle Column -->
                <ng-container matColumnDef="active">
                    <th id="" mat-header-cell *matHeaderCellDef>Active</th>
                    <td mat-cell *matCellDef="let element; let i=index;">
                        <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="1em" fxLayoutGap.xs="0.1em"
                            class="switchText">
                            <div fxFlex="24%" fxLayoutAlign="start" fxFlex.xs="24%" class="switchSection">
                                <span [ngClass]="{offText: !element.isSwitchActive}">Off</span>
                            </div>

                            <div fxFlex="37%" fxLayoutAlign="start" fxFlex.xs="37%" class="switchSection">
                                <label class="switch">
                                    <input type="checkbox" [(ngModel)]="element.isSwitchActive" (change)="onValChange($event,i)">
                                    <span class="slider round"></span>
                                </label>
                            </div>

                            <div fxFlex="39%" fxLayoutAlign="start" fxFlex.xs="39%" class="switchSection">
                                <span [ngClass]="{onText: element.isSwitchActive}">On</span>
                            </div>
                        </div>
                    </td>
                </ng-container>
            
              <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </section>
        <div mat-dialog-actions>
            <div class="statusBtn">
                <button type="submit" *ngIf="!isEmptyOracleSite" (click)="openOracleSiteId()" mat-button class="mat-button contained-button">Add Site ID</button>
            </div>
        </div>
      </section>

</div>