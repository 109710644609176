<ngx-spinner type="ball-spin-clockwise-fade" size="medium">
</ngx-spinner>

<div class="authorize-dialog">
    <div mat-dialog-title disableTypography fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="20px"
        fxLayoutGap.xs="0em">
        <div class="candidate-dialogtitle">
            <span>{{ isEmptySupplierInfo ? 'Add Supplier Contact' : 'Edit Supplier Contact' }}</span>
        </div>
        <a class="close-icon" id="close_icon" (click)="close($event)">
            <mat-icon>close</mat-icon>
        </a>
    </div>
    <div mat-dialog-content>
        <form fxLayout="column" class="middle-section form-group" [formGroup]="editsupplierContact"
            (ngSubmit)="sendInvite($event)" novalidate>
            <div class="form-group middle-section">
                <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em"
                    fxLayout.xs="column" fxLayoutGap.xs="0em">
                    <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                        <mat-form-field>
                            <input matInput type="text" class="form-control" formControlName="firstname"
                                placeholder="First Name" />
                            <mat-error>{{ getErrorMessage('FIRST_NAME') }}</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                        <mat-form-field>
                            <input matInput type="text" class="form-control" formControlName="lastname"
                                placeholder="Last Name" />
                            <mat-error>{{ getErrorMessage('LAST_NAME') }}</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayout.xs="column"
                fxLayoutGap.xs="0em">
                <div class="middle-section">
                    <div fxFlex="100%" fxLayout.xs="column" fxFlex.xs="100%">
                        <mat-form-field>
                            <input matInput type="email" class="form-control" formControlName="emailAddress"
                                placeholder="Email Address" />
                            <mat-error>
                                {{ getErrorMessage('EMAIL_ADDRESS') }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayout.xs="column"
                fxLayoutGap.xs="0em">
                <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                    <mat-form-field>
                        <mat-label>Supplier</mat-label>
                        <mat-select class="form-control" formControlName="supplier" [(value)]="selected"
                            [disabled]="!isEmptySupplierInfo">
                            <mat-option *ngFor="let supp of Supplier" [value]="supp.name">
                                {{supp.name}}</mat-option>
                        </mat-select>
                        <mat-error>
                            {{ errors['Supplier'] }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex="50%" fxLayout.xs="row" fxLayout="row" fxLayoutALign="space-between" fxFlexFill
                    fxLayoutGap="1em" fxFlex.xs="100%">
                    <div fxFlex="25%" fxFlex.xs="25%" class="extn-leftspacer">
                        <mat-form-field>
                            <mat-label>Extension</mat-label>
                            <mat-select formControlName="Extension" [(value)]="selectedExt">
                                <mat-option *ngFor="let extn of extensions" [value]="extn.countryDialingCode">
                                    {{extn.countryDialingCode}}
                                </mat-option>
                            </mat-select>
                            <mat-error>
                                {{ errors['Extension'] }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="75%" fxFlex.xs="75%" class="international-extn">
                        <mat-form-field>
                            <input matInput type="text" formControlName="phoneNumber" placeholder="Mobile Phone Number"
                                appInputPhoneFormat maxlength="10" />
                            <mat-hint align="end" class="hint">Mobile # required to recieve text messeges</mat-hint>
                            <mat-error>
                                {{ getErrorMessage('PHONE_NUMBER') }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div mat-dialog-actions class="actionBtn">
        <div class="statusBtn">
            <button class="uppercase" mat-button (click)="close($event)">Cancel</button>
            <button type="submit" *ngIf="!isEmptySupplierInfo && (supplierContactStatus === 'Active')"
                [disabled]="editsupplierContact.invalid" mat-button class="mat-button outlined-button"
                (click)="setToInactive(event)" id="InActivateBtn">Set to Inactive</button>
            <button type="submit" *ngIf="!isEmptySupplierInfo" mat-button
                class="mat-button contained-button" [disabled]="!editsupplierContact.valid"
                (click)="saveUpdates(event)">Save Changes</button>
            <button type="submit" *ngIf="isEmptySupplierInfo" [disabled]="!editsupplierContact.valid" mat-button
                class="mat-button outlined-button" (click)="saveDraft(event)">
                Save Draft</button>
            <button type="submit" *ngIf="isEmptySupplierInfo || (supplierContactStatus === 'Invitation Not Sent')"
                mat-button class="mat-button contained-button"
                [disabled]="!editsupplierContact.valid || (supplierContactExtension === '')" (click)="sendInvite(event)">
                Send Invite</button>
            <button type="submit" *ngIf="!isEmptySupplierInfo && (supplierContactStatus === 'Invitation Sent')"
                mat-button class="mat-button contained-button"
                [disabled]="!editsupplierContact.valid || (supplierContactExtension === '')" (click)="sendInvite(event)">
                Resend</button>
        </div>
    </div>
</div>
