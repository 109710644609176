/** MOV360-880 - to be removed at future date */
import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import {
  inactivateClientTitle,
  inactivateClientContactDisplayText1,
  inactivateClientContactDisplayText2
} from 'src/app/core/models/constants';
import { LoggerService } from '../../../../core/services/logger.service';
import { UserContextService } from '../../../../core/services/user-context.service';
import { ClientServiceService } from 'src/app/core/services/client-service.service';


@Component({
  selector: 'app-inactivate-client',
  templateUrl: './inactivate-client.component.html',
  styleUrls: ['./inactivate-client.component.scss']
})
export class InactivateClientComponent implements OnInit {

  /** Title for cost model pop up */
  title = inactivateClientTitle;
  /** Text to be displayed - first line */
  displayText1 = inactivateClientContactDisplayText1;
  /** Text to be displayed - last line */
  displayText2 = inactivateClientContactDisplayText2;
  /**
   * base constructor
   * @param data data received from calling component
   * @param dialogRef MatDialogRef component
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<InactivateClientComponent>,
    private Logger: LoggerService,
    private loggedInUserService: UserContextService,
    private clientService: ClientServiceService
  ) { }

  /** Function to be called on init  */
  ngOnInit() {
    // console.log(`Data: ${JSON.stringify(this.data, null, 4)}`)
    // console.log(`Clients to be inactivated : ${JSON.stringify(this.data.selectedClients, null, 4)}`)
    this.data.selectedClients.sort((firstData, secondData) => (firstData.clientPreferredName > secondData.clientPreferredName) ? 1 : -1);
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-INACTIVE_CLIENT', 'INACTIVE_CLIENT');
      });
  }

  /** Function to be called on cancel/close; returns 'close' to calling component */
  onNoClick() {
    this.dialogRef.close('cancel');
  }
  /** Function to be called on confirmation; returns 'withdraw' to calling component */
  inactivateClient() {
    this.dialogRef.close('inActivateClients');
  }

}
