/** MOV360-880 - to be removed at future date */
import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { MatSort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DecimalPipe } from '@angular/common';
import { PdfFontsService } from 'src/app/core/services/pdf-fonts.service';
import pdfMake from 'pdfmake/build/pdfmake';
import * as moment from 'moment';
import {
  cartusAddress, invoice, invoiceNumber, invoiceDate, paymentTerms, totalAmountDue, invoiceCurrency, budgetCurrency, invoiceTo,
  transferee, approvedMoveBudget, moveOrder, paymentRemit, electronicPayment, thankyouMsg, invoiceMsg, paymentType, payTerms,
  routingNumber, accountNumber, swiftNumber, hvgTitle, transfereeName, orderRef, hvgClientName, hvgDeparture, hvgDestination,
  hvgVanLineMove, hvgTotal, hvgTotalLine, typeOfItem, serialNum, replacementValue, itemDescription
} from '../../../../core/models/constants';

@Component({
  selector: 'app-transferee-document-library',
  templateUrl: './transferee-document-library.component.html',
  styleUrls: ['./transferee-document-library.component.scss'],
  providers: [DecimalPipe]
})
export class TransfereeDocumentLibraryComponent implements OnInit {
  /**property for amountDue */
  @Input() amountDue: string;
  /**property for Transferee Name */
  @Input() transfereeName: string;
  /**property for invoice details */
  @Input() invoiceDetails: any;

  displayedColumns: string[] = [
    'filename',
    'fileType',
    'addedBy',
    'dateAdded'
  ];

  dataSource: any;
  /**property for invoice Date */
  invoiceDate: string;
  /**property for  Client contact name  */
  clientContactName: string;
  /**property for payment Instructions */
  paymentInstructions: any;
  /**property for invoiceNumber */
  invoiceNumber: string;
  /**property for ClientAddress */
  clientAddress: any;
  /**property for Move Order Number */
  moveOrder: string;
  /**property to get date*/
  date: Date;
  hvgDate: Date;
  /**property to store clientName */
  clientName: string;
  dollars: any;
  cents: any;
  fullName: any;
  orderRef: any;
  hvgClientName: any;
  departure: any;
  destination: any;
  vanLineName: any;
  submittedOn: any;
  totalAmt: any;
  hvgItemList: any;
  hvgTransfereeName: any;
  isVisible = false;
  hvgItemLists: Array<any>[] = [];
  title: any;
  pdfDocInit = 'HVG';

  /** To sort the mat table columns */
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('hvgData', { static: false }) hvgData: ElementRef;

  constructor(
    private readonly pdfMakeFonts: PdfFontsService,
    private decimalPipe: DecimalPipe
  ) {
    pdfMakeFonts.defineFonts();
    pdfMakeFonts.assignFonts();
  }

  ngOnInit() {
    if (this.invoiceDetails && this.invoiceDetails.length > 0) {
      this.dataSource = new MatTableDataSource(this.invoiceDetails);
      this.dataSource.sort = this.sort;
      this.amountDue = this.decimalPipe.transform(parseInt(this.amountDue, 10).toFixed(2), '1.2-2');
      this.getPdfContents();
    }
  }

  setFullName(element) {
    return `${element.fullName.split(',')[1]} ${element.fullName.split(',')[0]}`;
  }

  setFileName(fullName) {
    return `${fullName.split(',')[1]} ${fullName.split(',')[0]}`;
  }

  /**
   * get pdf contents for invoice pdf.
   */
  getPdfContents() {
    if (this.invoiceDetails && this.invoiceDetails.length > 0) {
      this.paymentInstructions = this.invoiceDetails[0].epInfo[0];
      this.invoiceNumber = this.invoiceDetails[0].invoiceId;
      this.clientAddress = this.invoiceDetails[0].clientAddress;
      this.clientContactName = this.invoiceDetails[0].clientContactName.replace(',', '');
      this.moveOrder = this.invoiceDetails[0].moveOrderId;
      this.clientName = this.invoiceDetails[0].clientName;
      this.date = new Date(this.invoiceDetails[0].invoiceDate);
      this.clientContactName = this.invoiceDetails[0].authorizedBy;
      this.invoiceDate = moment(this.date).format('DD MMM YYYY');

      if (this.invoiceDetails[1] !== undefined) {
        this.fullName = this.invoiceDetails[1].fullName;
        this.orderRef = this.invoiceDetails[1].orderRef;
        this.hvgClientName = this.invoiceDetails[1].clientName;
        this.departure = this.invoiceDetails[1].departure;
        this.destination = this.invoiceDetails[1].destination;
        this.vanLineName = this.invoiceDetails[1].vanLineName;
        this.hvgDate = new Date(this.invoiceDetails[1].submittedOn);
        this.submittedOn = moment(this.hvgDate).format('YYYY-MM-DD');
        this.totalAmt = this.decimalPipe.transform(parseInt(this.invoiceDetails[1].totalAmt, 10), '1.0-0');
        this.hvgItemList = this.invoiceDetails[1].hvgItemList;
        this.hvgTransfereeName = this.setFileName(this.fullName);
        this.setHvgItemList(this.hvgItemList);
      }
    }
  }

  setHvgItemList(itemList) {
    for (let i = 0; i < itemList.length; i++) {
      let item = [];
      item = [
        {
          text: itemList[i].itemType, borderColor: ['#7D7D7D', '#7D7D7D', '#7D7D7D', '#7D7D7D'],
          border: [true, true, true, true], margin: [5, 6, 0, 6], lineHeight: 1.75,
        },
        {
          text: itemList[i].description, borderColor: ['#7D7D7D', '#7D7D7D', '#7D7D7D', '#7D7D7D'],
          border: [true, true, true, true], margin: [5, 6, 0, 6], lineHeight: 1.75,
        },
        {
          text: itemList[i].serialNum, borderColor: ['#7D7D7D', '#7D7D7D', '#7D7D7D', '#7D7D7D'],
          border: [true, true, true, true], margin: [5, 6, 0, 6], lineHeight: 1.75,
        },
        {
          text: this.decimalPipe.transform(parseInt(itemList[i].replacementValue, 10), '1.0-0') +
            ' USD', borderColor: ['#7D7D7D', '#7D7D7D', '#7D7D7D', '#7D7D7D'],
          border: [true, true, true, true], margin: [0, 6, 4, 6], fontSize: 14, alignment: 'right', lineHeight: 1.75,
        },
      ];
      this.hvgItemLists.push(item);
    }
  }

  /**
   * Creates template for the invoice pdf.
   */
  createDocumentDefinition() {
    const baseDocDefinition = {
      content: [
        {
          image: this.pdfMakeFonts.cartusLogo(),
          width: 212,
          height: 93
        },
        {
          columns: [
            {
              fontSize: 10,
              width: '40%',
              lineHeight: 0.5,
              margin: [0, 20, 0, 20],
              text: [
                { text: cartusAddress.name + '\n\n' },
                { text: cartusAddress.addLine1 + '\n\n' },
                { text: cartusAddress.addLine2 + '\n\n' },
                { text: cartusAddress.addLine3 + '\n\n' },
                { text: cartusAddress.website + '\n\n' },
                { text: cartusAddress.fax },
              ]
            },
            {
              table: {
                headerRows: 1,
                widths: ['50%', '50%'],
                body: [
                  [
                    { text: invoice, alignment: 'center', bold: true, colSpan: 2, fillColor: '#D0D0D0' },
                    {}
                  ],
                  [
                    { text: invoiceNumber, style: 'tableLeftContent', border: [true, false, false, false], lineHeight: 0.75, },
                    { text: this.invoiceNumber, style: 'tableRightContent', border: [false, false, true, false], lineHeight: 0.75, },
                  ],
                  [
                    { text: invoiceDate, style: 'tableLeftContent', border: [true, false, false, false], lineHeight: 0.75, },
                    { text: this.invoiceDate, style: 'tableRightContent', border: [false, false, true, false], lineHeight: 0.5, },
                  ],
                  [
                    { text: paymentTerms, style: 'tableLeftContent', border: [true, false, false, false], lineHeight: 0.75, },
                    { text: paymentType, style: 'tableRightContent', border: [false, false, true, false], lineHeight: 0.75, },
                  ],
                  [
                    { text: totalAmountDue, style: 'tableLeftContent', border: [true, false, false, false], lineHeight: 0.75, },
                    { text: this.amountDue, style: 'tableRightContent', border: [false, false, true, false], lineHeight: 0.75, },
                  ],
                  [
                    { text: invoiceCurrency, style: 'tableLeftContent', border: [true, false, false, true], lineHeight: 2.5, },
                    { text: budgetCurrency, style: 'tableRightContent', border: [false, false, true, true], lineHeight: 2.5, },
                  ]
                ]
              },
            }
          ],
        },
        {
          margin: [0, 20, 0, 0],
          table: {
            headerRows: 1,
            widths: ['100%'],
            body: [
              [{ text: invoiceTo, alignment: 'left', italics: true, bold: true, fillColor: '#D0D0D0' }],
              [{ text: this.clientName, style: 'tableLeftContent', border: [true, false, true, false], lineHeight: 0.5 }],
              [{
                text: this.clientAddress.streetAddress + ', ', style: 'tableLeftContent',
                border: [true, false, true, false], lineHeight: 0.5
              }],
              [{
                text: `${this.clientAddress.city}, ${this.clientAddress.state}`,
                style: 'tableLeftContent', border: [true, false, true, false], lineHeight: 0.60
              }],
              [{
                text: `${this.clientAddress.zipcode}, ${this.clientAddress.country}`,
                style: 'tableLeftContent', border: [true, false, true, false], lineHeight: 0.75
              }],
              [{ text: 'Attn: ' + this.clientContactName, style: 'tableLeftContent', border: [true, false, true, false], lineHeight: 1.5 }],
            ]
          }
        },
        {
          table: {
            headerRows: 1,
            widths: ['20%', '80%'],
            body: [
              [{ text: '', fillColor: '#D0D0D0', colSpan: 2, margin: [0, 10, 0, 0] }, {}],
              [
                { text: transferee, style: 'tableLeftContent', border: [true, false, false, false], lineHeight: 0.75, },
                { text: this.transfereeName.trim(), style: 'tableLeftContent', border: [false, false, true, false], lineHeight: 0.75, },
              ],
              [
                { text: moveOrder, style: 'tableLeftContent', border: [true, false, false, false], lineHeight: 2, },
                { text: this.moveOrder, style: 'tableLeftContent', border: [false, false, true, false], lineHeight: 2, },
              ],
            ]
          }
        },
        {
          table: {
            headerRows: 1,
            widths: ['50%', '50%'],
            body: [
              [{ text: approvedMoveBudget, style: 'tableRightContent', border: [true, false, false, false], lineHeight: 1.75, },
              {
                text: `${this.amountDue} ${budgetCurrency}`, style: 'tableLeftContent', border: [false, false, true, false],

                lineHeight: 1.75, margin: [50, 0, 0, 0]
              }],
              [{
                text: totalAmountDue, style: 'tableRightContent', border: [true, false, false, true], lineHeight: 1.5,
                fontSize: 17, bold: true
              },
              {
                text: `${this.amountDue}       ${budgetCurrency}`, style: 'tableLeftContent', border: [false, false, true, true],

                lineHeight: 1.5, margin: [50, 0, 0, 0], fontSize: 17, bold: true
              }],
            ]
          }
        },
        {
          margin: [0, 20, 0, 5],
          table: {
            headerRows: 1,
            widths: ['100%'],
            body: [
              [{ text: paymentRemit, alignment: 'left', italics: true, bold: true, fillColor: '#D0D0D0' }],
              [{
                border: [true, true, true, false],
                table: {
                  headerRows: 1,
                  widths: ['100%'],
                  body: [
                    [{
                      text: electronicPayment, border: [false, false, false, false], decoration: 'underline',
                      lineHeight: 1, style: 'tableLeftContent', margin: [10, 0, 0, 0]
                    }],
                    [{
                      text: this.paymentInstructions.bankName, border: [false, false, false, false],
                      lineHeight: 0.5, style: 'tableLeftContent', margin: [10, 0, 0, 0]
                    }],
                    [{
                      text: this.paymentInstructions.addrLine1, border: [false, false, false, false],
                      lineHeight: 0.5, style: 'tableLeftContent', margin: [10, 0, 0, 0]
                    }],
                    [{
                      text: this.paymentInstructions.addrLine2, border: [false, false, false, false],
                      lineHeight: 0.5, style: 'tableLeftContent', margin: [10, 0, 0, 0]
                    }],
                    [{
                      text: `${routingNumber} ${this.paymentInstructions.aba_RoutingNumber}`,
                      border: [false, false, false, false], lineHeight: 0.5, style: 'tableLeftContent', margin: [10, 0, 0, 0]
                    }],
                    [{
                      text: this.paymentInstructions.accountName, border: [false, false, false, false],
                      lineHeight: 0.5, style: 'tableLeftContent', margin: [10, 0, 0, 0]
                    }],
                    [{
                      text: accountNumber + this.paymentInstructions.accountNumber, border: [false, false, false, false],
                      lineHeight: 0.5, style: 'tableLeftContent', margin: [10, 0, 0, 0]
                    }],
                    [{
                      text: swiftNumber + this.paymentInstructions.swift_BICCode, border: [false, false, false, false],
                      lineHeight: 1, style: 'tableLeftContent', margin: [10, 0, 0, 0]
                    }],

                  ]
                }
              }],
              [{
                border: [true, false, true, true],
                table: {
                  headerRows: 1,
                  widths: ['18%', '82%'],
                  body: [
                    [{
                      text: paymentTerms, style: 'tableLeftContent', decoration: 'underline',
                      border: [false, false, false, false], margin: [10, 0, 0, 0]
                    },
                    {
                      text: payTerms,
                      style: 'tableLeftContent', border: [false, false, false, false], margin: [0, 0, 0, 20],
                      lineHeight: 0.5, font: 'AvenirBlack', normal: true
                    }],
                  ]
                }
              }]
            ]
          }
        },
        {
          text: `${cartusAddress.addLine1} ${cartusAddress.addLine2} ${cartusAddress.addLine3}`,
          fontSize: 8, alignment: 'center', lineHeight: 2,
        },
        { text: thankyouMsg, fontSize: 22, alignment: 'center' },
        { text: invoiceMsg, fontSize: 12, alignment: 'center' },
      ],
      styles: {
        tableLeftContent: {
          alignment: 'left',
          fontSize: 10,
        },

        tableRightContent: {
          alignment: 'right',
          fontSize: 10
        },
      },
      defaultStyle: {
        font: 'AvenirMedium',
        normal: true
      },
      pageOrientation: 'portrait',
      pageSize: 'A4',

    };
    return JSON.parse(JSON.stringify(baseDocDefinition));
  }

  createHVGDocumentDefinition = (...contentParts) => {
    const baseDocDefinition = {
      pageOrientation: 'portrait',
      pageSize: 'LETTER',
      pageMargins: [40, 110, 40, 110],
      header: currentPage => {
        if (currentPage !== 1) {
          return {
            table: {
              headerRows: 1,
              widths: ['40%', '60%'],
              body: [
                [
                  {
                    image: this.pdfMakeFonts.alphaLogo(), width: 108, height: 32,
                    fillColor: '#25397C', alignment: 'center',
                    border: [false, false, false, false], margin: [35, 10, 0, 10]
                  },
                  {
                    text: '', alignment: 'center', lineHeight: 0.5,
                    color: '#FFFFFF', fillColor: '#3D72B9', border: [false, false, false, false], margin: [10, 10, 0, 10]
                  }
                ]
              ]
            },
          };
        } else {
          return {
            table: {
              headerRows: 1,
              widths: ['40%', '60%'],
              margin: [0, 10, 0, 80],
              body: [
                [{
                  image: this.pdfMakeFonts.alphaLogo(), width: 150, height: 50, alignment: 'center', color: '#FFFFFF',
                  fillColor: '#25397C', border: [false, false, false, false], margin: [0, 30, 0, -12],
                },
                {
                  text: this.title, alignment: 'left', color: '#FFFFFF', lineHeight: 0,
                  fillColor: '#3D72B9', border: [false, false, false, false],
                  fontSize: 18, margin: [30, 45, 0, -12],
                }],
                [{
                  text: '', alignment: 'center', color: '#FFFFFF',
                  fillColor: '#25397C', border: [false, false, false, false],
                  fontSize: 24, margin: [0, 0, 0, 50],
                },
                {
                  text: `Date ${this.submittedOn}`, alignment: 'left', color: '#FFFFFF', lineHeight: 0,
                  fillColor: '#3D72B9', border: [false, false, false, false],
                  fontSize: 10, margin: [30, 0, 0, 55],
                }]
              ]
            },
          };
        }
      },
      footer: (currentPage, pageCount) => {
        return {
          table: {
            headerRows: 1,
            widths: ['55%', '45%'],
            body: [
              [{
                text: `${currentPage.toString()} / ${pageCount.toString()}`,
                alignment: 'right', border: [false, false, false, false], margin: [0, 70, 5, 0]
              },
              {
                image: this.pdfMakeFonts.cartusLogo(), width: 75, height: 35,
                alignment: 'right', border: [false, false, false, false], margin: [0, 50, 15, 0]
              }]
            ]
          },
        };
      },
      styles: {
        heading1: {
          font: 'AvenirBlack',
          normal: true,
          fontSize: 12,

        },
        heading2: {
          font: 'AvenirMedium',
          normal: true,
          fontSize: 12,

        },
        heading3: {
          font: 'AvenirMedium',
          normal: true,
          fontSize: 9,

        },
        candidateDetails: {
          font: 'AvenirBlack',
          normal: true,
          fontSize: 10
        },
        candidateInfo: {
          font: 'AvenirMedium',
          normal: true,
          fontSize: 10
        },

        backgrd: {
          fillColor: '#F5F5F5',
        }
      },
      defaultStyle: {
        font: 'AvenirMedium',
        normal: true,
      },

      content: [

      ],
    };
    const docDefinition = JSON.parse(JSON.stringify(baseDocDefinition));
    docDefinition.footer = baseDocDefinition.footer;
    docDefinition.header = baseDocDefinition.header;
    docDefinition.content.push(...contentParts);
    return docDefinition;
  }
  /**
   *method to call generate pdf
   */
  downloadInvoicePDF(event, invoiceDetails: any) {
    event.stopPropagation();
    event.preventDefault();
    if (invoiceDetails.invoiceId) {
      const docDefinition = this.createDocumentDefinition();
      pdfMake.createPdf(docDefinition).download(this.invoiceNumber + '.pdf');
    } else {
      this.title = hvgTitle;

      const candidateSummary: any = {
        columns: [
          {
            margin: [-5, 45, 0, 10],
            table: {
              widths: ['22.5%', '77.5%'],
              body: [
                [{
                  text: transfereeName, color: '#25397C', border: [false, false, false, false],
                  style: 'candidateDetails', margin: [0, 0, 0, 0]
                },
                {
                  text: this.hvgTransfereeName, color: '#000000', margin: [-2, 0, 0, 0],
                  border: [false, false, false, false], style: 'candidateInfo', alignment: 'left'
                }
                ],
                [{
                  text: orderRef, color: '#25397C', border: [false, false, false, false],
                  style: 'candidateDetails', margin: [0, 0, 0, 0]
                },
                {
                  text: this.orderRef, color: '#000000', border: [false, false, false, false],
                  style: 'candidateInfo', alignment: 'left', margin: [-2, 0, 0, 0]
                }
                ],
                [{
                  text: hvgClientName, color: '#25397C', border: [false, false, false, false],
                  style: 'candidateDetails', margin: [0, 0, 0, 0]
                },
                {
                  text: this.hvgClientName, color: '#000000', border: [false, false, false, false],
                  style: 'candidateInfo', alignment: 'left', margin: [-2, 0, 0, 0]
                }
                ],
                [{
                  text: hvgDeparture, color: '#25397C', border: [false, false, false, false],
                  style: 'candidateDetails', margin: [0, 0, 0, 0]
                },
                {
                  text: this.departure, color: '#000000', border: [false, false, false, false],
                  style: 'candidateInfo', alignment: 'left', margin: [-2, 0, 0, 0]
                },
                ],
                [{
                  text: hvgDestination, color: '#25397C', border: [false, false, false, false],
                  style: 'candidateDetails', margin: [0, 0, 0, 0]
                },
                {
                  text: this.destination, color: '#000000', border: [false, false, false, false],
                  style: 'candidateInfo', alignment: 'left', margin: [-2, 0, 0, 0]
                },
                ],
                [{
                  text: hvgVanLineMove, color: '#25397C', border: [false, false, false, false],
                  style: 'candidateDetails', margin: [0, 0, 0, 0]
                },
                {
                  text: this.vanLineName, color: '#000000', border: [false, false, false, false],
                  style: 'candidateInfo', alignment: 'left', margin: [-2, 0, 0, 0]
                },
                ],
                [{
                  text: hvgTotal, color: '#25397C', border: [false, false, false, false],
                  style: 'candidateDetails', margin: [0, 0, 0, 0]
                },
                {
                  text: this.totalAmt + ' USD', color: '#000000', border: [false, false, false, false],
                  style: 'candidateInfo', alignment: 'left', margin: [-2, 0, 0, 0]
                },
                ]
              ],
            }
          }
        ]
      };

      const candidatemoveBudgetAmt = {
        width: '50%',
        lineHeight: 0.5,
        margin: [60, 70, -20, 10],
        text: [
          { text: hvgTotalLine + '\n\n', fontSize: 12, color: '#25397C', alignment: 'center' },
          {
            text: this.totalAmt + ' USD', fontSize: 24, color: '#000000',
            font: 'AvenirHeavy', normal: true, alignment: 'center'
          },
        ]
      };

        const tableColumns = {
          style: 'backgrd',
          margin: [0, 20, 0, 0],
          table: {
            widths: ['30%', '30%', '20%', '20%'],
            body: [
              [{
                text: typeOfItem, borderColor: ['#7D7D7D', '#7D7D7D', '#7D7D7D', '#7D7D7D'],
                border: [true, true, true, false], margin: [5, 6, 0, 6], color: '#25397C', style: 'heading1',
              },
              {
                text: itemDescription, borderColor: ['#7D7D7D', '#7D7D7D', '#7D7D7D', '#7D7D7D'],
                border: [false, true, true, false], margin: [4, 6, 4, 6], color: '#25397C', style: 'heading1',
              },
              {
                text: serialNum, borderColor: ['#7D7D7D', '#7D7D7D', '#7D7D7D', '#7D7D7D'],
                border: [false, true, true, false], margin: [4, 6, 4, 6], color: '#25397C', style: 'heading1',
              },
              {
                text: replacementValue, borderColor: ['#7D7D7D', '#7D7D7D', '#7D7D7D', '#7D7D7D'],
                border: [true, true, true, false], margin: [0, 0, 0, 0], alignment: 'center', color: '#25397C', style: 'heading1',
              }]
            ]
          }
        };

      const candidateflexService = {
        table: {
          widths: ['30%', '30%', '20%', '20%'],
          body: this.hvgItemLists
        }
      };

      const candidateTotalAmt: any = {
        margin: [0, 20, 0, 0],
        table: {
          widths: ['50%', '50%'],
          body: [
            [{
              text: hvgTotalLine, style: 'heading2', alignment: 'left', color: '#25397C', border: [false, false, false, true],
              borderColor: ['#25397C', '#25397C', '#25397C', '#25397C'], margin: [0, 10, 0, 0]
            },
            {
              text: this.totalAmt + ' USD', color: '#000000', font: 'AvenirHeavy', normal: true, alignment: 'right',
              border: [false, false, false, true], borderColor: ['#25397C', '#25397C', '#25397C', '#25397C'],
              margin: [0, 10, 0, 0], fontSize: 18
            }]
          ]
        }
      };

      candidateSummary.columns[0].table.widths = ['41%', '59%'];
      candidateSummary.columns.push(candidatemoveBudgetAmt);
      const hvgDocDefinition = this.createHVGDocumentDefinition(candidateSummary, tableColumns, candidateflexService, candidateTotalAmt);
      pdfMake.createPdf(hvgDocDefinition).download(`${this.pdfDocInit}${this.setFileName(this.fullName)}.pdf`);
    }
  }

}
