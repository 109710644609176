/** MOV360-880 - to be removed at future date */
import { LevelService } from '../../../../core/services/level.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectorRef,
  Input,
  SimpleChanges,
  OnDestroy,
  OnChanges
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { CandidateProfilesService } from '../../../../core/services/candidate-profiles.service';
import { Candidate } from '../../../../core/models/candidate';
import { AddCandidateComponent } from '../add-candidate/add-edit-budget.component';
import { UntypedFormGroup } from '@angular/forms';
import { ResendInviteComponent } from '../resend-invite/resend-invite.component';
import { Selection } from '../../../../core/models/selection.model';
import { Subscription, of } from 'rxjs';
import { NotificationsService } from '../../../../core/services/notifications.service';
import { WithdrawCandidateComponent } from '../withdraw-candidate/withdraw-candidate.component';
import { InvitationSentComponent } from './../invitation-sent/invitation-sent.component';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Level } from '../../../../core/models/level';
import { PartySharedService } from '../../../../core/services/party-shared.service';
import { LoggerService } from '../../../../core/services/logger.service';
import { UserContextService } from '../../../../core/services/user-context.service';
import { debounceTime, switchMap, distinctUntilChanged } from 'rxjs/operators';
import * as querystring from 'querystring';
import { apiErrorMessage, skipRecordCount } from '../../../../core/models/constants';
import { ExtensionService } from 'src/app/core/services/extension.service';
import { Extension } from 'src/app/core/models/extension.model';

/**Class for CandidateDetailsComponent */
@Component({
  selector: 'app-candidate-details',
  templateUrl: './candidate-details.component.html',
  styleUrls: ['./candidate-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CandidateDetailsComponent implements OnInit, OnChanges, OnDestroy {
  /** to check for readonly access */
  disable: any;
  /**variable to store the title */
  displayedColumns: string[] = [
    'select',
    'fullname',
    'level',
    'departure.state',
    'destination.state',
    'status'
  ];
  /**Form to capture the candidate details */
  addCandidateForm: UntypedFormGroup;
  /**Data source for table */
  dataSource: any;
  /**stores the details of candidates */
  ELEMENT_DATA: Candidate[] = [];
  /**store the filter value */
  filterText = '';
  /**to store the rows selected */
  selection = new SelectionModel<Candidate>(true, []);
  /** To sort the mat table columns */
  @ViewChild(MatSort, { static: false }) set sortOrder(sort: MatSort) {
    if (sort) {
      this.dataSource.sort = sort;
    }
  }
  /** To paginate in a mat table */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  /** Input prop for receiving data*/
  @Input() selectedCols: Selection[];
  /** store default column for sorting */
  sortBy = 'fullname';
  /** sorting direction */
  sortDir = 'ASC';
  /**check if table data is loaded */
  isLoaded = false;
  /**store levelDetails */
  levelDetails: Level[] = [];
  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /**stores count of active candidates */
  initialCount = 0;
  /** Stores the client contact ID */
  clientContactId: string;
  /** pagination */
  pageInfo = {
    pageSize: 20,
    pageIndex: 0,
    totalCount: 0
  };
  /** accesssibility statement */
  sortStatement = '';
  /** constant value */
  departureState = 'departure.state';

  /**
   * injecting dependencies
   * @param dialog object for matdialog
   * @param  candidateProfilesService object for CandidateProfileService
   * @param changeDetectorRefs object for changeDetectorRefs object
   * @param snackBar object for snachbar
   * @param datePipe object for datePipe object
   * @param notificationsService object for NotificationsService
   * @param spinner object for spinner
   * @param levelService object for levelservice
   */
  constructor(
    public dialog: MatDialog,
    private readonly candidateProfilesService: CandidateProfilesService,
    private readonly changeDetectorRefs: ChangeDetectorRef,
    public snackBar: MatSnackBar,
    public datePipe: DatePipe,
    public notificationsService: NotificationsService,
    private readonly _router: Router,
    public spinner: NgxSpinnerService,
    public levelService: LevelService,
    private readonly partySharedSvc: PartySharedService,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: UserContextService,
    private extensionService: ExtensionService
  ) {}

  /**To initialize the component */
  ngOnInit() {
    this.subscription.add(this.partySharedSvc.partyId.subscribe(id => {
      if (id) {
        this.clientContactId = id;
        this.loadCandidates(this.pageInfo.pageSize, this.pageInfo.pageIndex);
      }
    }));
    this.subscription.add(
      this.levelService.getLevelDetails().subscribe(levels => {
        const levelDetails: Level[] = [];
        for (let index = 0; index < levels.length; index++) {
          const level = levels[index];
          const detailedLevel: Level = {
            levelId: `L${index + 1}`,
            levelName: level,
            levelDescription: level.split('(')[0].trim(),
            levelRange: level.replace('(', '#(').split('#')[1]
          };
          levelDetails.push(detailedLevel);
        }
        this.levelService.level$.next(levelDetails);
      })
    );
    this.subscription.add(
      this.extensionService.getExtensionDetails().subscribe((extensions: any) => {
        const extensionDetails: any[] = [];
        for (let i = 0; i < extensions.length; i++) {
          const extn = extensions[i];
          const extensionData: Extension = {
            countryDialingCode: `+${extn.dialingCode}`,
            name: extn.name
          };
          extensionDetails.push(extensionData);
        }
        this.extensionService.extension$.next(extensionDetails);
      })
    );
    this.loggedInUserService.getLoggedInUserDetails()
      .subscribe(response => {
        const userId: any = response.userId.replace(/ .*/, '');
        this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-CANDIDATE_DETAILS', 'CANDIDATE_DETAILS');
      });
  }

  sortData(event) {
    switch (event.active) {
      case this.departureState:
        event.active = 'departure';
        break;
      case 'destination.state':
        event.active = 'destination';
        break;
    }
    switch (event.direction) {
      case 'asc':
        this.sortStatement = `sorting ${event.active} in ascending order`;
        break;
      case 'desc':
        this.sortStatement = `sorting ${event.active} in descending order`;
        break;
      case '':
        this.sortStatement = `sorting ${event.active} in random order`;
        break;
    }
    this.sortBy = event.active;
    this.sortDir = event.direction === 'desc' ? 'DESC' : 'ASC';
    this.loadCandidates(this.pageInfo.pageSize, this.paginator.pageIndex * this.paginator.pageSize);
  }

  getQueryString(_clientContactId, _searchText?, _sortField?, _sortDir?, _skip?, _limit?): string {
    const _queryString = { clientContactId: _clientContactId };
    if (_searchText) { _queryString['searchText'] = _searchText; }
    if (_sortField) { _queryString['sortField'] = _sortField; }
    if (_sortDir) { _queryString['sortDir'] = _sortDir; }
    if (_skip) { _queryString['skip'] = _skip; }
    if (_limit) { _queryString['limit'] = _limit; }
    return querystring.stringify(_queryString);
  }

  loadCandidates(pageSize: number, skipRecords: number) {
    this.spinner.show();
    this.subscription.add(
      this.candidateProfilesService
        .getCandidateProfiles(this.getQueryString(this.clientContactId, this.filterText, this.sortBy, this.sortDir, skipRecords, pageSize))
        .subscribe({
          next: response => {
            if (response && response.status === 204) {
              this.calculateActiveCandidates(0);
              this.ELEMENT_DATA = [];
              this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
              this.spinner.hide();
            } else if (response && response.status === 200 && response.body) {
              this.formatModels(response.body.candidates);
              this.initialCount = Number(response.body.totalActiveCandidate);
              this.ELEMENT_DATA = response.body.candidates;
              this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
              this.dataSource.sort = this.sortOrder;
              setTimeout(() => {
                this.paginator.length = Number(response.body.totalActiveCandidate);
              });
              this.dataSource.sortingDataAccessor = this.getPropertyByPath;
              this.dataSource.filterPredicate = this.customFilterPredicate;
              this.calculateActiveCandidates(this.initialCount);
              this.spinner.hide();
            } else {
              this.calculateActiveCandidates(this.initialCount);
              this.spinner.hide();
              this.snackBar.open(
                apiErrorMessage,
                undefined,
                { duration: 5000 }
              );
            }
          },
          error: () => {
            this.spinner.hide();
          }
      })
    );
  }

  /**method to filter column values */
  customFilterPredicate(data, filter): boolean {
    const departure = data.departure ? `${data.departure.state}, ${data.departure.city}` : '';
    const searchTerm = filter.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const dataStr =
      `${data.level}
      ${data.destination.state}, ${data.destination.city}${data.fullname}${departure}
      ${data.businessUnit}${data.status}${data.createdBy}`;
    return dataStr.search(new RegExp(searchTerm, 'gi')) !== -1;
  }

  /**
   * function to get property by path
   * @param obj -object passed
   * @param pathString -path passed
   */
  getPropertyByPath(obj: Object, pathString: string) {
    if (pathString === this.departureState && !obj.hasOwnProperty('departure')) { return null; }
    return pathString.split('.').reduce((o, i) => o[i], obj);
  }

  /**Refresh and detect the changes */
  refresh() {
    this.changeDetectorRefs.detectChanges();
  }

  /**format model for candidate level*/
  formatModels(candidates) {
    for (const candidate of candidates) {
      if (candidate.level) {
        candidate.levelName = candidate.level.split('(')[0];
        candidate.levelDes = candidate.level.replace('(', '#(').split('#')[1];
      }
      if (candidate.clientContactName) {
        candidate.clientContactNameFormat = `creator: ${candidate.clientContactName}`;
      }
      if (candidate.countryDialingCode && !(candidate.countryDialingCode.toString().includes('+'))) {
        candidate.countryDialingCode = `+${candidate.countryDialingCode}`;
      }
    }
  }

  /**Function to update the candidates in the table */
  updateDataSource(data: Candidate) {
    if (data) {
      const index = this.ELEMENT_DATA.findIndex(
        x => x.candidateId === data.candidateId
      );
      if (index === -1) {
        this.ELEMENT_DATA.push(data);
        this.initialCount = this.initialCount + 1;
      } else {
        this.ELEMENT_DATA[index] = data;
      }
      this.formatModels(this.ELEMENT_DATA);
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.dataSource.sort = this.sortOrder;
      this.dataSource.sortingDataAccessor = this.getPropertyByPath;
      setTimeout(() => {
        this.paginator.length = this.initialCount;
      });
      this.calculateActiveCandidates(this.initialCount);
    }
  }

  /** Method to check if all the rows in the mat table were selected*/
  isAllSelected(): boolean {
    if (this.ELEMENT_DATA.length > 0) {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }
  }

  /** Method to toggle select all or clear all for rows inside in the mat table */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /**function for selecting the rows in the table */
  checkboxLabel(row?: Candidate): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${
      this.selection.isSelected(row) ? 'deselect' : 'select'
      } row ${row.fullname + 1}`;
  }

  /**Function to open the mat Dialog on click of a row */
  public open(element) {
    const dialogRef = this.dialog.open(AddCandidateComponent, {
      // panelClass: 'addCandidateModal',
      panelClass: 'dialogMainContainer',
      data: {
        disableButton: this.disable,
        candidateDetails: element
      },
      disableClose: true
    });
    this.subscription.add(
      dialogRef.afterClosed().subscribe((candidate: Candidate) => {
        if (candidate) {
          this.updateDataSource(candidate);
          if (candidate.executedFunctionType === 'Update') {
            this.notificationsService.flashNotification(
              'success',
              'Candidate updated successfully',
              true,
              'dismiss'
            );
          } else if (candidate.executedFunctionType === 'Invite') {
            this.notificationsService.confirmNotification('Email');
          }
        }
      })
    );
  }

  /** Mat data table filtering matching wild characters */
  applyFilter(filterValue: string) {
    this.filterText = filterValue.trim();
    if (this.filterText && this.filterText.length >= 3) {
      this.paginator.pageIndex = skipRecordCount;
      return this.loadCandidates(this.pageInfo.pageSize, skipRecordCount);
    } else if (this.filterText === '') {
      return this.loadCandidates(this.pageInfo.pageSize, this.paginator.pageIndex * this.paginator.pageSize);
    }
    return false;
  }

  /** Resend Invite Modal window */
  // openAlert(candidate: Candidate) {
  //   // const dialogRef = this.dialog.open(ResendInviteComponent);
  //   // this.subscription.add(
  //   //   dialogRef.afterClosed().subscribe(result => {
  //   //     // TODO:
  //   //   })
  //   // );
  //   // event.stopPropagation();
  //   this.candidateProfilesService
  //     .sendInviteToCandidate(candidate)
  //     .subscribe(result => { });
  // }

  /**Function to detect the changes */
  public ngOnChanges(changes: SimpleChanges) {
    if ('selectedCols' in changes) {
      const tempStr: string[] = [];
      this.selectedCols.forEach((item, index) => {
        if (this.displayedColumns.indexOf(item.value) < 0) {
          this.displayedColumns.splice(
            this.displayedColumns.length - 1,
            0,
            item.value
          );
        }
      });
      this.displayedColumns.forEach(element => {
        const ind = this.selectedCols.findIndex(col => col.value === element);
        if (ind !== -1) {
          tempStr.push(element);
        }
      });
      this.displayedColumns =
        tempStr.length > 0 ? tempStr : this.displayedColumns;
      if (this.displayedColumns.findIndex(val => val === 'select') < 0) {
        this.displayedColumns.unshift('select');
      }
    }
  }

  // resendInvite(candidate: Candidate) {
  //   this.spinner.show();
  //   this.candidateProfilesService.reSendInviteToCandidate(candidate).subscribe(
  //     response => {
  //       this.spinner.hide();
  //       candidate.status = 'Invitation Sent';
  //       candidate.invitationSentDate = new Date().toLocaleString();
  //       candidate.lastUpdatedDate = new Date().toLocaleString();
  //       this.updateDataSource(candidate);
  //       if (response.message === reSendInviteResponse) {
  //         this.notificationsService.confirmNotification('Email');
  //       }
  //     },
  //     err => {
  //       this.spinner.hide();
  //     }
  //   );
  // }

  /**
   * withdraw candidates
   */
  withdrawCandidates() {
    const data = {
      noOfSelected: this.selection.selected.length,
      selectedCandidates: this.selection.selected
    };
    const dialogRef = this.dialog.open(WithdrawCandidateComponent, {
      panelClass: ['dialogMainContainer', 'withdraw-candidate-dialog-container'],
      data
    });
    this.subscription.add(
      dialogRef.afterClosed().subscribe({
        next: result => {
          if (result === 'cancel') {
            this.selection.clear();
          } else if (result === 'withdraw') {
            this.spinner.show();
            const candidatesList = [];
            this.selection.selected.forEach(candidate => {
              candidatesList.push(candidate.candidateId);
            });
            // service call
            this.subscription.add(
              this.candidateProfilesService
                .withdrawCandidates(this.clientContactId, candidatesList)
                .subscribe({
                  next: res => {
                    if (res) {
                      this.spinner.hide();
                      // snack bar inside subscribe
                      this.snackBar.openFromComponent(InvitationSentComponent, {
                        horizontalPosition: 'center',
                        verticalPosition: 'bottom',
                        data: this.selection.selected.length > 1 ? `${this.selection.selected.length} Candidates Withdrawn`
                          : `${this.selection.selected.length} Candidate Withdrawn`,
                        duration: 5000
                      });
                      this.ELEMENT_DATA = this.ELEMENT_DATA.filter(
                        candidate => !this.selection.selected.includes(candidate)
                      );
                      this.initialCount = this.initialCount - this.selection.selected.length;
                      this.selection.clear();
                      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
                      this.dataSource.sort = this.sortOrder;
                      this.dataSource.sortingDataAccessor = this.getPropertyByPath;
                      setTimeout(() => {
                        this.paginator.length = this.initialCount;
                      });
                      this.calculateActiveCandidates(this.initialCount);
                    } else {
                      this.spinner.hide();
                      this.snackBar.open(
                        apiErrorMessage,
                        undefined,
                        { duration: 5000 }
                      );
                    }
                  },
                  error: () => {
                    this.spinner.hide();
                  }
                })
            );
          }
        },
        error: () => {
          this.spinner.hide();
        }
      })
    );
  }

  /** navigate to Budget Summary of requested candidate*/
  navigateToBudgetSummary(candidateId: string) {
    this._router.navigate(['project-alpha/budget-info', candidateId]);
  }

  /**
   * function to check whether data source is defined or undefined
   * @param dataSource parameter passed
   */
  calculateActiveCandidates(totalCandidate) {
    this.candidateProfilesService.totalCount.next({
      type: 'Candidates',
      length: totalCandidate,
      label: 'Active Candidates'
    });
  }

  /** to check for readonly access */
  isDisabled(flag) {
    this.disable = flag;
    if (flag === true) {
      this.displayedColumns = [
        'select',
        'fullname',
        'client',
        'level',
        'departure.state',
        'destination.state',
        'status'
      ];
    }
  }

  /**
   * destroys the object
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onPagination(event: any) {
    this.pageInfo.pageSize = this.paginator.pageSize;
    this.loadCandidates(this.paginator.pageSize, this.paginator.pageIndex * this.paginator.pageSize);
  }
}
