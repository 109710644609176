<div role="tooltip" aria-modal="true" aria-labelledby="tooltip-heading" cdkTrapFocus cdkTrapFocusAutoCapture>
  <div class="popUpTitleBlk" fxLayout="row" fxLayoutAlign="space-between center">
    <h2 id="tooltip-heading" tabindex="0">Move Phases</h2><button mat-icon-button (click)="close()"><span class="material-icons">close</span></button>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutAlign.xs="center center">
    <app-multi-step aria-hidden="true" [steps]="5" [selected]="data.movePhase"></app-multi-step>
  </div>
  <mat-divider></mat-divider>
  <app-linked-list [dataSet]="empMovePhases" listStyleClasses="full-width" [columnStyleClasses]="['', '', 'align-right']">
  </app-linked-list>
  <mat-divider></mat-divider>
  <div class="button-container" fxLayout="row" fxLayoutAlign="center center">
    <button mat-button class="contained-button" (click)="close()">Close</button>
  </div>  
</div>
