import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CustomSnackbarComponent implements OnInit {

  /** Should the passed data be shown as HTML */
  isHTML = false;

  constructor(private readonly _snackRef: MatSnackBarRef<CustomSnackbarComponent>,
  @Inject(MAT_SNACK_BAR_DATA) public data: any) { }

  ngOnInit() {
    if (this.data && this.data.html && this.data.html === true) {
      this.isHTML = true;
    }
  }

  dismiss() {
    if (!this._snackRef) { return; }
    this._snackRef.dismissWithAction();
  }

}
