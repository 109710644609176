/** MOV360-880 - to be removed at future date */
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoggerService } from 'src/app/core/services/logger.service';
import { Router } from '@angular/router';
import { EnddateRangeValidator } from 'src/app/core/validators/date.validators';
import { SupplierContractData } from 'src/app/core/models/contract-suppliers.model';
import { SupplierContractService } from 'src/app/core/services/supplier-contract.service';

@Component({
  selector: 'app-add-contract',
  templateUrl: './add-contract.component.html',
  styleUrls: ['./add-contract.component.scss']
})

export class AddContractComponent implements OnInit {
  addContractForm: UntypedFormGroup;
  contractServiceTypes = ['HHG Provider'];
  affiliationRelations = [];
  affiliateOf = ['Atlas Van Line'];
  selectedServiceType: string;
  selectedAffiliationRelationship: string;
  selectedAffiliatedOf: string;
  validationErrorMessages = {
    'startDateRequired': 'Contract start date must be entered',
    'endDateInvalid': 'Contract end date must be greater than contract start date',
    'serviceTypeInvalid': 'You must select Contract Service Type',
    'affilationRelationInvalid': 'You must select Relationship',
    'affiliateIdInvalid': 'You must enter Affiliate ID',
    'affiliateOfInvalid': 'You must select Affiliate Of',
    'emailInvalid': 'Valid email must be entered'
  };
  contractData: SupplierContractData;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<AddContractComponent>,
  private readonly notificationsService: NotificationsService,
  private readonly formBuilder: UntypedFormBuilder,
  private readonly snackBar: MatSnackBar,
  private readonly spinner: NgxSpinnerService,
  private readonly Logger: LoggerService,
  private router: Router,
  private supplierContractService: SupplierContractService) {}

  ngOnInit() {
    this.InitializeAfflicationRelation();
    this.addContractForm = this.formBuilder.group({
      packDateGroup: this.formBuilder.group({
        start: ['', Validators.required],
        end: [''],
      }, { validators: [EnddateRangeValidator] }),
      contractServiceType: ['', Validators.required],
      affiliationRelation: ['', Validators.required],
      affiliateOf: [''],
      affiliateId: [''] ,
      notificationEmail: ['', Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+[a-zA-Z0-9-]*\\.[a-zA-Z]{2,3}$')]
    });
    this.subscribeToAffiliationRelationChange();
    if (this.data.contractInfo) {
      this.setFormDataForEdit(this.data.contractInfo);
    }
  }

  /** value changes for affiliation relationship  selection control */
  subscribeToAffiliationRelationChange() {
    const affiliationRelationshipCtrl = this.addContractForm.get('affiliationRelation');
    affiliationRelationshipCtrl.valueChanges.subscribe(data => {
      if (data === 'Affiliate') {
      this.setValidationOnAffliate(true);
      } else {
        this.setValidationOnAffliate(false);
      }
    });
  }

  /**To inintialize the data for affilationRelations */
  InitializeAfflicationRelation() {
    this.affiliationRelations.push('Primary');
    this.affiliationRelations.push('Affiliate');
  }

  /**TO set validation for affilaite of selection and affiliate ID text control  */
  setValidationOnAffliate(isToValidate) {
    const affiliateOfCtrl = this.addContractForm.get('affiliateOf');
    const affiliateIdCtrl = this.addContractForm.get('affiliateId');
    if (isToValidate) {
      affiliateOfCtrl.setValidators(Validators.required);
      affiliateIdCtrl.setValidators(Validators.required);
    } else {
      affiliateOfCtrl.clearValidators();
      affiliateOfCtrl.markAsUntouched();

      affiliateIdCtrl.clearValidators();
      affiliateIdCtrl.markAsUntouched();
      affiliateIdCtrl.reset();
      affiliateOfCtrl.reset();
    }
    affiliateOfCtrl.updateValueAndValidity();
    affiliateIdCtrl.updateValueAndValidity();
  }

  /**To create Contract */
  saveContract(event): void {
    event.preventDefault();
    event.stopPropagation();
    if (!this.data.contractInfo) {
    this.notificationsService.flashNotification('success',
    'Contract Created',
    true,
    'dismiss');
    } else {
      this.notificationsService.flashNotification('success',
    'Contract Saved',
    true,
    'dismiss');
    }
    this.formContractData();
    this.supplierContractService.updateContractIndex(this.data.index);
    this.supplierContractService.updateContractData(this.contractData);
    this.dialogRef.close(this.contractData);
  }

  /**To Close the Add Contract Popup  */
  close(event): void {
    event.preventDefault();
    event.stopPropagation();
    this.dialogRef.close();
  }

  formContractData() {
    this.contractData = {
      contractServiceType: this.addContractForm.value.contractServiceType,
      affiliationRelationShip: this.addContractForm.value.affiliationRelation,
    };
    if (this.addContractForm.value.affiliationRelation === 'Affiliate') {
      this.contractData.affiliateOf = this.addContractForm.value.affiliateOf;
      this.contractData.affiliateId = this.addContractForm.value.affiliateId;
    }
    if (this.addContractForm.value.packDateGroup.start) {
      this.contractData.contractStartDate = this.addContractForm.value.packDateGroup.start;
    }
    if (this.addContractForm.value.packDateGroup.end) {
      this.contractData.contractEndDate = this.addContractForm.value.packDateGroup.end;
    }
    if (this.addContractForm.value.notificationEmail) {
      this.contractData.notificationEmail = this.addContractForm.value.notificationEmail;
    }
  }

  setFormDataForEdit(data: SupplierContractData) {
    this.addContractForm.get('contractServiceType').setValue(data.contractServiceType);
    this.addContractForm.get('affiliationRelation').setValue(data.affiliationRelationShip);
    this.selectedServiceType = data.contractServiceType;
    this.selectedAffiliationRelationship = data.affiliationRelationShip;

    if (data.affiliationRelationShip === 'Affiliate') {
      this.selectedAffiliatedOf = data.affiliateOf;
      this.addContractForm.get('affiliateOf').setValue(data.affiliateOf);
      this.addContractForm.get('affiliateId').setValue(data.affiliateId);
    }

    if (data.contractStartDate) {
      this.addContractForm.get('packDateGroup').get('start').setValue(data.contractStartDate);
    }
    if (data.contractEndDate) {
      this.addContractForm.get('packDateGroup').get('end').setValue(data.contractEndDate);
    }
    if (data.notificationEmail) {
      this.addContractForm.get('notificationEmail').setValue(data.notificationEmail);
    }
  }

}
