import { DailyOperations } from './dailyoperations.model';
import { OracleSiteId } from './oraclesiteid.model';
import { TerritoryCoverage } from './territory-coverage.model';

export interface SupplierContractData {
    contractServiceType: string;
    contractStartDate?: string;
    contractEndDate?: string;
    affiliationRelationShip: string;
    affiliateOf?: string;
    affiliateId?: string;
    notificationEmail?: string;
}
export interface SupplierContract {
    contract: SupplierContractData;
    dailyOperationContract: DailyOperations;
    contractStatus: string;
    oracleSiteIds: OracleSiteId[];
    territoryCoverages: TerritoryCoverage[];
}
