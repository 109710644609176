<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<div class="candidateprofile-nav">
  <a [routerLink]="['/project-alpha/suppliers']">Suppliers</a>
  <span class="current">{{preferredName}}</span>
</div>
<div class="budget-container">
  <div class="budget-content-container">
    <app-supplier-info [supplierDetails]="clickedSupplier" [isManageSupplier]="isManageSupplierCapability">
    </app-supplier-info>
    <mat-accordion class="candidate-review-panel candidate-info-panel" multi="true">
      <!--supplier Details starts-->
      <mat-expansion-panel class="candidate-detail">
        <mat-expansion-panel-header [collapsedHeight]="matExpansionHeaderHeight"
          [expandedHeight]="matExpansionHeaderHeight">
          <span class="panel-heading head">Supplier Details</span>
        </mat-expansion-panel-header>
        <mat-panel-description>
          <app-supplier-details-info [supplierDetails]="clickedSupplier" class="full-stretch wide">
          </app-supplier-details-info>
        </mat-panel-description>
      </mat-expansion-panel>
    </mat-accordion>
    <div class="place-content" *ngIf="!isContractAdded">
      <p>Add a Contract for Supplier</p>
      <button type="submit" mat-button class="mat-button contained-button size" (click)="addContract()">Add Contract</button>
    </div>
    <mat-accordion *ngIf="isContractAdded">
      <mat-expansion-panel #contract  hideToggle class="contractExpansionPanel" [expanded]="true" *ngFor="let contractData of Contracts;let contractIndex=index">
        <!-- commented out as part of MOV360-26: variable does not exist. -->

        <!-- <mat-expansion-panel-header [collapsedHeight]="matCollapseHeaderHeight" -->
        <!-- [expandedHeight]="matExpansionHeaderHeight" class="contractExpansionHeader"> -->

        <!-- Substituted lines below -->
        <mat-expansion-panel-header
          [expandedHeight]="matExpansionHeaderHeight" class="contractExpansionHeader">
          <mat-panel-title class="contractTitle">
            <div class="headdiv" fxLayout="row">
              <div fxFlex="70%" fxLayoutAlign="start">
            <span class="heading">Contract: {{contractData.contract.contractServiceType}}</span>
            </div>
            <div fxFlex="30%" fxLayoutAlign="end" *ngIf="!contract.expanded">
              <span class="contractStatusheading">Status: {{contractData.contractStatus}}</span>
            </div>
          </div>
          </mat-panel-title>
          <mat-icon *ngIf="!contract.expanded" class="maticon">add_circle_outline</mat-icon>
          <mat-icon *ngIf="contract.expanded" class="maticon">remove_circle_outline</mat-icon>
          </mat-expansion-panel-header>
          <app-supplier-contract class="contractSection" [contractData]="contractData" [contractIndex]="contractIndex"
            [isManageSupplier]="isManageSupplierCapability"></app-supplier-contract>
      </mat-expansion-panel>
    </mat-accordion>
    <div *ngIf="isContractAdded" class="place-addContract">
      <button type="submit" mat-button class="mat-button contained-button size" (click)="addContract()">Add Contract</button>
    </div>
  </div>
</div>
