<!-- Bredcrumcontainer starts -->
<div class="breadcrumcontainer" *ngIf="costmodelList && costmodelList.length > 1">
    <div class=" candidateprofile-nav">
        <a [routerLink]="['/project-alpha/cost-models']">{{costModelBreadcrumbTittle}}</a><span
            class="current">{{compareCostModelBreadcrumbTittle}}</span>
    </div>
</div>
<!-- Bredcrumcontainer ends -->


<div class="compareCostContainer">
    <h1 class="cdk-visually-hidden">Comparing selected cost models</h1>
    <div class="settings-scrolldiv-horizontalbar">
        <!-- First cont starts -->
        <div class="compareBoxMain" *ngFor="let costmodel of costmodelList">
            <div class="compareBoxMain-content">
                <div>
                    <mat-form-field fxFlex>
                        <input matInput type="text" class="costModelName" placeholder="Name of Cost Model"
                            [value]="costmodel.costModelName" readonly>
                        <mat-icon class="material-icons warning_icon" *ngIf="costmodel.status === 'Expired'"
                            (click)="openTooltipDialog(costmodel.expiryDate, $event)" matSuffix>
                            {{warningIcon}}
                        </mat-icon>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field fxFlex>
                        <input matInput type=" text" placeholder="Level" [value]="costmodel.level" readonly>
                    </mat-form-field>
                </div>
                <div fxLayout>
                    <div fxFlex="45%">
                        <mat-form-field fxFlex="100%">
                            <input type="text" placeholder="Departure" [value]="costmodel.deptFullAdd" matInput
                                readonly>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-icon class="material-icons modal-rightnavarrow">chevron_right</mat-icon>
                    </div>
                    <div fxFlex="45%">
                        <mat-form-field fxFlex="100%">
                            <input type="text" placeholder="Destination" [value]="costmodel.destFullAdd" matInput
                                readonly>
                        </mat-form-field>
                    </div>
                </div>
                <div class="personMainContainer">
                    <!-- 1Person cont starts -->
                    <div class="personContainer">
                        <div class="personHeader" fxLayout>
                            <span fxFlex="24%" fxFlex.xs="30%">{{personOne}}</span>
                            <span fxFlex="10%">
                                <mat-icon svgIcon="person-one" class="cartus-compaer-cost-icon"></mat-icon>
                            </span>
                            <span fxFlex="66%"
                                class="personHeaderprice">{{(costmodel.costEstimates[0].estimatedTotalMinimumCost)?(costmodel.costEstimates[0].estimatedTotalMinimumCost | number:'.0-0') : 0  }}
                                -
                                {{(costmodel.costEstimates[0].estimatedTotalMaximumCost)?(costmodel.costEstimates[0].estimatedTotalMaximumCost | number:'.0-0'): 0}}
                                {{currency}}</span>
                        </div>
                        <div class="personDetails" fxLayout>
                            <div fxFlex="60%" class="personInfo"><span class="services">{{coreServices}}
                                </span><span>{{vanLineMove}}</span></div>
                            <div fxFlex="40%" class="comparePrice">
                                {{(costmodel.costEstimates[0].coreServices.estimatedSubTotalMinimumCost)?(costmodel.costEstimates[0].coreServices.estimatedSubTotalMinimumCost | number:'.0-0') : 0}}
                                -
                                {{(costmodel.costEstimates[0].coreServices.estimatedSubTotalMaximumCost)?(costmodel.costEstimates[0].coreServices.estimatedSubTotalMaximumCost | number:'.0-0') : 0}}
                                {{currency}}</div>
                        </div>
                        <div class="personDetails" fxLayout>
                            <div fxFlex="60%" class="personInfo"><span class="services">{{flexibleServices}}</span>
                                <span>{{multipleProbableServices}}</span></div>
                            <div fxFlex="40%" class="comparePrice">
                                {{(costmodel.costEstimates[0].flexServices.estimatedSubTotalMinimumCost)?(costmodel.costEstimates[0].flexServices.estimatedSubTotalMinimumCost | number:'.0-0') : 0}}
                                -
                                {{(costmodel.costEstimates[0].flexServices.estimatedSubTotalMaximumCost)?(costmodel.costEstimates[0].flexServices.estimatedSubTotalMaximumCost | number:'.0-0') : 0}}
                                {{currency}}</div>
                        </div>
                        <div class="personDetails" fxLayout>
                            <div fxFlex="60%" class="personInfo"><span class="services">{{taxAssistanceServices}}</span>
                            </div>
                            <div fxFlex="40%" class="comparePrice">
                                {{(costmodel.costEstimates[0].minTaxAmount)?(costmodel.costEstimates[0].minTaxAmount | number:'.0-0') : 0}}
                                -
                                {{(costmodel.costEstimates[0].maxTaxAmount)?(costmodel.costEstimates[0].maxTaxAmount | number:'.0-0') : 0}}
                                {{currency}}</div>
                        </div>
                    </div>
                    <!--1 Person cont ends-->
                    <!-- 2-3 People starts -->
                    <div class="personContainer">
                        <div class="twopersonHeader" fxLayout>
                            <span fxFlex="24%" fxFlex.xs="30%">{{twoPerson}}</span>
                            <span fxFlex="10%">
                                <mat-icon matPrefix class="cartus-compaer-cost-icon" svgIcon="person-two"></mat-icon>
                            </span>
                            <span fxFlex="66%"
                                class="personHeaderprice">{{(costmodel.costEstimates[1].estimatedTotalMinimumCost)?(costmodel.costEstimates[1].estimatedTotalMinimumCost | number:'.0-0') : 0}}
                                -
                                {{(costmodel.costEstimates[1].estimatedTotalMaximumCost)?(costmodel.costEstimates[1].estimatedTotalMaximumCost | number:'.0-0') : 0 }}
                                {{currency}}</span>
                        </div>
                        <div class="personDetails" fxLayout>
                            <div fxFlex="60%" class="personInfo"><span class="services">{{coreServices}}</span>
                                <span>{{vanLineMove}}</span></div>
                            <div fxFlex="40%" class="comparePrice">
                                {{(costmodel.costEstimates[1].coreServices.estimatedSubTotalMinimumCost)?(costmodel.costEstimates[1].coreServices.estimatedSubTotalMinimumCost | number:'.0-0'): 0}}
                                -
                                {{(costmodel.costEstimates[1].coreServices.estimatedSubTotalMaximumCost)?(costmodel.costEstimates[1].coreServices.estimatedSubTotalMaximumCost | number:'.0-0'):0 }}
                                {{currency}}</div>
                        </div>
                        <div class="personDetails" fxLayout>
                            <div fxFlex="60%" class="personInfo"><span
                                    class="services">{{flexibleServices}}</span><span>{{multipleProbableServices}}</span>
                            </div>
                            <div fxFlex="40%" class="comparePrice">
                                {{(costmodel.costEstimates[1].flexServices.estimatedSubTotalMinimumCost)?(costmodel.costEstimates[1].flexServices.estimatedSubTotalMinimumCost | number:'.0-0') : 0}}
                                -
                                {{(costmodel.costEstimates[1].flexServices.estimatedSubTotalMaximumCost)?(costmodel.costEstimates[1].flexServices.estimatedSubTotalMaximumCost| number:'.0-0') : 0}}
                                {{currency}}</div>
                        </div>
                        <div class="personDetails" fxLayout>
                            <div fxFlex="60%" class="personInfo"><span class="services">{{taxAssistanceServices}}</span>
                            </div>
                            <div fxFlex="40%" class="comparePrice">
                                {{(costmodel.costEstimates[1].minTaxAmount)?(costmodel.costEstimates[1].minTaxAmount | number:'.0-0') : 0}}
                                -
                                {{(costmodel.costEstimates[1].maxTaxAmount)?(costmodel.costEstimates[1].maxTaxAmount | number:'.0-0') : 0}}
                                {{currency}}</div>
                        </div>
                    </div>
                    <!-- 2-3 People ends -->
                    <!-- 4+ People starts -->
                    <div class="personContainer">
                        <div class="fourpersonHeader" fxLayout>
                            <span fxFlex="24%" fxFlex.xs="30%">{{fourPerson}}</span>
                            <span fxFlex="10%">
                                <mat-icon matPrefix class="cartus-compaer-cost-icon" svgIcon="person-four">
                                </mat-icon>
                            </span>
                            <span fxFlex="66%"
                                class="personHeaderprice">{{(costmodel.costEstimates[2].estimatedTotalMinimumCost)?(costmodel.costEstimates[2].estimatedTotalMinimumCost | number:'.0-0') : 0}}
                                -
                                {{(costmodel.costEstimates[2].estimatedTotalMaximumCost)?(costmodel.costEstimates[2].estimatedTotalMaximumCost | number:'.0-0') : 0}}
                                {{currency}}</span>
                        </div>
                        <div class="personDetails" fxLayout>
                            <div fxFlex="60%" class="personInfo"><span class="services">{{coreServices}}</span>
                                <span>{{vanLineMove}}</span></div>
                            <div fxFlex="40%" class="comparePrice">
                                {{(costmodel.costEstimates[2].coreServices.estimatedSubTotalMinimumCost)?(costmodel.costEstimates[2].coreServices.estimatedSubTotalMinimumCost | number:'.0-0') : 0}}
                                -
                                {{(costmodel.costEstimates[2].coreServices.estimatedSubTotalMaximumCost)?(costmodel.costEstimates[2].coreServices.estimatedSubTotalMaximumCost | number:'.0-0') : 0}}
                                {{currency}}</div>
                        </div>
                        <div class="personDetails" fxLayout>
                            <div fxFlex="60%" class="personInfo"><span class="services">{{flexibleServices}}</span>
                                <span>{{multipleProbableServices}}</span></div>
                            <div fxFlex="40%" class="comparePrice">
                                {{(costmodel.costEstimates[2].flexServices.estimatedSubTotalMinimumCost)?(costmodel.costEstimates[2].flexServices.estimatedSubTotalMinimumCost | number:'.0-0') : 0}}
                                -
                                {{(costmodel.costEstimates[2].flexServices.estimatedSubTotalMaximumCost)?(costmodel.costEstimates[2].flexServices.estimatedSubTotalMaximumCost | number:'.0-0') : 0}}
                                {{currency}}</div>
                        </div>
                        <div class="personDetails" fxLayout>
                            <div fxFlex="60%" class="personInfo"><span class="services">{{taxAssistanceServices}}</span>
                            </div>
                            <div fxFlex="40%" class="comparePrice">
                                {{(costmodel.costEstimates[2].minTaxAmount)?(costmodel.costEstimates[2].minTaxAmount | number:'.0-0') : 0}}
                                -
                                {{(costmodel.costEstimates[2].maxTaxAmount)?(costmodel.costEstimates[2].maxTaxAmount | number:'.0-0') : 0}}
                                {{currency}}</div>
                        </div>
                    </div>
                    <!-- 4+ People ends -->
                </div>
            </div>
        </div>
        <!-- first cont ends -->
    </div>
</div>