<ngx-spinner type="ball-spin-clockwise-fade" aria-atomic="true" role="alert" size="medium">
  <span class="cdk-visually-hidden">Loading, please wait...</span>
</ngx-spinner>

<!--Section To Candidate Profiles-->
<div class="budget-container transferee" *ngIf="candidateBudgetDetails">
  <div class="budget-content-container">
    <div class="candidateprofile-nav">
      <a [routerLink]="['/project-alpha/approved-moves']" #breadcrumb>Authorized Transferees</a><span
        class="current">{{ candidateName }}</span>
    </div>

    <app-candidate-contact-info *ngIf="candidateContactInfo" [candidateContactInfo]="candidateContactInfo"
      [candidateId]="candidateId">
    </app-candidate-contact-info>

    <mat-accordion class="candidate-review-panel candidate-info-panel" multi="true">
      <!--Candidate Details starts-->
      <mat-expansion-panel class="candidate-detail">
        <mat-expansion-panel-header [collapsedHeight]="matExpansionHeaderHeight"
          [expandedHeight]="matExpansionHeaderHeight">
          <span class="panel-heading">Transferee Details</span>
        </mat-expansion-panel-header>
        <mat-panel-description>
          <app-candidate-info *ngIf="candidateInfo" [candidateInfo]="candidateInfo" class="full-stretch">
          </app-candidate-info>
        </mat-panel-description>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion class="candidate-review-panel" multi="true">
      <!--Move Details starts-->
      <mat-expansion-panel>
        <mat-expansion-panel-header fxLayout fxLayout.xs="row" fxLayoutGap="10px">
          <div fxFlex="34%" class="panel-heading">Move Details</div>
          <div *ngIf="candidateMoveInfo" fxFlex="66%" class="header-right-node move hide-on-expand">
            <span>{{candidateMoveInfo.departure.fullAddress}}</span>
            <mat-icon class="material-icons arrow">chevron_right</mat-icon>
            <span>{{candidateMoveInfo.destination.fullAddress}}</span>
          </div>
        </mat-expansion-panel-header>
        <mat-panel-description class="remove-margin" fxLayout="column" fxLayoutGap="16px">

          <app-candidate-move-info *ngIf="candidateMoveInfo" [candidateMoveInfo]="candidateMoveInfo"
            class="full-stretch"></app-candidate-move-info>

          <div class="display-data-inline">
            <mat-label class="data-label">Move Status: </mat-label>
            <span class="data-field">{{moveStatus}}</span>
          </div>

          <div class="display-data-inline">
            <mat-label class="data-label">Move Phase: </mat-label>
            <a href="javascript:void(0);" [ngClass]="{'clickable' : approvedMovesDetails.movePhase }"
              (click)="openMovePhaseModal(approvedMovesDetails, $event)">
              <app-multi-step [steps]="5" [selected]="approvedMovesDetails.movePhase"></app-multi-step>
            </a>
          </div>

        </mat-panel-description>
      </mat-expansion-panel>
    </mat-accordion>
    <!-- Financial Details starts-->
    <mat-accordion class="candidate-review-panel" multi="true">
      <mat-expansion-panel>
        <mat-expansion-panel-header fxLayout fxLayout.xs="row" fxLayoutGap="10px">
          <div fxFlex="34%" fxFlex.xs="40%" class="panel-heading">Financial Details</div>
          <div *ngIf="candidateMoveInfo" fxFlex="66%" class="header-right-node move hide-on-expand">
            <span class="unspent-txt">Unspent Budget: </span>
            <span>{{formatTotalAmount(candidateBudgetDetails.approvedMoves.remainingAmt)}}</span>
          </div>
        </mat-expansion-panel-header>
        <mat-panel-description class="remove-margin financial-detail" fxLayout="column" fxLayoutGap="16px">
          <div class="unspent-budgetcont">
            <div fxFlex="100%" fxLayout fxLayout.xs="row" fxLayoutGap="10px">
              <div fxFlex="66%" class="service-name">Unspent Budget
              </div>
              <div fxFlex="33%" class="price-field">
                {{formatTotalAmount(candidateBudgetDetails.approvedMoves.remainingAmt)}}</div>
            </div>
          </div>
          <div>

            <!-- Flex content -->
            <div class="finance-container">
              <div class="finance-money finance-chart">
                <app-budget-chart class="candidate-budget-chart" *ngIf="budgetDetailsService"
                  [serviceDetails]="budgetDetailsService">
                </app-budget-chart>
              </div>
              <div class="finance-flex-break" aria-hidden="true"></div>
              <div class="finance-money">
                <div class="financial-bord-btm data-field">
                  <div class="finance-total-mobcont" fxFlex="100%" fxLayout fxLayout.xs="column" fxLayoutGap="10px">
                    <div fxFlex="66%" fxFlex.xs="100%" class="service-name">
                      Total Budget
                    </div>
                    <div fxFlex="33%" fxFlex.xs="100%" class="price-field">
                      {{formatTotalAmount(candidateBudgetDetails.approvedMoves.authorizedAmt)}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="finance-money">
                <div class="financial-bord-btm">
                  <div fxFlex="100%" fxLayout fxLayout.xs="row" fxLayoutGap="10px">
                    <div fxFlex="66%" class="service-name">
                      <span>Core Services</span>
                      <span class="panel-subheading">
                        Includes Van Line Move, Insured for up to
                        {{formatInsuranceAmt(candidateBudgetDetails.coreBenefits.minInsuredValue)}}
                      </span>
                    </div>
                    <div fxFlex="33%" class="price-field">
                      {{formatTotalAmount(candidateBudgetDetails.coreBudgetTotal)}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="finance-money">
                <div class="financial-bord-btm">
                  <div fxFlex="100%" fxLayout fxLayout.xs="row" fxLayoutGap="10px">
                    <div fxFlex="66%" class="service-name">Flexible Spend
                    </div>
                    <div fxFlex="33%" class="price-field">
                      {{formatTotalAmount(roundAmount(candidateBudgetDetails.approvedMoves.authorizedAmt) - roundAmount(candidateBudgetDetails.coreBudgetTotal))}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Flex content ends -->
          </div>
        </mat-panel-description>
      </mat-expansion-panel>
    </mat-accordion>
    <!-- Financial Details ends-->


    <mat-accordion class="candidate-review-panel" multi="true">
      <!--Departure & Home Details starts-->
      <mat-expansion-panel>
        <mat-expansion-panel-header fxLayout fxLayout.xs="row" fxLayoutGap="10px">
          <div fxFlex="80%" class="panel-heading">Departure Home Details</div>
        </mat-expansion-panel-header>
        <mat-panel-description class="remove-margin">
          <app-candidate-departure-home-detail *ngIf="candidateDepartureInfo"
            [candidateDepartureInfo]="candidateDepartureInfo" class="full-stretch">
          </app-candidate-departure-home-detail>
        </mat-panel-description>
      </mat-expansion-panel>
      <!--Candidate Details starts-->
      <mat-expansion-panel class="candidate-detail">
        <mat-expansion-panel-header>
          <span class="panel-heading">Timeline</span>
        </mat-expansion-panel-header>
        <mat-panel-description>
          <app-transferee-timeline *ngIf="timelineService" [timelineService]="timelineService">
          </app-transferee-timeline>
        </mat-panel-description>
      </mat-expansion-panel>
      <!--Document Library starts-->
      <mat-expansion-panel class="candidate-detail">
        <mat-expansion-panel-header>
          <span class="panel-heading">Document Library</span>
        </mat-expansion-panel-header>
        <mat-panel-description class="document-library">
          <app-transferee-document-library *ngIf="invoiceDetails" [transfereeName]="candidateName"
            [invoiceDetails]="invoiceDetails" [amountDue]="moveBudget"></app-transferee-document-library>
        </mat-panel-description>
      </mat-expansion-panel>
      <!-- Sentiment -->
      <mat-expansion-panel class="candidate-detail">
        <mat-expansion-panel-header>
          <span class="panel-heading">Sentiment</span>
        </mat-expansion-panel-header>
        <mat-panel-description>
          <table class="sentimentDates">
            <ng-container>
              <tr>
                <td class="col-key-dates" style="width: 78px; height: 2.083em;">
                  <div class="no-key-date" *ngIf="!isHHGDate">
                    <span>N/A</span>
                  </div>
                  <div class="key-date" *ngIf="isHHGDate">
                    <span>{{hhgDate}}</span>
                  </div>
                </td>
                <ng-container>
                  <td class="col-data-detail" [ngClass]="{'hhgDel': !isHHGDate}">
                    {{hhgDeliver}}
                  </td>
                </ng-container>
                <ng-container>
                  <td class="col-data">
                    <mat-icon *ngIf="question && !isHHGDate" class="cartus-icon-que tooltip" svgIcon="question"
                      aria-hidden="false" aria-label="Question">
                      <span class="tooltiptext">Not Provided</span>
                    </mat-icon>
                    <mat-icon *ngIf="ok && isHHGDate" class="cartus-icon-ok tooltip" svgIcon="ok" aria-hidden="false"
                      aria-label="Ok">
                      <span class="tooltiptext">OK</span>
                    </mat-icon>
                    <mat-icon *ngIf="smile && isHHGDate" class="cartus-icon-smile tooltip" svgIcon="smile" aria-hidden="false"
                      aria-label="Smile">
                      <span class="tooltiptext">Good</span>
                    </mat-icon>
                    <mat-icon *ngIf="sad && isHHGDate" class="cartus-icon-sad tooltip" svgIcon="sad" aria-hidden="false"
                      aria-label="Sad">
                      <span class="tooltiptext">Bad</span>
                    </mat-icon>
                  </td>
                </ng-container>
              </tr>
            </ng-container>
          </table>
        </mat-panel-description>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>