/** MOV360-880 - to be removed at future date */
import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApprovedMove } from '../../../core/models/approved-move';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApprovedMovesService } from '../../../core/services/approved-moves.service';
import { BudgetDetailsService } from '../../../core/services/budget-details.service';
import { PopupPositionService } from './../../../core/services/popup-position.service';
import { CandidateBudgetDetails } from '../../../core/models/candidate-budget-details.model';
import { formatNumber } from '@angular/common';
import { CandidateContactInfo } from '../../../core/models/candidate-contact-info.model';
import { CandidateMoveInfo } from '../../../core/models/candidate-move-info.model';
import { DepatureHomeDetails } from '../../../core/models/depature-home-details.models';
import { OverlayConfig, OverlayRef, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { AuthorizedMovePhaseComponent } from '../../../core/components/authorized-move-phase/authorized-move-phase.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoggerService } from '../../../core/services/logger.service';
import { UserContextService } from '../../../core/services/user-context.service';
import { Timeline } from '../../../core/models/timeline-service.model';
import { PartySharedService } from '../../../core/services/party-shared.service';
import { apiErrorMessage } from '../../../core/models/constants';
import { HighValueItemsGetResponse } from '../../../core/models/high-value-items-get-response.model';
import { ArrayUtil } from 'src/app/public/utils/array.util';
import * as moment from 'moment';

/**Transferee details component */
@Component({
  selector: 'app-authorize-transferee-details',
  templateUrl: './authorize-transferee-details.component.html',
  styleUrls: ['./authorize-transferee-details.component.scss']
})
export class AuthorizeTransfereeDetailsComponent implements OnInit, AfterViewInit {

  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /**used to store approvedMoves details */
  approvedMovesDetails: ApprovedMove;
  /**used to store core and flex budget details */
  budgetDetailsService: any;
  /**store candidate budget details */
  candidateBudgetDetails: CandidateBudgetDetails;
  /**candidateInfo of type CandidateInfo */
  candidateInfo: any;
  /**candidateContactInfo of type CandidateContactInfo */
  candidateContactInfo: CandidateContactInfo;
  /**candidateMoveInfo of type CandidateMoveInfo */
  candidateMoveInfo: CandidateMoveInfo;
  /** stores candidate departure home details information */
  candidateDepartureInfo: DepatureHomeDetails;
  /**stores timeline information */
  timelineService: Timeline[];
  /**Client contact ID */
  clientContactId: string;
  /**store candidate name */
  candidateName: string;
  /**mat accordion panel header height */
  matExpansionHeaderHeight = '40px';
  /** overlayRef to hold overlay config */
  overlayRef: OverlayRef;
  /**candidate move status */
  moveStatus: string;
  /**property to store invoice details */
  invoiceDetails: any;
  /** variable to store the move budget */
  moveBudget: any;
  hvgItems: any;
  hvgDetails: any;
  clickedAuthorizedTransferee: any;
  candidateId: string;
  hhgDeliver = 'HHG Delivery';
  isHHGDate: boolean;
  hhgDate: any;
  sad: boolean;
  smile: boolean;
  ok: boolean;
  question: boolean;
  /**
   * Breadcrumb to be focused first
   */
  @ViewChild('breadcrumb', { static: false }) breadcrumb: ElementRef;

  /**
   * injecting dependencies
   * @param route instance of ActivatedRoute
   * @param spinner spinner injection
   * @param approvedService ApprovedMovesService injection
   */
  constructor(private readonly route: ActivatedRoute,
    private readonly spinner: NgxSpinnerService,
    private readonly approvedService: ApprovedMovesService,
    private readonly overlay: Overlay,
    private readonly positionService: PopupPositionService,
    private readonly snackBar: MatSnackBar,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: UserContextService,
    private readonly partySharedSvc: PartySharedService,
    private readonly budgetSrvc: BudgetDetailsService,
    private router: Router
  ) {
    this.clickedAuthorizedTransferee = this.router.getCurrentNavigation().extras.state;
  }

  /**Initialise the component */
  ngOnInit() {
    this.spinner.show();
    this.route.paramMap.subscribe((params: ParamMap) => {
      const candidateId = params.get('candidateId');
      this.candidateId = candidateId;
      if (candidateId) {
        this.partySharedSvc.getPartyId().then(id => {
          if (id) {
            this.clientContactId = id;
            this.subscription.add(
              this.approvedService.getAppovedMovesById(candidateId).subscribe(budgetDetails => {
                this.spinner.hide();
                if (budgetDetails) {
                  this.isHHGDate = (budgetDetails.sentimentSubmittedOn && budgetDetails.sentimentResponse) ? true : false;
                  this.hhgDate = budgetDetails.sentimentSubmittedOn ? moment(budgetDetails.sentimentSubmittedOn).format('YYYY-MM-DD') : '';
                  this.sad = budgetDetails.sentimentResponse === 'bad' ? true : false;
                  this.smile = budgetDetails.sentimentResponse === 'good' ? true : false;
                  this.ok = budgetDetails.sentimentResponse === 'okay' ? true : false;
                  this.question = (budgetDetails.sentimentSubmittedOn && budgetDetails.sentimentResponse) ? false : true;
                  this.candidateBudgetDetails = budgetDetails;
                  this.moveBudget = this.candidateBudgetDetails.UserBudget ?
                    Number(this.candidateBudgetDetails.UserBudget) :
                    Math.ceil(this.candidateBudgetDetails.coreBudgetTotal + this.candidateBudgetDetails.flexSpendTotal);
                  this.getCandidateBudgetDetails(this.candidateBudgetDetails);
                  this.setCandidateName();
                  this.getInvoiceDetails(this.candidateBudgetDetails.candidate.createdBy, candidateId);
                  this.loggedInUserService.getLoggedInUserDetails()
                    .subscribe(response => {
                      const userId: any = response.userId.replace(/ .*/, '');
                      this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-AUTHORIZE_TRANSFEREE_DETAILS');
                    });
                } else {
                  this.snackBar.open(
                    apiErrorMessage,
                    undefined,
                    { duration: 5000 }
                  );
                }
              })
            );
          }
        });
      }

    });
  }

  sortByItem(hvgRes) {
    if (hvgRes && hvgRes.length > 0) {
      hvgRes = ArrayUtil.sortArrayOfObject(
        hvgRes, 'itemType', 'asc', false
      );
    }
    return hvgRes;
  }
  /**store the candidate budget details */
  getCandidateBudgetDetails(candidateDetails: CandidateBudgetDetails) {
    candidateDetails.flexSpendTotal = candidateDetails.flexSpendTotal === null ? 0 : candidateDetails.flexSpendTotal;
    this.candidateContactInfo = {
      candidateName: candidateDetails.candidate.fullname,
      emailId: candidateDetails.candidate.emailAddress,
      phoneNumber: candidateDetails.candidate.phoneNumber,
      countryDialingCode: candidateDetails.candidate.countryDialingCode
    };
    this.candidateInfo = {
      level: candidateDetails.candidate.level,
      businessUnit: candidateDetails.candidate.businessUnit,
      clientName: candidateDetails.candidate.clientName
    };

    this.candidateMoveInfo = {
      departure: {
        fullAddress: candidateDetails.candidate.departure.fullAddress,
        streetAddress: candidateDetails.candidate.departure.streetAddress,
        city: candidateDetails.candidate.departure.city,
        state: candidateDetails.candidate.departure.state,
        zipcode: candidateDetails.candidate.departure.zipcode,
        country: candidateDetails.candidate.departure.country
      },
      destination: {
        fullAddress: candidateDetails.candidate.destination.fullAddress,
        streetAddress: candidateDetails.candidate.destination.streetAddress,
        city: candidateDetails.candidate.destination.city,
        state: candidateDetails.candidate.destination.state,
        zipcode: candidateDetails.candidate.destination.zipcode,
        country: candidateDetails.candidate.destination.country
      },
      totalNumberOfPeople: candidateDetails.needsAssessment.familyDetails.noOfRelocatePeople,
      estimatedMoveStartDate: candidateDetails.needsAssessment.estimatedMoveStartDate,
      estimatedMoveEndDate: candidateDetails.needsAssessment.estimatedMoveEndDate
    };
    this.candidateDepartureInfo = {
      departure: {
        fullAddress: candidateDetails.candidate.departure.fullAddress,
        streetAddress: candidateDetails.candidate.departure.streetAddress,
        city: candidateDetails.candidate.departure.city,
        state: candidateDetails.candidate.departure.state,
        zipcode: candidateDetails.candidate.departure.zipcode,
        country: candidateDetails.candidate.departure.country
      },
      ownerStatus: candidateDetails.needsAssessment.residenceDetails.ownerStatus,
      housingType: candidateDetails.needsAssessment.residenceDetails.homeType,
      noOfRooms: candidateDetails.needsAssessment.residenceDetails.noOfRooms.toString(),
      hasHighValueGoods: candidateDetails.hasHighValueGoods
    };
    this.budgetDetailsService = {
      coreBenefitsTotal: Math.ceil(candidateDetails.coreBudgetTotal),
      flexSpendTotal: this.roundAmount(candidateDetails.approvedMoves.authorizedAmt) - this.roundAmount(candidateDetails.coreBudgetTotal),
      title: '',
      class: 'authorized-moves'
    };

    this.approvedMovesDetails = candidateDetails.approvedMoves;
    let activeStatus;
    for (const element of candidateDetails.approvedMoves.movePhase) {
      if (element.status === true) {
        activeStatus = element.phase;
      } else {
        break;
      }
    }
    this.moveStatus = activeStatus;
    this.timelineService = candidateDetails.timelineDetails;
  }

  gethvgDetails(highValue: HighValueItemsGetResponse) {
    this.hvgDetails = {
      fullName: this.candidateContactInfo.candidateName,
      orderRef: this.clickedAuthorizedTransferee.orderReference,
      clientName: this.candidateInfo.clientName,
      departure: this.candidateMoveInfo.departure.city + ', ' + this.candidateMoveInfo.departure.state,
      destination: this.candidateMoveInfo.destination.city + ', ' + this.candidateMoveInfo.destination.state,
      vanLineName: 'Atlas Van Lines',
      submittedOn: highValue.hvgSubmittedOn,
      totalAmt: highValue.totalAmt,
      hvgItemList: highValue.List
    };
  }

  /** sets Candidate Name */
  setCandidateName() {
    this.candidateName =
      `${this.candidateContactInfo.candidateName.split(',')[1]} ${this.candidateContactInfo.candidateName.split(',')[0]}`;
  }
  /**Format the amount to USD format */
  formatTotalAmount(Amount) {
    return formatNumber(Math.round(Amount), 'en-US').toString() + ' USD';
  }
  formatInsuranceAmt(amt) {
    return '$' + formatNumber(amt, 'en-US').toString();
  }

  roundAmount(amount) {
    return Math.round(amount);
  }
  /** Open the move phase dialog */
  openMovePhaseModal(approvedMove: ApprovedMove, evt: MouseEvent) {
    evt.stopPropagation();
    if (approvedMove.movePhase) {
      const rect = (evt.currentTarget as Element).getBoundingClientRect();
      const target = this.getPositionByEvents(rect);
      const element = new ElementRef(target);
      const positionStrategy = this.placeByPositionStrategy(element);
      const overlayConfig = new OverlayConfig({
        width: '',
        height: '',
        panelClass: 'movePhaseModal',
        hasBackdrop: true,
        backdropClass: 'mat-backdrop-transparent',
        positionStrategy,
      });
      this.overlayRef = this.overlay.create(overlayConfig);
      const containerPortal = new ComponentPortal(AuthorizedMovePhaseComponent, null, this.positionService.createInjector({
        movePhase: approvedMove.movePhase,
        overlayRef: this.overlayRef
      }));
      this.overlayRef.attach(containerPortal);
      this.overlayRef.backdropClick().subscribe(() => {
        this.overlayRef.dispose();
      });
    }
  }

  /** get the position by events of the target */
  getPositionByEvents(rect) {
    return {
      getBoundingClientRect: () => ({
        bottom: rect.top + (rect.bottom - rect.top) / 2,
        height: rect.height,
        left: rect.left + (rect.right - rect.left) / 2,
        right: rect.left + (rect.right - rect.left) / 2,
        top: rect.top + (rect.bottom - rect.top) / 2,
        width: rect.width
      }),
    };
  }

  /** place the pop up by position strategy */
  placeByPositionStrategy(element: ElementRef) {
    return this.overlay
      .position()
      .flexibleConnectedTo(element)
      .withFlexibleDimensions(false)
      .withPositions([
        {
          originX: 'end',
          originY: 'center',
          overlayX: 'start',
          overlayY: 'center',
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'bottom',
        },
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        }
      ]);
  }
  /**
   * After view is initialized
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.breadcrumb) {
        this.breadcrumb.nativeElement.focus();
      }
    }, 2000);
  }

  /** method to get invoiceDetails for pdf generation*/
  getInvoiceDetails(clientContactId: string, candidateId: string) {
    this.subscription.add(
      this.budgetSrvc.getInvoicePdfDetails(clientContactId, candidateId, true).subscribe(res => {
        if (res && res.status === 204) {
          this.invoiceDetails = [];
        } else if (res && res.status === 200 && res.body) {
          this.approvedService.getAddedHighValueItems(candidateId).subscribe((hvgRes: HighValueItemsGetResponse) => {
            if (hvgRes !== null) {
              const sortedItem = this.sortByItem(hvgRes);
              this.gethvgDetails(sortedItem);
            }
            if (this.hvgDetails !== undefined) {
              this.invoiceDetails = [res.body, this.hvgDetails];
            } else {
              this.invoiceDetails = [res.body];
            }
          });
        } else {
          this.snackBar.open(
            apiErrorMessage,
            undefined,
            { duration: 5000 }
          );
        }
      })
    );
  }
}
