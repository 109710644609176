export interface Role {
  _id?: string;
  name: string;
  description: string;
  fromDate?: Date;
  toDate?: Date;
}

export interface Person {
  id: string;
  name: string;
  roles?: Role[];
}

export interface Client {
  id: string;
  name: string;
  number: string;
}

export interface Team {
  id?: string;
  name: string;
  owner: Person | string;
  effectiveDate?: Date;
  expirationDate?: Date;
  status: string;
  roles?: Role[];
  children?: Client[];
  members?: Person[];
  includeAll?: boolean;
}

export interface UpdateTeam extends Team {
  teamRole?: string;
}

export interface UpdateClients {
  includeAll: boolean;
  clients?: Client[] | string[];
}

export interface UpdateMembers {
  employees: Person[] | string[];
}

export interface UpdateMembersApiError {
  name: string;
  message: string;
  employeeDetails: {
    id: string;
    name: string;
  };
}

export interface UpdateMembersErrorData {
  html: boolean;
  header: string;
  lines: string[];
  footer: string;
}

export interface SelectedEmployee {
  id: string;
  name: string;
  roles?: Role[];
  checked: boolean;
}

export interface SelectedClient {
  id: string;
  name: string;
  number: string;
  checked: boolean;
}

export interface SearchTerms {
  teamName?: string;
  owner?: string;
  teamOwnerId?: string;
  status?: string;
  includeInactive?: boolean;
  client?: string;
  teamClientId?: string;
  employee?: string;
  teamEmployeeId?: string;
}

export interface TeamRoles {
  value: string;
  displayValue: string;
}
