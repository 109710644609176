import { Component, HostBinding, OnInit, OnDestroy, ViewEncapsulation, ViewChild, ChangeDetectorRef, ElementRef, AfterContentChecked } from '@angular/core'
import { Observable, of, Subscription } from 'rxjs'
import { map, startWith } from 'rxjs/operators'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { PageEvent } from '@angular/material/paginator'
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { RoleCapabilityService } from '../../../services/role-capability.service'
import { NotificationsService } from 'src/app/core/services/notifications.service'
import { RolecapsRoleMappingComponent } from '../rolecaps-role-mapping/rolecaps-role-mapping.component'
import { RolecapsCapMappingComponent } from '../rolecaps-cap-mapping/rolecaps-cap-mapping.component'
import { LoggerService } from 'src/app/core/services/logger.service'
import { CapabilityDefinition, RoleDefinition } from 'src/app/rolecaps/models/definition.model'
import { RoleCapability } from 'src/app/rolecaps/models/rolecapability.model'
import { GET_ROLE_CAPABILITY_ERROR, GET_ROLE_DEFINITIONS_ERROR, GET_CAPABILITY_DEFINITIONS_ERROR } from 'src/app/rolecaps/models/constants'
import { FormControl } from '@angular/forms'
import * as querystring from 'querystring'

@Component({
  selector: 'app-rolecaps-mappings',
  templateUrl: './rolecaps-mappings.component.html',
  styleUrls: ['./rolecaps-mappings.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RolecapsMappingsComponent implements OnInit, OnDestroy, AfterContentChecked {

  /** Component css class */
  @HostBinding('class') class = 'rolecaps-mappings'

  /** To check for readonly access */
  disable = false

  /** Property to indicate if the component data has loaded */
  loaded = false

  /** Processing */
  processing = false;

  /** Role Capability Table data */
  ROLECAP_BY_ROLE_ELEMENT_DATA: Array<RoleCapability> = []

  /** Role Capability Table data */
  ROLECAP_BY_CAP_ELEMENT_DATA: Array<RoleCapability> = []

  /** Definition Table data */
  DEFINITION_ELEMENT_DATA: Array<RoleDefinition | CapabilityDefinition> = []

  /** Table data source */
  dataSource: MatTableDataSource<RoleDefinition | CapabilityDefinition | RoleCapability> = new MatTableDataSource()

  /** Displayed role definition table columns */
  displayedRoleColumns = [
    'roleName',
    'friendlyName',
    'dependencies',
    'bypassAssociation'
  ]

  /** Displayed role capability table columns */
  displayedRoleCapsByRoleColumns = [
    'name',
    'description',
    'operation',
    'permission',
    'level',
  ]

  /** Displayed capability definition table columns */
  displayedCapabilityColumns = [
    'capabilityName',
    'description',
    'level',
    'application'
  ]

  /** Displayed role capability table columns */
  displayedRoleCapsByCapsColumns = [
    'roleName',
    'friendlyName',
    'operation',
    'permission',
    'level',
  ]

  /** Table Pagination */
  pageInfo = {
    itemCount: 0,
    currentPage: 0,
    perPage: 5,
    perPageOptions: [5, 10, 15]
  }

  /** Role select options */
  roles: RoleDefinition[] = []

  /** Capability select options */
  capabilities: Array<CapabilityDefinition> = []

  /** Roles search view child */
  @ViewChild('roleSearch') roleSearchTextBox: ElementRef

  /** Roles search view child */
  @ViewChild('capabilitySearch') capabilitySearchTextBox: ElementRef

  @ViewChild(MatSort, { static: false }) set sortOrder(sort: MatSort) {
    if (sort) {
      this.dataSource.sort = sort;
    }
  }

  /** To paginate in a mat table */
  @ViewChild('paginatorCaps', { read: MatPaginator }) paginatorCaps: MatPaginator;
  @ViewChild('paginatorRoles', { read: MatPaginator }) paginatorRoles: MatPaginator;

  /** Search filter value */
  filterText = ''

  /** Role form control  */
  roleControl = new FormControl('')

  /** Capability form control  */
  capabilityControl = new FormControl('')

  /** Roles search form control */
  roleSearchTextboxControl = new FormControl()

  /** Capabilities search form control */
  capabilitySearchTextboxControl = new FormControl()

  /** Role filtered options*/
  roleFilteredOptions: Observable<any[]> = of([])

  /** Capability filtered options*/
  capabilityFilteredOptions: Observable<any[]> = of([])

  /** Focused section */
  focusedSection: string

  /** To hold the role definition */
  roleDefinition: any

  /** To hold the returned capability definition */
  capabilityDefinition: any

  /** To hold the returned role capabilities by role */
  roleCapsbyRole: any[] = []

  /** To hold the returned role capabilities by capability */
  roleCapsbyCap: any[] = []

  /** To hold the role name of the returned role capabilities */
  roleName: string

  /** To hold the capability name of the returned role capabilities */
  capabilityName: string

  /** To hold the capability description of the returned role capabilities */
  capabilityDescription: string

  /** To hold the chosen definition item from the payload returned by the API */
  definitonData: RoleDefinition[] | CapabilityDefinition[]

  /** To hold the role capabilities from the payload returned by the API */
  roleCapsData: RoleCapability[]

  /** Dialog Ref */
  dialogRef: MatDialogRef<RolecapsRoleMappingComponent> | MatDialogRef<RolecapsCapMappingComponent>;

  /** Service subscription  */
  private readonly subscription: Subscription = new Subscription();

  /** To indicate when a capability was updated successfully */
  roleCapUpdated: boolean = false

  /** Initial table sort by column */
  sortBy = 'name'

  /** Initial table sort by column */
  roleSortBy = 'roleName'

  /** Initial table sort direction */
  sortDir = 'asc'

  constructor(
    private readonly roleCapabilityService: RoleCapabilityService,
    private readonly notificationsService: NotificationsService,
    private readonly loggerService: LoggerService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.loadRoles()
    this.loadCapabilities()
  }

  ngOnDestroy(): void {
    if (this.notificationsService.snackBar.open) {
      this.notificationsService.snackBar.dismiss()
    }
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges()
  }

 /**
  * Set search textbox value as empty while opening selectbox
  * @param sectionId role or capability section
  * @param event openedChange event
  */
  openedChange(event, section: string) {
    if (section === 'roles') {
      this.roleSearchTextboxControl.patchValue('')
      if (event === true) {
        this.roleSearchTextBox.nativeElement.focus()
      }
    } else if (section === 'capabilities') {
      this.capabilitySearchTextboxControl.patchValue('')
      if (event === true) {
        this.capabilitySearchTextBox.nativeElement.focus()
      }
    }
  }

 /**
  * Set the focus on the focused section
  * @param sectionId role or capability section
  */
  focus(sectionId: string) {
    this.clearValues()
    this.focusedSection = sectionId
    if (sectionId === 'capabilities') {
      this.roleControl.patchValue('')
    } else if (sectionId === 'roles') {
      this.capabilityControl.patchValue('')
    }
  }

  /** Load role data */
  loadRoles() {
    this.processing = true;
    this.roleCapabilityService.getRoleDefinitions().subscribe({
      next:response => {
        if (response && response.status === 200) {
          this.roles = this.filterRoles(response.body)
        } else {
          this.notificationsService.flashNotification('danger', GET_ROLE_DEFINITIONS_ERROR)
        }
      },
      error: err => {
        this.loggerService.error('Failed to get Roles', err)
        this.notificationsService.flashNotification('danger', GET_ROLE_DEFINITIONS_ERROR)
      },
      complete: () => {
        this.roleFilteredOptions = this.roleSearchTextboxControl.valueChanges.pipe(startWith<string>(''), map(name => this.filterValues(name, 'roles')))
      }
    })
  }

  /** Load capabilities data */
  loadCapabilities() {
    this.roleCapabilityService.getCapabilityDefinitions().subscribe({
      next:response => {
        if (response && response.status === 200) {
          let capabilityDefinitions: CapabilityDefinition[] = response.body
          this.capabilities = [...capabilityDefinitions].sort(this.compareCapabilities)
        } else {
          this.notificationsService.flashNotification('danger', GET_CAPABILITY_DEFINITIONS_ERROR)
        }
      },
      error: err => {
        this.loggerService.error('Failed to get Capabilities', err)
        this.notificationsService.flashNotification('danger', GET_CAPABILITY_DEFINITIONS_ERROR)
      },
      complete: () => {
        this.capabilityFilteredOptions = this.capabilitySearchTextboxControl.valueChanges.pipe(startWith<string>(''), map(name => this.filterValues(name, 'capabilities')))
        this.loaded = true
        this.processing = false;
      }
    })
  }

 /**
  * Filter values by search input
  * @param name search input
  * @param type array value
  */
  filterValues(name: string, type: string): any[] {
    const filterValue = name.toLowerCase()
    let filteredList
    if (type === 'roles') {
      filteredList = this.roles.filter(r => r.roleName.toLowerCase().indexOf(filterValue) > -1)
    } else if (type === 'capabilities') {
      filteredList = this.capabilities.filter(c => c.capabilityName.toLowerCase().indexOf(filterValue) > -1)
    }
    return filteredList
  }

 /**
  * Select element change handler
  * @param event selection change event
  * @param section role or capability section
  */
  selectionChange(event, section: string) {
    this.clearValues();
    if (event.isUserInput === true) {
      if (section === 'roles') {
        const roleName = event.source.value
        this.loadRoleCapsByRole(roleName)
      } else if (section === 'capabilities') {
        const capabilityName = event.source.value
        this.loadRoleCapsByCap(capabilityName)
      }
    }
  }

  /** Clear the values */
  clearValues() {
    if (this.notificationsService.snackBar.open && !this.roleCapUpdated) {
      this.notificationsService.snackBar.dismiss()
    } else {
      this.roleCapUpdated = false
    }
    this.roleDefinition = null
    this.capabilityDefinition = null
    this.roleCapsbyRole = []
    this.roleCapsbyCap = []
    this.ROLECAP_BY_CAP_ELEMENT_DATA = []
    this.ROLECAP_BY_ROLE_ELEMENT_DATA = []
    this.definitonData = []
    this.roleCapsData = []
  }

 /**
  * Load role capabilities data
  * @param roleName specified role name
  */
  loadRoleCapsByRole(roleName: string) {
    this.focus('roles')
    this.processing = true;
    const queryString = {}
    queryString['roleName'] = roleName
    this.roleCapabilityService.getRoleCapByRoleName(querystring.stringify(queryString)).subscribe({
      next: response => {
        if (response && !response.body) {
          this.roleDefinition = this.roles.filter(r => r.roleName === roleName)
          this.definitonData = this.roleDefinition
          this.DEFINITION_ELEMENT_DATA = this.roleDefinition
          this.dataSource = new MatTableDataSource(this.DEFINITION_ELEMENT_DATA)
        }
        if (response && response.body && response.status === 404) {
          this.roleDefinition = this.roles.filter(r => r.roleName === roleName)
          this.definitonData = this.roleDefinition
          this.DEFINITION_ELEMENT_DATA = this.roleDefinition
          this.dataSource = new MatTableDataSource(this.DEFINITION_ELEMENT_DATA)
        } else if (response && response.body && response.status === 200) {
          this.roleName = response.body.roleName
          this.roleCapsbyRole = response.body.capabilities
          this.roleCapsData = [response.body]
          this.ROLECAP_BY_ROLE_ELEMENT_DATA = this.roleCapsbyRole
          this.dataSource = new MatTableDataSource(this.ROLECAP_BY_ROLE_ELEMENT_DATA)
          if (!response.body.capabilities || response.body.capabilities.length === 0) {
            this.roleDefinition = this.roles.filter(r => r.roleName === roleName)
            this.definitonData = this.roleDefinition
            this.DEFINITION_ELEMENT_DATA = this.roleDefinition
            this.dataSource = new MatTableDataSource(this.DEFINITION_ELEMENT_DATA)
          }
        }
      },
      error: err => {
        this.loggerService.error('Failed to get RoleCapabilties by Capability Name', err)
        this.notificationsService.flashNotification('danger', GET_ROLE_CAPABILITY_ERROR)
      },
      complete: () => {
        this.dataSource.paginator = this.paginatorRoles
        this.pageInfo.itemCount = this.ROLECAP_BY_ROLE_ELEMENT_DATA.length
        this.processing = false;
      }
    })
  }

  /** Load role capabilities data */
  loadRoleCapsByCap(capabilityName) {
    this.focus('capabilities')
    this.processing = true;
    const queryString = {}
    queryString['capabilityName'] = capabilityName
    this.roleCapabilityService.getRoleCapabilities(querystring.stringify(queryString)).subscribe({
      next: response => {
        if (response && !response.body) {
          this.capabilityDefinition = this.capabilities.filter(c => c.capabilityName === capabilityName)
          this.definitonData = this.capabilityDefinition
          this.capabilityDescription = this.capabilityDefinition[0].description
          this.DEFINITION_ELEMENT_DATA = this.capabilityDefinition
          this.dataSource = new MatTableDataSource(this.DEFINITION_ELEMENT_DATA)
        } else if (response && response.body && response.status === 204) {
          this.capabilityDefinition = this.capabilities.filter(c => c.capabilityName === capabilityName)
          this.definitonData = this.capabilityDefinition
          this.DEFINITION_ELEMENT_DATA = this.capabilityDefinition
          this.dataSource = new MatTableDataSource(this.DEFINITION_ELEMENT_DATA)
        } else if (response && response.body && response.status === 200) {
          this.capabilityName = capabilityName
          this.roleCapsData = response.body
          this.roleCapsbyCap = this.filterRoleCaps(response.body, capabilityName)
          this.capabilityDescription = this.roleCapsbyCap[0].description
          this.ROLECAP_BY_CAP_ELEMENT_DATA = this.roleCapsbyCap
          this.dataSource = new MatTableDataSource(this.ROLECAP_BY_CAP_ELEMENT_DATA)
        }
      },
      error: err => {
        this.loggerService.error('Failed to get RoleCapabilties by Capability Name', err)
        this.notificationsService.flashNotification('danger', GET_ROLE_CAPABILITY_ERROR)
      },
      complete: () => {
        this.dataSource.paginator = this.paginatorCaps
        this.pageInfo.itemCount = this.ROLECAP_BY_CAP_ELEMENT_DATA.length
        this.processing = false;
      }
    })
  }

 /**
  * Filter role caps
  * @param roleCaps roleCaps array
  * @param capabilityName capability name
  */
  filterRoleCaps(roleCaps, capabilityName) {
    let transformedCaps = []
    for (let x = 0; x < roleCaps.length; x++) {
      for (let i = 0; i < roleCaps[x].capabilities.length; i++) {
        if (roleCaps[x].capabilities[i].name === capabilityName) {
          const cap = roleCaps[x].capabilities[i]
          cap['roleName'] = roleCaps[x].roleName
          cap['friendlyName'] = roleCaps[x].friendlyName
          transformedCaps.push(cap)
        }
      }
    }
    return transformedCaps.sort(this.compareRoles)
  }

 /**
  * Filter roles
  * @param roles roles array
  */
  filterRoles(roles: RoleDefinition[]): RoleDefinition[] {
    const roleNames = roles.flatMap(item => item.roleName)
    for (let x = 0; x < roles.length; x++) {
      roles[x].roleName = roleNames[x]
    }
    return roles.sort(this.compareRoles)
  }

  /** Sort Role data */
  compareRoles(a: any, b: any) {
    if (a.roleName?.toLowerCase() < b.roleName?.toLowerCase()) return -1
    if (a.roleName?.toLowerCase() > b.roleName?.toLowerCase()) return 1
    return 0
  }

  /** Sort Capability data */
  compareCapabilities(a: any, b: any) {
    if (a.capabilityName?.toLowerCase() < b.capabilityName?.toLowerCase()) return -1;
    if (a.capabilityName?.toLowerCase() > b.capabilityName?.toLowerCase()) return 1;
    return 0;
  }

 /**
  *Clear search textbox value
  * @param event click event
  */
  clearSearch(event) {
    event.stopPropagation()
    if (event.currentTarget.classList.contains('role-clear-btn')) {
      this.roleSearchTextboxControl.patchValue('')
    } else if (event.currentTarget.classList.contains('capability-clear-btn')) {
      this.capabilitySearchTextboxControl.patchValue('')
    }
  }

  /**
   * Edit Role Capabilities Dialog
   * @param definition definition determinator
   */
  openEditRoleCapabilitiesDialog() {
    let document
    let definitionOnly
    if (this.roleCapsData.length > 0) {
      document = this.roleCapsData
      definitionOnly = false
    } else {
      document = this.definitonData
      definitionOnly = true
    }
    this.dialogRef = this.dialog.open(RolecapsRoleMappingComponent, {
      disableClose: true,
      panelClass: 'dialog-main',
      data: { roleCap: document[0], definitionOnly },
      autoFocus: false,
      restoreFocus: false
    });
    this.subscription.add(
      this.dialogRef.afterClosed().subscribe((result: RoleCapability | null) => {
        if (result) {
          this.roleCapUpdated = true
          this.loadRoleCapsByRole(result.roleName);
        }
      })
    );
  }

  /**
   * Edit Capabilities Dialog
   * @param definition definition determinator
   */
  openEditCapabilitiesDialog() {
    let document
    let definitionOnly
    if (this.roleCapsData.length > 0) {
      document = this.roleCapsData
      definitionOnly = false
    } else {
      document = this.definitonData
      definitionOnly = true
    }
    this.dialogRef = this.dialog.open(RolecapsCapMappingComponent, {
      disableClose: true,
      panelClass: 'dialog-main',
      data: {
        roleCaps: document,
        definitionOnly,
        capability: this.capabilityControl.value,
        capabilityDescription: this.capabilityDescription
      },
      autoFocus: false,
      restoreFocus: false
    });
    this.subscription.add(
      this.dialogRef.afterClosed().subscribe((result: string | null) => {
        if (result) {
          this.roleCapUpdated = true
          this.loadRoleCapsByCap(result)
        }
      })
    );
    
  }

  /**
   * To check for readonly access
   * @param flag boolean
   */
  isDisabled(flag: boolean) {
    this.disable = flag
  }

 /**
  * Table Pagination
  * @param event PageEvent
  */
  onPagination(event: PageEvent) {
    this.pageInfo.perPage = event.pageSize
  }
}
