/** MOV360-880 - to be removed at future date */
import { Component, OnInit, Input } from '@angular/core';
import { ActiveSuppliers } from 'src/app/core/models/active-suppliers.model';
import { Supplier } from 'src/app/core/models/add-supplier.model';
import { MatDialog } from '@angular/material/dialog';
import { AddSupplierComponent } from '../suppliers/add-supplier/add-supplier.component';

@Component({
  selector: 'app-supplier-info',
  templateUrl: './supplier-info.component.html',
  styleUrls: ['./supplier-info.component.scss']
})
export class SupplierInfoComponent implements OnInit {
  /**
   * Stores the supplier details
   */
  @Input() supplierDetails: any;
  @Input() isManageSupplier: boolean;

  /* Holds the supplier Name*/
  preferredName: string;

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
    this.getSuppliersInfo();
  }

  getSuppliersInfo() {
    this.preferredName = this.supplierDetails.supplierPreferredName ?
      `${this.supplierDetails.supplierLegalName}` : `${this.supplierDetails.entityName}`;
  }
  openAddModal(edit) {
    const dialogRef = this.dialog.open(AddSupplierComponent, {
      disableClose: true,
      panelClass: ['dialogMainContainer'],
      data: { edit: edit, supplierDetails: this.supplierDetails}
    });
  }

}
