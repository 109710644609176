<div class="container" fxFlex="100%" fxLayout="column" fxLayout.xs="column" fxLayoutGap="24px">

  <div fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%" fxLayout.xs="column" fxLayoutAlign="space-between"
    fxLayoutGap.xs="4px" fxLayoutGap="20px" class="contractDetails">


    <div fxFlex="30%" fxLayout="column" fxLayout.xs="column" fxFlex.xs="100%" fxLayoutAlign="center center"
      fxLayoutAlign.xs="center center" class="contractStatusDiv" *ngIf="isMobileView">

      <div fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%" fxLayoutAlign="space-between" fxLayoutGap.xs="4px"
        fxLayoutGap="20px" *ngIf="!isActive" class="onlyCon">

        <div fxFlex="100%" fxLayout="column" fxLayoutAlign="start center">
          <span class="contract-status-title">Current Contract Status:</span>
          <span class="contract-status">{{contractState}}</span>
        </div>

      </div>

      <div fxFlex="100%" fxLayout="row" fxFlex.xs="100%" fxLayout.xs="row" fxLayoutAlign="space-between"
        fxLayoutGap.xs="4px" fxLayoutGap="20px" *ngIf="isActive" class="init" style="margin-bottom: 15px;">

        <div fxFlex="50%" fxFlex.xs="50%" fxLayoutAlign="start center" class="currCon">
          <span class="contract-status-title" style="margin-bottom: 7px;">Current Contract Status:</span>
          <span class="contract-status">{{contractState}}</span>
        </div>

        <div fxFlex="50%" fxFlex.xs="50%" fxLayoutAlign="start" class="savedBy">
          <span class="contract-status-title namestyle">
            <p style="font-size: 12px;">By: &nbsp;</p>
            <p><a class="shortName" aria-hidden="true" (click)="userContactInfo($event)">{{userInitials()}}</a></p>
            <p style="font-size: 16px;">&nbsp;{{firstName}}</p>
          </span>
          <span class="contract-status-title namestyle">
            <p style="font-size: 12px;">On: &nbsp;</p>
            <p style="font-size: 16px;">{{date}}</p>
          </span>
        </div>

      </div>

      <div *ngIf="!isDeactive">
        <button mat-button [disabled]="canSubmit()"  [ngClass]="{'mat-button contained-button contract-statusBtn':!isActive,'mat-button outlined-button contract-statusBtn':isActive}"  id="Authorize"
          (click)='sendStatus(ContractButtonText)' >
          {{ContractButtonText}}
        </button>        
      </div>

    </div>

    <div fxFlex="36%" fxLayoutAlign="start" fxLayout="column" fxLayout.xs="column" fxLayoutGap="4px" fxFlex.xs="100%">
      <div fxFlex="100%" fxLayout="row" fxLayout.xs="column">
        <div fxFlex="40%" fxLayout="row" class="contract-section-div">
          <mat-form-field class="contract-mat-form">
            <mat-label>Contract Service Type</mat-label>
            <input matInput type="text" readonly class="contract-input-fields"
              [value]="contractData.contract.contractServiceType" />
            <button *ngIf="isManageSupplier" mat-button matSuffix mat-icon-button aria-label="EditContractDetails" (click)="openEditContract(event)" class="pointer">
              <mat-icon class="pointer">edit</mat-icon>
            </button>

          </mat-form-field>
        </div>
        <div fxFlex="60%" fxLayout="row" fxLayoutGap="2px">
          <div fxFlex="46%" fxLayout="row" class="contract-section-div">
            <mat-form-field class="contract-mat-form">
              <mat-label>Contract Dates</mat-label>
              <input matInput type="text" readonly class="contract-input-fields"
                [value]="contractStartDate?contractStartDate:'N/A'" />
              <button *ngIf="isManageSupplier" mat-button matSuffix mat-icon-button aria-label="EditContractDetails" (click)="openEditContract(event)"
                class="pointer">
                <mat-icon class="pointer">edit</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div fxFlex="5%" fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
            <span>-</span>
          </div>
          <div fxFlex="46%" fxLayout="row" class="contract-section-div">
            <mat-form-field class="contract-mat-form">
              <input matInput type="text" readonly class="contract-input-fields"
                [value]="contractEndDate?contractEndDate:''" />
              <button *ngIf="isManageSupplier" mat-button matSuffix mat-icon-button aria-label="EditContractDetails" (click)="openEditContract(event)"
                class="pointer">
                <mat-icon class="pointer">edit</mat-icon>
              </button>
            </mat-form-field>
          </div>

        </div>
      </div>
      <div fxFlex="100%" fxLayout="row" class="contract-section-div">
        <mat-form-field class="contract-mat-form notificationText">
          <mat-label>Notification Email (optional)</mat-label>
          <input matInput type="text" readonly class="contract-input-fields"
            [value]="contractData.contract.notificationEmail?contractData.contract.notificationEmail:'N/A'" />
          <button *ngIf="isManageSupplier" mat-button matSuffix mat-icon-button aria-label="EditContractDetails" (click)="openEditContract(event)" class="pointer">
            <mat-icon class="pointer">edit</mat-icon>
          </button>
        </mat-form-field>
      </div>

    </div>
    <div fxFlex="24%">
      <div fxFlex="100%" fxLayout="column" class="contract-section-div">
        <mat-form-field fxFlex class="contract-mat-form affiliateText">
          <mat-label>Affiliation Relationship</mat-label>
          <input matInput type="text" readonly class="contract-input-fields" placeholder="Affliatiation Relationship"
            [value]="contractData.contract.affiliationRelationShip" />
          <button *ngIf="isManageSupplier" mat-button matSuffix mat-icon-button aria-label="EditContractDetails" (click)="openEditContract(event)" class="pointer">
            <mat-icon class="pointer">edit</mat-icon>
          </button>
        </mat-form-field>
        <div fxLayout="row" fxLayoutGap="4px" *ngIf="contractData.contract.affiliationRelationShip === 'Affiliate' ">
          <div fxFlex="50%" class="contract-section-div">
            <mat-form-field class="contract-mat-form">
              <mat-label>Affiliate of</mat-label>
              <input matInput type="text" readonly class="contract-input-fields" placeholder="Affliate of"
                [value]="contractData.contract.affiliateOf" />
              <button *ngIf="isManageSupplier" mat-button matSuffix mat-icon-button aria-label="EditContractDetails" (click)="openEditContract(event)"
                class="pointer">
                <mat-icon class="pointer">edit</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div fxFlex="50%" class="contract-section-div">
            <mat-form-field class="contract-mat-form">
              <mat-label>Affiliate Id</mat-label>
              <input matInput type="text" readonly class="contract-input-fields" placeholder="Affiliate ID"
                [value]="contractData.contract.affiliateId" />
              <button *ngIf="isManageSupplier" mat-button matSuffix mat-icon-button aria-label="EditContractDetails" (click)="openEditContract(event)"
                class="pointer">
                <mat-icon class="pointer">edit</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <div fxFlex="40%" fxLayout="column" fxLayout.xs="column" fxFlex.xs="100%" fxLayoutAlign="center center"
      fxLayoutAlign.xs="center center" class="contractStatusDiv" *ngIf="!isMobileView">
      <div fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%" fxLayoutAlign="space-between" fxLayoutGap.xs="4px"
        fxLayoutGap="20px" *ngIf="!isActive" style="margin-bottom: 15px;">

        <div fxFlex="100%" fxLayout="column" fxLayoutAlign="start center">
          <span class="contract-status-title">Current Contract Status:</span>
          <span class="contract-status">{{contractState}}</span>
        </div>

      </div>

      <div fxFlex="100%" fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%" fxLayout.xs="column"
        fxLayoutAlign="space-between" fxLayoutGap.xs="4px" fxLayoutGap="20px" *ngIf="isActive" class="init"
        style="margin-bottom: 5px;">

        <div fxFlex="50%" fxLayout="column" fxLayoutAlign="start center">
          <span class="contract-status-title" style="margin-bottom: 7px;">Current Contract Status:</span>
          <span class="contract-status">{{contractState}}</span>
        </div>

        <div fxFlex="50%" fxLayout="column" fxLayoutAlign="start" style="background: #F7F7F7; padding-left: 15px;">
          <span class="contract-status-title namestyle">
            <p style="font-size: 12px;">By: &nbsp;</p>
            <p><a class="shortName" aria-hidden="true" (click)="userContactInfo($event)">{{userInitials()}}</a></p>
            <p style="font-size: 16px;">&nbsp;{{firstName}}</p>
          </span>
          <span class="contract-status-title namestyle">
            <p style="font-size: 12px;">On: &nbsp;</p>
            <p style="font-size: 16px;">{{date}}</p>
          </span>
        </div>

      </div>

      <div *ngIf="!isDeactive">

        <div [ngClass]="{'tooltip': canSubmit()}">
          <button mat-button [disabled]="canSubmit()" *ngIf="!isActive" class="mat-button contained-button"
            id="Authorize" (click)='sendStatus(ContractButtonText)' style="width: 255px; height: 40px;">
            {{ContractButtonText}}
          </button>
          <span class="tooltiptext" *ngIf="canSubmit()">{{toolTipText}}</span>
        </div>
        <button mat-button [disabled]="canSubmit()" *ngIf="isActive" class="mat-button outlined-button" id="Authorize"
          (click)='sendStatus(ContractButtonText)' style="width: 255px; height: 40px;">
          {{ContractButtonText}}
        </button>
      </div>

    </div>
  </div>

  <mat-accordion class="candidate-review-panel candidate-info-panel" multi="true">
    <!--supplier Details starts-->
    <mat-expansion-panel #dailyOperation class="candidate-detail" [expanded]="true">
      <mat-expansion-panel-header [collapsedHeight]="matExpansionHeaderHeight"
        [expandedHeight]="matExpansionHeaderHeight" class="panelHeader">

        <div class="headdiv" fxLayout="row">
          <div fxFlex="50%" fxLayoutAlign="start">
            <span class="panel-heading head">Daily Operation Contact</span>
          </div>
          <div fxFlex="50%" fxLayoutAlign="end" *ngIf="!dailyOperation.expanded && contractData.dailyOperationContract">
            <span class="dailyOperationNameheading">Name:
              {{contractData.dailyOperationContract.firstName.charAt(0).toUpperCase()}}{{contractData.dailyOperationContract.firstName.slice(1)}}
              {{contractData.dailyOperationContract.lastName.charAt(0).toUpperCase()}}{{contractData.dailyOperationContract.lastName.slice(1)}}
            </span>
          </div>
        </div>
      </mat-expansion-panel-header>
      <mat-panel-description class="contractPanelDescription">
        <app-daily-operation-info class="infoSection" [dailyOperationsData]="contractData.dailyOperationContract"
          [contractIndex]="contractIndex" (hasDailyOperation)='checkDailyOperation($event)' [isManageSupplier]="isManageSupplier">
        </app-daily-operation-info>
      </mat-panel-description>
    </mat-expansion-panel>
    <mat-expansion-panel class="candidate-detail" [expanded]="true">
      <mat-expansion-panel-header [collapsedHeight]="matExpansionHeaderHeight"
        [expandedHeight]="matExpansionHeaderHeight" class="panelHeader">
        <span class="panel-heading head">Territory Coverage</span>
      </mat-expansion-panel-header>
      <mat-panel-description class="contractPanelDescription">
        <app-territory-coverage-info class="infoSection" [isManageSupplier]="isManageSupplier" [terriToryCoverages]="contractData.territoryCoverages" [contractIndex]="contractIndex">
        </app-territory-coverage-info>
      </mat-panel-description>
    </mat-expansion-panel>
    <mat-expansion-panel class="candidate-detail" [expanded]="true">
      <mat-expansion-panel-header [collapsedHeight]="matExpansionHeaderHeight"
        [expandedHeight]="matExpansionHeaderHeight" class="panelHeader">
        <span class="panel-heading head">Oracle Site IDs</span>
      </mat-expansion-panel-header>
      <mat-panel-description class="contractPanelDescription">
        <app-oracle-site-ids class="infoSection" [isManageSupplier]="isManageSupplier" [contractIndex]="contractIndex">
        </app-oracle-site-ids>
      </mat-panel-description>
    </mat-expansion-panel>
  </mat-accordion>


</div>