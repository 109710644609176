import { Component, OnDestroy, ViewEncapsulation, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SettingsComponent } from './settings/settings.component';
import { Subscription } from 'rxjs';
import { PartySharedService } from '../../../app/core/services/party-shared.service';
import { Location } from '@angular/common';
import { FeatureFlagService } from 'src/app/core/services/feature-flag.service';

/** Landing component */
@Component({
  selector: 'app-project-alpha',
  templateUrl: './project-alpha.component.html',
  styleUrls: ['./project-alpha.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [Location]
})
export class ProjectAlphaComponent implements OnInit, OnDestroy {

  /**
   * Object to hold the role capabilities payload
   */
  dataPayload: any = {
    rolecapabilities: [],
    partyroles: [],
  };

  /**
   * variable to hold the partyId
   */
  partyId: string;

  /**
   * variable to hold enableTeams boolean
   */
  enableTeams = false;

  /**
   * variable to hold enableTeams boolean
   */
  enableUserAlignment = false;

  /**
   * variable to hold featureFlag friendly names
   */
  featureFlags = ['Manage Teams', 'User Alignment']

  /**
   * Subscription prop for unsubscribing services
   */
  private readonly subscription: Subscription = new Subscription();

  @HostBinding('class') class = 'alpha-root-section';
  /**
   * Injecting dependencies
   * @param router - To route the page
   * @param dialog - Dialog
   * @param iconRegistry - To display the SVG as maticon
   * @param sanitizer - To import the maticons
   */
  constructor(
    private readonly router: Router,
    public dialog: MatDialog,
    private loc: Location,
    private readonly partySharedSvc: PartySharedService,
    private readonly featureFlagSvc: FeatureFlagService,
  ) { }

  async ngOnInit() {
    if (this.isIE() === null) {
      await this.getFeatureFlags(this.featureFlags).then(() => {
        this.navigateByCap()
      })
      
    } else {
      this.router.navigate(['/not-supported']) // redirect to non-supported url.
    }
  }
  /**
   * To open a modal for setting component
   */
  openModal() {
    const dialogRef = this.dialog.open(SettingsComponent);
    this.subscription.add(dialogRef.afterClosed().subscribe(result => {
    }));
  }
  /**
   * scroll top on every router click
   */
  onActivate() {
    document.querySelector('.main-content').scrollTop = 0;
  }
  /**
   * * destroys the object
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * To get the feature flags
   * @param {Array} flagNames flag names
   */
  async getFeatureFlags(flagNames: string[]) {
    for (let f of flagNames) {
      await this.featureFlagSvc.getFeatureFlag(f).then(resp => {
        switch (f) {
          case 'User Alignment':
            this.enableUserAlignment = resp
            break
          case 'Manage Teams':
            this.enableTeams = resp
          default:
            break
        }
      })
    }
  }

  /**
   * * navigate to the correct component based on capablities
   */
  async navigateByCap() {
    await this.getRolesAndCapabilities().then(() => {
      const capabilities = this.dataPayload.rolecapabilities;
      console.log('this.enableTeams', this.enableTeams)
      if (capabilities.length > 0) {
        if (this.router.url === '/project-alpha') {
          if (capabilities.find(cap => cap.name === 'MOB - Call Summarizer')) {
            this.router.navigate([`/project-alpha/callsummarizer`]);
          } else if (capabilities.findIndex(cap => cap.name === 'Assign Employee Roles') >= 0) {
            this.router.navigate([`/project-alpha/cartus-employee`]);
          } else if (capabilities.findIndex(cap => cap.name === 'Employee to Client Association') >= 0) {
            this.router.navigate([`/project-alpha/account-manager`]);
          } else if (capabilities.findIndex(cap => cap.name === 'Manage Teams') >= 0 && this.enableTeams) {
            this.router.navigate([`/project-alpha/teams`]);
          } else if (capabilities.findIndex(cap => cap.name === 'Role Capability Maintenance') >= 0) {
            this.router.navigate([`/project-alpha/rolecaps`]);
          } else if (capabilities.findIndex(cap => cap.name === 'Manage User Alignment') >= 0 && this.enableUserAlignment) {
            this.router.navigate([`/project-alpha/user-alignment`]);
          } else {
            this.router.navigate(['/contact-us']);
          }
        }
      } else {
        this.router.navigate(['/contact-us']);
      }
    });
  }

  /** Check if the browser is IE */
  isIE(): any {
    return window.navigator.userAgent.match(/(MSIE|Trident)/);
  }

  /**
   * * wrapper call to the getRoleCapabilities function
   */
  async getRolesAndCapabilities() {
    await this.partySharedSvc.getPartyId().then(id => {
      this.partyId = id;
      this.partySharedSvc.getRoleCapabilities(id).then(response => {
        if (!!response && !!response.roleCapabilities && !!response.partyRoles) {
          this.dataPayload = {
            rolecapabilities: response.roleCapabilities,
            partyroles: response.partyRoles
          };
        }
      });
    });
  }
}
