import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, HostBinding, ChangeDetectorRef  } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { TeamsService } from '../../services/teams.service';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { SelectedEmployee, Team, UpdateTeam, Person } from '../../models/teams.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateAdapterService } from '../../services/date-adapter.service';
import { APP_DATE_FORMATS } from '../../../core/models/constants';
import { Subscription, BehaviorSubject } from 'rxjs';
import { teamProfileTitle, saveTeamProfileTitle, apiErrorMessage,
  apiSuccessMessage, teamProfileFormErrors, apiTeamRoleNotFound, apiTeamAlreadyExists } from '../../models/constants';
import { ConfirmDialogComponent } from 'src/app/core/components/shared/confirm-dialog/confirm-dialog.component';
import { TeamEmployeeSearchComponent } from '../team-employee-search/team-employee-search.component';

@Component({
  selector: 'app-team-profile',
  templateUrl: './team-profile.component.html',
  styleUrls: ['./team-profile.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: DateAdapterService },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS
    }
  ]
})

export class TeamProfileComponent implements OnInit, OnChanges {

  /** Component css class */
  @HostBinding('class') class = 'team-profile';

  /** Selected Team */
  @Input() team: Team;

  /** Team changed emitter */
  @Output() teamChange = new EventEmitter<{updated: boolean, team: Team}>();

  /** Has the profile form changed */
  @Output() profileFormChange = new EventEmitter<boolean>();

  /** To check for readonly access */
  disable = false;

  /** Subscription property for subscribing services */
  private readonly subscription: Subscription = new Subscription();

  /** Form group name */
  teamProfileForm: UntypedFormGroup;

  /** Team status options */
  teamStatuses: string[] = [];

  /** Title */
  title = teamProfileTitle;

  /** Dialog Refs */
  employeeSearchDialogRef: MatDialogRef<TeamEmployeeSearchComponent>;
  teamConfirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

  /** Save Team Profile Confirm Lines */
  saveTeamProfileLines = [
    'Are you sure you wish to save your changes to Team $TeamName?'
  ];
  /** saveTeamProfileLines subject to emit changes to saveTeamProfileLines */
  saveTeamProfileLines$ = new BehaviorSubject<any>(this.saveTeamProfileLines);
  /** Has the form changed */
  formChange = false;
  /** form controls changed state */
  formState = [];

  /** Injecting dependencies */
  constructor(
    private readonly teamsService: TeamsService,
    private readonly notificationsService: NotificationsService,
    private readonly loggerService: LoggerService,
    public dialog: MatDialog,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly spinner: NgxSpinnerService,
    public snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef
  ) {
    this.teamProfileForm = this.formBuilder.group({
      name: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(30),
          Validators.pattern('^[a-z A-Z0-9-`]*$')
        ])
      ],
      owner: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      ownerId: [''],
      status: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      effectiveDate: [''],
      expirationDate: [''],
    });
    Object.keys(this.teamProfileForm.controls).forEach(key => {
      const controlStatus = {
        name: key,
        change: false
      };
      this.formState.push(controlStatus);
      this.teamProfileForm.get(key).valueChanges.subscribe(selectedValue => {
        this.detectFormChanges(key, selectedValue);
      });
   });
    notificationsService.autoHide = 10000;
  }

  /** Listener function that the stores each control's changed state */
  detectFormChanges (controlName: string, valueChange: any)  {
    let formDate: string;
    let teamDate: string;
    if (valueChange) {
      const index = this.formState.findIndex((obj => obj.name === controlName));
      switch (controlName) {
        case 'name':
        case 'status':
          if (valueChange !== this.team[controlName]) {
            this.formState[index].change = true;
            this.formChange = true;
           } else {
            this.formState[index].change = false;
           }
          break;
        case 'owner':
          const ownerName = this.team[controlName] ? this.team[controlName]['name'] : null;
          if (valueChange !== ownerName) {
            this.formState[index].change = true;
            this.formChange = true;
          } else {
            this.formState[index].change = false;
          }
        break;
        case 'effectiveDate':
        case 'expirationDate':
            formDate = new Date(valueChange).toDateString();
            teamDate = this.team[controlName] ? new Date(this.team[controlName]).toDateString() : null;
            if (formDate !== teamDate) {
              this.formState[index].change = true;
              this.formChange = true;
              } else {
              this.formState[index].change = false;
              }
            break;
        default:
           break;
      }
    }
    const foundChange = this.formState.find(c => c.change === true);
    this.formChange = !!foundChange;
  }

  /** Init */
  ngOnInit() {
    this.teamProfileForm.valueChanges.subscribe(() => {
      this.profileFormChange.emit(this.formChange);
    });
    this.teamsService.getStatuses().subscribe({
      next: statuses => {
        this.teamStatuses = statuses;
        if (statuses && statuses.length > 0) {
          this.teamProfileForm.controls.status.setValidators(this.validateSelect(this.teamStatuses));
          this.setValues();
        } else {
          this.notificationsService.flashNotification('danger', apiErrorMessage);
        }
      },
      error: err => {
        this.loggerService.error('Failed to get team statuses', err);
        this.notificationsService.flashNotification('danger', apiErrorMessage);
    }});
  }

  /** Populate form */
  setValues() {
    this.teamProfileForm.setValue({
      name: this.team.name || '',
      owner: this.team.owner ? (this.team.owner as Person).name : '',
      ownerId: this.team.owner ? (this.team.owner as Person).id  : '',
      status: this.team.status || '',
      effectiveDate: this.team.effectiveDate || '',
      expirationDate: this.team.expirationDate || ''
    });
    this.formChange = false;
  }

  /** Custom error messages */
  getErrorMessage(fieldName) {
    if (fieldName === 'NAME') {
      return this.teamProfileForm.get('name').hasError('required')
      ? teamProfileFormErrors.name.required
      : this.teamProfileForm.get('name').hasError('pattern')
        ? teamProfileFormErrors.name.pattern
        : this.teamProfileForm.get('name').hasError('minlength')
          ? teamProfileFormErrors.name.minlength
          : this.teamProfileForm.get('name').hasError('maxlength')
            ? teamProfileFormErrors.name.maxLength
            : '';
    }
    if (fieldName === 'OWNER') {
      return this.teamProfileForm.get('owner').hasError('required')
        ? teamProfileFormErrors.owner.required
        : '';
    }
    if (fieldName === 'STATUS') {
      return this.teamProfileForm.get('status').hasError('required') || this.teamProfileForm.get('status').hasError('match')
        ? teamProfileFormErrors.status.required
        : '';
    }
    if (fieldName === 'EFFECTIVE_DATE') {
      return this.teamProfileForm.get('effectiveDate').hasError('matDatepickerParse')
        ? teamProfileFormErrors.effectiveDate.matDatepickerParse
        : '';
    }
    if (fieldName === 'EXPIRATION_DATE') {
      return this.teamProfileForm.get('expirationDate').hasError('matDatepickerParse')
        ? teamProfileFormErrors.expirationDate.matDatepickerParse
        : '';
    }
  }

  /** Validate Team Status was chosen from select */
  validateSelect(arr: any[]): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (this.teamProfileForm.controls.owner.dirty) {
        const value = c.value;
        const selected = arr.filter(item => item === value);
        return selected.length > 0 ? null : { match: true };
      } else {
        return null;
      }
    };
  }
  /** Resets the change key back to false in the formState object for each control */
  resetFormState() {
    const found = this.formState.map((item, index) => {
      if (item.change === true) {
        return index;
      }
    }).filter(item => item >= 0);
    if (found.length > 0) {
      found.forEach(element => this.formState[element].change = false);
    }
    this.formChange = false;
  }

  cancel() {
    this.setValues();
    this.teamProfileForm.markAsPristine();
    this.teamProfileForm.updateValueAndValidity();
    this.resetFormState();
  }

  /** Update dates when status changes */
  statusChange(event: MatSelectChange) {
    if (event.value === 'Active' && !this.teamProfileForm.controls.effectiveDate.value) {
      this.teamProfileForm.controls.effectiveDate.setValue(new Date());
    }
    if (event.value === 'Inactive' && !this.teamProfileForm.controls.expirationDate.value) {
      this.teamProfileForm.controls.expirationDate.setValue(new Date());
    }
  }

  /** Employee Search */
  openEmployeeSearchDialog(): void {
    this.employeeSearchDialogRef = this.dialog.open(TeamEmployeeSearchComponent, {
      disableClose: true,
      panelClass: 'dialogMainContainer',
      data: { multiSelect: false, team : this.team},
      autoFocus: false
    });
    this.subscription.add(
      this.employeeSearchDialogRef.afterClosed().subscribe((employee: SelectedEmployee) => {
        if (employee) {
            this.teamProfileForm.controls.owner.setValue(employee.name);
            this.teamProfileForm.controls.ownerId.setValue(employee.id);
            this.teamProfileForm.markAsDirty();
        }
      })
    );
  }

  /** Save Team Profile confirm */
  openSaveTeamProfileConfirmDialog(): void {
    const teamName = this.teamProfileForm.controls.name.value;
    this.saveTeamProfileLines$.next(this.saveTeamProfileLines.map(x => x.replace('$TeamName', teamName)));
    const dialogTitle = saveTeamProfileTitle;
    const dialogLines = this.saveTeamProfileLines$.getValue();
    this.teamConfirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      panelClass: 'dialogMainContainer',
      data: { dialogTitle, dialogLines },
      autoFocus: false
    });
    this.subscription.add(
      this.teamConfirmDialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          const updateTeam: UpdateTeam = {
            name: this.teamProfileForm.controls.name.value,
            owner: this.teamProfileForm.controls.ownerId.value,
            status: this.teamProfileForm.controls.status.value,
          };
          if (this.team['roles'] && this.team['roles'][0] && this.team['roles'][0]._id) {
            // role is needed to be passed when the status changes
            updateTeam.teamRole = this.team['roles'][0]._id;
          }
          // May not need the check for roles following IL fix to always return populated role array
          if (this.teamProfileForm.controls.effectiveDate.value &&
            this.teamProfileForm.controls.effectiveDate.value !== this.team.effectiveDate) {
            updateTeam['effectiveDate'] = new Date(this.teamProfileForm.controls.effectiveDate.value);
          }
          if (this.teamProfileForm.controls.expirationDate.value &&
            this.teamProfileForm.controls.expirationDate.value !== this.team.expirationDate)  {
            updateTeam['expirationDate'] = new Date(this.teamProfileForm.controls.expirationDate.value);
          }
          this.spinner.show();
          this.teamsService.updateTeam(this.team.id, updateTeam).subscribe({
            next: response => {
              if (response && response.status === 200) {
                const team: Team = response.body;
                this.notificationsService.flashNotification('success', apiSuccessMessage);
                this.teamChange.emit({ updated: true, team: team });
                this.teamProfileForm.reset();
                this.setValues();
              } else {
                this.notificationsService.flashNotification('danger', this.processError(response));
              }
            },
            error:  err => {
              this.loggerService.error('Failed to update team', err);
              this.notificationsService.flashNotification('danger', apiErrorMessage);
            },
            complete: () => {
              this.spinner.hide();
            }
          });
        }
      })
    );
  }

  /** Translate errors into user friendly messages */
  processError(err: any): string {
    if (err.toString().indexOf('"teamRole" is required') > -1) {
      return apiTeamRoleNotFound;
    }
    if (err.toString().indexOf('A team already exists') > -1) {
      return apiTeamAlreadyExists.replace('$TeamName', this.teamProfileForm.controls.name.value);
    }
    return apiErrorMessage;
  }

  /** Update component following team update */
  ngOnChanges(changes: SimpleChanges) {
    if ('team' in changes) {
      this.teamProfileForm.reset();
      this.setValues();
      this.cdr.detectChanges();
    }
  }
  maxEffectiveDateFilter () {
    if (this.teamProfileForm && this.teamProfileForm.get('expirationDate') && this.teamProfileForm.get('status').value !== 'Inactive') {
      const expirationDate = new Date(this.teamProfileForm.get('expirationDate').value);
      expirationDate.setDate(expirationDate.getDate() - 1);
      return expirationDate;
    }
    return '';
  }

  minExpirationDateFilter() {
    if (this.teamProfileForm && this.teamProfileForm.get('effectiveDate') && this.teamProfileForm.get('status').value !== 'Inactive') {
      const effectiveDate = new Date(this.teamProfileForm.get('effectiveDate').value);
      effectiveDate.setDate(effectiveDate.getDate() + 1);
      return effectiveDate;
    }
   return '';
  }

  /** To check for readonly access */
  isDisabled(flag) {
    this.disable = flag;
    if (flag === true) {
      this.teamProfileForm.disable();
    }
  }

}
