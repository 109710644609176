<p class="warning-msg" *ngIf="invoiceDetails && invoiceDetails.length > 0">
  Downloading files with sensitive information is a security risk, please clear your cache afterwards
</p>
<p *ngIf="!(invoiceDetails && invoiceDetails.length > 0)">
  No records found
</p>
<section *ngIf="invoiceDetails && invoiceDetails.length > 0" class="content_table">
  <section class="mat-elevation-z8">
    <div style="overflow:auto;">
      <table mat-table [dataSource]="dataSource" matSort>

        <!-- Billing Contact Email Column -->
        <ng-container matColumnDef="filename">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Filename</th>
          <td mat-cell *matCellDef="let element" class="cLinks">
            <a href="#" class="client-table-links" (click)="downloadInvoicePDF($event, element)">
              <span class="text-as-link" *ngIf="element.invoiceId" id="invoice"
                [innerHTML]="element.invoiceId ? element.invoiceId :''">{{element.invoiceId.fullname}}
              </span>
              <span class="text-as-link" *ngIf="element.fullName">HVG {{setFullName(element)}}
              </span>
            </a>
          </td>
        </ng-container>

        <!-- Mobile Phone Number Column -->
        <ng-container matColumnDef="fileType">
          <th mat-header-cell *matHeaderCellDef> File type</th>
          <td mat-cell *matCellDef="let element"> PDF</td>
        </ng-container>

        <!-- Billing Contact Role Column -->
        <ng-container matColumnDef="addedBy">
          <th mat-header-cell *matHeaderCellDef> Added By</th>
          <td mat-cell *matCellDef="let element"> System</td>
        </ng-container>

        <!-- Status Date Column -->
        <ng-container matColumnDef="dateAdded">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Added</th>
          <td mat-cell *matCellDef="let element">
            <a class="invcdates">
              <span *ngIf="element.invoiceDate" class="text-as-link invcdate">
                {{element.invoiceDate | date:'yyyy-MM-dd'}}
              </span>
              <span *ngIf="element.submittedOn" class="text-as-link invcdate">
                {{invoiceDate | date:'yyyy-MM-dd'}}
              </span>
            </a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </section>
</section>
