/** MOV360-880 - to be removed at future date */
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { APIResponse } from '../models/response.model';
import { LoggerService } from './logger.service';
import { urlType } from '../models/urlType';
import { Supplier } from '../models/add-supplier.model';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  constructor(private readonly baseClientService: BaseClientService,
    private readonly logSvc: RemoteLoggingService,
    private readonly customLogger: LoggerService) { }

    createSupplier(request: Supplier): Observable<any> {
      return this.baseClientService
      .post<any>('/supplier', request)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty: any = null;
          this.customLogger.error('Failed to Add the Supplier', err);
          return of(empty);
        })

      );
    }


}
