import { UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import * as moment from 'moment';

export class DateValidator extends Validators {
    static dateComparison(formGroup: UntypedFormGroup)  {
        const fromDateControl = formGroup.controls['startDate'];
        const toDateControl = formGroup.controls['endDate'];
        if (fromDateControl.dirty || toDateControl.dirty) {

            if (fromDateControl.touched && (!fromDateControl.value || fromDateControl.value === null) && toDateControl.value) {
                fromDateControl.setErrors({ requiredFromDate: true });
                return { requiredFromDate: true };
                // return true;
            } else {
                fromDateControl.setErrors(null);
            }

            if (toDateControl.touched && (!toDateControl.value || toDateControl.value === null) && fromDateControl.value) {
                toDateControl.setErrors({ requiredToDate: true });
                return { requiredToDate: true };
                // return true;
            } else if (fromDateControl.value && toDateControl.value && toDateControl.value < fromDateControl.value) {
                toDateControl.setErrors({ validateToDate: true });
                return { validateToDate: true };
                // return true;
            } else {
                toDateControl.setErrors(null);
            }
        }
        return null;
    }
}

export function dateRangeValidator(control: AbstractControl): {[key: string]: any} {
    const startDate = control.get('start').value;
    const endDate = control.get('end').value;

    if (!endDate && startDate) {
        control.get('end').setErrors({ 'startNoEnd': true });
        control.get('end').markAsTouched();
        return {'startNoEnd': true};
    } else if (!startDate && endDate) {
        control.get('start').setErrors({ 'endNoStart': true });
        control.get('start').markAsTouched();
        return {'endNoStart': true};
    } else if (moment(endDate).diff(moment(startDate)) < 0 ) {
        control.get('end').setErrors({ 'dateValidator': true });
        control.get('end').markAsTouched();
        return { 'dateValidator': true };
    }
    control.get('end').setErrors(null);
    control.get('start').setErrors(null);
    control.setErrors(null);
    return null;
}

export function EnddateRangeValidator(control: AbstractControl): {[key: string]: any} {
    const startDate = control.get('start').value;
    const endDate = control.get('end').value;
    if (!startDate && endDate) {
        control.get('start').setErrors({ 'startDateRequired': true });
        control.get('start').markAsTouched();
        return {'startDateRequired': true};
    } else if (endDate && startDate) {
        if (moment(endDate).diff(moment(startDate)) < 0 ) {
            control.get('end').setErrors({ 'dateValidator': true });
            control.get('end').markAsTouched();
            return { 'dateValidator': true };
        }
    }
    control.get('end').setErrors(null);

    control.setErrors(null);
    return null;
}
