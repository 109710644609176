/** MOV360-880 - to be removed at future date */
import { Injectable } from '@angular/core';
import { BaseClientService } from './base-client.service';
import { ClientContact } from '../models/client-contact';
import { Client } from '../models/client';
import { formatDate } from '@angular/common';
import { RemoteLoggingService } from './remote-logging.service';
import { of, Observable, BehaviorSubject, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { roleType } from '../models/client-role';
import { APIResponse } from '../models/response.model';
import { CdkAccordion } from '@angular/cdk/accordion';
import { LoggerService } from './logger.service';
import { clientContacts } from 'src/UnitTest-Support/Mocks/data.mock';
import { SupplierInvoice } from '../models/supplier-invoice';

@Injectable({
  providedIn: 'root'
})
export class SupplierInvoiceService {

  constructor(private readonly baseClientService: BaseClientService,
    private readonly logSvc: RemoteLoggingService,
    private readonly customLogger: LoggerService) { }

  createSupplierInvoice(request: SupplierInvoice): Observable<APIResponse> {
    return this.baseClientService.post<APIResponse>(`/candidate/createSupplierInvoice/${request['orderId']}`, request).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: APIResponse = null;
        this.logSvc.logger('', 'Failed to add candidate details', err);
        return of(err);
      })
    );
  }

  saveSupplierInvoiceDraft(request: SupplierInvoice): Observable<APIResponse> {
    return this.baseClientService.post<APIResponse>(`/candidate/saveSupplierInvoice/${request['orderId']}`, request).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: APIResponse = null;
        this.logSvc.logger('', 'Failed to add candidate details', err);
        return of(err);
      })
    );
  }

  updateSupplier(request: SupplierInvoice): Observable<APIResponse> {
    return this.baseClientService.put<APIResponse>(`/createSupplierInvoice/${request['orderId']}`, request).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const emptyMsg: APIResponse = null;
        this.logSvc.logger('', 'Failed to update client details', err);
        return of(err);
      })
    );
  }

}
