<ngx-spinner type="ball-spin-clockwise-fade" size="medium">
</ngx-spinner>
<div class="content-wrapper-container">
  <div class="middle-section">
    <section>
      <!-- <h2> {{ this.ELEMENT_DATA.length }} Clients</h2> -->
      <app-header-status (clientEvent)="updateDataSource($event)"></app-header-status>
    </section>

    <section class="search_table">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <span fxFlex="60%" fxLayoutAlign="start">
            <mat-form-field appearance="outline" class="search-within-table search-box">
              <mat-icon matPrefix>search</mat-icon>
              <input matInput id="searchInput" (keyup)="applyFilter($event.target.value)" placeholder="Search within table for..." width="80%" fxFlex.sm="65">
            </mat-form-field>
            <span fxLayoutAlign="start center" >
              <a id="selectColumns" (click)="openModal()">
                <mat-icon matTooltip="Select columns" matTooltipPosition="right" class="viewsetting-icon">view_column</mat-icon>
              </a>
            </span>
        </span>
        <!-- <span fxFlex="12.5em" fxLayoutAlign="end" fxFlex.xs="10.5em">
          <span fxFlex="12.5em" fxLayoutAlign="end" fxFlex.xs="10.5em">
            <button id="cstMdlBtn" mat-raised-button (click)="openDialog()">Add Client</button>
          </span>
        </span> -->
      </div>
    </section>  
    <p></p>
    <section class="content_table">
      <section class="mat-elevation-z8">
        <div style="overflow:auto;">
          <table mat-table [dataSource]="dataSource" matSort>
            <!-- Select Checkbox Column -->
            <ng-container matColumnDef="select" sticky>
              <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox (change)="$event && masterToggle()"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                  [aria-label]="checkboxLabel()">
                  </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row" >
                  <mat-checkbox (click)="$event.stopPropagation()"
                  (change)="$event && selection.toggle(row)"
                  [checked]="selection.isSelected(row)"
                  [aria-label]="checkboxLabel(row)" >
                  </mat-checkbox>
              </td>
            </ng-container>

            <!-- Client Name Column -->
            <ng-container matColumnDef="clientPreferredName" sticky>
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Client Name </th>
                <td mat-cell *matCellDef="let element" class="cLinks" (click)="open($event, element)">
                  <span class="text-as-link" [innerHTML]="element.clientPreferredName | highlightSearch: filterText">{{element.clientPreferredName}}</span> 
                  <br>
                  <span class="text-as-link subtitle">Client#: 
                    <span [innerHTML]="element.clientID | highlightSearch: filterText"> {{element.clientID}}</span>
                  </span>
                </td>
            </ng-container>

            <!-- Client Location Column -->
            <ng-container matColumnDef="clientAddress.state">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="client location"> Client Location </th>
                <td mat-cell *matCellDef="let element">
                  <span [innerHTML]="element.clientAddress.city | highlightSearch: filterText">{{element.clientAddress.city}}</span>,
                  <span [innerHTML]="element.clientAddress.state | highlightSearch: filterText">{{element.clientAddress.state}}</span>
                </td>
              </ng-container>

            <!-- Client ID Column -->
            <ng-container matColumnDef="clientID">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Client Number </th>
              <td mat-cell *matCellDef="let element" [innerHTML]="element.clientID | highlightSearch: filterText"> {{element.clientID}}</td>
            </ng-container>

            <!-- Billing Contact Name Column -->
            <ng-container matColumnDef="billingContact.lastName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="Billing contact"> Billing Contact </th>
                <td mat-cell *matCellDef="let element">
                  <span [innerHTML]="element.billingContact.lastName | highlightSearch: filterText">{{element.billingContact.lastName}}</span>, 
                  <span [innerHTML]="element.billingContact.firstName | highlightSearch: filterText">{{element.billingContact.firstName}}</span>
                </td>
            </ng-container>
      
            <!-- Billing Contact Email Column -->
            <ng-container matColumnDef="billingContact.emailAddress">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="Billing Contact Email"> Billing Contact Email</th>
                <td mat-cell *matCellDef="let element" [innerHTML]="element.billingContact.emailAddress | highlightSearch: filterText"> {{element.billingContact.emailAddress}}</td>
            </ng-container>

            <!-- TaxID Column -->
            <ng-container matColumnDef="taxID">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tax ID</th>
                <td mat-cell *matCellDef="let element" [innerHTML]="element.taxID | highlightSearch: filterText"> {{element.taxID}}</td>
            </ng-container>

            <!-- Mobile Phone Number Column -->
            <ng-container matColumnDef="billingContact.phoneNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Mobile Phone</th>
                <td mat-cell *matCellDef="let element" [innerHTML]="element.billingContact.phoneNumber | highlightSearch: filterText"> {{element.billingContact.phoneNumber}}</td>
            </ng-container>

            <!-- Billing Contact Role Column -->
            <ng-container matColumnDef="billingContact.role.roleName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="Billing Contact Role"> Billing Contact Role</th>
                <td mat-cell *matCellDef="let element" [innerHTML]="element.billingContact.clientContactType | highlightSearch: filterText"> {{element.billingContact.clientContactType}}</td>
                <!-- <td *ngIf ="element.billingContact.invitedAsClientContact" mat-cell *matCellDef="let element" [innerHTML]="`Billing Contact` | highlightSearch: filterText"> Billing Contact</td> -->
            </ng-container>
      
            <!-- Status Date Column -->
            <ng-container matColumnDef="statusDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status Date </th>
            <td mat-cell *matCellDef="let element" [innerHTML]="element.statusDate | highlightSearch: filterText"> {{element.statusDate}} </td>
            </ng-container>

            <!-- Client Legal Date Column -->
            <ng-container matColumnDef="clientLegalName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Client Legal Name </th>
                <td mat-cell *matCellDef="let element" [innerHTML]="element.clientLegalName | highlightSearch: filterText"> {{element.clientLegalName}} </td>
                </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status" stickyEnd>
            <th mat-header-cell id="" *matHeaderCellDef mat-sort-header="Client Status"> Client Status </th>
            <td mat-cell *matCellDef="let element">
              <div class="notification-important">
                <span *ngIf="element.status === 'Ready for Review'" (click)="open($event, element)">
                  <mat-icon *ngIf="element.status === 'Ready for Review'"> notification_important </mat-icon>
                  <a class="text-as-link" [innerHTML]="element.status | highlightSearch: filterText"></a>
                </span>
                <span *ngIf="element.status !== 'Ready for Review'"
                  [innerHTML]="element.status | highlightSearch: filterText">
                </span>
              </div>
              <span *ngIf="element.status === 'Ready for Review'" class="status-subtitle">
              on
              <span [innerHTML]="element.statusDate | highlightSearch: filterText"> {{element.statusDate}}</span>
              </span>
              <span *ngIf="element.status !== 'Ready for Review'" class="subtitle">
                  on
                  <span [innerHTML]="element.statusDate | highlightSearch: filterText"> {{element.statusDate}}</span>
                  </span>
            </td>
          </ng-container>

            <!-- Delete Action Column -->
            <ng-container matColumnDef="action" stickyEnd>
                <th mat-header-cell *matHeaderCellDef>
                    Delete
                </th>
                <td mat-cell *matCellDef="let row" >
                    <a id="deleteLink" (click)="deleteRow($event, row, true)">
                    <mat-icon matPrefix class="error">delete</mat-icon>
                  </a>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
          </table>
        </div>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" ></mat-paginator>
      </section>
    </section>

    <div [hidden]="ELEMENT_DATA?.length>0 && dataSource.filteredData?.length>0" class="invalidData">
      No records found
    </div>
  </div>
  <div *ngIf="selection.selected.length>0" class="candidate-highlight-whitebg">
    <div class="statusBtn">
      <button mat-button class="mat-button contained-button" id="withdrawBtn" (click)="inactivateClients(true)">
        {{this.inactivateButtonText}}<span class=" hide-xs cost-model-btn-txt"></span></button>
    </div>
  </div>
</div>
