<mat-panel-description class="clickable" tabindex="0" (click)="openHtmlTooltip(coreService.coreBenefit, $event)"
    (keydown.enter)="openHtmlTooltip(coreService.coreBenefit, $event)">
    <div class="container price-info-wrapper" fxFlex="100%" fxLayout fxLayout.xs="row" fxLayoutGap="10px">
        <div fxFlex="33%" class="service-name">{{coreService.coreBenefit.budgetName}}<span
                class="panel-subheading">Insured for up to ${{coreService.coreBenefit.minInsuredValue | number}}</span>
        </div>
        <div fxFlex="33%" class="tooltip-icon">
            <mat-icon class="material-icons info" aria-label="locked price">lock</mat-icon>
        </div>
        <div fxFlex="33%" class="price-field">{{coreService.coreBenefitsTotal | number}} {{currency}}</div>
    </div>
</mat-panel-description>