<div class="dialogMainContainer-withdraw" flexLayout fxLayout.xs="column">
    <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
        fxLayoutGap="20px" fxLayoutGap.xs="0em">
        <div class="candidate-dialogtitle">{{ title }}</div>
        <a href="#" class="close-icon" (click)="onNoClick($event)" title="close">
            <mat-icon>close</mat-icon>
        </a>
    </div>
    <mat-dialog-content class="withdraw-popup-content middle-section">
        {{ displayText1 }}
        <div *ngFor="let candidate of data.selectedCandidates">
            <div class="candidate-name-highlightbg">{{candidate.fullname}}</div>
        </div>
        {{ displayText2 }}
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="statusBtn">
            <button mat-button color="primary" class="mat-button text-button uppercase"
                (click)="onNoClick($event)">Cancel</button>
            <button mat-button class="mat-button contained-button" (click)="withdraw()" id="withdrawBtn">Withdraw
                <span *ngIf="data.noOfSelected===1" class=" cost-model-btn-txt">Candidate</span><span
                    *ngIf="data.noOfSelected>1" class=" cost-model-btn-txt">{{data.noOfSelected}}
                    Candidates</span></button>
        </div>
    </mat-dialog-actions>
</div>