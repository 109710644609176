import { OperationType } from "src/app/core/models/OperationType";
interface RoleName {
  roleName: string;
}

interface EligibleLayer {
  application: string;
  module?: string;
  component?: string;
  element?: string;
}

interface MenuDetails {
  path: string;
  label: string;
  description: string;
  icon?: string;
  sortOrder: number;
  parent?: string;
  children: string[];
}

export interface Capability {
  name: string;
  operation: 'write' | 'read' | 'delete';
  permission: 'allow' | 'deny';
  level: 'UI' | 'IL' | 'Core';
  description: string;
  eligibilityRules?: EligibleLayer[];
  linkedCapabilities?: string[];
  tags?: string[];
  menuItem?: boolean;
  menuDetails?: MenuDetails;
}

export interface RoleCapability {
  _id?: string;
  roleName: string;
  bypassAssociation?: boolean;
  friendlyName: string;
  dependencies?: RoleName[];
  capabilities?: Capability[];
  __v?: number;
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
  updatedBy?: string;
}

export interface RoleCapabilityChanges {
  _id: string;
  roleName: string
  friendlyName: string
  capability: Capability,
  operationType: OperationType
}
