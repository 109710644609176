/** MOV360-880 - to be removed at future date */
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Extension } from 'src/app/core/models/extension.model';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidationErrors, ValidatorFn, AbstractControl, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationsService } from 'src/app/core/services/notifications.service';
import { ExtensionService } from 'src/app/core/services/extension.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { phoneErrorMessage, errorMessage } from 'src/app/core/models/constants';
import { DailyOperations } from 'src/app/core/models/dailyoperations.model';
export const errorMessages: { [key: string]: string } = {
  Extension: 'You must select Extension',
  Supplier: 'You must select Supplier'
};

export const fixDialogFocus = (dialogRef: MatDialogRef<any>) => {
  /* eslint-disable */
  if (event && event.currentTarget && (<any> event.currentTarget).blur) {
    const initiator: any = event.currentTarget;
    /* eslint-enable */
    dialogRef.afterClosed().subscribe(() => {
      setTimeout(() => {
        try { initiator.blur(); } catch {}
      }, 0);
    });
  }
};

@Component({
  selector: 'app-add-edit-daily-operations',
  templateUrl: './add-edit-daily-operations.component.html',
  styleUrls: ['./add-edit-daily-operations.component.scss']
})
export class AddEditDailyOperationsComponent implements OnInit, OnDestroy {

  isEmptySupplierInfo = true;

  dailyOperationForm: UntypedFormGroup;
  selected = true;
  Supplier: any;
  matExpansionHeaderHeight: string;
  isPhoneNumberValid = false;
  isExtensionValid = false;
  isEmailValid = false;
  extensions: Extension[];
  defaultExtension: any;
  selectedExt: any;
  errors = errorMessages;

  dailyOperations: DailyOperations = {} as DailyOperations;

   /** Subscription prop for unsubscribing services */
   private readonly subscription: Subscription = new Subscription();

  /**
   * base constructor
   * @param data data received from calling component
   * @param dialogRef - property for mat dialog reference
   * @param notificationsService - snackbar service
   * @param spinner - ngx spinner service
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddEditDailyOperationsComponent>,
    private readonly notificationsService: NotificationsService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly spinner: NgxSpinnerService,
    private router: Router,
    private extnSvc: ExtensionService,
  ) { }

  /** function to be executed on init */
  ngOnInit() {
    this.isEmptySupplierInfo = this.data.isEmptySupplierInfo;
    // this.Supplier = [this.data.activeSupplier.preferredName];
    fixDialogFocus(this.dialogRef);
    this.dailyOperationForm = this.formBuilder.group({
      firstname: ['',
        Validators.compose([Validators.required, this.noWhitespaceValidator])],
      lastname: ['',
        Validators.compose([Validators.required, this.noWhitespaceValidator])],
      emailAddress: ['',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+(?:\\.[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])$'),
          this.noWhitespaceValidator
        ])],
      phoneNumber: ['',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.minLength(10),
          this.regexValidator(new RegExp(/[A-Za-z]/g), { 'alphabets': true }),
          this.regexValidator(new RegExp(/[!@#$%^&*()/\\?,.?":{}\-\+=_|<>;'`~\]\[ ]/g), { 'specialCharacter': true }),
          this.noWhitespaceValidator
        ])],
      Extension: ['', Validators.required]
    });

    this.extnSvc.extensionList$.subscribe(extension => {
      if (extension) {

        this.extensions = extension;
        this.defaultExtension = this.extensions.find(x => (x.name === 'United States' || x.name === 'Canada'));

        if (this.data.dailyOperations && this.data.dailyOperations.countryDialingCode) {

          this.dailyOperationForm.get('Extension')
            .setValue(`${this.data.dailyOperations.countryDialingCode}`);
        } else {
            if (this.defaultExtension) {
            this.dailyOperationForm.controls.Extension
              .setValue(this.defaultExtension.countryDialingCode);
          }
        }
      }
      this.extensions = extension;
    });
    if (!this.isEmptySupplierInfo) {
      this.setData();
    }
  }

  setData() {

    this.dailyOperationForm.get('firstname').setValue(this.data.dailyOperations.firstName);
    this.dailyOperationForm.get('lastname').setValue(this.data.dailyOperations.lastName);
    this.dailyOperationForm.get('emailAddress').setValue(this.data.dailyOperations.emailAddress);
    this.dailyOperationForm.get('phoneNumber').setValue(this.data.dailyOperations.phoneNumber);
  }
/**
* Closing the dialog box
*/
close(event): void {
 event.preventDefault();
 event.stopPropagation();
 this.dialogRef.close(false);
}

 /**
   * function to flash message and close dialog
   * @param response string
   */
  flashAndCloseDialog(message: string) {
    this.dialogRef.close(true);
    this.notificationsService.flashNotification(
      'success',
      message,
      true,
      'dismiss'
    );
  }

  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = control.value.match(regex);
      if (valid) {
        return error;
      } else {
        return null;
      }
    };
  }

  getErrorMessage(fieldName): string {
    const charNotAllowed = 'Special characters are not allowed';
    if (fieldName === 'FIRST_NAME') {
      return this.dailyOperationForm.get('firstname').hasError('required')
        ? 'You must enter first name'
        : this.dailyOperationForm.get('firstname').hasError('pattern')
          ? charNotAllowed
          : '';
    }
    if (fieldName === 'LAST_NAME') {
      return this.dailyOperationForm.get('lastname').hasError('required')
        ? 'You must enter last name'
        : this.dailyOperationForm.get('lastname').hasError('pattern')
          ? charNotAllowed
          : '';
    }
    if (fieldName === 'PHONE_NUMBER') {
      return this.dailyOperationForm.get('phoneNumber').hasError('required')
        ? phoneErrorMessage
        : this.dailyOperationForm.get('phoneNumber').hasError('specialCharacter')
          ? charNotAllowed
          : this.dailyOperationForm.get('phoneNumber').hasError('pattern')
            ? phoneErrorMessage
            : this.dailyOperationForm.get('phoneNumber').hasError('alphabets')
              ? phoneErrorMessage
              : this.dailyOperationForm.get('phoneNumber').hasError('minlength')
                ? phoneErrorMessage
                : '';
    }
    if (fieldName === 'EMAIL_ADDRESS') {
      return this.dailyOperationForm.get('emailAddress').hasError('required')
        ? 'You must enter email address'
        : this.dailyOperationForm.get('emailAddress').hasError('pattern')
          ? 'You must enter valid email address'
          : '';
    }
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  canSaveContact() {
    return this.dailyOperationForm.get('firstname').valid && this.dailyOperationForm.get('lastname').valid
      && this.dailyOperationForm.get('emailAddress').valid && this.dailyOperationForm.get('phoneNumber').valid;
  }

  populateDailyOperations() {
    const dateString = moment(new Date()).format('YYYY-MM-DD');
    this.dailyOperations = {
      'firstName': this.dailyOperationForm.get('firstname').value ? this.dailyOperationForm.get('firstname').value : '',
      'lastName': this.dailyOperationForm.get('lastname').value ? this.dailyOperationForm.get('lastname').value : '',
      'emailAddress': this.dailyOperationForm.get('emailAddress').value ? this.dailyOperationForm.get('emailAddress').value : '',
      'phoneNumber': this.dailyOperationForm.get('phoneNumber').value ? this.dailyOperationForm.get('phoneNumber').value : '',
      'countryDialingCode': (this.dailyOperationForm.get('Extension').value && this.dailyOperationForm.get('phoneNumber').value) ?
        this.dailyOperationForm.get('Extension').value : this.defaultExtension.countryDialingCode ?
          this.defaultExtension.countryDialingCode : '',
    };
  }

  /* on click of save*/
  save(event) {
    event.preventDefault();
    event.stopPropagation();
    this.populateDailyOperations();
    this.dialogRef.close(this.dailyOperations);
  }

  /**
   * destroys the object
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
