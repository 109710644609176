<mat-panel-description tabindex="0" class="remove-margin clickable" *ngFor="let flexData of flexibleSpendDetails.flexSpend"
(click)="openHtmlTooltip(flexData, $event)" (keydown.enter)="openHtmlTooltip(flexData, $event)">
    <div class="container price-info-wrapper" fxFlex="100%" fxLayout fxLayout.xs="row" fxLayoutGap="10px">
        <div fxFlex="33%"><span class="flexible-field-name">{{flexData.budgetName}}</span></div>
        <div fxFlex="33%" class="tooltip-icon" aria-hidden="true">
            <mat-icon class="material-icons info">info</mat-icon>
        </div>
        <div fxFlex="33%" class="price-field">{{flexData.budgetAmount | number}} {{currency}}</div>
    </div>
</mat-panel-description>
