<div class="userInfoPop" style="background: white; padding: 6px 15px;" tabindex="0" role="user contact info"
    aria-modal="true" aria-labelledby="user-contact-info" cdkTrapFocus cdkTrapFocusAutoCapture>
    <div class="popUpTitleBlk" fxLayout="row" fxLayoutAlign="start" role="contentinfo">
        <div fxFlex="85%" fxLayoutAlign="start" role="heading" class="lastSavedName">
            <a class="shortName" tabindex="0" aria-hidden="true"
                href="javascript:void(0)">{{userInitials(data.data.firstName, data.data.lastName)}}</a>
            <span
                style="margin-right: 20px; padding-top: 13px;">{{data.data.firstName}}&nbsp;{{data.data.lastName}}</span>
        </div>
        <div fxFlex="15%" fxLayoutAlign="center">
            <a class="close-icon tooltip" id="close_icon" (click)="close($event)" tabindex="0" aria-label="close"
                href="javascript:void(0)">
                <mat-icon style="margin-top: 12px; margin-left: 10px;">close</mat-icon>
                <span class="tooltiptext">close</span>
            </a>
        </div>
    </div>

    <hr />

    <div>
        <div fxLayout.xs="row">
            <div fxFlex="12%" fxLayoutAlign="start" fxLayout.xs="column" class="ics">
                <img alt="email-address"
                    src="../../../../../assets/images/candidate-assessment/baseline-email-24px.svg" />
            </div>
            <div fxFlex="88%" fxLayoutAlign="start" fxLayout.xs="column" style="align-items: center !important;">
                <span class="email" tabindex="0">{{data.data.emailAddress}}</span>
            </div>
        </div>
        <br />
        <div fxLayout.xs="row">
            <div fxFlex="12%" fxLayoutAlign="start" fxLayout.xs="column" class="ics">
                <img alt="phone-contact"
                    src="../../../../../assets/images/candidate-assessment/baseline-phone-24px.svg" />
            </div>
            <div fxFlex="88%" fxLayoutAlign="start" fxLayout.xs="column">
                <span class="phone" tabindex="0">{{data.data.phoneNumber}}</span>
            </div>
        </div>
    </div>

    <hr />

    <div fxLayout="row" fxLayoutAlign="center center" role="button">
        <button mat-button tabindex="0" class="contained-button closeButton" (click)="close($event)">Close</button>
    </div>
</div>