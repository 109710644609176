<ng-template [appReadonly]="['Role Capability Maintenance']" (valueChange)="isDisabled($event)"></ng-template>
<h2>Role & Capability Mapping</h2>
<div class="container">

  <!-- Role Selection -->
  <section id="role-selection">
    <h2 class="title">Role</h2>
    <mat-form-field appearance="outline" class="field-outline" [ngClass]="{'focused-border': focusedSection === 'roles'}">
      <mat-select [formControl]="roleControl" panelClass="select-panel" (openedChange)="openedChange($event, 'roles')"
        placeholder="Roles"  disableOptionCentering [disableRipple]="true">
        <mat-select-trigger>
          {{roleControl.value || ''}}
        </mat-select-trigger>
        <mat-optgroup>
          <mat-form-field>
            <input id="roleSearch"  appearance="outline" #roleSearch autocomplete="off" placeholder="Search" aria-label="Search" matInput
              [formControl]="roleSearchTextboxControl" (keydown)="$event.stopPropagation()">
            <button [disableRipple]="true" class='role-clear-btn' *ngIf="roleSearch.value" matSuffix mat-icon-button aria-label="Clear"
              (click)="clearSearch($event)">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </mat-optgroup>
        <mat-optgroup *ngIf="(roleFilteredOptions | async).length === 0 && loaded === true">
          <div>No results found</div>
        </mat-optgroup>
        <mat-option (onSelectionChange)="selectionChange($event, 'roles')"
          *ngFor="let option of roleFilteredOptions | async" [value]="option.roleName">
          {{option.roleName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-optgroup *ngIf="(roleFilteredOptions | async).length === 0 && loaded === true">
      <div>No results found</div>
    </mat-optgroup>
  </section>

  <!-- Capability Selection -->
  <section id="capability-selection">
    <h2 class="title">Capability</h2>
    <mat-form-field appearance="outline" class="field-outline" [ngClass]="{'focused-border': focusedSection === 'capabilities'}">
      <mat-select [formControl]="capabilityControl" panelClass="select-panel" (openedChange)="openedChange($event, 'capabilities')"
        placeholder="Capabilities" disableOptionCentering [disableRipple]="true">
        <mat-select-trigger>
          {{capabilityControl.value || ''}}
        </mat-select-trigger>
        <mat-optgroup>
          <mat-form-field>
            <input id="capabilitySearch" (keydown)="$event.stopPropagation()" appearance="outline" #capabilitySearch autocomplete="off" placeholder="Search" aria-label="Search" matInput
              [formControl]="capabilitySearchTextboxControl">
            <button [disableRipple]="true" class='capability-clear-btn' *ngIf="capabilitySearch.value" matSuffix mat-icon-button aria-label="Clear"
              (click)="clearSearch($event)">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </mat-optgroup>
        <mat-optgroup *ngIf="(capabilityFilteredOptions | async).length === 0 && loaded === true">
          <div>No results found</div>
        </mat-optgroup>
        <mat-option (onSelectionChange)="selectionChange($event, 'capabilities')"
          *ngFor="let option of capabilityFilteredOptions | async" [value]="option.capabilityName">
          {{option.capabilityName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-optgroup *ngIf="(capabilityFilteredOptions | async).length === 0 && loaded === true">
      <div>No results found</div>
    </mat-optgroup>
  </section>
</div>

<!-- Role Definition Table -->
<section *ngIf="roleDefinition && !(roleCapsbyRole.length > 0) && this.focusedSection !== 'capabilities'" class="definition">
  <div class="title-container">
    <div class="title">Role Definition</div>
    <div class="btn-wrapper">
      <button disableRipple mat-icon-button class="action-button" [disabled]="disable" (click)="openEditRoleCapabilitiesDialog()">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </div>
  <div class="mat-elevation-z8">
    <div *ngIf="DEFINITION_ELEMENT_DATA.length > 0">
      <table mat-table [dataSource]="dataSource">

        <!-- Role Name Column -->
        <ng-container matColumnDef="roleName">
          <th mat-header-cell *matHeaderCellDef>Role Name</th>
          <td mat-cell *matCellDef="let element" [innerHTML]="element['roleName']"></td>
        </ng-container>

        <!-- Capability Friendly Name Column -->
        <ng-container matColumnDef="friendlyName">
          <th mat-header-cell *matHeaderCellDef>Friendly Name</th>
          <td mat-cell *matCellDef="let element" [innerHTML]="element['friendlyName']"></td>
        </ng-container>

        <!-- Capability Dependencies Column -->
        <ng-container matColumnDef="dependencies">
          <th mat-header-cell *matHeaderCellDef>Dependencies</th>
          <td mat-cell *matCellDef="let element">
          <ng-container *ngFor="let item of element?.dependencies; let i = index">
            <span [innerHTML]="item.roleName"></span>
            <br *ngIf="element?.dependencies.length > 1 && i !== element.dependencies.length - 1" />
          </ng-container>
          <span *ngIf="!element.dependencies">-</span>
          </td>
        </ng-container>

        <!-- Bypass Association Column -->
        <ng-container matColumnDef="bypassAssociation">
          <th mat-header-cell *matHeaderCellDef>Bypass Association</th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox disableRipple class="bypass-checkbox" [checked]="element?.bypassAssociation === true"
              [indeterminate]="!element.hasOwnProperty('bypassAssociation')" [disabled]="true"></mat-checkbox>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedRoleColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedRoleColumns"></tr>
      </table>
    </div>
  </div>
  <div>No role capabilities found for selected role.</div>
</section>

<!-- Role Capability By Role Table -->
<section *ngIf="roleCapsbyRole.length > 0 && !roleDefinition && this.focusedSection !== 'capabilities'">
  <div class="title-container">
    <div class="title">Role:&nbsp;<span>{{roleName}}</span></div>
      <div class="btn-wrapper">
        <button disableRipple mat-icon-button class="action-button" [disabled]="disable" aria-label="Edit" (click)="openEditRoleCapabilitiesDialog(false)">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
  </div>
    <div class="mat-elevation-z8">
      <div *ngIf="ROLECAP_BY_ROLE_ELEMENT_DATA.length > 0">
        <table mat-table [dataSource]="dataSource" matSort [matSortActive]="sortBy" matSortDirection="asc" matSortDisableClear>

          <!-- Capability Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell mat-sort-header="name" *matHeaderCellDef>Capability Name</th>
            <td mat-cell *matCellDef="let element" [innerHTML]="element['name']"></td>
          </ng-container>

          <!-- Capability Description Column -->
          <ng-container matColumnDef="description" >
            <th mat-header-cell mat-sort-header="description" *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let element" [innerHTML]="element['description']"></td>
          </ng-container>

          <!-- Capability Operation Column -->
          <ng-container matColumnDef="operation">
            <th mat-header-cell mat-sort-header="operation" *matHeaderCellDef>Operation</th>
            <td mat-cell *matCellDef="let element" [innerHTML]="element['operation']"></td>
          </ng-container>

          <!-- Capability Permission Column -->
          <ng-container matColumnDef="permission">
            <th mat-header-cell mat-sort-header="permission" *matHeaderCellDef>Permission</th>
            <td mat-cell *matCellDef="let element" [innerHTML]="element['permission']"></td>
          </ng-container>

          <!-- Capability Level Column -->
          <ng-container matColumnDef="level">
            <th mat-header-cell mat-sort-header="level" *matHeaderCellDef>Level</th>
            <td mat-cell *matCellDef="let element" [innerHTML]="element['level']"></td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedRoleCapsByRoleColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedRoleCapsByRoleColumns"></tr>
        </table>
      </div>
    </div>
</section>
<div [hidden]="!(ROLECAP_BY_ROLE_ELEMENT_DATA.length > 0)">
  <mat-paginator #paginatorRoles [length]="pageInfo.itemCount" [pageSize]="pageInfo.perPage" [pageSizeOptions]="pageInfo.perPageOptions"
    [pageIndex]="pageInfo.currentPage" [disabled]="pageInfo.itemCount === 0" (page)="onPagination($event)"></mat-paginator>
</div>

<!-- Capability Definition Table -->
<section *ngIf="capabilityDefinition && !(roleCapsbyCap.length > 0) && this.focusedSection !== 'roles'" class="definition">
  <div class="title-container">
    <div class="title">Capability Definition</div>
    <div class="btn-wrapper">
      <button disableRipple mat-icon-button class="action-button" [disabled]="disable" (click)="openEditCapabilitiesDialog(false)">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </div>
  <div class="mat-elevation-z8">
    <div *ngIf="DEFINITION_ELEMENT_DATA.length > 0">
      <table mat-table [dataSource]="dataSource">

        <!-- Capability Name Column -->
        <ng-container matColumnDef="capabilityName">
          <th mat-header-cell *matHeaderCellDef>Capability Name</th>
          <td mat-cell *matCellDef="let element" [innerHTML]="element['capabilityName']"></td>
        </ng-container>

          <!-- Capability Description Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Description</th>
          <td mat-cell *matCellDef="let element" [innerHTML]="element['description']"></td>
        </ng-container>

        <!-- Capability Level Column -->
        <ng-container matColumnDef="level">
          <th mat-header-cell *matHeaderCellDef>Level</th>
          <td mat-cell *matCellDef="let element" [innerHTML]="element['level']"></td>
        </ng-container>

        <!-- Application Name Column -->
        <ng-container matColumnDef="application">
          <th mat-header-cell *matHeaderCellDef>Application</th>
          <td mat-cell *matCellDef="let element" [innerHTML]="element['application']"></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedCapabilityColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedCapabilityColumns"></tr>
      </table>
    </div>
  </div>
  <div>No role capabilities found for selected capability.</div>
</section>

<!-- Role Capability By Capability Table -->
<section *ngIf="roleCapsbyCap.length > 0 && !capabilityDefinition && this.focusedSection !== 'roles'" class="rolecaps">
  <div class="title-container">
    <div class="title">Capability:&nbsp;<span>{{capabilityName}}</span></div>
    <div class="btn-wrapper">
      <button disableRipple mat-icon-button class="action-button" [disabled]="disable" aria-label="Edit" (click)="openEditCapabilitiesDialog(false, definitionPayload)">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </div>
  <div class="description">{{capabilityDescription}}</div>
    <div class="mat-elevation-z8">
      <div *ngIf="ROLECAP_BY_CAP_ELEMENT_DATA.length > 0">
        <table mat-table [dataSource]="dataSource" matSort [matSortActive]="sortBy" matSortDirection="asc" matSortDisableClear>

          <!-- Role Name Column -->
          <ng-container matColumnDef="roleName">
            <th mat-header-cell mat-sort-header="roleName" *matHeaderCellDef>Role Name</th>
            <td mat-cell *matCellDef="let element" [innerHTML]="element['roleName']"></td>
          </ng-container>
          
          <!-- Friendly Name Column -->
          <ng-container matColumnDef="friendlyName">
            <th mat-header-cell mat-sort-header="friendlyName" *matHeaderCellDef>Friendly Name</th>
            <td mat-cell *matCellDef="let element" [innerHTML]="element['friendlyName']"></td>
          </ng-container>

          <!-- Capability Operation Column -->
          <ng-container matColumnDef="operation">
            <th mat-header-cell mat-sort-header="operation" *matHeaderCellDef>Operation</th>
            <td mat-cell *matCellDef="let element" [innerHTML]="element['operation']"></td>
          </ng-container>

          <!-- Capability Permission Column -->
          <ng-container matColumnDef="permission">
            <th mat-header-cell mat-sort-header="permission" *matHeaderCellDef>Permission</th>
            <td mat-cell *matCellDef="let element" [innerHTML]="element['permission']"></td>
          </ng-container>

          <!-- Capability Level Column -->
          <ng-container matColumnDef="level">
            <th mat-header-cell mat-sort-header="level" *matHeaderCellDef>Level</th>
            <td mat-cell *matCellDef="let element" [innerHTML]="element['level']"></td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedRoleCapsByCapsColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedRoleCapsByCapsColumns"></tr>
        </table>
      </div>
    </div>
</section>
<div [hidden]="!(ROLECAP_BY_CAP_ELEMENT_DATA.length > 0)">
  <mat-paginator #paginatorCaps [length]="pageInfo.itemCount" [pageSize]="pageInfo.perPage" [pageSizeOptions]="pageInfo.perPageOptions"
    [pageIndex]="pageInfo.currentPage" [disabled]="pageInfo.itemCount === 0" (page)="onPagination($event)"></mat-paginator>
</div>
<app-progress-spinner [backdropEnabled]="true" [positionGloballyCenter]="true" [displayProgressSpinner]="processing">
</app-progress-spinner>
